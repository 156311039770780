export const enStore = {
 "Create A Store":"Create A Store",
"Store successfully registered!":"Store successfully registered!",
"Store Name":"Store Name",
"Store Description":"Store Description",
"Enter Store Name":"Enter Store Name",
"Monthly":"Monthly",
"Weekly":"Weekly",
"Enter Store Description":"Enter Store Description",
"Address":"Address",
"Next":"Next",
"To":"To",
"Enter":"Enter",
"Sales Volume":"Sales Volume",
"Area":"Area",
"Floor":"Floor",
"Unit Number":"Unit Number",
"City":"City",
"Zip Code":"Zip Code",
"Instructions for driver to reach store":"Instructions for driver to reach store",
"Select Store":"Select Store",
"Search":"Search",
"Back":"Back",
"Confirm":"Confirm",
"Store Details":"Store Details",
"Jan":"Jan",
"Feb":"Feb",
"Mar":"Mar",
"Apr":"Apr",
"May":"May",
"Jun":"Jun",
"Jul":"Jul",
"Aug":"Aug",
"Sep":"Sep",
"Oct":"Oct",
"Nov":"Nov",
"Dec":"Dec",
"january":"january",
"february":"february",
"march":"march",
"april":"april",
"may":"may",
"june":"june",
"july":"july",
"august":"august",
"september":"september",
"october":"october",
"november":"november",
"december":"december",
"Mon":"Mon",
"Tue":"Tue",
"Wed":"Wed",
"Thr":"Thr",
"Thu":"Thu",
"Fri":"Fri",
"Sat":"Sat",
"Sun":"Sun",
"Units Sold":"Units Sold",
"Instructions for driver":"Instructions for driver",
"Block":"Block",
"Mall Name":"Mall Name",
"Phone Number":"Phone Number",
"Optional":"Optional",
"Select":"Select",
"Last Period":"Last Period",
"lower than last period": "lower than last period",
"Week":"Week",
"Month":"Month",
"Year":"Year",
"Date - Date":"Date - Date",
"Order":"Order",
"No Transactions found":"No Transactions found",
"X-Axis - Month Dates":"X-Axis - Month Dates",
"Monthly Income Data":"Monthly Income Data",
"Y-Axis - USD":"Y-Axis - USD",
"Transactions":"Transactions",
"Select Option":"Select Option",
"All":"All",
"Refunded":"Refunded",
"Sold":"Sold",
"Filters":"Filters",
"Clear All":"Clear All",
"Apply":"Apply",
"Past 1": "Past 1",
"From": "From",
"This Week":"This Week",
"This Year":"This Year",
"This Month":"This Month",
"Average time to ship order":"Average time to ship order",
"Doc File":"Doc File",
"JPG":"JPG",
"PDF":"PDF",
"Export": "Export",
"Close": "Close",
"Export Report": "Export Report",
"Export Analytics report as": "Export Analytics report as",
"Product Analytics": "Product Analytics",
"Impression vs Sales": "Impression vs Sales",
"Average Delivery Time": "Average Delivery Time",
"Appeared in Search": "Appeared in Search",
"Upload Store Profile Image":"Upload Store Profile Image",
"*End date is required for Filter Please select end date.": "*End date is required for Filter Please select end date.",
"*Start date is required for Filter Please select start date.": "*Start date is required for Filter Please select start date.",
"Start date should be before the end date.": "Start date should be before the end date.",
". Please enter the necessary information" : ". Please enter the necessary information",
"Earnings": "Earnings",
"Timings":"Timings",
"is not a valid phone number":"is not a valid phone number",
"Unsupported file type OR File size too large":"Unsupported file type OR File size too large",
"Leading or trailing spaces are not allowed.":"Leading or trailing spaces are not allowed.",
"*Store Name is required":"*Store Name is required",
"*Store Description is required":"Store Description is required",
"Store image is required":"Store image is required",
"Please upload the image":"Please upload the image",
"File size should be less than 5 MB":"File size should be less than 5 MB",
"Only .png, .jpg, and .jpeg files are allowed":"Only .png, .jpg, and .jpeg files are allowed",
"Monday From Time is required":"Monday From Time is required",
"*Address is Required. Please enter the necessary information":"*Address is Required. Please enter the necessary information",
"*Area is Required. Please enter the necessary information":"*Area is Required. Please enter the necessary information",
"*Block is Required. Please enter the necessary information":"*Block is Required. Please enter the necessary information",
"*City is Required. Please enter the necessary information":"*City is Required. Please enter the necessary information",
"*City accept's alphanumeric characters only":"*City accept's alphanumeric characters only",
"accept's alphanumeric characters only":"accept's alphanumeric characters only",
"*ZipCode is Required. Please enter the necessary information":"*ZipCode is Required. Please enter the necessary information",
"*Instructions for driver to reach store is Required. Please enter the necessary information":"*Instructions for driver to reach store is Required. Please enter the necessary information",
"*Phone Number is Required. Please enter the necessary information":"*Phone Number is Required. Please enter the necessary information",
"*Phone number min length is 8":"*Phone number min length is 8",
"*Phone number max length is 10":"*Phone number max length is 10",
"Invalid time format":"Invalid time format",
"Tuesday From Time is required":"Tuesday From Time is required",
"Wednesday From Time is required":"Wednesday From Time is required",
"Thursday From Time is required":"Thursday From Time is required",
"Friday From Time is required":"Friday From Time is required",
"saturday From Time is required":"saturday From Time is required",
"Sunday From Time is required":"Sunday From Time is required",
"Average time to ship order is Required":"Average time to ship order is Required",
"Monday To Time is required":"Monday To Time is required",
"Tuesday To Time is required":"Tuesday To Time is required",
"Wednesday To Time is required":"Wednesday To Time is required",
"Thursday To Time is required":"Thursday To Time is required",
"Friday To Time is required":"Friday To Time is required",
"saturday To Time is required":"saturday To Time is required",
"Sunday To Time is required":"Sunday To Time is required",
"Monday To Time must be greater than Monday From Time":"Monday To Time must be greater than Monday From Time",
"Tuesday To Time must be greater than Tuesday From Time":"Tuesday To Time must be greater than Tuesday From Time",
"Wednesday To Time must be greater than Wednesday From Time":"Wednesday To Time must be greater than Wednesday From Time",
"Thursday To Time must be greater than Thursday From Time":"Thursday To Time must be greater than Thursday From Time",
"Friday To Time must be greater than Friday From Time":"Friday To Time must be greater than Friday From Time",
"saturday To Time must be greater than saturday From Time":"saturday To Time must be greater than saturday From Time",
"Sunday To Time must be greater than Sunday From Time":"Sunday To Time must be greater than Sunday From Time",
"Arabic": "(Arabic)",
"English": "(English)",
"Add New Store": "Add New Store",
"No Store is added here":"No Store is added here",
"Stores": "Stores",
"My Stores": "My Stores",
"Search Store": "Search Store",
"Open": "Open",
"Select Average Delivery Time": "Select Average Delivery Time",
"Update": "Update",
"Manage Timings": "Manage Timings",
"0 mins": "0 mins",
"10 mins": "10 mins",
"20 mins": "20 mins",
"30 mins": "30 mins",
"40 mins": "40 mins",
"50 mins": "50 mins",
"60 mins": "60 mins",
"Store Profile": "Store Profile",
"Edit Details": "Edit Details",
"Analytics & Insights": "Analytics & Insights",
"Revenue": "Revenue",
"Catalogues": "Catalogues",
"Inventory Management": "Inventory Management",
"Offers & Discounts" : "Offers & Discounts",
"Contact Number": "Contact Number",
"Email": "Email",
"Store Hours": "Store Hours",
"Edit Store Profile": "Edit Store Profile",
"Upload": "Upload",
"Save Details": "Save Details",
"Store Name is required. Please enter the necessary information": "Store Name is required. Please enter the necessary information",
"Store Description is required. Please enter the necessary information": "Store Description is required. Please enter the necessary information",
"Store name already exists": "Store name already exists",
"Create": "Create"
}

export const arStore = {
 "Save Details": "حفظ التفاصيل",
 "Upload": "تحميل",
 "Edit Store Profile": "تحرير ملف تعريف المتجر",
 "Store Hours": "ساعات عمل المتجر",
 "Email": "البريد الإلكتروني",
 "Contact Number" : "رقم التواصل",
 "Offers & Discounts": "العروض والخصومات",
 "Inventory Management": "إدارة المخزون",
 "Catalogues": "قوائم المعروضات",
 "Revenue": "الإيرادات",
 "Analytics & Insights": "التحليلات والمعطيات",
 "Edit Details": "تعديل البيانات",
"Store Profile": "الملف الشخصي للمتجر",
"0 mins": "0 دقيقة",
"10 mins": "10 دقائق",
"20 mins": "20 دقائق",
"30 mins": "30 دقائق",
"40 mins": "40 دقائق",
"50 mins": "50 دقائق",
"60 mins": "60 دقائق",
 "Manage Timings": "ضبط المواعيد",
 "Create A Store":"إنشاء متجر",
 "Sunday To Time is required":"الأحد إلى الوقت مطلوب",
 "Sunday To Time must be greater than Sunday From Time":"من الأحد إلى الوقت يجب أن يكون أكبر من الأحد من الوقت",
 "saturday To Time must be greater than saturday From Time":"من السبت إلى الوقت يجب أن يكون أكبر من السبت من الوقت",
 "Friday To Time must be greater than Friday From Time":"الجمعة إلى الوقت يجب أن تكون أكبر من الجمعة من الوقت",
 "Thursday To Time must be greater than Thursday From Time":"الخميس إلى الوقت يجب أن يكون أكبر من الخميس من الوقت",
 "Wednesday To Time must be greater than Wednesday From Time":"الأربعاء إلى الوقت يجب أن يكون أكبر من الأربعاء من الوقت",
 "Monday To Time must be greater than Monday From Time":"من الاثنين إلى الوقت يجب أن يكون أكبر من يوم الاثنين من الوقت",
 "Tuesday To Time must be greater than Tuesday From Time":"من الثلاثاء إلى الوقت يجب أن يكون أكبر من يوم الثلاثاء من الوقت",
 "saturday To Time is required":"السبت إلى الوقت مطلوب",
 "Friday To Time is required":"الجمعة إلى الوقت مطلوب",
 "Thursday To Time is required":"Thursday To Time is required",
 "Invalid time format":"تنسيق الوقت غير صالح",
 "Wednesday To Time is required":"الأربعاء إلى الوقت مطلوب",
 "Tuesday To Time is required":"الثلاثاء إلى الوقت مطلوب",
 "Monday To Time is required":"من الاثنين إلى الوقت مطلوب",
 "Average time to ship order is Required":"متوسط الوقت اللازم لشحن الطلب",
 "Sunday From Time is required":"الأحد من الوقت مطلوب",
 "Friday From Time is required":"الجمعة من الوقت مطلوبة",
 "saturday From Time is required":"السبت من الوقت المطلوب",
 "Tuesday From Time is required":"الثلاثاء من الوقت مطلوب",
 "Thursday From Time is required":"الخميس من الوقت مطلوب",
 "Wednesday From Time is required":"الأربعاء من الوقت مطلوب",
 "*Phone number max length is 10":"* الحد الأقصى لطول رقم الهاتف هو 10",
 "*Phone number min length is 8":"*الحد الأدنى لطول رقم الهاتف هو 8",
 "*Phone Number is Required. Please enter the necessary information":"* رقم الهاتف مطلوب. الرجاء إدخال المعلومات اللازمة",
"Store successfully registered!":"تم تسجيل المتجر بنجاح!",
"*Store Name is required":"اسم المتجر مطلوب",
"*Instructions for driver to reach store is Required. Please enter the necessary information":"*تعليمات السائق للوصول إلى المتجر مطلوبة. الرجاء إدخال المعلومات اللازمة",
"*ZipCode is Required. Please enter the necessary information":"*الرمز البريدي مطلوب. الرجاء إدخال المعلومات الضرورية",
"*Address is Required. Please enter the necessary information":"*العنوان مطلوب. الرجاء إدخال المعلومات اللازمة",
"*City is Required. Please enter the necessary information":"*المدينة مطلوبة. الرجاء إدخال المعلومات اللازمة",
"*City accept's alphanumeric characters only":"*تقبل المدينة الأحرف الأبجدية الرقمية فقط",
"accept's alphanumeric characters only":"قبول الأحرف الأبجدية الرقمية فقط",
"*Block is Required. Please enter the necessary information":"* الكتلة مطلوبة. الرجاء إدخال المعلومات اللازمة",
"*Area is Required. Please enter the necessary information":"*المنطقة مطلوبة. الرجاء إدخال المعلومات اللازمة",
"Monday From Time is required":"الاثنين من الوقت مطلوب",
"Store image is required":"صورة المتجر مطلوبة",
"Only .png, .jpg, and .jpeg files are allowed":"يُسمح فقط بملفات ‎.png و.jpg و.jpeg",
"Please upload the image":"يرجى تحميل الصورة",
"File size should be less than 5 MB":"حجم الملف يجب أن يكون أقل من 5 ميجابايت",
"Store Name":"اسم المتجر",
"*Store Description is required":"وصف المتجر مطلوب",
"Leading or trailing spaces are not allowed.":"غير مسموح بالمسافات البادئة أو اللاحقة.",
"Store Description":"وصف المتجر",
"Enter Store Name":"أدخل اسم المتجر",
"Unsupported file type OR File size too large":"نوع الملف غير مدعوم أو حجم الملف كبير جدًا",
"Monthly":"شهريا",
"is not a valid phone number":"ليس رقم هاتف صالح",
"Weekly":"أسبوعي",
"Enter Store Description":"أدخل وصف المتجر",
"Address":"العنوان",
"Next":"التالي",
"Enter":"أدخل",
"To": "الى",
"Sales Volume":"حجم المبيعات",
"Area":"المنطقة",
"Upload Store Profile Image":"تحميل صورة الملف الشخصي للمتجر",
"Floor":"الطابق",
"Unit Number": "رقم القطعة",
"City":"المدينة",
"Zip Code":"الرمز البريدي",
"Instructions for driver to reach store":"تعليمات للسائق للوصول إلى المتجر",
"Select Store":"حدد المتجر",
"Store Details":"تفاصيل المتجر",
"Timings":"التوقيت",
"Search":"البحث",
"Back":"الرجوع",
"Confirm":"التأكيد",
"Jan":"يناير",
"Feb":"فبراير",
"Mar":"مارس",
"Apr":"أبريل",
"May":"مايو",
"Jun":"يونيو",
"Jul":"يوليو",
"Aug":"أغسطس",
"Sep":"سبتمبر",
"Oct":"أكتوبر",
"Nov":"نوفمبر",
"Dec":"ديسمبر",
"january":"يناير",
"february":"فبراير",
"march":"يمشي",
"april":"أبريل",
"may":"يمكن",
"june":"يونيو",
"july":"يوليو",
"august":"أغسطس",
"september":"سبتمبر",
"october":"أكتوبر",
"november":"نوفمبر",
"december":"ديسمبر",
"Mon":"الاثنين",
"Tue":"الثلاثاء",
"Wed":"الأربعاء",
"Thr":"الخميس",
"Thu":"الخميس",
"Fri":"الجمعة",
"Sat":"السبت",
"Sun":"الاحد",
"Units Sold":"الوحدات المباعة",
"Instructions for driver":"تعليمات للسائق",
"Block":"العمارة",
"Mall Name": "إسم مركز التسوق",
"Phone Number":"رقم التليفون",
"Optional":"إختياري",
"Select":"يختار",
"Last Period":"الفترة الأخيرة",
"lower than last period": "أقل من الفترة الماضية",
"Week":"أسبوع",
"Month":"شهر",
"Year":"سنة",
"Date - Date":"التاريخ - التاريخ",
"Order":"طلب",
"No Transactions found":"لم يتم العثور على أي معاملات",
"X-Axis - Month Dates":"المحور السيني - تواريخ الشهر",
"Monthly Income Data":"بيانات الدخل الشهري",
"Y-Axis - USD":"المحور الصادي - الدولار الأمريكي",
"Transactions":"المعاملات",
"Select Option":"حدد الخيار",
"All":"الكل",
"Refunded":"تم رد المبلغ",
"Sold":"ما تم بيعه",
"Filters":"المرشحات",
"Clear All":"مسح الكل",
"Apply":"تطبيق",
"Past 1": "الماضي 1",
"From": "من",
"This Week":"هذا الاسبوع",
"This Year":"هذا العام",
"This Month":"هذا الشهر",
"Average time to ship order":"متوسط ​​الوقت لشحن الطلب",
"Doc File":"ملف الوثيقة",
"JPG":"جبغ",
"PDF":"قوات الدفاع الشعبي",
"Export": "التصدير",
"Close": "إغلاق",
"Export Report": "تصدير التقرير",
"Export Analytics report as": "تصدير تقرير التحليلات بصيغة",
"Product Analytics": "لوحة التحليلات",
"Impression vs Sales": "الظهور في نتائج البحث مقابل المبيعات",
"Average Delivery Time": "متوسط ​​وقت التسليم",
"Appeared in Search": "مرات الظهور في نتائج البحث",
"*End date is required for Filter Please select end date.": "*تاريخ الانتهاء مطلوب للتصفية برجاء تحديد تاريخ الانتهاء.",
"*Start date is required for Filter Please select start date.": "*تاريخ البدء مطلوب للتصفية برجاء تحديد تاريخ البدء.",
"Start date should be before the end date.": "يجب أن يكون تاريخ البدء قبل تاريخ الانتهاء.",
". Please enter the necessary information" : ". الرجاء إدخال المعلومات اللازمة",
"Earnings": "الأرباح",
"Arabic": "(عربي)",
"English": "(إنجليزي)",
"Add New Store": "إضافة متجر جديد",
"No Store is added here": "لا تتم إضافة أي متجر هنا",
"Stores": "متاجر",
"My Stores": "المتاجر الخاصة بي",
"Search Store": "البحث عن متجر",
"Open": "مفتوح",
"Select Average Delivery Time": "اختر متوسط وقت التسليم",
"Update": "إنشاء",
"Store Name is required. Please enter the necessary information": "اسم المتجر مطلوب. الرجاء إدخال المعلومات اللازمة",
"Store Description is required. Please enter the necessary information": "وصف المتجر مطلوب. الرجاء إدخال المعلومات اللازمة",
"Store name already exists": "اسم المتجر موجود بالفعل",
"Create": "إنشاء"
}