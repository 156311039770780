import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import { getFileNameFromUrl, getFileType } from "./chat.web";
import PageviewIcon from "@material-ui/icons/Pageview";

interface ChatFilePreviewProps {
  url: string;
  isSender?: boolean;
}

interface ChatFilePreviewState {}

const styles = {
  previewWrapper: {
    border: "1px solid #ddd",
    borderRadius: "4px",
    width: "100%",
    maxWidth: "400px",
    backgroundColor: "#f9f9f9",
    position: "relative" as "relative",
  },
  previewTitle: {
    fontSize: "1.25rem",
    color: "#375280",
    fontWeight: "bold" as "bold",
    fontFamily: "Lato, sans-serif",
    marginTop: 0,
    marginBottom: "10px",
  },
  viewUrl: {
    color: "#94A3B8",
    paddingLeft: "7px",
    textDecoration: "none",
    fontFamily: "Lato, sans-serif",
  },
  fileName: {
    fontSize: "0.9rem",
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    display: "flex",
    padding: "5px 7px 4px",
    marginLeft: "auto",
    marginRight: "auto",
    whiteSpace: "nowrap" as "nowrap",
    overflow: "hidden" as "hidden",
    textOverflow: "ellipsis" as "ellipsis",
    maxWidth: "100%",
    textDecoration: "none",
  },
  previewContainer: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    height: "auto",
    width: "100%",
    maxWidth: "320px",
    backgroundColor: "white",
    overflow: "hidden" as "hidden",
  },
  previewMedia: {
    maxHeight: "200px",
    maxWidth: "100%",
    objectFit: "contain" as "contain",
  },
  unsupportedMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    textAlign: "center" as "center",
    fontSize: "1rem",
    fontFamily: "Lato, sans-serif",
    color: "#8A91A8",
  },
};

class ChatFilePreview extends Component<
  ChatFilePreviewProps,
  ChatFilePreviewState
> {

  renderFilePreview = (fileType: string) => {
    const { url } = this.props;

    if(fileType === 'other'){
      return (
        <Box style={styles.previewContainer}>
          <Box style={styles.unsupportedMessage}>
            <Typography
              component="a"
              href={url}
              target="_blank"
              style={styles.viewUrl}
              rel="noopener noreferrer"
            >
              Unsupported file type. Click to view or download.
              <PageviewIcon fontSize="medium" />
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box style={styles.previewContainer}>
          {fileType === "image" && <img src={url} alt="Image preview" style={styles.previewMedia} />}
          {fileType === "audio" && <audio src={url} controls style={{ width: "100%" }} />}
          {fileType === "video" && <video src={url} controls style={{ width: "100%" }} />}
          <Typography 
            style={styles.fileName}
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getFileNameFromUrl(url, 30)}
            <PageviewIcon style={styles.viewUrl} fontSize="medium" />
          </Typography>
          
        </Box>
      );
    }
  };

  render() {
    const fileType = getFileType(this.props.url);
    return <Box style={styles.previewWrapper}>{this.renderFilePreview(fileType)}</Box>;
  }
}

export default ChatFilePreview;
