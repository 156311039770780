import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import StylingCallBackRequestController, {
  callStatus,
  Props,
} from "./StylingCallBackRequestController";
import { ThemeProvider } from "react-native-elements";

import SellerStylishContainer from "../../../../../components/src/SellerStylish/SellerStylishContainer";
import {
  CustomStyledStylishPagination,
  StylishPaginationContainer,
} from "../../../../catalogue/src/Stylish/StylishProductSourcing.web";
import i18n from "../../../../../components/src/i18next/i18n";
import { Box, Grid, styled, Typography } from "@material-ui/core";
import ReusableSnackbar from "../../../../../components/src/AlertContainer";
import CommonBackdrop from "../../../../../components/src/BackDrop";
import { backBtnIcon } from "../../assets";
import { StylishClientTitleContainer } from "./StylingClientWishlist.web";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylingCallBackRequest extends StylingCallBackRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  completedBtn = () => <CompletedBoxContainer>Completed</CompletedBoxContainer>;

  pendingBtn = (id: string) => (
    <PendingBoxContainer>
      <RejectBtnContainer
        data-test-id="reject-btn"
        onClick={() => this.updateStylishClientRequestData(id, "rejected")}
      >
        Reject
      </RejectBtnContainer>
      <AcceptBtnContainer
      data-test-id="accept-btn"
        onClick={() => this.updateStylishClientRequestData(id, "accepted")}
      >
        Accept
      </AcceptBtnContainer>
    </PendingBoxContainer>
  );

  acceptedBtn = (id: string) => (
    <AcceptBtnContainer
    data-test-id="complete-btn"
      onClick={() => this.updateStylishClientRequestData(id, "completed")}
    >
      Make Call
    </AcceptBtnContainer>
  );

  rejectedBtn = () => <RejectBtnContainer>Rejected</RejectBtnContainer>;

  handleBtn = (id: string, status: callStatus) => {
    switch (status) {
      case "completed":
        return this.completedBtn();
      case "pending":
        return this.pendingBtn(id);
      case "accepted":
        return this.acceptedBtn(id);
      case "rejected":
        return this.rejectedBtn();
      default:
        return <></>;
    }
  };
  renderSCBPagination = () => (
    <>
      {this.state.metaSCBResp !== undefined &&
      this.state.metaSCBResp.total_record > 10 ? (
        <StylishPaginationContainer>
          <Box className="pagination-data-container">
            {this.state.metaSCBResp.current_page !== null
              ? (this.state.metaSCBResp.current_page - 1) * 10 + 1
              : ""}{" "}
            -{" "}
            {this.state.metaSCBResp.current_page !== null &&
            this.state.metaSCBResp.total_record
              ? Math.min(
                  this.state.metaSCBResp.current_page * 10,
                  this.state.metaSCBResp?.total_record
                )
              : ""}{" "}
            {this.tranStylingCallbackRequest("of")}{" "}
            {this.state.metaSCBResp.total_record}{" "}
            {this.tranStylingCallbackRequest("results")}
          </Box>
          <CustomStyledStylishPagination
            page={
              this.state.metaSCBResp.current_page !== null
                ? this.state.metaSCBResp.current_page
                : 1
            }
            count={
              this.state.metaSCBResp.total_pages !== null
                ? this.state.metaSCBResp.total_pages
                : 1
            }
            shape="rounded"
            color="primary"
            data-test-id="sps-page-test-id"
            dir={i18n.dir()}
            onChange={this.handleStylishClientReqPageChange}
          />
        </StylishPaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderTableData = () => (
      <Grid container spacing={3} dir={i18n.dir()}>
        {this.state.tableBody.map((item) => (
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <StylingRequestContainer>
              <Box className="styling-request-header">
                <Typography className="styling-request-header-typo">
                  {item.attributes.buyer_name}
                </Typography>
                <Typography className="styling-request-header-typo">
                  Time - {item.attributes.hour}:{item.attributes.minute}
                </Typography>
              </Box>
              <Typography className="low-font-weight-typo height-52">
                {item.attributes.reason}
              </Typography>
              <Box className="styling-request-header styling-request-phone-no">
                <Typography className="styling-request-header-typo styling-request-phone-no-typo">
                  Phone Number
                </Typography>
                <Typography className="low-font-weight-typo">
                  +{item.attributes.buyer_phone_number}
                </Typography>
              </Box>
              {this.handleBtn(item.id, item.attributes.status)}
            </StylingRequestContainer>
          </Grid>
        ))}
      </Grid>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerStylishContainer
          role={this.state.userRole === "stylist" ? "Stylish" : ""}
          navigation={this.props.navigation}
        >
          <TableContainer>
          <StylishClientTitleContainer>
            <img
              src={backBtnIcon}
              data-test-id="back-btn-test-id"
              alt="backIcon"
              onClick={() => this.props.navigation.goBack()}
            />
            <Typography className="main-title">Call Requests</Typography>
          </StylishClientTitleContainer>
          {this.renderTableData()}
          </TableContainer>
          {this.renderSCBPagination()}

          <ReusableSnackbar
            onClose={this.onCloseSCBRAlert}
            open={this.state.isAlert}
            severity={this.state.severity}
            message={this.state.message}
            dataTestId="alertTestId"
            autoHideDuration={3000}
          />
        </SellerStylishContainer>
        <CommonBackdrop open={this.state.isLoading} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const TableContainer = styled(Box)({
  padding: "36px 30px",
});

const StylingRequestContainer = styled(Box)({
  "& .styling-request-header": {
    display: "flex",
    width: "100%",
    gap: 20,
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },
  "& .styling-request-header-typo": {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 18,
    lineHeight: "28px",
    letterSpacing: "-0.41px",
    color: "#375280",
  },
  "& .low-font-weight-typo": {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "26px",
    color: "#375280",
  },
  "& .styling-request-header-typo.styling-request-phone-no-typo": {
    fontSize: 16,
  },
  "& .styling-request-header.styling-request-phone-no": {
    marginTop: 16,
    marginBottom: 24,
  },
  "& .low-font-weight-typo.height-52": {
    height: "52px",
    "@media(max-width:540px)": {
      height: "78px",
    },
  },
});

const CompletedBoxContainer = styled(Box)({
  width: "100%",
  backgroundColor: "#CBD5E1",
  border: "1px solid #CBD5E1",
  color: "#FFFFFF",
  fontFamily: "Lato",
  fontWeight: 900,
  fontSize: 16,
  height: 42,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const PendingBoxContainer = styled(Box)({
  width: "100%",
  height: 42,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
});

const RejectBtnContainer = styled(Box)({
  width: "100%",
  height: 42,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 2,
  border: "1px solid #F87171",
  color: "#F87171",
  fontSize: "16px",
  fontFamily: "Lato",
});

const AcceptBtnContainer = styled(Box)({
  width: "100%",
  height: 42,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 2,
  fontSize: "16px",
  fontFamily: "Lato",
  border: "1px solid #CCBEB1",
  color: "#FFF",
  backgroundColor: "#CCBEB1",
  fontWeight: 900,
});
// Customizable Area End
