export const enAnalytics = {
 "Select Product":"Select Product",
"Sold Units":"Sold Units",
"Returned Units":"Returned Units",
"Sales Revenue Report Store":"Sales Revenue Report Store",
"Sales Revenue":"Sales Revenue",
"Monthly":"Monthly",
"Weekly":"Weekly",
"Average Order Value":"Average Order Value",
"Analytics & Insights":"Analytics & Insights",
"All Stores":"All Stores",
"Sales Volume Report":"Sales Volume Report",
"Sales Volume":"Sales Volume",
"Total Units Sold":"Total Units Sold",
"No Data Found":"No Data Found",
"Sales Growth Report":"Sales Growth Report",
"higher than last period":"higher than last period",
"Analytics Dashboard":"Analytics Dashboard",
"Total Revenue":"Total Revenue",
"Select Store":"Select Store",
"Search":"Search",
"Back":"Back",
"Confirm":"Confirm",
"Jan":"Jan",
"Feb":"Feb",
"Mar":"Mar",
"Apr":"Apr",
"May":"May",
"Jun":"Jun",
"Jul":"Jul",
"Aug":"Aug",
"Sep":"Sep",
"Oct":"Oct",
"Nov":"Nov",
"Dec":"Dec",
"january":"january",
"february":"february",
"march":"march",
"april":"april",
"may":"may",
"june":"june",
"july":"july",
"august":"august",
"september":"september",
"october":"october",
"november":"november",
"december":"december",
"Mon":"Mon",
"Tue":"Tue",
"Wed":"Wed",
"Thr":"Thr",
"Fri":"Fri",
"Sat":"Sat",
"Sun":"Sun",
"Units Sold":"Units Sold",
"Sales Revenue Report":"Sales Revenue Report",
"Sales Volume Report Store":"Sales Volume Report Store",
"Sales Volume Report Product":"Sales Volume Report Product",
"Sales by Store":"Sales by Store",
"Sales by Product":"Sales by Product",
"Current Period":"Current Period",
"Last Period":"Last Period",
"lower than last period": "lower than last period",
"Week":"Week",
"Month":"Month",
"Year":"Year",
"Date - Date":"Date - Date",
"Order":"Order",
"No Transactions found":"No Transactions found",
"X-Axis - Month Dates":"X-Axis - Month Dates",
"Monthly Income Data":"Monthly Income Data",
"Y-Axis - USD":"Y-Axis - USD",
"Transactions":"Transactions",
"Select Option":"Select Option",
"All":"All",
"Refunded":"Refunded",
"Sold":"Sold",
"Filters":"Filters",
"Clear All":"Clear All",
"Apply":"Apply",
"Past 1": "Past 1",
"From": "From",
"To": "To",
"This Week":"This Week",
"This Year":"This Year",
"This Month":"This Month",
"Select Date Range":"Select Date Range",
"Doc File":"Doc File",
"JPG":"JPG",
"PDF":"PDF",
"Export": "Export",
"Close": "Close",
"Export Report": "Export Report",
"Export Analytics report as": "Export Analytics report as",
"Product Analytics": "Product Analytics",
"Impression vs Sales": "Impression vs Sales",
"Return Units": "Return Units",
"Appeared in Search": "Appeared in Search",
"*End date is required for Filter Please select end date.": "*End date is required for Filter Please select end date.",
"*Start date is required for Filter Please select start date.": "*Start date is required for Filter Please select start date.",
"Start date should be before the end date.": "Start date should be before the end date.",
"End date should be after the start date." : "End date should be after the start date.",
"Earnings": "Earnings",
"All Store": "All Store"
}

export const arAnalytics = {
 "Select Product":"حدد المنتج",
"Sold Units":"العناصر المباعة",
"Returned Units":"الوحدات العائدة",
"Sales Revenue Report Store":"مخزن تقارير إيرادات المبيعات",
"Sales Revenue":"إيرادات المبيعات",
"Monthly":"شهريا",
"Weekly":"أسبوعي",
"Average Order Value":"متوسط ​​قيمة الطلب",
"Analytics & Insights":"التحليلات و المعطيات",
"All Stores":"جميع المتاجر",
"Sales Volume Report":"تقرير حجم المبيعات",
"Sales Volume":"حجم المبيعات",
"Total Units Sold":"إجمالي الوحدات المباعة",
"No Data Found":"لم يتم العثور على بيانات",
"Sales Growth Report":"تقرير تطور المبيعات",
"higher than last period":"أعلى من الفترة الماضية",
"Analytics Dashboard":"لوحة تحكم التحليلات",
"Total Revenue":"إجمالي الإيرادات",
"Select Store":"حدد المتجر",
"Search":"البحث",
"Back":"الرجوع",
"Confirm":"التأكيد",
"Jan":"يناير",
"Feb":"فبراير",
"Mar":"مارس",
"Apr":"أبريل",
"May":"مايو",
"Jun":"يونيو",
"Jul":"يوليو",
"Aug":"أغسطس",
"Sep":"سبتمبر",
"Oct":"أكتوبر",
"Nov":"نوفمبر",
"Dec":"ديسمبر",
"january":"يناير",
"february":"فبراير",
"march":"يمشي",
"april":"أبريل",
"may":"يمكن",
"june":"يونيو",
"july":"يوليو",
"august":"أغسطس",
"september":"سبتمبر",
"october":"أكتوبر",
"november":"نوفمبر",
"december":"ديسمبر",
"Mon":"الاثنين",
"Tue":"الثلاثاء",
"Wed":"الأربعاء",
"Thr":"الخميس",
"Fri":"الجمعة",
"Sat":"السبت",
"Sun":"الأحد",
"Units Sold":"الوحدات المباعة",
"Sales Revenue Report":"تقرير إيرادات المبيعات",
"Sales Volume Report Store":"تقرير إيرادات مبيعات المتجر",
"Sales Volume Report Product":"تقرير حجم مبيعات المنتج",
"Sales by Store":"المبيعات عن طريق المتجر",
"Sales by Product":"المبيعات حسب المنتج",
"Current Period":"الفترة الحالية",
"Last Period":"الفترة الأخيرة",
"lower than last period": "أقل من الفترة الماضية",
"Week":"أسبوع",
"Month":"شهر",
"Year":"سنة",
"Date - Date":"التاريخ - التاريخ",
"Order":"طلب",
"No Transactions found":"لم يتم العثور على أي معاملات",
"X-Axis - Month Dates":"المحور السيني - تواريخ الشهر",
"Monthly Income Data":"بيانات الدخل الشهري",
"Y-Axis - USD":"المحور الصادي - الدولار الأمريكي",
"Transactions":"المعاملات",
"Select Option":"حدد الخيار",
"All":"الكل",
"Refunded":"تم رد المبلغ",
"Sold":"ما تم بيعه",
"Filters":"المرشحات",
"Clear All":"مسح الكل",
"Apply":"تطبيق",
"Past 1": "الماضي 1",
"From": "من",
"To": "ل",
"This Week":"هذا الاسبوع",
"This Year":"هذا العام",
"This Month":"هذا الشهر",
"Select Date Range":"حدد النطاق الزمني",
"Doc File":"ملف الوثيقة",
"JPG":"جبغ",
"PDF":"قوات الدفاع الشعبي",
"Export": "التصدير",
"Close": "إغلاق",
"Export Report": "تصدير التقرير",
"Export Analytics report as": "تصدير تقرير التحليلات بصيغة",
"Product Analytics": "لوحة التحليلات",
"Impression vs Sales": "الظهور في نتائج البحث مقابل المبيعات",
"Return Units": "وحدات العودة",
"Appeared in Search": "مرات الظهور في نتائج البحث",
"*End date is required for Filter Please select end date.": "*تاريخ الانتهاء مطلوب للتصفية برجاء تحديد تاريخ الانتهاء.",
"*Start date is required for Filter Please select start date.": "*تاريخ البدء مطلوب للتصفية برجاء تحديد تاريخ البدء.",
"Start date should be before the end date.": "يجب أن يكون تاريخ البدء قبل تاريخ الانتهاء.",
"End date should be after the start date." : "يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء.",
"Earnings": "الأرباح",
"All Store": "جميع المتاجر"
}