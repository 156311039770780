import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface Product {
  id: string;
  type: string;
  attributes: {
    name: string;
    description: string;
    primary_image: string | undefined;
    primary_price: string;
    is_wishlist: boolean;
  };
}
interface INavigateTo {
  productId: string | undefined;
  props: unknown;
  screenName: string;
  raiseMessage?: Message;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  products: Product[],
  stylishbuyer: boolean;
  wishlistData:any,
  error:any,
  favouriteId:any,
  deleteItem:string,
  addedItem:string,
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertType;
  fullName: string,
  favLoading: boolean;
  currencySign: string;
  token: string;
  isDrawerOpen:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WishlistController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  wishlistApiCallID: string ="";
  getcategoriesId: string = "";
  DeletefavouriteCallID : string = "";
  postFavouriteApiCalledId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      products: [],
      wishlistData: [],
      error:null,
      favouriteId:"",
      deleteItem:"",
      stylishbuyer: false,
      addedItem:"",
      isAlert: false,
      alertMsg: '',
      alertType: 'success' as AlertType,
      fullName: "",
      favLoading: false,
      currencySign: "$", token: "",
      isDrawerOpen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
      
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getcategoriesId !== null &&
        this.getcategoriesId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(this.state.token && responseJson.data.length > 0){
          this.setState({
            products: responseJson.data,
            currencySign: responseJson.data[0].attributes.currency_logo

          })
        }
        this.setState({ products: responseJson.data })
      }
      

      if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.wishlistApiCallID !== null &&
      this.wishlistApiCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // this.setState({ wishlistData: responseJson.data })
      if (responseJson.data.errors) {
        this.setState({ error: responseJson.data.errors });
      } else {
        this.setState({ wishlistData: responseJson.data, error: null });
      }


      
    }
    this.processDeleteFavouriteResponse(message);
    
  if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
  this.postFavouriteApiCalledId !== null &&
  this.postFavouriteApiCalledId ===
  message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
  let responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  this.handleFavouriteResponse(responseJson,this.state.favouriteId);
}


    } 
    // Customizable Area End
  }



  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  contioneShopping = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
}

wishlistApi=async ()=>{
  const token = await getStorageData("auth-token");
  const header = {
      token: token,
  };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.wishlistApiCallID = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_favourites/favourites`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `GET`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const userDetails = await getStorageData("userRole", true);
    const stylishbuyer = await getStorageData("exploreBuyer", true);
    if(stylishbuyer) this.setState({stylishbuyer})
    if(userDetails?.currencyLogo){
      this.setState({
        currencySign: userDetails.currencyLogo
      })
    }
    this.wishlistApi();
    this.categoreySimilar();
    const name = await getStorageData('user_data', true);
    const token = await getStorageData("auth-token");
    this.setState({
      fullName: name.full_name, token: token
    });
    }
    getCurrency = () => {
      return this.state.currencySign === "$" ? "dollar" : "dinar"
    }
    categoreySimilar = async () => {
      const token = await getStorageData("auth-token");
  const header = {
      token: token,
  };
      const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getcategoriesId = requestMessageList.messageId;
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.product_cataloge
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCatalogues}?&page=1&per_page=5&currency=${this.getCurrency()}`
      );
      requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    navigateTo = ({
      productId,
      props,
      screenName,
    }: INavigateTo) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      productId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
      runEngine.sendMessage(message.id, message);
    }
    Deletefavourite=async ( favouriteId: string | null)=>{
      const token = await getStorageData("auth-token");
      const header = {
          token: token,
      };

      this.setState({
        favLoading: true
      })
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.DeletefavouriteCallID = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_favourites/favourites/destroy_by_favouritable?favouriteable_id=${favouriteId}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
          
          const updatedWishlistData = this.state.wishlistData.filter((item: { attributes: { favouriteable_id: string | null} }) => item.attributes.favouriteable_id!== favouriteId);
      this.setState({ wishlistData: updatedWishlistData });
      
      }
      oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    handlePostApiCall = async (favouriteId?: string) => {
      const token = await getStorageData("auth-token");
      const headers = {
        "Content-Type": configJSON.exampleApiContentType,
        token: token,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const httpBody = {
        data: {
          favouriteable_id: favouriteId
        }
      };
  
      this.postFavouriteApiCalledId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.postFavouriteApi
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
    }
    handleFavouriteApi = async (favouriteId: string, fav: boolean) => {
      const updatedProducts = this.state.products.map((product) =>
        product.id === favouriteId
          ? { ...product, attributes: { ...product.attributes, is_wishlist: !fav } }
          : product
      );
      this.setState({ products: updatedProducts });
    
      if (fav) {
        this.Deletefavourite(favouriteId).then((response) => {
          this.handleFavouriteResponse(response, favouriteId);
        });
      } else {
        this.handlePostApiCall(favouriteId).then((response) => {
          this.handleFavouriteResponse(response, favouriteId);
        });
      }
    };
    
    handleFavouriteResponse = (response: any, favouriteId: string) => {
      if (response.error) {
        this.setState({ error: response.error });
        this.setState({
          isAlert: true,
          alertMsg: this.state.error || "error",
          alertType: "error",
        });
        
      } else {
        const updatedProducts = this.state.products.map((product) =>
          product.id === favouriteId
            ? { ...product, attributes: { ...product.attributes, is_wishlist: !product.attributes.is_wishlist } }
            : product
        );
    
        this.setState({
          products: updatedProducts,
          isAlert: true,
          alertMsg: response?.meta?.message || "Updated successfully",
          alertType: "success",
        },()=>this.wishlistApi());
      }
    };

    processDeleteFavouriteResponse = (message:any) => {
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.DeletefavouriteCallID !== null &&
        this.DeletefavouriteCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({
          deleteItem: responseJson.message,
          isAlert: true,
          alertMsg: `${configJSON.removeFavTitle}`,
          alertType: 'success',
          favLoading: false
        },()=>this.wishlistApi());
      }
    };
    
    tranWishlist = (transKey: string) => {
      return i18n.t( transKey, {ns: "wishlist"})
    }

    transProfile = (transKey: string) => {
      return i18n.t( transKey, {ns: "sidebar"})
    }
  // Customizable Area End
}
