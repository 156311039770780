import React, { Component } from "react";
import { Button, Box, Typography, DialogTitle, DialogContent, DialogActions, Dialog } from "@material-ui/core";
import { Room as MapPin, ExpandMore as ChevronDown, MyLocation as Locate } from "@material-ui/icons";
import { styled } from "@material-ui/core/styles";
import { GoogleMap, Marker, LoadScriptNext } from "@react-google-maps/api";
import { getFormattedAddress } from "../Googlelogin/googleMap.web";
import { ApiKey } from "../APICall";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import CustomTooltip from "../Chat/CustomTooltip.web";
import ReusableSnackbar from "../AlertContainer";
import { AddressData } from "../../../blocks/landingpage/src/components/LocationPickerController.web";

interface State {
  isOpen: boolean;
  selectedLocation: string | null;
  selectedLatLng: { lat: number; lng: number} | null
  address: string;
  mapLoaded: boolean;
  inputFocus: boolean;
  isLocError: boolean;
  locErrorMessage: string;
  openDialog: boolean;
  selectedAddressId: string | number | null
}

interface Props {
  id?: string ;
  navigation: any;
  addressData: AddressData[]
}

class LocationSelector extends Component<Props, State> {
  private wrapperRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedLocation: null,
      address: "",
      mapLoaded: false,
      selectedLatLng: null,
      inputFocus: false,
      isLocError: false,
      locErrorMessage: "",
      openDialog: false,
      selectedAddressId: null
    };
  }

  async componentDidMount() {
    this.handleLocationChecker();
    document.addEventListener("mousedown", this.handleClickOutSideMenu);
  }

  async componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any) {
    if(prevState.isOpen !== this.state.isOpen){
        this.loadGoogleAPIs(this.initAutocomplete)
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutSideMenu);
}

  handleClickOutSideMenu = (event: MouseEvent) => {
    const wrapperElement = this.wrapperRef?.current;
    const pacContainers = document.querySelectorAll(".pac-container");

    if (
        wrapperElement &&
        !wrapperElement.contains(event.target as Node) &&
        ![...pacContainers].some(container => container.contains(event.target as Node))
    ) {
        this.setState({ isOpen: false });
    }
  };

  closeLocationSnackbar = () => {
    this.setState({
      isLocError: false,
      locErrorMessage: "",
    })
  }

  handleCloseDialog = () => {
    this.setState({openDialog: false})
  }

  handleUpdate = (loc: {lat: number, lng: number} | null) => {
    if(loc && loc.lat){
      setStorageData("userLocation", JSON.stringify(loc));
      document.body.setAttribute("data-change", new Date().toISOString());
    }
  }

  handleMainLocation = (lat: number, lng: number) => {
    const location = { lat: lat, lng: lng}
    this.setState({selectedLatLng:location}, 
      async() => {
        const userLocation = await getFormattedAddress(lat, lng)
        this.setState({selectedLocation:userLocation ,
        address:userLocation })
      }
    )
    this.handleUpdate(location);
  }

  handleLocationChecker = async () => {
    const storedLocation = await getStorageData("userLocation", true);
    const {addressData} = this.props
    if (!storedLocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          position.coords && this.handleMainLocation(position.coords.latitude, position.coords.longitude)
        },
        async (error) => {
          const isLogin = await getStorageData("auth-token");
          if (isLogin) {
            const userData = await getStorageData("user_data", true);
            if (userData.latitude && userData.longitude) {
              this.handleMainLocation(userData.latitude, userData.longitude)
            } else {
              if(addressData && addressData.length > 0){
                const findDefault = addressData.find(address => address.attributes.is_default)
                if(findDefault?.id){
                  const {latitude, longitude} = findDefault?.attributes
                  this.setState({ selectedAddressId: findDefault.id})
                  this.handleMainLocation(latitude, longitude)
                }
              } else {
                this.handleMainLocation(29.3759, 47.9774)
                this.setState({
                  openDialog: true
                })
              }
            }
          } else {
            this.handleMainLocation(29.3759, 47.9774)
          }
        }
      );
    } else {
      const storedLoc = await getStorageData("userLocation", true);
      storedLoc && this.handleMainLocation(storedLoc?.lat, storedLoc?.lng)
    }
  };

  initAutocomplete = async () => {
    this.setState({ mapLoaded: true })
    const input = document.getElementById("searchAuto") as HTMLInputElement;

    if (input) {
      const autocomplete = new window.google.maps.places.Autocomplete(input, { types: ["geocode"] });
      autocomplete.setFields(["geometry", "formatted_address"]);
      autocomplete.addListener("place_changed", async () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          const { lat, lng } = place.geometry.location;
          const locationDetails = await getFormattedAddress(lat(), lng());

          this.setState({
            selectedLatLng: {lat:lat(), lng:lng()},
            selectedLocation: locationDetails,
            selectedAddressId: null,
          })
        }
      });
    }
  };

  loadGoogleAPIs = (handleScriptLoadAddress: () => void) => {
    if (document.getElementById("google-maps-script1")) {
      handleScriptLoadAddress();
      return;
    }
    
    const script = document.createElement("script");
    script.id = "google-maps-script1";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
    script.async = true;
    script.defer = true;
    script.onload = handleScriptLoadAddress;
    document.body.appendChild(script);
  };

  toggleDropdown = () => {  
    if(this.state.isOpen){
      this.setState({ inputFocus: false, selectedAddressId: null})
    }
    this.setState((prev) => ({ isOpen: !prev.isOpen }));
  };

  getCurrentLocation = () => {
    navigator.geolocation?.getCurrentPosition(
      async (position) => {
        const coords = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const userLocation = await getFormattedAddress(position.coords.latitude, position.coords.longitude)
        this.setState({
          inputFocus: true,
          selectedLatLng:{
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          selectedLocation:userLocation || ""
        })
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          this.setState({ isLocError: true, locErrorMessage: "Location access is required to continue." });
        }
      }
    );
  };

  confirmLocation = () => {
    if (this.state.selectedLocation) {
      this.setState({ address: this.state.selectedLocation}, ()=>{
        this.handleUpdate(this.state.selectedLatLng)
        this.setState({isOpen: false, inputFocus: false, selectedAddressId: null})
      });
    }
  };

  handleMapClick = async (event: google.maps.MapMouseEvent, form: string) => {
    const locationDetails = await getFormattedAddress(event.latLng.lat(), event.latLng.lng());
    if (event.latLng) {
      const selectedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      this.setState({
        selectedLatLng: selectedLocation,
        selectedLocation: locationDetails
      })
    }
  };

  handleViewAddress = () => {
    this.setState({ openDialog: false})
    this.props.navigation.navigate("AddAddress")
  }

  handleSelectAddress = async(lat: number, lng: number) => {
    this.setState({
      selectedLatLng: {lat: lat, lng: lng},
    })
  }

  renderGoogleMap = () => {
    const { mapLoaded, selectedLatLng, inputFocus } = this.state
    const defaultCenter = { lat: 29.3759, lng: 47.9774};

    return mapLoaded && inputFocus && <Box className="mapContainer"><LoadScriptNext googleMapsApiKey={ApiKey()} libraries={["places"]}>
      <GoogleMap
        data-test-id="addMapTest"
        zoom={14}
        mapContainerStyle={{ width: "100%", height: "200px" }}
        center={selectedLatLng ?? defaultCenter}
        onClick={(event: google.maps.MapMouseEvent) => this.handleMapClick(event, "addMap")}
      >
        {selectedLatLng && (
          <Marker position={selectedLatLng} />
        )}
      </GoogleMap></LoadScriptNext></Box>
  }

  renderAdresses = () => {
    const {addressData} = this.props;
    if (!addressData || addressData.length === 0) return null;
    return <AddressContainer >
      {addressData?.map((address, index: number)=>{
        const {latitude, longitude, address_name} = address.attributes
        return <Box key={index}
          style={{ border: address.id === this.state.selectedAddressId ? '1px solid #375280': '1px solid #ddd'}}
          className="card"
          onClick={()=>{
            this.handleSelectAddress(latitude, longitude)
            this.setState({selectedAddressId: address.id, selectedLocation: address.attributes.street})
          }}
        >
          <Typography className="title">{address_name}</Typography>
          <Typography className="street">{address.attributes.street}</Typography>
        </Box>
      })}
    </AddressContainer>
  }


  handleClickInput = () => {
    navigator.geolocation.getCurrentPosition(
      () => {
        this.setState({ inputFocus: true });
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          this.setState({ isLocError: true, locErrorMessage: "Location access is required to display Map." });
        }
      }
    );
  }
  

  render() {
    const { isOpen, selectedLocation, address, isLocError, locErrorMessage, openDialog } = this.state;
    return (
      <LocationTriggerWrapper ref={this.wrapperRef}>
        <HeaderButton>
          <Button className="styledButton" variant="text" onClick={this.toggleDropdown}>
            <MapPin fontSize="small" style={{ color: "#375280" }} />
            <span className="buttonText">Deliver To</span>
            <ChevronDown className="arrow" style={{ transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
          </Button>
          {address && <CustomTooltip title={address || ""}><Typography className="selectedAddress">
            {address}
          </Typography>
          </CustomTooltip>}
        </HeaderButton>

        {isOpen && (
          <LocationDropdownWrapper>
            <Box className="searchContainer" display="flex" alignItems="center">
              <input
                id="searchAuto"
                type="text"
                className="searchInput"
                onClick={()=>this.handleClickInput()}
                placeholder="Enter your location"
              />
              <Button className="currentLocation" variant="outlined" size="small" onClick={this.getCurrentLocation}>
                <Locate fontSize="small" style={{ color: '#375280' }} />
              </Button>
            </Box>
            {this.renderGoogleMap()}
            {this.renderAdresses()}

            <Box className="confirmContainer" display="flex" justifyContent="space-between" alignItems="center">
              {selectedLocation && <Box display="flex" alignItems="center">
                <MapPin fontSize="small" style={{ color: '#375280' }} />
                <CustomTooltip title={selectedLocation || ""}>
                  <Typography className="progressAddress" noWrap>
                    {this.props.addressData && <span style={{ color: "#375280", fontWeight: 500}}>{`${this.props.addressData.find((adress)=> this.state.selectedAddressId === adress.id)?.attributes.address_name ?? ""}`} </span>}
                    {selectedLocation}
                  </Typography>
                </CustomTooltip>
              </Box>}
              <Button disabled={address === selectedLocation} className="confirm" variant="contained" onClick={this.confirmLocation}>
                Confirm
              </Button>
            </Box>

          </LocationDropdownWrapper>
        )}

      <ReusableSnackbar
        open={isLocError}
        message={locErrorMessage}
        onClose={this.closeLocationSnackbar}
        severity={"error"}
        dataTestId="alertLocationTestId"
        autoHideDuration={3000}
      />


      <StyledDialog open={openDialog} onClose={this.handleCloseDialog}>
          <DialogContent className="content">Location access denied. Please set your default home address in your profile.</DialogContent>
            <Button className="view" onClick={this.handleViewAddress} color="primary">
              View Adresses
            </Button>
      </StyledDialog> 

      </LocationTriggerWrapper>
    );
  }
}

export default LocationSelector;

const LocationTriggerWrapper = styled('div')({
  position: "relative",
});

const HeaderButton = styled(Box)({
  display: "flex",
  flexDirection: 'column',
  alignItems: 'flex-start',
  "& .styledButton": {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    padding: 0,
    textTransform: "none",
    fontFamily: "Lato",
    fontSize: "0.875rem",
    fontWeight: 500,
    "& .buttonText": {
      fontFamily: "Lato",
      marginLeft: '6px',
      width: 'max-content',
    },
    "& .arrow": {
      marginLeft: "6px",
      color: '#375280',
      fontSize: '24px',
      transition: "transform 0.2s"
    }
  },
  "& .selectedAddress": {
    color: "#94A3B8",
    fontFamily: "Lato",
    fontSize: '14px',
    maxWidth: "200px",
    paddingLeft: '3px',
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: 400
  }
})

const LocationDropdownWrapper = styled(Box)({
  position: "absolute",
  top: "100%",
  left: 0,
  marginTop: "4px",
  zIndex: 50,
  background: "#fff",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  border: '1px solid #ddd',
  borderRadius: "4px",
  padding: "10px",
  maxHeight: "500px",
  minWidth: "330px",
  width: '100%',
  "& .searchContainer": {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    "& .currentLocation": {
      marginLeft: 'auto',
      height: '35px'
    },
    "& .searchInput":{
      padding: "10px",
      backgroundColor: "white",
      display: "block",
      borderRadius: "4px",
      border: "1px solid lightgray",
      width: "100%",
      "&:focus":{
        border: "1px solid lightgray !important",
        outline: '2px solid #375280'
      }
    },
    marginBottom: '10px'
  },
  "& .mapContainer": {
    width: "100%",
    height: 200,
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "hidden",
  },
  "& .confirmContainer": {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    gap: '10px',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    "& .progressAddress": {
      color: "#94A3B8",
      fontFamily: "Lato",
      marginLeft: '6px',
      fontSize: "0.875rem",
      fontWeight: 500,
      maxWidth: "200px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    "& .confirm": {
      fontFamily: "Lato",
      backgroundColor: '#375280',
      textTransform: 'capitalize',
      color: 'white',
      width: "120px",
      height: "35px",
      cursor: 'pointer',
      fontSize: "16px",
      fontWeight: 500,
      padding: '10px',
      marginLeft: 'auto',
      "&:disabled": {
        backgroundColor: 'lightgrey'
      }
    }
  }
});

const AddressContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',

  maxHeight: "200px",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "6px",
    border: "2px solid transparent",
    backgroundClip: "content-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  scrollbarWidth: "thin",
  "-ms-overflow-style": "none",
  "& .card":{
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '5px 10px',
    width: '90%',
    borderRadius: '4px',
    justifyContent: 'center',
    "& .title":{
      fontFamily: "Lato",
      maxWidth: "280px",
      color: "#375280",
      textOverflow: "ellipsis",
      fontSize: '16px',
      overflow: "hidden",
      whiteSpace: "nowrap",
      fontWeight: 500
    },
    "& .street":{
      color: "#94A3B8",
      fontFamily: "Lato",
      maxWidth: "280px",
      fontSize: '14px',
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: 400
    }
  }
})

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    width: "90%",
    maxWidth: "480px",
    minWidth: "300px",
    borderRadius: "4px",
    padding: "20px",
  },
  "& .view":{
    fontFamily: "Lato",
    backgroundColor: '#375280',
    textTransform: 'capitalize',
    color: 'white',
    marginTop: "15px",
    marginLeft: "auto",
    marginRight: 'auto',
    width: "auto",
    height: "38px",
    cursor: 'pointer',
    fontSize: "16px",
    fontWeight: 500,
    padding: '10px 15px',
  },
  "& .content": {
    padding: "0",
    color: '#375280',
    fontFamily: "Lato",
    fontSize: "1.275rem",
    lineHeight: "1.6rem",
    textAlign: "center",
    fontWeight: 500, 
  }
});