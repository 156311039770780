import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
} from "@material-ui/core";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import StylishHeader from "../../../components/src/Stylish/StylishHeader";
import StylishSidebar from "../../../components/src/Stylish/StylishSidebar";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  offButtonNone = () => {
    return this.state.offerBack === "offer" ? "block" : "none"
  }

  // handleConditon = () => {
  //   if (this.state.userToken && (this.state.userRole === "buyer" || this.state.userRole === "" || this.state.buyerstylish)) {
  //     return true
  //   }
  //   return false
  // }

  handleCond = (condition: boolean, truSta: string, falseSta: string) => {
    return condition ? truSta : falseSta
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const policyData = this.state.policyData
    return (
      <>
        {this.handleBuyerHeader() && <Header navigation={this.props.navigation} />}
        {this.state.buyerstylish && <StylishBuyerHeader navigation={this.props.navigation} />}
        <ContainerBox>
          <Grid container style={{ backgroundColor: !this.handleBuyerHeader() ? "#ffffff" : "#f2efef", flexWrap: "nowrap", flexDirection: "column" }}>
          <Grid item 
            xs={window.innerWidth < 951 ? 1 : 2}
             md={window.innerWidth < 951 ? 1 : 2}>

              {this.state.userRole === "seller" && (
                <SellerSidebar navigation={this.props.navigation} />)

              }

              {this.state.userRole === "stylist" && !this.state.buyerstylish && (
                <StylishSidebar navigation={this.props.navigation} />)

              }

              {(this.handleBuyerHeader() || this.state.buyerstylish) && (
                <>
                  <NavigationBox>
                    <HomeText data-test-id="homeLandingPage" onClick={this.handleLandingPageFromPolicy}>Home</HomeText>
                    <ChevronRightRoundedIcon style={{ color: "rgba(55, 82, 128, 1)" }} />
                    <PolicyText>{configJSON.PoliciesMsg}</PolicyText>
                  </NavigationBox>
                  <Box>
                    <PolicyHeaderText>
                      {configJSON.PoliciesMsg}
                    </PolicyHeaderText>
                  </Box>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={window.innerWidth < 951 ? 11 : 10}
              md={window.innerWidth < 951 ? 11 : 10}
              style={{
                marginTop: window.innerWidth < 951 ? "-6rem" : "0px"
              }}
            >
              {this.state.userToken && this.state.userRole === "seller" && <SellerHeader navigation={this.props.navigation} />}
              {this.state.userToken && this.state.userRole === "stylist" && !this.state.buyerstylish && <StylishHeader navigation={this.props.navigation} />}
              <Box style={{ paddingLeft: "40px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "start" }}>
                <TabBox>
                  <Box style={{ marginLeft: "4px", display: 'flex', alignItems: 'center' }}>
                    <Box style={{ display: this.offButtonNone(), marginRight: '10px' }} data-test-id="handleOfferPage" onClick={this.handleOfferPage}> <ArrowBackIosRoundedIcon /> </Box>
                    {(this.state.userRole === "seller" || this.state.userRole === "stylist") && <Typography style={{
                      color: "#375280",
                      fontSize: "24px",
                      fontFamily: "Lato",
                      fontWeight: 700
                    }} >Policies</Typography>
                    }
                  </Box>
                  <Box style={{ display: "flex" }}>
                    {this.state.tabData.map((tabValue, index) => (
                      <TabText key={index} style={{
                        color: this.handleCond(this.state.activeTab === index, "#375280", "#94A3B8"),
                        borderBottom: this.handleCond(this.state.activeTab === index, "4px solid #375280", "1px solid gainsboro")
                      }}
                        data-test-id={`tabsdataId${index}`}
                        onClick={() => this.handleChangeTab(index)}>{tabValue}</TabText>
                    ))}
                  </Box>
                </TabBox>
                <PolicyContainer style={{ margin: (this.state.userRole === "buyer" || this.state.userRole === "" || this.state.buyerstylish) ? "25px 0px" : "55px 0px" }}>
                  <div style={webStyles.divContainer}
                    dangerouslySetInnerHTML={{ __html: this.state.policyData[this.state.activeTab as keyof typeof policyData] }} />
                </PolicyContainer>
              </Box>
            </Grid>
          </Grid>
        </ContainerBox>
        {this.handleBuyerHeader() && <Footer navigation={this.props.navigation} />}
      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const ContainerBox = styled(Box)({
  backgroundColor: "rgb(242, 239, 239)",
  fontFamily: "Lato",
  color: "#375280"
})

const NavigationBox = styled(Box)({
  width: "135px",
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "40px"
})

const HomeText = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  color: "#375280",
  cursor: "pointer",
  fontFamily: "Lato"
})

const PolicyText = styled(Typography)({
  fontWeight: 700,
  fontSize: "14px",
  color: "#94A3B8",
  cursor: "pointer",
  fontFamily: "Lato"
})

const PolicyHeaderText = styled(Typography)({
  fontWeight: 800,
  fontSize: "28px",
  color: "#375280",
  margin: "40px",
  fontFamily: "Lato"
})

const TabText = styled(Typography)({
  fontWeight: 500,
  fontSize: "14px",
  color: "#94A3B8",
  cursor: "pointer",
  padding: "11px 20px",
  fontFamily: "Lato"
})

const TabBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  height: "70px",
  marginTop: "65px",
  fontFamily: "Lato",
  gap: "20px"
})

const PolicyContainer = styled(Box)({
  wordWrap: "break-word",
  width: "95%",
  overflowY: "auto",
  padding: "20px",
  fontFamily: "Lato",
  border: "1px solid #bfd1e8",
  "@media only screen and (max-width: 1400px)": {
    width: "90%"
  }
})

const webStyles = {
  divContainer: {
    color: "#375280"
  }
}
// Customizable Area End
