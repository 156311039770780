import React from "react";
// Customizable Area Start
import { Paper, Typography, Grid, Box, Button, Card } from "@material-ui/core";
import i18n from "../../../components/src/i18next/i18n";
import { image_Like, viewLike } from "../../categoriessubcategories/src/assets";
import { handleImgUrl } from "../../../components/src/Chat/chat.web";
import CustomLandingCarousel from "../../../components/src/CustomLandingCarousel.web";
// Customizable Area End

import LandingPageController, { Props, configJSON } from "./LandingPageController";
import { createTheme, styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { ThemeProvider } from "react-native-elements";
import CustomSlider from "../../../components/src/CustomSlider.web";
import { noImage } from "./assets";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AuthenticateUser from "../../../components/src/AuthenticateUser";
import CircularProgress from '@material-ui/core/CircularProgress';
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";

const theme = createTheme({
  typography: {
    subtitle1: {
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "36px"
    },
    subtitle2: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "28px",
      textTransform: "uppercase",
      color: "#1C2324",
    },
    fontFamily: "Lato"
  }
});

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDialog = () => {
    return (
      <AuthenticateUser
        auth={this.state.token}
        title="Log in to view your Wishlist."
        description="Save your favorite items for later and easily find them when you're ready to shop"
        btnText="Log in or Sign up"
        navigation={this.props.navigation}
        onClose={this.closeModal}
      />
    )
  }
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  // istanbul ignore next
  getStylesByIndex = (index: number) => {
    let imageStyle;
    let gridItemStyle = {};

    if (index === 0 || index === 2) {
      imageStyle = webStyles.stylistImage;
      gridItemStyle = { gridRow: "span 2" };
    } else if (index === 1) {
      imageStyle = webStyles.stylistImage2;
    } else {
      imageStyle = {
        width: "317.37px",
        height: "338px",
      };
    }

    return { imageStyle, gridItemStyle };
  }

  renderNewLaunchItems = () => {
    return this.state.newLaunchDataList && this.state.newLaunchDataList.length > 0 &&
      <RecommendOrNewLaunchContainer>
        <Typography dir={i18n.dir()} style={webStyles.headContentText5}>{this.transLand(configJSON.newLauchHeadingText)}</Typography>
        <div dir={i18n.dir()} style={webStyles.divBroder}>
          <p style={webStyles.textBroder} />
        </div>
        <Box style={{ position: "relative" }}>
          <CustomSlider data={this.state.newLaunchDataList} navigateToDeatilPage={this.navigateToDeatilPage} handleLikeApiCall={this.handleFavouriteApi}
            calledFrom="newLaunch" currencyLogo={this.state.userCurrency} />
          {this.state.newLaunchLoading && <Box style={{ width: '54px', textAlign: 'center', paddingBottom: '20px', position: "absolute", borderRadius: "2px", top: "50%", backgroundColor: "#FFFFFF", left: "50%", height: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress style={{ color: '#375280', marginTop: "20px" }} /></Box>}
        </Box>
      </RecommendOrNewLaunchContainer>
  }

  renderExlusiveNewTrendItems = () => {
    return <Box style={{ position: "relative" }}>
      {this.state.trendgingDataList && this.state.trendgingDataList.length > 0 &&
        <TrendContainer
          dir={i18n.dir()}
        >
          <Typography style={webStyles.headContentText4}>
            {this.transLand(configJSON.trendingHeadingText)}
          </Typography>
          <div className="divborder" style={webStyles.divBroder}>
            <p style={webStyles.textBroder} />
          </div>
          <MuiGrid >
            {this.state.trendgingDataList && this.state.trendgingDataList.map((cardDetail, index) => (
                <Card data-testId="cartDetails" style={webStyles.trendingCard}>
                  <Box style={{ position: 'relative' }}>
                    <img
                      src={this.handleCondition(cardDetail?.attributes?.primary_image === null, noImage, cardDetail?.attributes?.primary_image)}
                      alt={`Image ${index + 1}`}
                      style={{ ...webStyles.trendingImage, objectFit: 'fill' }}
                      data-test-id="navigateToDetail"
                      onClick={this.navigateToDeatilPage.bind(this, { id: cardDetail.id, screenName: "Productdescription3" })}
                    />
                    <Box style={{ ...webStyles.productIcon, position: 'absolute' }}>
                      <Box style={{ ...webStyles.productDiscount, backgroundColor: cardDetail.attributes.primary_discounted_percentage > 0 ? "#FFFFFF" : "transparent" }}>
                        {cardDetail.attributes.primary_discounted_percentage > 0 &&
                          `${cardDetail.attributes.primary_discounted_percentage}${configJSON.offerPercentage}`}
                      </Box>
                      <Box style={webStyles.productFavorite} data-test-id="handleLikeClick" onClick={() => this.handleFavouriteApi(cardDetail?.id, cardDetail.attributes?.is_wishlist, "trending")}>
                        <img width="18px" height="18px" style={{cursor:"pointer"}}  src={this.handleCondition(cardDetail.attributes?.is_wishlist, viewLike, image_Like)} alt={`${index}`} />
                      </Box>

                    </Box>
                  </Box>
                  <Box style={{ padding: '24px' }}>
                    <Box style={{ ...webStyles.productData, whiteSpace: 'nowrap', flexDirection: 'row' }}>
                      <Box style={{ ...webStyles.productValue, whiteSpace: 'nowrap' }}>
                        <Typography style={{ ...webStyles.productName, whiteSpace: 'nowrap' }} >{cardDetail?.attributes?.name}</Typography>
                        <Typography style={{ ...webStyles.productDescription, whiteSpace: 'nowrap' }} >{cardDetail.attributes.primary_store_name}</Typography>
                      </Box>
                      <Box style={{ ...webStyles.acountMrp, textAlign: 'center' }}>
                        {cardDetail?.attributes?.primary_discounted_percentage > 0 ? (
                          <>
                            <Typography id="landing-page-primary-price">
                              <del>{`${this.state.userCurrency} ${cardDetail?.attributes?.primary_main_price}`}</del>
                            </Typography>
                            <Typography id="landing-page-actual-price" style={webStyles.actualPrice}>{`${this.state.userCurrency} ${cardDetail?.attributes?.primary_price}`}</Typography>
                          </>
                        ) : (
                          <Typography id="landing-page-original-price" style={webStyles.actualPrice}>{`${this.state.userCurrency} ${cardDetail?.attributes?.primary_main_price}`}</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Card>
            ))}
          </MuiGrid>
          <Button variant="contained" data-testId="trendingButton" style={webStyles.stylistBtn} onClick={this.handleTrendingList}>{this.transLand("Explore Our Trending Collection")}</Button>
        </TrendContainer>
      }
      {this.state.favLoading && <Box style={{ width: '54px', textAlign: 'center', paddingBottom: '20px', position: "absolute", borderRadius: "2px", top: "50%", backgroundColor: "#FFFFFF", left: "50%", height: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress style={{ color: '#375280', marginTop: "20px" }} /></Box>}
  </Box>
  }

  renderRecommendedItems = () => {
    return this.state.recommendationDataList && this.state.recommendationDataList.length > 0 &&
      <>
        <RecommendOrNewLaunchContainer>
          <Typography style={webStyles.headContentText2}>
            {this.transLand(configJSON.recommendedHeadingText)}
          </Typography>
          <div style={webStyles.divBroder}>
            <p style={webStyles.textBroder} />
          </div>
            <CustomSlider data={this.state.recommendationDataList} navigateToDeatilPage={this.navigateToDeatilPage} handleLikeApiCall={this.handleFavouriteApi} calledFrom="recommendation" currencyLogo={this.state.userCurrency} />
            {this.state.recommendLoading && <Box style={{ width: '54px', textAlign: 'center', paddingBottom: '20px', position: "absolute", borderRadius: "2px", top: "50%", backgroundColor: "#FFFFFF", left: "50%", height: "36px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress style={{ color: '#375280', marginTop: "20px" }} /></Box>}
        </RecommendOrNewLaunchContainer>
        <div style={{...webStyles.divBroder, paddingBottom: "50px"}}>
          <p style={webStyles.borderStyle} />
        </div>
      </>
  }

  // Customizable Area End

  render() {
    // const { windowWidth } = this.state;
    // const fontSize = windowWidth <= 600 ? '40px' : '48px';
    // const webStyles1 = {
    //   headContentText: {
    //     color: '#375280',
    //     fontSize: fontSize,
    //     fontWeight: 800,
    //     fontFamily: 'Lato',
    //     textTransform: 'capitalize' as 'capitalize',
    //   },
    // };
    return (
      <div>
        {this.state.stylishBuyer ? (
          <StylishBuyerHeader navigation={this.props.navigation} />
        ) : (
          <Header navigation={this.props.navigation} />
        )}

        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
          dir={i18n.dir()}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        {this.state.modalOpen && this.renderDialog()}
        <ThemeProvider theme={theme}>
          <div style={{ background: "#f2efef" }}>
            <Grid
              dir={i18n.dir()}
              container
              direction="column"
              spacing={2}
              style={webStyles.headContentTextWrapper}
            >
              <Grid item style={{ justifyContent: "center" }}>
                <Typography align="center" style={webStyles.headContentText}>
                  {this.state.landingPageTitleList?.attributes.title}
                </Typography>
                <Typography align="center" style={webStyles.headContentText1}>
                  {this.state.landingPageTitleList?.attributes.subtitle}
                </Typography>
              </Grid>
            </Grid>
            <Grid>
              <CustomLandingCarousel data={this.state.carouselList} />
            </Grid>
            {this.renderRecommendedItems()}
            <Grid
              dir={i18n.dir()}
              style={{
                paddingTop: this.state.recommendationDataList && this.state.recommendationDataList.length > 0 ? "0px" : "40px",
                ...webStyles.recommendationGrid
              }}
            >
              {this.state.stylistServiceDataList && this.state.stylistServiceDataList.length > 0 && !this.state.stylishBuyer &&
                <StylistListContainer>
                  <Typography style={webStyles.headContentText3}>
                    {this.transLand(configJSON.stylistHeadingText)}
                  </Typography>
                  <div style={webStyles.divBroder}>
                    <p style={webStyles.textBroder} />
                  </div>
                  <StylistGrid container spacing={2}>
                    {this.state.stylistServiceDataList.map((item, index) => {
                      const span = index === 0 || index===2
                      return (<ImageContainer key={index} 
                        style={{
                          gridRow: span ? "span 2" : "default",
                          maxHeight: span ? "711px" : "350px",
                        }}>
                          <StyledImage src={handleImgUrl(item?.image?.url)} alt={`Stylist Image ${index + 1}`} />
                        </ImageContainer>)
                      }
                    )}
                  </StylistGrid>
                  <Button variant="contained" style={webStyles.stylistBtn} onClick={this.handleHiredStylist}>{this.transLand("Explore Our Stylists")}</Button>
                </StylistListContainer>}
              
            </Grid>
            {this.renderExlusiveNewTrendItems()}
            {this.renderNewLaunchItems()}
          </div>
        </ThemeProvider>
        <Footer navigation={this.props.navigation} />
      </div>
    );
  }
}

// Customizable Area Start
const webStyles = {
  landingPageCard: {
    width: "878px",
    height: "470px"
  },
  headContentTextWrapper: {
    padding: "3rem 0"
  },

  headContentText: {
    color: "#375280",
    fontSize: "clamp(32px, 6vw, 48px)",
    fontWeight: 800,
    fontFamily: "Lato",
    textTransform: "capitalize" as "capitalize",
    cursor: "default",
  } as React.CSSProperties,
  
  headContentText1: {
    color: "#375280",
    fontSize: "clamp(16px, 3vw, 24px)",
    fontWeight: 400,
    fontFamily: "Lato",
    textTransform: "capitalize" as "capitalize",
    cursor: "default",
  },
  
  headContentText2: {
    color: "#375280",
    fontSize: "clamp(20px, 4vw, 32px)",
    fontWeight: 700,
    lineHeight: "clamp(30px, 6vw, 47px)",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato",
    cursor: "default",
  },
  
  headContentText3: {
    color: "#375280",
    fontSize: "clamp(20px, 4vw, 32px)",
    fontWeight: 700,
    lineHeight: "clamp(24px, 5vw, 30px)",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato",
    cursor: "default",
  },
  
  headContentText4: {
    color: "#375280",
    fontSize: "clamp(20px, 4vw, 32px)",
    fontWeight: 700,
    lineHeight: "clamp(24px, 5vw, 30px)",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato",
    cursor: "default",
  },
  
  headContentText5: {
    color: "#375280",
    fontSize: "clamp(20px, 4vw, 32px)",
    fontWeight: 700,
    lineHeight: "clamp(30px, 6vw, 47px)",
    textAlign: "center" as "center",
    padding: "20px 0 0",
    fontFamily: "Lato",
    cursor: "default",
  },
  
  stylistBtn: {
    marginTop: "clamp(30px, 8vw, 67px)",
    marginBottom: "clamp(20px, 5vw, 35px)",
    fontSize: "clamp(16px, 2.5vw, 20px)",
    fontFamily: "Lato",
    fontWeight: 800,
    backgroundColor: "#CCBEB1",
    color: "#FFF",
    padding: "clamp(8px, 1.5vw, 10px) clamp(12px, 4vw, 16px)",
    textTransform: "none" as "none",
  },
  stylistImage: {
    width: "375px",
    height: "711px",
  },
  stylistImage2: {
    width: "317px",
    height: "344px",
  },
  paperContainer: {
    gridRow: "span 2"
  },
  paperContainer4: {
    gridColumn: 2,
    gridRow: "span 1"
  },
  paperContainer3: {
    gridRow: "span 2"
  },
  textBroder: {
    width: "100px",
    height: "4px",
    marginTop: '10px',
    borderBottom: "solid #CCBEB1"
  },
  divBroder: {
    display: "flex",
    paddingBottom: "20px",
    width: "100%",
    alignItems: "center" as "center",
    justifyContent: "center" as "center"
  },
  borderStyle: {
    width: "1150px",
    height: "0px",
    borderBottom: "solid #DDDCDC",
    top: "1693px",
    left: "136px",
    gap: "0px"
  },
  productIcon: { top: '20px', display: "flex", justifyContent: 'space-between', alignItems: "center", width: "100%" },
  productFavorite: { cursor: "pointer", color: "#375280", margin: "0 20px", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productDiscount: { color: "#375280", margin: "0 20px", fontSize: '12px', padding: '2px 0px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  productData: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle', cursor: "default", },
  productValue: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto', display: "flex" as "flex", flexDirection: "column" as "column", gap: 6 },
  productDescription: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productName: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  acountMrp: { fontFamily: "Lato", flexDirection: "column-reverse" as "column-reverse", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '14px', fontWeight: 700, backgroundColor: '#FFFFFF', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  recommendationGrid: {
    width: "100%",
    height: "auto",
    padding: "80px 0 40px",
    display: "flex",
    flexDirection: "column" as 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  actualPrice: { fontFamily: "Lato", color: '#375280', fontSize: '14px', fontWeight: 700 },
  recommendationOuterGrid: { width: "100%", height: "697 px", padding: "80px 0 40px" },
  trendingOuterGrid: {
    padding: "40px 120px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column" as 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  trendingGrid: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  trendingCard: {
    boxShadow: 'none',
    borderRadius: "0px, 0px, 2px, 2px",
    width: "100%",
    minWidth: '280px',
    maxWidth: '320px',
    height: "455px",
    backgroundColor: "#f2efef"
  },
  trendingImage: {
    width: "100%",
    height: "370px",
    position: 'relative' as 'relative',
    display: 'block',
    margin: 'auto',
    cursor: "pointer",
  },
  newLaunchGrid: {
    width: "100%",
    height: "800px",
    padding: "40px 0"
  }

};

const TrendContainer  = styled(Grid)({
  padding: "70px 0px",
  background: "#FFF",
  display: "flex",
  flexDirection: "column" as 'column',
  justifyContent: "center",
  alignItems: "center",
  "& .divborder":{
    paddingBottom: "36px !important",
    "@media(max-width: 667px)": {
      paddingBottom: "30px !important",
    },
    "@media(max-width: 484px)": {
      paddingBottom: "25px !important",
    },
    "@media(max-width: 392px)": {
      paddingBottom: "20px !important",
    },
  },
  "@media(max-width: 982px)": {
    padding: "60px 0"
  },
  "@media(max-width: 767px)": {
    padding: "50px 0"
  },
  "@media(max-width: 672px)": {
    padding: "40px 0"
  },
  "@media(max-width: 484px)": {
    padding: "40px 0"
  },
  "@media(max-width: 392px)": {
    padding: "20px"
  },
})

const RecommendOrNewLaunchContainer = styled(Grid)({
  width: "100%", 
  padding: "80px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
})

const StylistListContainer = styled(Box)({
  width: "80%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
})

const StylistGrid = styled(Grid)({
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "repeat(3, 1fr)",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 867px)": {
    gridTemplateColumns: "repeat(2, minmax(250px, 1fr))",
  },
  "@media (max-width: 612px)": {
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});

const ImageContainer = styled("div")({
  maxWidth: "400px",
  height: '100%',
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "@media (max-width: 612px)": {
    maxHeight: "350px",
    width: "100%"
  },
});

const StyledImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "2px",
});


const MuiGrid = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "center",
  gap: '24px',
  flexWrap: 'wrap',
  width: "83.33%",
  "@media(max-width: 571px)": {
    width: "90%",
  },

})
// Customizable Area End
