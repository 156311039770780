import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerRevenueController, {
  Props,
  saleRevenueCount,
} from "./SellerRevenueController";
import { Grid, styled, Box, Typography } from "@material-ui/core";
import { backBtnIcon, downloadIcon, filterIcon } from "../../assets";
import {
  ChartContainer,
  SalesCountContainer,
  SellerCountContainer,
  SellerStoreHeader,
} from "../AnalyticsInsights/SellerAnalyticsInsights.web";
import BarChart from "../../../../../components/src/Chart/BarChart";
import { monthLabel } from "../AnalyticsInsights/SellerAnalyticsInsightsController";
import TwoLineChart from "../../../../../components/src/Chart/LineChart";
import SellerStylishContainer from "../../../../../components/src/SellerStylish/SellerStylishContainer";

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerRevenue extends SellerRevenueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerStylishContainer navigation={this.props.navigation} role="Seller">
            <SellerStoreBodyContainer>
              <SellerStoreHeader>
                <Box className="title-container">
                  <img
                    src={backBtnIcon}
                    data-test-id="back-btn-test-id"
                    alt="backIcon"
                    onClick={() => this.handleRedirectTo("SellerStoreProfile")}
                  />
                  <Typography id="revenue-title" className="title">
                    Revenue
                  </Typography>
                  <Typography
                    id="revenue-second-title"
                    className="second-title"
                  >
                    [Past 1 Month]
                  </Typography>
                </Box>
                <Box className="btn-container">
                  <img src={downloadIcon} alt="down-load-icon" />
                  <img src={filterIcon} alt="filter-icon" />
                </Box>
              </SellerStoreHeader>
              <SellerCountContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      id="revenue-sale-count"
                      className="seller-count-title"
                    >
                      Sales Count
                    </Typography>
                  </Grid>
                  {saleRevenueCount.map(
                    (item: { label: string; value: string }) => (
                      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <SalesCountContainer>
                          <Typography className="seller-count-show-div-label">
                            {item.label}
                          </Typography>
                          <Typography className="seller-count-show-div-value">
                            {item.value}
                          </Typography>
                        </SalesCountContainer>
                      </Grid>
                    )
                  )}
                </Grid>
              </SellerCountContainer>
              <ChartContainer>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                    <Box className="chart-outer-container">
                      <Typography className="chart-title">
                        Impression vs Visitors
                      </Typography>
                      <Box className="revenue-chart-container">
                        <BarChart
                          labels={monthLabel}
                          datasets={[
                            {
                              label: "Revenue",
                              data: [
                                5000, 10000, 7500, 15000, 20000, 1000, 5000,
                                15000, 10000, 7000, 5000, 8000,
                              ],
                              backgroundColor: "#F59E0B",
                            },
                          ]}
                          barThickness={18}
                          xYTick="USD"
                          xTickColor="#375280"
                          yTickColor="#375280"
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12} lg={6} xl={6}>
                    <Box className="chart-outer-container">
                      <Typography className="chart-title">Sales</Typography>
                      <Box className="revenue-chart-container">
                        <TwoLineChart
                          labels={monthLabel}
                          datasets={[
                            {
                              data: [
                                50, 100, 100, 125, 150, 175, 150, 200, 175, 225,
                                125, 50,
                              ],
                              borderColor: "#A666FF",
                              backgroundColor: "#A666FF",
                              borderWidth: 1,
                              fill: false,
                              pointRadius: 0,
                              pointHoverRadius: 0,
                            },
                          ]}
                          xTickColor="#375280"
                          yTickColor="#375280"
                          highlightLastTick={false}
                          isPercentage={true}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </ChartContainer>
            </SellerStoreBodyContainer>
        </SellerStylishContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const SellerStoreBodyContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
});
// Customizable Area End
