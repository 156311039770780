import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  styled,
  Fade,
} from "@material-ui/core";
import RadioButton from "../../../components/src/RadioButton";
// Customizable Area End

import CurrencylanguageController, {
  Props,
} from "./CurrencylanguageController";
import i18n from "../../../components/src/i18next/i18n";

export default class CurrencyLanguage extends CurrencylanguageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getLanguageCurrency();
  }

  languageOptions = [
    { value: "0", label: "English", selectedValue: "English" },
    {
      value: "1",
      label: (
        <Typography style={{ fontFamily: "Lato, sans-serif" }}>
          Arabic - <b>عربي</b>
        </Typography>
      ),
      selectedValue: "Arabic",
    },
  ];
  render() {
    const { showLanguage } = this.props;
    return (
      <FadeContainer in={showLanguage} timeout={300}>
        <DropdownWrapper show={showLanguage} dir={i18n.dir()} data-test-id="language-wrapper">
          <RadioGroupWrapper
            row
            aria-labelledby="language-selection"
            name="language-selection"
            value={this.state.language}
            data-test-id="group-language"
            onChange={(event, value) => this.onChangeLanguage(value)}
          >
            {this.languageOptions.map((lang, index) => (
              <div key={lang.value}>
                <RadioButtonWrapper
                  value={lang.selectedValue}
                  control={<RadioButton variant="outlined" />}
                  label={lang.label}
                  data-test-id={`radio-${lang.value}`}
                  className={`radioButton ${
                    lang.label === this.state.language
                      ? "activeRadioButton"
                      : ""
                  }`}
                />
                {index === 0 && <Divider />}
              </div>
            ))}
          </RadioGroupWrapper>
        </DropdownWrapper>
      </FadeContainer>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const DropdownWrapper = styled(Box)(
  ({ show, dir }: { show: boolean | undefined; dir: "ltr" | "rtl" }) => ({
    width: 260,
    height: "auto",
    backgroundColor: "#ffffff",
    position: "absolute",
    top: 50,
    right: dir === "ltr" ? 10 : "auto",
    left: dir === "rtl" ? 10 : "auto",
    zIndex: 10,
    boxShadow: "0 0px 0px 2px rgba(0, 0, 0, 0.06)",
    padding: 25,
    transition: "opacity 0.3s ease, transform 0.3s ease",
    opacity: show ? 1 : 0,
    transform: show ? "translateY(0)" : "translateY(-10px)",
    display: show ? "block" : "none",
    "@media (max-width: 600px)": {
      width: "max-content",
      padding: 16,
    },
  })
);

const RadioGroupWrapper = styled(RadioGroup)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: 10,
});

const RadioButtonWrapper = styled(FormControlLabel)({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row-reverse",
  alignItems: "center",
  color: "#375280",
  margin: 0,
  "& .activeRadioButton": {
    color: "#1e88e5",
  },
});

const Divider = styled("hr")({
  color: "#d9d9d9",
});

const FadeContainer = styled(Fade)({});
// Customizable Area End
