import React from "react";

// Customizable Area Start
import {
  styled, Grid, Drawer,
  Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerReadyForCollectionDetailController, {
  Props,
} from "./SellerReadyForCollectionDetailController.web";
import { Noimageavailablepng, backBlueArrowImg } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import { statusContainerData } from "../../../components/src/Seller/SellerStatusPopup";
import i18n from "../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SellerReadyForCollectionDetail extends SellerReadyForCollectionDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  readyForCollectionRole = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return "Seller";
      case "stylist":
        return "Stylish";
      default:
        return "";
    }
  };

  handleReadyForCollectionDetailImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  handleAnchorLR = () => {
    return i18n.dir() === "rtl" ? "left": "right"
  }

  marginAutoReadyForCollectionCSS = () => {
    return i18n.dir()==="ltr" ? "0px 0px 0px auto" : "0px auto 0px 0px"
  }
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const orderDetailData = this.state.SellerOrdersProcessedView.find(
      (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
        order.attributes.order_management_order.attributes.order_number === this.state.productDetailId
    );

    function imageNotFound(data: string) {
      return <Box><img src={data ? data : Noimageavailablepng} style={webStyle.productIMg} /></Box>
    }

    return (
      <ThemeProvider theme={theme}>
        <SellerStylishContainer role={this.readyForCollectionRole()} navigation={this.props.navigation}>
            {this.state.isLoadingReady ? (

              <Box style={{ ...webStyle.tabsBoxReady }}>
                <CircularProgress style={webStyle.CircularProgressColor} />
              </Box>

            ) :
              <><Box style={webStyle.readyForCollectionTableContainer}>
                <GroupDetailBox>
                  <Box display="block" alignItems="center">
                    <Box className="GroupDetailBoxLeft">
                      <Box data-testid="proceedToAllOrderPage" onClick={this.proceedToAllOrderPage}>
                        <img src={backBlueArrowImg} style={{transform: this.handleReadyForCollectionDetailImgCSS()}} alt="" />
                      </Box>
                      <Typography className="itemIdText" data-testid="order_number">
                        #{orderDetailData?.attributes.order_management_order.attributes.order_number}
                      </Typography>
                      <button className="radyToCollectionStatusTopBtn" style={{margin: this.marginAutoReadyForCollectionCSS()}}>{this.transSellerReadyForCollectionDetail("Ready for Collection")}</button>
                    </Box>
                    <Box>
                  <Typography style={webStyle.itemIdTextDescription}>
                    {this.transSellerReadyForCollectionDetail("Total Products")}: {orderDetailData?.attributes.order_items[0].attributes.quantity}
                  </Typography>
                </Box>
                  </Box>
                  <Box display="flex" alignItems="center" margin={this.marginAutoReadyForCollectionCSS()}>
                    <button className="readyForStatusButton" data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true)}>{this.transSellerReadyForCollectionDetail("Order Status")}</button>
                  </Box>
                </GroupDetailBox>
                <Box style={webStyle.ArrivingCell2}>
                  <Typography variant="body1" style={webStyle.ArrivingText as React.CSSProperties}>{this.transSellerReadyForCollectionDetail("Anderson")}: <span style={webStyle.ArrivingTextValue}>{this.transSellerReadyForCollectionDetail("Arriving in")} 14 {this.transSellerReadyForCollectionDetail("mins")}</span></Typography>
                  <Typography variant="body1" style={webStyle.ArrivingText as React.CSSProperties}>{this.transSellerReadyForCollectionDetail("Pickup OTP")}: <span style={webStyle.ArrivingTextValue}>105698</span></Typography>
                </Box>
                <GroupOtherDetails className="container">
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                      <Typography className="detailTitle">
                        {this.transSellerReadyForCollectionDetail("Store Name")}
                      </Typography>
                      <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="detailTitle">
                      {this.transSellerReadyForCollectionDetail("Customer Name")}
                      </Typography>
                      <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.account}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="detailTitle">
                      {this.transSellerReadyForCollectionDetail("Shipping Address")}
                      </Typography>
                      <Typography className="detailTitleDescription">
                        {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="detailTitle">
                      {this.transSellerReadyForCollectionDetail("Coupon Applied")}
                      </Typography>
                      <Typography className="detailTitleDescription">{this.transSellerReadyForCollectionDetail("Fash")}10(-$5)</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography className="detailTitle">
                      {this.transSellerReadyForCollectionDetail("Payment Method")}
                      </Typography>
                      <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                    </Grid>
                  </Grid>
                </GroupOtherDetails>
                <Drawer anchor={this.handleAnchorLR()} open={this.state.isDrawerStatusOpen} onClose={this.toggleStatusDrawer(false)}>
                  <Box style={webStyle.MapComponentContainer}>
                    <Box style={webStyle.mapHeaderText}>
                      <Box style={webStyle.mapTitleContainerText}>
                        <Typography style={webStyle.trackDriveTitle}>{this.transSellerReadyForCollectionDetail("Order Status")}</Typography>
                        <CloseIcon role="presentation"
                          onClick={this.toggleStatusDrawer(false)}
                          onKeyDown={this.toggleStatusDrawer(false)} />
                      </Box>
                    </Box>
                    <Box style={webStyle.trackContentStatusContainer}>
                     {statusContainerData()}
                    </Box>
                  </Box>

                </Drawer>
                <CustomStyledTable className="container">
                  <Table stickyHeader size="small" className="custom-table">
                    <TableHead>
                      <TableRowCustom style={webStyle.tableHeaderBg}>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Product")}</Typography></TableCell>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Brand")}</Typography></TableCell>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Size")}</Typography></TableCell>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Color")}</Typography></TableCell>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Product Code")}</Typography></TableCell>
                        <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headerText">{this.transSellerReadyForCollectionDetail("Price")}</Typography></TableCell>
                      </TableRowCustom>
                    </TableHead>
                    <TableBody>
                      {orderDetailData?.attributes.order_items?.map((order, index: number) => (
                        <React.Fragment>
                          <TableRow>
                            <TableCell>
                              <Box style={webStyle.productDflex}>
                              {imageNotFound(order.attributes.catalogue_variant_front_image)}
                                <Box style={{ ...webStyle.tableHaderrowReady }}><Typography className="sr-fc-table-body-typo">{order.attributes.catalogue_name}</Typography></Box>
                              </Box>
                            </TableCell>
                            <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="sr-fc-table-body-typo">{order.attributes.brand_name}</Typography></TableCell>
                            <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="sr-fc-table-body-typo">{order.attributes.catalogue_variant_size}</Typography></TableCell>
                            <TableCell style={{ ...webStyle.tableHaderrowReady }}><Box style={webStyle.tableVarientColorMainBoxReady}>
                              <Box style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: order.attributes.catalogue_variant_color }} /> <Typography className="sr-fc-table-body-typo">{order.attributes.catalogue_variant_color}</Typography>
                            </Box></TableCell>
                            <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="sr-fc-table-body-typo">{order.attributes.catalogue_variant_sku}</Typography></TableCell>
                            <TableCell style={{ ...webStyle.tableHaderrowReady }}><Typography className="sr-fc-table-body-typo">{order.attributes.currency_logo} {order.attributes.total_price}</Typography></TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </CustomStyledTable>
              </Box></>
            }
          </SellerStylishContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  fontFamily: "Lato",
  MapComponentContainer: {
    width: 440,
    height: "100%"
  },
  MapComponentHeight: {
    height: "100%"
  },
  mapHeaderText: {
    padding: 25
  },
  containerElementHeight: {
    height: "450px"
  },
  stepTitle: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#375280",
  },
  stepOpacityTitle: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#94A3B8",
  },
  stepDate: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#B2A69B",
  },
  stepDescription: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#375280",
    marginTop: "10px"
  },
  stepOpacityDescription: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    marginTop: "10px",
    color: "#CBD5E1"
  },
  stepStatusDescription: {
    fontSize: "18px",
    fontFamily: "Lato",
    fontWeight: 400,
    color: "#808080",
    marginTop: "10px"
  },
  trackDriveTitle: {
    fontSize: "24px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#375280",
  },
  tableHaderReady: {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 700,
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px",
  },
  tableHaderrowReady: {
    fontSize: '14px',
    color: "#375280",
    fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', height: '14px', borderRadius: '10px',
  },
  tableVarientColorMainBoxReady: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  productDflex: {
    display: "flex",
    gap: 10
  },
  productIMg: {
    width: '34px',
    height: '34px',
    borderRadius: '2px',
    marginBottom: "12px",
  },
  trackStatusTitleMB: {
    marginBottom: 30
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingCell2: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  ArrivingCell3: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
  },
  trackContentContainer: {
    padding: 25
  },
  trackContentTrackContainer: {
    marginTop: "30px"
  },
  trackContentStatusContainer: {
    padding: '10px 25px 80px 25px'
  },
  ArrivingCell4: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px"
  },
  mapTitleContainerText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mapBtnAlign: {
    borderRadius: "3px",
    backgroundColor: "#CCBEB1",
    padding: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  otpMapTitle: {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Lato",
    color: "#ffffff",
    lineHeight: "24px"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  itemIdTextDescription: {
    color: "#9A9A9A",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "32px"
  }
}
const GroupOtherDetails = styled(Box)({
  border: '1px solid #E2E8F0',
  borderRadius: "5px 5px 0px 0px",
  padding: "24px",
  margin: "25px 0px",
  "& .detailTitle": {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .detailTitleDescription": {
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "10px"
  }
})
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .sr-fc-table-body-typo": {
    fontSize: '14px',
    color: "#375280",
    fontWeight: 400,
    font: "Lato",
    display: "flex"
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToCollectionStatusBtn": {
    width: "147px",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FEF3C7",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const GroupDetailBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  fontFamily: "Lato",
  flexWrap: "wrap",
  "& .GroupDetailBoxLeft": {
    display: "flex",
    alignItems: "center",
    gap: 20,
    flexWrap: "wrap"
  },
  "& .itemIdText": {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
  },
  "& .readyForStatusButton": {
    color: "rgb(55, 82, 128)",
    width: "150px",
    border: "1px solid rgb(204, 190, 177)",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .readyForTrackButton": {
    color: "#ffffff",
    width: "150px",
    border: "none",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#CCBEB1",
    textTransform: "none",
    marginLeft: "10px"
  },
  "& .radyToCollectionStatusTopBtn": {
    color: "#D97706",
    width: "147px",
    border: "none",
    height: "30px",
    fontWeight: 500,
    textAlign: "center",
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#FEF3C7",
    textTransform: "none",
  }
})

const TableRowCustom = styled(TableRow)(() => ({
  "& .headerText": {
    fontFamily: "Lato",
    display: "flex",
      "@media only screen and (max-width: 900px)": {
          fontSize: "15px",
          fontFamily: "Lato",
        }
  }
}))
// Customizable Area End
