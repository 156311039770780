import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  styled,
  Tooltip,
  Box,
  Typography,
  Paper,
} from "@material-ui/core";
import StylistProductSourcingChatController, {
  Props,
} from "./StylistProductSourcingChatController";
import { ThemeProvider } from "react-native-elements";
import { TitleContainer } from "./StylishProductSourcing.web";
import { backBtnIcon } from "../../../customform/src/assets";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
import Loader from "../../../../components/src/LoaderContainer";
import { ListContainer } from "./StylishMyOffer.web";
import OneToOneChat from "../../../chat/src/OneToOneChat.web";
import i18n from "../../../../components/src/i18next/i18n";
import StylishContainer from "../../../../components/src/SellerStylish/SellerStylishContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    },
  },
});
// Customizable Area End

export default class StylistProductSourcingChat extends StylistProductSourcingChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <StylishContainer role="Stylish" navigation={this.props.navigation}>
            <StylishProductSourceChatContainer>
              <TitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-chat-test-id"
                  alt="back-Icon"
                  style={{transform: i18n.dir()==="ltr"? "rotate(0deg)": "rotate(180deg)"}}
                  onClick={() =>
                    this.handleStylishProductSourcingChatRedirect(
                      "StylishProductSourcingRequest"
                    )
                  }
                />
                <Typography className="title" id="chatBackTitle">
                  {this.transStylishProductSourcingChat("Product Sourcing Requests")}
                </Typography>
              </TitleContainer>
              {this.state.isLoading ? (
                <Box className="loader-container" id="chat-loader">
                  <Loader
                    id="product-sourcing-chat-loader"
                    dataTestId="product-sourcing-chat-loader-test"
                  />
                </Box>
              ) : (
                  <Box className="mainContainer">
                    <Box maxWidth={"800px"} width={'100%'} mr={3}>
                    {this.state.bidRequestChatResp?.id && (
                      <MainOfferChatContainer id="PrdchatContainer">
                        <ListContainer
                          data-test-id={`list01-${this.state.bidRequestChatResp.id}`}
                        >
                          <Box className="img-pro-sourcing-request-container" id="img-chat-container">
                            <img
                              className="image"
                              src={
                                this.state.bidRequestChatResp.attributes
                                  .product_sourcing_request.images[0].url
                              }
                              alt={`img01-${this.state.bidRequestChatResp.attributes.product_sourcing_request.images[0].id}`}
                            />
                          </Box>
                          <Box
                            style={{ height: 110 }}
                            className="list-right-part"
                            id="right-part"
                          >
                            <Box className="top-container" id="topContainer">
                              <Box>
                                <Tooltip
                                  id="tooltiptext"
                                  title={
                                    this.state.bidRequestChatResp.attributes
                                      .product_sourcing_request.product_name
                                  }
                                >
                                  <Typography
                                    style={{ fontWeight: 500 }}
                                    className="text-title"
                                  >
                                    {
                                      this.state.bidRequestChatResp.attributes
                                        .product_sourcing_request.product_name
                                    }
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                className={`container-${this.state.bidRequestChatResp.attributes.request_status}`}
                              >
                                <Typography
                                  className={`status-${this.state.bidRequestChatResp.attributes.request_status}`}
                                  style={{ fontSize: 14, fontWeight: 700 }}
                                >
                                  {
                                    this.transStylishProductSourcingChat(this.state.bidRequestChatResp.attributes
                                      .request_status)
                                  }
                                </Typography>
                              </Box>
                            </Box>
                            <BottomContainer>
                              <Typography className="price-range" id="priceRange">
                                $
                                {
                                  +this.state.bidRequestChatResp.attributes
                                    .product_sourcing_request.min_price
                                }{" "}
                                - $
                                {
                                  +this.state.bidRequestChatResp.attributes
                                    .product_sourcing_request.max_price
                                }
                              </Typography>
                            </BottomContainer>
                          </Box>
                        </ListContainer>
                        <Box className="list-chat-display">
                          <Box className="row-chat-container">
                            <Box className="description-chat-data">
                              <Typography className="description-chat-name">
                                {this.transStylishProductSourcingChat("Sizes")} -{" "}
                              </Typography>
                              <Typography className="description-chat-value">
                                {this.state.bidRequestChatResp.attributes.product_sourcing_request.sizes.join(
                                  " ,"
                                )}
                              </Typography>
                            </Box>
                            <Box className="description-chat-data">
                              <Typography className="description-chat-name">
                              {this.transStylishProductSourcingChat("Quantity")} -{" "}
                              </Typography>
                              <Typography className="description-chat-value">
                                {
                                  this.state.bidRequestChatResp.attributes
                                    .product_sourcing_request.quantity
                                }{" "}
                                {this.transStylishProductSourcingChat("Units")}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="row-chat-container">
                            <Box className="description-chat-data">
                              <Typography className="description-chat-name">
                              {this.transStylishProductSourcingChat("Colours")} -{" "}
                              </Typography>
                              <Typography className="description-chat-value">
                                {this.state.bidRequestChatResp.attributes.product_sourcing_request.colours.join(
                                  " ,"
                                )}
                              </Typography>
                            </Box>
                            <Box className="description-chat-data">
                              <Typography className="description-chat-name">
                              {this.transStylishProductSourcingChat("Gender")} -{" "}
                              </Typography>
                              <Typography className="description-chat-value">
                                {
                                  this.state.bidRequestChatResp.attributes
                                    .product_sourcing_request.gender
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="row-chat-container">
                            <Box className="description-chat-data">
                              <Typography
                                className="description-chat-name"
                                style={{ marginBottom: 16 }}
                              >
                                {
                                  this.state.bidRequestChatResp.attributes
                                    .product_sourcing_request.description
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </MainOfferChatContainer>
                    )}
                    </Box>
                    <Box maxWidth={"856px"}  width={'100%'}>
                    {this.state.bidRequestChatResp?.attributes?.product_sourcing_request?.account_id && 
                      <OneToOneChat
                        navigation={this.props.navigation}
                        receiver_id={ this.state.bidRequestChatResp.attributes.product_sourcing_request.account_id}
                      />
                    }
                    </Box>
                  </Box>
              )}
            </StylishProductSourceChatContainer>
        </StylishContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={()=>{}}
          severity={this.state.severity}
          dataTestId="alertChatTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const BottomContainer = styled(Box)({
  display: "flex",
  width: "100%",
  "& .price-range": {
    fontFamily: "Lato, sans-serif",
    marginBottom: 24,
    color: "#375280",
    width: "100%",
    fontWeight: 700,
    fontSize: 18,
  },
});
const StylishProductSourceChatContainer = styled(Box)({
  minHeight: "calc(100vh - 85px)",
  padding: "30px",
  "& .requester-detail": {
    marginTop: 32,
    "& .offer": {
      display: "flex",
      width: "100%",
      padding: 16,
      "& .request-price-container": {
      flexDirection: "column",
        justifyContent: "space-between",
        display: "flex",
        width: "100%",
      },
      "& .title-container": {
        fontSize: 18,
        fontWeight: 700,
        color: "#375280",
        fontFamily: "Lato, sans-serif",
      },
      "& .requestor-description": {
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "Lato, sans-serif",
        color: "#94A3B8",
        width: "100%",
        marginTop: 10,
      },
    },
  },
  "& .loader-container": {
    width: "100%",
    minHeight: 375,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .preview-container": {
    margin: "16px 0px 20px 0px",
    display: "flex",
    gap: 12,
    flexWrap: "wrap",
    "& .file-preview": {
      position: "relative",
      "& .preview-image": {
        width: 100,
        objectFit: "cover",
        height: 100,
        borderRadius: 2,
      },
    },
  },
  "& .mainContainer":{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "15px",
    flexWarp: "nowrap",
    "@media(max-width:1100px)": {
      flexWrap: "wrap",
    },
  }
});

const MainOfferChatContainer = styled(Paper)({
  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  padding: 16,
  minWidth: "200px",
  "& .list-chat-display": {
    gap: 12,
    flexDirection: "column",
    display: "flex",
    paddingTop: 16,
    "& .row-chat-container": {
      justifyContent: "space-between",
      display: "flex",
      gap: "10px",
      flexWrap: "wrap",
      "@media(max-width:1040px)": {
        flexDirection: "column",
      },
      "& .description-chat-data": {
        display: "flex",
        flexWrap: "nowrap",
        "& .description-chat-name": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 16,
          color: "#375280",
        },
        "& .description-chat-value": {
          fontFamily: "Lato, sans-serif",
          fontSize: 16,
          fontWeight: 900,
          color: "#375280",
          paddingLeft: 4,
        },
      },
    },
  },
});
// Customizable Area End
