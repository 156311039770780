import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Card
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll, view } from "./assets";
import { Noimageavailable, image_Likepng } from "../../productdescription3/src/assets";
import { close } from "../../customisableuserprofiles2/src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const configJSON = require("./config");
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import WishlistController, {
  Props,
} from "./WishlistController.web";
import { ConnectorProps } from "../../ordermanagement/src/OrderDetails.web";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"
import { image_Like, viewLike } from "../../categoriessubcategories/src/assets";

export default class Wishlist extends WishlistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleFavouriteClick = (product: any) => {
    const isFavourite = product.attributes.is_wishlist;
    this.handleFavouriteApi(product.id, isFavourite);
  };
  renderWishlistContent() {

    if (this.state.wishlistData.length === 0) {
      return (
        <div style={{ display: "grid", placeItems: "center" }}>
          <div style={webStyle.innerContainer6 as React.CSSProperties}>
            <div>
              <div style={webStyle.innerContainer5}>
                <img src={view} alt="View" />
              </div>
              <div style={{
                fontSize: '34px',
                fontWeight: 700, marginBottom: "14px", marginTop: "39px", color: "#3C4242", fontFamily: "Lato , sans-serif",
              }}>
                {this.tranWishlist("Empty Wishlist Text")}
              </div>

              <div
                style={{
                  ...webStyle.innerContainer7,
                  width: containerWidth,
                }}
              >

                {this.tranWishlist("Empty Wishlist Message")}
              </div>
              <Button onClick={this.contioneShopping} style={webStyle.innerContainer8 as React.CSSProperties} data-test-id="continueShopping">
                {this.tranWishlist("Continue Shopping")}
              </Button>
            </div>
          </div>
        </div >
      );
    } else {
      return (
        <WishListCotainer data-test-id="language-translate-test-id" direction={i18n.dir()}>
          <div style={{ color: "#375280", fontSize: "28px", fontWeight: 700, fontFamily: "Lato , sans-serif", marginTop: "85px", marginBottom: "50px" }}>
            {this.tranWishlist("Wishlist")}
          </div>
          <div className="mainContainer">
            {this.state.wishlistData.map((item: { id: React.Key | undefined; attributes: { favouriteable_id: string; favouriteable: { data: { id: string; attributes: { primary_image: string | undefined; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; primary_colour: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; primary_price: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; }; }; }; }; }) => (
              <div key={item.id}>
                <div style={{ display: "flex", alignItems: "center", padding: "0px 20px 0px 24px", justifyContent: "space-between", }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "36px" }}>
                    <img
                      src={close}
                      data-test-id="Deletefavourites"
                      onClick={() => this.Deletefavourite(item.attributes.favouriteable_id ? item.attributes.favouriteable_id : null)}
                      style={{ width: "22px", height: "22px", cursor: "pointer" }}
                      alt="Close"
                    />
                    <img data-test-id={`description-test-id-${item.id}`} src={item.attributes.favouriteable?.data?.attributes?.primary_image ? item.attributes.favouriteable?.data?.attributes?.primary_image : Noimageavailable} onClick={() => this.navigateTo({ productId: item.attributes.favouriteable_id, props: this.props, screenName: "Productdescription3" })} style={{ width: "110px", height: "110px", borderRadius: "2px" }} alt="Product" />
                    <div style={{ color: "#375280", fontSize: "22px", fontWeight: 700, fontFamily: "Lato , sans-serif" }}>
                      <p>{item.attributes.favouriteable.data?.attributes.name}</p>
                      <p style={{ marginTop: "-10px" }}>{this.tranWishlist("Color")}: <span style={{ color: "#94A3B8" }}>{item.attributes.favouriteable?.data?.attributes?.primary_colour?.name}</span></p>
                      <p
                        style={{ marginTop: "-10px" }}>{this.tranWishlist("Quantity")}: <span style={{ color: "#94A3B8" }}>1</span></p>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "19px" }}>
                    <div style={{ color: "#375280", fontSize: "22px", fontWeight: 700, fontFamily: "Lato , sans-serif" }}>{this.state.currencySign} {item.attributes.favouriteable?.data?.attributes?.primary_price}</div>
                    <div data-test-id={`move-to-cart-test-id-${item.id}`} onClick={() => this.navigateTo({ productId: item.attributes.favouriteable_id, props: this.props, screenName: "Productdescription3" })} style={{ fontSize: "18px", fontWeight: 700, color: "#375280", border: "1px solid #CCBEB1", borderRadius: 5, padding: "14px 28px", cursor: "pointer", fontFamily: "Lato" }}>
                      {this.tranWishlist("Move To Cart")}
                    </div>
                  </div>
                </div>
                <hr style={{ color: "#E2E8F0" }} />
              </div>
            ))}
          </div>
        </WishListCotainer>
      );
    }
  }
  // Customizable Area End
  handleImgCSS = () => {
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  navHeaderMargin = () => {
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  welcomeCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }

  handleCondition = (condition: boolean, trueStat: string, falseStat: string | undefined) => {
    return condition ? trueStat : falseStat
  }

   NavigationHeader = () => (
    <div style={{ ...webStyle.innerContainer, margin: this.navHeaderMargin() }}>
      <Typography style={webStyle.innerContainer1} onClick={this.contioneShopping}>
        {this.tranWishlist("Home")}
      </Typography>
      <img src={Vector} style={{ transform: this.handleImgCSS() }} />
      <Typography style={webStyle.innerContainer2}>{this.tranWishlist("Wishlist")}</Typography>
    </div>
  );

   DrawerToggle = () => (
    <div style={{ marginLeft: window.innerWidth >= 768 ? "66%" : "42%", marginTop: window.innerWidth >= 768 ? "5%" : "7%" }}>
      <MoreVertIcon
        data-test-id="drawerOpenMoreVertIcon"
        onClick={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
      />
    </div>
  );

   DrawerContent = () => (
    <BuyerDrawer
      navigation={this.props.navigation}
      data-test-id="drawerClose"
      open={this.state.isDrawerOpen}
      onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
    >
      <div>
        <Typography
          style={{
            margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "31px 40px 0 0",
            ...webStyle.innerContainer3,
          }}
          dir={i18n.dir()}
        >
          <img src={scroll} alt="Scroll" />
          {this.state.fullName}
        </Typography>
        <Typography
          style={{
            margin: i18n.dir() === "ltr" ? "0 0 40px 37px" : "0 40px 37px 0",
            ...webStyle.innerContainer4,
          }}
          dir={i18n.dir()}
        >
          {this.transProfile(configJSON.WelcomeAccountMsg)}
        </Typography>
      </div>
      <Sidebar navigation={this.props.navigation} data-test-id="mobilesidebar" />
    </BuyerDrawer>
  );

   LoadingIndicator = (): false | JSX.Element | any => {
    return this.state.favLoading ? (
      <Box
        style={{
          textAlign: "center",
          paddingBottom: "20px",
          position: "absolute",
          top: "62%",
          left: "50%",
          width: "54px",
          backgroundColor: "#FFFFFF",
          height: "36px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ color: "#375280", marginTop: "20px" }} />
      </Box>
    ) : false;
  };


  MobileView = () => {
    if (window.innerWidth >= 951) return null;
    return (
      <>
        <Grid item style={{ display: "flex", height: 117 }}>
          {this.NavigationHeader()}
          {this.DrawerToggle()}
          {this.DrawerContent()}
        </Grid>
        <Grid item style={{ display: "grid", width: "-webkit-fill-available" }}>
          {this.renderWishlistContent()}
          {this.LoadingIndicator()}
        </Grid>
      </>
    );
  };

  DesktopView = () => {
    if (window.innerWidth < 951) return null;

    return (
      <>
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px", display: "flex" }} dir={i18n.dir()}>
          <Grid item>
            {this.NavigationHeader ()}
            <Typography style={{ ...webStyle.innerContainer3, margin: this.nameCSS() }}>
              <img src={scroll} />
              {this.state.fullName}
            </Typography>
            <Typography style={{ ...webStyle.innerContainer4, margin: this.welcomeCSS() }}>
              {this.tranWishlist("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation} />
          </Grid>
          <Grid item style={{ display: "grid", width: "-webkit-fill-available" }}>
            {this.renderWishlistContent()}
            {this.LoadingIndicator()}
          </Grid>
        </Grid>
      </>
    );
  };

  render() {


    return (
      // Customizable Area Start
      <>
        {this.state.stylishbuyer ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />}
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} dir={i18n.dir()}>
          <Grid item style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            {this.MobileView()}
            {this.DesktopView()}
          </Grid>
        </Grid>
        <div
          style={{
            fontSize: "28px", fontWeight: 700, color: "#375280", display: "flex", justifyContent: "center", padding: "30px 30px 10px", fontFamily: "Lato , sans-serif",
          }}>{this.tranWishlist("You May Also Like")}</div>
        <hr style={{ width: 100,
    height: 4,
    background: "#CCBEB1", border: 0, color: "#CCBEB1", marginTop: "15px", marginBottom: "45px" }} />
        <Box style={{ marginBottom: '78px', display: 'flex', flexWrap: 'nowrap' }}>
          <Grid container spacing={4} justifyContent="center">
            {this.state.products.map((product, index: number) => (<>
              <Grid item key={index}>
                <Box style={{ cursor: 'pointer' }} >
                  <Card style={webStyle.productBoxproductd}>
                    <Box style={{ position: 'relative' }}>
                      <img
                        style={{ position: 'relative' }}
                        width="100%" height="370px"
                        src={this.handleCondition(product.attributes.primary_image === null, Noimageavailable, product.attributes.primary_image)}
                        alt="Product"
                        data-test-id={`navigateTo=${product.id}`}
                        onClick={() => this.navigateTo({ productId: product.id, props: this.props, screenName: "Productdescription3" })}
                      />
                      <Box style={{ ...webStyle.productIconproductd, position: 'absolute', flexDirection: i18n.dir() === 'ltr' ? "row" : "row-reverse", right: i18n.dir() === 'ltr' ? 0 : 18 }}>
                        <Box style={webStyle.productDiscountproductd}>15% Off</Box>

                        <Box
                          style={webStyle.productFavorite}
                          data-test-id="handleLikeClick"
                          onClick={() => this.handleFavouriteClick(product)}
                        >
                          {product.attributes.is_wishlist ? (
                            <img src={viewLike} width="18px" height="18px" style={{cursor:"pointer"}}  alt="clickSearchAlsoLike" />
                          ) : (
                            <img src={image_Like} width="18px" height="18px" style={{cursor:"pointer"}}  alt="likedSearchAlsoLike" />
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ padding: '24px', backgroundColor: '#F8F8F8' }} data-testid="alertTestId">
                      <Box style={{ ...webStyle.productDataproductd, whiteSpace: 'nowrap', flexDirection: i18n.dir() === 'ltr' ? 'row' : 'row-reverse' }}>
                        <Box style={{ ...webStyle.productValueproductd, whiteSpace: 'nowrap' }}>
                          <Typography style={{ ...webStyle.productNameproductd, whiteSpace: 'nowrap', display: "flex", justifyContent: i18n.dir() === 'ltr' ? 'flex-start' : 'flex-end' }} >{product.attributes.name}</Typography>
                          <Typography style={{ ...webStyle.productDescriptionproductd, whiteSpace: 'nowrap', display: "flex", justifyContent: i18n.dir() === 'ltr' ? 'flex-start' : 'flex-end' }} >{product.attributes.description}</Typography>
                        </Box>
                        <Box style={{ ...webStyle.acountMrpproductd, textAlign: 'center' }}>{this.state.currencySign}&nbsp;{`${product.attributes.primary_price}`}</Box>
                      </Box>
                    </Box>
                  </Card>
                </Box>
              </Grid>
            </>))}
          </Grid>

        </Box>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}

          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>

        <Footer navigation={this.props.navigation} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const containerWidth =
  window.innerWidth >= 768
    ? i18n.dir() === "ltr"
      ? "465px"
      : "457px"
    : "350px";

    const isWideScreen = window.innerWidth >= 768;
const isMediumScreen = window.innerWidth > 400;
const isLargeWidth = window.innerWidth >= 700;

const containerWidth1 = isLargeWidth ? "690px" : "350px";
const containerHeight = isWideScreen ? "498px" : "450px";
const containerMarginLeft = isWideScreen ? "2.5rem" : isMediumScreen ? "2rem" : "0.8rem";


const webStyle = {
  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px"
  },
  productBoxproductd: { boxShadow: 'none', borderRadius: "0px, 0px, 2px, 2px", width: "282px", marginBottom: "20px" },
  productIconproductd: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between' },
  acountMrpproductd: { fontFamily: "Lato", display: "flex", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18px', fontWeight: 700, backgroundColor: '#FFFFFF', padding: '10px 10.5px', borderRadius: '8px', width: '73px' },
  productDescriptionproductd: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productNameproductd: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: '90%', fontFamily: "Lato" },
  productFavorite: { color: "#375280", marginRight: '30px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productValueproductd: { height: '50px', lineHeight: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 2 auto' },
  productDataproductd: { width: '100%', display: 'flex', margin: '0', padding: '0', height: '50px', overflow: 'hidden', textOverflow: 'ellipsis', flex: '1 1 33%', verticalAlign: 'middle' },
  productFavoriteproductd: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productDiscountproductd: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', width: "56px", height: '17px', display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "Lato", fontWeight: 700 },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: "pointer"
  },
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569",
    fontWeight: 700,
    cursor: "pointer"
  },
  innerContainer3: {
    fontFamily: "Lato , sans-serif",
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 800,
    color: "#375280",
    display: "flex",
    alignItems: "center",
    gap: "15px"
  },
  innerContainer4: {
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
  },
  innerContainer5: {
    borderRadius: "50%",
    backgroundColor: "#CCBEB121",
    width: "170px",
    height: "170px",
    alignItems: "center",
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  innerContainer6: {
    boxShadow: "2px 2px 4px 0px #0000000D, -2px -2px 4px 0px #0000000D",
    width: containerWidth1,
    marginLeft: containerMarginLeft,
    height: containerHeight,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  },
  innerContainer7: {
    color: '#807D7E',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'Lato , sans-serif',
  },
  innerContainer8: {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    fontSize: "18px",
    padding: "10px 16px 10px 16px",
    marginTop: "48px",
    height: "64px",
    color: "white",
    textTransform: "none",
    fontWeight: 800,
    width: window.innerWidth >= 768 ? "365px" : "350px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer9: {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    fontSize: "18px",
    padding: "14px 28px 14px 28px",
    height: "50px",
    color: "white",
    textTransform: "none",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
  },
  productIcon1: { top: '20px', left: '18px', display: "flex", justifyContent: 'space-between', right: '0' },
  productDiscount1: { color: "#375280", fontSize: '12px', backgroundColor: '#FFFFFF', padding: '2px 5px', borderRadius: '2px', height: '17px' },
  productFavorite1: { color: "#375280", marginRight: '20px', fontSize: '12px', backgroundColor: '#FFFFFF', padding: '5px', borderRadius: '20px', display: 'flex', alignContent: 'center' },
  productName1: { color: '#375280', fontSize: '16px', fontWeight: 700, textOverflow: "ellipsis", overflow: "hidden", width: "70%" },
  productDescription1: { color: '#375280', fontSize: '14px', lineHeight: '16.8px', fontWeight: 500, width: "70%" },
  acountMrp: { color: '#375280', fontSize: '14px', fontWeight: 700, backgroundColor: '#F8F8F8', padding: '10px 14px', borderRadius: '8px', width: '73px' },

};
const WishListCotainer = styled("div")({
  margin: (props: ConnectorProps) => (props.direction === 'ltr' ? "0px 0px 0px 130px" : "0px 130px 0px 0px"),
  "@media only screen and (max-width: 960px)": {
    margin: (props: ConnectorProps) => (props.direction === 'ltr' ? "0px 0px 0px 30px" : "0px 30px 0px 0px"),
    width: "100%"
  },
  "& .mainContainer": {
    height: "700px", 
    overflowY: "auto",
    scrollbarWidth: "none",
    width: "85%",
    "@media only screen and (max-width: 960px)": {
      width: "90%",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    msOverflowStyle: "none",
  }
});


// Customizable Area End
