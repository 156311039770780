import { ApiKey } from "../APICall";

export type LocationDetails = {
 city: string;
 postalCode: string;
 area: string;
 formattedAddress: string;
 houseNumber: string;
 blockNumber: string
};

export async function getLocationDetails(lat: number, lng: number): Promise<LocationDetails> {
 const geocoder = new window.google.maps.Geocoder();
 const location = { lat, lng };

 const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
   geocoder.geocode({ location }, (results, status) => {
     if (status === "OK" && results && Array.isArray(results) && results.length > 0) {
       resolve(results);
     } else {
       reject(`Geocoding failed: ${status}`);
     }
   });
 }).catch(() => null);

 if (!results || results.length === 0) {
   return { city: "", postalCode: "", area: "", formattedAddress: "", houseNumber: "", blockNumber: "" };
 }

 const addressComponents = results[0]?.address_components;
 const formattedAddress = results[0]?.formatted_address;

 let postalCode = "";
 let city = "";
 let area = "";
 let houseNumber = "";
 let blockNumber = ""

 addressComponents.forEach((component) => {
   if (component.types.includes("postal_code")) {
     postalCode = component.long_name;
   }
   if (component.types.includes("locality")) {
     city = component.long_name;
   }
   if (
     component.types.includes("sublocality") ||
     component.types.includes("sublocality_level_1")
   ) {
     area = component.long_name;
   }
   if (component.types.includes("street_number")) {
    houseNumber = component.long_name;
   }
   if (component.types.includes("street_address") || component.types.includes("premise")) {
     blockNumber = component.long_name;
   }
 });

 return { city, postalCode, area, formattedAddress, houseNumber, blockNumber };
}

export async function getLatLngFromAddress(details: {
  city: string;
  postalCode: string;
  formattedAddress: string;
  area?: string;
}): Promise<{ lat: number; lng: number } | null> {
  const geocoder = new window.google.maps.Geocoder();

  if (!details.city || !details.postalCode || !details.formattedAddress) {
    throw new Error("City, postalCode, and formattedAddress are mandatory");
  }

  const addressParts = [
    details.area,
    details.city,
    details.postalCode,
    details.formattedAddress,
  ];
  const address = addressParts.filter(Boolean).join(", ");

  const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results && results.length > 0) {
        resolve(results);
      } else {
        reject(`Geocoding failed: ${status}`);
      }
    });
  }).catch(() => null);

  if (!results || results.length === 0) {
    return { lat: 0, lng: 0} ;
  }

  const location = results[0]?.geometry?.location;
  return { lat: location.lat(), lng: location.lng() };
}

export const getFormattedAddress = async (lat: number, lng: number): Promise<string> => {
  if (!lat || !lng) {
    return "";
  }
  const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${ApiKey()}`;
  try {
    const request = new Request(apiUrl, { method: "GET" });
    const response = await (await globalThis.fetch(request)).json();

    if (response.status === "OK" && response.results.length) {
      return response.results[0].formatted_address;
    } else {
      console.error("Error fetching address:", response.status);
      return "";
    }
  } catch (error) {
    console.error("Geocoding API error:", error);
    return "";
  }
};

export const addLatLngToUrl = (url: string, lat?: number, lng?: number): string => {
  if (!lat  || !lng) {
    return url ?? "";
  }

  const hasParams = url.includes("?"); 
  const separator = hasParams ? "&" : "?";
  return `${url}${separator}latitude=${lat}&longitude=${lng}`;
};


export const checkIfKuwait = async (latitude: number | null, longitude: number | null): Promise<boolean> => {
  const geocoder = new window.google.maps.Geocoder();
  if (latitude === null || longitude === null) {
    return false;
  }
  const latLng = new window.google.maps.LatLng(latitude, longitude);

  try {
    const results = await new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK" && results && results.length > 0) {
          resolve(results);
        } else {
          reject("Geocoder failed or no results found");
        }
      });
    });

    const country = results[0].address_components.find(component =>
      component.types.includes("country")
    );
    return country?.short_name === "KW";
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const loadGoogleAPIs = (handleScriptLoadAddress : ()=> void) => {
  const script = document.createElement("script");
  script.id = "google-maps-script";
  script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
  script.async = true;
  script.onload = handleScriptLoadAddress;
  document.body.appendChild(script);
}



