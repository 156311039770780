import { initializeApp, FirebaseError } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  AuthProvider,
  signOut,
  signInWithPopup,
  User,
} from "firebase/auth";
import { IAppleLoginResponse } from "./AppleLogin/AppleLogin";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,

};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: "select_account" });
const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ prompt: "select_account" });
const githubProvider = new OAuthProvider("github.com");
githubProvider.setCustomParameters({ prompt: "select_account" });
const microsoftProvider = new OAuthProvider("microsoft.com");
microsoftProvider.setCustomParameters({ prompt: "select_account" });
const appleProvider = new OAuthProvider("apple.com");
appleProvider.setCustomParameters({ prompt: "select_account" });
appleProvider.addScope("email");
appleProvider.addScope("name");

export {
  auth,
  googleProvider,
  githubProvider,
  facebookProvider,
  microsoftProvider,
  appleProvider,
};

export const getAccessToken = (
  provider: AuthProvider,
  result: UserCredential
): string | null => {
  if (provider instanceof GoogleAuthProvider) {
    return GoogleAuthProvider.credentialFromResult(result)?.accessToken ?? null;
  } else if (provider instanceof FacebookAuthProvider) {
    return (
      FacebookAuthProvider.credentialFromResult(result)?.accessToken ?? null
    );
  } else if (provider instanceof OAuthProvider) {
    return OAuthProvider.credentialFromResult(result)?.accessToken ?? null;
  }
  return null;
};

export const handleAuthError = (error: unknown, providerName: string) => {
  if (error instanceof FirebaseError) {
    switch (error.code) {
      case "auth/cancelled-popup-request":
        console.log(
          `Popup was cancelled before completing Social login. Provider Name ==> ${providerName}`
        );
        break;
      case "auth/popup-closed-by-user":
        console.log(
          `Popup was closed before completing Social login. Provider Name ==> ${providerName}`
        );
        break;
      case "auth/invalid-credential":
        console.error(
          `Invalid credential provided. Please check your configuration. Provider Name ==> ${providerName}`
        );
        break;
      default:
        console.error(
          `Firebase Error during login Provider Name ==> ${providerName}: ${error.message}`
        );
    }
  } else {
    console.error(`An unexpected error occurred`, error);
  }
};

export const handleSignIn = async (
  provider: GoogleAuthProvider | FacebookAuthProvider | OAuthProvider,
  providerName: string,
  onSuccess: (user: IAppleLoginResponse) => void,
) => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const idToken = await user.getIdToken();

    onSuccess({accessToken: idToken, email: user.email, displayName: user.displayName})
  } catch (error: unknown) {
    if (error instanceof FirebaseError) {
      handleAuthError(error, providerName);
    } else {
      console.error("Unexpected error:", error);
    }
  }
};

export const handleLogout = async () => {
  try {
    await signOut(auth);
  } catch (e) {
    console.error("Error during logout:", e);
  }
};
