import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Modal,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  TextField,
  Grid, styled,
  Typography,
  Checkbox,
  Dialog, DialogContent,DialogActions
} from "@material-ui/core";
import { CheckBox, CheckBoxTwoTone } from "@material-ui/icons";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff"
    }
  },
  typography: {
    h6: {},
    subtitle1: {
      margin: "20px 0px"
    }
  }
});
import { ImageListType } from "./types";
// Customizable Area End

import PhotoLibraryController, {
  Props,
  configJSON
} from "./PhotoLibraryController";

// Customizable Area Start
import StylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp';
import CheckIcon from '@material-ui/icons/Check';
import Pagination from '@material-ui/lab/Pagination';
import {  deleteIconPng, close, upload } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { storeSuccessIcon } from "../../customform/src/assets";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export default class PhotoLibrary extends PhotoLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderEditTab = () => {
    const isRTL = i18n.dir() === 'rtl';
    return (
      <>
      <Box style={{paddingTop: "35px", display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}}>
      <Box style={{display: "flex", gap: 5, alignItems: "center"}}>
    <StyledCheckbox
      checked={this.state.checkedAll}
      data-test-id="selectAllClick"
      style={{color: this.state.checkedAll ? "#CCBEB1" :"", padding: "0px"}}
      onChange={(event) => this.handleSelectAll(event.target.checked)}
      inputProps={{ 'aria-label': 'checkbox' }}
      icon={<CheckBoxOutlineBlankSharpIcon fontSize="large" style={{color: "#CCBEB1"}} />}
    />
    <Typography style={{fontFamily: "Lato", fontSize: "24px", fontWeight: 700, color: "#375280"}}>{this.transPortfolio("Select All Images")}</Typography>
      </Box>

      <Box style={{ height: "32px", width:"32px", cursor: 'pointer'}}>
        {this.state.checkedAll && <img src={deleteIconPng} data-test-id="deleteDialogOpen" alt="deleteIcon" style={{width: "100%", height: "100%"}} onClick={this.handleDeleteOpen}/> }
      </Box>
    </Box>

    <Box className="image-grid">
  {this.state.portFolioPaginateDataApi?.length > 0 && this.state.portFolioPaginateDataApi?.map((image: any, index: number) => (
    <Box key={index} className="image-item">
      <img src={image.attributes.image_url} style={{width: "100%", height: "100%", objectFit: "cover"}} alt={`Portfolio ${index + 1}`} data-test-id={`Portfolio-${index}`} onClick={()=> this.handleParticularDelete(image)}/>
      {this.state.checkedAll && <StyledCheckbox
      checked={this.state.checkedAll}
      data-test-id= {`selectId-${index}`}
      style={{ padding: "0px", 
        position: "absolute", 
        top: this.state.checkedAll ? "4px": "0px", 
        right: isRTL ? 'unset' : this.state.checkedAll ? '6px' : '0px',
        left: isRTL ? (this.state.checkedAll ? '6px' : '0px') : 'unset',

      }}
      disabled={true}
      inputProps={{ 'aria-label': 'checkbox' }}
      icon={<CheckBoxOutlineBlankSharpIcon fontSize="large" style={{color: "#CCBEB1"}} />}
      checkedIcon={<CheckIcon fontSize="large" style={{color: "white", background: "#CCBEB1", height: "27px", width: "27px", borderRadius: "4px"}} />}
    />}
    </Box>
  ))}
</Box>
  { this.state.portFolioPaginateDataApi?.length === 0 && (
    <Box style={{display: "flex", justifyContent: "center", alignItems: "center", fontFamily: "Lato", fontSize: "20px", fontWeight: 500}}>
      {this.transPortfolio("No Data Found")}
    </Box>
  )}
      </>
    )
  };
  renderUploadTab = () => {
    return (
      <>
      <MultiUploadBoxContainer>
        <Box style={{width: "45%", height: "295px",  display: "flex", flexDirection: "column", alignItems: "center", border: "1px solid #E2E8F0", borderRadius:"4px" }}>
          <Box style={{width: "90%", height: "185px", backgroundColor: '#F8F8F8', marginTop: "16px"}}>
            <Box style={{display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center"}}>
              <Box style={{marginBottom: "10px"}}>
                <img src={upload} alt="uploadImage"/>
              </Box>
              <Typography style={{fontFamily: "Lato", fontSize:"18px", fontWeight: 700, color: "#375280"}}>{this.transPortfolio("Upload Image")}</Typography>
              <Typography style={{fontFamily: "Lato", fontSize:"14px", fontWeight: 400, color: "#94A3B8"}}>{this.transPortfolio("Only png or Jpg files are accepted")}</Typography>
            </Box>
            
            <Box style={{width: "100%", height:"54px", border: "1px solid #CCBEB1", marginTop: "10px",cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center"}} data-test-id={`Portfolio`} onClick={this.handleUploadDelete}>
              <Typography style={{fontFamily: "Lato", fontSize:"20px", fontWeight: 500, color: "#375280"}}>{this.transPortfolio("Upload Image")}</Typography>
            </Box>
          </Box>
        </Box>

        <Box style={{display: "flex", alignItems: "center"}}>
          <Typography style={{color: "#000000", fontFamily: "Lato", fontWeight: 500, fontSize: "20px"}}> {this.transPortfolio("Or")} </Typography>
        </Box>

        <Box style={{width: "45%", height: "295px",  display: "flex", flexDirection: "column", cursor: "pointer",alignItems: "center", border: "1px solid #E2E8F0", borderRadius:"4px" }}>
          <Box style={{width: "90%", height: "185px", backgroundColor: '#F8F8F8', marginTop: "16px"}}>
            <Box style={{display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center"}}>
              <Box style={{marginBottom: "10px"}}>
                <img src={upload} alt="uploadImage"/>
              </Box>
              <Typography style={{fontFamily: "Lato", fontSize:"18px", fontWeight: 700, color: "#375280"}}>{this.transPortfolio("Upload Multiple Images")}</Typography>
              <Typography style={{fontFamily: "Lato", fontSize:"14px", fontWeight: 400, color: "#94A3B8"}}>{this.transPortfolio("Only png or Jpg files are accepted")}</Typography>
            </Box>
            
            <Box style={{width: "100%", height:"54px", border: "1px solid #CCBEB1", marginTop: "10px", display: "flex", justifyContent: "center", alignItems: "center"}} data-test-id="multiple" onClick={this.handleUpload}>
              <Typography style={{fontFamily: "Lato", fontSize:"20px", fontWeight: 500, color: "#375280"}}>{this.transPortfolio("Upload Images")}</Typography>
              <input type="file" multiple accept="image/*" data-test-id="dialogUploadMultiple" onChange={(event) => this.handleFileUploadMultiple(event.target.files)} ref={this.state.inputRef} style={{display: "none"}} />
            </Box>
          </Box>
        </Box>

      </MultiUploadBoxContainer>
        <Box style={{width: "45%",marginTop: 10,  display: "flex", flexDirection: "column"}}>
        <Box>
      </Box>
        </Box>

        <DialogContainer  data-test-id="handleDialog" open={this.state.uploadDelete} onClose={this.handleDeleteUpload} dir={i18n.dir()}>
          <Box className="content-container">
            <Box>
            <Typography style={webStyles.inputHeadingBox1}>{this.transPortfolio("Description")}</Typography>
            <TextField
                placeholder={this.transPortfolio("Enter Description")}
                data-test-id="Description"
                fullWidth
                multiline
                value={this.state.deleteData.imageDescription[0]}
                error={true}
                onChange={(event)=>this.handleDescriptionChange(event.target.value)}
                className={this.generateClassName(this.state.errorsMessage.descriptionErrorMsg, "inputFeild2", "inputFeild2Error2")}
                helperText={this.state.errorsMessage.descriptionErrorMsg ? this.state.errorsMessage.descriptionErrorMsg : ""}
                minRows={3}  
                variant="outlined"  
            />

            </Box>

           <Box className="upload-btn-input">
              <Box style={{position: "relative", display: "flex", height: "100%", justifyContent: "center", alignItems: "center"}}>
              {this.state.imageUpload ? <img  src={this.state.deleteData?.image[0]} alt={this.transPortfolio('upload ui')} style={{height: "100%", width: "100%",backgroundRepeat: "no-repeat",backgroundSize: "cover"}} /> : (
                <>
                <Box style={{display: "flex", flexDirection: "column", cursor: 'pointer', justifyContent: "center", alignItems: "center", backgroundColor: "#F8F8F8"}} data-test-id="handleUploadRef" onClick={this.handleUpload}>
              <Box style={{marginBottom: "10px"}}>
                <img src={upload} alt="uploadImage"/>
              </Box>
              <Typography style={{fontFamily: "Lato", fontSize:"18px", fontWeight: 700, color: "#375280"}}>{this.transPortfolio("Upload Image")}</Typography>
              <Typography style={{fontFamily: "Lato", fontSize:"14px", fontWeight: 400, color: "#94A3B8"}}>{this.transPortfolio("Only png or Jpg files are accepted")}</Typography>
            </Box>
                </>
              )}
              <input type="file" accept="image/*" data-test-id="dialogUpload" onChange={(event) => this.handleFileUpload(event.target.files)} ref={this.state.inputRef} style={{display: "none"}} />

                {this.state.imageUpload && (
                <>
                <Box style={{width: "85%", height: "54px",cursor: "pointer", backgroundColor: "white", position: "absolute", bottom: "27px", left: "8%", display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #CCBEB1", marginTop: "10px"}} onClick={this.handleUpload}>
                  <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, textTransform: "capitalize", color: "#375280"}}>{this.transPortfolio("Replace Image")}</Typography>
                </Box>
                <Box style={{position: "absolute", top: "7px", cursor: 'pointer',
                  right: i18n.dir() === 'rtl' ? 'unset' : '20px',
                  left: i18n.dir() === 'rtl' ? '20px' : 'unset', 
                  backgroundColor: "#FFFFFF", borderRadius: "50%", display: "flex", width: "48px", height: "48px", justifyContent: "center", alignItems: "center"}} data-test-id="handleDeleteinDialog" onClick={this.handleDeleteParticularImage}>
                  <img src={deleteIconPng} alt="deleteIcon" style={{height: "32px", width: "32px"}}/>
                </Box>
                </>
                )}
              </Box>
            </Box>

                <Box style={{display: "flex", gap: "10%"}}>
            <Button  data-test-id="handleDeleteClose" style={{width: "45%", height: "64px", backgroundColor: "#FFFFFF", border: "1px solid #CCBEB1", textTransform: "capitalize"}} onClick={this.handleDeleteUpload} ><Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 500, color: "#375280"}}>{this.transPortfolio("Cancel")}</Typography></Button>
            <Button  data-test-id="handleConfirmUpload" style={{width: "45%", height: "64px", backgroundColor: "#CCBEB1", border: "1px solid #CCBEB1", textTransform: "capitalize"}} data-testID="singleUpload" onClick={this.handleSingleUpload}><Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 800, color: "#FFFFFF"}}>{this.transPortfolio("Submit")}</Typography></Button>
            </Box>
            </Box>
        </DialogContainer>
      </>
    )
  }
  renderUploadListing = () => {
    return(
      <ListingUi>
        <Box style={{display: "flex"}}>
        <Box className="header">
          <Typography className="photosHeader">{this.transPortfolio("Images")}</Typography>
          <Typography className="descriptionHeader">{this.transPortfolio("Description")}</Typography>
        </Box>
        {this.state.multipleUpload.length > 1 && (
        <Box className="secondaryContainer">
          <Typography className="photosHeader">{this.transPortfolio("Images")}</Typography>
          <Typography className="descriptionHeader">{this.transPortfolio("Description")}</Typography>
        </Box>
        )}
        </Box>

        <Box className="gridContainer">
          {this.state.multipleUpload.map((element, index)=> {
            return (
              <Box className="mainContainer">
                <Box style={{position: "relative"}}>
                <Box className="imageContainer">
                  <img src={element.image} style={{width: "113px", height: "113px"}}/>
                </Box>
                <Box style={{width: "28px", height: "28px", position: "absolute", top: "5px", cursor: 'pointer',
                  right: i18n.dir() === 'rtl' ? 'unset' : '6px',
                  left: i18n.dir() === 'rtl' ? '6px' : 'unset',
                  backgroundColor: "white", padding: "4px", borderRadius: "50%", display:"flex", justifyContent: "center", alignItems: "center"}} data-test-id={`handleUpload-${index}`} onClick={()=> this.handleMultipleDel(index)}>
                  <img src={deleteIconPng} alt="close" style={{width: "24px", height: "24px"}}/>
                </Box>
                </Box>
                <Box className="descriptionContainer">
                <TextField
                placeholder={this.transPortfolio("Enter Description")}
                data-test-id={`DescriptionMultiple-${index}`}
                fullWidth
                multiline
                helperText={this.state.multipleErrorDes[index] ? this.state.multipleErrorDes[index] : ""}
                value={element.description}
                error={true}
                onChange={(event) => this.handleMultipleChange(event.target.value, index)}
                className={this.generateClassName(this.state.multipleErrorDes[index], "inputFeild2", "inputFeild2Error2")}
                minRows={4}  
                variant="outlined"  
            />
                </Box>
              </Box>
            )
          })}
          <Box style={{width: "100%", height: "64px", display: "flex", gap: 24, justifyContent: "center"}}>
            <Box style={{width: "23%", height: "60px", border: "1px solid #CCBEB1", display: "flex", justifyContent: "center", alignItems: "center"}} data-test-id={"multipleUploadCancel"} onClick={this.handleMultiplelistCancel}>
              <Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 500, color: "#375280", cursor:"pointer"}}>{this.transPortfolio("Cancel")}</Typography>
            </Box>
            <Box style={{width: "23%", height: "60px", border: "1px solid #CCBEB1", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#CCBEB1"}} data-test-id={"multipleUploadSubmit"} onClick={this.handleMultipleUpload}>
              <Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 800, color: "#FFFFFF", cursor:"pointer"}}>{this.transPortfolio("Submit")}</Typography>
            </Box>
          </Box>
        </Box>
      </ListingUi>
    )
  }

  renderCreateStore = (startIndex: number, endIndex: number) => {
    const ROWS_PER_PAGE = 20;
    return  <CreateStore>
      <Typography className="store-title">{this.transPortfolio("Portfolio")}</Typography>
      <Box style={{ height:"64px", display: "flex", gap: 10, paddingTop: "20px"}}>
        <Box style={{width: "50%", backgroundColor: this.state.activeTab === 1 ? "#375280" : "#FFFFFF", display: "flex", cursor: "pointer", alignItems:"center", justifyContent:"center", borderRadius: '2px', boxShadow: "0px 2px 8px 0px #00000014"}} data-test-id="editPortfolio" onClick={()=> this.handleTab(1)}>
        <Typography style={{color: this.state.activeTab === 1 ? "#FFFFFF" : "#94A3B8", fontFamily:"Lato", fontSize:"18px", fontWeight:500}}> {this.transPortfolio("Upload Portfolio")}</Typography>
        </Box>
        <Box style={{width: "50%", backgroundColor: this.state.activeTab === 0 ? "#375280" : "#FFFFFF", display: "flex", alignItems:"center", cursor: "pointer", justifyContent:"center", borderRadius: '2px', boxShadow: "0px 2px 8px 0px #00000014" }} data-test-id="uploadPortfolio" onClick={()=> this.handleTab(0)}>
            <Typography style={{color: this.handleCondition(this.state.activeTab=== 1, "#94A3B8", "#FFFFFF"), fontFamily:"Lato", fontSize:"18px",fontWeight:500}}>{this.transPortfolio("Edit Portfolio")}</Typography>
        </Box>
      </Box>

      {this.state.activeTab === 0 && (
      this.renderEditTab()
      )}

      {this.state.activeTab === 1 && (
        <>
      {this.renderUploadTab()}
      {this.state.multipleUpload.length > 0 && this.renderUploadListing()}
        </>
      )}

      {this.state.activeTab === 0 && <PaginationContainer>
        <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>
            {startIndex} - {endIndex} {this.transPortfolio("of")} {this.state.portfolioMeta?.total_record} {this.transPortfolio("results")}
        </Box>
        <CustomStyledPagination
            data-test-id="paginationCustom"
            page={this.state.page}
            count={Math.ceil(this.state.portfolioMeta?.total_record/ROWS_PER_PAGE)}
            shape="rounded"
            color="primary"
            onChange={this.handlePageChange}
            dir={i18n.dir()}
        />
        </PaginationContainer>}

        </CreateStore>
  }

  renderEditPortfolio = () => {
    const ROWS_PER_PAGE = 20;
    const startIndex = (this.state.portfolioMeta?.current_page - 1) * ROWS_PER_PAGE + 1;
    const endIndex = Math.min(this.state.portfolioMeta?.current_page * ROWS_PER_PAGE, this.state.portfolioMeta?.total_record);
    return (
        <StylishContainer role="Stylish" navigation={this.props.navigation}>
        {this.renderCreateStore(startIndex, endIndex)}
                         <DeleteDialog
                              open={this.state.deleteDialog}
                              data-test-id="deleteDialoClose"
                              onClose={this.handleDeleteDialogClose}
                              dir={i18n.dir()}
                          >
                              <DiologcontentStyled >
                                  <Box style={{
                                      padding: "25px 25px 0px 25px"
                                  }}>
                                      <Box style={{
                                          display: "flex",
                                          justifyContent: "end"
                                      }} onClick={this.handleDeleteDialogClose}>
                                          <img src={close} alt="close" />
                                      </Box>
                                      <Box style={webStyles.headingContainer}>
                                          <Typography style={webStyles.primaryText}>
                                          {this.transPortfolio("Delete Images?")}
                                          </Typography >
                                          <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 400, textAlign: "center", color: "#375280"}}>{this.transPortfolio("All the selected posts will be deleted. Are you sure you want to delete all the Images?")} </Typography>
                                      </Box>
                                      <Box>
                                      </Box>

                                  </Box>
                              </DiologcontentStyled>
                              <DialogActions style={{ justifyContent: "center", padding: "0px 40px 40px 40px " }}>
                                  <Button data-test-id="handleDeleteAllCallID" style={{...webStyles.cancelButton,  marginRight: i18n.dir() === "ltr" ? "8px" : "0",
                                  marginLeft: i18n.dir() === "rtl" ? "8px" : "0"}} onClick={()=> this.handleDeleteAll(this.state.portFolioDataApi?.id)}>{this.transPortfolio("Delete")}</Button>
                                  <Button data-test-id="handleConfirmAdd" style={webStyles.confirmButton}
                                  onClick={this.handleDeleteDialogClose}>{this.transPortfolio("Cancel")}</Button>
                              </DialogActions>
                          </DeleteDialog>

                          <DialogContainer  data-test-id="handleDialogParticular" open={this.state.particularDeleteDialog}
                           onClose={()=>this.handleDeleteDialogClose()} dir={i18n.dir()}>
          <Box className="content-container">
            <Box>
            <Typography style={webStyles.inputHeadingBox1}>{this.transPortfolio("Description")}</Typography>
            <TextField
                placeholder={this.transPortfolio("Enter Description")}
                data-test-id="Descriptionui"
                fullWidth
                multiline
                value={this.state.deleteUploadData.description ? this.state.deleteUploadData.description: this.state.deleteData?.imageDescription[0]}
                error={true}
                onChange={(event)=>this.handleDescriptionchange(event.target.value)}
                className={this.generateClassName(this.state.errorsMessage.descriptionErrorMsg, "inputFeild2", "inputFeild2Error2")}
                helperText={this.state.errorsMessage.descriptionErrorMsg ? this.state.errorsMessage.descriptionErrorMsg : ""}
                minRows={3}  
                variant="outlined"  
            />

            </Box>

            <Box  className="edit-upload-btn">
              <Box style={{position: "relative"}}>
              {(this.state.imageUpload || this.state.isParticular) ? <img style={webStyles.uploadImage} src={this.state.isParticular ? this.state.deleteUploadData?.imageUrl : this.state.deleteData?.image[0]} alt={this.transPortfolio('upload ui')}  /> : (
                <>
                <Box style={{display: "flex", flexDirection: "column", height: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "#F8F8F8"}} data-test-id="handleUploadRef" onClick={this.handleUpload}>
              <Box style={{marginBottom: "10px"}}>
                <img src={upload} alt="uploadImage"/>
              </Box>
              <Typography style={{fontFamily: "Lato", fontSize:"18px", fontWeight: 700, color: "#375280"}}>{this.transPortfolio("Upload Image")}</Typography>
              <Typography style={{fontFamily: "Lato", fontSize:"14px", fontWeight: 400, color: "#94A3B8"}}>{this.transPortfolio("Only png or Jpg files are accepted")}</Typography>
            </Box>
                </>
              )
              }
              <input type="file" accept="image/*" data-test-id="dialogUpload" onChange={(event) => this.handleFileUpload(event.target.files)} ref={this.state.inputRef} style={{display: "none"}} />

                {(this.state.imageUpload || this.state.isParticular) && <Box style={{width: "85%", height: "54px", backgroundColor: "white", cursor: 'pointer', position: "absolute", bottom: "27px", left: "8%",display: "flex", justifyContent: "center", alignItems: "center"}} onClick={this.handleUpload}>
                  <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 500, textTransform: "capitalize", color: "#375280"}}>{this.transPortfolio("Replace Photo")}</Typography>
                </Box>}
                <Box style={{position: "absolute", top: "7px", cursor: 'pointer',
                  right: i18n.dir() === 'rtl' ? 'unset' : '9px',
                  left: i18n.dir() === 'rtl' ? '9px' : 'unset',
                  backgroundColor: "#FFFFFF", borderRadius: "50%", display: "flex", width: "48px", height: "48px", justifyContent: "center", alignItems: "center"}} data-test-id="handleDeleteinDialog" onClick={this.handleDeleteSingleOpen}>
                  <img src={deleteIconPng} alt="deleteIcon" style={{height: "32px", width: "32px"}}/>
                </Box>
              </Box>
            </Box>

            <Box style={{display: "flex", gap: "10%"}}>
            <Button  data-test-id="handleDeleteClose" style={{width: "45%", height: "64px", backgroundColor: "#FFFFFF", border: "1px solid #CCBEB1", textTransform: "capitalize"}} onClick={this.handleDeleteClosePar} ><Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 500, color: "#375280"}}>{this.transPortfolio("Cancel")}</Typography></Button>
            <Button  data-test-id="handleConfirmUpload" style={{width: "45%", height: "64px", backgroundColor: "#CCBEB1", border: "1px solid #CCBEB1", textTransform: "capitalize"}} onClick={this.handleSingleUpdate}><Typography style={{fontFamily: "Lato", fontSize: "20px", fontWeight: 800, color: "#FFFFFF"}}>{this.transPortfolio("Update")}</Typography></Button>
            </Box>
            </Box>
                          </DialogContainer>

                          <DialogContainerSuccess  dir={i18n.dir()} data-test-id="create-store-success-dialog" open={this.state.uploadSuccess} onClose={()=>this.handleDeleteDialogClose()}>
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">{this.transPortfolio("Your images has been successfully uploaded.")}</Typography>
          </Box>
        </DialogContainerSuccess>
      </StylishContainer>
    )
  }

  openSingleDelete = () => {
    return (
                        <DeleteDialogSmall
                             open={this.state.singleDeletePop}
                             data-test-id="singleDeletePop"
                             onClose={this.handleDeleteUpdateDelete}
                             dir={i18n.dir()}
                         >
                             <DiologcontentStyled2>
                                 <Box style={{
                                     padding: "25px 25px 0px 25px"
                                 }}>
                                     <Box style={{
                                         display: "flex",
                                         justifyContent: "end"
                                     }} onClick={this.handleDeleteUpdateDelete}>
                                         <img src={close} alt="close" />
                                     </Box>
                                     <Box style={webStyles.headingContainer}>
                                         <Typography style={webStyles.primaryText}>
                                         {this.transPortfolio("Delete Image?")}
                                         </Typography >
                                         <Typography style={{fontFamily: "Lato", fontSize: "18px", fontWeight: 400, textAlign: "center", color: "#375280"}}>{this.transPortfolio("Are you sure you want to delete the particular portfolio?")} </Typography>
                                     </Box>
                                     <Box>
                                     </Box>
                                 </Box>
                             </DiologcontentStyled2>
                             <DialogActions style={{ justifyContent: "center", padding: "0px 40px 40px 40px " }}>
                                 <Button data-test-id="handleDeleteAllCall" style={{...webStyles.cancelButton,
                                  marginRight: i18n.dir() === "ltr" ? "8px" : "0",
                                  marginLeft: i18n.dir() === "rtl" ? "8px" : "0"
                                 }} onClick={this.handleUpdateDelete}>{this.transPortfolio("Delete")}</Button>
                                 <Button data-test-id="handleConfirmAdd" style={webStyles.confirmButton}
                                 onClick={this.handleDeleteUpdateDelete}>{this.transPortfolio("Cancel")}</Button>
                             </DialogActions>
                         </DeleteDialogSmall>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"md"} style={{display: "none"}}>
          <Box sx={webStyles.headerButtonViewStyle}>
            <Button
              data-test-id="handlebtnAddImage"
              variant="contained"
              color="inherit"
              onClick={this.handlebtnAddImage}
            >
              {this.transPortfolio(configJSON.textAddNewImage)}
            </Button>
            <Box sx={webStyles.secondButtonViewStyle}>
              <Button
                data-test-id="openShareModal"
                variant="contained"
                color="inherit"
                onClick={this.openShareModal}
              >
                {this.transPortfolio(configJSON.textShare)}
              </Button>
            </Box>
            <Box sx={webStyles.secondButtonViewStyle}>
              <Button
                data-test-id="handleDeleteGallery"
                variant="contained"
                color="secondary"
                onClick={() => this.handleDeleteGallery()}
              >
                {this.transPortfolio(configJSON.textDeleteGallery)}
              </Button>
            </Box>
            <Box sx={webStyles.secondButtonViewStyle}>
              <Button
                data-test-id="toggleDeleteMultipleImages"
                variant="contained"
                color="secondary"
                onClick={() => this.toggleDeleteMultipleImages()}
              >
                {this.transPortfolio(configJSON.textDelete)}
              </Button>
            </Box>
            {this.state.isVisibleDeleteCheckbox && (
              <Box sx={webStyles.secondButtonViewStyle}>
                <Button
                  data-test-id="deleteSelectedImages"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.deleteSelectedImages()}
                >
                  {this.transPortfolio(configJSON.textDeleteSelected)}
                </Button>
              </Box>
            )}
          </Box>

          <Box sx={webStyles.root}>
            <ImageList rowHeight={160} style={webStyles.imageList} cols={4}>
              {this.state.imageData.map(
                (item: ImageListType, index: number) => {
                  return (
                    <ImageListItem  dir={i18n.dir()} key={index} cols={1}>
                      <img
                        data-test-id={"handlebtnViewImage" + index}
                        src={item.file_url}
                        alt={item.file_name}
                        onClick={() => this.handlebtnViewImage(item)}
                      />
                      {this.state.isVisibleDeleteCheckbox && (
                        <ImageListItemBar
                          style={webStyles.imageListBar}
                          position="top"
                          actionIcon={
                            <IconButton
                              data-test-id={"toggleImageChecked" + index}
                              style={webStyles.btnCheck}
                              onClick={() => this.toggleImageChecked(item)}
                            >
                              {item.isSelected ? (
                                <CheckBox color="primary" />
                              ) : (
                                <CheckBoxTwoTone />
                              )}
                            </IconButton>
                          }
                          actionPosition="left"
                        />
                      )}
                    </ImageListItem>
                  );
                }
              )}
            </ImageList>
          </Box>

          <Modal
            open={this.state.isAddImageModalVisible}
            onClose={this.closeAddImageModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            dir={i18n.dir()}
          >
            <Box sx={webStyles.modalStyle}>
              <Button
                variant="contained"
                component="label"
                data-test-id="handleAddnewImage"
              >
                {this.transPortfolio(configJSON.chooseImageButtonTitle)}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  data-test-id="fileInput"
                />
                {this.transPortfolio(configJSON.textSelectImage)}
              </Button>
              <p
                style={{
                  ...webStyles.errorMsg,
                  visibility: this.state.addImageError ? "visible" : "hidden"
                }}
              >
                {this.transPortfolio(configJSON.noImageErrorMsg)}
              </p>
              <img
                style={webStyles.imgStyle}
                src={
                  this.state.viewSelectedImage && this.state.selectedImage.uri
                }
                alt={"image"}
              />
              <Box sx={webStyles.modalRowViewStyle}>
                <Button
                  data-test-id="handleSaveImage"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleSaveImage()}
                >
                  {this.transPortfolio(configJSON.textSave)}
                </Button>
                <Button
                  data-test-id="closeAddImageModal"
                  variant="contained"
                  onClick={this.closeAddImageModal}
                  style={webStyles.secondButtonViewStyle}
                >
                  {this.transPortfolio(configJSON.textClose)}
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={this.state.isViewImageModalVisible}
            onClose={this.closeViewImageModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            dir={i18n.dir()}
          >
            <Box sx={webStyles.modalStyle}>
              <img
                style={webStyles.imgStyle}
                alt={"image"}
                src={
                  this.state.viewSelectedImage &&
                  this.state.viewSelectedImage.uri
                }
              />
              <Box sx={webStyles.modalButtonViewStyle}>
                <Button
                  data-test-id="closeViewImageModal"
                  variant="contained"
                  onClick={this.closeViewImageModal}
                >
                  {this.transPortfolio(configJSON.textClose)}
                </Button>
                <Button
                  data-test-id="handleDeleteImage"
                  variant="contained"
                  color="secondary"
                  onClick={this.handleDeleteImage}
                  style={webStyles.secondButtonViewStyle}
                >
                  {this.transPortfolio(configJSON.textDelete)}
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={this.state.isShareModalVisible}
            onClose={this.closeShareModal}
            dir={i18n.dir()}
          >
            <Box sx={webStyles.modalStyle}>
              <p
                style={{
                  ...webStyles.errorMsg,
                  visibility: this.state.inputAccountIdError
                    ? "visible"
                    : "hidden",
                }}
              >
                {configJSON.errorMsgAccountId}
              </p>
              <TextField
                required
                fullWidth
                type="number"
                id="standard-required"
                data-test-id={"inputAccountId"}
                label={configJSON.textAccountId}
                defaultValue={this.state.inputAccountId}
                onChange={(event) =>
                  this.handleAnputAccountID(event.target.value)
                }
              />
              <Box sx={webStyles.modalButtonViewStyle}>
                <Button
                  data-test-id="sharePhotoLibrary"
                  variant="contained"
                  color="secondary"
                  onClick={() => this.sharePhotoLibrary()}
                >
                  {this.transPortfolio(configJSON.textSave)}
                </Button>
                <Button
                  data-test-id="closeShareModal"
                  variant="contained"
                  onClick={this.closeShareModal}
                  style={webStyles.secondButtonViewStyle}
                >
                  {this.transPortfolio(configJSON.textClose)}
                </Button>
              </Box>
            </Box>
          </Modal>
        </Container>
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          onClose={this.oncloseAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
          dir={i18n.dir()}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
            {this.renderEditPortfolio()}
            {this.openSingleDelete()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const PaginationContainer = styled(Box)({
  width: "-webkit-fill-available", display: 'flex',height:"64px", justifyContent: 'space-between', alignItems: 'center', margin: "10px 0px 0px 0px", backgroundColor: "#FFFFFF" , padding: "0px 25px 0px 25px", position: "fixed", bottom: "0px",
  "@media only screen and (max-width: 600px)": {
    flexDirection: "column-reverse",
    padding: 10
  }
});

const DialogContainerSuccess = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
  }});

const DiologcontentStyled = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: "24px",
  flex: "none",
  height: "200px"
});

const DiologcontentStyled2 = styled(DialogContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: "24px",
  flex: "none",
  height: "180px"
});

const ListingUi = styled(Box)({
  maxWidth: "100%", maxHeight: "528px", border: "1px solid #E2E8F0", marginTop: "25px", padding:'25px', overflowY: "auto", marginBottom: "60px",
  "& .header": { display: "flex", width: "48.7%", gap: 20},
  "& .photosHeader": {width: "18%", minWidth: "104px", fontFamily: "Lato",fontWeight: 900, fontSize: "16px", color: "#375280"},
  "& .descriptionHeader": {width: "87%", fontFamily: "Lato",fontWeight: 900, fontSize: "16px", color: "#375280"},
  "& .gridContainer": {display: "flex", flexWrap: "wrap", gap: 20, marginTop: "15px"},
  "& .mainContainer": {display: "flex", width: "47%", minHeight: "104px", gap: 10},
  "& .imageContainer": {width: "19%", height: "104px",  minWidth: "104px"},
  "& .secondaryContainer": { display: "flex",  width: "50%"},
  "& .descriptionContainer": {width: "81%", maxHeight: "113px", backgroundColor: "#FFFFFF", border: "1px solid #E2E8F0", height: "fit-content", overflow: "auto"},
  "@media(min-width:200px) and (max-width: 1100px)": {
    "& .secondaryContainer": {
      backgroundColor: "blue",
      display: "none"
    },
    "& .gridContainer": {
      display: "flex", flexDirection: "column",
      gap: 20, marginTop: "15px"
    },
    "& .mainContainer": {
      display: "flex", width: "100%", minHeight: "104px", gap: 10
    },
    "& .header": {
      display: "flex", width: "93%", gap: 20
    }
  },
});

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    minWidth: '500px',
    minHeight: "634px",
    padding: "36px",
    "@media(max-width:680px)": {
        minWidth: "70% !important",
        padding: "20px",
      },
      "@media(max-height:700px)": {
        minHeight: "90%  !important"
      },
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
      "& .upload-btn-input":{
      width:"516px", height: "430px", borderRadius: "2px", border: "1px solid #E2E8F0", padding: "10px",
      "@media(max-width:700px)": {
        width: "-webkit-fill-available !important"
      },
      "@media(max-height:560px)": {
        height: "100%  !important"
      },
    },
    "& .edit-upload-btn":{
        width:"516px", 
        height: "415px", 
        borderRadius: "2px",
        "@media(max-width:700px)": {
        width: "-webkit-fill-available !important"
      },
      "@media(max-height:560px)": {
        height: "100%  !important"
      },
      },
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
"& .inputFeild2Error2": {
  "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
  },
  "& .MuiFormHelperText-contained": {
      margin: "0 !important"
  },
  '& .MuiInputBase-input': {
      color: "#375280"
  },
},
"& .inputFeild2": {
  "& .MuiTextField-root": {
      "&:hover": {
          border: "1px solid #F0F0F0 !important",
      },
  },
  "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
  },
  "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

  },
  "& .MuiFormHelperText-contained": {
      margin: "0 !important"
  },
  '& .MuiInputBase-input': {
      color: "#375280"
  },
  "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
  }
},
});

const DeleteDialog = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
      minWidth: '620px',
      minHeight: "316px",
      "@media(max-width:620px)": {
        minWidth: "90%"
      },
      "@media(max-height:400px)": {
        minHeight: "90%"
      }
  },
  '& .MuiPaper-rounded': {
      borderRadius: '1px',
      border: "1px solid #CCBEB1"
  }, 
  "& .MuiDialogContent-root:first-child": {
      paddingTop: "0px"
  }
});

const DeleteDialogSmall = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
      minWidth: '620px',
      minHeight: "280px",
      "@media(max-width:660px)": {
          minWidth: "90%"
        },
        "@media(max-height:360px)": {
          minHeight: "90%"
        },
  },
  '& .MuiPaper-rounded': {
      borderRadius: '1px',
      border: "1px solid #CCBEB1"
  }, 
  "& .MuiDialogContent-root:first-child": {
      paddingTop: "0px"
  }
});

const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) =>({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    "& li:last-of-type .MuiPaginationItem-icon": {
      transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
    "& li:first-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
}));

const StyledCheckbox = styled(Checkbox)({
  "& .MuiCheckbox-root":{
    color:"#CCBEB1",
  },

  '& .MuiSvgIcon-root': {
    width: "32px",
    height: "32px",
  }
});

const CreateStore = styled(Box)({
  padding: "30px",
  "& .loader-container":{
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
    "& .store-title": {
      fontFamily: "Lato, sans-serif",
      fontSize: "24px",
      fontWeight: 700,
      color: "#375280",
  },
  "& .checbox":{
    height: "32px", width: "32px", color: "#CCBEB1",
    "&:checked":{
      backgroundColor: "red"
    }
  }, "& .secondContainer": {
    display: "flex", gap: 20, padding: "12px 0px", justifyContent: "flex-start", flexWrap: "wrap",
  },
  "& .image-grid": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
    gap: "20px",
    maxHeight: "538px", width: "100%", marginTop: "20px", overflow: "auto", marginBottom : "15px"
  },
  "& .image-item": {
    width: "100%",
    height: "166px",
    overflow: "hidden",
    cursor: 'pointer',
    position: "relative"
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
        border: "none"
    },
    "& .MuiFormHelperText-contained": {
        margin: "0 !important"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
},
"& .inputFeild2": {
    "& .MuiTextField-root": {
        "&:hover": {
            border: "1px solid #F0F0F0 !important",
        },
    },
    "& .MuiInputBase-input::placeholder": {
        fontWeight: 400,
        fontSize: "14px"
    },
    "& .MuiOutlinedInput-root:hover": {
        border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px",
    },
    "& .MuiFormHelperText-contained": {
        margin: "0 !important"
    },
    '& .MuiInputBase-input': {
        color: "#375280"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0",
    }
},

});
const webStyles = {
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  secondButtonViewStyle: {
    marginLeft: 10
  },
  modalStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 10
  },
  modalRowViewStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  modalButtonViewStyle: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 0px"
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden"
  },
  imageList: {
    width: "100%",
    height: "100%"
  },
  imgStyle: {
    maxHeight: "400px",
    maxWidth: "400px",
    width: "auto",
    height: "auto",
    margin: 10
  },
  imageListBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  btnCheck: {
    color: "white"
  },
  errorMsg: {
    color: "red",
  },
  headingContainer: {
    marginLeft: "12px", display: "flex", flexDirection: "column",
    justifyContent: "center",
    alignItems: "center", gap: "24px"
} as React.CSSProperties,
inputHeadingBox1: {
  color: "#375280",
  margin: "10px 0",
  textOverflow: "ellipsis",
  fontFamily: "Lato",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 900,
  lineHeight: "24px",
},
primaryText: {
  fontSize: '30px',
  color: "#375280",
  fontWeight: 500,
  fontFamily: "Lato"
},
confirmButton: {
  alignItems: "center",
  fontSize: "18px",
  justifyContent: "center",
  fontWeight: 500,
  display: "flex",
  color: "#375280",
  backgroundColor: "#FFFFFF",
  border: "1px solid #CCBEB1",
  height: "56px",
  borderRadius: "5px",
  width: "252px",
  padding: "10px",
  cursor: "pointer",
  textTransform: "capitalize"
} as React.CSSProperties,
cancelButton: {
  alignItems: "center",
  fontSize: "18px",
  justifyContent: "center",
  fontWeight: 700,
  display: "flex",
  color: "#FFFFFF",
  backgroundColor: "#F87171",
  height: "56px",
  borderRadius: "5px",
  width: "252px",
  padding: "10px",
  cursor: "pointer",
  textTransform: "capitalize"
} as React.CSSProperties,
uploadImage: {
  width: "100%",
  height: "100%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover"
},
};

const MultiUploadBoxContainer = styled(Box)({
  display: "flex", 
  gap: 35, 
  marginTop: "35px",
  "@media(max-width:620px)": {
    flexDirection: "column",
    alignItems: "center"
  }
})
// Customizable Area End
