// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import i18n from "../../../../components/src/i18next/i18n";
import { CatalogueVariantSize, Category, IAssignFilterState, ICatalogueVariantSizeResp, ICategoryResp, initialAssignStoreFilter, ISubCategoryResp, SubCategory } from "../Seller/InventoryManagement/SellerInventoryManagementController";
import { apiCall } from "../../../../components/src/APICall";
import { ErrorMessage } from "../CreateSellerStoreController";
import { ErrorMessageResponse } from "../SellerStoreController";
import { MyStoreList } from "../MySellerStoreController";
import { Meta } from "../../../chat/src/OneToOneChatCotroller.web";
import { checkCondition } from "../../../../components/src/Seller/logOut";

interface INavigateDetailsPageTo {
  id: number | undefined;
  screenName: string;
  raiseMessage?: Message;
}

type StringNumNull = string | number | null;

type StringNull = string | null;

interface IVariant{
  "id": number;
  "name": string;
  "created_at": string;
  "updated_at": string;
  "name_in_arabic": string;
}

interface ICatalogueVariant{
    "id": string;
    "type": string;
    "attributes": {
        "id": number;
        "catalogue_id": number;
        "catalogue_variant_color_id": number;
        "catalogue_variant_color": IVariant;
        "catalogue_variant_size_id": number;
        "catalogue_variant_size": IVariant;
        "stock_qty": number;
        "on_sale": StringNumNull;
        "sale_price": StringNumNull;
        "discount_price": StringNumNull;
        "length": StringNumNull;
        "breadth": StringNumNull;
        "height": StringNumNull;
        "created_at": string;
        "updated_at": string;
        "sku": string;
        "deactivate": boolean;
        "low_stock_threshold": number;
        "is_listed": boolean;
        "loyalty_points_wil_credit": number;
        "custom_pay": StringNumNull;
        "custom_lp": StringNumNull;
        "discounted_price": string;
        "discounted_percentage": number;
        "front_image": string;
        "back_image": string;
        "side_image": string;
        "pair_it_with": string[];
        "price": string;
        "currency_logo": string;
    }
}


export interface IVariantData{
    "id": string;
    "type": string;
    "attributes": {
        "name": string;
        "description": string;
        "catalogue_variants": ICatalogueVariant[];
        "primary_image": string;
        "primary_price": string
        "primary_brand_name": string
        "primary_store_name": string;
        "currency_logo": string;
        "primary_main_price": string;
        "primary_discounted_percentage": number;
        "is_wishlist": boolean;
    }
}

const defaultMeta = {
  current_page: 0,
  next_page: null,
  prev_page: null,
  total_pages: 0,
  total_count: 0,
}
// Customizable Area End


export const configJSON = require("../../src/config.js");


export interface Props {
  navigation: any;
  // Customizable Area Start
  
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  storeList: MyStoreList;
  circularProgress: boolean;
  token: string;
  isAlert:boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  variantsData: IVariantData[];
  tokenApi: string;
  buyerStylish: boolean;
  mondayWeek: {
    start: string | null,
    end: string | null,
  },
  satWeek: {
    start: string | null,
    end: string | null,
  }, currencySign: string;
  categoryName: string;
  categoryType: string;
  sizes: CatalogueVariantSize[];
  category: Category[];
  subCategory: SubCategory[];
  search: string;
  buyerStoreProductFilterState: IAssignFilterState;
  meta: Meta;
  hasmoreClients: boolean;
  favouriteId: string; 
  isBackDropProductWishlist: boolean
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BuyerStoreProductController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchStoreDetailsCallID: string = "";
  fetchStoreCatalogueCallId: string = "";
  postFavouriteApiCalledId: string  = "";
  deleteFavouriteApiCalledId: string = "";
  fetchStoreDetailsSearchCallID: string = "";
  getSizeCallId: string = "";
  getCategoryCallId: string = "";
  getSubCategoriesCallId: string = "";
  timeoutId: NodeJS.Timeout|null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      storeList: {} as MyStoreList,
      circularProgress: false,
      token: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: "",
      variantsData: [],
      buyerStylish: false,
      tokenApi: "",
      mondayWeek: {
        start: "",
        end: "",
      },
      satWeek: {
        start: "",
        end: "",
      },
      currencySign: "$",  
      categoryName: "",
      categoryType: "",
      sizes: [],
      category: [],
      subCategory: [],
      search: "",
      buyerStoreProductFilterState: initialAssignStoreFilter,
      meta: defaultMeta,
      hasmoreClients: false,
      favouriteId: "0",
      isBackDropProductWishlist: false
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
   
this.postFavouriteApiCalledResp(apiRequestCallId, message);
this.fetchStoreDetailsCalledResp(apiRequestCallId, message);
this.handleResponse(apiRequestCallId, message);

    await this.FilterResp(message);
    // Customizable Area End
  }
  // Customizable Area Start
  postFavouriteApiCalledResp = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  if (apiRequestCallId === this.postFavouriteApiCalledId) {
    const alertType = checkCondition(responseJson?.error, "error", "success") as "error" | "success";
    const alertMsg = checkCondition(responseJson?.error, responseJson.error as string,`${configJSON.addFavTitle}`) as string;
    if(responseJson?.errors){
      this.setState({
        isAlert: true,
        alertType,
        alertMsg,
        isBackDropProductWishlist: false
      });
    }else{
      this.setState({
        isAlert: true,
        alertType,
        alertMsg,
      }, ()=> this.handleUpdateWishlist());
    }
  }
}
fetchStoreDetailsCalledResp= (apiRequestCallId: string, message: Message) => {
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
if (apiRequestCallId === this.fetchStoreDetailsCallID) {
  if (responseJson?.errors) {
    const alertMsg = Array.isArray(responseJson.errors) && responseJson.errors.length > 0
      ? responseJson.errors[0]?.token
      : responseJson.errors;
    this.setState({
      isAlert: true,
      alertType: "error",
      alertMsg,
      circularProgress: false,
    });
  } else {
    const storeList = responseJson?.data;
    const mondayweekData = storeList?.attributes?.store_operating_hours?.monday
      ? Object.values(storeList.attributes.store_operating_hours.monday)
      : [];
    const satweekData = storeList?.attributes?.store_operating_hours?.saturday
      ? Object.values(storeList.attributes.store_operating_hours.saturday)
      : [];

    this.setState({
      storeList,
      circularProgress: false,
      mondayWeek: {
        start: checkCondition(typeof mondayweekData[0] === "string", mondayweekData[0], null) as StringNull,
        end: checkCondition(typeof mondayweekData[1] === "string", mondayweekData[1], null) as StringNull,
      },
      satWeek: {
        start: checkCondition(typeof satweekData[0] === "string", satweekData[0], null) as StringNull,
        end: checkCondition(typeof satweekData[1] === "string", satweekData[1], null) as StringNull,
      },
    });
  }
}
}

  handleApiResponse = (apiRequestCallId: string, message: Message) => {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if(apiRequestCallId === this.fetchStoreDetailsSearchCallID){
      if(responseJson?.data){
        this.handleProductResp(responseJson);
        if(responseJson?.meta) {
          this.setState((prevState) => ({
            ...prevState,
            meta: { ...responseJson.meta},
            hasmoreClients: !!responseJson.meta.next_page
          }));
        }
        
      }else if(responseJson?.errors){
        if((Array.isArray(responseJson.errors) && responseJson.errors.length > 0) || responseJson.errors==="Catalogues not found."){
          this.setState({
            tokenApi: "",
            variantsData: []
          })
        }
      }
    }
  }

  handleProductResp = (responseJson: {data: IVariantData[], meta: Meta}) =>{
    if(responseJson?.data?.length > 0){
      this.setState((prevState) => {
        let updatedVariantsData = prevState.variantsData;
      
        if (Array.isArray(responseJson.data)) {
          updatedVariantsData = checkCondition(responseJson.meta.current_page === 1, responseJson.data, prevState.variantsData.concat(responseJson.data)) as IVariantData[]; 
        }
      
        return {
          ...prevState,
          variantsData: updatedVariantsData,
          currencySign: checkCondition(this.state.token !== null, responseJson.data[0]?.attributes?.currency_logo 
            , prevState.currencySign) as string
        };
      });
    }
  }

handleResponse = (apiRequestCallId: string, message: Message) => {
  const responseJson = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
   if(apiRequestCallId === this.deleteFavouriteApiCalledId){
    const alertType = responseJson?.message ? "success" : "error";
      const alertMsg = responseJson?.message
        ? `${configJSON.removeFavTitle}`
        :  responseJson.error;
    if(responseJson?.message){
      this.setState({
        isAlert: true,
        alertType,
        alertMsg,
      }, ()=> this.handleUpdateWishlist());
    }else{
      this.setState({
        isAlert: true,
        alertType,
        alertMsg,
        isBackDropProductWishlist: false
      });
    }
}else if(apiRequestCallId === this.fetchStoreCatalogueCallId){
  const last =  responseJson.data.attributes.sub_category.attributes.name;
  const categoryName = responseJson.data.attributes.category.attributes.name;
  const match = categoryName.match(/^[^\s']+/);
  const firstWord = match ? match[0] : '';
  this.setState({
    categoryName: firstWord,
    categoryType: last
  })
  }else {
    this.handleApiResponse(apiRequestCallId, message);
  }
}

debounceTimeAPI = () =>{
  if (this.timeoutId) {
    clearTimeout(this.timeoutId);
  }
  this.timeoutId = setTimeout(() => {
    this.getbuyerStoresSearchApi();
  }, 800);
}

handleUpdateWishlist = () => {
  this.setState((prevState) => ({
    ...prevState,
    variantsData: prevState.variantsData.map((variant) =>
      variant.id === prevState.favouriteId
        ? {
            ...variant,
            attributes: {
              ...variant.attributes,
              is_wishlist: !variant.attributes.is_wishlist,
            },
          }
        : variant
    ),
    isBackDropProductWishlist: false
  }));
}

  async componentDidMount() {
    // Customizable Area Start
    const authToken = await getStorageData('auth-token')
    const userDetails = await getStorageData('userRole', true)
    const stylishBuyer = await  getStorageData('exploreBuyer', true);
    if(stylishBuyer){
      this.setState({buyerStylish: stylishBuyer})
    }
    if(userDetails?.currencyLogo){
      this.setState({
        currencySign: userDetails.currencyLogo
      });
    }
    this.getbuyerStores();
    this.setState({
      token: authToken,
      tokenApi: authToken
    }, ()=>this.getbuyerStoresSearchApi())
    await this.categoryTole();
    await this.sizeTole();
    // Customizable Area End
  }

  navigateToDeatils = ({id,screenName,raiseMessage}: INavigateDetailsPageTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && 
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
};

handleFavouriteApi = (favouriteId: string, fav: boolean) => {
  this.setState({favouriteId, isBackDropProductWishlist: true}, ()=>{if (fav) {
    this.handleDeleteWishlist(favouriteId);
  } else {
    this.handleAddWishlistApiCall(favouriteId);
  }})
}

headerNavigation = async (pageLink: string, name?: string) => {
  await setStorageData("navigationGender", name)
  const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
  toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
  toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(toNavigate);
};

handleDeleteWishlist = async (favouriteId?: string) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteFavouriteApiCalledId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

navigateToHome = (navigateLink: string) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), navigateLink);
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

oncloseAlert = () => {
  this.setState({
    isAlert: false
  })
}
getCurrency = (currency: string) => {
  if(currency === "KWD") return "dinar";
  else  return "dollar"
}
handleAddWishlistApiCall = async (favouriteId?: string) => {
  const headers = {
    "Content-Type": configJSON.exampleApiContentType,
    token: this.state.token,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const httpBody = {
    data: {
      favouriteable_id: favouriteId
    }
  };

  this.postFavouriteApiCalledId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.postFavouriteApi
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

  getbuyerStores = async () => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreDetailsCallID = requestMessage.messageId;
    
    const apiRoute =  `${configJSON.getParticularStore}/${storeId}`

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChangeSearch(searchvalue: string) {
    this.setState({search: searchvalue, meta: defaultMeta}, ()=>this.debounceTimeAPI());
  }

  handleFilterApply = (filter: IAssignFilterState) =>{
    this.setState({buyerStoreProductFilterState: filter, meta: defaultMeta}, ()=>this.getbuyerStoresSearchApi());
  }

  getbuyerStoresSearchApi = async () => {
    const storeId = this.props.navigation.getParam("navigationBarTitleText");
    const { search, buyerStoreProductFilterState, meta } = this.state;
    if (meta.current_page===undefined || (meta.next_page === null && meta.current_page > 0)) {
      this.setState({ hasmoreClients: false })
      return
    }
    this.setState({
      circularProgress: true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStoreDetailsSearchCallID = requestMessage.messageId;
    let apiRoute =  `${this.state.tokenApi ? configJSON.getCatalogueWithTokenApiEndPoint : configJSON.getCatalogueWithOutToken}?currency=${this.getCurrency(this.state.currencySign)}&`
    const currentPage = Number(meta.current_page) || 0;
    const queryParams = new URLSearchParams({
      search,
      "store_ids[]": storeId.toString(),
      per_page: "6",
      page: (currentPage + 1).toString(),
    });
    if (buyerStoreProductFilterState.value.min && buyerStoreProductFilterState.value.min !== "") {
      queryParams.append("min_price", buyerStoreProductFilterState.value.min);
    }
    if (buyerStoreProductFilterState.value.max && buyerStoreProductFilterState.value.max !== "") {
      queryParams.append("max_price", buyerStoreProductFilterState.value.max);
    }
    if (buyerStoreProductFilterState.filterSizes.length !== 0) {
      queryParams.append("variant_size_ids", `[${buyerStoreProductFilterState.filterSizes.join(",")}]`);
    }
    if (buyerStoreProductFilterState.filterCategory.length !== 0) {
      queryParams.append("category_ids", `[${buyerStoreProductFilterState.filterCategory.join(",")}]`);
    }
    if (buyerStoreProductFilterState.filterSubCategory.length !== 0) {
      queryParams.append("sub_category_ids", `[${buyerStoreProductFilterState.filterSubCategory.join(",")}]`);
    }
    
    apiRoute += queryParams.toString();
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  tranBuyerStoreFilter = (transKey: string) => {
    return i18n.t(transKey, {ns: "inventoryManagement"});
  }

  getLocalTokenData = async () => {
    return await getStorageData("auth-token");
  };

  subCategoryTole = async (categoryId: number) => {
    this.getSubCategoriesCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.getSubCategoriesEndPoint}${categoryId}`,
      token: await this.getLocalTokenData(),
    });
  };

  categoryTole = async () => {
    this.getCategoryCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getCategoriesEndPoint,
      token: await this.getLocalTokenData(),
    });
  };
  
  sizeTole = async () => {
    this.getSizeCallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getCataloguesVariantsSizesEndPoint,
      token: await this.getLocalTokenData(),
    });
  };

  FilterResp = async (message: Message) =>{
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (
        responseJsonData &&
        !responseJsonData.errors &&
        !responseJsonData.error &&
        !responseJsonData.message
      ) {
        await this.apiSuccessCalled(apiRequestCallId, responseJsonData);
      } else if (
        responseJsonData &&
        (responseJsonData.error || responseJsonData.errors)
      ) {
        await this.apiFailureCalled(responseJsonData);
      }
    }
  }

  apiSuccessCalled = async (
      apiRequestCallId: string,
      responseJsonData: ICatalogueVariantSizeResp &
        ICategoryResp &
        ISubCategoryResp
    ) => {
    switch (apiRequestCallId) {
      case this.getCategoryCallId:
        await this.handleCategoryResp(responseJsonData);
        break;
      case this.getSubCategoriesCallId:
        await this.handleSubCategoryResp(responseJsonData);
        break;
      case this.getSizeCallId:
        await this.handleSizeResp(responseJsonData);
        break;
    }
  };

  handleCategoryResp = async (responseJsonData: ICategoryResp) => {
    if (responseJsonData.data) {
      this.setState((prevState) => ({
        ...prevState,
        category: responseJsonData.data,
      }));
    }
  };

  handleSubCategoryResp = async (responseJsonData: ISubCategoryResp) => {
    if (responseJsonData.data) {
      this.setState((prevState) => ({
        ...prevState,
        subCategory: [...prevState.subCategory, ...responseJsonData.data],
      }));
    }
  }

  handleSizeResp = async (responseJsonData: ICatalogueVariantSizeResp) => {
    if (responseJsonData.data) {
      this.setState((prevState) => ({
        ...prevState,
        sizes: responseJsonData.data,
      }));
    }
  };

  apiFailureCalled = async (
      responseJsonError: ErrorMessage & ErrorMessageResponse & { message: string }
    ) => {
    if (responseJsonError.error) {
      this.setState((prevState) => ({
        ...prevState,
        alertMsg: responseJsonError.error,
      }));
    } else if (responseJsonError.errors[0].token) {
      await this.handleRedirectToHome(responseJsonError.errors[0].token);
    } else if (responseJsonError.errors) {
      this.setState((prevState) => ({
        ...prevState,
        alertMsg: responseJsonError.errors as string,
      }));
    }
  };

  handleRedirectToHome = async (message: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        isAlert: true,
        alertType: "error",
        alertMsg: message,
      })
    );
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
