export const enCard = {
    "Home": "Home",
    "Save Card": "Save Card",
    "Saved Cards": "Saved Cards",
    "Welcome to your Account": "Welcome to your Account",
    "Add New Card": "Add New Card",
    "Primary": "Primary",
    "Make Primary": "Make Primary",
    "Delete": "Delete",
    "Card Holder": "Card Holder",
    "Yes": "Yes",
    "Cancel": "Cancel",
    "No Cards added to this account": "No Cards added to this account",
    "Are you sure you want to remove the card ?": "Are you sure you want to remove the card ?",
    "MM/YY": "MM/YY",
    "Name on Card": "Name on Card",
    "Card Number": "Card Number",
    "Close":"Close",
    "CVV": "CVV",
    "This card already exists in your account": "This card already exists in your account"
}

export const arCard = {
    "Saved Cards": "البطاقات المحفوظة",
    "Home": "الصفحة الرئيسية",
    "Save Card": "حفظ البطاقة",
    "Welcome to your Account": "مرحبا بك في حسابك",
    "Add New Card": "إضافة بطاقة جديدة",
    "Primary": "الرئيسية",
    "Make Primary": "التعيين كالرئيسية",
    "Delete": "الحذف",
    "Card Holder": "اسم حامل البطاقة",
    "Yes": "نعم",
    "Cancel": "الإلغاء",
    "No Cards added to this account": "لم تتم إضافة أي بطاقات إلى هذا الحساب",
    "Are you sure you want to remove the card ?": "هل أنت متأكد تريد حذف البطاقة؟",
    "MM/YY": "الشهر/السنة",
    "Name on Card": "الاسم على البطاقة",
    "Card Number": "رقم البطاقة",
    "Close":"إغلاق",
    "CVV": "(CVV) رقم تحقق البطاقة",
    "This card already exists in your account": "هذه البطاقة موجودة بالفعل في حسابك."
}