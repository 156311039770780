import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { Grid, styled, Box, Modal, Fade, Breadcrumbs, InputAdornment, FormControl, Select, MenuItem, withStyles, Typography, IconButton, TextField, Button } from "@material-ui/core";
import HireStylistFormWebController, {
    Props,
} from "./HireStylistController.web";
import { buttonDelete, uploadIcon, modeofimage } from "./assets"
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import i18n from "../../../components/src/i18next/i18n";
import ReusableSnackbar from "../../../components/src/AlertContainer";
// Customizable Area End

export default class HireStylistFormWeb extends HireStylistFormWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderImages = () => {
        const { editReqId } = this.state;
        return this.state.combineImg.length > 0 && this.state.combineImg.map((image: any, index: number) => (
            <Box key={index} style={{ position: 'relative', ...webStyle.addimgPosisen }}>
                <Box style={{ ...webStyle.uplodeImgIcon, textAlign: 'center' }}>
                    <img
                        src={editReqId ? image : image || undefined}
                        style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                        alt={`Uploaded Image ${index}`}
                    />
                </Box>
                <Box style={{ position: 'absolute', ...webStyle.removedImg }}>
                    <img
                        src={buttonDelete}
                        width="30px"
                        height="30px"
                        style={{ cursor: "pointer"}}
                        data-testid="removeImage"
                        onClick={() => this.handleRemoveMain(index)} 
                    />
                </Box>
            </Box>
        ))
    }

    renderPageHeader = () => {
        const { editReqId } = this.state;
        return <>
               <IconButton data-testid="handleBackButton" className="iconButton" onClick={() => editReqId ? this.hiredStylistPage() : this.hiredStylistContinueShopping({ productId: Number(this.state.navigationId).toString(), props: this.props, screenName: "HiredStylistProfile" })}> {i18n.dir() === "ltr" ? <KeyboardArrowLeftRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}</IconButton>
               <Typography className={i18n.dir() === "ltr" ? "text" : "text2"}>{editReqId ? this.tranHireStylist("Requirement Form") : this.tranHireStylist("Hire Stylist")}</Typography>
        </>
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { editReqId } = this.state;
        return (
            <>
                <PageWrapper>
                    <Header navigation={this.props.navigation} />
                    <Box dir={i18n.dir()} style={{ padding: "40px" }}>
                        <BreadcrumbStyle>
                            {editReqId ? "" : <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={i18n.dir() === "ltr" ? <KeyboardArrowRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}>
                                <Typography color="inherit" style={{ cursor: 'pointer' }} data-testid="hiredStylistPage" id="Stylist" onClick={this.hiredStylistPage}>{this.tranHireStylist("Stylist")}</Typography>
                                <Typography color="inherit" id="Stylist" onClick={this.hiredStylistPage} style={{ cursor: 'pointer' }}>{this.tranHireStylist("Stylists Portfolios")}</Typography>
                                <Typography color="inherit" id="Stylist" data-testid="hiredStylistContinueShopping" onClick={() => this.hiredStylistContinueShopping({ productId: Number(this.state.navigationId).toString(), props: this.props, screenName: "HiredStylistProfile" })}>{this.tranHireStylist("Stylist Profile")}</Typography>
                                <Typography color="inherit" id="Stylist">{this.tranHireStylist("Hire Stylist")}</Typography>
                            </Breadcrumbs>}
                            <Box className="pageTitle">
                             {this.renderPageHeader()}
                            </Box>
                            <Box style={{ backgroundColor: '#FFFFFF', boxShadow: "0px 2px 8px 0px #00000014", margin: '0px 10%', padding: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Box className="inputHeading">{this.tranHireStylist("Gender")}*</Box>
                                        <Box>
                                            <CustomSelect variant="outlined" style={{ width: '100%', height: '56px', backgroundColor: '#F0F0F0' }}>
                                                <Select
                                                    style={webStyle.reminderName}
                                                    value={this.state.numberData || ""}
                                                    data-testid="sub-categories-selection"
                                                    onChange={(event) => {
                                                        this.handleSingleChangeNumber(event);
                                                        this.setState({ errors: { ...this.state.errors, numberData: '' } });
                                                    }}
                                                    displayEmpty
                                                    name="numberData"
                                                    inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id" }}
                                                    MenuProps={{ getContentAnchorEl: null, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } }}
                                                >
                                                    <MenuItem value="" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.tranHireStylist("Select Gender")}</Box></MenuItem>
                                                    <MenuItem value="Male" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.tranHireStylist("Male")}</Box></MenuItem>
                                                    <MenuItem value="Female" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.tranHireStylist("Female")}</Box></MenuItem>
                                                    <MenuItem value="Other" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.tranHireStylist("Other")}</Box></MenuItem>
                                                </Select>
                                            </CustomSelect>
                                        </Box>
                                        {this.state.errors.numberData && <Typography color="error">{this.state.errors.numberData}</Typography>}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box className="inputHeading">{this.tranHireStylist("Colour Preference")}*</Box>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{ 'data-testid': 'moreDetails',style: { fontSize: '16px', fontFamily: 'Lato', fontStyle: "normal", fontWeight: 700 } } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                'aria-label'?: string;
                                                'data-testid'?: string;
                                            }}
                                            name="colourPreference"
                                            placeholder={this.tranHireStylist("Enter Preferred Colour")}
                                            className="inputFeild2"
                                            value={this.state.colourPreference}
                                            onChange={(event) => {
                                                this.handleChange(event);
                                                this.setState({ errors: { ...this.state.errors, colourPreference: '' } });
                                            }}
                                        />
                                        {this.state.errors.colourPreference && <Typography color="error">{this.state.errors.colourPreference}</Typography>}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className="inputHeading">{this.tranHireStylist("Minimum Price")}*</Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{ 'data-testid': 'minimumPrice' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                'aria-label'?: string;
                                                'data-testid'?: string;
                                            }}
                                            name="minimumPrice"
                                            InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: i18n.dir() === 'ltr' ? '0px' : '8px' }}>$</span></InputAdornment>, }}
                                            className="inputFeild2"
                                            value={this.state.minimumPrice}
                                            onChange={(event) => {
                                                this.handleChange(event);
                                                this.setState({ errors: { ...this.state.errors, minimumPrice: '', minMaxError: '' } });
                                            }}
                                        />
                                        {this.state.errors.minimumPrice && <Typography color="error">{this.state.errors.minimumPrice}</Typography>}

                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography className="inputHeading">{this.tranHireStylist("Maximum Price")}*</Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: i18n.dir() === 'ltr' ? '0px' : '8px' }}>$</span></InputAdornment>, }}
                                            inputProps={{ 'data-testid': 'maximumPrice' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                'aria-label'?: string;
                                                'data-testid'?: string;
                                            }}
                                            name="maximumPrice"
                                            className="inputFeild2"
                                            value={this.state.maximumPrice}
                                            onChange={(event) => {
                                                this.handleChange(event);
                                                this.setState({ errors: { ...this.state.errors, maximumPrice: '', minMaxError: '' } });
                                            }}
                                        />
                                        {this.state.errors.maximumPrice && <Typography color="error">{this.state.errors.maximumPrice}</Typography>}
                                    </Grid>
                                    {(!(this.state.errors.maximumPrice || this.state.errors.minimumPrice)) && <Typography color="error">{this.state.errors.minMaxError}</Typography>}
                                    <Grid item xs={12}>
                                        <Typography className="inputHeading">{this.tranHireStylist("More Details (Style, Size & Fit etc.)")}*</Typography>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{ 'data-testid': 'moreDetails',style: { fontSize: '16px', fontFamily: 'Lato', fontStyle: "normal", fontWeight: 700 } } as React.InputHTMLAttributes<HTMLInputElement> & {
                                                'aria-label'?: string;
                                                'data-testid'?: string;
                                            }}
                                            id="outlined-multiline-static"
                                            name="moreDetails"
                                            multiline
                                            className="inputFeild2"
                                            placeholder={this.tranHireStylist("Enter more details about size, fit and the style you want")}
                                            rows={6}
                                            value={this.state.moreDetails}
                                            onChange={(event) => {
                                                this.handleChange(event);
                                                this.setState({ errors: { ...this.state.errors, moreDetails: '' } });
                                            }}
                                        />
                                        {this.state.errors.moreDetails && <Typography color="error">{this.state.errors.moreDetails}</Typography>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className="inputHeading">{this.tranHireStylist("Attachments")}*</Typography>
                                        <Box>
                                            <Box style={webStyle.documentBox as React.CSSProperties}>
                                                <Box>
                                                    <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                        <label htmlFor="file-upload">
                                                            <img
                                                                className="fieldImg"
                                                                alt='civilIdPassport'
                                                                src={uploadIcon}
                                                                data-testid="civilIdPassport"
                                                                style={{ cursor: 'pointer' }} onClick={this.openPictureUpload}
                                                            />
                                                        </label>
                                                    </Box>
                                                    <Box style={{ textAlign: 'center' }}>
                                                        <Typography className="fieldAreaLabel portableNewOne" style={webStyle.allLableText}>
                                                            {this.tranHireStylist("Upload")} png, jpg, jpeg
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            {this.state.errors.selectedFile && <Typography color="error">{this.state.errors.selectedFile}</Typography>}
                                            <Box display="flex" flexWrap="wrap">
                                            {this.renderImages()}
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        variant="contained"
                                        className={"place-offer-btn-2"}
                                        disableElevation
                                        data-testid="hireStylistProfile"
                                        onClick={this.hireStylistProfile}
                                        style={{ fontFamily: "Lato"}}
                                    >
                                        {this.tranHireStylist("Submit")}
                                    </Button>
                                </Box>
                            </Box>
                        </BreadcrumbStyle>
                    </Box>
                    <Footer navigation={this.props.navigation} />
                </PageWrapper>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.openConfirmation}
                    closeAfterTransition
                    style={{ ...webStyle.menuDrawer }}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openConfirmation} style={{ width: "420px", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <img src={modeofimage} width="200px" style={{ margin: '28px 0px' }} />
                            <div style={{ fontSize: '30px', color: "#375280", fontWeight: 700, fontFamily: "Lato" }}>{this.tranHireStylist("Confirmation")}</div>
                            <Box style={{ fontSize: '18px', color: "#375280", margin: '20px 50px', fontWeight: 600, fontFamily: "Lato" }}>{this.tranHireStylist("Your stylist will review your request and contact you.")}</Box>
                            <div style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
                                <Button style={{ backgroundColor: '#CCBEB1', color: '#FFFFFF', width: '320px', height: '64px', padding: '19px 73px', fontSize: '18px', fontWeight: 800, fontFamily: 'Lato', textTransform: "none" }} data-testid="handleClose1" onClick={() => this.hiredStylistRequest()}>{this.tranHireStylist("My Requests")}</Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                <ReusableSnackbar
                    open={this.state.isAlertError}
                    message={this.state.errorMsg}
                    onClose={this.handleAlertClose}
                    severity="error"
                    data-testid="alertHireTestId"
                    autoHideDuration={3000}
                />
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const webStyle = {
    menuDrawer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "none",
    },
    removedImg: { top: '10px', right: '-14px' },
    addimgPosisen: { color: 'white', width: 'max-content', marginRight: '30px' },
    uplodeImgIcon: { width: '80px', height: '80px', marginTop: '8px' },
    selectNameUplodeFile: { color: '#375280', fontSize: '12px', fontFamily: 'Lato', fontWeight: 400 },
    reminderName: { backgroundColor: '#F0F0F0', height: '56px' },
    newShort: { color: '#375280', fontSize: '16px', fontWeight: 700, fontFamily: "Lato" },
    allLableText: { color: "#375280", fontFamily: 'Lato', fontSize: '16px', fontWeight: 700, marginTop: '16px' },
    documentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: "21.5px 0px",
        width: '100%',
        backgroundColor: '#F0F0F0',
        position: "relative",
    },
}
const BreadcrumbStyle = styled(Box)({
    "& .place-offer-btn-2": {
        marginTop: 28,
        textTransform: "capitalize",
        color: "white",
        gap: "8px",
        "& .MuiButton-label": {
            fontSize: 20,
            fontWeight: 800,
        },
        backgroundColor: "#CCBEB1",
        height: 56,
        borderRadius: "1px",
        width: "447px",
    },
    "& .inputFeild2": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",

        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },

        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },



    margin: "20px",
    "& .inputHeading": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "0px 0px 10px 0px",
        textOverflow: "ellipsis",
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    "& .pageTitle": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0"
    },
    "& .iconButton": {
        backgroundColor: '#fff',
        borderRadius: '50%',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
        padding: '8px',
        marginRight: '8px',
    },
    "& .text": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        color: '#375280',
        marginLeft: "20px"
    },
    "& .text2": {
        fontFamily: 'Lato',
        fontSize: '20px',
        fontWeight: 800,
        color: '#375280',
        marginRight: "20px"
    },
    "& .breadcrumb": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
        textAlign: 'left',
        textDecoration: 'none',
        color: '#475569',
    }
})
const PageWrapper = styled(Box)({
    background: "#F8F8F8",
})

const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
// Customizable Area End
