import React from "react";
import { Tooltip, TooltipProps, styled } from "@material-ui/core";

interface CustomTooltipProps {
  title: string | React.ReactNode;
  children: React.ReactNode;
  arrow?: boolean;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    color: "#375280",
    fontSize: "14px",
    lineHeight: "18px",
    fontFamily: "'Lato', sans-serif",
    borderRadius: 8,
    padding: "8px 12px 8px 14px",
  },
  "& .MuiTooltip-arrow": {
    color: "#ffffff",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
  },
  "& .MuiTooltip-popper": {
    margin: "-8px 2px 2px",
  },
});

const CustomTooltip: React.FC<CustomTooltipProps> = ({ title, children, arrow = false }) => {
  if (!title) {
    return <>{children}</>;
  }

  return (
    <StyledTooltip title={title} arrow={arrow}>
      <span>{children}</span>
    </StyledTooltip>
  );
};

export default CustomTooltip;
