import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Box, styled, Grid, Typography } from "@material-ui/core";
import DownloadTemplatesController, {
  Props,
} from "./DownloadTemplatesController";
import { NoRecordTypoContainer } from "../AssignStore/InventoryAssignStore.web";
import { csvFileIcon, downloadIcon } from "../../../assets";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
import Loader from "../../../../../../components/src/LoaderContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class DownloadTemplates extends DownloadTemplatesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderTemplate = () => (
    <>
      {this.state.downloadTemplates.length === 0 ? (
        <NoRecordTypoContainer>{this.tranDownloadTemp("No Templates Found")}</NoRecordTypoContainer>
      ) : (
        <Grid container spacing={3}>
          {this.state.downloadTemplates.map((template) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box className="template-container">
                <Box className="file-container">
                  <img src={csvFileIcon} alt="csv-icon" />
                  <Typography className="file-name-title">
                    {template.attributes.file_name}
                  </Typography>
                </Box>
                <img
                  src={downloadIcon}
                  style={{cursor: "pointer"}}
                  alt="down-load-icon"
                  data-test-id={`template-test-id-${template.id}`}
                  onClick={() =>
                    this.handleDownloadTemplates(
                      template.attributes.file,
                      template.attributes.file_name
                    )
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <DownloadTemplatesContainer>
        <>
          {this.state.isLoading ? (
            <Loader id="step-6-loader" dataTestId="step-6-loader-test" />
          ) : (
            this.renderTemplate()
          )}
          <ReusableSnackbar
            open={this.state.isAlert}
            message={this.state.message}
            onClose={this.onAlertDTSnackClose}
            severity={this.state.severity}
            dataTestId="alertTestId"
            autoHideDuration={3000}
          />
        </>
      </DownloadTemplatesContainer>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const DownloadTemplatesContainer = styled(Box)({
  "& .template-container": {
    boxShadow: "gba(0, 0, 0, 0.08)",
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    "& .file-container": {
      display: "flex",
      gap: 20,
      alignItems: "center",
      "& .file-name-title": {
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 18,
        fontWeight: 500,
      },
    },
  },
});
// Customizable Area End
