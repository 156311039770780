import React from "react";
// Customizable Area Start
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  styled

} from "@material-ui/core";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { plus, Vector, scroll, threeDot } from "../src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import storage from '../../../framework/src/StorageProvider';

import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"
// Customizable Area End
//@ts-ignore
import Select from "react-select";
// import { Dropdown } from "react-native-material-dropdown";
import Icon from "react-native-vector-icons/AntDesign";

import AddressManagementController, {
  Props,
  configJSON,
  AdressTypeData,
  // Customizable Area Start
  Address
  // Customizable Area End
} from "./AddressManagementController";

export default class AddAddress extends AddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  renderDropdown = (addressID: {
    id: string,
    attributes: { is_default: boolean }
  }) => {
    if (this.state.openDropdownId === addressID.id) {
      storage.set('addressID', addressID.id)
      return (
        <div style={webStyle.dropdown as React.CSSProperties}>
          {

            !addressID.attributes.is_default && <p style={webStyle.dropdownP as React.CSSProperties} data-test-id="handleMakeDefault" onClick={() => this.handleMakeDefault(addressID)}>{this.tranAddress("Make Default")}</p>
          }
          <p style={addressID.attributes.is_default ? webStyle.dropdownDefault as React.CSSProperties : webStyle.dropdownP as React.CSSProperties} data-test-id="handleEditTestId" onClick={() => this.getNavigationEditAddress()}>{this.tranAddress("Edit Address")}</p>
          {
            !addressID.attributes.is_default &&
            <p style={webStyle.dropdownP1 as React.CSSProperties} data-test-id="handleOpenDeleteDialog" onClick={() => this.handleOpenDeleteDialog(addressID.id)}>{this.tranAddress("Delete")}</p>
          }
        </div>
      );
    }
  };

  navAddAddressHeaderMargin = () => {
    return i18n.dir() === "ltr" ? "37px 0px 0px 40px" : "37px 40px 0px 0px"
  }

  navAddAddressNameHeaderMargin = () => {
    return i18n.dir() === "ltr" ? "20px 0px 31px 40px" : "20px 40px 31px 0px"
  }
  NavigationHeader = () => {
    return (
      <>
        <div
          style={{ ...webStyle.innerContainer11, margin: this.navAddAddressHeaderMargin() }}>
          <Typography style={webStyle.innerContainer12} data-test-id="home-test-id" onClick={() => this.handleNavigation("LandingPage")}>
            {this.tranAddress("Home")}
          </Typography>
          <img src={Vector} style={{ transform: i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)" }} />
          <Typography style={{ ...webStyle.innerContainer23, fontWeight: 700 }}>
            {this.tranAddress("Address")}
          </Typography>
        </div>

      </>
    )
  }
  DrawerToggle = () => (
    <div
      id="drawer-toggle"
      style={{ marginLeft: window.innerWidth >= 768 ? "66%" : "42%", marginTop: window.innerWidth >= 768 ? "5%" : "7%" }}
    >
      <MoreVertIcon
        id="drawer-open-icon"
        data-test-id="drawerIcon"
        onClick={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
      />
    </div>
  );
  DrawerContent = () => (
    <BuyerDrawer
      navigation={this.props.navigation}
      open={this.state.isDrawerOpen}
      data-test-id="toggleDrawer"
      onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
    >
      <div >
        <Typography style={{ ...webStyle.innerContainer3, margin: this.navAddAddressHeaderMargin() }}>
          <img src={scroll} />
          {this.state.fullName}
        </Typography>
      </div>
      <Typography style={{ ...webStyle.innerContainer4, margin: this.navAddAddressNameHeaderMargin() }}>
        {this.tranAddress("Welcome to your Account")}
      </Typography>
      <Sidebar navigation={this.props.navigation} />
    </BuyerDrawer>
  )
  MobileView = () => {
    if (window.innerWidth >= 951) return null;
    return (
      <>
        <Grid id="mobile-view-container" item style={{ display: "flex", width: "90vw" }}>
          {this.NavigationHeader()}
          {this.DrawerToggle()}
          {this.DrawerContent()}
        </Grid>
        <Grid item xs={12} md={6} style={{ paddingInline: 20, margin: "0px auto" }}>
          <div>
            <div style={{
              fontSize: '20px',
              fontWeight: 800,
              lineHeight: '33.5px',
              marginBottom: '29px',
              color: "#375280",
              fontFamily: 'Lato , sans-serif',
              marginTop: "110px",
            }}> {this.tranAddress("Address")}</div>
            <div style={webStyle.addAddressContainer as React.CSSProperties} >
              <div onClick={this.getNavigationMessage} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" }}>
                <img src={plus} style={{ cursor: "pointer" }} />
                {this.tranAddress("Add Address")}
              </div>
            </div>
            <div style={{ backgroundColor: '#F8F8F8' }} >
              <div >
                {this.state.addressData && this.state.addressData.map((address: Address) => (
                  <div key={address.id} style={webStyle.addAddressContainer}>
                    <div style={{
                      fontSize: "16px", fontWeight: 700, lineHeight: "26px", color: "#94A3B8",
                      display: 'flex', alignItems: "center", gap: '17px', justifyContent: "space-between", fontFamily: "Lato , sans-serif",
                    }}>
                      <div style={{ display: 'flex', alignItems: "center" }}>
                        <p style={{
                          backgroundColor: '#F6F6F6',
                          width: '94px',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>{this.tranAddress(address.attributes.address_name)}</p>
                        <p style={{
                          backgroundColor: 'white',
                          width: '94px',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>{address.attributes.is_default ? this.tranAddress("Default") : null}</p>
                      </div>
                      <div style={{ position: 'relative', cursor: "pointer" }}>
                        <img src={threeDot} data-test-id='clickbyyyy' onClick={() => this.toggleDropdown1(address.id)} />
                        {this.renderDropdown(address)}
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "17px", fontSize: "16px", lineHeight: "26px", fontWeight: 700, color: "#375280" }}>
                      <p>{address.attributes.name}</p>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <p>{address.attributes.country_code}</p>
                        <p>{address.attributes.phone_number}</p>
                      </div>
                    </div>
                    <div style={{ fontSize: "14px", fontWeight: 400, color: "#94A3B8" }}>
                      {address.attributes.street}, {address.attributes.block}, {address.attributes.area},<br />
                      {address.attributes.city} - {address.attributes.zipcode}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </>
    )

  }
  desktopView = () => {
    if (window.innerWidth <= 950) return null;
    return (
      <>
        <Grid item id="desktop-grid">
          <div
            id="desktop-nav-container"
            style={{ ...webStyle.innerContainer11, margin: this.navAddAddressHeaderMargin() }}
          >
            <Typography
              id="desktop-home-link"
              style={webStyle.innerContainer12}
              onClick={() => this.handleNavigation("LandingPage")}
            >
              {this.tranAddress("Home")}
            </Typography>
            <img id="desktop-vector-icon" src={Vector} style={{ transform: i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)" }} />
            <Typography id="desktop-address-label" style={{ ...webStyle.innerContainer23, fontWeight: 700 }}>
              {this.tranAddress("Address")}
            </Typography>
          </div>
          <div id="desktop-user-container">
            <Typography id="desktop-user-info" style={{ ...webStyle.innerContainer3, margin: this.navAddAddressHeaderMargin() }}>
              <img id="desktop-scroll-icon" src={scroll} />
              {this.state.fullName}
            </Typography>
          </div>
          <Typography id="desktop-welcome-text" style={{ ...webStyle.innerContainer4, margin: this.navAddAddressNameHeaderMargin() }}>
            {this.tranAddress("Welcome to your Account")}
          </Typography>
          <Sidebar id="desktop-sidebar" navigation={this.props.navigation} />
        </Grid>

        <Grid item xs={12} md={6} id="desktop-address-section" style={{ paddingInline: 20, margin: "0px auto" }}>
          <div id="desktop-address-container">
            <div id="desktop-address-title" style={{
              fontSize: '20px',
              fontWeight: 800,
              lineHeight: '33.5px',
              marginBottom: '29px',
              color: "#375280",
              fontFamily: 'Lato , sans-serif',
              marginTop: "110px",
            }}> {this.tranAddress("Address")}</div>
            <div id="desktop-add-address-container" style={webStyle.addAddressContainer as React.CSSProperties} >
              <div id="desktop-add-address-button" onClick={this.getNavigationMessage} style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: '4px', color: "#375280" }}>
                <img id="desktop-add-icon" src={plus} style={{ cursor: "pointer" }} />
                {this.tranAddress("Add Address")}
              </div>
            </div>
            <div id="desktop-address-list-container" style={{ backgroundColor: '#F8F8F8' }} >
              <div id="desktop-address-list">
                {this.state.addressData && this.state.addressData.map((address: Address, index: number) => (
                  <div key={address.id} id={`desktop-address-${index}`} style={webStyle.addAddressContainer}>
                    <div id={`desktop-address-header-${index}`} style={{
                      fontSize: "16px", fontWeight: 700, lineHeight: "26px", color: "#94A3B8",
                      display: 'flex', alignItems: "center", gap: '17px', justifyContent: "space-between", fontFamily: "Lato , sans-serif",
                    }}>
                      <div id={`desktop-address-name-container-${index}`} style={{ display: 'flex', alignItems: "center" }}>
                        <p id={`desktop-address-name-${index}`} style={{
                          backgroundColor: '#F6F6F6',
                          width: '94px',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>{this.tranAddress(address.attributes.address_name)}</p>
                        <p id={`desktop-address-default-${index}`} style={{
                          backgroundColor: 'white',
                          width: '94px',
                          height: '36px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}>{address.attributes.is_default ? this.tranAddress("Default") : null}</p>
                      </div>
                      <div id={`desktop-address-options-${index}`} style={{ position: 'relative', cursor: "pointer" }}>
                        <img id={`desktop-options-icon-${index}`} src={threeDot} onClick={() => this.toggleDropdown1(address.id)} />
                        {this.renderDropdown(address)}
                      </div>
                    </div>

                    <div id={`desktop-address-user-${index}`} style={{ display: "flex", alignItems: "center", gap: "17px", fontSize: "16px", lineHeight: "26px", fontWeight: 700, color: "#375280" }}>
                      <p id={`desktop-user-name-${index}`}>{address.attributes.name}</p>
                      <div id={`desktop-phone-container-${index}`} style={{ display: "flex", gap: "5px" }}>
                        <p id={`desktop-country-code-${index}`}>{address.attributes.country_code}</p>
                        <p id={`desktop-phone-number-${index}`}>{address.attributes.phone_number}</p>
                      </div>
                    </div>
                    <div id={`desktop-address-details-${index}`} style={{ fontSize: "14px", fontWeight: 400, color: "#94A3B8" }}>
                      {address.attributes.street}, {address.attributes.block}, {address.attributes.area},<br />
                      {address.attributes.city} - {address.attributes.zipcode}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Grid>
      </>
    )
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        {this.state.stylishbuyer ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />}
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} container dir={i18n.dir()}>
          {this.MobileView()}
          {this.desktopView()}
        </Grid>
        <Snackbar
          data-test-id="alertTestId"
          open={this.state.isAlert}
          onClose={this.oncloseAlert}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Footer navigation={this.props.navigation} />
        <DiologcontentStyledMain
          open={this.state.isDeleteDialogOpen}
          data-test-id="handleCloseDeleteDialog"
          onClose={this.handleCloseDeleteDialog}
        >
          <DiologcontentStyled>
            <Typography style={{ width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>
              {this.tranAddress("Are you sure you want to delete this address?")}
            </Typography>
          </DiologcontentStyled>
          <DialogActions style={{ display: 'flex', flexDirection: i18n.dir() === 'ltr' ? 'row' : 'row-reverse', gap: '10px', marginBottom: '10px' }}>
            <Button onClick={this.handleCloseDeleteDialog} style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "#375280",
              backgroundColor: "white",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }}>
              {this.tranAddress("No")}
            </Button>
            <Button onClick={this.handleConfirmDelete} data-test-id="handleConfirmDelete" style={{
              alignItems: "center",
              fontSize: "15px",
              justifyContent: "center",
              fontWeight: 500,
              display: "flex",
              color: "white",
              backgroundColor: "#CCBEB1",
              border: "1px solid #CCBEB1",
              height: "46px",
              borderRadius: "5px",
              width: "50%",
              padding: "10px 16px 10px 16px",
              cursor: "pointer"
            }} >
              {this.tranAddress("Yes")}
            </Button>
          </DialogActions>
        </DiologcontentStyledMain>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const DiologcontentStyled = styled(DialogContent)({
  '&.MuiDialogContent-root:first-child': {
    paddingTop: '30px',
    textAlign: 'center',
  },

  // MuiDialog-paperWidthSm
})
const DiologcontentStyledMain = styled(Dialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '450px'
  },
  '& .MuiPaper-rounded': {
    borderRadius: '12px',

  }
  // MuiDialog-paperWidthSm
})
const webStyle = {
  addAddressContainer: {
    fontFamily: 'Lato , sans-serif',
    fontWeight: 700,
    fontSize: '20px',
    boxShadow: "0px 2px 8px 0px #00000014",
    marginBottom: "24px",
    lineHeight: '26px',
    backgroundColor: "#FFFFFF",
    padding: "16px 12px 16px 16px",
  },

  dropdown: {
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 0px #00000014',
    backgroundColor: 'white',
    zIndex: 1,
    left: 0,
    width: '120px',
    top: '100%',
    position: 'absolute',
  },
  dropdownP: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#375280",
    paddingRight: '6px',
    paddingLeft: '6px',
    lineHeight: "22px",
    paddingBottom: '10px',
    borderBottom: '1px solid #D5D5D5',
  },
  dropdownP1: {
    fontSize: "12px",
    color: "#DC2626",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  dropdownDefault: {
    fontSize: "12px",
    color: "#375280",
    fontWeight: "600",
    paddingRight: '6px',
    lineHeight: "22px",
    paddingLeft: '6px',
  },
  innerContainer11: {
    gap: "10px",
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    color: "#475569",
    cursor: "pointer"
  },
  innerContainer12: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
  },
  innerContainer23: {
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569"
  },
  innerContainer3: {
    alignItems: "center",
    display: "flex",
    color: "#375280",
    fontWeight: 800,
    gap: "15px",
    fontStyle: "normal",
    fontSize: "28px",
    fontFamily: "Lato , sans-serif",
  },
  innerContainer4: {
    color: "#807D7E",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
  },
};


// Customizable Area End
