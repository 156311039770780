import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import i18n, { i18nUpdate } from "../../../components/src/i18next/i18n"
import storage from '../../../framework/src/StorageProvider';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { apiCall,ApiKey } from "../../../components/src/APICall";
import { checkCondition, logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import { message } from "../../customisableuserprofiles2/src/assets";
import { ICountryResp } from "../../../components/src/CountryDropDown.web";

type AlertType = 'success' | 'error' | 'warning' | 'info';
interface ErrorsUplod {
  errorMsgCombine?: string;
  errorMsgLicense?: string;
  errorMsgSignatures?: string;
  errorMsgmoaOrAoa?: string;
  errorMsgBankAccount?: string;
}
interface BankDetail {
  id: string;
  type: string;
  attributes: {
    bank_name: string;
    account_holder_name: string;
    bank_code: string;
    account_number: string;
    iban: string;
    is_default: boolean;
  };
}

interface StylistAccountAttributes {
  status: number;
  preferred_language: string;
  location_info: string;
  address: string;
  contact_details: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  pinterest: string;
  biography: string;
  year_of_experience: string;
  expertise: string;
  block: string;
  mall_name: string;
  floor: string;
  unit_number: number;
  city: string;
  zipcode: string;
  contact_number: string;
  google_pay: string | null;
  apple_pay: string | null;
  account_id: number;
  passport_status: string | null;
  moa_status: string | null;
  authorized_signature_status: string | null;
  commercial_license_status: string | null;
  business_bank_account_status: string | null;
  country_code: string | null;
  phone_number: string | null;
  full_phone_number: string | null;
  skip: boolean;
  bank_detail: {
    data: BankDetail[];
  };
  stylist_redirect_flag: string | null;
  passport: string | null;
  authorized_signature: string | null;
  commercial_license: string | null;
  moa: string | null;
  business_bank_account: string | null;
  profile_picture: string;
}

interface StylistAccount {
  id: string;
  type: string;
  attributes: StylistAccountAttributes;
}
export interface ActiveBread {
  activeBread: "1" | "2" | "3";
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
interface FormErrors {
  profileBio?: string;
  areaofExpertise?: string;
  yearsofExperience?: string;
  company_contact_number?: string;
  preferredLanguage?: string;
  Address?: string;
  Area?: string;
  instagram?: string;
  facebook?: string;
  tiktok?: string;
  pinterest?: string;
  accountName?: string;
  IBANNumber?: string;
  accountNumber?: string;
}
interface FormFieldData {
  profileBio: string;
  areaofExpertise: string;
  yearsofExperience: string | number;
  Address: string;
  Instructions: string;
  zipCode: string | number;
  accountName: string;
  IBANNumber: string | number;
  accountNumber: string | number;
  City: string;
  Floor: string;
  unitNumber: string;
  mallName: string;
  Block: string;
  Area: string;
  company_contact_number: string | number;
  instagram: string;
  facebook: string;
  tiktok: string;
  pinterest: string;
  latitude: number | null,
    longitude:number | null,
    country_code_name: string,
    country_code: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  alertMsg: string;
  errorsUplod: ErrorsUplod;
  alertType: AlertType;
  visible: boolean,
  emailError: boolean;
  countrySuccess: Array<CountrySuccess>;
  selectedCountry: CountrySuccess;
  formData: FormFieldData;
  profileId: null;
  numberData: string;
  errors: FormErrors;
  errorsSelectLanguage: boolean;
  selectedFile: (File | null)[];
  combineImg: (string | null)[];
  presentImage: { selectedProFiles: (File | null)[], updateProImages: (string | null)[], description: string }[];
  isCountryData: string;
  presentImageCombineImg: { selectedFilecivilID: (File)[], combineImgcivilID: (string | null)[], newSelectedFilesType: string[] }[];
  commercialLicense: { selectedCommercialLicense: (File)[], combineImgCommercialLicense: (string | null)[], newSelectedCommercialLicense: string[] }[];
  authorizedSignatures: { selectedAuthorizedSignatures: (File)[], combineImgAuthorizedSignatures: (string | null)[], newSelectedAuthorizedSignatures: string[] }[];
  moaOrAoa: { selectedMoaOrAoa: (File)[], combineImgMoaOrAoa: (string | null)[], newSelectedFMoaOrAoa: string[] }[];
  businessBankAccount: { selectedBankAccount: (File)[], combineImgBankAccount: (string | null)[], newSelectedBankAccount: string[] }[];
  uplodeProfileEdite: StylistAccount | null
  isAlert: boolean;
  validateNumber: boolean;
  validateIBAN: boolean;
  mapVisible: boolean;
  selectedLocation: { lat: number; lng: number } | null;
  currentLocation: { lat: number; lng: number } | null;
  mapsLoaded: boolean;
  searchQuery: string; 
  errorState: {
    attachmentErrorMessage: string;
    isAttachmentError: boolean;
  },
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylistAccountActiveProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailLoginFormAPICallId: string = "";
  countryCodeAPIEditCallId: string = '';
  AccountActiveApiCallId: string = '';
  createProfileApiCallId: string = '';
  uplodeProfileApiCallId: string = '';
  customProfileGetCallId: string = '';
  createUplodeDocumentApiCallId: string = '';
  validPhoneNumberAPICallId: string = "";
  validIBANAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      alertMsg: "",
      errorsUplod:{},
      alertType: 'success' as AlertType,
      isAlert: false,
      visible: false,
      uplodeProfileEdite: null,
      profileId: null,
      businessBankAccount: [],
      moaOrAoa: [],
      authorizedSignatures: [],
      presentImageCombineImg: [],
      presentImage: [],
      commercialLicense: [],
      isCountryData: "+965",
      countrySuccess: [{
        numeric_code: "",
        country_full_name: "",
        country_code: "",
        country_flag: ""
      }],
      emailError: false,
      selectedCountry: { country_code: "KW", numeric_code: "+965" ,country_full_name: "", country_flag: ""},
      formData: {
        company_contact_number: "",
        instagram: "",
        facebook: "",
        tiktok: "",
        pinterest: "",
        profileBio: "",
        areaofExpertise: "",
        yearsofExperience: "",
        Address: "",
        Instructions: "",
        zipCode: "",
        accountName: "",
        IBANNumber: "",
        accountNumber: "",
        City: "",
        unitNumber: "",
        Floor: "",
        mallName: "",
        Block: "",
        Area: "",
        latitude: null,
    longitude: null,
    country_code_name: "",
    country_code: ""
      },
      searchQuery: "",
    mapVisible: false,
      selectedLocation: null,
      currentLocation: null,
      mapsLoaded: false,
      numberData: "Select Language",
      errors: {},
      errorsSelectLanguage: false,
      selectedFile: [],
      combineImg: [],
      validateNumber: false,
      validateIBAN: false,
      errorState: {
        attachmentErrorMessage: "",
        isAttachmentError: false,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    i18nUpdate()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    await this.handleValidNumber(message);
    await this.handleIBANNumber(message)
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.countryCodeAPIEditCallId !== null &&
      this.countryCodeAPIEditCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ countrySuccess: responseJson as CountrySuccess[] },()=>this.profileDataGet() )
    }

    await this.handleCreateProfileResp(message);
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.uplodeProfileApiCallId !== null &&
      this.uplodeProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ isAlert: true, alertMsg: this.transStylist("Your protfolio has been updated"), alertType: 'success' }, () => {
          setTimeout(() => { this.uplodeDocuments() }, 1000);
        })
      }
    }
    this.handleGetProfileResp(message);
    this.uplodeDoc(message);
    // Customizable Area End
  }

  // Customizable Area Start
  getCountryByCode = (code: string): string => {
    const country = this.state.countrySuccess.find((country) => country.numeric_code === code);
    return country ? country.country_code : "KW";
  };

  handleGetProfileResp = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.customProfileGetCallId !== null &&
      this.customProfileGetCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message === "Not Stylist Custom Account Found") return;
      const { attributes } = responseJson.data;
          
            let countryData: string = "";
            
            if (!attributes.country_code_name || !attributes.country_code) {
              const countryCode = checkCondition(attributes.country_code===null, "+965", `+${attributes.country_code.toString()}`) as string;
              countryData = this.getCountryByCode(countryCode);
            }
      const mappedFormData = {
        company_contact_number: responseJson.data.attributes.contact_number,
        instagram: responseJson.data.attributes.instagram,
        facebook: responseJson.data.attributes.facebook,
        tiktok: responseJson.data.attributes.tiktok,
        pinterest: responseJson.data.attributes.pinterest,
        profileBio: responseJson.data.attributes.biography,
        areaofExpertise: responseJson.data.attributes.expertise,
        yearsofExperience: responseJson.data.attributes.year_of_experience,
        Address: responseJson.data.attributes.address,
        Instructions: responseJson.data.attributes.location_info,
        zipCode: checkCondition(responseJson.data.attributes.zipcode!==undefined, responseJson.data.attributes.zipcode, "") as string,
        accountName: responseJson.data.attributes.bank_detail.data[0].attributes.account_holder_name,
        IBANNumber: responseJson.data.attributes.bank_detail.data[0].attributes.iban,
        accountNumber: responseJson.data.attributes.bank_detail.data[0].attributes.account_number,
        City: responseJson.data.attributes.city,
        unitNumber: checkCondition(responseJson.data.attributes.unit_number!==undefined, responseJson.data.attributes.unit_number, "") as string,
        Floor: responseJson.data.attributes.floor,
        mallName: responseJson.data.attributes.mall_name,
        Block: responseJson.data.attributes.block,
        Area: responseJson.data.attributes.block,
        latitude: responseJson.data.attributes.latitude,
        longitude: responseJson.data.attributes.longitude,
      };
      
      this.setState((prevState)=>({
        ...prevState,
        selectedCountry: {...prevState.selectedCountry, country_code: countryData ? countryData : attributes.country_code_name, numeric_code: responseJson.data.attributes.country_code,country_full_name: "",
          country_flag: ""},
        formData: {...prevState.formData, ...mappedFormData}, combineImg: [responseJson.data.attributes.profile_picture], numberData: responseJson.data.attributes.preferred_language, profileId: responseJson.data.id
      }));
    }
  }

  handleCreateProfileResp = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createProfileApiCallId !== null &&
      this.createProfileApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        const storageKeys = [
          'combineImg',
          'profileBio',
          'areaofExpertise',
          'yearsofExperience',
          'instagram',
          'facebook',
          'tiktok',
          'pinterest',
          'numberData',
          'Address',
          'Area',
          'Block',
          'mallName',
          'Floor',
          'unitNumber',
          'City',
          'zipCode',
          'Instructions',
          'accountName',
          'accountNumber',
          'IBANNumber',
          'selectedFile',
          'company_contact_number'
        ];
        storageKeys.forEach((keys) => removeStorageData(keys));
        this.setState({ isAlert: true, alertMsg: this.transStylist("Your protfolio has been updated"), alertType: 'success' });
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistPortfolioAcitve');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        this.send(message);
      } else if (responseJson.error && !responseJson.data) {
        this.setState({ errors: { company_contact_number: responseJson.error } });
      } else if (responseJson.errors[0].stylist_account[0] === "Iban has already been taken" && !responseJson.data) {
        this.setState({ errors: { IBANNumber: responseJson.errors[0].stylist_account[0] } });
      }
    }
  }

  uplodeDoc = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createUplodeDocumentApiCallId !== null &&
      this.createUplodeDocumentApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ visible: true })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  transStylist = (klog: string) => {
    return i18n.t(klog, { ns: "stylist" })
  };

  scrollToSectionID = (sectionIdName:string) => {
    const section = document.getElementById(sectionIdName);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  initializeAutocomplete = (input: HTMLInputElement) => {
    const autocompleted = new window.google.maps.places.Autocomplete(input);
    autocompleted.setFields(["geometry", "formatted_address"]);
  
    autocompleted.addListener("place_changed", () => {
      const place = autocompleted.getPlace();
  
      if (place.geometry && place.geometry.location) {
        const { lat, lng } = place.geometry.location;
        const AccountAddress = place.formatted_address || "";
  
        this.setState((prevState) => ({
          selectedLocation: { lat: lat(), lng: lng() },
          currentLocation: { lat: lat(), lng: lng() },
          formData: {
            ...prevState.formData,
            latitude: lat(),
            longitude: lng(),
            Address: AccountAddress,
          },
          mapVisible: false,
        }));
      } else {
        console.error("No geometry found for this place.");
      }
    });
  };
  handleScriptLoad = () => {
  this.setState({ mapsLoaded: true });

  const input = document.getElementById("autocompleted") as HTMLInputElement;
  if (input) {
    this.initializeAutocomplete(input);
  }
};

  handleValidNumber = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validPhoneNumberAPICallId !== null &&
      this.validPhoneNumberAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors){
         if (!responseJson.company_contact_valid) {
            this.setState({ errors: { company_contact_number: `${this.state.isCountryData} ${this.state.formData.company_contact_number} ${this.transStylist("is not a valid phone number")}`}, validateNumber: false });
            this.scrollToSectionID("phoneNumber");
          } else {
            this.setState({ validateNumber: true }, async ()=> await this.validateIBAN())
          }
      } else if(responseJson && responseJson.errors){
        this.handleInvalidToken(responseJson.errors[0].token)
    }
    }
  }

  handleIBANNumber = async (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.validIBANAPICallId !== null &&
      this.validIBANAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = await message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (responseJson && !responseJson.errors){
         if (!responseJson.owner_iban_valid) {
        this.setState({ errors: { IBANNumber: this.transStylist('IBAN Number is not a valid.') }, validateIBAN: false })
        this.scrollToSectionID("IBANNumber");
      } else {
        this.setState({ validateIBAN: true }, ()=> this.getNavigationDocument())
      }} else if(responseJson && responseJson.errors){
        this.handleInvalidToken(responseJson.errors[0].token)
    }
    }
  }


  handleInvalidToken = (messageText: string) =>{
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true, alertMsg: messageText, alertType: 'error' 
    }), () => {
      setTimeout(() => {
        this.handleHomeRedirect("Home");
        logoutSellerNavigation();
      }, 2000);
    });
  }

  handleHomeRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }

  fetchFormDataFromStorage = async (fields: string[]) => {
    const formData: Partial<FormFieldData> = {};

    for (const field of fields) {
      formData[field as keyof FormFieldData] = await storage.get(field, this.state.formData[field as keyof FormFieldData]) || "";
    }

    return formData;
  }

  async componentDidMount() {
    this.toggleVariantsListOffer()

    const fieldsToFetch = [
      'profileBio',
      'areaofExpertise',
      'yearsofExperience',
      'instagram',
      'facebook',
      'tiktok',
      'pinterest',
      'Address',
      'Area',
      'Block',
      'mallName',
      'Floor',
      'unitNumber',
      'City',
      'zipCode',
      'Instructions',
      'company_contact_number',
      'IBANNumber',
      'accountName',
      'accountNumber',
      'country_code',
      'country_code_name'
    ];

    const formDataFromStorage = await this.fetchFormDataFromStorage(fieldsToFetch);

    const numberData = await storage.get('numberData', this.state.numberData) || "";
    const newCombin = await getStorageData('combineImg') || "";
    // const combineImg = JSON.parse(newCombin);

    this.setState({
      numberData: numberData,
      // combineImg: combineImg === null ? [] : combineImg,
      formData: {
        ...this.state.formData,
        ...formDataFromStorage,
      },
      selectedCountry:{
        numeric_code: formDataFromStorage.country_code || "+965",
        country_full_name: "",
        country_code: formDataFromStorage.country_code || "KW",
        country_flag: ""
      }
    });
    if (!document.getElementById("google-maps-script")) {
      const script = document.createElement("script");
      script.id = "google-maps-script";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
      script.async = true;
      script.onload = this.handleScriptLoad;
      document.body.appendChild(script);
    } else {
      this.handleScriptLoad();
    }
  }

  handleSingleChangeNumber = (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
    const { value } = event.target;
    this.setState({
      numberData: value as string,
      errorsSelectLanguage: false,
    });
  };

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const isValid = this.firstPageValidateForm();
    if (this.state.numberData == "" || this.state.numberData == "Select Language") {
      this.setState({ errorsSelectLanguage: true })
    } else {
      this.setState({ errorsSelectLanguage: false })
      if (isValid) {
        this.getNavigationActiveAddress();
      }
    }

  };
  setStorage(key: string, value: string | number) {
    storage.set(key, value || '');
  }
  setValue = (value: string) => {
    return value ? value : ""
  }
  getNavigationActiveAddress = () => {
    this.setStorage('combineImg', JSON.stringify(this.state.combineImg))
    this.setStorage('profileBio', this.state.formData.profileBio)
    this.setStorage('areaofExpertise', this.state.formData.areaofExpertise)
    this.setStorage('yearsofExperience', this.state.formData.yearsofExperience)
    this.setStorage('instagram', this.setValue(this.state.formData.instagram))
    this.setStorage('facebook', this.setValue(this.state.formData.facebook))
    this.setStorage('tiktok', this.setValue(this.state.formData.tiktok))
    this.setStorage('pinterest', this.setValue(this.state.formData.pinterest))
    this.setStorage('numberData', this.state.numberData)

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistContactPayment');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { formData, errors } = this.state;
    const { name, value } = e.target;
    let updatedValue: string | number = value;
    if (['yearsofExperience', 'zipCode', 'company_contact_number', 'unitNumber', 'accountNumber'].includes(name)) {
      const numberValue = parseFloat(value);
      updatedValue = !isNaN(numberValue) ? numberValue : '';
    }
    if (['areaofExpertise', 'City'].includes(name)) {
      updatedValue = value.replace(configJSON.addNumber, '');
    }
    const updatedErrors = { ...errors };
    if (updatedErrors[name as keyof FormErrors]) {
      delete updatedErrors[name as keyof FormErrors];
    }
    this.setState(() => ({
      formData: {
        ...formData,
        [name]: updatedValue,
      },
      errors: updatedErrors
    }));
  };

  handleStylishAccountChange = (eventStylishCoutry: ICountryResp) => {
    this.setState((prevState) => ({
      selectedCountry: {...prevState.selectedCountry, country_code: eventStylishCoutry.country_code, numeric_code: eventStylishCoutry.numeric_code,country_full_name: prevState?.selectedCountry?.country_full_name ?? "",
        country_flag: prevState?.selectedCountry?.country_flag ?? "",},
      isCountryData: eventStylishCoutry.numeric_code,
    }));
  };

  openPictureUpload = (index: number) => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.openProfileUploadInSideMain(event, index);
    };
  }
  openProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, index: number) => {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files || files.length === 0) {
      return;
    }
    const file = files[0];
    const base64 = URL.createObjectURL(file);
    this.fileReader(file)
    this.setState({
      combineImg: [base64],
      selectedFile: [file],
    });
  }
  fileReader = (file: File) => {
    const reader = new FileReader();
    reader.onload = () => {
      setStorageData('selectedFile', JSON.stringify({ file: reader.result, fileName: file.name }));
    };
    reader.readAsDataURL(file)
  }
  convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  handleRemove = () => {
    this.setState({ selectedFile: [], combineImg: [] });
  };

  firstPageValidateForm = () => {
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};
    const requiredFields = [
      { name: "profileBio", message: `*${this.transStylist('Please enter your profile bio')}` },
      { name: "areaofExpertise", message: `*${this.transStylist('Please enter Area of Expertise')}`},
      { name: "yearsofExperience", message: `*${this.transStylist('Please enter Your Years of Experience')}` },
    ];
    requiredFields.forEach(field => {
      const value = formData[field.name as keyof typeof formData];
      if (typeof value === "string" && value.trim() === "") {
        errors[field.name] = field.message;
      }
    });

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  secondPageValidateForm = () => {
    const { formData } = this.state;
    const errors: { [key: string]: string } = {};
    const requiredFieldsSecondPage = [
      { name: "Address", message:`*${this.transStylist('Please enter your Address')}` },
      { name: "accountName", message: `*${this.transStylist('Please enter Account holder')}` },
      { name: "IBANNumber", message: `*${this.transStylist('Please enter your IBAN Number')}` },
      { name: "accountNumber", message: `*${this.transStylist('Please enter Account Number')}` },
      { name: "company_contact_number", message: `*${this.transStylist('Please enter Your Contact Number')}`},
    ];
    requiredFieldsSecondPage.forEach(field => {
      const secondPagevalue = formData[field.name as keyof typeof formData];
      if (typeof secondPagevalue === "string" && secondPagevalue.trim() === "") {
        errors[field.name] = field.message;
      }
    });
    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };
  secondPagehandleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    const isValid = this.secondPageValidateForm();
    if (isValid) {
      await this.validateNumber();
    }
  };

  validateNumber = async () => {
      const {formData, isCountryData} =this.state;
      const formDataCode = new FormData();
      formDataCode.append("data[attributes][company_contact_number]", `${isCountryData}${formData.company_contact_number}`);
      this.validPhoneNumberAPICallId = await apiCall({
        token: await getStorageData("auth-token"),
        body: formDataCode,
        isJsonStringify: false,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.validPhoneNumEndPoint,
      });
  }

  validateIBAN = async () => {
    const {formData} =this.state;
      const formDataCode = new FormData();
      formDataCode.append("data[attributes][iban]", formData.IBANNumber.toString());
      this.validIBANAPICallId = await apiCall({
        token: await getStorageData("auth-token"),
        body: formDataCode,
        isJsonStringify: false,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.validIBANEndPoint,
      });
  }
  getFileFromLocalStorage = async (): Promise<File | null> => {
    const getNewFile: string | null = await getStorageData('selectedFile');
    if (!getNewFile) {
      return null;
    }
    const base64String = JSON.parse(getNewFile);

    if (base64String.file) {
      const byteString = atob(base64String.file.split(',')[1]);
      const mimeString = base64String.file.split(',')[0].split(':')[1].split(';')[0];
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      const file = new File([intArray], base64String.fileName, { type: mimeString });
      return file;
    }
    return null;
  };
  async getNavigationDocument() {
    this.setStorage('Address', this.state.formData.Address)
    this.setStorage('Area', this.state.formData.Area)
    this.setStorage('Block', this.state.formData.Block)
    this.setStorage('mallName', this.state.formData.mallName)
    this.setStorage('Floor', this.state.formData.Floor)
    this.setStorage('unitNumber', this.state.formData.unitNumber)
    this.setStorage('City', this.state.formData.City)
    this.setStorage('zipCode', this.state.formData.zipCode)
    this.setStorage('Instructions', this.state.formData.Instructions)
    this.setStorage('company_contact_number', this.state.formData.company_contact_number)
    this.setStorage('IBANNumber', this.state.formData.IBANNumber)
    this.setStorage('accountName', this.state.formData.accountName)
    this.setStorage('accountNumber', this.state.formData.accountNumber)
    this.setStorage('country_code_name', this.state.selectedCountry.country_code)
    this.setStorage('country_code', this.state.selectedCountry.numeric_code)

    let selectLg = await getStorageData("numberData")
    let yearsofExperience = await getStorageData("yearsofExperience")
    let profileBio = await getStorageData("profileBio")
    let areaofExpertise = await getStorageData("areaofExpertise")
    let instagram = await getStorageData("instagram")
    let facebook = await getStorageData("facebook")
    let tiktok = await getStorageData('tiktok')
    let pinterest = await getStorageData('pinterest')

    const token = await getStorageData("auth-token")
    const selectedFilenew: File | null = await this.getFileFromLocalStorage();
    const formData = new FormData()
    selectedFilenew && formData.append('profile_picture', selectedFilenew);
    formData.append('biography', profileBio);
    formData.append('expertise', areaofExpertise);
    formData.append('year_of_experience', yearsofExperience);
    { instagram !== null && formData.append('instagram', instagram) }
    { facebook !== null && formData.append('facebook', facebook) }
    { tiktok !== null && formData.append('tiktok', tiktok) }
    { pinterest !== null && formData.append('pinterest', pinterest) }
    formData.append('preferred_language', selectLg);
    formData.append('address', this.formDataProfilr('Address'));
    formData.append('location_info', this.formDataProfilr('Area'));
    formData.append('block', this.formDataProfilr('Block'));
    formData.append('mall_name', this.formDataProfilr('mallName'));
    formData.append('floor', this.formDataProfilr('Floor'));
    formData.append('unit_number', this.formDataProfilr('unitNumber'));
    formData.append('city', this.formDataProfilr('City'));
    formData.append('account_holder_name', this.formDataProfilr('accountName'))
    formData.append('contact_number', this.state.isCountryData.replace("+", "") + this.formDataProfilr('company_contact_number'))
    formData.append('zipcode', this.formDataProfilr('zipCode'));
    formData.append('bank_name', this.formDataProfilr('accountName'));
    formData.append('iban', this.formDataProfilr('IBANNumber'));
    formData.append('account_number', this.formDataProfilr('accountNumber'));
    formData.append('latitude',this.formDataProfilr('latitude'))
    formData.append('longitude',this.formDataProfilr('longitude'));
    formData.append('country_code_name', this.formDataProfilr('country_code_name'))
    formData.append('country_code', this.formDataProfilr('country_code'))
    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createProfileApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.state.profileId == null ? configJSON.loginAPiMethod : configJSON.customProfilePutApi,
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.state.profileId == null ? configJSON.createProfileApi : `${configJSON.customProfilePut}/${this.state.profileId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  formDataProfilr = (dataKey: keyof FormFieldData): string => {
    let fromFiled = this.state.formData[dataKey]
    return fromFiled ? fromFiled.toString() : ''
  }

  getNavigationProfile = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistAccountActiveProfile');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  toggleVariantsListOffer = async () => {
    const header = {
      "Content-Type": configJSON.countryApiContentType,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeAPIEditCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryCodeEndPoint
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  handleUploadAddPortfolioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editFiles = event.target.files;
    if (editFiles) {
      const newEditFiles = Array.from(editFiles);
  
      const validFiles: File[] = [];
      const invalidFiles: File[] = [];
  
      const maxFileSize = 10 * 1024 * 1024;
      const allowedExtensions = /\.(jpg|jpeg|png)$/i;
  
      newEditFiles.forEach((file) => {
        const isValidType = allowedExtensions.test(file.name);
        const isValidSize = file.size <= maxFileSize;
  
        if (isValidType && isValidSize) {
          validFiles.push(file);
        } else {
          invalidFiles.push(file);
        }
      });
  
      if (invalidFiles.length > 0) {
        const errorMessage = invalidFiles.map((file) => {
          const isValidSize = file.size <= maxFileSize;
          return `${file.name} ${!isValidSize 
            ? this.transStylist("exceeds the 10MB size limit.")
            : this.transStylist("has an invalid file type.")}`;
        }).join(" ");
  
        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            attachmentErrorMessage: errorMessage,
            isAttachmentError: true,
          },
        }));
        return;
      }
  
      if (this.state.presentImage.length + validFiles.length > 10) {
        this.setState((prevState) => ({
          ...prevState,
          errorState: {
            ...prevState.errorState,
            attachmentErrorMessage: this.transStylist("You can only upload up to 10 files."),
            isAttachmentError: true,
          },
        }));
        return;
      }
  
      const newUpdateProImages: string[] = validFiles.map(file => URL.createObjectURL(file));
      const newSelectedProFiles: File[] = validFiles;
  
      this.setState(prevState => ({
        presentImage: [
          ...prevState.presentImage,
          ...newSelectedProFiles.map((file, index) => ({
            selectedProFiles: [file],
            updateProImages: [newUpdateProImages[index]],
            description: '',
          }))
        ],
        errorState: {
          ...prevState.errorState,
          attachmentErrorMessage: "",
          isAttachmentError: false,
        },
      }));
    }
  };

  uplodeApi = async () => {
    const token = await getStorageData("auth-token")
    const formData = new FormData();
    this.state.presentImage.forEach((it: { selectedProFiles: (File | null)[], updateProImages: (string | null)[], description: string }, index: number) => {
      it.selectedProFiles.forEach((file) => {
        if (file) {
          formData.append("images[]", file);
        }
      });
      formData.append("image_descriptions[]", it.description);
    });
    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uplodeProfileApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uplodeProfileApi
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  handleRemoveThad = (index: number) => {
    this.setState(prevState => { return { presentImage: prevState.presentImage.filter((_, numData) => { return index !== numData }) } });
  }
  changeDisc = (index: number, value: string) => {
    this.setState(prevState => { return { presentImage: prevState.presentImage.map((newValue, numData) => { return numData === index ? { ...newValue, description: value } : newValue }) } });
  }

  civilIDPassportopenPictureUpload = (nameUplodeFile: string) => {
    const inputAddPensionTwo = document.createElement('input');
    inputAddPensionTwo.type = 'file';
    inputAddPensionTwo.accept = 'image/png, image/jpeg, image/jpg ,image/pdf';
    inputAddPensionTwo.multiple = false;
    inputAddPensionTwo.click();
    inputAddPensionTwo.onchange = (event: React.ChangeEvent<HTMLInputElement> | Event) => {
      this.civilIDPassportopenProfileUploadInSideMain(event, nameUplodeFile);
    };
  }
  civilIDPassportopenProfileUploadInSideMain = async (event: React.ChangeEvent<HTMLInputElement> | Event, nameUplodeFile: string) => {
    const targetPassport = event.target as HTMLInputElement;
    const files = targetPassport.files;
    if (!files || files.length === 0) {
      return;
    }
    if (nameUplodeFile === "civilIdPassport") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          presentImageCombineImg: [{ selectedFilecivilID: [file], combineImgcivilID: [base64], newSelectedFilesType: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "commercialLicense") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          commercialLicense: [{ selectedCommercialLicense: [file], combineImgCommercialLicense: [base64], newSelectedCommercialLicense: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "authorizedSignatures") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          authorizedSignatures: [{ selectedAuthorizedSignatures: [file], combineImgAuthorizedSignatures: [base64], newSelectedAuthorizedSignatures: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "moaOrAoa") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          moaOrAoa: [{ selectedMoaOrAoa: [file], combineImgMoaOrAoa: [base64], newSelectedFMoaOrAoa: [file.type] }],
        }));
      });
    } else if (nameUplodeFile === "businessBankAccount") {
      Array.from(files).forEach(file => {
        const base64 = URL.createObjectURL(file);
        this.setState(prevState => ({
          businessBankAccount: [{ selectedBankAccount: [file], combineImgBankAccount: [base64], newSelectedBankAccount: [file.type] }],
        }));
      });
    }
  }

  civilIDPassportHandleRemoveThad = (index: number, nameUplode: string) => {
    if (nameUplode === "civilIdPassport") {
      this.setState(prevState => { return { presentImageCombineImg: prevState.presentImageCombineImg.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "commercialLicense") {
      this.setState(prevState => { return { commercialLicense: prevState.commercialLicense.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "authorizedSignatures") {
      this.setState(prevState => { return { authorizedSignatures: prevState.authorizedSignatures.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "moaOrAoa") {
      this.setState(prevState => { return { moaOrAoa: prevState.moaOrAoa.filter((_, numData) => { return index !== numData }) } });
    } else if (nameUplode === "businessBankAccount") {
      this.setState(prevState => { return { businessBankAccount: prevState.businessBankAccount.filter((_, numData) => { return index !== numData }) } });
    }
  }

  uplodeDocuments = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylistUploadDocumentsweb');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  profileDataGet = async () => {
    const token = await getStorageData("auth-token")
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.customProfileGetCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customProfileGet
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  createProfileUplodeDocument = async () => {
    const token = await getStorageData("auth-token")
    const formData = new FormData()

    let hasErrors = false;
    const errorsUplod:ErrorsUplod = {};
    if (!this.state.presentImageCombineImg[0]) {
      errorsUplod.errorMsgCombine = "Please upload Civil ID/Passport";
      hasErrors = true;
    }
    if (!this.state.commercialLicense[0]) {
      errorsUplod.errorMsgLicense = "Please upload Commercial License";
      hasErrors = true;
    }
    if (!this.state.authorizedSignatures[0]) {
      errorsUplod.errorMsgSignatures = "Please upload Authorized Signatures";
      hasErrors = true;
    }
    if (!this.state.moaOrAoa[0]) {
      errorsUplod.errorMsgmoaOrAoa = "Please upload MOA or AOA";
      hasErrors = true;
    }
    if (!this.state.businessBankAccount[0]) {
      errorsUplod.errorMsgBankAccount = "Please upload Business Bank Account";
      hasErrors = true;
    }
    if (hasErrors) {
      this.setState({errorsUplod});
      return;
    }
    
    this.state.presentImageCombineImg[0] && formData.append("passport", this.state.presentImageCombineImg[0].selectedFilecivilID[0])
    this.state.commercialLicense[0] && formData.append("commercial_license", this.state.commercialLicense[0].selectedCommercialLicense[0])
    this.state.authorizedSignatures[0] && formData.append("authorized_signature", this.state.authorizedSignatures[0].selectedAuthorizedSignatures[0])
    this.state.moaOrAoa[0] && formData.append("moa", this.state.moaOrAoa[0].selectedMoaOrAoa[0])
    this.state.businessBankAccount[0] && formData.append("business_bank_account", this.state.businessBankAccount[0].selectedBankAccount[0])
    const header = {
      token: token
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createUplodeDocumentApiCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.uploadDocumentsApi
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadDocumentsPost
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  handleCancel = () => {
    this.setState({ visible: false });
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'StylishDashboard');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  backButtonLgin = () => {
    this.setState({ visible: false });
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}