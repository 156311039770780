import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
    FormControl,
    withStyles,
    Tabs,
    Tab,
    InputBase,
    IconButton,
    WithStyles,
    FormControlLabel,
    Button,
    Divider,
    Snackbar
} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import clsx from 'clsx';
import { Search, calendarpng, Noimageavailablepng, scrollImg, modeofimage } from "./assets";
import { styled } from "@material-ui/core/styles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Header from "../../../components/src/Header"
import Footer from "../../../components/src/Footer"
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import Sidebar from "../../../components/src/Sidebar";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
type StringNumberOrDate = string | number | Date;
export interface IOrderItem {
    id: string;
    type: string;
    attributes: {
        id: string;
        return_type: string;
        quantity: number;
        unit_price: string;
        total_price: string;
        reason_of_rejection: string | null;
        status: string;
        catalogue_name: string;
        brand_name: string;
        catalogue_variant_color: string;
        catalogue_variant_sku: string;
        store_name: string;
        driver_name: string | null;
        catalogue_variant_size: string;
        catalogue_variant_front_image: string;
        catalogue_variant_back_image: string;
        catalogue_variant_side_image: string;
        driver: string | null;
        stylist_full_name: string | null,
        stylist_id: number | null
    }
}
export interface IOrderDetail {
    id: string;
    type: string;
    attributes: {
        order_number: string;
        account: string;
        order_item_count: number;
        sub_total: string;
        total: string;
        status: string;
        placed_at: StringNumberOrDate;
        confirmed_at: StringNumberOrDate;
        in_transit_at: string | null;
        delivered_at: string | null;
        cancelled_at: string | null;
        refunded_at: string | null;
        returned_at: string | null;
        deliver_by: string | null;
        order_status_id: number;
        created_at: string;
        updated_at: string;
        order_deliver_date: StringNumberOrDate;
        order_deliver_time: {
            id: string;
            type: string;
            attributes: {
                name: string;
                country_code: string;
                phone_number: string;
                contact_number: string;
                street: string;
                zip_code: string;
                area: string;
                block: string;
                city: string;
                house_or_building_number: string;
                address_name: string;
                is_default: boolean;
                latitude: number;
                longitude: number;
            };
        };
        delivery_addresses: {
            id: string;
            type: string;
            attributes: {
                name: string;
                country_code: string;
                phone_number: string;
                contact_number: string;
                street: string;
                zip_code: string;
                area: string;
                block: string;
                city: string;
                house_or_building_number: string;
                address_name: string;
                is_default: boolean;
                latitude: number;
                longitude: number;
            };
        };
        order_return_date: string | null;
        order_return_time: string | null;
        delivery_charges: string;
        order_items: IOrderItem[];
        payment_detail: {
            id: number;
            status: string;
            created_at: string;
            updated_at: string;
            charge_id: string;
            merchant_id: string | null;
            order_id: string;
            amount: number;
            currency: string;
            customer_id: string;
            reason: string;
            account_id: number;
            order_management_order_id: number;
            refund_id: string | null;
            refund_amount: number | null;
            refund_reason: string | null;

            seller_order_id: string | null;
            last_four_card_digit: string;
            payment_type: string;
            deleted: boolean;
        };
    }
}
// Customizable Area End

import OrderManagementPageWebController, {
    Props,
} from "./OrderManagementPageWebController";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"

export default class CategoriessubcategoriesWebPage extends OrderManagementPageWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCharge = () => {
        const { modeofreturn, deliveryCharges } = this.state;
        if (modeofreturn === "request_delivery_partner") {
            const charge = deliveryCharges && !isNaN(parseFloat(deliveryCharges)) ? `${this.state.currencySign} ${parseFloat(deliveryCharges).toFixed(2)}` : `${this.state.currencySign} 0.00`;
            return <Typography style={webStyle.totelCharge}>{charge}</Typography>;
        } else {
            return <Typography style={webStyle.pickupCharge}>{this.state.currencySign} 0.00</Typography>;
        }
    }
    renderModeOfReturnButton = (orderId: string, orderStatus: string) => {
        return orderStatus === "confirmed" ? this.renderReturnStatusButton(orderId) : <StyledButton1
            style={{ fontSize: "18px" }}
            data-testid="navigateToModeofReturn"
            onClick={() => this.modeRetuneHandleOpen(orderId)}
        >
            {this.transProfile("Mode of Return")}
        </StyledButton1>;
    }

    renderReturnStatusButton = (orderId: string) => {
        return (
            <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToDeliverdreturned" onClick={() => this.navigateTo({ id: orderId, props: this.props, screenName: "ReturnStatus" })}>{this.transProfile("Return Status")}</StyledButton1>
        );
    }

    renderOrderButtons = (orderId: string, orderStatus: string, returnOrderStatus: string) => {
        const isReturnOrder = ["return_order", "returned", "placed"].includes(orderStatus);
        const isReturnConfirmed = returnOrderStatus === 'return_confirmed';
        if (isReturnOrder) { return isReturnConfirmed ? this.renderModeOfReturnButton(orderId, orderStatus) : this.renderReturnStatusButton(orderId); }
        return this.renderModeOfReturnButton(orderId, orderStatus);
    }

    returnTates = (oderId: string, returnOrderStatus: string) => {
        return returnOrderStatus === 'return_confirmed' ? <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturnOrder" onClick={() => this.retunOrder({ productId: oderId, props: this.props, screenName: "BuyerReturnOrderDetailsWebPage" })}>{this.transProfile("Return Order")}</StyledButton> : null
    }
    styledButton = (label: string, oderId: string, orderStatus: string, returnOrderStatus: string, returnModeStatus: string) => {
        switch (label) {
            case 'Processing':
                return <>
                    <BtnContainer>
                        <StyledButton style={{ fontSize: "18px" }} data-testid="cancelOrderOpen" onClick={() => this.handleOpen1(label, oderId)}>{this.transProfile("Cancel Order")}</StyledButton>
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateTo" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "OrderDetails" })}>{this.transProfile("Order Status")}</StyledButton1>
                    </BtnContainer>
                </>
            case 'Deliverd':
                return <>
                    <BtnContainer>
                        <StyledButton style={{ fontSize: "18px" }} data-testid="cancelOrderOpenReturnOrder" onClick={() => this.retunOrder({ productId: oderId, props: this.props, screenName: "BuyerReturnOrderDetailsWebPage" })}>{this.transProfile("Return Order")}</StyledButton>
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToDeliverd" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "OrderDetails" })}>{this.transProfile("Order Status")}</StyledButton1>
                    </BtnContainer>
                </>
            case 'Return':
                return <>
                    {["return_cancelled", "refunded", "returned"].includes(orderStatus) || returnOrderStatus === "return_cancelled" || returnModeStatus === "request_delivery_partner" ?
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateTorefunded" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: "ReturnStatus" })}>{this.transProfile("Order Status")}</StyledButton1> :
                        <Box>
                            <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturn" onClick={() => this.cancelReturnOpen(oderId)}>Cancel Return</StyledButton>
                            {this.renderOrderButtons(oderId, orderStatus, returnOrderStatus)}
                        </Box>
                    }
                </>
            case 'All Orders':
                return <>
                    <Box>
                        {returnOrderStatus === "return_placed" ?
                            <StyledButton style={{ fontSize: "18px", marginRight: '12px' }} data-testid="cancelOrderOpenReturn" onClick={() => this.cancelReturnOpen(oderId)}>Cancel Return</StyledButton>
                            : this.returnTates(oderId, returnOrderStatus)}
                        <StyledButton1 style={{ fontSize: "18px" }} data-testid="navigateToAllOrder" onClick={() => this.navigateTo({ id: oderId, props: this.props, screenName: returnOrderStatus === "refunded_cancel" ? "ReturnStatus" : "OrderDetails" })}>{this.transProfile("Order Status")}</StyledButton1>
                    </Box>
                </>
            default:
                return null;
        }
    }
    deliveryAddresses = (label: string, delivery_addresses: {
        id: string;
        type: string;
        attributes: {
            name: string;
            country_code: string;
            phone_number: string;
            contact_number: string;
            street: string;
            zip_code: string;
            area: string;
            block: string;
            city: string;
            house_or_building_number: string;
            address_name: string;
            is_default: boolean;
            latitude: number;
            longitude: number;
        };
    }) => {
        const address = this.getAddress(delivery_addresses)
        return (
            <>
                <span style={webStyle.cusName}>{label === "All Orders" ? `${this.transProfile("Shipping Address")}` : `${this.transProfile("Address")}`} &nbsp;:</span>&nbsp;
                <span style={webStyle.customer}>{address}</span>
            </>
        )
    }
    dataPicker = () => (
        <Box position="relative" style={{ display: "none" }}>
            <Box style={webStyle.menBox}>
                <Box style={webStyle.picker}>
                    <Box>
                        <Box style={{ ...webStyle.customer, fontSize: "10px" }}>{this.transProfile("From")}</Box>
                        <Box style={{ ...webStyle.customer, fontSize: "12px" }}>{this.state.startDate ? this.state.startDate : `${this.transProfile('DD-MM-YY')}`}</Box>
                    </Box>
                    <Box>
                        <Box style={{ ...webStyle.customer, fontSize: "10px" }}>{this.transProfile("To")}</Box>
                        <Box style={webStyle.pickerBox}>
                            <Box style={{ ...webStyle.customer, fontSize: "12px" }}>{this.state.endDate ? this.state.endDate : `${this.transProfile('DD-MM-YY')}`}</Box>
                            <Box data-testid="handleInputClick" onClick={this.handleInputClick}><img width="14px" height="14px" src={calendarpng} /></Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Box style={webStyle.ShortBy}>{this.transProfile("Sort by")}:</Box>&nbsp;&nbsp;
                    <Box>
                        <CustomSelect variant="outlined" style={{ width: '196px', backgroundColor: '#ffffff' }}>
                            <MuiSelectSelect
                                style={webStyle.reminderName}
                                value={this.state.numberData}
                                onChange={this.handleSingleChangeNumber}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                data-testid='sub-categories-selection'
                                MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                }}
                            >
                                <MenuItem value="" aria-label="Select Option" data-testid="select-option"><Box style={webStyle.newShort}>{this.transProfile("Most Recent")}</Box></MenuItem>
                                {this.state.short.map((select: string, index: number) => (
                                    <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>
                                ))}
                            </MuiSelectSelect>
                        </CustomSelect >
                    </Box>
                </Box>
            </Box>
            {this.state.showDatePicker && (
                <Box style={{ ...webStyle.datePick, position: "absolute" }}>
                    <DateRangePicker
                        onSelect={this.onChange}
                        singleDateRange={true}
                    />
                </Box>
            )}
        </Box>
    )

    OrderDetails = ({ orderItem, label }: { orderItem: IOrderDetail, label: string }) => {
        return (
            <Box style={webStyle.customerData}>
                <Box>
                    <Box style={{ paddingBottom: '7px' }}>
                        <span style={webStyle.cusName}>{this.transProfile("Customer Name")}&nbsp;:</span>&nbsp;
                        <span style={webStyle.customer}>{orderItem.attributes.delivery_addresses && orderItem.attributes.delivery_addresses.attributes.name}</span>
                    </Box>
                    <Box style={{ paddingBottom: '7px' }}>
                        {this.deliveryAddresses(label, orderItem.attributes?.delivery_addresses)}
                    </Box>
                    {label !== "Processing" && (
                        <Box style={{ paddingBottom: '7px' }}>
                            <span style={webStyle.cusName}>{this.transProfile("Payment Method")}&nbsp;:</span>&nbsp;
                            <span style={webStyle.customer}>{this.transProfile("Debit Card ending")}&nbsp;{orderItem.attributes?.payment_detail?.last_four_card_digit}</span>
                        </Box>
                    )}
                </Box>
                <this.OrderStatusAndDate orderItem={orderItem} />
            </Box>
        );
    };

    OrderStatusAndDate = ({ orderItem }: { orderItem: IOrderDetail }) => {
        return (
            <Box>
                <Box style={{ paddingBottom: '7px', ...webStyle.endContent }}>
                    <Box style={webStyle.cusName}>
                        {this.transProfile("Order Status")}&nbsp;:&nbsp;
                        <span style={{ ...webStyle.customer, textTransform: "capitalize" }}>
                            {orderItem.attributes?.status}
                        </span>
                    </Box>
                </Box>
                <Box style={{ ...webStyle.cusName, ...webStyle.endContent, paddingBottom: '7px' }}>
                    {this.transProfile("Order Date")}&nbsp;:&nbsp;
                    <div style={webStyle.customer}>
                        {new Date(orderItem.attributes?.placed_at).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                        })}
                    </div>
                </Box>
            </Box>
        );
    };

    ProductDetails = ({ item, label }: { item: IOrderItem, label: string }
    ) => {
        return (
            <Box style={webStyle.proData}>
                <img
                    src={item.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : item.attributes.catalogue_variant_front_image}
                    width="96px"
                    height="96px"
                    style={{ borderRadius: "4px" }}
                />
                <Box>
                    <div style={{ ...webStyle.proName, whiteSpace: "nowrap", textOverflow: "ellipsis", width: '100px' }}>
                        {item.attributes.catalogue_name}
                    </div>
                    <div style={{ ...webStyle.cusName, ...webStyle.proDetail }}>
                        <span style={{ ...webStyle.cusName, marginRight: "4px" }}>{this.transProfile("Colour")}:</span>
                        <div style={{ ...webStyle.colorBorder, position: "relative" }}>
                            <div style={{ ...webStyle.colorRound, position: "absolute", backgroundColor: item.attributes.catalogue_variant_color }} />
                        </div>
                        <span style={{ ...webStyle.sizes, marginLeft: i18n.dir() === "ltr" ? "17px" : 0, marginRight: i18n.dir() === "rtl" ? "17px" : 0 }}>
                            {this.transProfile("Size")}&nbsp;:
                        </span>&nbsp;
                        <span style={webStyle.customer}>{item.attributes.catalogue_variant_size}</span>
                    </div>
                    <div style={{ ...webStyle.cusName, color: "#375280", paddingBottom: '5px' }}>
                        {this.transProfile("Qty")} : <span style={webStyle.customer}> {item.attributes.quantity}</span>
                    </div>
                    <div style={{ ...webStyle.cusName, color: "#375280" }}>
                        {this.transProfile("Total")} : <span style={webStyle.customer}>{this.state.currencySign} &nbsp;{item.attributes.total_price}</span>
                    </div>
                    {label !== "Processing" && (
                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                            <Box style={{ marginRight: i18n.dir() === "ltr" ? '17px' : 0 }}>
                                <span style={webStyle.cusName}>{this.transProfile(item.attributes.stylist_full_name === null ? "Store Name" : " Stylish Name")}&nbsp;:</span>&nbsp;
                                <span style={webStyle.customer}>{item.attributes.stylist_full_name === null ? item.attributes.store_name : item.attributes.stylist_full_name}</span>
                            </Box>
                            <Box style={{ marginRight: i18n.dir() === "ltr" ? 0 : '17px' }}>
                                <span style={webStyle.cusName}>{this.transProfile("Delivery Partner")}&nbsp;:&nbsp;</span>
                                <span style={webStyle.customer}>{item.attributes?.driver_name}</span>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    };
    renderHeaderr = () => {
        return this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />
    }
    tabData = (label: string) => (
        <Box>
            {this.state.myOrders?.map((orderItem, index: number) => (
                <Box style={{ ...webStyle.orderContent, marginBottom: '22px' }} key={index}>
                    <Box style={webStyle.orderIdBox}>
                        <Box style={webStyle.orderId}>
                            {this.transProfile("OrderID")} : #{orderItem.attributes?.order_number}
                        </Box>
                        <Box style={{ ...webStyle.cusName, color: "#94A3B8" }}>
                            {orderItem.attributes?.order_items.length}&nbsp;
                            {this.transProfile(orderItem.attributes?.order_items.length > 1 ? "Products" : "Product")}
                        </Box>
                    </Box>
                    <this.OrderDetails orderItem={orderItem} label={label} />
                    <Box style={webStyle.productContainer}>
                        <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                            {orderItem.attributes?.order_items.map((item: IOrderItem, index: number) => (
                                <this.ProductDetails key={index} item={item} label={label} />
                            ))}
                        </Box>
                        <Box style={{ display: "flex", gap: "10px", marginTop: "24px" }}>
                            {this.styledButton(label, orderItem.id, orderItem.attributes?.status, orderItem.attributes?.order_items[0]?.attributes.status, orderItem.attributes?.order_items[0]?.attributes.return_type)}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    );



    NavigationHeader = () => {
        return (
            <>
                <Typography style={webStyle.innerContainer23} data-testid="home-test-id" onClick={() => this.handleNavigationBuyerOrder("LandingPage")} >
                    {this.transProfile("Home")}
                </Typography>
                <NavigateNextRoundedIcon style={{ color: '#375280', transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                <Typography style={webStyle.innerContainer23} data-testid="my-account-test-id" onClick={() => this.handleNavigationBuyerOrder("Customisableuserprofiles2")}>
                    {this.transProfile("My Account")}
                </Typography>

                <NavigateNextRoundedIcon style={{ color: '#375280', transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                <Typography style={{ ...webStyle.innerContainer23, fontWeight: 700 }}>
                    {this.transProfile("My Order")}
                </Typography>
            </>
        )

    }

    DrawerToggle = () => (
        <div 
            id="drawer-toggle-container"
            style={{ 
                marginLeft: window.innerWidth >= 768 ? "135%" : "42%", 
                marginTop: window.innerWidth >= 768 ? "5%" : "7%" 
            }}
        >
            <MoreVertIcon
                id="drawer-toggle-icon"
                data-test-id="drawerOpenMoreVertIcon"
                onClick={() => this.setState((prevState) => ({ 
                    isDrawerOpen: !prevState.isDrawerOpen 
                }))}
            />
        </div>
    );

    DrawerContent = () => (
        <BuyerDrawer
            navigation={this.props.navigation}
            data-test-id="drawerClose"
            open={this.state.isDrawerOpen}
            onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
        >
            <div
                id="drawer-header-container"
                style={{
                    ...webStyle.innerContainer11,
                    margin: i18n.dir() === "ltr" ? "27px 0px 0px 40px" : "27px 40px 0px 0px"
                }}
            >
            </div>
            <div id="drawer-profile-container">
                <Typography
                    id="drawer-profile-name"
                    style={{
                        ...webStyle.innerContainer3,
                        marginLeft: i18n.dir() === "rtl" ? "0px" : "40px",
                        marginRight: i18n.dir() === "rtl" ? "40px" : 0
                    }}
                >
                    <img
                        id="drawer-profile-image"
                        src={scrollImg}
                        alt="Profile"
                    />
                    {this.state.profileName.split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                </Typography>
            </div>
            <Typography
                id="drawer-welcome-message"
                style={{
                    ...webStyle.innerContainer4,
                    marginLeft: i18n.dir() === "ltr" ? "40px" : 0,
                    marginRight: i18n.dir() === "rtl" ? "40px" : 0
                }}
            >
                {this.transProfile("Welcome to your Account")}
            </Typography>
            <Sidebar
                id="drawer-sidebar-navigation"
                navigation={this.props.navigation}
            />
        </BuyerDrawer>
    );
    commonView767 = () => {
        return (
            <>
                <Grid item md={12} sm={12} id="orders-grid-container">
                    <Box
                        id="orders-main-container"
                        style={{
                            backgroundColor: "#F8F8F8",
                            margin: i18n.dir() === "ltr" ? '90px 92px 90px 30px' : '90px 0px 90px 92px'
                        }}
                    >
                        <Box
                            id="orders-header"
                            style={webStyle.orderText}
                            marginTop="1rem"
                        >
                            {this.transProfile("My Orders")}
                        </Box>
                        <Box id="search-container">
                            <InputBase
                                id="orders-search-input"
                                placeholder={`${this.transProfile("Search for a product")}`}
                                style={webStyle.searchBox}
                                data-testid="handleSearchChange767"
                                startAdornment={
                                    <IconButton
                                        id="search-icon-button"
                                        style={{ padding: '10px' }}
                                        aria-label="search"
                                    >
                                        <img
                                            id="search-icon-image"
                                            src={Search}
                                            width="20px"
                                            height="20px"
                                            alt="Search"
                                        />
                                    </IconButton>
                                }
                                onChange={this.handleSearchChange}
                            />
                        </Box>
                        <Box id="tabs-outer-container">
                            <Box id="tabs-padding-container" style={{ paddingBottom: "30px" }}>
                                <Box id="tabs-container" style={webStyle.container}>
                                    <Box id="tabs-wrapper">
                                        <Tabs
                                            id="order-status-tabs"
                                            value={this.state.value}
                                            onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                            variant="scrollable"
                                            data-test-id="valueAdded"
                                            scrollButtons="auto"
                                            TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                        >
                                            <Tab
                                                id="tab-processing"
                                                data-testid="Processing"
                                                label={this.transProfile("Processing")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-delivered"
                                                data-testid="Deliverd"
                                                label={this.transProfile("Delivered")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-returns"
                                                data-testid="Return"
                                                label={this.transProfile("Returns")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-all-orders"
                                                data-testid="All_Orders"
                                                label={this.transProfile("All Orders")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                        </Tabs>
                                    </Box>
                                </Box>
                                {['Processing', 'Deliverd', 'Return', 'All Orders'].map((value, index) => {
                                    const isCurrentTab = this.state.value === index;
                                    const isLoading = this.state.tabeLoder;
                                    const isAlert = this.state.isAlert;
                                    const allOrderMessage = this.state.allOrderMasg;
                                    const content = (
                                        <div id={`tab-content-${value.toLowerCase().replace(' ', '-')}`}>
                                            {isLoading && (
                                                <Box
                                                    id="loading-indicator"
                                                    data-testid="loder"
                                                    style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}
                                                >
                                                    <CircularProgress style={{ color: '#375280' }} />
                                                </Box>
                                            )}
                                            {!isLoading && isAlert && (
                                                <Box
                                                    id="alert-message"
                                                    style={{ ...webStyle.tabeLable, textAlignLast: 'center', textAlign: 'center' }}
                                                >
                                                    {allOrderMessage}
                                                </Box>
                                            )}
                                            {!isLoading && !isAlert && (
                                                <div id={`tab-data-container-${value.toLowerCase().replace(' ', '-')}`}>
                                                    {this.dataPicker()}
                                                    {this.tabData(value)}
                                                </div>
                                            )}
                                        </div>
                                    );
                                    return isCurrentTab ? <>{content}</> : null;
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </>
        );
    }

    renderResponsiveView() {
        const { windowWidth } = this.state;
        return windowWidth >= 768
            ? this.commonView768()
            : this.commonView767();
    }

    commonView768 = () => {
        return (
            <>
                <Grid item md={12} sm={12}>
                    <Box style={{ backgroundColor: "#F8F8F8", margin: i18n.dir() === "ltr" ? '90px 92px 90px 70px' : '90px 0px 90px 92px' }}>
                        <Box style={webStyle.orderText} marginTop="1rem">
                            {this.transProfile("My Orders")}
                        </Box>
                        <Box>
                            <InputBase
                                placeholder={`${this.transProfile("Search for a product")}`}
                                style={webStyle.searchBox}
                                data-testid="handleSearchChange768"
                                startAdornment={
                                    <IconButton style={{ padding: '10px' }} aria-label="search">
                                        <img src={Search} width="20px" height="20px" />
                                    </IconButton>
                                }
                                onChange={this.handleSearchChange}
                            />
                        </Box>
                        <Box>
                            <Box style={{ paddingBottom: "30px" }}>
                                <Box style={webStyle.container}>
                                    <Box >
                                        <Tabs
                                            value={this.state.value}
                                            onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                        >
                                            <Tab data-testid="Processing" label={this.transProfile("Processing")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                            <Tab data-testid="Deliverd" label={this.transProfile("Delivered")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                            <Tab data-testid="Return" label={this.transProfile("Returns")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                            <Tab data-testid="All_Orders" label={this.transProfile("All Orders")} style={{ ...webStyle.allTab, textTransform: 'none' }} />
                                        </Tabs>
                                    </Box>

                                </Box>
                                {['Processing', 'Deliverd', 'Return', 'All Orders'].map((value, index) => {
                                    const isCurrentTab = this.state.value === index;
                                    const isLoading = this.state.tabeLoder;
                                    const isAlert = this.state.isAlert;
                                    const allOrderMessage = this.state.allOrderMasg;
                                    const content = (
                                        <div>
                                            {isLoading && <Box data-testid="loder" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}><CircularProgress style={{ color: '#375280' }} /></Box>}
                                            {!isLoading && isAlert && <Box style={{ ...webStyle.tabeLable, textAlignLast: 'center', textAlign: 'center' }}>{allOrderMessage}</Box>}
                                            {!isLoading && !isAlert && <div> {this.dataPicker()}{this.tabData(value)}</div>}
                                        </div>
                                    );
                                    return isCurrentTab ? <>{content}</> : null;
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </>
        )
    }

    MobileView = () => {
        if (window.innerWidth >= 951) return null;
        return (
            <>
                <Grid data-test-id="drawerToggle" style={{ display: "flex", justifyContent: "space-between", marginTop: "1.5rem" }}>
                    {this.NavigationHeader()}
                    {this.DrawerToggle()}
                    {this.DrawerContent()}
                </Grid>
                {this.renderResponsiveView()}
            </>
        )

    }
    DesktopView = () => {
        if (window.innerWidth < 951) return null;
        return (
            <>
                <Grid item lg={3} md={3} id="sidebar-grid-container">
                    <div
                        id="navigation-header-container"
                        style={{
                            ...webStyle.innerContainer11,
                            margin: i18n.dir() === "ltr" ? "27px 0px 0px 40px" : "27px 40px 0px 0px"
                        }}
                    >
                        {this.NavigationHeader()}
                    </div>
                    <div id="profile-info-container">
                        <Typography
                            id="profile-name-container"
                            style={{
                                ...webStyle.innerContainer3,
                                marginLeft: i18n.dir() === "rtl" ? "0px" : "40px",
                                marginRight: i18n.dir() === "rtl" ? "40px" : 0
                            }}
                        >
                            <img id="profile-scroll-image" src={scrollImg} alt="Profile scroll" />
                            {this.state.profileName.split(' ')
                                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')}
                        </Typography>
                    </div>
                    <Typography
                        id="welcome-message"
                        style={{
                            ...webStyle.innerContainer4,
                            marginLeft: i18n.dir() === "ltr" ? "40px" : 0,
                            marginRight: i18n.dir() === "rtl" ? "40px" : 0
                        }}
                    >
                        {this.transProfile("Welcome to your Account")}
                    </Typography>
                    <Sidebar id="navigation-sidebar" navigation={this.props.navigation} />
                </Grid>

                <Grid item lg={9} md={9} id="main-content-grid">
                    <Box
                        id="orders-container"
                        style={{
                            backgroundColor: "#F8F8F8",
                            margin: i18n.dir() === "ltr" ? '90px 92px 90px 0px' : '90px 0px 90px 92px'
                        }}
                    >
                        <Box id="orders-header" style={webStyle.orderText}>
                            {this.transProfile("My Orders")}
                        </Box>
                        <Box id="orders-content">
                            <Box id="orders-tab-container" style={{ paddingBottom: "30px" }}>
                                <Box id="tab-search-container" style={webStyle.container}>
                                    <Box id="tabs-wrapper">
                                        <Tabs
                                            id="order-status-tabs"
                                            value={this.state.value}
                                            onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            TabIndicatorProps={{ style: { ...webStyle.tabs } }}
                                        >
                                            <Tab
                                                id="tab-processing"
                                                data-testid="Processing"
                                                label={this.transProfile("Processing")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-delivered"
                                                data-testid="Deliverd"
                                                label={this.transProfile("Delivered")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-returns"
                                                data-testid="Return"
                                                label={this.transProfile("Returns")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                            <Tab
                                                id="tab-all-orders"
                                                data-testid="All_Orders"
                                                label={this.transProfile("All Orders")}
                                                style={{ ...webStyle.allTab, textTransform: 'none' }}
                                            />
                                        </Tabs>
                                    </Box>
                                    <Box id="search-box-container">
                                        <InputBase
                                            id="orders-search-input"
                                            placeholder={`${this.transProfile("Search for a product")}`}
                                            style={webStyle.searchBox}
                                            data-testid="handleSearchChange"
                                            startAdornment={
                                                <IconButton
                                                    id="search-icon-button"
                                                    style={{ padding: '10px' }}
                                                    aria-label="search"
                                                >
                                                    <img
                                                        id="search-icon-image"
                                                        src={Search}
                                                        width="20px"
                                                        height="20px"
                                                        alt="Search"
                                                    />
                                                </IconButton>
                                            }
                                            onChange={this.handleSearchChange}
                                        />
                                    </Box>
                                </Box>
                                {['Processing', 'Deliverd', 'Return', 'All Orders'].map((value, index) => {
                                    const isCurrentTab = this.state.value === index;
                                    const isLoading = this.state.tabeLoder;
                                    const isAlert = this.state.isAlert;
                                    const allOrderMessage = this.state.allOrderMasg;
                                    const content = (
                                        <div id={`tab-content-${value.toLowerCase().replace(' ', '-')}`}>
                                            {isLoading && (
                                                <Box
                                                    id="loading-indicator"
                                                    data-testid="loder"
                                                    style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}
                                                >
                                                    <CircularProgress style={{ color: '#375280' }} />
                                                </Box>
                                            )}
                                            {!isLoading && isAlert && (
                                                <Box
                                                    id="alert-message"
                                                    style={{ ...webStyle.tabeLable, textAlignLast: 'center', textAlign: 'center' }}
                                                >
                                                    {allOrderMessage}
                                                </Box>
                                            )}
                                            {!isLoading && !isAlert && (
                                                <div id={`tab-data-container-${value.toLowerCase().replace(' ', '-')}`}>
                                                    {this.dataPicker()}
                                                    {this.tabData(value)}
                                                </div>
                                            )}
                                        </div>
                                    );
                                    return isCurrentTab ? <>{content}</> : null;
                                })}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {this.renderHeaderr()}
                <Grid style={{ backgroundColor: "#F8F8F8" }} dir={i18n.dir()} container>
                    {this.MobileView()}
                    {this.DesktopView()}
                </Grid>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open1}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.open1} style={webStyle.fade}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={webStyle.fadeH2}>{this.transProfile("Order Cancel Popup Text")}</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between", flexDirection: i18n.dir() === "ltr" ? "row" : "row-reverse" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>{this.transProfile("No")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid={`cancelOrder-${this.state.modelId}`} onClick={() => this.cancelOrder(this.state.modelLable, this.state.modelId)}>{this.transProfile("Yes")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.modeReturnOpen}
                    onClose={this.modeRetuneHandleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modeReturnOpen} style={{ maxWidth: "501px", backgroundColor: "white" }}>
                        <div style={{ padding: "40px" }}>
                            <Box style={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }} ><CloseRoundedIcon style={{ color: '#375280', fontSize: '30px' }} onClick={this.modeRetuneHandleClose} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.fadeMode, textAlign: 'center' }}>{this.transProfile("Mode of Return")}</h2>
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.fadeTypo}>{this.transProfile("Self-Drop Off")}</Typography>
                                <Box>
                                    <FormControlLabel
                                        control={<StyledRadioWithStyles data-testid="self_drop_offRadio" aria-label="self_drop_off" />}
                                        label=""
                                        value="self_drop_off"
                                        defaultValue="self_drop_off"
                                        data-testid="self_drop_offRadio"
                                        onChange={this.handleGenderChange}
                                        checked={this.state.modeofreturn === 'self_drop_off'}
                                    />
                                </Box>
                            </Box>
                            <Typography style={{ fontSize: '14px', color: "#94A3B8", fontWeight: 400, fontFamily: "Lato", marginBottom: '9px' }}>{this.transProfile("Drop off the product at store by yourself")}</Typography>
                            <Divider style={{ margin: '24px 0px' }} />
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.fadeTypo}>{this.transProfile("Request a Delivery Partner")}</Typography>
                                <Box>
                                    <FormControlLabel
                                        control={<StyledRadioWithStyles data-testid="request_delivery_partner" aria-label="self_drop_off" />}
                                        label=""
                                        value="request_delivery_partner"
                                        data-testid="request_delivery_partnerRadio"
                                        onChange={this.handleGenderChange}
                                        checked={this.state.modeofreturn === 'request_delivery_partner'}
                                    />
                                </Box>
                            </Box>
                            <Typography style={webStyle.deilveryData}>{this.transProfile("Schedule a delivery partner to pick up the product. Delivery charges will be applicable.")}</Typography>
                            {this.state.modeofreturn === 'request_delivery_partner' && <Typography style={webStyle.deilveryNote}>{this.transProfile("Note - Pickup charges will be deducted from your refund")}</Typography>}
                            <Box style={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography style={webStyle.pickupCharge}>{this.transProfile("Estimated Pickup Charges")}</Typography>
                                {this.renderCharge()}
                            </Box>
                            <Divider style={{ margin: '24px 0px' }} />
                            <Box style={{ display: 'flex', justifyContent: "space-between", marginBottom: '40px' }}>
                                <Typography style={webStyle.totelCharge}>{this.transProfile("Total Charges")}</Typography>
                                {this.renderCharge()}
                            </Box>
                            <StyledButton1Model style={webStyle.submitButton} onClick={this.sellerReuturnType} data-testid="modeRetuneHandleSubmit">{this.transProfile("Submit")}</StyledButton1Model>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleReturnClose}
                    open={this.state.cancelReturnOrder}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.cancelReturnOrder} style={webStyle.fade}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={webStyle.fadeH2}>{this.transProfile("Order Cancel Popup Text")}</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleReturnClose" onClick={this.handleReturnClose}>{this.transProfile("No")}</StyledButtonModel>
                                <StyledButton1Model style={{ fontSize: "14px" }} data-testid={`cancelReturnOrder-${this.state.modelId}`} onClick={() => this.cancelReturnOrder()}>{this.transProfile("Yes")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.modeOfsuccessClose}
                    open={this.state.modeOfsuccessOpen}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modeOfsuccessOpen} style={webStyle.modeOffade}>
                        <div style={{ textAlign: "center", padding: "50px" }}>
                            <img src={modeofimage} />
                            <h2 id="transition-modal-title" style={webStyle.modeOffadeH2}>{this.transProfile("Self-Drop Off Confirmed")}</h2>
                            <Typography style={{ fontSize: '24px', fontFamily: 'Lato', fontWeight: 400, color: '#375280', margin: '43px 0px' }}>{this.transProfile("You must return the items within 24 hours.")}</Typography>
                            <div style={{ marginTop: "40px" }}>
                                <StyledButtonModelOfRefund style={{ width: '184px !important', fontSize: "14px", margin: '0px 6px' }} data-testid="modeOfsuccessClose" onClick={() => this.cancelReturnOpen(this.state.modeOfOrderId)}>{this.transProfile("Cancel Return")}</StyledButtonModelOfRefund>
                                <StyledButton1Model style={{ width: '184px', fontSize: "14px", margin: '0px 6px' }} data-testid={`cancelReturnOrder-${this.state.modelId}`} onClick={this.modeOfsuccessClose}>{this.transProfile("Mode of Return")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                <StyledDrawer
                    aria-describedby="transition-modal-description"
                    BackdropComponent={Backdrop}
                    aria-labelledby="transition-modal-title"
                    onClose={this.modedeliverysuccessOpen}
                    open={this.state.modedeliverysuccessOpen}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.modedeliverysuccessOpen} style={webStyle.modeOffade}>
                        <div style={{ textAlign: "center", padding: "50px" }}>
                            <img src={modeofimage} />
                            <h2 id="transition-modal-title" style={webStyle.modeOffadeH2}>{this.transProfile("Driver Requested")}</h2>
                            <Typography style={{ fontSize: '24px', fontFamily: 'Lato', fontWeight: 400, color: '#375280', margin: '43px 0px' }}>{this.transProfile("A delivery partner will be assigned soon")}</Typography>
                            <div style={{ marginTop: "40px" }}>
                                <StyledButton1Model style={{ width: '302px', fontSize: "18px" }} data-testid="trackReturn" onClick={this.modedeliverysuccessOpen}>{this.transProfile("Track Return")}</StyledButton1Model>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>
                {window.innerWidth > 951 ? <Footer navigation={this.props.navigation} /> : null}

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    deilveryNote: { fontSize: '14px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginTop: '96px', marginBottom: "24px" },
    deilveryData: { fontSize: '14px', color: "#94A3B8", fontWeight: 400, fontFamily: "Lato", marginBottom: '96px' },
    pickupCharge: { fontSize: '18px', color: "#375280", fontWeight: 500, fontFamily: "Lato", marginBottom: '9px' },
    totelCharge: { fontSize: '20px', color: "#375280", fontWeight: 700, fontFamily: "Lato", marginBottom: '9px' },
    submitButton: { fontWeight: 700, fontFamily: "Lato", fontSize: "18px", width: '100%', height: '64px' },
    fadeTypo: { fontSize: '18px', color: "#375280", fontWeight: 700, fontFamily: "Lato", marginBottom: '9px' },
    fadeMode: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", marginBottom: '72px' },
    fadeH2: { width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" },
    modeOffadeH2: { fontSize: '24px', color: "#375280", fontWeight: 800, fontFamily: "Lato", marginTop: '28px' },
    fade: { borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" },
    modeOffade: { maxWidth: "529px", backgroundColor: "white" },
    tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '100%', height: '71%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    menBox: { display: "flex", justifyContent: 'space-between', marginTop: "30px", marginBottom: "24px" },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    ShortBy: { color: "#94A3B8", fontSize: '14px', fontFamily: "Lato", fontWeight: 400 },
    reminderName: { backgroundColor: '#FFFFFF', height: '45px' },
    innerContainer11: {
        gap: "10px",
        display: "flex",
        fontWeight: 400,
        alignItems: "center",
        lineHeight: "22px",
        color: "#475569",
    },
    innerContainer23: {
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",
        padding: "4px 6px 4px 6px",
        fontStyle: "normal",
        lineHeight: "22px",
        color: "#475569",
        cursor: "pointer"
    },
    innerContainer3: {
        alignItems: "center",
        display: "flex",
        marginTop: "31px",
        lineHeight: "33.5px",
        color: "#375280",
        fontWeight: 800,
        gap: "15px",
        fontStyle: "normal",
        fontSize: "28px",
        fontFamily: "Lato , sans-serif",
    },
    innerContainer4: {
        marginBottom: "37px",
        color: "#807D7E",
        fontWeight: 400,
        lineHeight: "33.5px",
        fontStyle: "normal",
        fontSize: "14px",
        fontFamily: "Lato , sans-serif",

    },
    pickerBox: { display: "flex", gap: '8px', alignItems: "center" },
    picker: { display: "flex", gap: "12px", width: "193px", height: "48px", borderRadius: "2px", backgroundColor: "#FFFFFF", padding: "4px 10px 4px 12px", justifyContent: 'center', alignItems: "center" },
    endContent: { display: "flex", justifyContent: "end" },
    orderText: {
        color: "#375280",
        fontFamily: "Lato",
        fontWeight: 800,
        fontSize: "20px",
        paddingBottom: "26px",

    },
    container: { display: "flex", justifyContent: "space-between" },
    tabs: { backgroundColor: '#375280', borderBottom: '1px solid #E2E8F0' },
    allTab: { fontSize: "14px", fontFamily: "Lato", fontWeight: 600, color: "#375280", borderBottom: '1px solid #E2E8F0' },
    searchBox: { width: "249px", height: "48px", border: "1px solid #CBD5E1", background: "#FFFFFF", color: "#94A3B8", fontSize: "16px", fontFamily: "Lato", fontWeight: 400, borderRadius: "2px", padding: "10px, 8px, 10px, 12px" },
    orderContent: { marginTop: "20px", backgroundColor: '#ffffff', padding: "28px 47px", borderRadius: '2px' },
    orderIdBox: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "14px" },
    orderId: { fontSize: "20px", fontWeight: 800, color: "#375280", fontFamily: "Lato" },
    customerData: { display: "flex", justifyContent: "space-between", marginTop: "10px", alignItems: "center" },
    cusName: { fontSize: "14px", fontWeight: 700, color: "#375280", fontFamily: "Lato", },
    customer: { fontSize: "14px", fontWeight: 400, color: "#94A3B8", fontFamily: "Lato" },
    productContainer: { display: "flex", justifyContent: "space-between", marginTop: "36px" },
    proData: { display: "flex", justifyContent: "row", alignItems: "center", gap: "21px" },
    proName: { fontSize: "16px", fontWeight: 700, color: "#375280", fontFamily: "Lato", paddingBottom: '10px' },
    proDetail: { paddingBottom: '5px', display: "flex", alignItems: "center" },
    colorBorder: {
        display: 'inline-block',
        width: '10px',
        height: '10px',
        border: "1px solid #375280",
        borderRadius: "50%",
    },
    colorRound: {
        width: 'calc(100% - 2px)',
        height: 'calc(100% - 2px)',
        borderRadius: "50%",
        top: "1px",
        left: "1px",
    },
    sizes: { fontSize: "14px", fontWeight: 700, color: "rgba(55, 82, 128, 1)" },
    datePick: { zIndex: 1, backgroundColor: 'white', borderRadius: '4px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' },
};
const StyledButton = styled(Button)(() => ({
    fontWeight: 600,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "170px",
    height: "50px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}));

const StyledButton1 = styled(Button)(() => ({
    fontWeight: 600,
    color: "#fff",
    width: "182px",
    height: "50px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "150px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButtonModelOfRefund = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "184px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "150px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const styles = {
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        border: '1px solid #CCBEB1',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        border: '2px solid #cbbeb2',
        borderRadius: '50%',
        width: 20,
        height: 20,
        backgroundColor: '#fff',
        '&:before': {
            content: '""',
            display: 'block',
            width: 10,
            height: 10,
            backgroundColor: '#cbbeb2',
            borderRadius: '50%',
            marginTop: '25%',
            marginLeft: '25%',
        },
    },
};
const StyledRadioWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
    <Radio
        className={props.classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
        icon={<span className={props.classes.icon} />}
        inputProps={{ 'aria-label': 'customized-radio' }}
        {...props}
    />
));

const BtnContainer = styled(Box)({
    display: "flex",
    gap: 12
})
// Customizable Area End