import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ShippingAddressDetailController, {
  Props,
} from "./ShippingAddressDetailController";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class ShippingAddressDetail extends ShippingAddressDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Typography>Shipping Address Detail</Typography>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
// Customizable Area End
