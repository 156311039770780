export const enHiredStylist = {
    "Hired Stylists": "Hired Stylists",
    "Explore Portfolio": "Explore Portfolio",
    "Product Sourcing": "Product Sourcing",
    "Stylists": "Stylists",
    "Requested Stylists": "Requested Stylists",
    "Source a Product": "Source a Product",
    "View": "View",
    "Delete": "Delete",
    "Quotes": "Quotes",
    "Quote": "Quote",
    "View More": "View More",
    "Edit Request": "Edit Request",
    "Hired": "Hired",
    "Stylist's Profile": "Stylist's Profile",
    "Explore Portfolios": "Explore Portfolios",
    "Search Stylist": "Search Stylist",
    "Stylists Portfolios": "Stylists Portfolios",
    "Stylist": "Stylist",
    "Products by": "Products by",
    "Pricing": "Pricing",
    "Available to Hire": "Available to Hire",
    "Request a callback": "Request a callback",
    "Chat with": "Chat with",
    "Hire Stylist": "Hire Stylist",
    "Hire Stylist Plans": "Hire Stylist Plans",
    "Weekly Plan": "Weekly Plan",
    "Monthly Plan": "Monthly Plan",
    "Quarterly Plan": "Quarterly Plan",
    "stylings per week": "stylings per week",
    "styling per week":"styling per week",
    "hour of discussion time":"hour of discussion time",
    "hours of discussion time": "hours of discussion time",
    "Voice and Video call facility": "Voice and Video call facility",
    "I agree to the stylist’s": "I agree to the stylist’s",
    "of services": "of services",
    "Terms and Conditions": "Terms and Conditions",
    "Plan Buy": "Plan Buy",
    "Stylist Profile": "Stylist Profile",
    "Gender": "Gender",
    "Colour Preference": "Colour Preference",
    "Minimum Price": "Minimum Price",
    "Maximum Price": "Maximum Price",
    "More Details (Style, Size & Fit etc.)": "More Details (Style, Size & Fit etc.)",
    "Enter more details about size, fit and the style you want": "Enter more details about size, fit and the style you want",
    "Attachments": "Attachments",
    "Enter Preferred Colour": "Enter Preferred Colour",
    "Select Gender": "Select Gender",
    "Male": "Male",
    "Female": "Female",
    "Other": "Other",
    "Upload": "Upload",
    "Submit": "Submit",
    "Product Quotes": "Product Quotes",
    "Sizes": "Sizes",
    "Colours": "Colours",
    "Accepted": "Accepted",
    "Rejected": "Rejected",
    "Reject": "Reject",
    "Accept": "Accept",
    "No data available": "No data available",
    "No data found": "No data found",
    "Source Product": "Source Product",
    "Product Name": "Product Name",
    "Product Description": "Product Description",
    "Product name here": "Product name here",
    "Product description here": "Product description here",
    "Product Colour": "Product Colour",
    "Product Sizes": "Product Sizes",
    "Quantity": "Quantity",
    "Kids": "Kids",
    "Amount": "Amount",
    "Product name is required": "Product name is required",
    "Product description is required": "Product description is required",
    "Product color is required": "Product color is required",
    "Product size is required": "Product size is required",
    "Minimum price is required": "Minimum price is required",
    "Maximum price is required": "Maximum price is required",
    "Gender is required": "Gender is required",
    "Quantity is required": "Quantity is required",
    "Confirmation": "Confirmation",
    "Your stylist will review your request and contact you.": "Your stylist will review your request and contact you.",
    "Continue Shopping": "Continue Shopping",
    "Reason for Call": "Reason for Call",
    "Hours (24 Hours)": "Hours (24 Hours)",
    "Minutes": "Minutes",
    "Enter Reason for Call": "Enter Reason for Call",
    "Enter Hours": "Enter Hours",
    "Enter Minutes": "Enter Minutes",
    "Requirement Form": "Requirement Form",
    "My Requests":"My Requests",
    "There are no bids on your product quote yet": "There are no bids on your product quote yet",
    "No portfolio images found":"No portfolio images found",
    "No product found":"No product found",
    "No plan found":"No plan found",
    "No Stylist Hired": "No Stylist Hired",
    "% Off": "% Off",
}

export const arHiredStylist = {
    "% Off": "خصم %",
    "Hired Stylists": "المصممون الذين تم تعيينهم",
    "Explore Portfolio": "استكشف المعرض",
    "Product Sourcing": "توريد المنتجات",
    "Stylists": "المصممون.",
    "Requested Stylists": "المصممون الذين تم طلبهم",
    "Source a Product": "توريد منتج",
    "View": "العرض",
    "Delete": "المسح",
    "hour of discussion time":"ساعة من وقت المناقشة",
    "Quotes": "العروض",
    "Quote": "اقتبس",
    "View More": "عرض المزيد",
    "Edit Request": "تعديل الطلب",
    "Hired": "متاحة للتعيين",
    "Stylist's Profile": "الملف الشخصي للمصمم",
    "Explore Portfolios": "استكشف المعارض",
    "Search Stylist": "البحث عن مصمم الأزياء",
    "Stylists Portfolios": "معروضات المصممين",
    "Stylist": "المصممون",
    "Products by": "منتجات",
    "Pricing": "الأسعار",
    "Available to Hire": "متاحة للتعيين",
    "Request a callback": "طلب معاودة الاتصال",
    "Chat with": "الدردشة مع",
    "Hire Stylist": "تعيين المصمم",
    "Hire Stylist Plans": "اختيار خطط المصمم",
    "Weekly Plan": "الخطة الأسبوعية",
    "Monthly Plan": "الخطة الشهرية",
    "Quarterly Plan": "الخطة ربع السنوية",
    "stylings per week": "تصاميم أسبوعيا",
    "hours of discussion time": "ساعات للمناقشة",
    "Voice and Video call facility": "القدرة على المكالمات الصوتية والفيديو",
    "I agree to the stylist’s": "أوافق على رأي المصمم",
    "of services": "الخدمات",
    "Terms and Conditions": "الشروط والأحكام",
    "Plan Buy": "شراء الخطة",
    "Stylist Profile": "الملف الشخصي للمصمم",
    "Gender": "الجنس",
    "Colour Preference": "تفضيلات اللون",
    "Minimum Price": "السعر الأدنى",
    "Maximum Price": "السعر الأعلى",
    "More Details (Style, Size & Fit etc.)": "تفاصيل إضافية (الطراز والحجم والملاءمة وما إلى ذلك)",
    "Enter more details about size, fit and the style you want": "إدخال تفاصيل إضافية عن الحجم والملاءمة والطراز الذي تريده",
    "Attachments": "الملفات الملحقة",
    "Enter Preferred Colour": "أدخل اللون المفضل",
    "Select Gender": "حدد الجنس",
    "Male": "ذكر",
    "Female": "الأنثى",
    "Other": "أخرى",
    "Upload": "إرفع ملفات",
    "Submit": "إرسال",
    "Product Quotes": "عروض المنتجات",
    "Sizes": "المقاسات",
    "Colours": "الألوان",
    "Accepted": "تم القبول",
    "Rejected": "مرفوض",
    "Reject": "يرفض",
    "Accept": "يقبل",
    "No data available": "لا توجد بيانات متاحة",
    "No data found": "لم يتم العثور على أي بيانات",
    "Source Product": "توريد منتج",
    "Product Name": "اسم المنتج",
    "Product Description": "وصف المنتج",
    "Product name here": "اسم المنتج هنا",
    "Product description here": "وصف المنتج هنا",
    "Product Colour": "لون المنتج",
    "Product Sizes": "أحجام المنتج",
    "Quantity": "الكمية",
    "Kids": "أطفال",
    "Amount": "كمية",
    "Product name is required": "اسم المنتج مطلوب",
    "Product description is required": "وصف المنتج مطلوب",
    "Product color is required": "لون المنتج مطلوب",
    "Product size is required": "حجم المنتج مطلوب",
    "Minimum price is required": "السعر الأدنى مطلوب",
    "Maximum price is required": "السعر الأقصى مطلوب",
    "Gender is required": "الجنس مطلوب",
    "Quantity is required": "الكمية المطلوبة",
    "Confirmation": "التأكيد",
    "Your stylist will review your request and contact you.": "سيراجع المصمم طلبك وسيتواصل معك.",
    "Continue Shopping": "متابعة التسوق",
    "Reason for Call": "سبب الاتصال",
    "Hours (24 Hours)": "ساعات العمل (24 ساعة)",
    "Minutes": "دقائق",
    "Enter Reason for Call": "أدخل سبب المكالمة",
    "Enter Hours": "أدخل الساعات",
    "Enter Minutes": "أدخل الدقائق",
    "Requirement Form": "استمارة المتطلبات",
    "styling per week":"التصميم في الأسبوع",
    "My Requests":"طلباتي",
    "There are no bids on your product quote yet": "لا توجد عروض أسعار على عرض أسعار المنتج الخاص بك حتى الآن",
    "No portfolio images found":"لم يتم العثور على أي صور للمعرض",
    "No product found":"لم يتم العثور على أي منتج",
    "No plan found":"لم يتم العثور على خطة",
    "No Stylist Hired": "لم يتم تعيين أي مصممين"
}