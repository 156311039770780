import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area Start
export const configJSON = require("./config");
interface Payment {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  charge_id: string;
  merchant_id: string | null;
  order_id: string;
  amount: number;
  currency: string;
  customer_id: string;
  reason: string;
  account_id: number;
  order_management_order_id: number;
  refund_id: string | null;
  refund_amount: number | null;
  refund_reason: string | null;
  seller_order_id: string | null;
  last_four_card_digit: string;
  payment_type: string;
  deleted: boolean;
  url: string
}
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  paymentTransaction: Payment | undefined,
  transactionDateAt: string,
  formattedTime: string,
  paymentDetail: Payment[],
  sampleState: string,
  payment: boolean,
  anchorEl: HTMLElement | null,
  downloadId: number,
  isDrawerOpen:boolean,
  fullName: string,
  open1: boolean,
  cardIde: number;
  currencySign: string;
  stylishBuyer: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PaymentHistoryWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getcategoriesId: string = "";
  getPaymenntHistoryCallId: string = "";
  getPaymenntDeleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      payment: true,
      sampleState: 'sample',
      anchorEl: null,
      fullName: "",
      paymentDetail: [],
      stylishBuyer: false,
      isDrawerOpen:false,
      paymentTransaction: {
        id: 0,
        status: '',
        created_at: '',
        updated_at: '',
        charge_id: '',
        merchant_id: '',
        order_id: '',
        amount: 0,
        currency: '',
        customer_id: '',
        reason: '',
        account_id: 0,
        order_management_order_id: 0,
        refund_id: '',
        refund_amount: 0,
        refund_reason: '',
        seller_order_id: '',
        last_four_card_digit: '',
        payment_type: '',
        deleted: false,
        url: ''
      },
      transactionDateAt: "",
      formattedTime: "",
      downloadId: 0,
      open1: false,
      cardIde: 0, currencySign: "$"
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const order_id = this.props.navigation.getParam("order_id")
    if(order_id){
      this.transaction(order_id)
    }
    const name = await getStorageData('user_data', true);
    const stylishBuyer = await getStorageData('exploreBuyer', true);
    if(stylishBuyer) this.setState({stylishBuyer})
    this.setState({
      fullName: `${name.first_name} ${name.last_name}`
    });
    this.paymentDetailGet()
    // Customizable Area End
  }

  // Customizable Area Start
  getCurrency = (currency: string) => {
    return currency === "Dollar" ? "$" : "KWD"
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPaymenntHistoryCallId !== null &&
      this.getPaymenntHistoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ paymentDetail: responseJson });
      if(responseJson.length > 0){
        this.setState({
          currencySign: this.getCurrency(responseJson[0].current_currency)
        })
      }
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPaymenntDeleteCallId !== null &&
      this.getPaymenntDeleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message === "Payment detail marked as deleted.") {
        this.paymentDetailGet()
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getNavigationMessage = () => {
    this.setState({ payment: true })
  }
  transaction = (order_id: string) => {
    const orderId = this.state.paymentDetail.find(payment => payment.order_id === order_id) || {
      id: 0,
      status: '',
      created_at: '',
      updated_at: '',
      charge_id: '',
      merchant_id: null,
      order_id: '',
      amount: 0,
      currency: '',
      customer_id: '',
      reason: '',
      account_id: 0,
      order_management_order_id: 0,
      refund_id: null,
      refund_amount: null,
      refund_reason: null,
      seller_order_id: null,
      last_four_card_digit: '',
      payment_type: '',
      deleted: false,
      url: ""
    };
    this.setState({ paymentTransaction: { ...orderId, status: orderId.status === 'CAPTURED' ? this.tranPaymentHistory("Completed") : orderId.status }, transactionDateAt: orderId.created_at, payment: false, downloadId: orderId.id })
  }
  dateFormet = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    const months = [this.tranPaymentHistory("Jan"), this.tranPaymentHistory("Feb"), this.tranPaymentHistory("Mar"), this.tranPaymentHistory("Apr"), this.tranPaymentHistory("May"), this.tranPaymentHistory("Jun"), this.tranPaymentHistory("Jul"), this.tranPaymentHistory("Aug"), this.tranPaymentHistory("Sep"), this.tranPaymentHistory("Oct"), this.tranPaymentHistory("Nov"), this.tranPaymentHistory("Dec")];
    const days = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? this.tranPaymentHistory("PM") : this.tranPaymentHistory("AM");
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${days} ${month} ${year} ${hours}:${minutesStr} ${ampm}`;
  }

  dateFormetTransaction = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    const months = [this.tranPaymentHistory("Jan"), this.tranPaymentHistory("Feb"), this.tranPaymentHistory("Mar"), this.tranPaymentHistory("Apr"), this.tranPaymentHistory("May"), this.tranPaymentHistory("Jun"), this.tranPaymentHistory("Jul"), this.tranPaymentHistory("Aug"), this.tranPaymentHistory("Sep"), this.tranPaymentHistory("Oct"), this.tranPaymentHistory("Nov"), this.tranPaymentHistory("Dec")];
    const days = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${days} ${month} ${year}`;
  }
  dateFormetTime = (created_at: string) => {
    if (!created_at) return "";
    const date = new Date(created_at);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? this.tranPaymentHistory("PM") : this.tranPaymentHistory("AM");
    hours = hours % 12;
    hours = hours ? hours : 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  }

  getStatusStyles = (status: string) => {
    switch (status) {
      case "CAPTURED":
        return { statusColor: "#059669", titleRightColor: "#059669", status: this.tranPaymentHistory("Completed")};
      case "Pending":
        return { statusColor: "#F59E0B", titleRightColor: "#F59E0B", status: this.tranPaymentHistory("Pending")};
      case "Refunded":
        return { statusColor: "#059669", titleRightColor: "#059669", status: this.tranPaymentHistory("Refunded")};
      default:
        return { statusColor: "#000000", titleRightColor: "#000000" };
    }
  };
  headerNavigation = async (pageLink: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
};
  paymentDetailGet = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymenntHistoryCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.paymentDetail}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  paymentDetailDownload = async (cardId: number) => {
    const orderIdDownlod = this.state.paymentDetail.find(payment => payment.id === cardId) || {
      url: ""
    };
    window.open(orderIdDownlod.url)
  }
  paymentDetailDelete = async (cardId: number) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPaymenntDeleteCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiDelete
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApiMethodDelete}/${cardId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  handleMenuCloseCard = () => {
    this.setState({ open1: false })
  };
  deleteCard = () => {
    this.setState({ open1: false })
    this.paymentDetailDelete(this.state.cardIde)
  }
  handleMenuOpenCard = (cardId: number) => {
    this.setState({ open1: true, cardIde: cardId })
  };

  tranPaymentHistory = (transKey: string) => {
    return i18n.t( transKey, {ns: "payment"});
  }

  // Customizable Area End
}