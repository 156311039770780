import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CountrySuccess} from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import storage from "../../../framework/src/StorageProvider.web";
import i18n from "../../../components/src/i18next/i18n";
import { ICountryResp } from "../../../components/src/CountryDropDown.web";
import { checkCondition } from "../../../components/src/Seller/logOut";


interface ErrorsMessage {
  firstNameMessage?: string;
  lastNameMessage?: string;
  emailMessage?: string;
  passwordMessage?: string;
  rePasswordMessage?: string;
  phoneNumberMessage?: string;
  oldPasswordMessage?: string;
  newPasswordMessage?: string;
  errorFirstName?: boolean;
  errorLastName?: boolean;
  errorEmail?: boolean;
  errorPassword?: boolean;
  errorOldPassword?: boolean;
  errorNewPassword?: boolean;
  errorRePassword?: boolean;
  errorPhoneNumber?: boolean;
}

interface ResendEmailOTP{
  data: {
      type: string;
      attributes: {
          email: string;
      }
  }
};

interface ResendSMSOTP{
  data: {
      type: string;
      attributes: {
          full_phone_number:string;
      }
  }
}

interface OTPBody {
  otp_type: string | undefined;
  token: string | undefined;
  pin: string;
}

interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ErrorPut {
  errors: string;
  meta: {
    message: string
  }
}

interface ErrorPhone {
  errors : {
    full_phone_number: string
  }
}

interface MetaResponse{
  meta: {
    sms_token: string;
    email_token: string;
   }
  }


interface ApiCallPutError {
  errors?: {
    email?: string,
    full_phone_number?: string;
    phone_number?: string;
  }[],
}

interface PhoneOTPPayLoad {
  token: string | undefined;
  pin: string;
}

interface GetPersonalDetail {
    "data": {
        id: string,
        type: string,
        "attributes": {
            "email": string,
            "first_name": string,
            "last_name": string,
            "full_name": string,
            "full_phone_number": string,
            "type": null,
            "activated": boolean,
            "profile_picture": string,
            "phone_number": string,
            "country_code_name": string | null,
            country_code: number
        }
    }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  emailOtpVerify: boolean;
  emailType: string;
  firstName: string;
  otpInput: string;
  errorOtpMsg: string;
  firstNameError: boolean;
  phoneNumberError: boolean;
  lastName: string;
  lastNameError: boolean;
  emailError: boolean;
  errorsMessage: ErrorsMessage;
  phoneNumber: string;
  oldPassword: string;
  newPassword: string;
  email: string;
  errorEmail: boolean;
  oldPasswordIcon: boolean;
  newPasswordIcon: boolean,
  ReNewPasswordIcon: boolean,
  rePassword: string;
  contryCodeResp: CountrySuccess[],
  countryCode: string | unknown,
  isAlert: boolean,
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  fullName: string;
  selectedCountry: CountrySuccess;
  isCountryData: string;
  loading: boolean;
  isDrawerOpen:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Customisableuserprofiles2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryCodeApiCallId: string = "";
  personalDetailGetApiCallId: string = "";
  changeProfilePutApiCallId: string = "";
  changePasswordPutApiCallId: string = "";
  phoneNumberOtpVerifyCallID: string = "";
  resendOTPCallID: string = "";
  emailVerifyCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      emailOtpVerify: false,
      errorOtpMsg: '',
      otpInput: '',
      emailType: '',
      firstName: "",
      firstNameError: false,
      phoneNumberError: false,
      email: '',
      lastName: "",
      phoneNumber: "",
      lastNameError: false,
      isAlert: false,
      emailError: false,
      errorEmail: false,
      oldPassword: "",
      countryCode: "+91",
      newPassword: "",
      errorsMessage: {
        firstNameMessage: "",
        lastNameMessage: "",
        emailMessage: "",
        passwordMessage: "",
        rePasswordMessage: "",
        phoneNumberMessage: "",
        oldPasswordMessage: "",
        newPasswordMessage: "",
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false,
        errorPassword: false,
        errorOldPassword: false,
        errorNewPassword: false,
        errorRePassword: false,
        errorPhoneNumber: false
      },
      oldPasswordIcon: false,
      newPasswordIcon: false,
      ReNewPasswordIcon: false,
      rePassword: "",
      contryCodeResp: [
        {
          numeric_code: "",
          country_full_name: "",
          country_code: "",
          country_flag: ""
        }
      ],
      alertType: 'success',
      alertMsg: '',
      fullName: "",
      selectedCountry: { country_code: "KW", numeric_code: "+965" ,country_full_name: "", country_flag: ""},
      isCountryData: "+965",
      loading: false,
      isDrawerOpen:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCountryCodeApi();
    const name = await getStorageData('user_data', true);
    this.setState({
      fullName: `${name?.first_name} ${name?.last_name}`
    });
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  transProfile = (transKey: string) => {
    return i18n.t( transKey, {ns: "sidebar"})
  }

  transProfileMain = (transKey: string) => {
    return i18n.t( transKey, {ns: "profile"})
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  apiSucessCall = async (
    apiRequestCallId: string,
    responseJson: CountrySuccess[] &  GetPersonalDetail & ErrorPut & ApiCallPutError & ErrorPhone & MetaResponse & {message: string, email?: string} & {
      errors: {
        pin: string
      }
    } & {
      errors: {
        phone_number: string;
      }[]
    }
  ) => {
    if (apiRequestCallId === this.countryCodeApiCallId) {
      this.handleContryResponse(responseJson);
    } else if (apiRequestCallId === this.personalDetailGetApiCallId) {
      if (responseJson.data) {
        const { attributes } = responseJson.data;
          
            let countryData: string = "";
            
            if (!attributes.country_code_name) {
              countryData = this.getCountryByCode(`+${attributes.country_code.toString()}`);
            }
            this.setState((prevState)=>({
              firstName: responseJson?.data?.attributes?.first_name,
                lastName: responseJson?.data?.attributes?.last_name,
                email: responseJson?.data?.attributes?.email,
                phoneNumber: responseJson?.data?.attributes?.phone_number?.toString(),
                selectedCountry: {
                  ...prevState.selectedCountry,
                  country_code: checkCondition(countryData !== "", countryData, attributes.country_code_name ?? "KW") as string,
                  numeric_code: `+${responseJson.data.attributes.country_code?.toString()}` || "+965",
                  country_full_name: "",
                  country_flag: "",
                },
              }));
          }
    } else if (apiRequestCallId === this.changeProfilePutApiCallId) {
      this.handleChangeResponse(responseJson)
    } else if(apiRequestCallId === this.changePasswordPutApiCallId) {
      this.handleChangePasswordApiCall(responseJson as ErrorPut);
    } else{
      this.handleAPICalling(apiRequestCallId, responseJson)
    }
  };

  handleChangeResponse = async(responseJson: ApiCallPutError & ErrorPhone & MetaResponse) => {
    if(responseJson?.errors){
      this.handleChangeProfileApiCall(responseJson)
    }else{
      if(responseJson.meta.sms_token || responseJson.meta.email_token){
        await setStorageData("user-email", responseJson.meta.email_token);
        await setStorageData("sms-token", responseJson.meta.sms_token);
        const tokenToOpen = this.handleSmsOREmail(responseJson.meta)
        this.setState({
          emailOtpVerify: true,
          emailType: tokenToOpen
        });
    }else{
      const userProfileData = await getStorageData('user_data', true);
      const updatedData = {...userProfileData, first_name: this.state.firstName, last_name: this.state.lastName, email: this.state.email, country_code: Number(this.state.isCountryData)}
       setStorageData('user_data', JSON.stringify(updatedData));
      this.setState({
        isAlert: true,
       alertMsg: "User Profile Updated Successfully",
        alertType: 'success',
        fullName: this.state.firstName + ' ' + this.state.lastName
      })
    }
    this.setState({loading: false})
    }
  }

  headerNavigation = async (pageLink: string) => {
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
};

  handleContryResponse = async(responseJson: CountrySuccess[]) => {
    if (responseJson) {
      this.setState({
        contryCodeResp: responseJson
      }, ()=> this.getPersonalDetail());
    }
  }

  getCountryByCode = (code: string): string => {
    const country = this.state.contryCodeResp.find((country) => country.numeric_code === code);
    return country ? country.country_code : "KW";
  };

  handlekeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || (e.key >= '0' && e.key <= '9')) {
        return;
    }
    e.preventDefault();
}
  handleAPICalling = async(apiRequestCallId: string, responseJson: CountrySuccess[] &  GetPersonalDetail & ErrorPut & ApiCallPutError & ErrorPhone & MetaResponse & {message: string, email?: string} & {
    errors: {
      pin: string
    } 
  } & {errors: {phone_number: string}[]
  }) => {
       if(apiRequestCallId === this.emailVerifyCallID){
          this.handleVerifyApiCallResponse(responseJson)
    }else if(apiRequestCallId === this.phoneNumberOtpVerifyCallID){
      this.handlePhoneNumberCall(responseJson)
  } else if(apiRequestCallId === this.resendOTPCallID){
    alert(this.transProfileMain(configJSON.resentOTP));
  }
  }

  handlePhoneNumberCall = async(responseJson: {errors: {
    pin: string
  }} & {message: string} & {
    errors: {
      phone_number: string;
    }[]
  }) => {
    if(responseJson.errors?.pin){
      this.setState({
        errorOtpMsg: responseJson.errors?.pin  
      })}else{   
          this.setState({
            emailOtpVerify: false ,emailType: "",
            otpInput: '', isAlert: true, alertMsg: responseJson?.message,
            alertType: "success"
          });
          await removeStorageData('sms-token');  
             await removeStorageData('user-email');
            }}

  handleVerifyApiCallResponse = async(responseJson: {message: string, email?: string} & {
    errors: {pin: string
    }
  }) => {
    const smsPresent = await getStorageData('sms-token');
    if(smsPresent && responseJson?.message){ 
      this.setState({ 
        otpInput: '' ,
        emailType: "sms_otp",  
      })}else if(responseJson?.email){
      this.setState({errorOtpMsg: responseJson?.email
      });
    }else if(responseJson?.errors?.pin){
      this.setState({errorOtpMsg: responseJson?.errors?.pin
      });
    }else { 
      this.setState({ emailOtpVerify: false,otpInput: ''});
      if(responseJson?.message){
        this.setState({
          isAlert: true, alertMsg: responseJson?.message,
          alertType: "success"
        });
}
      await removeStorageData('user-email');
    }
  }

  handleResendSMSOTP = async (body:ResendSMSOTP | ResendEmailOTP) => {
    this.resendOTPCallID = await this.apiCallService({
        contentType: configJSON.postResendOTPContentType,
        method: configJSON.postApimethod,
        endPoint: configJSON.postResendOTPEndPoint,
        body: body
    })
};

  handeResentOTPApiCall = () => {
    if (this.state.emailType === 'email_otp') {
        const body = {
            data: {
                type: configJSON.email_accountMsg,
                attributes: {
                    email: this.state.email,
                },
            },
        };
        this.handleResendSMSOTP(body);
    } else {
        const body = {
            data: {
                type: configJSON.sms_accountMsg,
                attributes: {
                    full_phone_number: `${this.state.isCountryData}${this.state.phoneNumber}`,
                },
            },
        };
        this.handleResendSMSOTP(body);
    }
};

  handleSmsOREmail = (metaData: {
    sms_token: string;
    email_token: string;
  }) => {
    if(metaData.sms_token && metaData.email_token){
      return "email_otp"
    }else if(metaData.email_token){
      return "email_otp"
    }else{
      return "sms_otp"
    }
  };

  handleOption = () => {
    if (this.state.emailType === 'email_otp') {
     return true;
   }
 };

 handleOTPChange = (otps: string) => {
  this.setState({ 
      otpInput: otps,
          errorOtpMsg:"",
   });
}

  maskEmail = () => {
    let mail = this.state.email;
    let atIndex = mail?.indexOf("@");
    let maskedEmail = mail?.substring(atIndex - 2);
    let starMail = `********${maskedEmail}`;
    return starMail;
};

maskPhone = () => {
    let phoneNumber = this.state.phoneNumber;
    let length = phoneNumber?.length;
    let maskedPart1 = phoneNumber?.substring(length - 2);
    let starPhone = `*******${maskedPart1}`;
    return starPhone;
}; 

apiCallService = async (data: APIPayloadType) => {
  const { contentType, method, endPoint, body } = data;
  const header = {"Content-Type": contentType};

  const requestmobileOTPMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
   requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
   requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
   requestmobileOTPMessageSerice.addData( 
      getName(MessageEnum.RestAPIRequestMethodMessage),  
      method
  ); 
  body &&requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
      );
  runEngine.sendMessage(requestmobileOTPMessageSerice.id, requestmobileOTPMessageSerice);
  return requestmobileOTPMessageSerice.messageId; 
};

handleSubmitPhoneOTPForm = async (body: PhoneOTPPayLoad) => { 
   this.phoneNumberOtpVerifyCallID = await  this.apiCallService({     
      contentType: configJSON.postEmailVarificationContentType, 
       method: configJSON.postApimethod, 
       endPoint: configJSON.postPhoneVarificationEndPoint,
      body: body   
    }); 
};  

handleSubmitEmailOTPForm =  async (body: OTPBody)  =>  { 
  this.emailVerifyCallID = await this.apiCallService({ 
      contentType: configJSON.postEmailVarificationContentType,
          method: configJSON.postApimethod, endPoint: configJSON.postEmailVarificationEndPoint, 
      body: body }); 
}; 

handleEmailAndOtpVerifyClose = () => {
  this.setState({ emailOtpVerify: false });
};

handleHelperTextFirstname = () => {
   return this.state.errorsMessage.firstNameMessage ?  this.state.errorsMessage.firstNameMessage :  ""
}
 
handleSubmit = async() => { 
  const { otpInput } = this.state; 
  const errorMsg =  otpInput === '' ||  otpInput.length !==  4 ?  this.transProfileMain(configJSON.enterOTP) : '';
   this.setState({ errorOtpMsg:   errorMsg}); 
   const tokenEmail = await  storage.get("user-email");
   const isApiType = this.state.emailType  === configJSON.smsType; 
   const tokenMobile = await storage.get("sms-token");
   const otpTypeMobile  = configJSON.smsType;   
   const otpTypeEmail  = configJSON.emailType;   
  if (errorMsg === "") {             
       if (isApiType) {                              
          const apiData = { 
            pin: otpInput, 
              token: tokenMobile,
              otp_type:otpTypeMobile
          };
           this.handleSubmitPhoneOTPForm(apiData);
      } else {                
           const apiData = { token:tokenEmail,
            pin: otpInput,otp_type:otpTypeEmail,};
           this.handleSubmitEmailOTPForm(apiData); 
      }  
   }
} 
 
  handleHelperText = (data: string | undefined) => {
    if (data) {
      return this.state.errorsMessage.lastNameMessage;
    }
    return ""
  };

  handleHelperLastText = (data: string | undefined) => {
    if (data) {
      return this.state.errorsMessage.emailMessage;
    }
    return ""
  };

  handleOldPasswordText = () => {
    return this.state.errorsMessage.oldPasswordMessage  ? this.state.errorsMessage.oldPasswordMessage : "";
  }

  handlePhoneNumber = () => {
      return this.state.errorsMessage.phoneNumberMessage  ?  this.state.errorsMessage.phoneNumberMessage  : ""
  }

  handleChangeProfileApiCall = async (responseJson: ApiCallPutError & ErrorPhone) => {
  if(responseJson?.errors && responseJson.errors[0]?.email){
        this.setState({
          alertMsg: `Email ${responseJson.errors[0].email}`,
          isAlert: true,alertType: 'error',loading:false})
      }else if(responseJson?.errors[0]?.phone_number){
        this.setState({
          alertType: 'error',loading:false,
          isAlert: true,alertMsg: `Phone Number ${responseJson.errors[0].phone_number}`   
        });
      } else if(responseJson?.errors?.full_phone_number){
        this.setState({
          isAlert: true,loading:false,
          alertType: 'error',alertMsg: responseJson.errors.full_phone_number
        })
      }
  }

  handleChangePasswordApiCall = (responseJson: ErrorPut) => {
    if(responseJson?.errors){
      this.setState({
        isAlert: true,
        alertType: "error",
        alertMsg: responseJson?.errors
      });
    }else{
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: responseJson.meta.message
      })
    }
    this.setState({loading: false})
  }

  generateClassName = (
    error: boolean | undefined,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };


  handleOldPassword = (passVal: string) => {
    const oldPassword = passVal;
    this.setState({
      oldPassword: oldPassword,
      errorsMessage: {
        oldPasswordMessage: "",
        errorOldPassword: false
      }
    })
  }

  handleNewPassword = (passVal: string) => {
    const newPassword = passVal;
    this.setState({
      newPassword: newPassword,
      errorsMessage: {
        newPasswordMessage: "",
        errorNewPassword: false
      }
    })
  }

  handleRePassword = (passVal: string) => {
    const rePassword = passVal;
    const { newPassword } = this.state;
    if (newPassword !== rePassword) {
      this.setState({
        rePassword: rePassword,
        errorsMessage: {
          rePasswordMessage: this.transProfileMain(configJSON.confirmPass),
          errorRePassword: true
        }
      });
    } else {
      this.setState({
        rePassword: rePassword,
        errorsMessage: {
          rePasswordMessage: "",
          errorRePassword: false
        }
      });
    }
  };

  validatePassword = () => {
    const { oldPassword } = this.state;
    const passRegx = configJSON.passRegx;
  
    if (oldPassword === "" || !passRegx.test(oldPassword)) {
      this.setState({
        errorsMessage: {
          oldPasswordMessage: oldPassword
            ? this.transProfileMain(configJSON.min8digitValidationMsg)
            : this.transProfileMain(configJSON.validationPassMsg),
          errorOldPassword: true
        }
      });
      return false;
    }
  
    return true;
  };
  

  validateNewPassword = () => {
    const { newPassword } = this.state;
    const passRegx = configJSON.passRegx;
    if (newPassword === "" || !passRegx.test(newPassword)) {
      this.setState({
        errorsMessage: {
          newPasswordMessage: newPassword
            ? this.transProfileMain(configJSON.min8digitValidationMsg)
            : this.transProfileMain(configJSON.validationPassMsg),
          errorNewPassword: true
        }
      });
      return false;
    }
    return true;
  };

  handleUserProfilePhoneCodeChange = (event: ICountryResp) => {
    this.setState((prevState) => ({
      selectedCountry: {...prevState.selectedCountry, country_code: event.country_code, numeric_code: event.numeric_code,country_full_name: "",
        country_flag: ""},
      isCountryData: event.numeric_code,
    }));
  };


  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getCountryCodeApi = async () => {
    this.countryCodeApiCallId = await this.apiCall({
      contentType: configJSON.countryApiContentType,
      method: configJSON.httpMethodType,
      endPoint: configJSON.countryCodeEndPoint
    });
  };

  getPersonalDetail = async () => {
    const user_id = localStorage.getItem('user_id');
    const endPoint = `/account_block/accounts/get_personal_detail?user_id=${user_id}`;
    this.personalDetailGetApiCallId = await this.apiCall({
      contentType: configJSON.countryApiContentType,
      method: configJSON.httpMethodType,
      endPoint: endPoint
    });
  }

  validateRePassword = () => {
    const { newPassword, rePassword } = this.state;
    if (rePassword === "" || rePassword !== newPassword) {
      this.setState({
        errorsMessage: {
          rePasswordMessage: rePassword
            ? this.transProfileMain(configJSON.confirmPass)
            : this.transProfileMain(configJSON.reEnterValidationMsg),
          errorRePassword: true
        }
      });
      return false;
    }
    return true;
  };

  handleChangePassword = () => {
    if (!this.validatePassword()) {
      return;
    }
    if (!this.validateNewPassword()) {
      return;
    }
    if (!this.validateRePassword()) {
      return;
    }
    this.changePasswordApiCall();
  }

  changePasswordApiCall = async () => {
    const token = await getStorageData("auth-token");
    this.setState({
      loading: true
    })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    const body = {
      data: {
        "current_password": this.state.oldPassword,
        "new_password": this.state.newPassword,
        "confirm_password": this.state.rePassword
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePasswordPutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/change_password`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNumber = (numberVal: string) => {
    const number = numberVal;
    this.setState({
      phoneNumber: number.replace(/\D/g, ''),
      phoneNumberError: false,
      emailError: false,
      errorsMessage: {
        phoneNumberMessage: "",
        errorPhoneNumber: false
      }
    })
  }

  validateEmail = () => {
    const { email } = this.state; 
    const emailRegex = configJSON.emailRegex; 
    if (email === "" || !emailRegex.test(email)) { 
      this.setState({
        emailError: true,
        errorEmail: true,
        errorsMessage: {
          emailMessage: email
            ? this.transProfileMain(configJSON.emailValidMsg) 
            : this.transProfileMain(configJSON.emailValidationMsg), 
          errorEmail: true
        } 
      });
      return false;
    }
    return true;
  };

  handleFirst = (event:string) => {
    const firstName = event;
    this.setState({
      firstName: firstName.replace(/\d/g, ''),
      firstNameError: false,
      emailError: false,
      errorsMessage: {
        firstNameMessage: "",
        errorFirstName: false
      }
    });
  };


  validateFirstName = () => {
    const { firstName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (firstName.trim() === "" || !nameRegex.test(firstName)) {
      this.setState({
        errorsMessage: {
          firstNameMessage: this.transProfileMain(configJSON.firstNameValidationMsg),
          errorFirstName: true
        },
        firstNameError: true
      });
      return false;
    }
    return true;
  };


  

  validateLastName = () => {
    const { lastName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (lastName.trim() === "" || !nameRegex.test(lastName)) {
      this.setState({
        errorsMessage: {
          lastNameMessage: this.transProfileMain(configJSON.lastNameValidationMsg),
          errorLastName: true
        },
        lastNameError: true
      });
      return false;
    }
    return true;
  };

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    const phoneRegex = configJSON.phoneRegex;

    if (phoneNumber?.trim() === "") {
      this.setState({
        phoneNumberError: true,
        errorsMessage: {
          phoneNumberMessage: this.transProfileMain(configJSON.phoneValidationMsg),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else if (!phoneRegex.test(phoneNumber) && phoneNumber.length <= 7) {
      this.setState({
        phoneNumberError: true,
        errorsMessage: {
          phoneNumberMessage: this.transProfileMain(configJSON.alphnewmertic),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else {
      this.setState({
        phoneNumberError: false,
        errorsMessage: {
          phoneNumberMessage: "",
          errorPhoneNumber: false
        }
      });
      return true;
    }
  };


  handleSaveClick = () => {

    if (!this.validateFirstName()) {
      return;
    }
    if (!this.validateLastName()) {
      return;
    }
    if (!this.validateEmail()) {
      return;
    }

    if (!this.validatePhoneNumber()) {
      return;
    }
    this.postProfileApi();
  };

  postProfileApi = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      token: token
    };
    this.setState({loading: true});
    const formData = new FormData();
    formData.append("first_name", this.state.firstName);
    formData.append("last_name", this.state.lastName);
    formData.append("email", this.state.email);
    formData.append("full_phone_number", `${this.state.selectedCountry.numeric_code}${this.state.phoneNumber}`);
    formData.append("country_code", this.state.selectedCountry.numeric_code);
    formData.append("country_code_name", this.state.selectedCountry.country_code);
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changeProfilePutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_personal_detail`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEmail = (emailVal: string) => {
    const email = emailVal;
    this.setState({
      email: email,
      errorEmail: false,
      emailError: false,
      errorsMessage: {
        emailMessage: "",
        errorEmail: false
      }
    });
  };

  handleLast = (event:string) => {
    const lastName = event;
    this.setState({
      lastName: lastName.replace(/\d/g, ''),
      lastNameError: false,
      emailError: false,
      errorsMessage: {
        lastNameMessage: "",
        errorLastName: false
      }
    });
  }
  // Customizable Area End
}
