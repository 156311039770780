import React from "react";

// Customizable Area Start
import { FormHelperText, Popover, Grid, withStyles,CircularProgress,Button,Radio,Modal,Divider,Fade,FormControlLabel,WithStyles,Backdrop, Box, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { backBtnIcon, downloadIcon, filterIcon } from "../../customform/src/assets";
import TwoLineChart from "../../../components/src/Chart/LineChart";
import SellerProductOverviewController, {
  Props,
} from "./SellerProductOverviewController.web";
import clsx from "clsx";
import { button_close, calendar } from "./assets";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/date-fns';
import i18n from "../../../components/src/i18next/i18n";
import { ClearButton, localeMap, StartDateCalendar, StyledRadioWithStyles } from "./EarningsAnalyticsPage.web";
import Loader from "../../../components/src/LoaderContainer";
import SellerStylish from "../../../components/src/SellerStylish/SellerStylishContainer";
export const productOverviewAnalyticsSaleCount = [
  { label: "Sold Units", value: 0 },
  { label: "Return Units", value: 0 },
  { label: "Appeared in Search", value: 0 },
  { label: "Total Revenue", value: 0 }
]

const analyticsLineChartData = [{
  label: "Sold Units",
  color: "#F59E0B",
},
{
  label: "Appeared in Search",
  color: "#34D399",
}
]

const analyticsMonthLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]
// Customizable Area End

export default class SellerProductOverview extends SellerProductOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBackCSS = ( )=> {
    return i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)";
  }

  viewAnalyticsRole = () => {
    const { roleDataType } = this.state;
    switch (roleDataType) {
      case "seller":
        return "Seller";
      case "stylist":
        return "Stylish";
      default:
        return "";
    }
};
  // Customizable Area End

  render() {
    // Customizable Area Start
    const open = Boolean(this.state.anchorEl);
    const currentDate = new Date();
    if (this.state.isLoadingProductOverviewsales) {
      return (
        <Box style={{ ...webStyle.tabsBoxAnalytics }}>
        <CircularProgress style={webStyle.CircularProgressColor} />
      </Box>
      );
  }
  
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerStylish role={this.viewAnalyticsRole()} navigation={this.props.navigation}>
           <SellerStoreAnalyticsContainer>
              <SellerStoreHeader>
                <Box className="analytics-title-container">
                <img
                    src={backBtnIcon}
                    alt="backIcon"
                    onClick={this.proceedToAnalyticsInsightsPage}
                    data-test-id="back-btn-test-id"
                    style={{transform: this.handleBackCSS()}}
                  />
                  <Typography className="title" style={{whiteSpace: "break-spaces"}}>
                    {this.transEarnings("Product Analytics")} [ {this.state.accountFilter !== "manually" ? `This ${this.state.accountFilter}`: `${this.handleDateShow(this.state.startDate)} - ${this.handleDateShow(this.state.endDate)}`} ]
                  </Typography>
                </Box>
                <Box className="btn-container">
                  <img src={downloadIcon} className="img-icon" alt="down-load-icon" data-test-id="handleDownload" onClick={this.handleOpenPdf} />
                  <img src={filterIcon} className="img-icon" alt="filter-icon" data-testid="handlePopoverOpen" onClick={this.handlePopoverOpen} />
                </Box>
                <Popover
                            id="mouse-over-popover"
                            open={open}
                            anchorEl={this.state.anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            onClose={this.handlePopoverClose}
                            disableRestoreFocus
                            dir={i18n.dir()}
                        >
                            <div style={{
                                width: '435px',
                                borderRadius: '5px',
                            }}>
                                <Box style={styles.filterBox}><Box style={styles.filterName}>{this.transEarnings("Filters")}</Box><img width="35.36px" height="35.36px" src={button_close} data-testid="handlePopoverClose" onClick={this.handlePopoverClose} /></Box>
                                <Divider style={{ color: "#F1F5F9" }} />
                                <Box style={{ padding: '20px' }}>
                                    {this.state.downloadOptionsActive.map((option, index) => (
                                        <>
                                            <Box key={index} style={{ display: "flex", alignItems: 'center' }}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={<StyledRadioWithStyles />}
                                                        label=""
                                                        value={option.type}
                                                        checked={this.state.filterType === option.type}
                                                        onChange={(event) => this.handlefilterChange(event as React.ChangeEvent<HTMLInputElement>)}
                                                        style={{ ...webStyle.radioName, marginRight: '0px' }}
                                                        data-testId={`DownloadOption-${option.type}`}
                                                    />
                                                </Box>
                                                <Box style={{ ...styles.acountData, margin: '0 8px' }}>
                                                    {this.transEarnings(option.label)}
                                                </Box>
                                            </Box>
                                        </>
                                    ))}
                                </Box>
                                {this.state.filterType === "manually" && <Box style={{ padding: '20px' }}>
                                    <MuiPickersUtilsProvider utils={MomentUtils} locale={localeMap[i18n.language as "en" | "ar"]}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <StartDateCalendar
                                                autoOk
                                                disableToolbar
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                dir={i18n.dir()}
                                                style={{ width: '190px' }}
                                                value={this.state.startDate}
                                                onChange={this.handlesatrtDateChange}
                                                data-test-id="handleStartDate"
                                                inputProps={{
                                                    'data-testid': 'handleDateChangeInput',
                                                }}
                                                InputProps={{
                                                    id: "teststart",
                                                    disableUnderline: true,
                                                    style: styles.pickerFields,
                                                    placeholder: this.transEarnings('From')
                                                }}
                                                maxDate={currentDate}
                                                keyboardIcon={<img src={calendar} style={styles.pickerIcon} alt="calendar_startdate" />}
                                            />
                                            <StartDateCalendar
                                                autoOk
                                                disableToolbar
                                                dir={i18n.dir()}
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                style={{ width: '190px' }}
                                                value={this.state.endDate}
                                                onChange={this.handleEndDateChange}
                                                inputProps={{
                                                    'data-testid': 'handleEndDateChange',
                                                }}
                                                data-test-id="handleEndDate"
                                                InputProps={{
                                                    id: "teststart",
                                                    disableUnderline: true,
                                                    style: styles.pickerFields,
                                                    placeholder: this.transEarnings('To')
                                                }}
                                                maxDate={currentDate}
                                                keyboardIcon={<img src={calendar} style={styles.pickerIcon} alt="calendar_startdate" />}
                                            />
                                        </Box>
                                    </MuiPickersUtilsProvider>
                                </Box>}
                                {this.state.error && (
                                  <FormHelperTextContainer data-test-id="date-error">
                                    {this.state.error}
                                  </FormHelperTextContainer>
                                )}
                                <Box style={styles.buttonBox}>
                                    <ClearButton onClick={this.handleClearFilter}>{this.transEarnings("Clear All")}</ClearButton>
                                    <Button style={{ ...styles.applayButton, textTransform: 'capitalize' }} data-testid="filterApply" onClick={this.filterSellerEarnings}>{this.transEarnings("Apply")}</Button>
                                </Box>
                            </div>
                        </Popover>
              </SellerStoreHeader>
              <ChartContainer>
                <Grid container spacing={3}>
                {this.state.productOverviewsalesCountValue.map((item: { label: string; value: number }) => (
                          <Grid item xs={12} md={6} sm={6} lg={3} xl={3}>
                            <SalesOverCountContainer>
                              <Typography className="seller-count-show-div-label">{this.transEarnings(item.label)}</Typography>
                              <Typography className="seller-count-show-div-value">{item.value}</Typography>
                            </SalesOverCountContainer>
                          </Grid>
                        ))}
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} 
                      sm={12} lg={6} xl={6}> 
                    <Box  className="chart-outer-container" > 
                      <Typography className="chart-title">
                        {this.transEarnings("Impression vs Sales")}
                      </Typography> 
                      <Box className="line-chart-labels-container" > 
                         {analyticsLineChartData.map((item: { label: string; color: string }) => (
                          <Box className="line-chart-label" > 
                            <Box style={{ backgroundColor: item.color }} 
                                className="color-picker"  /> 
                            <Typography>
                              {this.transEarnings(item.label)}
                            </Typography> 
                          </Box> 
                        ))} 
                      </Box>
                      <Box className="line-chart-container" > 
                        {this.state.isChartLoading ? <Box className="loader-container"><Loader
                      id="my-offer-loader"
                      dataTestId="my-offer-loader-test"
                    /></Box>:
                        <TwoLineChart  labels={this.extractKeysAndValues(this.state.appearInSearch).keys} 
                          datasets={[ 
                             { 
                              data: this.extractKeysAndValues(this.state.soldUnit).values,
                              borderColor: "#F59E0B", 
                              backgroundColor: "#F59E0B", 
                              borderWidth: 2, 
                              fill: false, 
                            }, 
                            { 
                              data: this.extractKeysAndValues(this.state.appearInSearch).values,
                              borderColor: "#34D399", 
                              backgroundColor: "#34D399", 
                              borderWidth: 2, 
                              fill: false, 
                            }, 
                          ]}  
                          xTickColor="#375280"  
                          yTickColor="#375280"  
                          highlightLastTick
                          isRtl={i18n.dir()==="rtl"}
                        />  
                      }
                      </Box>  
                    </Box> 
                  </Grid> 
                </Grid> 
              </ChartContainer> 
            </SellerStoreAnalyticsContainer> 
        </SellerStylish>
         <StyledOverDrawerOrder aria-labelledby="transition-modal-title" 
          onClose={this.handleClosePdf} 
          BackdropProps={{ 
            timeout: 500, 
          }} 
          open={this.state.pdfOpen}  aria-describedby="transition-modal-description" 
          data-test-id="export-popup-test-id"
          closeAfterTransition
          BackdropComponent={Backdrop} 
          dir={i18n.dir()}
        > 
          <Fade in={this.state.pdfOpen} style={webStyle.febBox} > 
            <Box > 
              <Box style={webStyle.pdfCloseBtn}>
                <img src={button_close} onClick={this.handleClosePdf} />
              </Box> 
              <Box style={webStyle.exportReject as React.CSSProperties}>
                {this.transEarnings("Export Report")}
              </Box> 
              <Box style={webStyle.exportData as React.CSSProperties}>
                {this.transEarnings("Export Analytics report as")}:
              </Box> 
              <Box> 
                {this.state.downloadOptions.map((option, index) => ( 
                  <> 
                    <Box key={index} 
                      style={webStyle.pdfDwonBox}> 
                      <Box style={webStyle.contentContainer} > 
                        <img width="60px" height="60px" src={option.src}/> 
                        <Box style={webStyle.pdfData}> {this.transEarnings(option.label)} </Box> 
                      </Box> 
                      <Box> 
                        <FormControlLabel 
                          control={<StyledRadioOverWithStyles /> } 
                          checked={this.state.accountPdf === option.type} 
                          onChange={this.handlePdfChange}
                          data-test-id={`export-type-${option.type}`}
                          label="" 
                          value={option.type}
                          style={webStyle.radioName} 
                        /> 
                      </Box> 
                    </Box> 
                    { this.state.downloadOptions.length - 1 !== index && <Divider style={webStyle.divayder} /> } 
                  </> 
                ))} 
              </Box> 
              <Box style={webStyle.btnModelContainer} > 
                <StyledButtonOverModelNewOrder onClick={this.handleClosePdf}>
                  {this.transEarnings("Close")}
                </StyledButtonOverModelNewOrder> 
                <StyledButton1OverModelNewOrder data-test-id="export-btn-test-id" onClick={this.handleExportPdf}>
                  {this.transEarnings("Export")}
                </StyledButton1OverModelNewOrder> 
              </Box > 
            </Box> 
          </Fade > 
        </StyledOverDrawerOrder> 
      </ThemeProvider> 
      // Customizable Area End
    ); 
  } 
} 

// Customizable Area Start
const theme = createTheme({ 
  palette: { 
    primary: { 
      contrastText: "#fff",
      main: "#0000ff", 
    } 
  } 
}); 

const webStyle = {
  febBox: { 
    borderRadius: '10px', 
    width: "500px", 
    backgroundColor: "white",
    padding: "10px 30px 30px 30px"
  },
  CircularProgressColor: {
    color: "#375280"
  },
  tabsBoxAnalytics: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfCloseBtn: {
    width: '100%', 
    justifyContent: 'end',
    display: 'flex',
  },
  btnModelContainer: { 
    display: "flex", 
    justifyContent: "space-between", 
    marginTop: '30px',
    gap: "20px",
  },
  pdfData: { 
    color: "#375280",
    marginLeft: '20px', 
    fontSize: "22px", 
    fontWeight: 700, 
    fontFamily: 'Lato' 
  },
  exportData: { 
    fontSize: '18px', 
    color: '#375280',
    fontFamily: 'Lato', 
    fontWeight: 700, 
    textAlign: "center",
    marginBottom: '20px' 
  },
  contentContainer: { 
    alignItems: 'center' ,
    display: 'flex',
  },
  exportReject: { 
    fontSize: '30px', 
    fontFamily: 'Lato', 
    textAlign: "center",
    marginBottom: '20px',
    fontWeight: 500, 
    color: '#375280',
   },
  pdfDwonBox: { 
    display: 'flex', 
    justifyContent: 'space-between' ,
    alignItems: 'center', 
  },
  divayder: { 
    color: '#e2e8f0', 
    marginBottom: '24px',
    marginTop: '24px', 
  },
  radioName: { 
    color: "rgba(55, 82, 128, 1)", 
    fontFamily: "Lato",
    fontSize: "16px", 
    fontWeight: 700,  
  },
}

const SellerStoreAnalyticsContainer = styled(Box)({
  padding: "30px",
  minHeight: "calc(100vh - 85px)",
  "& .popular-product-container": {
    padding: 20,
    border: "1px solid #E2E8F0",
    marginTop: 40,
    maxHeight: 480,
    overflowY: "auto",
    "& .popular-product-title-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 20,
      "& .popular-product-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 20,
        color: "#375280",
      },
    },
    "& .products-container": {
      "& .product-container": {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px 0px",
        "& .product-detail-container": {
          display: "flex",
          gap: 20,
          "& .product-detail": {
            display: "flex",
            flexDirection: "column",
            gap: 15,
            fontFamily: "Lato, sans-serif",
            fontSize: 18,
            width: "350px",
            color: "#375280",
            "& .product-name": {
              fontSize: "24px",
              fontWeight: 500,
            },
            "& .product-order": {
              fontWeight: 700,
            },
            "& .product-content-container": {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              "& .product-title": {
                fontSize: "18px",
                fontWeight: 500
              },
              "& .product-value": {
                fontSize: "18px",
                fontWeight: 700
              }
            }
          },
        },
        "& .product-detail-btn-container":{
          display: "flex",
          "& .product-btn-sold": {
            padding: "0px 25px",
            height: "40px",
            borderRadius: "2px 0px 0px 0px",
            backgroundColor: "#E2E8F0",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            display: "flex",
            color: "#375280",
            alignItems: "center",
            justifyContent: "center",
          },
          "& .product-btn-unit": {
            padding: "0px 25px",
            height: "40px",
            borderRadius: "2px 0px 0px 0px",
            backgroundColor: "#FEE2E2",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: 500,
            textAlign: "center",
            color: "#DC2626",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "15px"
          },
        },
        "& .product-price": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 700,
          fontSize: 18,
          color: "#059669",
        },
      },
    },
  },
}); 
 
const styles = { 
  pickerIcon: { width: '20px', height: '20px' },
  filterName: { fontSize: '20px', fontFamily: 'Lato', fontWeight: 800, color: '#375280' },
  pickerFields: { fontFamily: "Lato", fontWeight: 500, fontSize: "18px", color: "#94A3B8", paddingRight: "5px", backgroundColor: "#F8F8F8", padding: '16px 18px', },
  filterBox: { display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center' },
  icon: {
    width: 20,
    borderRadius: '50%',
    height: 20,
    backgroundColor: '#f5f8fa',
    border: '1px solid #CCBEB1',
    'input:disabled ~ &': {
        background: 'rgba(206,217,224,.5)',
    },
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
},
buttonBox: { padding: '20px', marginTop: '10px', display: 'flex', justifyContent: 'space-between' },
checkedIcon: {
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  backgroundColor: '#cbbeb2',
  '&:before': {
      display: 'block',
      height: 20,
      width: 20,
      content: '""',
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
  },
},
acountData: { color: "#375280", fontSize: "16px", fontWeight: 400, fontFamily: 'Lato' },
applayButton: { backgroundColor: '#CCBEB1', padding: '10px 16px', width: '185px', height: '54px', color: '#FFFFFF', fontFamily: 'Lato', fontSize: '18px', fontWeight: 700 },
  root: {
      '&:hover': {
          backgroundColor: 'transparent',
      },
  },
};

const StyledRadioOverWithStyles = withStyles(styles)((props: WithStyles<typeof styles>) => (
  <Radio 
  disableRipple
      className={props.classes.root}
      checkedIcon={<span className={clsx(props.classes.icon, props.classes.checkedIcon)} />}
      icon={<span className={props.classes.icon} />}
      color="default"
      {...props}
  />
));

const SellerStoreHeader = styled(Box)({
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  height: 44,
  width: "100%",
  "& .btn-container": {
    gap: 24,
    display: "flex",
    height: "100%",
    alignItems: "center",
    "& .img-icon":{
      height: 30,
      width: 30
    }
  },
  "@media(max-width:600px)": {
    height: "max-content",
    "& .analytics-title-container": {
      "& .title": {
        fontSize: "18px !important",
        fontWeight: 600,
      },
    },
    "& .btn-container": {
      flexDirection: "column",
      height: "max-content",
      gap: 4,
    },
  },
  "& .analytics-title-container": {
    justifyContent: "flex-start",
    display: "flex",
    gap: 20,
    alignItems: "center",
    height: "100%",
    "& .title": {
      fontWeight: 700,
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 24,
    }, 
  }, 
}); 

const StyledButtonOverModelNewOrder = styled(Button)(() => ({
  fontWeight: 500,
  textTransform: "none",
  border: "1px solid #CCBEB1",
  borderRadius: "2px",
  color: "#375280",
  fontSize: "18px", height: '54px',
  '&:hover': { 
    backgroundColor: "white", 
}, 
  width: "252px !important",
}))
const StyledButton1OverModelNewOrder = styled(Button)(() => ({
  fontSize: "18px", height: '54px',
  backgroundColor: '#CCBEB1',
  fontWeight: 500,
  textTransform: "none",
  color: "#fff",
  '&:hover': {
    backgroundColor: "#CCBEB1"
},
  width: "252px",
}))

const SellerOverCountContainer = styled(Box)({
  border: "1px solid #E2E8F0",
  padding: 20,
  marginTop: 30,
  "& .MuiGrid-root.MuiGrid-container": {
    "& .MuiGrid-root.MuiGrid-item": {
      "& .seller-count-title": {
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        color: "#375280",
        fontSize: 20,
      },
    },
  },
});

const SalesOverCountContainer = styled(Box)({
  height: "170px",
  width: "100%",
  flexDirection: "column",
  display: "flex",
  gap: 15,
  alignItems: "center",
  backgroundColor: "#F8F8F8",
  justifyContent: "center",
  "& .seller-count-show-div-label": {
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#334155",
    fontSize: 22,
  },
  "& .seller-count-show-div-value": {
    fontFamily: "Lato, sans-serif",
    fontSize: 36,
    color: "#375280",
    fontWeight: 700,
  },
});

const StyledOverDrawerOrder = styled(Modal)({
  backgroundColor: "none",
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
});

const ChartContainer = styled(Box)({ 
  width: "100%", 
  marginTop: 20,
  "& .MuiGrid-root.MuiGrid-container": { 
    height: "max-content",
    "& .MuiGrid-root.MuiGrid-item": {
      "& .chart-outer-container": {
        border: "1px solid #E2E8F0",
        marginTop: 30,
        padding: 20,
        "& .chart-title": {
          fontWeight: 700,
          fontFamily: "Lato, sans-serif",
          color: "#375280",
          fontSize: 20,
        },
        "& .line-chart-labels-container": {
          display: "flex",
          gap: 20,
          padding: "20px 0px",
          "& .line-chart-label": {
            display: "flex",
            gap: 12,
            justifyContent: "center",
            color: "#375280",
            fontSize: 16,
            "& .color-picker": {
              width: 20,
              height: 24,
              borderRadius: 2,
            },
          },
        },
        "& .revenue-chart-container": { 
          width: "100%", 
          marginTop: 30
        }, 
        "& .pie-chart-container": { 
          display: "flex", 
          alignItems: "center", 
          justifyContent: "center", 
          height: "100%", 
          "& .pie-chart": {
            width: "60%",
          },
          "& .pie-chart-label-container": {
            display: "flex",
            flexDirection: "column",
            gap: 30,
            width: "40%",
          },
          "& .pie-chart-label": {
            gap: 12,
            display: "flex",
            width: "100%",
            fontSize: 16,
            color: "#375280",
            "& .label-name": {
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: 2,
            },
            "& .color-picker": {
              borderRadius: 2,
              width: 20,
              height: 24,
            },
          },
          "@media(max-width:800px)": {
            gap: 20,
            flexDirection: "column",
            "& .pie-chart": {
              width: "100%",
            },
            "& .pie-chart-label-container": {
              alignItems: "center",
              width: 320,
            },
          },
        },
        "& .line-chart-container": {
          width: "100%",
        },
        "@media(max-width:600px)": {
          "& .line-chart-container": {
            overflowX: "auto",
            width: "100%",
          },
        },
      },
    },
  },
});

export const FormHelperTextContainer = styled(FormHelperText)({
  margin: "10px 25px",
  color: "f44336",
  display: "flex"
});
// Customizable Area End
