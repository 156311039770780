import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Grid,
  Button
} from "@material-ui/core";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import { createTheme, styled } from "@material-ui/core/styles";
import { imgBackground } from "./assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import SplashScreenController, {
  Props,
  configJSON
} from "./SplashScreenController.web";
import RadioButton from "../../../components/src/RadioButton";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export default class SplashScreen extends SplashScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  typeOptions = [
    { value: "0", label: "Buyer" },
    { value: "1", label: "Seller" },
    { value: "3", label: "Stylist" }
  ];

  languageOptions = [
    { value: "en", label: "English" },
    {
      value: "ar",
      label: "Arabic - عربي",
    },
  ];

  currencyOptions = [
    { value: "0", label: "Dollar" },
    { value: "1", label: "Dinar" }
  ];
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      // Required for all blocks
      <>
        <Header navigation={this.props.navigation}/>
        <MainGrid dir={i18n.dir()} data-test-id="mainGrid" container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              <img src={imgBackground} alt="" className="sideImage" />
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={"formGrid"}
          >
            <Box className={"formBox"}>
              <Box className="formBlock">
                <Box className={"welcomeBlock"}>
                  <Typography className={"welcomeText"}>
                    {this.tranSplashScreen("Welcome!")}
                  </Typography>
                  <Typography className={"welcomeSubText"}>
                    {this.tranSplashScreen("Choose your user type")}
                  </Typography>

                  <FormControl fullWidth>
                    <FormLabel id="type-selection" className={"inputHeading"}>
                      {this.tranSplashScreen("Continue as")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="type-selection"
                      name="type-selection"
                      style={{ justifyContent: "space-between" }}
                      data-test-id="group-user-type"
                      value={this.state.userType}
                      onChange={(event, value) =>
                        this.onChangeHandler("userType", value)
                      }
                    >
                      {this.typeOptions.map(type => (
                        <FormControlLabel
                          key={type.value}
                          value={type.value}
                          control={<RadioButton variant="outlined"/>}
                          label={this.tranSplashScreen(type.label)}
                          data-test-id={`radio-${type.value}`}
                          className={`radioButton ${
                            type.value === this.state.userType
                              ? "activeRadioButton"
                              : ""
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="formComp">
                    <FormLabel
                      id="language-selection"
                      className={"inputHeading"}
                    >
                      {this.tranSplashScreen("Select Language")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="language-selection"
                      name="language-selection"
                      style={{ justifyContent: "space-between" }}
                      value={this.state.language}
                      data-test-id="group-language"
                      onChange={(event, value) =>
                        this.onChangeHandler("language", value)
                      }
                    >
                      {this.languageOptions.map(lang => (
                        <FormControlLabel
                          key={lang.value}
                          value={lang.value}
                          control={<RadioButton variant="outlined" />}
                          label={this.tranSplashScreen(lang.label)}
                          data-test-id={`radio-${lang.value}`}
                          className={`radioButton ${
                            lang.value === this.state.language
                              ? "activeRadioButton"
                              : ""
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl className="formComp">
                    <FormLabel
                      id="currency-selection"
                      className={"inputHeading"}
                    >
                      {this.tranSplashScreen("Select Currency")}
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="currency-selection"
                      name="currency-selection"
                      style={{ justifyContent: "space-between" }}
                      value={this.state.currency}
                      data-test-id="group-currency"
                      onChange={(event, value) =>
                        this.onChangeHandler("currency", value)
                      }
                    >
                      {this.currencyOptions.map(curr => (
                        <FormControlLabel
                          key={curr.value}
                          value={curr.value}
                          control={<RadioButton variant="outlined" />}
                          label={this.tranSplashScreen(curr.label)}
                          data-test-id={`radio-${curr.value}`}
                          className={`radioButton ${
                            curr.value === this.state.currency
                              ? "activeRadioButton"
                              : ""
                          }`}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <Button
                    variant="contained"
                    className="continueButton"
                    disableElevation
                    type="submit"
                    onClick={this.handleSubmit}
                    data-test-id="submit-btn"
                  >
                    {this.tranSplashScreen("Continue")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const MainGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  "& .formComp": {
    width: "100%"
  },
  "& .welcomeBlock": {
  width: "100%",
  "@media (max-width: 600px)": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  }
},
  "& .welcomeSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px",
    marginTop: "10px", cursor: "default",
  },
  "& .sideImageBox": {
    width: "100%",
    height: "100%"
  },
  "& .sideImage": {
    width: "100%",
    height: "100%"
  },
  "& .imageGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .formBlock": {
    marginBottom: "0px"
  },
  "& .radioButton": {
    width: "40%",
    marginRight: "0px",
    marginLeft: "0px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #F8F8F8",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "20px 16px 20px 16px",
    borderRadius: "2px",
    marginBottom: "20px",
    color: "#375280",
    "&	.MuiFormControlLabel-label": {
      fontFamily: "Lato, sans-serif"
    },
    "@media (max-width: 600px)":{
      width:"100%"
      
    }
  },
  "& .activeRadioButton": {
    backgroundColor: "#fff",
    border: "1px solid #CCBEB1",
    borderCollapse: "collapse"
  },
  "& .formGrid": {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "7%"
  },
  "& .formBox": {
    alignItems: "center"
  },
  "& .welcomeText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px",
    alignSelf: "flex-start",
    cursor: "default",
    "@media (max-width : 600px)":{
      alignSelf: "center",
    }
  },
  "& .inputHeading": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "20px 10px 10px 0px",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir, sans-serif",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px"
  },
  "& .continueButton": {
    textTransform: "capitalize",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
    gap: "8px",
    margin: "10px 0",
    width: "100% !important",
    borderRadius: "2px",
    height: "56px !important",
    "& .MuiButton-label": {
      fontWeight: 800,
      fontSize: "18px",
      fontFamily: "Lato, sans-serif",
      color: "white"
    }
  },
  "& .loginButton": {
    cursor: "pointer",
    fontFamily: "Lato , sans-serif",
    fontWeight: 700,
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textAlign: "center"
  },
  "& .login": {
    width: "100%",
    marginTop: "10px"
  },
  "& .bottomText": {
    color: "#375280",
    fontFamily: "Lato, sans-serif",
    textAlign: "center",
    marginTop: "30px"
  }
});

// Customizable Area End
