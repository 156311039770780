// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData } from "../../../../framework/src/Utilities";

export const configJSON = require("../config");
interface Attributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

export interface AddressData {
  id: string;
  attributes: Attributes;
  type: string;
}

interface AddressResponse {
  data: AddressData;
  meta: {
      message: string;
  };
  errors?: {[errKey:string]: string}[];
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  addressData: AddressData[];
  isError: boolean;
  errorMessage: string;
  isVisible: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LocationPickerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllAddressCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      isVisible: false,
      addressData: [],
      isError: false,
      errorMessage: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse =  message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))       
    if(errorResponse){
      this.parseApiCatchErrorResponse(errorResponse)
      return
    }
    if (responseJson?.errors) {
        const errorMessages = responseJson.errors.map((error: { [key:string]: string}) => Object.values(error)[0]).join(", ");
        this.setState({
          isError: true,
          errorMessage: errorMessages,
          isVisible: true,
        })
      return;
    }
    if (apiRequestCallId === this.getAllAddressCallId && (responseJson || responseJson.data)){
      this.setState({
        addressData: responseJson.data ?? [],
        isVisible: true
      })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  closeSnackbar = () => {
    this.setState({
      isError: false,
      errorMessage: "",
    })
  }

  async componentDidMount() {
    const hasUser = await getStorageData("auth-token")
    if(hasUser){
      this.getAllAddress()
    } else {
      this.setState({ isVisible: true })
    }
  }

  getAllAddress = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllAddressCallId = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getAllAddressEndPoint
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };

  // Customizable Area End
}
