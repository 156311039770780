import React, { useEffect, useState } from "react";
import {
  Drawer,
  useMediaQuery,
  Box,
  useTheme,
  IconButton,
  styled,
  Typography,
} from "@material-ui/core";
import StylishBuyerHeader from "../StylishBuyerHeader";
import Header from "../Header";
import Footer from "../Footer";
import i18n from "../i18next/i18n";
import MenuIcon from "@material-ui/icons/Menu";
import Sidebar from "../Sidebar";
import { getStorageData } from "framework/src/Utilities";
import { scroll } from "../Seller/assets"

interface HeaderSidebarProps {
  role: "Seller" | "Stylish" | "Buyer" | "";
  children: React.ReactNode;
  header?: React.ReactNode;
  navigation: any;
  isStylishBuyer?: boolean;
  isBuyerSidebar?: boolean;
}

const HeaderSidebar: React.FC<HeaderSidebarProps> = ({
  role,
  children,
  header,
  isStylishBuyer,
  navigation,
  isBuyerSidebar,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [userName, setUserName] = useState<string>("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dir = i18n.dir();
  const drawerWidth = 330;

  useEffect(() => {
    getUsername()
    setIsDrawerOpen(!isMobile);
  }, [isMobile]);

  const toggleDrawer = () => setIsDrawerOpen((prev) => !prev);

  const shouldShowHeader = role === "Buyer" || (isStylishBuyer && role !== "Stylish");

  const getUsername = async () => {
    try {
      const name = await getStorageData("user_data", true);
      setUserName(`${name?.first_name} ${name?.last_name}`);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const nameCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  const welcomeCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }

  const buyerSidebar = () => (    
    <div>
      <NameTypography style={{margin: nameCSS()}}>
        <img src={scroll} />
        {userName}
      </NameTypography>
      <WelcomeTypography style={{ margin: welcomeCSS()}}>
        {i18n.t("Welcome to your Account", {ns: "languageCurrency"})}
      </WelcomeTypography>
      <Sidebar navigation={navigation}/>
    </div>
    )

  const SidebarComponent = () => {
    if (role !== "Buyer" || !isBuyerSidebar) return <></>;
  
    return isMobile ? (
      <Drawer
        variant="temporary"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        anchor={dir === "ltr" ? "left" : "right"}
        style={{ width: drawerWidth, flexShrink: 0 }}
        dir={dir}
      >
        {buyerSidebar()}
      </Drawer>
    ) : (
      <Box style={{ width: drawerWidth }}>{buyerSidebar()}</Box>
    );
  };

  return (
    <>
      {isStylishBuyer && role !== "Stylish" && <StylishBuyerHeader navigation={navigation} />}
      {role === "Buyer" && <Header navigation={navigation} />}

      {isBuyerSidebar && role === "Buyer" && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          dir={dir}
        >
          {header}
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginTop: 10, marginLeft: dir === "ltr" ? 0 : 10 }}
              onClick={toggleDrawer}
            >
              <MenuIcon style={{ color: "#375280" }} />
            </IconButton>
          )}
        </Box>
      )}

      <MainContainer dir={dir}>
        <SidebarComponent />
        <Box style={{ padding: dir === "ltr" ? "0 0 0 45px" : "0 45px 0 0" }}>{children}</Box>
      </MainContainer>

      {shouldShowHeader && <Footer />}
    </>
  );
};

export default HeaderSidebar;

const MainContainer = styled(Box)({
  display: "flex",
  width: "100%",
  marginLeft: 0,
  marginRight: 0,
});

const NameTypography = styled(Typography)({
  alignItems: "center",
  display: "flex",
  color: "#375280",
  fontWeight: 800,
  gap: "15px",
  fontStyle: "normal",
  fontSize: "28px",
  fontFamily: "Lato",
  textTransform: "capitalize"
});

const WelcomeTypography = styled(Typography)({
  marginBottom: "37px",
  marginLeft: "40px",
  color: "#807D7E",
  fontWeight: 400,
  lineHeight: "33.5px",
  fontStyle: "normal",
  fontSize: "14px",
  fontFamily: "Lato",
})
