import React, { Component } from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  Box,
  createTheme,
  FormHelperText,
  Grid,
  styled,
  ThemeProvider,
} from "@material-ui/core";
import { crossIcon, filterIcon } from "../assets";
import {
  SetPriceFilterListContainer,
  RightFilterBoxContainer,
  FormHelperTextContainer,
  CheckboxContainer,
  PriceBoxContainer,
  PriceBoxContainerInput,
  SPFilterRight,
} from "../Seller/InventoryManagement/SellerInventoryManagement.web";
import {
  assignStoreFilters,
  CatalogueVariantSize,
  Category,
  IAssignFilterState,
  initialAssignStoreFilter,
  SubCategory,
} from "../Seller/InventoryManagement/SellerInventoryManagementController";
import { checkCondition } from "../../../../components/src/Seller/logOut";

interface Props {
  sizes: CatalogueVariantSize[];
  category: Category[];
  subCategory: SubCategory[];
  tranBuyerStoreFilter: (transKey: string) => string;
  subCategoryTole: (id: number) => void;
  dir: "ltr" | "rtl";
  handleFilterApply: (filter: IAssignFilterState) => void;
}

interface S {
  filterSizes: number[];
  filterCategory: number[];
  filterSubCategory: number[];
  anchorElFilter: HTMLElement | null;
  value: { min: string; max: string };
  buyerStoreProductFilterError: {
    priceError: string;
    emptyError: string;
  };
  apiDoneCategoryIds: number[];
  buyerStoreProductFilterState: IAssignFilterState;
  filterState: {
    setPrice: {
      name: string;
    }[];
    selectedSetPrice: string;
  };
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#CCBEB1",
    },
  },
});

class BuyerStoreFilter extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorElFilter: null,
      filterSizes: [],
      filterCategory: [],
      filterSubCategory: [],
      value: { min: "", max: "" },
      buyerStoreProductFilterError: {
        priceError: "",
        emptyError: "",
      },
      apiDoneCategoryIds: [],
      buyerStoreProductFilterState: initialAssignStoreFilter,
      filterState: {
        setPrice: assignStoreFilters,
        selectedSetPrice: "Size",
      },
    };
  }

  handleBuyerStoreProductFilterClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    this.setState({ anchorElFilter: event.currentTarget });
  };

  handleCloseBuyerStoreProductFilter = () => {
    this.setState({ anchorElFilter: null });
  };

  isSizeChecked = (id: number): boolean => {
    const { filterSizes } = this.state;
    return filterSizes.includes(id);
  };

  isCategoryChecked = (id: number): boolean => {
    const { filterCategory } = this.state;
    return filterCategory.includes(id);
  };

  isSubCategoryChecked = (id: number): boolean => {
    const { filterSubCategory } = this.state;
    return filterSubCategory.includes(id);
  };

  handleSizeCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterSizes } = prevState;

      const newFilterSizes = filterSizes.includes(id)
        ? filterSizes.filter((sizeId) => sizeId !== id)
        : [...filterSizes, id];

      return {
        filterSizes: newFilterSizes,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          emptyError: "",
        },
      };
    });
  };

  handleCategoryCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterCategory, apiDoneCategoryIds } = prevState;

      const newFilterCategories = filterCategory.includes(id)
        ? filterCategory.filter((categoryId) => categoryId !== id)
        : [...filterCategory, id];

      let updatedApiDoneCategoryIds = apiDoneCategoryIds;

      if (!apiDoneCategoryIds.includes(id)) {
        this.props.subCategoryTole(id);

        updatedApiDoneCategoryIds = [...apiDoneCategoryIds, id];
      }

      return {
        filterCategory: newFilterCategories,
        apiDoneCategoryIds: updatedApiDoneCategoryIds,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          emptyError: "",
        },
      };
    });
  };

  handleSubCategoryCheckboxChange = (id: number) => {
    this.setState((prevState) => {
      const { filterSubCategory } = prevState;

      const newFilterSubCategory = filterSubCategory.includes(id)
        ? filterSubCategory.filter((subCategoryId) => subCategoryId !== id)
        : [...filterSubCategory, id];

      return {
        filterSubCategory: newFilterSubCategory,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          emptyError: "",
        },
      };
    });
  };

  handleAsFilterPriceChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number
  ) => {
    const newData = { ...this.state.value };
    let parsedValue = event.target.value.replace(/[^0-9.]/g, "");

    if (
      (parsedValue.startsWith("0") && parsedValue.length === 1) ||
      parsedValue.startsWith(".")
    ) {
      parsedValue = "";
    }

    if (index === 0) {
      newData.min = parsedValue;
    } else if (index === 1) {
      newData.max = parsedValue;
    }

    this.setState((prevState) => ({
      ...prevState,
      value: newData,
      buyerStoreProductFilterError: {
        ...prevState.buyerStoreProductFilterError,
        priceError: "",
        emptyError: "",
      },
    }));
  };

  handlePriceBlur = () => {
    const { min, max } = this.state.value;

    const minPrice = parseFloat(min) || 0;
    const maxPrice = parseFloat(max) || 0;

    if (maxPrice < minPrice && maxPrice !== 0 && minPrice !== 0) {
      this.setState((prevState) => ({
        ...prevState,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          priceError: this.props.tranBuyerStoreFilter(
            "Max price cannot be less than min price"
          ),
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          priceError: "",
        },
      }));
    }
  };

  renderASFilterRight = () => {
    const { selectedSetPrice } = this.state.filterState;

    switch (selectedSetPrice) {
      case "Size":
        return this.renderSizeFilter();

      case "Price":
        return this.renderPriceFilter();

      case "Category":
        return this.renderCategoryFilter();

      case "Sub Category":
        return this.renderSubCategoryFilter();

      default:
        return <></>;
    }
  };

  renderSizeFilter = () => (
    <Box>
      {this.props.sizes.map((f) => (
        <SPFilterRight
          key={+f.id}
          dir={this.props.dir}
          data-test-id="size-test-dive-id"
        >
          <CheckboxContainer
            color="secondary"
            data-test-id={`as-size-checked-${+f.id}`}
            checked={this.isSizeChecked(+f.id)}
            onChange={() => this.handleSizeCheckboxChange(+f.id)}
          />
          <Typography
            className={`filter-right-title ${
              this.isSizeChecked(+f.id) ? "selected" : ""
            }`}
          >
            {this.props.tranBuyerStoreFilter(f.attributes.name)}
          </Typography>
        </SPFilterRight>
      ))}
    </Box>
  );

  renderPriceFilter = () => (
    <>
      <PriceBoxContainer>
        <PriceBoxContainerInput
          startAdornment="$ "
          value={this.state.value.min}
          placeholder={this.props.tranBuyerStoreFilter("Min Price")}
          onChange={(event) => this.handleAsFilterPriceChange(event, 0)}
          data-test-id="as-min-price-test-id"
          inputProps={{ maxLength: 6 }}
          onBlur={this.handlePriceBlur}
        />
        <PriceBoxContainerInput
          startAdornment="$ "
          value={this.state.value.max}
          inputProps={{ maxLength: 6 }}
          placeholder={this.props.tranBuyerStoreFilter("Max Price")}
          onChange={(event) => this.handleAsFilterPriceChange(event, 1)}
          data-test-id="as-max-price-test-id"
          onBlur={this.handlePriceBlur}
        />
      </PriceBoxContainer>
      {this.state.buyerStoreProductFilterError.priceError && (
        <FormHelperTextPriceErrorContainer>
          {this.state.buyerStoreProductFilterError.priceError}
        </FormHelperTextPriceErrorContainer>
      )}
    </>
  );

  renderCategoryFilter = () => (
    <Box>
      {this.props.category.map((f) => (
        <SPFilterRight dir={this.props.dir} key={+f.id}>
          <CheckboxContainer
            color="secondary"
            data-test-id={`as-category-checked-${+f.id}`}
            checked={this.isCategoryChecked(+f.id)}
            onChange={() => this.handleCategoryCheckboxChange(+f.id)}
          />
          <Typography
            className={`filter-right-title ${
              this.isCategoryChecked(+f.id) ? "selected" : ""
            }`}
          >
            {this.props.tranBuyerStoreFilter(f.attributes.name)}
          </Typography>
        </SPFilterRight>
      ))}
    </Box>
  );

  renderSubCategoryFilter = () => (
    <Box>
      {this.props.subCategory.length === 0 ? (
        <Typography className="sub-category-not-found">
          {this.props.tranBuyerStoreFilter(
            "Please Select Any one Category For Filter"
          )}
        </Typography>
      ) : (
        <>
          {this.props.subCategory.map((f) => (
            <SPFilterRight key={+f.id} dir={this.props.dir}>
              <CheckboxContainer
                color="secondary"
                data-test-id={`as-sub-category-checked-${+f.id}`}
                checked={this.isSubCategoryChecked(+f.id)}
                onChange={() => this.handleSubCategoryCheckboxChange(+f.id)}
              />
              <Typography
                className={`filter-right-title ${
                  this.isSubCategoryChecked(+f.id) ? "selected" : ""
                }`}
              >
                {this.props.tranBuyerStoreFilter(f.attributes.name)}
              </Typography>
            </SPFilterRight>
          ))}
        </>
      )}
    </Box>
  );

  handlePopover = () => {
    return checkCondition(this.props.dir === "ltr", "right", "left") as
      | "right"
      | "left";
  };

  handleSelectedSetPricesFilter = (item: string) => {
    if (this.state.buyerStoreProductFilterError.priceError) return;
    this.setState((prevState) => ({
      filterState: {
        ...prevState.filterState,
        selectedSetPrice: item,
      },
    }));
  };

  handleBuyerStoreProductClearAll = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        anchorElFilter: null,
        buyerStoreProductFilterError: {
          priceError: "",
          emptyError: "",
        },
        filterSizes: [],
        filterCategory: [],
        filterSubCategory: [],
        value: { min: "", max: "" },
        buyerStoreProductFilterState: initialAssignStoreFilter,
      }),
      () => this.props.handleFilterApply(initialAssignStoreFilter)
    );
  };

  areNotValueForBuyerStoreProductFilter = () => {
    const { filterSizes, filterCategory, filterSubCategory, value } =
      this.state;
    return (
      filterCategory.length === 0 &&
      filterSubCategory.length === 0 &&
      filterSizes.length === 0 &&
      value.max === "" &&
      value.min === ""
    );
  };

  handleBuyerStoreProductFilterApply = () => {
    const {
      value,
      filterSizes,
      filterCategory,
      filterSubCategory,
      buyerStoreProductFilterError,
    } = this.state;
    if (buyerStoreProductFilterError.priceError !== "") return;
    else if (this.areNotValueForBuyerStoreProductFilter()) {
      this.setState((prevState) => ({
        ...prevState,
        buyerStoreProductFilterError: {
          ...prevState.buyerStoreProductFilterError,
          emptyError: this.props.tranBuyerStoreFilter(
            "Please Select any one for Filter. This is required for Filter"
          ),
        },
      }));
      return;
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          buyerStoreProductFilterState: {
            ...prevState.buyerStoreProductFilterState,
            value,
            filterSizes,
            filterCategory,
            filterSubCategory,
          },
          anchorElFilter: null,
        }),
        () =>
          this.props.handleFilterApply(this.state.buyerStoreProductFilterState)
      );
    }
  };

  render() {
    const { anchorElFilter } = this.state;
    const open = Boolean(anchorElFilter);
    const id = open ? "simple-popover" : undefined;

    return (
      <ThemeProvider theme={theme}>
        <div>
          <Button
            aria-describedby={id}
            variant="text"
            data-test-id="ui-filter-ic"
            onClick={this.handleBuyerStoreProductFilterClick}
          >
            <FilterImage
              src={filterIcon}
              alt="filter-icon"
            />
          </Button>
          <PopoverContainer
            id={id}
            anchorEl={anchorElFilter}
            open={open}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: this.handlePopover(),
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            dir={this.props.dir}
            data-test-id="popover-test-id"
          >
            <Box className="filter-header">
              <Typography className="filter-header-title">
                {this.props.tranBuyerStoreFilter("Filters")}
              </Typography>
              <img
                src={crossIcon}
                onClick={this.handleCloseBuyerStoreProductFilter}
                alt="close-icon"
                data-test-id="as-filter-close-ic"
                className="cursor-pointer"
              />
            </Box>
            <Box className="filter-body">
              <Box>
                <Grid container>
                  <Grid item xs={5}>
                    <SetPriceFilterListContainer
                      dir={this.props.dir}
                      data-test-id="set-price-filter-dive-test-id"
                    >
                      {this.state.filterState.setPrice.map((item) => (
                        <Box
                          key={item.name}
                          onClick={() =>
                            this.handleSelectedSetPricesFilter(item.name)
                          }
                          data-test-id={`as-filter-type-${item.name}`}
                          className={`${
                            checkCondition(
                              item.name ===
                                this.state.filterState.selectedSetPrice,
                              "set-price-selected",
                              ""
                            ) as string
                          } cursor-pointer`}
                        >
                          <Typography className="list-item-textfield">
                            {this.props.tranBuyerStoreFilter(item.name)}
                          </Typography>
                        </Box>
                      ))}
                    </SetPriceFilterListContainer>
                  </Grid>
                  <Grid item xs={7}>
                    <RightFilterBoxContainer>
                      {this.renderASFilterRight()}
                    </RightFilterBoxContainer>
                  </Grid>
                </Grid>
              </Box>
              {this.state.buyerStoreProductFilterError.emptyError && (
                <FormHelperTextContainer>
                  {this.state.buyerStoreProductFilterError.emptyError}
                </FormHelperTextContainer>
              )}
              <Box className="btn-container">
                <Button
                  variant="outlined"
                  className={"clear-all-btn"}
                  disableElevation
                  data-test-id="as-filter-clear-test-id"
                  onClick={() => this.handleBuyerStoreProductClearAll()}
                >
                  {this.props.tranBuyerStoreFilter("Clear All")}
                </Button>
                <Button
                  variant="contained"
                  className={"apply-btn"}
                  onClick={() => this.handleBuyerStoreProductFilterApply()}
                  disableElevation
                  data-test-id="as-filter-apply"
                >
                  {this.props.tranBuyerStoreFilter("Apply")}
                </Button>
              </Box>
            </Box>
          </PopoverContainer>
        </div>
      </ThemeProvider>
    );
  }
}

export default BuyerStoreFilter;

const PopoverContainer = styled(Popover)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& .MuiPaper-root.MuiPopover-paper": {
    width: 420,
    "& .cursor-pointer": {
      cursor: "pointer",
    },
    "& .filter-header": {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #F1F5F9",
      padding: checkCondition(
        dir === "ltr",
        "10px 10px 10px 20px",
        "10px 20px 10px 10px"
      ) as string,
      alignItems: "center",
      "& .filter-header-title": {
        color: "#375280",
        fontFamily: "Avenir,sans-serif",
        fontWeight: 800,
        fontSize: 20,
      },
    },
    "& .filter-body": {
      padding: checkCondition(
        dir === "ltr",
        "0px 10px 10px 20px",
        "0px 20px 10px 10px"
      ) as string,
      display: "flex",
      gap: 10,
      flexDirection: "column",
      width: 375,
      "& .filter-container": {
        display: "flex",
        padding: "9px 0px",
        justifyContent: "space-between",
        alignItems: "center",
        width: 375,

        "& .filter-body-title": {
          color: "#375280",
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 18,
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        marginBottom: 10,
        height: "54px",
        width: 380,
        marginTop: 10,
        "& .clear-all-btn": {
          textTransform: "capitalize",
          height: "100%",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .apply-btn": {
          textTransform: "capitalize",
          color: "white",
          borderRadius: "2px",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "@media(max-width:480px)": {
        width: "70vw",
        "& .btn-container": {
          width: "70vw",
        },
        "& .filter-container": {
          width: "70vw",
        },
      },
    },
    "@media(max-width:500px)": {
      width: "78vw",
      "& .filter-container": {
        width: "78vw",
      },
      "& .btn-container": {
        width: "78vw",
      },
    },
  },
}));

const FilterImage = styled("img")({
  cursor: "pointer",
});

const FormHelperTextPriceErrorContainer = styled(FormHelperText)({
  padding: "0px 15px",
  color: "f44336",
});
