// Customizable Area Start
import { arrow, arrowselect } from "./assets";
import {storeSuccessIcon} from "../../customform/src/assets";
import { Box, Checkbox, styled, TextField, MenuItem, Select,  Radio, FormControl,  Typography, Dialog, InputAdornment } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import React,  { ChangeEvent } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ServiceController ,{Props } from "./ServiceController";

import ArrowGender from "../../../components/src/ArrowGender.web";
import i18n from "../../../components/src/i18next/i18n";

// Customizable Area End


export default class CreatePlan extends ServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  handleChange = async(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    let value = target.value;
    const chargeRegex = /^\d*$/;
    if (name === 'planName') {
      if (!chargeRegex.test(value)) {
        return
      }
    }
    if (name) {
      this.setState({
        [name]: value,
        errors: { ...this.state.errors, [name]: '' }
      } as any);
    }

  
  };


handleJustification = () =>{
  return i18n.dir()==="ltr" ? "flex-start" : "flex-end"
}

handleWidthCSS = () =>{
  return i18n.dir()==="ltr" ? 30 : 55
}

handleIconCSS = (arrowHandle: boolean) => {
  const positionValue = arrowHandle ? 10 : 0;
  return i18n.dir()==='ltr' ? {right: positionValue, paddingRight: 10,} : {left: positionValue, paddingLeft: 10}
}

selectIconRender = (arrowHandle: boolean) => (
    <img
      src={arrowselect}
      alt="dropdown"
      style={{ position: "absolute",
        zIndex: 1,
        transform: ArrowGender(arrowHandle),
        transition: "transform 0.3s ease",
        pointerEvents: "none", ...this.handleIconCSS(arrowHandle)}}
      data-test-id="select-drop-down-img"
    />
  );

  handleHorizontal = () =>{
    return i18n.dir() === "rtl" ? "right" as "right" : "left" as "left"
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    const weekMenuValues = ["1", "2", "3", "4", "5", "6", "7"];
    const MenuProps = () => {
      return {
        style: { minWidth: "180px", height: "320px" },
        anchorOrigin: {
          vertical: "bottom" as "bottom", // Use "top", "bottom", or "center"
          horizontal: this.handleHorizontal(),
        },
        transformOrigin: {
          vertical: "top" as "top", // Use "top", "bottom", or "center"
          horizontal: this.handleHorizontal(),
        },
        getContentAnchorEl: null,
      };
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <div dir={i18n.dir()} style={{width:"400px"}}>
      <ImgContainer >
          <img 
          data-test-id="onGoBack"
          style={{ transform: i18n.dir()==="ltr"?"rotate(0deg)": "rotate(180deg)" }}
          src={arrow} alt="arrow" onClick={() => {
    if (this.state.linkStatus === 1 && this.props.onGoBack) {
      this.props.onGoBack();
    } 
  }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h2 style={webStyle.addPro}>
          {this.tranServiceList(this.state.selectedPlan)}
            </h2>
        </ImgContainer>
              <div style={{ width: '100%' }}>
                <h2 style={webStyle.labelH}>{this.tranServiceList(this.state.planLabel)}*</h2>
                <FormControl fullWidth style={webStyle.selectDiv}>
                  <SelectCustom
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.genderValue}
                    label="Age"
                    disableUnderline={true}
                    data-test-id="genderSel1"
                    onChange={(event) => this.setState({ genderValue: event.target.value as string })}
                    onOpen={() => this.setState({ isStylingPerWeekOpen: true })}
                    onClose={() => this.setState({ isStylingPerWeekOpen: false })}
                    dir={i18n.dir()}
                    MenuProps={MenuProps()}
                    IconComponent={() => this.selectIconRender(this.state.isStylingPerWeekOpen)}
                  >
                  {weekMenuValues.map((value) => (
                    <MenuItem key={value} style={{...webStyle.menuItem, justifyContent: this.handleJustification()}} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                  </SelectCustom>
                  {this.state.errors.genderValue && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.tranServiceList(this.state.errors.genderValue)}</div>}

                </FormControl>
              </div>

              <div style={{ width: '100%' }}>
                <h2 style={webStyle.labelH}>{this.tranServiceList("Discussion time")}*</h2>
                <FormControl fullWidth style={webStyle.selectDiv}>
                  <SelectCustom
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.discussionTime}
                    label="Age"
                    disableUnderline={true}
                    data-test-id="genderSel2"
                    onChange={(event) => this.setState({ discussionTime: event.target.value as string })}
                    onOpen={() => this.setState({ isDiscussionTimeOpen: true })}
                    dir={i18n.dir()}
                    onClose={() => this.setState({ isDiscussionTimeOpen: false })}
                    IconComponent={() => this.selectIconRender(this.state.isDiscussionTimeOpen)}
                    MenuProps={MenuProps()}
                  >
                    {weekMenuValues.map((value) => (
                      <MenuItem key={value} style={{...webStyle.menuItem, justifyContent: this.handleJustification()}} value={value}>
                        {value} {this.tranServiceList("Hours")}
                      </MenuItem>
                    ))}
                  </SelectCustom>
                  {this.state.errors.discussionTime && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.tranServiceList(this.state.errors.discussionTime)}</div>}

                </FormControl>
              </div>
          
              <div style={{ width: '100%' }}>
                <h2 style={webStyle.labelH}>{this.tranServiceList("Voice Call Facility")}*</h2>
                <FormControl fullWidth style={webStyle.selectDiv}>
                  <SelectCustom
                  MenuProps={MenuProps()}
                  dir={i18n.dir()}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.voiceCall}
                    label="Age"
                    disableUnderline={true}
                    data-test-id="genderSel3"
                    onChange={(event) => this.setState({ voiceCall: event.target.value as string })}
                    onOpen={() => this.setState({ isVoiceCallFacilityOpen: true })}
                    onClose={() => this.setState({ isVoiceCallFacilityOpen: false })}
                    IconComponent={() => this.selectIconRender(this.state.isVoiceCallFacilityOpen)}
                  >
                    <MenuItem style={{...webStyle.menuItem, justifyContent: this.handleJustification()}} value={"yes"}>{this.tranServiceList("Yes")}</MenuItem>
                    <MenuItem style={{...webStyle.menuItem, justifyContent: this.handleJustification()}} value={"No"}>{this.tranServiceList("No")}</MenuItem>
                  </SelectCustom>
                  {this.state.errors.voiceCall && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.tranServiceList(this.state.errors.voiceCall)}</div>}

                </FormControl>
              </div>

              <div style={{ width: '100%' }}>
                <h2 style={webStyle.labelH}>{this.tranServiceList("Service Charges")}*</h2>
                <CustomInput
                data-test-id="handleChangeg"
                  id="outlined-basic"
                  style={{ padding: i18n.dir()==="ltr"?"8px 0px 0px 15px": "8px 40px 0px 0px", width: `calc(100% - ${this.handleWidthCSS()}px)`,}}
                  placeholder={""}
                  variant="standard"
                  name="planName"
                  value={this.state.planName}
                  onChange={this.handleChange}
                  
                  InputProps={{ disableUnderline: true, startAdornment: (
                    <InputAdornment
                        position="start"
                        component="div"
                        style={{paddingLeft: '-14px'}}
                        disablePointerEvents>
                        <span>{this.state.currencySign}</span>
                    </InputAdornment>
                )}}
                />
                  {this.state.errors.planName && <div style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif", }}>{this.tranServiceList(this.state.errors.planName)}</div>}
              </div>


            
        <div style={{ display: 'flex', marginTop: '25px',alignItems:"center" }}>
          
          <div style={{ marginLeft: 'auto', width: "100%" }}>
          {this.state.editPlan ? (
              <button 
                style={webStyle.confirmBtn} 
                onClick={this.editPlanPut}
                data-test-id="editPlanPut"
              >
                
                {this.tranServiceList("Update Plan")}
              </button>
            ) : (
              <button 
                style={webStyle.confirmBtn} 
                onClick={this.createPlanPost}
              >
                {this.tranServiceList("Create Plan")}
              </button>
            )}
          </div>
        </div>

        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <DialogContainer data-test-id="handleRedirect1"  open={this.state.isSuccess} >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">{this.state.editPlan ? this.tranServiceList("Plan Updated successfully!") : this.tranServiceList("Plan listed successfully!")}</Typography>
          </Box>
        </DialogContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center"
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});
const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  '&.Mui-checked': {
    color: "#CCBEB1",
  },

  '&.Mui-checked + .MuiIconButton-label': {
    backgroundColor: '#CCBEB1',
  }
});
const UploadText = styled(Typography)({
  textTransform: "lowercase",
  color: "#94A3B8",
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "24px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginLeft: "-59px",
  marginTop: "5px"
})
const UploadBtn = styled(Typography)({
  boxShadow: 'none',
  textTransform: "capitalize",
  color: "#375280",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "26px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginLeft: "-45px",
  marginTop: "15px"
})
const CustomRadio = styled(Radio)({
  '&  .MuiIconButton-label': {
    color: '#C7B9AD',
    fontWeight: 'inherit !Important'
  },
  '& MuiTypography-body1': {
    fontWeight: 'inherit !Important'
  }
})
const SelectCustom = styled(Select)({
  paddingLeft: "20px",
  paddingRight: "15px",
  background: "#f1f1f1",
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  height: "53px",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 400,
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  color: '#375280',
  '& .MuiSelect-select.MuiSelect-select': {
    background: '#f1f1f1'
  }
})
const SelectCustomMul = styled(Select)({

  background: "#f1f1f1",
  borderRadius: "4px",
  fontSize: "18px",
  lineHeight: "26px",
  fontWeight: 400,
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  color: '#94A3B8',
  paddingRight: 15,
  boxShadow: 'none', '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select.MuiSelect-select': {
    background: '#f1f1f1',
    padding: "0px !important",
    margin: 0,
    height: 60
  },
})
const CustomBoxDiv = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
})
const CustomBoxDivs = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap:"30px"
})
const CustomBox = styled(Box)({
  display: 'flex',
  alignItems: "center",
  width: '100%',
  marginLeft: "34px",
  marginTop: '12px'
})

const CustomInput = styled(TextField)({
  background: "#f1f1f1",
  height: '43px',
  border: "1px solid #F8F8F8",
  borderRadius: "4px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginBottom: 10,
  '& ::placeholder': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  '& .MuiInputBase-root': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  }
});
const CustomInputTable = styled(TextField)({
  height: '40px',
  borderRadius: "4px",
  width: "100px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",

  border: '1px solid #E2E8F0',
  '& ::placeholder': {
    color: "#375280",
    fontSize: "18px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  '& .MuiInputBase-root': {
    color: "#375280",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    textAlign: 'center',
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    paddingTop: '10px'
  }
});
const CustomImgFont = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "18px",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  color: '#94A3B8',
  marginTop: 42,
  textAlign: 'center',
  background: '#E2E8F0',
})
const webStyle = {
  titleImg: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: '#375280',
  },

  allThreeimg: {
    width: "100%",
    marginBottom: "-40px",
    height: 80
  },
  boxesImg: { width: 100, height: 100, border: '1px solid #E2E8F0', marginTop: 18, marginRight: 22, borderRadius: 2 },
  tableInput: {
    width: 100,
    height: 40,
    border: '1px solid #E2E8F0',
    textAlign: "center"
  },
  thHead: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: '#375280',
    borderBottom: "1px solid #CBD5E1"
  },
  trHead: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: '#375280',
    borderBottom: "1px solid #CBD5E1",
    paddingTop: 25,
    paddingBottom: 25
  },
  errorMsg: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    margin: 0,
    color: '#94A3B8'
  },
  errorMsg1:{
    color: 'rgb(244, 67, 54)',
    fontWeight: 100,
    fontSize: '16px',
    fontFamily: 'Lato, sans-serif',
    border: '1px solid #DC26264D',
    padding: '1px 8px 1px 16px',
    borderRadius: '4px',
    backgroundColor: '#FEE2E24D',
    display:"flex",
    alignItems:"center"
  },
  selectedItem: {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  detailTitle: {
    color: "#375280",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "30px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    height: 122
  },
  detailDiv: {
    width: "103%"
  },
  selectDiv: {
    width: '97%'
  },
  labelH: {
    width: '97%',
    color: "#375280",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px"
  },
  headingL: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "26px",
    fontWeight: 500,
    fontSize: "18px",
    marginBottom: "5px",
    color: "#375280",
    width: '97%',
  },
  labelVari: {
    width: '97%',
    color: "#375280",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    marginBottom: "5px",
    marginTop: '20px'
  },
  menuItem: {
    color: "#375280",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    display: "flex"
  },
  hrTag: {
    width: 315,
    height: 0,
    margin: 0,
    border: '1px solid #E2E8F0'
  }, hrTagFill: {
    width: 244,
    height: 0,
    margin: 0,
    border: '1px solid #375280'
  },
  circleDivActive: {
    border: "2px solid #375280",
    height: 32,
    width: 32,
    borderRadius: '30px'
  },
  circleDiv: {
    border: "2px solid rgb(89, 117, 163)",
    height: 32,
    width: 32,
    borderRadius: '30px'
  }, circleDivFilled: {
    border: "2px solid #375280",
    height: 32,
    width: 32,
    borderRadius: '30px',
    background: '#375280'
  },
  addPro: {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  uploadBtn: {
    marginTop: 18,
    width: '535px',
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer"
  },
  backCsvBtn: {
    width: '200px',
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer"
  }, createVarBtn: {
    width: '250px',
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    marginBottom: 6
  },
  confirmBtn: {
    width: 'calc(100% - 15px)',
    height: '54px',
    background: "#CCBEB1",
    border: "1px solid #CCBEB1",
    color: "#FFFFFF",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    borderRadius: '2px',
    cursor: "pointer"
  },
};

const ImgContainer = styled("div")({
  display: 'flex', alignItems: 'center',
  "& img":{
    cursor: "pointer"
  }
})
// Customizable Area End
