export const supportHelp = require("./assets/24-support-help.svg");
export const analytics = require("./assets/analytics.svg");
export const catalogue = require("./assets/catalogue.svg");
export const sale = require("./assets/sale.svg");
export const earnings = require("./assets/earnings.svg");
export const boardDashboard = require("./assets/menu-boardDashboard.svg");
export const order = require("./assets/orders.svg");
export const userActivity = require("./assets/user-activity.svg");
export const language = require("./assets/language.svg");
export const storeLogo = require("./assets/store-logo.svg");
export const notification = require("./assets/notification.svg");
export const notificationRead = require("./assets/notificationRead.svg");
export const clients = require("./assets/clientss.svg");
export const portfolio = require("./assets/portfolio.svg");
export const inventory = require("./assets/inventory.svg");
export const notSelectedStore = require("./assets/not-selected-store.svg");
export const expandArrowIcon = require("./assets/expand-arrow-icon.svg");
export const defaultProfileIcon = require("./assets/default-profile-img.svg");
export const wishlist = require("./assets/wishlist.svg");
export const address = require("./assets/adreess.svg");
export const deleteAccount = require("./assets/deleteAccount.png")
export const scroll = require("./assets/scroll.svg");
export const selectedDashboard = require("./assets/selected-dashboard.svg");
export const selectedPortfolio = require("./assets/selected-portfolio.svg");
export const selectedCatalogue = require("./assets/selected-catalogue.svg");
export const selectedEarning = require("./assets/selected-earning.svg");
export const selectedInventoryManagement = require("./assets/selected-inventory-management.svg");
export const selectedOrder = require("./assets/selected-order.svg");
export const selectedClients = require("./assets/selected-clients.svg");
export const selectedSupportHelp = require("./assets/selected-24-support-help.svg");
export const selectedLanguage = require("./assets/selected-language.svg");
export const selectedAnalytics = require("./assets/selected-analytics.svg");