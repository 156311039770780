import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
import { apiCall } from "../../../components/src/APICall";
import { addLatLngToUrl } from "../../../components/src/Googlelogin/googleMap.web";

interface INavigateDetailsTo {
  id: string | undefined;
  screenName: string;
  raiseMessage?: Message;
}

interface ImageData {
  portfolio_image_id: number;
  description: string | null;
  image_id: number;
  url: string;
}

interface DataRecord {
  id: number;
  description: string | null;
  account_id: number;
  created_at: string;
  updated_at: string;
  image: ImageData;
}

interface LandingTitleContentDataType {
  id: string,
  type: string,
  attributes: {
      id: number,
      title: string,
      subtitle: string,
      created_at: string,
      updated_at: string
  }
}

interface HandlePostAPIResponse {
  data: {
    id: string,
    type: string,
    attributes: {
      created_at: number,
      favouriteable: {
        data: {
          id: string,
          type: string,
          attributes: {
            availability: null,
            brand: null,
            brand_name: string,
            description: string,
            discount: null,
            fit: string,
            fit_discription: null,
            gender: string,
            is_wishlist: true,
            list_the_product: string,
            manufacture_date: null,
            material: string,
            name: string,
            price: null,
            primary_colour: {
              created_at: "2023-10-05T05:19:28.122Z",
              id: 7,
              name: "Grey",
              updated_at: "2023-10-05T05:19:28.122Z",
            },
            primary_image: "https://google/profile.jpg", primary_price: "45.0",
            primary_size: {
              created_at: string,
              id: number,
              name: string,
              updated_at: string
            },
            prodcut_care: string,
            product_number: null,
            sku: null,
            stock_qty: null
          },
          favouriteable_id: number,
          favouriteable_type: string,
          notes: null,
          updated_at: string,
          wishlist_id: number
        }
      },
      favouriteable_id: number,
      favouriteable_type: string, notes: null,
      updated_at: string,
      wishlist_id: number
    }
  },
  meta: {
    message: string
  }
};
interface HandlePostResponseError {
  error: string
}

interface HeaderTextResp {
   header_text_eng: string,
   header_text_ar: string,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  stylishBuyer: boolean;
  location: {lat: number, lng: number} | null;
  carouselList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      title: string;
      subtitle: string;
      description: string;
      link: string;
      rank: number;
      created_at: string;
      updated_at: string;
      image: string;
      web_image: string
    };
  }[];
  trendgingDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_store_name: string;
      primary_image: string;
      is_wishlist: boolean;
      primary_main_price: string;
      primary_discounted_percentage: number;
    };
  }[]
  recommendationDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean;
      primary_main_price: string;
      primary_discounted_percentage: number;
      primary_store_name: string;
    };
  }[]
  stylistServiceDataList: DataRecord[],
  landingPageTitleList: LandingTitleContentDataType,
  newLaunchDataList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      description: string;
      catalogue_variants: {}[],
      primary_price: string;
      primary_image: string;
      is_wishlist: boolean;
      primary_main_price: string;
      primary_discounted_percentage: number;
      primary_store_name: string;
    };
  }[]
  error: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  token: string;
  modalOpen: boolean;
  favCalled : string;
  favLoading: boolean;
  newLaunchLoading: boolean;
  recommendLoading: boolean;
  userCurrency: string;
  headerText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchCarouselPageListApi: string = "";
  fetchStylistServicePageListApi: string = "";
  fetchLandingPageTitleListApi: string = "";
  fetchTrendingListPageApi: string = "";
  fetchNewLaunchListPageApi: string = "";
  fetchRecommendationListPageApi: string = "";
  postFavouriteApiCalledId: string = "";
  deleteFavouriteApiCalledId: string = "";
  getCartCount: string = "";
  getHeaderTextApiCallId: string = "";
  observerL: MutationObserver | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      location: null,
      showLoader: false,
      carouselList: [],
      stylishBuyer: false,
      trendgingDataList: [],
      newLaunchDataList: [],
      recommendationDataList: [],
      stylistServiceDataList: [],
      landingPageTitleList: {
        id: "",
        type: "",
        attributes: {
          id: 0,
          title: "",
          subtitle: "",
          created_at: "",
          updated_at: ""
        }
      },
      error: "",
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      token: '',
      modalOpen: false,
      favCalled: "",
      favLoading: false,
      newLaunchLoading: false,
      recommendLoading: false,
      userCurrency: "$",
      headerText: "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const ERROR_MESSAGE = "An error occurred, please try again";

    if (!responseJson) {
      this.setState({ error: ERROR_MESSAGE });
      return;
    }
  
    const { errors } = responseJson;
  
    if (errors) {
      this.setState({ error: ERROR_MESSAGE });
      return;
    }
  
      switch (apiRequestCallId) {
        case this.fetchCarouselPageListApi:
          this.setState({
            carouselList: responseJson.data
          });
          break;
        case this.fetchNewLaunchListPageApi:
          this.setState({
            newLaunchDataList: responseJson.data,
            newLaunchLoading: false
          })
          break;
        case this.fetchTrendingListPageApi:
          if(this.state.token && responseJson.data?.length > 0){
            this.setState({
              userCurrency: responseJson.data[0].attributes?.currency_logo,
              trendgingDataList: responseJson.data,
              favLoading: false,
            })
          }
          this.setState({
            trendgingDataList: responseJson.data,
            favLoading: false,
          })
          break;
        case this.fetchRecommendationListPageApi:
          this.setState({
            recommendationDataList: responseJson?.data,
            recommendLoading: false
          })
          break;
        case this.fetchStylistServicePageListApi:
            this.setState({
              stylistServiceDataList: responseJson?.data,
            })   
          break;  
        case this.fetchLandingPageTitleListApi:
            this.setState({
              landingPageTitleList: responseJson?.data,
            }) 
          break;
        case this.postFavouriteApiCalledId:
          this.handleFavouriteResponse(responseJson, this.state.favCalled);
          break;
        case this.deleteFavouriteApiCalledId:
          this.handleDeletefav(message, this.state.favCalled)
          break;
        case this.getCartCount:
          const cartOrder = responseJson.data.attributes.order_items
          await setStorageData("orderNumber", cartOrder ? cartOrder.length : 0 )
          break;
        case this.getHeaderTextApiCallId:
          this.handleResponseHeaderText(responseJson)
          break;
        default:
          runEngine.debugLog("Unhandled API request ID", apiRequestCallId);
      }

    // Customizable Area End
  }

  // Customizable Area Start

  async componentWillUnmount() {
    if (this.observerL) {
      this.observerL.disconnect();
    }
  }

  transLand = (keyLand: string) => {
    return i18n.t(keyLand, { ns: "sidebar"})
  }

  getHeaderText = async () => {
    this.getHeaderTextApiCallId = await apiCall({
      token: undefined,
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.headerTextEndPoint,
    })
  }

  handleResponseHeaderText = (response: HeaderTextResp) => {
      let header = ""
      const isRtl = i18n.dir() === "rtl"
      if(response?.header_text_eng) {
        header = response.header_text_eng
      } else if(response?.header_text_ar && isRtl){
        header = response.header_text_ar
      } else {
        header = this.transLand("TopTitle");
      }
      setStorageData("headerText", header)
  }

  handleFavouriteResponse = (response: HandlePostAPIResponse & HandlePostResponseError, favCalled: string) => {
    if (response.error) {
      this.setState({
        error: response.error, favLoading: false, recommendLoading: false, newLaunchLoading: false
      });
    } else {
      if(favCalled === "recommendation"){
        this.getRecommandtionPageList()
      }else if(favCalled === "trending"){
        this.getTrendingListPage();
      }else if(favCalled === "newLaunch"){
        this.getNewLaunchPageList();
      }
      this.getCarouselLandingPageList();
      this.setState({
        isAlert: true,
        alertMsg: this.transLand(configJSON.addFavTitle),
        alertType: 'success', 
        favLoading: false
      });


    }
  }

  handleCondition = (condition: any, trueSta: any, falseSta: any) => {
      return condition ? trueSta : falseSta
  }

  handleDeletefav = (message: Message, favCalled: string) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson.error) {
      this.setState({
        error: responseJson.error,
        favLoading: false, newLaunchLoading: false, recommendLoading: false
      });
    } else {
      if(favCalled === "recommendation"){
        this.getRecommandtionPageList()
      }else if(favCalled === "trending"){
        this.getTrendingListPage();
      }else if(favCalled === "newLaunch"){
        this.getNewLaunchPageList();
      }
      this.getCarouselLandingPageList();
      this.setState({
        isAlert: true,
        alertMsg: this.transLand(configJSON.removeFavTitle),
        alertType: "success",
      });
    }
  }

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  componentDidMount = async () => {
    const userDetails = await getStorageData("userRole", true);
    const exploreBuyer = await getStorageData("exploreBuyer", true);
    const userLocation = await getStorageData("userLocation", true);

    if(!!userLocation) {
      this.setState({
        location: userLocation
      })
    }
   
    if(exploreBuyer){
      this.setState({
        stylishBuyer: exploreBuyer
      })
    }
    const token = await getStorageData("auth-token");
    this.setState({
        token: token,
    })
    if(userDetails?.currencyLogo){
      this.setState({
        userCurrency: userDetails.currencyLogo
      })  
    }
    this.getHeaderText()
    this.getCartCountFn()
    this.getCarouselLandingPageList();
    this.getTrendingListPage();
    this.getNewLaunchPageList();
    this.getStylistServiceData();
    this.getLandingPageTitleData();
    token && this.getRecommandtionPageList();
    this.observerL = new MutationObserver(this.handleStorageChangeL);
    this.observerL.observe(document.body, { attributes: true });
  };
  



  handleDeleteApiCall = async (favouriteId?: string) => {

    if (!this.state.token) {
      this.setState({
        modalOpen: true, newLaunchLoading: false, favLoading: false, recommendLoading: false
      });
      return false;
    }

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteFavouriteApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteFavouriteApi}?favouriteable_id=${favouriteId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handlePostApiCall = async (favouriteId?: string) => {
    if (!this.state.token) {
      this.setState({
        modalOpen: true, newLaunchLoading: false, favLoading: false, recommendLoading: false
      });
      return false;
    }

    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const httpBody = {
      data: {
        favouriteable_id: favouriteId
      }
    };

    this.postFavouriteApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postFavouriteApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFavouriteApi = async (favouriteId: string, fav: boolean, favFrom: string) => {
    this.setState({
      favCalled: favFrom,
    })
    if(favFrom === "trending"){
      this.setState({
        favLoading: true
      })
    }else if(favFrom === "newLaunch"){
      this.setState({
        newLaunchLoading: true
      })
    }else if(favFrom === "recommendation"){
      this.setState({recommendLoading: true})
    }
    if (fav) {
      this.handleDeleteApiCall(favouriteId);
    } else {
      this.handlePostApiCall(favouriteId);
    }
  };

  handleTrendingList = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'TrendingListPage');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleHiredStylist = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'HiredStylist');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  getStylistServiceData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchStylistServicePageListApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStylistServiceApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getLandingPageTitleData = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchLandingPageTitleListApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingPageTitleContentApi
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getNewLaunchPageList = async () => {
    const {lat ,lng} = this.state.location || {}
    const newCurrency = this.state.userCurrency === "$" ? "dollar" : "dinar"
    const newLaunchEndpoint = addLatLngToUrl(`${configJSON.getLandingPageNewLauncheApi}&currency=${newCurrency}`, lat, lng)
    const newTokendEndPoint = addLatLngToUrl(configJSON.getLandingPageNewLaunchApi, lat, lng)
    const apiRoute = this.state.token ? newTokendEndPoint : newLaunchEndpoint;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    this.fetchNewLaunchListPageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  navigateToDeatilPage = ({ id, screenName, raiseMessage }: INavigateDetailsTo) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id &&
      message.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    raiseMessage &&
      message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    runEngine.sendMessage(message.id, message);
  }

  getTrendingListPage = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const { lat, lng } = this.state.location || {}
    const trendingTokenedUrl = addLatLngToUrl(configJSON.getLandingPageTrendingApi, lat, lng)
    const trendingUrl = addLatLngToUrl(configJSON.getLandingPageTrendingPageApi, lat, lng)
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    const trendcurrency = this.state.userCurrency === "$" ? "dollar" : "dinar"
    const apiRoute = this.state.token ? trendingTokenedUrl : `${trendingUrl}&currency=${trendcurrency}`;
    this.fetchTrendingListPageApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiRoute
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRecommandtionPageList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const { lat, lng } = this.state.location || {}
    const recommendUrl = addLatLngToUrl(configJSON.getLandingPageRecommendationsApi, lat, lng)
    this.fetchRecommendationListPageApi = requestMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      recommendUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  getCarouselLandingPageList = () => {
    const requestCarouselMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchCarouselPageListApi = requestCarouselMessage.messageId;
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };
    requestCarouselMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestCarouselMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLandingCarouselApi
    );
    requestCarouselMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    runEngine.sendMessage(requestCarouselMessage.id, requestCarouselMessage);
    return true;
  };

  getCartCountFn = async () => {
    const unique_token1 = await getStorageData("unique_token");
    const token = await getStorageData("auth-token")
    const tokenAuthr1 =  token === null ? `${configJSON.getActiveCartView}=${unique_token1}` :
    `${configJSON.getUnique_token}=${unique_token1}`
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        unique_token: unique_token1,
        token:token
    };
    const requestMessageLandingList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartCount = requestMessageLandingList.messageId;
    requestMessageLandingList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.product_cataloge
    );
    requestMessageLandingList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        tokenAuthr1
    );
    requestMessageLandingList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageLandingList.id, requestMessageLandingList);
}

  handleStorageChangeL = async () => {
    const newLocationL = await getStorageData("userLocation", true);
    if (newLocationL !== this.state.location) {
      this.setState({ location: newLocationL}, 
        () =>{
          this.getTrendingListPage();
          this.getNewLaunchPageList();
          this.getRecommandtionPageList()
        } );  
    }
  }
  // Customizable Area End
}
