import React from "react";

// Customizable Area Start
import {
  styled, Grid,
  Box, TableContainer, Table, TableBody,InputAdornment,FormHelperText, TableRow, TableCell,Dialog, TableHead, CircularProgress, Typography,Drawer,Divider,TextField,Button,Backdrop,Fade,Modal,withStyles,FormControl,Select,MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import ReturnDetailController, {
  Props,
  SellerOrderSeller,
  OrderItemSeller
} from "./ReturnDetailController.web";
import { Noimageavailablepng, backBlueArrowImg ,message,calls,button_close,document,group_Group} from "./assets";
import moment from "moment";
import { statusContainerReturnData, statusContainerTrackDriver } from "../../../components/src/Seller/SellerStatusPopup";
import { withScriptjs } from 'react-google-maps';
import TrackMap from "../../../components/src/TrackMap";
import i18n from "../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";
export const configJSON = require("./config");
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ReturnDetail extends ReturnDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  errorTestMassege = () => {
    return this.state.errorMessage && <FormHelperText error>{this.state.errorMessage}</FormHelperText>
  }
  errorMessageRedColore = () => {
    return this.state.errorMessage ? '1px solid red' : '#ddd'
  }
  orderItemImg = (orderItem: OrderItemSeller) => {
    return orderItem.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : orderItem.attributes.catalogue_variant_front_image
  };
  handleReturnDetailsImgCSS = () =>{
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  handleAnchor = () => {
    return i18n.dir() === "rtl" ? "left" : "right"
  }
  getOrderDetailData = (orders: SellerOrderSeller[]) => {
    return orders?.find(
      (order: SellerOrderSeller) =>
        order?.attributes.order_management_order?.attributes.order_number === this.state.productDetailId
    );
  };

  marginAutoReturnDetailCSS = () => {
    return i18n.dir()==="ltr" ? "0px 0px 0px auto" : "0px auto 0px 0px"
  }

  statusShow = (statuses: any) => {
    if (!statuses?.attributes) return null;

    const status = statuses.attributes.status;

    switch (status) {
      case 'return_request':
        return (
          <Box className="container" style={{margin: this.marginAutoReturnDetailCSS()}}>
            <button className="readyrequestButton">{this.tranReturnDetail("Return Request")}</button>
          </Box>
        );
      case 'return_in_process':
        return (
          <Box display="flex" style={{margin: this.marginAutoReturnDetailCSS()}} alignItems="center">
            <button className="readyinProgressButton">{this.tranReturnDetail("Return In Process")}</button>
          </Box>
        );
      case 'return_under_process':
        return (
          <Box display="flex" style={{margin: this.marginAutoReturnDetailCSS()}} alignItems="center">
            <button className="readyUnderPButton">{this.tranReturnDetail("Refund Under Process")}</button>
          </Box>
        );
      case 'refunded':
        return (
          <Box style={{margin: this.marginAutoReturnDetailCSS()}} display="flex" alignItems="center">
            <button className="readyRefundedButton">{this.tranReturnDetail("Refunded")}</button>
          </Box>
        );
      default:
        return null;
    }
  };

  statusShowButton = (statuses: SellerOrderSeller | undefined) => {
    if (!statuses?.attributes) return null;
    const status = statuses.attributes.status;
    switch (status) {
      case 'return_request':
        return (
          <Box style={{ display: 'flex', gap: '10px', margin: this.marginAutoReturnDetailCSS() }}>
            <Box display="flex" alignItems="center" style={{ gap: '10px',cursor:'pointer' }}>
              <button className="readyForStatusButton" data-testid="handleOpen1" onClick={() => this.handleOpen1(statuses.attributes.id)}>{this.tranReturnDetail("Reject")}</button>
              <button className="readyForTrackButtonData" data-testid="handleOkDetail" onClick={() => this.handleOkDetail(statuses.attributes.id)}>{this.tranReturnDetail("Accept")}</button>
            </Box>
          </Box>
        );
      case 'return_in_process':
        return (
          <Box style={{ display: 'flex', gap: '10px', margin: this.marginAutoReturnDetailCSS() }}>
            <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
              <button className="readyForStatusButton" data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true, statuses?.attributes.id)}>{this.tranReturnDetail("Order Status")}</button>
              <button disabled={!this.state.orderData.return_pick_at} className="readyForTrackButtonData" data-testid="toggleStatusDataOrder" onClick={this.toggleStatusData(true, statuses?.attributes.id)}>{this.tranReturnDetail("Track Driver")}</button>
            </Box>
          </Box>
        );
      case 'return_under_process':
        return (
          <Box style={{ display: 'flex', gap: '10px', margin: this.marginAutoReturnDetailCSS() }}>
            <Box display="flex" alignItems="center" style={{ gap: '10px' ,cursor:'pointer'}}>
              <button className="readyForStatusButton" data-testid="handleOpenDeleteDetailsModal" onClick={() => this.handleOpenReturnRequestDetailsModal(statuses.id)}>{this.tranReturnDetail("Reject")}</button>
              <button className="readyForTrackButtonData" data-testid="handleOpenReturnRequestDetailsModal" onClick={() => this.handleOpenDeleteDetailsModal(statuses.id)}>{this.tranReturnDetail("Initiate Refund")}</button>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  renderTableBody = (statuses: any) => {
    if (!statuses?.attributes) return null;
    const status = statuses.attributes.status;

    switch (status) {
      case 'return_request':
        return (
          <>
          <TableBody>
            {this.state.SellerOrdersProcessedView?.map((order: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={this.orderItemImg(orderItem)}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady1 }}>
                        {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                      <Box style={webStyle.tableVarientColorMainBoxReady1}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady1 }}>
                      <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
            <StyledDrawer
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.rejectOpen}
              onClose={this.handleClose1}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              dir={i18n.dir()}
            >
              <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                <div style={{ padding: "10px 30px 30px 30px" }}>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                  <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.tranReturnDetail("Reject Return Order")}</h2>
                  <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.tranReturnDetail("Are you sure you want to reject the return order?")}</h2>
                  <Box style={{ marginBottom: '30px' }}>
                    <Box style={webStyle.modelText}>{this.tranReturnDetail("Reason of Rejection")}</Box>
                    <CustomSelectDetail variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                      <MuiSelectSelectDetail
                        value={this.state.numberData}
                        displayEmpty
                        style={webStyle.reminderName}
                        onChange={this.handleSingleChangeNumber}
                        data-testid='sub-categories-selection'
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                        }}
                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                      >
                        <MenuItem value="" disabled>
                          <Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.tranReturnDetail("Out of Stock")}</Box>
                        </MenuItem>
                        {this.state.short.map((select: string, index: number) => (
                          <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.tranReturnDetail(select)}</MenuItem>
                        ))}
                      </MuiSelectSelectDetail>
                    </CustomSelectDetail >
                  </Box>
                  <Box style={{ marginBottom: '30px' }}>
                    <Box style={webStyle.modelText}>{this.tranReturnDetail("Rejection Details")}</Box>
                    <InputFieldDetail
                      id="outlined-multiline-static"
                      multiline
                      type="text"
                      data-test-id="enterRejectionDetails"
                      rows={3}
                      variant="outlined"
                      placeholder={this.tranReturnDetail("Enter Rejection Details")}
                      style={{ width: '100%' }}
                      value={this.state.descriptionReson}
                      onChange={(event) => {
                        this.changeDisc(event.target.value)
                      }}
                    />
                  </Box>
                  <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                    <SellerButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>{this.tranReturnDetail("Close")}</SellerButtonModel>
                    <SellerButton1Model style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectData}>{this.tranReturnDetail("Reject")}</SellerButton1Model>
                  </div>
                </div>
              </Fade>
            </StyledDrawer>

            <StyledDrawer
              aria-labelledby="transition-modal-title"
              onClose={this.handleCancel}
              aria-describedby="transition-modal-description"
              BackdropComponent={Backdrop}
              closeAfterTransition
              open={this.state.visible}
              BackdropProps={{
                timeout: 500,
              }}
              dir={i18n.dir()}
            >
              <Fade in={this.state.visible} style={webStyle.febBox}>
                <div style={{ padding: "10px 30px 30px 30px" }}>
                  <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancel} /></Box>
                  <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.tranReturnDetail("Accept Return?")}</h2>
                  <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.tranReturnDetail("Are you sure you want to accept the return order?")}</h2>

                  <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                    <SellerButtonModel style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>{this.tranReturnDetail("Close")}</SellerButtonModel>
                    <SellerButton1Model style={{ fontSize: "14px" }} data-test-id="AcceptRequest" onClick={() => this.AcceptRequest()}>{this.tranReturnDetail("Confirm")}</SellerButton1Model>
                  </div>
                </div>
              </Fade>
            </StyledDrawer>
          </>
        );
      case 'return_in_process':
        return (
          <>
          <TableBody>
            {this.state.SellerOrdersProcessedViewProcess?.map((order1: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order1.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img src={orderItem.attributes.catalogue_variant_front_image}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady2 }}>
                          {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                      <Box style={{
                        display: "flex",
                        alignItems: "center"
                      }}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                          {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady2 }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
          </>
        );
      case 'return_under_process':
        return (
          <>
          <TableBody>

            {this.state.SellerUnderProcess?.map((order3: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order3.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={this.orderItemImg(orderItem)}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{
                          fontWeight: 400,
                          color: "#375280",
                          fontSize: '16px',
                        }}>
                          {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{
                      color: "#375280",
                      fontWeight: 400,
                      fontSize: '16px',
                    }}>
                      <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{
                      fontWeight: 400,
                      fontSize: '16px',
                      color: "#375280",
                    }}>
                      <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: "#375280",
                    }}>
                      <Box style={webStyle.tableVarientColorMainBoxReady}>
                        <Box
                          style={{backgroundColor: orderItem.attributes.catalogue_variant_color, ...webStyle.tableVarientColorBoxReady,  }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>

            <Dialog
              onClose={this.handleCloseDeleteDetailsModal}
              open={this.state.isOpenDelete}
              maxWidth="md"
              dir={i18n.dir()}
              PaperProps={{ style: { maxWidth: "600px", height: "444px", width: "100%", borderRadius: "12px" } }}
            >
              <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseDeleteDetailsModal} style={{ cursor: "pointer" }} /></Box>
              <Box style={{ margin: '0 auto', padding: '0 20px', display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography style={{ ...webStyle.refTitle, textAlign: "center" }}>{this.tranReturnDetail("Initiate Refund")}</Typography>
                <Typography style={{ ...webStyle.ListTitleReason, textAlign: "center", width: 420 }}>{this.tranReturnDetail("Are you sure you want to initiate a refund for the returned product?")}</Typography>
                <Box style={{ marginTop: "32px", width: '100%' }}>
                  <Typography style={webStyle.refundTitle}> {this.tranReturnDetail("Refund Amount")}</Typography>
                  <InputFieldNewDetail
                    inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                      type: 'number';
                      'aria-label'?: string;
                      min: '0';
                      'data-testid'?: string;
                    }}
                    id="outlined-multiline-static"
                    data-testid="changeDiscRejectMrpData"
                    multiline
                    value={this.state.rejectMrpData}
                    disabled={true}
                    InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: '0px' }}>$</span></InputAdornment>, }}
                    variant="outlined"
                    style={{ border: this.errorMessageRedColore(), width: '100%' }}
                    type="number"
                    onChange={(event) => {
                      const value = event.target.value;
                      const cleanedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                      this.setState({ refundAmount: cleanedValue });
                    }}
                  />
                  {this.errorTestMassege()}
                </Box>
                <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                  <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} data-testid="handleCloseDeleteDetailsModal" onClick={this.handleCloseDeleteDetailsModal}>{this.tranReturnDetail("Close")}</Button>
                  <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="handleConfirm" onClick={() => this.handleConfirmDetalis(this.state.rejectMrpData)}>{this.tranReturnDetail("Confirm")}</Button>
                </Box>
              </Box>
            </Dialog>

            <Dialog
              open={this.state.isOpenReturnRequest}
              maxWidth="md"
              onClose={this.handleCloseReturnRequestDetailsModal}
              PaperProps={{
                style: {
                  height: "731px",
                  maxWidth: "600px",
                  borderRadius: "12px",
                  width: "100%",
                },
              }}
              dir={i18n.dir()}
            >
              <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseReturnRequestDetailsModal} style={{ cursor: "pointer" }} data-testid="handleCloseRejectDeleteModal"/></Box>
              <Box style={{ margin: "0 auto" }}>
                <Typography style={{textAlign: "center", ...webStyle.rejTitle }}>{this.tranReturnDetail("Reject Refund")}</Typography>
                <Typography style={webStyle.ListTitleReason}>{this.tranReturnDetail("Reason of Rejection")}</Typography>
                <CustomSelectReason variant="outlined" style={{ width: '100%', backgroundColor: '#ffffff' }}>
                  <MuiSelectSelectReason
                    inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                    value={this.state.numberDataOrder}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                    }}
                    displayEmpty
                    style={webStyle.reminderName}
                    data-testid='sub-categories-selection'
                    onChange={this.handlenumberDataOrder}
                  >
                    <MenuItem value="" disabled><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.tranReturnDetail("Select Reason")}</Box></MenuItem>
                    {this.state.selectReson.map((select: string, index: number) => (
                      <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.tranReturnDetail(select)}</MenuItem>
                    ))}
                  </MuiSelectSelectReason>
                </CustomSelectReason >
                <Box style={{ marginTop: "32px" }}>
                  <Typography style={webStyle.uploadTitle}>
                    {this.tranReturnDetail("Upload the item image for rejecting the refund")}
                  </Typography>

                  {this.state.combineImg.length == 0 ?
                    <Box style={webStyle.documentBox as React.CSSProperties}>
                      <Box>
                        <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                          <img className="fieldImg" alt='civilIdPassport' src={document} data-testid="openPictureUploadUplode" onClick={() => this.openPictureUploadPhoto(0)} />
                        </Box>
                        <Typography style={{ color: "#375280", fontWeight: 700, fontSize: "18px", fontFamily: "Lato" }}>{this.tranReturnDetail("Upload Image (PNG or JPG)")}</Typography>
                        <Typography style={{ color: "#94A3B8", fontWeight: 400, fontSize: "14px", fontFamily: "Lato" }}>{this.tranReturnDetail("Only PNG or JPG files are accepted")}</Typography>
                      </Box>
                    </Box>
                    :
                    <Box style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
                      <img className="uploadBoximg" src={this.state.combineImg[0] || undefined} style={{ cursor: "pointer", width: '100%', height: '14.5rem' }} />
                      <Box style={{ position: 'absolute', top: '75%', right: '20px' }}><img src={group_Group} width="48px" height="48px" data-testid="handleRemoveimg" onClick={this.handleRemoveimg} /></Box>
                    </Box>
                  }
                  <Button style={{ ...webStyle.uploadBtn, textTransform: "none", }} data-testid="openPictureUploadPhoto" onClick={() => { this.openPictureUploadPhoto(0) }}>
                    {this.tranReturnDetail("Upload Photo")}
                  </Button>
                </Box>
                <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                  <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                    <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} onClick={this.handleCloseReturnRequestDetailsModal}>{this.tranReturnDetail("Close")}</Button>
                    <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="timeOrderDataReject" onClick={this.timeOrderDataReject}>{this.tranReturnDetail("Confirm")}</Button>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </>
        );
      case 'refunded':
        return (

          <TableBody>
            {this.state.SellerRefunded?.map((order4: SellerOrderSeller, index: number) => (
              <React.Fragment key={index}>
                {order4.attributes.order_items.map((orderItem: OrderItemSeller, itemIndex: number) => (
                  <TableRow key={itemIndex}>
                    <TableCell>
                      <Box style={webStyle.productDflex}>
                        <Box>
                          <img
                            src={this.orderItemImg(orderItem)}
                            style={webStyle.productIMg}
                          />
                        </Box>
                        <Box style={{ ...webStyle.tableHaderrowReady }}>
                        {orderItem.attributes.catalogue_name}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.brand_name}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_size}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady }}>
                      <Box style={{
                        alignItems: "center",
                        display: "flex",
                        gap: 8
                      }}>
                        <Box
                          style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: orderItem.attributes.catalogue_variant_color }}
                        />
                        <Typography className="body-typography">
                        {orderItem.attributes.catalogue_variant_color}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady3 }}>
                    <Typography className="body-typography">{orderItem.attributes.catalogue_variant_sku}</Typography>
                    </TableCell>
                    <TableCell style={{ ...webStyle.tableHaderrowReady3 }}>
                    <Typography className="body-typography">{orderItem.attributes.currency_logo}{orderItem.attributes.total_price}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>

        );
      default:
        return null;
    }
  };

  returnDetailRole = () => {
    const { roleData } = this.state;
    switch (roleData) {
        case "stylist":
          return "Stylish";
        case "seller":
          return "Seller";
        default:
          return "";
    }
};

  // Customizable Area End
  render() {
    const orderDetailData = this.getOrderDetailData(this.state.SellerOrdersProcessedView)
      || this.getOrderDetailData(this.state.SellerOrdersProcessedViewProcess)
      || this.getOrderDetailData(this.state.SellerUnderProcess)
      || this.getOrderDetailData(this.state.SellerRefunded);
      const MapLoader = withScriptjs(TrackMap);
    return (
      <>
      <ThemeProvider theme={theme}>
        <SellerStylishContainer role={this.returnDetailRole()} navigation={this.props.navigation}>
            {this.state.isLoadingOut ? (
              <Box style={{ ...webStyle.tabsBoxReady }}>
                <CircularProgress style={webStyle.CircularProgressColor} />
              </Box>
            ) : (
              <>
                <Box style={webStyle.readyForCollectionTableContainer}>
                  <GroupDetailBox>
                    <Box display="block" alignItems="center">
                      <Box className="GroupDetailBoxLeft">
                        <Box data-test-id="proceedToAllOrderPage" onClick={this.proceedToAllOrderPage}>
                          <img src={backBlueArrowImg} style={{transform: this.handleReturnDetailsImgCSS()}} alt="" />
                        </Box>
                        <Typography className="itemIdText">
                          #{orderDetailData?.attributes.order_management_order.attributes.order_number}
                        </Typography>

                        {this.statusShow(orderDetailData)}

                      </Box>
                      <Box>
                    <Typography style={webStyle.itemIdTextDescription}>
                      {this.tranReturnDetail("Total Products")}: {orderDetailData?.attributes.order_items[0].attributes.quantity} | {this.tranReturnDetail("Purchase Date")}: {moment(orderDetailData?.attributes.order_items[0].attributes.placed_at as string).format("D MMM, YYYY")}
                    </Typography>
                  </Box>
                    </Box>
                    {this.statusShowButton(orderDetailData)}
                  </GroupDetailBox>
                  
                  <Box style={webStyle.ArrivingCell2}>
                    <Typography variant="body1" style={webStyle.ArrivingText as React.CSSProperties}>{this.tranReturnDetail("Reason of product rejection")} : <span style={webStyle.ArrivingTextValue}>{orderDetailData?.attributes.order_items[0].attributes?.reason_of_return}</span></Typography>
                  </Box>

                  <GroupOtherDetails className="container">
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                          {this.tranReturnDetail("Store Name")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Customer Name")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.account}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Delivery Date")}
                        </Typography>
                        <Typography className="detailTitleDescription">{moment(orderDetailData?.attributes.order_management_order.attributes.order_deliver_date as string).format("D MMM, YYYY")}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Shipping Address")}
                        </Typography>
                        <Typography className="detailTitleDescription">
                          {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Coupon Applied")}
                        </Typography>
                        <Typography className="detailTitleDescription">{this.tranReturnDetail("Fash")}10(-$5)</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography className="detailTitle">
                        {this.tranReturnDetail("Payment Method")}
                        </Typography>
                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                      </Grid>
                    </Grid>
                  </GroupOtherDetails>

                  <CustomStyledTable className="container">
                    <Table stickyHeader size="small" className="custom-table">
                      <TableHead>
                        <TableRow style={webStyle.tableHeaderBg}>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Product")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Brand")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Size")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Color")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Product Code")}</Typography></TableCell>
                          <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="table-header">{this.tranReturnDetail("Price")}</Typography></TableCell>
                        </TableRow>
                      </TableHead>
                      {this.renderTableBody(orderDetailData)}

                    </Table>
                  </CustomStyledTable>
                </Box>
              </>
            )}
        </SellerStylishContainer>  
      </ThemeProvider>
        <Drawer anchor={this.handleAnchor()} open={this.state.isDrawerStatusOpen} dir={i18n.dir()} onClose={this.toggleStatusDrawer(false)}>
          <Box style={webStyle.MapComponentContainer}>
            <Box style={webStyle.mapHeaderText}>
              <Box style={webStyle.mapTitleContainerText}>
                <Typography style={webStyle.trackDriveTitle}>{this.tranReturnDetail("Order Status")}</Typography>
                <CloseIcon role="presentation"
                  onClick={this.toggleStatusDrawerClose(this.state.orderDataId)}
                  onKeyDown={this.toggleStatusDrawerClose(this.state.orderDataId)} />
              </Box>
            </Box>
            <Box style={webStyle.trackContentStatusContainer}>
              {statusContainerReturnData(this.state.orderData)}
            </Box>
          </Box>
        </Drawer>
        <Drawer anchor={this.handleAnchor()} open={this.state.isDrawerStatus} dir={i18n.dir()} onClose={this.toggleStatusData(false)}>
          <Box style={webStyle.MapComponentContainer}>
            <Box style={webStyle.mapHeaderText}>
              <Box style={webStyle.mapTitleContainerText}>
                <Typography style={webStyle.trackDriveTitle}>{this.tranReturnDetail("Track Driver")}</Typography>
                <CloseIcon role="presentation"
                  onClick={this.toggleStatusDataClose(this.state.orderDataId)}
                  onKeyDown={this.toggleStatusDataClose(this.state.orderDataId)} />
              </Box>
            </Box>
            <Box style={{ height: '449px' }}>
              <div>
                <MapLoader
                  googleMapURL={configJSON.mapApk}
                  loadingElement={<div style={{ width: "200px", height: "400px" }} className="MapComponentHeight" />}
                  origin={this.state.latitudeData}
                  destination={this.state.longitudeData}
                />
              </div>
              <Box style={{ padding: '25px' }}>
                <Box style={webStyle.delivedTime}>
                  <Typography style={webStyle.driverName}>{this.state.sellerDriverName}</Typography>
                  {this.state.orderData.estimated_delivery_time === null ? null : <Typography style={webStyle.driverName}>{this.tranReturnDetail("Delivering in")} : {this.state.orderData.estimated_delivery_time}</Typography>}
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between', margin: '24px 0px' }}>
                  <Box style={webStyle.otpData}>{this.state.orderData.otp === null ? "****" : this.state.orderData.otp} - {this.tranReturnDetail("OTP")}</Box>
                  <Box style={{ display: 'flex', justifyContent: 'space-between', gap:12 }}>
                    <Box><img src={message} /></Box>
                    <Box><img src={calls} /></Box>
                  </Box>
                </Box>
                <Divider style={{ marginBottom: '20px' }} />
                {statusContainerTrackDriver(this.state.orderData)}
              </Box>
            </Box>
          </Box>
        </Drawer>
      </>
    );
  }
}
// Customizable Area Start
const webStyle = {
  uploadBtn: {
    fontSize: "18px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 500,
    width: "520px",
    height: "54px",
    border: "1px solid #CCBEB1",
    marginTop: "16px",
},
  documentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '14.5rem',
    width: '100%',
    backgroundColor: '#F0F0F0',
    position: "relative",
},
  rejTitle: {
    fontSize: "30px",
    marginBottom: "30px",
    lineHeight: "40px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 500,
},
  uploadTitle: {
    fontSize: "18px",
    margin: "10px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 400,
    lineHeight: "24px",
},
  confirm: {
    fontSize: "18px",
    fontFamily: "Lato",
    color: "#FFFFFF",
    fontWeight: 500,
    width: '252px',
    height: "54px",
    backgroundColor: "#CCBEB1",
    marginTop: "16px",
    boxShadow: 'none',
},
  close: {
    fontSize: "18px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 500,
    width: '252px',
    height: "54px",
    border: '1px solid #CCBEB1',
    backgroundColor: 'transparent',
    marginTop: "16px",
    boxShadow: 'none',
},
  refundTitle: {
    fontSize: "18px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 700,
},
  ListTitleReason: {
    fontSize: "18px",
    margin: "10px",
    fontFamily: "Lato",
    color: "#375280",
},
  closeicon: { display: "flex", justifyContent: "flex-end", margin: "20px 20px 0px 0px", color: "#475569" },

  refTitle: {
    fontSize: "30px",
    marginBottom: "30px",
    fontFamily: "Lato",
    color: "#375280",
    fontWeight: 500,
},
  confomMaseg: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
  rejectName: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },

  febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
  reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
  modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
  delivedTime:{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '6px 16px', borderRadius: '2px' },
  treckOrder:{ backgroundColor: '#CCBEB1', padding: '15px', width: '100%', color: '#FFFFFF', fontFamily: 'Lato', fontWeight: 800, fontSize: '20px'},
  driverNameSpase:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '16px' },
  otpData:{backgroundColor: '#CCBEB1', padding: '13px 20px', color: "#FFFFFF", fontWeight: 700, fontSize: '14px', fontFamily: 'Lato',display:'flex',alignItems:'center'},
  driverName:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' },
  trackDriveTitle: {
    fontSize: "24px",
    fontFamily: "Lato",
    fontWeight: 700,
    color: "#375280",
  },
  newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
  trackContentStatusContainer: {
    padding: '10px 25px 80px 25px'
  },
  mapTitleContainerText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
mapHeaderText: {
    padding: 25
  },
  fontFamily: "Lato",
  tableHaderReady: {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 700,
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px",
  },
  tableHaderrowReady: {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 400,
  },
  tableHaderrowReady1: {
    fontWeight: 400,
    color: "#375280",
    fontSize: '16px',
  },
  tableHaderrowReady2: {
    color: "#375280",
    fontWeight: 400,
    fontSize: '16px',
  },
  tableHaderrowReady3: {
    color: "#375280",
    fontSize: '16px',
    fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', height: '14px', borderRadius: '10px',
  },
  tableVarientColorMainBoxReady: {
    display: "flex",
    alignItems: "center", 
    gap: 8
  },
  tableVarientColorMainBoxReady1: {
    alignItems: "center",
    display: "flex",
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  productDflex: {
    display: "flex",
    gap: 10
  },

  productIMg: {
    width: '34px',
    height: '34px',
    borderRadius: '2px',
    marginBottom: "12px",
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingCell2: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  MapComponentContainer: {
    width: 440,
    height: "100%"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  itemIdTextDescription: {
    color: "#9A9A9A",
    fontSize: "16px",
    fontWeight: 500,
    marginLeft: "32px"
  }
}
const StyledDrawer = styled(Modal)((theme) => ({
  alignItems: 'center',
  display: 'flex',
  backgroundColor: "none",
  justifyContent: 'center',
}));
const GroupOtherDetails = styled(Box)({
  border: '1px solid #E2E8F0',
  borderRadius: "5px 5px 0px 0px",
  padding: "24px",
  margin: "25px 0px",
  "& .detailTitle": {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .detailTitleDescription": {
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "10px"
  }
})
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  "& .table-header": {
    fontSize: '16px',
    color: "#375280",
    fontWeight: 700,
    letterSpacing: "1px",
    fontFamily: "Lato",
    WebkitTextStrokeWidth: "0.5px",
    display: 'flex'
  },
  "& .body-typography": {
    display: 'flex',
    fontWeight: 400,
    color: "#375280",
    fontSize: '16px',
    font: "Lato"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToCollectionStatusBtn": {
    width: "147px",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FEF3C7",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const GroupDetailBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
  fontFamily: "Lato",
  flexWrap: "wrap",
  gap: 10,
  "& .GroupDetailBoxLeft": {
    display: "flex",
    alignItems: "center",
    gap: 20,
    flexWrap: "wrap",
    marginBottom: 15
  },
  "& .itemIdText": {
    color: "#375280",
    fontSize: "24px",
    fontWeight: 700,
  },
  "& .readyForStatusButton": {
    color: "rgb(55, 82, 128)",
    width: "150px",
    border: "1px solid rgb(204, 190, 177)",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .readyForTrackButton": {
    color: "rgb(55, 82, 128)",
    width: "150px",
    border: "1px solid rgb(204, 190, 177)",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#ffffff",
    textTransform: "none",
  },
  "& .readyForStatusButton1": {
    width: "150px",
    height: "44px",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    color: "#FFFFFF",
    fontSize: "16px",
    borderRadius: "2px",
    textTransform: "none",
    backgroundColor: "#CCBEB1",
    fontFamily: "Lato",
  },
  '& .readyrequestButton': {
    textTransform: 'none',
    borderRadius: '2px',
    fontWeight: 500,
    fontSize: '16px',
    border: '1px solid white',
    height: '44px',
    backgroundColor: '#0A84FF1A',
    padding: '6px 10px 6px 10px',
    width: '150px',
    color: '#0A84FF',
  },
  '& .readyUnderPButton': {
    padding: '6px 10px 6px 10px',
    border: '1px solid white',
    fontSize: '16px',
    borderRadius: '2px',
    color: '#F545C4',
    textTransform: 'none',
    backgroundColor: '#F545C41A',
    fontWeight: 500,
    height: '44px',
  },
  '& .readyinProgressButton': {
    backgroundColor: '#B55B521A',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 500,
    borderRadius: '2px',
    height: '44px',
    border: '1px solid white',
    padding: '6px 10px 6px 10px',
    color: '#B55B52',
  },
 
  '& .readyRefundedButton': {
    borderRadius: '2px',
    color: '#039000',
    backgroundColor: '#E1EFE1',
    padding: '6px 10px 6px 10px',
    width: '150px',
    textTransform: 'none',
    border: '1px solid white',
    fontWeight: 500,
    height: '44px',
    fontSize: '16px',
  },
  "& .readyForTrackButtonData": {
    color: "#FFFFFF",
    width: "150px",
    border: "none",
    height: "44px",
    fontWeight: 500,
    fontSize: "16px",
    borderRadius: "2px",
    backgroundColor: "#CCBEB1",
    textTransform: "none",
    marginLeft: "10px",
  },
  "& .outForDeliveryStatusTopBtn": {
    color: "#6200EA",
    width: "147px",
    border: "none",
    height: "30px",
    fontWeight: 500,
    textAlign: "center",
    marginLeft: "15px",
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#F0E5FF",
    textTransform: "none",
  }
})
const CustomSelectDetail = withStyles(theme => ({
  root: {
      '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
              borderColor: '#ffffff',
          },
          '& fieldset': {
            borderColor: '#ffffff',
          },
          '&.Mui-focused fieldset': {
              borderColor: '#ffffff',
          },
          "& .MuiSelect-select:focus": {
              backgroundColor: "transparent"
          }
      },
  },
}))(FormControl);
const MuiSelectSelectDetail = styled(Select)({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e9eef4',
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "#e9eef4",
  },
  "&.MuiOutlinedInput-root": {
    borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
},
})
const InputFieldDetail = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
      borderWidth: "1px",
    }
  },
  "& .MuiInputBase-input": {
    color: "rgb(55, 82, 128) !important"
  },
  "& .MuiInputBase-root": {
    borderRadius: "10px",
    margin: "auto",
    fontSize: "16px",
    background: 'rgb(248, 248, 248)',
    width: '100%',
    height: '104px',
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-input": {
    color: "#375280",
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    },
    "&::placeholder": {
      color: "#375280",
      "@media only screen and (max-width: 414px)": {
        fontSize: "20px"
      }
    },

  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
});
const SellerButtonModel = styled(Button)(() => ({
  color: "#375280",
  fontWeight: 500,
  textTransform: "none",
  borderRadius: "2px",
  border: "1px solid #CCBEB1",
  height: "45px",
  width: "252px !important",
  '&:hover': {
      backgroundColor: "white",
  },
}))
const SellerButton1Model = styled(Button)(() => ({
  color: "#fff",
  height: "45px",
  width: "252px",
  fontWeight: 500,
  backgroundColor: '#CCBEB1',
  textTransform: "none",
  '&:hover': {
      backgroundColor: "#CCBEB1"
  },
}))
const InputFieldNewDetail = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#FFFFFF",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },

  },

  "& .MuiOutlinedInput-input": {
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    },
    color: "#375280",
    "&::placeholder": {
      color: "#375280",
      "@media only screen and (max-width: 414px)": {
        fontSize: "20px"
      }
    },

  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "none"
  },
  "& .MuiInputBase-input": {
    color: "rgb(55, 82, 128) !important"
  },
  "& .MuiInputBase-root": {
    fontSize: "16px",
    width: '100%',
    background: 'rgb(248, 248, 248)',
    margin: "auto",
    borderRadius: "2px",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderRadius: "8px",
    borderWidth: "1px",
  },

});
const CustomSelectReason = withStyles(theme => ({
  root: {
      '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
              borderColor: '#ffffff',
          },
          '& fieldset': {
              borderColor: '#ffffff',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#ffffff',
          },
          "& .MuiSelect-select:focus": {
              backgroundColor: "transparent"
          }
      },
  },
}))(FormControl);
const MuiSelectSelectReason = styled(Select)({
  "&.MuiOutlinedInput-root": {
      borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#e9eef4',
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderColor: "#e9eef4",
},
})
// Customizable Area End
