import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import storage from 'framework/src/StorageProvider';
import React, { RefObject, createRef } from "react";
import i18n from "../../../components/src/i18next/i18n";

type UserRole = {
  userType: string;
  language: string;
  currency: string;
};


interface Category {
  id: string | undefined;
  type: string;
  subSubData: SubCategory[] | undefined
  attributes: {
    id: number | undefined;
    updated_at: string;
    created_at: string;
    image: string;
    status: string;
    name: string;
  };
  subSubSubData?: SubCategory[]
}
type imageView = 'front' | 'back' | 'side'
interface SubCategory {
  id: string | number;
  type: string;
  subSubSubData?: SubsubsubCategory[]
  attributes: {
    id: number;
    status: string;
    updated_at: string;
    created_at: string;
    image: string;
    name: string;
  };
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

interface SubsubsubCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    image: string;
    updated_at: string;
    status: string;
    created_at: string;
    name: string;
  };
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onGoBack?: () => void;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  isDeleteDialogOpen: boolean;
  likeWish: boolean
  showError: string
  showAlert: boolean
  catalogueDataLoading: boolean
  pageNum: number
  totalPage: number
  hasmore: boolean
  openModel: boolean
  openModelCsv: boolean
  fileName: string
  openMainForm: boolean
  genderSelect: boolean
  isStylingPerWeekOpen: boolean
  isDiscussionTimeOpen: boolean
  isVoiceCallFacilityOpen: boolean
  genderValue: string
  discussionTime: string
  voiceCall: string
  genValue:string
  productDescription: string
  productCare: string
  fit: string
  material: string
  brand1:string
  planName: any
  clothValue: string
  categorySelect: boolean
  linkStatus: number
  listed: any
  categoryValue1: string
  totalSteps: number
  PrdName: string
  categoryValue: string
  personName: string[]
  selectedSizeIds: string[]
  sizevarient: string[]
  sizeMulSelect: boolean
  colorArry: string[]
  clothSelect: boolean
  selectedColorIds: any
  brand: string
  varient_size: any
  subsubCategory: Category[]
  varient_color: any
  category: Category[]
  create_vari: any
  colorSel: boolean
  create_variEdit: any
  categoryIdd:any;
  selectedCategory1: Category | null;
  selectedCategory: Category | null;
  sortedCategoryLoading: boolean,
  subCategoryLoading: boolean,
  filterStart: boolean,
  showScorl: boolean;
  subsubcategoryValueId:any;
  variants: any;
  selectedView: imageView; 
  backImage?: string | null;
  sideImage?: string | null;
  selectId: number | undefined;
  blackSelectedImage: any
  whiteSelectedImage: any
  subsubSelectId: number | undefined;
  category1: Category[]
  whiteActiveGridImage: string
  blackActiveGridImage: string
  frontImage?: string | null;
  storeList: any;
  find_name:any;
  categoryValueId:any;
  serviceResponce:any;
  editserviceResponce:any;
  serviceShowResponce:any;
  styling_per_week: any;
    discussion_time: any;
    voice_call_facility: any;
    service_charges: any;
  servicedeleteResponce:any;
  sku_change: any;
  catalogueDetail: any;
  selectedStores: any;
  selectAll: boolean;
  errors: {
    genderValue?: string;
    discussionTime?: string;
    voiceCall?: string;
    planName?: any;
  };
  isVariantCreated: boolean,
  validationErrors: string[]
   variant_color: string;
   [key: string]: any;
   gridImages:any;
   isSuccess: boolean;
   isAlert: boolean;
   alertType: AlertType;
   alertMsg:string;
   selectedItem: number;
   arrowDisable: boolean;
   productId:any
   editPlan:boolean;
  userRole: UserRole | null;
  roleData:string,
   selectedColorIdsPrev:any
   selectedSizeIdsPrev:any
   create_var_prev:any
   isColorDropdownOpen: boolean;
   isSizeDropdownOpen: boolean; 
   selectedColor: string;
   selectedColorId:number
   selectedSize: string; 
   selectedPlan: any;
   planLabel: any,
   currencySign: string,
   showCreatePlan: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddProductCatalougeController extends BlockComponent<Props, S, SS> {
   // Customizable Area Start
   elementRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

   getCatalogueApiCallId: string
  getCategoryCallId: string = "";
  getServiceCallId: string = "";
  showServiceCallId: string = "";
  createPlanCallId: string = "";
  editPlanCallId: string = "";
  deletPlanCallID: string = "";
  fileInputRef: any = React.createRef();
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
   // Customizable Area Start
   // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    // Customizable Area Start
    this.state = {
      selectedPlan: '',
      planLabel: 'Styling Per Week',
      isDeleteDialogOpen: false,
      category: [],
      arrayHolder: [],
      token: "",
      likeWish: false,
      showError: '',
      showAlert: false,
      catalogueDataLoading: true,
      pageNum: 1,
      totalPage: 0,
      hasmore: true,
      openModel: false,
      openModelCsv: false,
      fileName: '',
      openMainForm: false,
      genderSelect: false,
      isStylingPerWeekOpen: false,
  isDiscussionTimeOpen: false,
  isVoiceCallFacilityOpen: false,
      genderValue: '',
      discussionTime: '',
      voiceCall: '',
      genValue:'',
      productDescription: '',
      productCare: '',
      fit: '',
      material: '',
      brand: '',
      brand1:'',
      planName: '',
      PrdName:'',
      clothSelect: false,
      clothValue: "Womens Clothing",
      categorySelect: false,
      categoryValue: "fullSleeve",
      categoryValue1: "",
      listed: "listed",
      linkStatus: 1,
      totalSteps: JSON.parse(localStorage.getItem('stylist') || '{}').userType === 'stylist' ? 5 : 6,
      personName: [],
      selectedSizeIds: [],
      sizevarient: [],
      sizeMulSelect: false,
      selectedColorIds: '',
      colorSel: false,
      subCategoryLoading: false,
      sortedCategoryLoading: false,
      showScorl: false,
      filterStart: false,
      categoryValueId: null,
      categoryIdd: null,
      selectedCategory: null,
      subsubcategoryValueId:null,
      category1: [],
      selectedCategory1: null,
      varient_size: [],
      varient_color: [],
      subsubCategory: [],
      create_vari: [],
      selectedStores: [],
      storeList: [],
      create_variEdit:[],
      selectId: 0,
      find_name: '',
      serviceResponce:'',
      editserviceResponce:'',
      serviceShowResponce:{},
      currencySign: "$",
      styling_per_week: "",
    discussion_time: "",
    voice_call_facility: "",
    service_charges: "",
      servicedeleteResponce:'',
      colorArry: [],
      catalogueDetail:'',
      sku_change: '',
      variants: [],
      isAlert: false,
      selectedView: 'front', // default view
      subsubSelectId: 0,
      selectAll: false,
      alertType: 'success' as AlertType,
      sideImage: null,
      backImage: null,
      alertMsg:'',
      errors: {},
      frontImage: null,
      selectedColors: [], 
      isVariantCreated: false,
      gridImages: [
      ],
   
      variant_color: '',
      blackSelectedImage: "",
      whiteSelectedImage: "",
      whiteActiveGridImage: "",
      blackActiveGridImage: "",
      validationErrors: [],
      isSuccess: false,
      arrowDisable: false,
      selectedItem: 0,
      productId:"",
      editPlan:false,
      userRole:null,
      selectedColorIdsPrev:"",
      selectedSizeIdsPrev:"",
   create_var_prev:"",
   roleData:"",
   isColorDropdownOpen: false,
   isSizeDropdownOpen: false, // Initialize size dropdown state
   selectedColor: '',  // Initially empty, updated in componentDidMount
   selectedColorId: 0,  // Initially empty, updated in componentDidMount
   selectedSize: '', 
   showCreatePlan: true,
    };
    // Customizable Area End
   
    // Customizable Area Start
    this.getCatalogueApiCallId = ''
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async componentDidMount() {
  // Customizable Area Start
   const userdetails = await getStorageData('userRole', true)
   if(userdetails?.currencyLogo){
    this.setState({
      currencySign: userdetails.currencyLogo
    })
   }
    this.getService()
   this.setState({productId:await storage.get('productId')})
   
   const serviceShowLocalResponce = localStorage.getItem('serviceShowLocalResponce');


   if (serviceShowLocalResponce) {
    const parsedData = JSON.parse(serviceShowLocalResponce);

    // Update state with the retrieved data
    this.setState({
      planId: parsedData.data.id, 
      genderValue: parsedData.data.attributes.styling_per_week.toString(),
      discussionTime: parsedData.data.attributes.discussion_time,
      voiceCall: parsedData.data.attributes.voice_call_facility ? 'yes' : 'no',
      planName: parsedData.data.attributes.service_charges.toString(),
    });
  }

   this.setState({editPlan: localStorage.getItem("editPlan")=="true"})
   const savedPlan = localStorage.getItem('selectedPlan');

   if (savedPlan) {
     this.setState({ selectedPlan: savedPlan });
   } 
    
   let planLabel = this.tranServiceList('Stylings per week'); // Default label

   // Set the plan label based on the selected plan
   if (savedPlan === 'Monthly Plan') {
     planLabel = 'Styling Per Month';
   } else if (savedPlan === 'Quarterly Plan') {
     planLabel = 'Styling Per Quarter';
   }

   this.setState({ selectedPlan: savedPlan || 'Weekly Plan', planLabel });
  

    const userData = await getStorageData("userRole", true)
    this.setState({ roleData: userData.userType })

 

  
  
   
  // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ token: from });
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    

     

    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoryCallId !== null &&
      this.getCategoryCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonCategory = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ category: responseJsonCategory.data, sortedCategoryLoading: false })
    }

  
   
this.managegetServiceResponce(message);
this.manageShowServiceResponce(message);
this.managegetServicPostResponce(message);
this.manageEditServictResponce(message);
this.managegetServiceDeleteResponce(message);

    // Customizable Area End
  }

  // Customizable Area Start
 


  closeModel = () => {
    
    this.setState({ showAlert: false })
  }



  handleRedirect = (redirect:string) =>{
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  }
  

    oncloseAlert = () => {
      this.setState({ isAlert: false });
    };
    


  getService = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getService}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
   
  }


  showService = async (planId:any) => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showServiceCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.product_cataloge
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getService}/${planId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
   
  }
  handleEdit = (service: any) => {
    localStorage.setItem("editPlan", "true");
    if ( service?.attributes?.name === "weekly_plan") {
      localStorage.setItem("selectedPlan", "Weekly Plan");
    
    }
    if (service?.attributes?.name === "monthly_plan") {
      localStorage.setItem("selectedPlan", "Monthly Plan");
    
    }
    if (service?.attributes?.name === "quarterly_plan") {
      localStorage.setItem("selectedPlan", "Quarterly Plan");
     
    }
    this.showService(service?.id);
   
    
    const { serviceResponce } = this.state;
   
    // Check if serviceResponce.data is an array
    if (Array.isArray(serviceResponce.data)) {
        const selectedPlan = serviceResponce.data.find(
            (data: any) => data.id === service.id
        )?.attributes.name;

        this.setState({
            editPlan: true,
            selectedPlanForEdit: selectedPlan
        });
        
    } 
    else {
        console.error('serviceResponce.data is not an array:', serviceResponce.data);
    }

   
};



  
  manageShowServiceResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showServiceCallId !== null &&
      this.showServiceCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseService = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ serviceShowResponce: responseJsonseService})
      localStorage.setItem("serviceShowLocalResponce", JSON.stringify(responseJsonseService))
      this.setState({openMainForm:true})
    }
  }

  
  managegetServiceResponce = (message: Message) => {
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getServiceCallId !== null &&
      this.getServiceCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJsonseService = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.setState({ serviceResponce: responseJsonseService})
    }
  }
  handleServiceRedirect = (path: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };


  editPlanPut = async () => {

    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const { genderValue, discussionTime, voiceCall, planName, planId } = this.state;
    const errors: any = {};


    const body = {
      "service_information": {
        "name":
         this.state.selectedPlan === "Monthly Plan" ? "monthly_plan" : 
                this.state.selectedPlan === "Quarterly Plan" ? "quarterly_plan" : 
                this.state.selectedPlan === "Weekly Plan" ? "weekly_plan" : "default_plan",
        "styling_per_week": parseInt(this.state.genderValue, 10),

        "discussion_time": this.state.discussionTime,

        "voice_call_facility": this.state.voiceCall === "yes" ? true : false,

        "service_charges": parseInt(this.state.planName, 10),
      }
    };

    const requestMessageList = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editPlanCallId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `accounts/service/${planId}` 
    );

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT" 
    );

    runEngine.sendMessage(requestMessageList.id, requestMessageList);


    if (!genderValue) errors.genderValue = "Please enter styling per week/ month/quarterly";
    if (!discussionTime) errors.discussionTime = "Please enter valid discussion time";
    if (!voiceCall) errors.voiceCall = "Please enter the valid facility";
    if (!planName || planName <= 0) {
      errors.planName = "Please Enter valid service charges";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
  }




    createPlanPost=async ()=>{
      localStorage.removeItem("editPlan")
      const { genderValue, discussionTime, voiceCall, planName } = this.state;
  const errors: any = {};

  if (!genderValue) errors.genderValue = "Please enter styling per week/ month/quarterly";
  if (!discussionTime) errors.discussionTime = "Please enter valid discussion time";
  if (!voiceCall) errors.voiceCall = "Please enter the valid facility";
  if (!planName || planName <= 0) {
    errors.planName = "Please Enter valid service charges";
  }

  if (Object.keys(errors).length > 0) {
    this.setState({ errors });
    return;
  }
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const body = {
     

      
        "service_information": {
          "name": this.state.selectedPlan === "Monthly Plan" ? "monthly_plan" : 
          this.state.selectedPlan === "Quarterly Plan" ? "quarterly_plan" : 
          this.state.selectedPlan === "Weekly Plan" ? "weekly_plan" : "default_plan",
          "styling_per_week": parseInt(this.state.genderValue, 10),
          "discussion_time":  this.state.discussionTime,
          "voice_call_facility": this.state.voiceCall === "yes" ? true : false,
          "service_charges": parseInt(this.state.planName, 10),
        }
      
      
    }

const setIntroMsg = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.createPlanCallId = setIntroMsg.messageId;

setIntroMsg.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `accounts/service`
);

setIntroMsg.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);
setIntroMsg.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  JSON.stringify(body)
);

setIntroMsg.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  "POST"
);

runEngine.sendMessage(setIntroMsg.id, setIntroMsg);
    }
 
    managegetServicPostResponce = (message: Message) => {
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.createPlanCallId !== null &&
        this.createPlanCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJsonseService = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        this.setState({ serviceResponce1: responseJsonseService });
    
        if (responseJsonseService.errors && responseJsonseService.errors.length > 0) {
          const errorMessage = responseJsonseService.errors[0].name;
          this.setState({
            isAlert: true,
            alertMsg: errorMessage, 
            alertType: 'error'
          });
        }
        else if (responseJsonseService.data && responseJsonseService.data.id) {
          this.setState({ isSuccess: true }, () => {
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          });

        }
        localStorage.removeItem("editPlan")
      }
    };
    
    manageEditServictResponce = (message: Message) => {
      if (
        getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.editPlanCallId !== null &&
        this.editPlanCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        let responseJsonseService = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        this.setState({ editserviceResponce: responseJsonseService });
    
        if (responseJsonseService.errors && responseJsonseService.errors.length > 0) {
          const errorMessage = responseJsonseService.errors[0].name;
          this.setState({
            alertType: 'error',
            isAlert: true,
            alertMsg: errorMessage, 
          });
        }
        else if (responseJsonseService.data && responseJsonseService.data.id) {
          this.setState({ isSuccess: true }, () => {
            setTimeout(() => {
              window.location.reload();
            }, 4000);
          });
        }
        localStorage.removeItem("editPlan")
        // localStorage.removeItem("selectedPlan");
      }
    };
    Deleteplan=async ( addressID: string | null)=>{
      const token = await getStorageData("auth-token");
      const header = {
          token: token,
      };
        
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.deletPlanCallID = requestMessage.messageId;
          
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `accounts/service/${addressID}`
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `DELETE`
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
         
          
      }
      managegetServiceDeleteResponce = (message: Message) => {
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
          this.deletPlanCallID !== null &&
          this.deletPlanCallID ===
          message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
          let responseJsonseService = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          this.setState({ servicedeleteResponce: responseJsonseService})
          if(this.state.servicedeleteResponce===responseJsonseService){
            this.setState({ isAlert: true, alertMsg: 'Plan deleted successfully', alertType: 'success'});
          }
        }
        
      }

      handleRemovePlan = async (planId: string) => {
        try {
          await this.Deleteplan(planId);  // Call the Deleteplan function
          // Filter out the removed plan from serviceResponce state
          this.setState({
            serviceResponce: {
              ...this.state.serviceResponce,
              data: this.state.serviceResponce.data.filter(
                (service: any) => service.id !== planId
              ),
            },
          });
        } catch (error) {
          console.error("Error removing plan:", error);
        }
      };
      handleOpenDeleteDialog = (addressID: string) => {
        this.setState({ isDeleteDialogOpen: true, addressToDelete: addressID });
      };
      
     
      handleCloseDeleteDialog = () => {
        this.setState({ isDeleteDialogOpen: false, addressToDelete: null });
      };
      
      handleConfirmDelete = () => {
        this.handleRemovePlan(this.state.addressToDelete);
        this.handleCloseDeleteDialog();
      }; 
      tranServiceList = (transKey: string) => {
        return i18n.t(transKey, {ns: "catalogue"});
      }

      handleGoBack = () => {
        this.setState({ openMainForm: false }); // Close the dialog
      };
    
      openCreatePlan = () => {
        this.setState({ openMainForm: true }); // Open the dialog if needed elsewhere
      };
  // Customizable Area End
}
