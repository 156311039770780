import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import SellerSidebar from "../../../components/src/Seller/SellerSideBar";
import { Grid, styled, Box, Typography, Button, TextField, Dialog, DialogContent, DialogActions, Backdrop, Modal, Fade } from "@material-ui/core";
import { editFillUploadIcon, Noimageavailable, upload, uploadImgAr } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import OtpInput from "react-otp-input";
import i18n from "../../../components/src/i18next/i18n";
import CountrySelect from "../../../components/src/CountryDropDown.web";
// Customizable Area End

import SellerProfileController, {
    Props,
    configJSON
} from "./SellerProfileController.web";
import { checkCondition } from "../../../components/src/Seller/logOut";



// Customizable Area Start
const theme = createTheme({
    direction: i18n.dir(),
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
type leftRight = "left" | "right";
// Customizable Area End

export default class SellerProfile extends SellerProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    renderShowHidePwd = (isVisible: boolean)=>{
        return isVisible ? <Visibility style={webStyle.iconstyle} /> : <VisibilityOff style={webStyle.iconstyle} />;
    }
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start 
            <ThemeProvider theme={theme}>
                <Grid dir={i18n.dir()} container>
                    <Grid item xl={2} md={2} lg={2} sm={2} xs={2} >
                        < SellerSidebar navigation={this.props.navigation} />
                    </Grid>
                    <Grid dir={i18n.dir()} item xl={10} md={10} lg={10} sm={10} xs={10}  >
                        <SellerHeader navigation={this.props.navigation} />
                        <Snackbar
                            dir={i18n.dir()}
                            open={this.state.isAlert}
                            autoHideDuration={3000}
                            anchorOrigin={{
                                horizontal: checkCondition(i18n.dir() === 'rtl','left', 'right') as leftRight,
                                vertical: 'top'
                            }}
                            onClose={this.oncloseAlert}
                            data-test-id="alertTestId"
                        >
                            <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                        </Snackbar>
                        <StoreProfile>
                            <Typography className="store-title">{this.transSeller(configJSON.MyprofileMsg)}</Typography>
                            <Box style={webStyle.profileData}>
                                <Box className="profileContainer">
                                    <Box >
                                        <img src={checkCondition(this.state.newProfileImg !== undefined, this.state.newProfileImg, Noimageavailable) as string } style={webStyle.imageSrc} alt="profile" />
                                    </Box>
                                    <Box className="profileName">
                                        <Typography className="profile-title">{this.state.firstName + " " + this.state.lastName}</Typography>
                                        <Button className="profileText" data-test-id="editProfileButton" onClick={this.handleEditProfileClick}>{this.transSeller(configJSON.EditProfileMsg)}</Button>
                                    </Box>
                                </Box>

                                <Seccontainer dir={i18n.dir()} >
                                    <Box className="leftContainer">
                                        <Box>
                                            <Typography className="user-title">{this.transSeller(configJSON.userDetailsMsg)}</Typography>
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.Fname)}</Typography>
                                            <TextField
                                                data-test-id="FristNameId"
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.firstName}
                                                onChange={(event) => this.handleFirst(event.target.value)}
                                                helperText={this.generateHelperFirst()}
                                                error={true}
                                                className={this.generateClassName(this.state.errorsMessage.firstNameMessage, "inputFeild2", "inputFeild2Error2")}
                                                placeholder={this.transSeller(configJSON.PlaceholderFirst)}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.Lname)}</Typography>
                                            <TextField
                                                data-test-id="LastNameId"
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.lastName}
                                                onChange={(event) => this.handleLast(event.target.value)}
                                                helperText={this.generateHelperLast()}
                                                error={true}
                                                className={this.generateClassName(this.state.errorsMessage.lastNameMessage, "inputFeild2", "inputFeild2Error2")}
                                                placeholder={this.transSeller(configJSON.Placeholderlast)}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.Email)}</Typography>
                                            <TextField
                                                data-test-id="email"
                                                fullWidth
                                                variant="outlined"
                                                value={this.state.email}
                                                onChange={(event) => this.handleEmail(event.target.value)}
                                                helperText={this.generateHelperEmail()}
                                                error={true}
                                                className={this.generateClassName(this.state.errorsMessage.emailMessage, "inputFeild2", "inputFeild2Error2")}
                                                placeholder={this.transSeller(configJSON.PlaceholderEmail)}
                                            />
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.Phone)}</Typography>
                                            <Box style={{
                                                display: "flex",
                                                gap: 20
                                            }}>
                                                <CustomDropDown className={checkCondition(this.state.errorsMessage.errorPhoneNumber===true, "isActive", "") as string}>
                                                <CountrySelect
                                                    id="phoneCode"
                                                    name="phoneCode"
                                                    value={this.state.selectedCountry.country_code}
                                                    placeholder="Select Phone Code"
                                                    onChange={this.handleSelectCountryCode}
                                                    countryCode={this.state.contryCodeResp}
                                                />
                                                </CustomDropDown>

                                                <MobileTextField
                                                    data-test-id="phoneNumberTextInput"
                                                    variant="outlined"
                                                    onChange={(event) => this.handleNumber(event.target.value)}
                                                    value={this.state.phoneNumber}
                                                    helperText={this.helperTextPhoneNumber()}
                                                    error={true}
                                                    className={this.generateClassName(this.state.errorsMessage.phoneNumberMessage, "inputFeild2", "inputFeild2Error2")}
                                                    style={{marginLeft: 0}}
                                                    placeholder={this.transSeller(configJSON.PlaceholderPhone)}
                                                    inputProps={{ maxLength: 12 }}
                                                    InputProps={{
                                                        style: {
                                                            gap: "4px",
                                                            border: "none",
                                                            borderRadius: "0px",
                                                        },
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box style={webStyle.savebuttn} data-test-id="handleUserProfile" onClick={this.handleUserProfile}>
                                            <Typography style={webStyle.saveText2}>{this.transSeller(configJSON.SaveChangeMsg)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box className="leftContainer">
                                        <Box>
                                            <Typography className="user-title">{this.transSeller(configJSON.ChangePasswordMSG)}</Typography>
                                        </Box>

                                        <Box><Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.Password)}</Typography>
                                            <Box style={{ display: "flex", alignItems: "center", position: "relative" }}>
                                                <TextField
                                                    data-test-id="oldPassword"
                                                    fullWidth
                                                    type={this.handleView(this.state.oldPasswordIcon, "text", "password")}
                                                    value={this.state.oldPassword}
                                                    variant="outlined"
                                                    helperText={this.helperTextOldPassword()}
                                                    error={true}
                                                    className={this.generateClassName(this.state.errorsMessage.oldPasswordMessage, "inputFeild2", "inputFeild2Error2")}
                                                    onChange={(event) => this.handleOldPassword(event.target.value)}
                                                    placeholder={this.transSeller(configJSON.PlaceholderPasss)}
                                                />
                                                <Box style={{
                                                    position: "absolute",
                                                    top: "14px",
                                                    [checkCondition(i18n.dir() === 'rtl', 'left', 'right') as leftRight]: "10px",
                                                    cursor: "pointer",
                                                }} onClick={() => {
                                                    this.setState({ oldPasswordIcon: !this.state.oldPasswordIcon })
                                                }}
                                                    data-test-id="oldIconChange"
                                                >
                                                    {this.renderShowHidePwd(this.state.oldPasswordIcon)}
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}>{this.transSeller(configJSON.NewPass)}</Typography>
                                            <Box style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative"
                                            }}>
                                                <TextField
                                                    data-test-id="newPassword"
                                                    fullWidth
                                                    type={this.handleView(this.state.newPasswordIcon, "text", "password")}
                                                    variant="outlined"
                                                    value={this.state.newPassword}
                                                    helperText={this.state.errorsMessage.newPasswordMessage ? this.state.errorsMessage.newPasswordMessage : ""}
                                                    error={true}
                                                    className={this.generateClassName(this.state.errorsMessage.newPasswordMessage, "inputFeild2", "inputFeild2Error2")}
                                                    onChange={(event) => this.handleNewPassword(event.target.value)}
                                                    placeholder={this.transSeller(configJSON.PlaceholderNewPass)}
                                                />
                                                <Box style={{
                                                    position: "absolute",
                                                    [checkCondition(i18n.dir() === 'rtl', 'left', 'right') as leftRight]: "10px",
                                                    cursor: "pointer",
                                                    top: "14px"
                                                }}
                                                    onClick={() => {
                                                        this.setState({
                                                            newPasswordIcon: !this.state.newPasswordIcon
                                                        })
                                                    }} data-test-id="newPasswordViewClick" >
                                                        {this.renderShowHidePwd(this.state.newPasswordIcon)}
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box>
                                            <Typography style={webStyle.inputHeadingBox1}> {this.transSeller(configJSON.ReNewPass)} </Typography>
                                            <Box style={{
                                                display: "flex",
                                                alignItems: "center", position: "relative"
                                            }}>
                                                <TextField
                                                    data-test-id="reNewPassword"
                                                    fullWidth
                                                    type={this.state.ReNewPasswordIcon ? "text" : "password"}
                                                    variant="outlined"
                                                    value={this.state.rePassword}
                                                    onChange={(event) => this.handleRePassword(event.target.value)}
                                                    helperText={this.state.errorsMessage.rePasswordMessage ? this.state.errorsMessage.rePasswordMessage : ""}
                                                    error={true}
                                                    placeholder={this.transSeller(configJSON.PlaceholderReNewPass)}
                                                    className={this.generateClassName(this.state.errorsMessage.rePasswordMessage, "inputFeild2", "inputFeild2Error2")}
                                                />
                                                <Box style={{
                                                    position: "absolute", [checkCondition(i18n.dir() === 'rtl', 'left', 'right') as leftRight]: "10px", cursor: "pointer", top: "14px"
                                                }}
                                                    onClick={() => this.setState({
                                                        ReNewPasswordIcon: !this.state.ReNewPasswordIcon
                                                    })} data-test-id="rePasswordView"> {this.renderShowHidePwd(this.state.ReNewPasswordIcon)}
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box style={webStyle.changebuttn} data-test-id="changePasswordApi"
                                            onClick={this.handleChangePassword}
                                        >
                                            <Typography style={webStyle.saveText2}>   {this.transSeller(configJSON.ChangePasswordMSG)}    </Typography>
                                        </Box>
                                    </Box>
                                </Seccontainer>
                            </Box>
                        </StoreProfile>
                    </Grid>
                </Grid>
                <StyledContentMain
                    open={this.state.uploadDialogOpen}
                    onClose={this.handleCloseUploadDialog}
                    data-test-id="handleCloseDeleteDialog"
                    dir={i18n.dir()}
                >
                    <DiologcontentStyled>
                        <Box dir={i18n.dir()} style={{ width: "440px" }}>
                            <Typography style={webStyle.editProfileText}>
                                {this.transSeller(configJSON.EditProfilePicMsg)}    </Typography>
                        </Box>
                        {this.state.profileImage === "" ? <Box style={webStyle.imageUploadBox}>
                            {this.state.imageUpload ? <img style={webStyle.uploadImage} src={this.state.imageUrl} alt='upload ui' /> : <img src={i18n.dir() === 'rtl' ? uploadImgAr : upload} alt='upload ui' data-test-id="handleUpload" onClick={this.handleUpload} />}
                            <input type="file" accept="image/*" data-test-id="dialogUpload" onChange={(event) => this.handleFileUpload(event.target.files)} ref={this.state.inputRef} style={{
                                display: "none"
                            }} /> </Box> : <Box
                            className="image-preview-box"
                            style={{ height: "184px", width: "440px", position: "relative", marginTop: "16px" }}
                          >
                            <img
                              src={this.state.profileImage}
                              alt="Uploaded Profile"
                              className="image-preview"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "fill",
                              }}
                            />
                            <Button
                              variant="contained"
                              component="label"
                              className="edit-btn"
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "0px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              <img src={editFillUploadIcon} alt="edit" />
                              <input
                                type="file"
                                data-test-id="upload-image-test"
                                hidden
                                onChange={(event) => this.handleFileUpload(event.target.files)} ref={this.state.inputRef}
                                accept="image/*"
                              />
                            </Button>
                          </Box> }
                    </DiologcontentStyled><DialogActions style={{ marginTop: "24px", justifyContent: "center" }}>
                        <Button data-test-id="handleConfirmUpload" style={webStyle.uploadButton} onClick={this.handleProfilePicUpload}>
                            {this.transSeller(configJSON.uploadImagePicMsg)}</Button>
                    </DialogActions></StyledContentMain>

                <StyledModalFig
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.emailAndOtpVerify}
                    onClose={this.handleEmailAndOtpVerifyClose}
                    closeAfterTransition
                    data-test-id="transition"
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.emailAndOtpVerify} style={webStyle.modalImage}>
                        <MainContainer  >
                            <Box className={"formBox"}>
                                <Box data-test-id="formSubmit" style={{ width: "100%" }}>
                                    <Box className={"headBox"}>
                                        <Typography dir={i18n.dir() === 'rtl' ? 'ltr' : 'rtl'} className={"headText"}>
                                            {this.transSeller(configJSON.otpVerification)}</Typography>
                                    </Box>
                                    <Typography dir={i18n.dir() === 'rtl' ? 'ltr' : 'rtl'} data-test-id="maskEmail" className={"headSubText"}>
                                        {this.handleOption() ?
                                            <>
                                                {this.transSeller(configJSON.sentTo)} <br />{this.transSeller(configJSON.emailText)}{this.maskEmail()}
                                            </> :
                                            <>
                                                {this.transSeller(configJSON.sentTo)} <br />
                                                {this.transSeller(configJSON.smsText)}
                                                {this.maskPhone()}
                                            </>
                                        }
                                    </Typography>
                                    <Box dir={i18n.dir()} className="otpBox">
                                        <OtpMainContainer>
                                            <OtpInput
                                                data-test-id="otpInputID"
                                                onChange={this.handleOTPChange}
                                                value={this.state.otpInput}
                                                numInputs={4}
                                                containerStyle={{ gap: "15px" }}
                                                inputStyle={webStyle.inputStyles}
                                                renderInput={(props) => (
                                                    <input
                                                        {...props}
                                                        type="text"
                                                        onKeyDown={(event) => { this.handlekeyDownHan(event) }}
                                                    />
                                                )}
                                            />
                                            {this.state.errorOtpMsg && (
                                                <Box className={"otpMsgBox"}>
                                                    {this.state.errorOtpMsg}
                                                </Box>
                                            )}
                                        </OtpMainContainer>
                                    </Box>
                                    <Box dir={i18n.dir()} className={"textHeading"}>
                                        <Typography className={"bottomText"} align="center">
                                            <Typography dir={i18n.dir()} className={"reciveText"}>{this.transSeller(configJSON.reciveCodeText)} </Typography>
                                            <Typography data-test-id="resend-otp"
                                                onClick={this.handeResentOTP}
                                            >
                                                &nbsp;{this.transSeller(configJSON.resendCode)}
                                            </Typography>
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            className={"LoginUserButton"}
                                            disableElevation
                                            data-test-id="handleSaveSubmit"
                                            onClick={() => this.handleSubmit()}
                                        >
                                            {this.transSeller(configJSON.verifyAccount)}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </MainContainer>

                    </Fade>
                </StyledModalFig>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

const MainContainer = styled(Box)({
    "& .formBox": {
        padding: "20px", alignItems: "center", justifyContent: "center",
        display: "flex",
        gap: 20, flexDirection: "column",
    },
    "& .LoginUserButton": {
        color: "white ", backgroundColor: "var(--Primary-Purple-500, #CCBEB1) ",
        gap: "8px",
        width: "100% ", borderRadius: "0px",
        height: "56px ", textTransform: 'none',
        fontSize: "19px",
        fontWeight: 600, margin: "15px 0px 0px 0px"
    },
    "& .reciveText": {
        color: "var(--Primary-Purple-500, #CCBEB1)"
    },
    "& .textHeading": {
        textAlign: "center"
    },
    "& .bottomText": {
        display: "flex", color: "#375280 ",
        cursor: "pointer", fontFamily: "Lato,sans-serif", fontSize: "16px", fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px",
    },
    "& .otpMsgBox": {
        color: "red", margin: "11px 12% 0px 0px",
        fontWeight: 300, fontFamily: "Avenir, sans-serif",
        width: "100%",
    },
    "& .headBox": {
        textAlign: "center",
        width: "100%",
    },
    "& .headText": {
        textOverflow: "ellipsis", src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        fontFamily: "Avenir,sans-serif",
        lineHeight: "26px", alignSelf: "center",
        fontSize: "22px",
        fontStyle: "normal", fontWeight: 800,
    },
    "& .otpBox": {
        alignItems: "center",
        padding: "10px 0px",
        display: "flex",
        justifyContent: "center",
        gap: 15,
    },
    "& .headSubText": {
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir,sans-serif", fontSize: "16px",
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))", textOverflow: "ellipsis",
        lineHeight: "26px",
        fontStyle: "normal", fontWeight: 700,
        alignSelf: "center",
    },

});

const StyledModalFig = styled(Modal)(({ theme }) => ({
    display: 'flex', alignItems: 'center',
    backgroundColor: "none", justifyContent: 'center',
}));

const OtpMainContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 5px 10px",
});

const StyledContentMain = styled(Dialog)({
    '& .MuiDialog-paperWidthSm': {
        minWidth: '520px',
        minHeight: "392px"
    },
    '& .MuiPaper-rounded': {
        borderRadius: '12px',

    }
});

const DiologcontentStyled = styled(DialogContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: "24px",
    flex: "none",
    padding: "0px",
    justifyContent: "center",
    alignItems: "center",
    '&.MuiDialogContent-root:first-child': {
        paddingTop: '30px',
    },
});

const SelectedCountryBox = styled(Box)({
    display: "flex",
    alignItems: "center"
});

const DropDownOption = styled(Box)({
    width: "100%", left: 0,
    border: "1px solid #ccc",
    borderRadius: "0 0 4px 4px",
    borderTop: "none", position: "absolute",
    backgroundColor: "#fff", zIndex: 1,
    height: "60vh", overflow: "auto"
});

const OptionsValue = styled(Box)({
    alignItems: "center", justifyContent: "center",
    padding: "10px", display: "flex",
    "&:hover": {
        backgroundColor: "#F0F0F0"
    },
})

const CustomDropDown = styled(Box)({
    position: "relative",
    width: "180px",
    height: 56,
    cursor: "pointer"
});

const DropDownHeader = styled(Box)({
    padding: "10px",
    gap: "15px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    background: "#F0F0F0",
    height: "36px",
    color: "#375280",
    fontSize: "18px",
    "&.isActive": {
        border: "1px solid red",
    },
    "&:hover": {
        backgroundColor: "#F0F0F0"
    }
});

const Seccontainer = styled(Box)({
    display: "flex",
    gap: "35px",
    "@media (min-width: 610px) and (max-width: 1280px)": {
        flexWrap: 'wrap'
    },

    "@media (min-width: 360px) and (max-width: 610px)": {
        flexWrap: 'wrap',
        overflowY: "scroll"
    },
});

const MobileTextField = styled(TextField)({
    width: "100%",
    marginLeft: "20px",
    gap: "4px",
    border: "none",
    borderRadius: "none",
    "& .MuiOutlinedInput-root": {
        border: "none"
    }
});

const webStyle = {
    imageSrc: {
        width: "80px",
        height: "80px",
        borderRadius: "50%"
    },
    modalImage: { borderRadius: '10px', maxWidth: "390px", backgroundColor: "white" },
    editProfileText: {
        width: "300px",
        fontSize: '18px',
        color: "#375280",
        fontWeight: 600,
        fontFamily: "Lato"
    }, uploadImage: {
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    inputStyles: {
        width: "50px",
        height: "50px",
        border: "none",
        background: "#F0F0F0",
        margin: "0px 3px",
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)",
        fontSize: "25px",
        textAlign: "center",
        outline: "none"
    } as React.CSSProperties,
    imageUploadBox: { display: "flex", justifyContent: "center", alignItems: "center", height: "184px", width: "440px", backgroundColor: "#F8F8F8" } as React.CSSProperties,
    uploadButton: {
        alignItems: "center",
        fontSize: "15px",
        justifyContent: "center",
        fontWeight: 500,
        display: "flex",
        color: "white",
        backgroundColor: "#CCBEB1",
        border: "1px solid #CCBEB1",
        height: "56px",
        borderRadius: "5px",
        width: "90%",
        padding: "10px",
        cursor: "pointer",
        textTransform: "none" as "none"
    },
    profileData: { display: "flex", flexDirection: "column", gap: "20px" } as React.CSSProperties,
    changebuttn: {
        width: "200px",
        height: "56px",
        borderRadius: "3px",
        backgroundColor: "rgb(204, 190, 177)",
        display: "flex",
        marginTop: "1rem",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        cursor: "pointer",
    },
    iconstyle: {
        color: "#000000",
        opacity: "0.5",
    },
    saveText2: {
        fontFamily: 'Lato',
        fontSize: "20px",
        fontWeight: 800,
    },
    savebuttn: {
        width: "200px",
        height: "56px",
        borderRadius: "3px",
        backgroundColor: "rgb(204, 190, 177)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        cursor: "pointer",

    },
    inputHeadingBox1: {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px 0",
        textOverflow: "ellipsis",
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
};

const StoreProfile = styled(Box)({
    minHeight: "calc(100vh - 85px)",
    padding: "30px 60px",

    "& .inputFeild2": {
        "& .MuiTextField-root": {
            "&:hover": {
                border: "1px solid #F0F0F0 !important",
            },
        },
        "& .MuiOutlinedInput-root:hover": {
            border: "none !important",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }, "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "2px",
            backgroundColor: "#F0F0F0"
        },
        "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        }
    },
    "& .inputFeild2Error2": {
        "& .MuiOutlinedInput-root": {
            border: "none",
            borderRadius: "2px",
            backgroundColor: "#F0F0F0",
        }, "& .MuiFormHelperText-contained": {
            margin: "0 !important"
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .Mui-error":{
            display: "flex"
        }
    },
    "& .store-title": {
        fontFamily: "Lato",
        fontSize: "24px",
        fontWeight: 700,
        color: "#375280",
        marginBottom: "12px"
    },
    "& .user-title": {
        fontFamily: "Lato",
        fontSize: "22px",
        fontWeight: 700,
        color: "#375280"
    },
    "& .profileContainer": {
        height: '80px', display: "flex", gap: "15px", margin: "20px 0px",
        "& .profileName": {
            display: "flex",
            flexDirection: "column",
            gap: "6px"
        },
    },
    "& .profile-title": {
        fontFamily: "Lato",
        fontSize: "20px",
        fontWeight: 700,
        color: "#375280",
        textTransform: "capitalize"
    },
    "& .profileText": {
        backgroundColor: "#E2E8F0",
        textTransform: "capitalize",
        fontFamily: "Lato",
        fontSize: "14px",
        width: "max-content"
    },
    "& .leftContainer": {
        width: "535px",
        minHeight: "497px",
        border: "1px solid #E2E8F0",
        padding: "15px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "22px"
    }
});
// Customizable Area End
