import React from "react";
// Customizable Area Start
import { Grid, Typography, TextField, Box } from "@material-ui/core";

import { Vector, scroll } from "./assets";
import Switch from "@material-ui/core/Switch";
import Header from "../../../components/src/Header";
import Sidebar from "../../../components/src/Sidebar";
import Footer from "../../../components/src/Footer";
import { styled, withStyles } from "@material-ui/core/styles";
import { button_, location } from "../src/assets";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { GoogleMap, Marker } from "@react-google-maps/api";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
// Customizable Area End
import EditAddressController, {
  Props,
  CountrySuccess
} from "./EditAddressController.web";
import { CustomTypography } from "./EditAddress.web";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"

export default class SuccessAddress extends EditAddressController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.handleToggle = this.handleToggle.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);

    // Customizable Area End
  }

  // Customizable Area Start


  renderLocationButton() {
    return (
      <div
        className="address_child"
        style={{
          backgroundColor: "#CCBEB1",
          padding: "19px 35px",
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "22px",
          borderRadius: "2px",
          width: 184,
          marginLeft: "10px",
          flexDirection: i18n.dir() === "ltr" ? "row" : "row-reverse",
          justifyContent: "center",
        }}
        onClick={this.handleUseCurrentLocation}
      >
        <img src={location} alt="location icon" />
        <CustomTypography>{this.tranSuccessAddress("Use my current location")}</CustomTypography>
      </div>
    );
  }

  renderSearchInput() {
    return (
      this.state.mapsLoaded && (
        <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
          <input
            id="autocomplete"
            type="text"
            placeholder={this.tranSuccessAddress("Search for a place")}
            style={{
              width: "254px",
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: "white",
              boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
              display: this.state.mapVisible ? "block" : "none",
            }}
          />
        </div>
      )
    );
  }
  renderGoogleMap() {
    return (
      this.state.mapsLoaded &&
      this.state.mapVisible &&
      this.state.currentLocation && (
        <GoogleMap
          zoom={14}
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={this.state.currentLocation} // Center map on currentLocation
        >
          {this.state.selectedLocation && (
            <Marker position={this.state.selectedLocation} /> // Place marker on selected location
          )}
        </GoogleMap>
      )
    );
  }


  handleUseCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.setState({
            currentLocation: currentLatLng,
            selectedLocation: currentLatLng,
            mapVisible: true,
            formData: {
              ...this.state.formData,
              latitude: currentLatLng.lat,
              longitude: currentLatLng.lng,
            },
          });
        },
        (error) => {
          alert("Unable to retrieve your location. Please enable location services.");
        }
      );
    }
  };


  handleADDAddressImgCSS = () => {
    return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
  }

  navHeaderADDAddressMargin = () => {
    return i18n.dir() === "ltr" ? "27px 0px 20px 40px" : "27px 40px 20px 0px"
  }

  nameADDAddressCSS = () => {
    return i18n.dir() === "ltr" ? "31px 0px 20px 40px" : "31px 40px 20px 0px"
  }

  welcomeADDAddressCSS = () => {
    return i18n.dir() === "ltr" ? "0px 0px 37px 40px" : "0px 40px 37px 0px"
  }

  get webStyle1() {
    return {
      button12: {
        padding: "10px 16px 10px 16px",
        width: window.innerWidth > 768 ? "50%" : "35%",
        height: "64px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: 700,
        color: "#375280",
        border: "1px solid #CCBEB1",
        borderRadius: "2px",
        cursor: "pointer"
      },
    };
  }
  NavigationHeader = () => {
    return (
      <div id="nav-header-container">
        <div style={{ ...webStyle.innerContainer, margin: this.navHeaderADDAddressMargin() }} id="nav-header-inner">
          <Typography style={webStyle.innerContainer13} id="nav-home-text">{this.tranSuccessAddress("Home")}</Typography>
          <img src={Vector} style={{ transform: this.handleADDAddressImgCSS() }} id="nav-arrow-icon"/>
          <Typography style={webStyle.innerContainer23} id="nav-address-text">
            {this.tranSuccessAddress("Address")}
          </Typography>
        </div>
      </div>
    )
  }

  DrawerToggle = () => (
    <div id="drawer-toggle-container" style={{ marginLeft: window.innerWidth >= 768 ? "66%" : "42%", marginTop: window.innerWidth >= 768 ? "5%" : "7%" }}>
      <MoreVertIcon
        id="drawer-toggle-icon"
        data-test-id="drawerIcon"
        onClick={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
      />
    </div>
  );

  DrawerContent = () => (
    <BuyerDrawer
      navigation={this.props.navigation}
      open={this.state.isDrawerOpen}
      data-test-id="toggleDrawer"
      onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
    >
      <div id="drawer-name-container">
        <Typography style={{ ...webStyle.innerContainer33, margin: this.nameADDAddressCSS() }} id="drawer-name-text">
          <img src={scroll} id="drawer-scroll-icon"/>
          {this.state.fullName}
        </Typography>
      </div>
      <Typography style={{ ...webStyle.innerContainer43, margin: this.welcomeADDAddressCSS() }} id="drawer-welcome-text">
        {this.tranSuccessAddress("Welcome to your Account")}
      </Typography>
      <Sidebar id="drawer-sidebar-nav" navigation={this.props.navigation} />
    </BuyerDrawer>
  )

  MobileView = () => {
    if (window.innerWidth >= 951) return null;
    return (
      <>
        <Grid item style={{ display: "flex", width: "90vw" }} id="mobile-view-grid">
          {this.NavigationHeader()}
          {this.DrawerToggle()}
          {this.DrawerContent()}
        </Grid>
        <Grid item xs={12} md={6} style={{ margin: "0px auto" }} id="form-container-grid">
          <div
            style={{
              fontSize: "20px",
              fontWeight: 800,
              lineHeight: "33.5px",
              marginBottom: "29px",
              color: "#375280",
              display: "flex",
              alignItems: "center",
              gap: 8,
              marginTop: "93px",
              fontFamily: 'Lato , sans-serif',
            }}
            id="header-container"
          >
            <img
              src={button_}
              onClick={this.getNavigationAddAddress}
              style={{ cursor: "pointer", transform: this.handleADDAddressImgCSS() }}
              id="back-button-icon"
            />
            <span id="header-title">{this.tranSuccessAddress("Add Address")}</span>
          </div>
          <div style={{ backgroundColor: "#F8F8F8" }} id="form-background">
            <div id="form-wrapper">
              <div style={webStyle.addAddressContainer as React.CSSProperties} id="form-inner-container">
                <div
                  className="address_child"
                  style={{
                    fontSize: "20px",
                    color: "#375280",
                    marginBottom: "22px",
                    marginLeft: "10px",
                    fontFamily: 'Lato , sans-serif',
                  }}
                  id="form-section-title"
                >
                  {this.tranSuccessAddress("Add Address")}
                </div>

                <div style={{ position: "relative" }} id="map-container">
                  {this.renderLocationButton()}
                  {this.renderSearchInput()}
                  {this.renderGoogleMap()}
                </div>
                <Grid container spacing={2} style={{ width: "90%", margin: 0 }} id="form-fields-grid">
                 
                  <Grid item xs={12} sm={6} id="fullname-field-container">
                    <p id="fullname-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Full Name")}
                      <span id="fullname-required-mark">*</span>
                    </p>
                    <InputField
                      id="fullname-input"
                      data-test-id="fullNameField"
                      fullWidth
                      name="name"
                      placeholder={this.tranSuccessAddress("Enter Full Name")}
                      variant="outlined"
                      value={this.state.formData.name}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.name && <span id="fullname-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.name}</span>}
                  </Grid>

                 
                  <Grid item xs={12} sm={6} id="phone-field-container">
                    <MainGridContainer container id="phone-inner-container">
                      <Grid item style={{ width: "100%" }} id="phone-field-wrapper">
                        <Box id="phone-box">
                          <Typography id="phone-label" className={"inputHeading"}>
                            {this.tranSuccessAddress("Phone Number")}
                            <span id="phone-required-mark">*</span>
                          </Typography>
                          <Box className={"phoneInput"} style={{ width: "100%" }} id="phone-input-container">
                            <CustomDropDownBox id="country-code-wrapper">
                              <CountrySelect
                                id="phoneCode"
                                name="phoneCode"
                                value={this.state.selectedCountry?.country_code || "KW"}
                                placeholder={this.tranSuccessAddress("Select Phone Code")}
                                onChange={this.handleSuccessAddressPhoneCodeChange}
                                countryCode={this.state.countrySuccess}
                                isBorder
                              />
                            </CustomDropDownBox>
                            <TextField
                              id="phone-number-input"
                              data-test-id="phoneNumberField"
                              variant="outlined"
                              className="inputFeild2"
                              style={{ width: "100%" }}
                              placeholder={this.tranSuccessAddress("Enter Contact Number")}
                              inputProps={{ maxLength: 12 }}
                              name="phone_number"
                              value={this.state.formData.phone_number}
                              onChange={this.handleInputChange}
                              InputProps={{
                                style: {
                                  gap: "4px",
                                  border: "0px",
                                  borderRadius: "8px",
                                  width: "100%"
                                }
                              }}
                            />
                          </Box>
                          {this.state.errors.phone_number && <span id="phone-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.phone_number}</span>}
                        </Box>
                      </Grid>
                    </MainGridContainer>
                  </Grid>

                 
                  <Grid item xs={12} sm={6} id="street-field-container">
                    <p id="street-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Street Name")}
                      <span id="street-required-mark">*</span>
                    </p>
                    <InputField
                      id="street-input"
                      data-test-id="streetField"
                      fullWidth
                      placeholder={this.tranSuccessAddress("Enter Street Name")}
                      variant="outlined"
                      name="street"
                      value={this.state.formData.street}
                      onClick={this.handleUseCurrentLocation}
                    />
                    {this.state.errors.street && <span id="street-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.street}</span>}
                  </Grid>

                  
                  <Grid item xs={12} sm={6} id="building-field-container">
                    <p id="building-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("House / Building Number")}
                      <span id="building-required-mark">*</span>
                    </p>
                    <InputField
                      id="building-input"
                      data-test-id="buildingField"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter House / Building Number")}
                      name="house_or_building_number"
                      value={this.state.formData.house_or_building_number}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.house_or_building_number && <span id="building-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.house_or_building_number}</span>}
                  </Grid>

                 
                  <Grid item xs={12} sm={6} id="block-field-container">
                    <p id="block-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Block")}
                      <span id="block-required-mark">*</span>
                    </p>
                    <InputField
                      id="block-input"
                      data-test-id="blockField"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter Block")}
                      name="block"
                      value={this.state.formData.block}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.block && <span id="block-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.block}</span>}
                  </Grid>

                  
                  <Grid item xs={12} sm={6} id="area-field-container">
                    <p id="area-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Area")}
                      <span id="area-required-mark">*</span>
                    </p>
                    <InputField
                      id="area-input"
                      data-test-id="areaField"
                      fullWidth
                      variant="outlined"
                      name="area"
                      placeholder={this.tranSuccessAddress("Enter Area")}
                      value={this.state.formData.area}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.area && <span id="area-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.area}</span>}
                  </Grid>

                  
                  <Grid item xs={12} sm={6} id="city-field-container">
                    <p id="city-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("City")}
                      <span id="city-required-mark">*</span>
                    </p>
                    <InputField
                      id="city-input"
                      data-test-id="cityField"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter City")}
                      name="city"
                      value={this.state.formData.city}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.city && <span id="city-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.city}</span>}
                  </Grid>

                  
                  <Grid item xs={12} sm={6} id="zipcode-field-container">
                    <p id="zipcode-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Zip Code")}
                      <span id="zipcode-required-mark">*</span>
                    </p>
                    <InputField
                      id="zipcode-input"
                      data-test-id="zipcodeField"
                      fullWidth
                      variant="outlined"
                      name="zipcode"
                      value={this.state.formData.zipcode}
                      placeholder={this.tranSuccessAddress("Enter Zip Code")}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.zipcode && <span id="zipcode-error-text" style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }}>{this.state.errors.zipcode}</span>}
                  </Grid>
                 
                  <Grid item xs={12} sm={6} id="address-name-field-container">
                    <p id="address-name-label" style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "24px",
                      color: "#375280",
                      fontFamily: "Lato , sans-serif",
                    }}>
                      {this.tranSuccessAddress("Address Name")}
                      <span id="address-name-required-mark">*</span>
                    </p>
                    <InputField
                      id="address-name-input"
                      data-test-id="addressNameField"
                      fullWidth
                      variant="outlined"
                      name="address_name"
                      value={this.state.formData.address_name}
                      placeholder={this.tranSuccessAddress("Enter Address")}
                      onChange={this.handleInputChange}
                    />
                    {this.state.errors.address_name && <span id="address-name-error-text" style={{ color: '#f44336', fontSize: "16px", fontFamily: "Lato , sans-serif", fontWeight: 100 }}>{this.state.errors.address_name}</span>}
                  </Grid>
                </Grid>
                <div
                  id="default-address-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#375280",
                    fontSize: "16px",
                    fontWeight: 500
                  }}
                >
                  <GreenSwitch
                    id="default-address-toggle"
                    checked={this.state.isChecked}
                    onChange={this.handleToggle}
                    inputProps={{ "aria-label": "toggle switch" }}
                  />
                  <p id="default-address-text">{this.tranSuccessAddress("Make it default delivery address")}</p>
                </div>
                <div
                  id="form-actions-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 22,
                    paddingInline: 10
                  }}
                >
                  <div 
                    id="cancel-action-button" 
                    style={this.webStyle1.button12} 
                    onClick={this.getNavigationAddAddress}
                  >
                    {this.tranSuccessAddress("Cancel")}
                  </div>
                  <div
                    id="save-action-button"
                    style={{ ...this.webStyle1.button12, ...webStyle.saveButton }}
                    onClick={this.handleSubmit}
                  >
                    {this.tranSuccessAddress("Save")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </>
    )
  }
  DesktopView = () => {
    if (window.innerWidth <= 950) return null;
    return (
      <>
        <Grid item>
          <div style={{ ...webStyle.innerContainer, margin: this.navHeaderADDAddressMargin() }} id="inner-container">
            <Typography style={webStyle.innerContainer13} id="home-typography">{this.tranSuccessAddress("Home")}</Typography>
            <img src={Vector} style={{ transform: this.handleADDAddressImgCSS() }} id="vector-image" />
            <Typography style={webStyle.innerContainer23} id="address-typography">
              {this.tranSuccessAddress("Address")}
            </Typography>
          </div>
          <div id="full-name-container">
            <Typography style={{ ...webStyle.innerContainer33, margin: this.nameADDAddressCSS() }} id="full-name-typography">
              <img src={scroll} id="scroll-image" />
              {this.state.fullName}
            </Typography>
          </div>
          <Typography style={{ ...webStyle.innerContainer43, margin: this.welcomeADDAddressCSS() }} id="welcome-typography">
            {this.tranSuccessAddress("Welcome to your Account")}
          </Typography>
          <Sidebar navigation={this.props.navigation} id="sidebar" />
        </Grid>
        <Grid item xs={12} md={6} style={{ margin: "0px auto" }}>
          <div
            style={{
              fontSize: "20px",
              fontWeight: 800,
              lineHeight: "33.5px",
              marginBottom: "29px",
              color: "#375280",
              display: "flex",
              alignItems: "center",
              gap: 8,
              marginTop: "93px",
              fontFamily: 'Lato , sans-serif',
            }}
            
          >
            <img
              src={button_}
              onClick={this.getNavigationAddAddress}
              style={{ cursor: "pointer", transform: this.handleADDAddressImgCSS() }}
              
            />
            {this.tranSuccessAddress("Add Address")}
          </div>

          <div style={{ backgroundColor: "#F8F8F8" }}>
            <div>
              <div style={webStyle.addAddressContainer as React.CSSProperties}>
                <div
                  className="address_child"
                  style={{
                    fontSize: "20px",
                    color: "#375280",
                    marginBottom: "22px",
                    marginLeft: "10px",
                    fontFamily: 'Lato , sans-serif',
                  }}
                  
                >
                  {this.tranSuccessAddress("Add Address")}
                </div>
  
                <div style={{ position: "relative" }}>
                  {this.renderLocationButton()}
                  {this.renderSearchInput()}
                  {this.renderGoogleMap()}
                </div>
                <Grid container spacing={2} style={{ width: "100%", margin: 0 }} >
                  <Grid item xs={12} sm={6} >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="full-name-label"
                    >
                      {this.tranSuccessAddress("Full Name")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
  
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      name="name"
                      placeholder={this.tranSuccessAddress("Enter Full Name")}
                      variant="outlined"
                      value={this.state.formData.name}
                      onChange={this.handleInputChange}
                      id="full-name-input"
                    />
                    {this.state.errors.name && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="full-name-error">{this.state.errors.name}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MainGridContainer container>
                      <Grid item style={{ width: "100%" }}>
                        <Box>
                          <Typography
                            data-test-id="countryId"
                            className={"inputHeading"}
                            id="phone-number-label"
                          >
                            {this.tranSuccessAddress("Phone Number")}
                            <span style={{ color: '#375280' }}>*</span>
                          </Typography>
                          <Box className={"phoneInput"} style={{ width: "100%" }} id="phone-input-container">
                            <CustomDropDownBox id="country-select-container">
                              <CountrySelect
                                id="phoneCodeDesktop"
                                name="phoneCode"
                                value={this.state.selectedCountry?.country_code || "KW"}
                                placeholder={this.tranSuccessAddress("Select Phone Code")}
                                onChange={this.handleSuccessAddressPhoneCodeChange}
                                countryCode={this.state.countrySuccess}
                                isBorder
                              />
                            </CustomDropDownBox>
                            <TextField
                              data-test-id="phoneNumberTextFiled"
                              variant="outlined"
                              className="inputFeild2"
                              style={{ width: "100%" }}
                              placeholder={this.tranSuccessAddress("Enter Contact Number")}
                              inputProps={{ maxLength: 12 }}
                              name="phone_number"
                              value={this.state.formData.phone_number}
                              onChange={this.handleInputChange}
                              InputProps={{
                                style: {
                                  gap: "4px",
                                  border: "0px",
                                  borderRadius: "8px",
                                  width: "100%"
                                }
                              }}
                              id="phone-number-input"
                            />
                          </Box>
                          {this.state.errors.phone_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="phone-number-error">{this.state.errors.phone_number}</span>}
                        </Box>
                      </Grid>
                    </MainGridContainer>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="street-name-label"
                    >
                      {this.tranSuccessAddress("Street Name")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      placeholder={this.tranSuccessAddress("Enter Street Name")}
                      variant="outlined"
                      name="street"
                      value={this.state.formData.street}
                      onClick={this.handleUseCurrentLocation}
                      id="street-name-input"
                    />
                    {this.state.errors.street && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="street-name-error">{this.state.errors.street}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="house-number-label"
                    >
                      {this.tranSuccessAddress("House / Building Number")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter House / Building Number")}
                      name="house_or_building_number"
                      value={this.state.formData.house_or_building_number}
                      onChange={this.handleInputChange}
                      id="house-number-input"
                    />
                    {this.state.errors.house_or_building_number && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="house-number-error">{this.state.errors.house_or_building_number}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="block-label"
                    >
                      {this.tranSuccessAddress("Block")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter Block")}
                      name="block"
                      value={this.state.formData.block}
                      onChange={this.handleInputChange}
                      id="block-input"
                    />
                    {this.state.errors.block && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="block-error">{this.state.errors.block}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="area-label"
                    >
                      {this.tranSuccessAddress("Area")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      name="area"
                      placeholder={this.tranSuccessAddress("Enter Area")}
                      value={this.state.formData.area}
                      onChange={this.handleInputChange}
                      id="area-input"
                    />
                    {this.state.errors.area && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="area-error">{this.state.errors.area}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="city-label"
                    >
                      {this.tranSuccessAddress("City")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      placeholder={this.tranSuccessAddress("Enter City")}
                      name="city"
                      value={this.state.formData.city}
                      onChange={this.handleInputChange}
                     
                    />
                    {this.state.errors.city && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} >{this.state.errors.city}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="zipcode-label"
                    >
                      {this.tranSuccessAddress("Zip Code")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      name="zipcode"
                      value={this.state.formData.zipcode}
                      placeholder={this.tranSuccessAddress("Enter Zip Code")}
                      onChange={this.handleInputChange}
                      id="zipcode-input"
                    />
                    {this.state.errors.zipcode && <span style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato , sans-serif", }} id="zipcode-error">{this.state.errors.zipcode}</span>}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "24px",
                        color: "#375280",
                        fontFamily: "Lato , sans-serif",
                      }}
                      id="address-name-label"
                    >
                      {this.tranSuccessAddress("Address Name")}
                      <span style={{ color: '#375280' }}>*</span>
                    </p>
                    <InputField
                      data-test-id="memberId"
                      fullWidth
                      variant="outlined"
                      name="address_name"
                      value={this.state.formData.address_name}
                      placeholder={this.tranSuccessAddress("Enter Address")}
                      onChange={this.handleInputChange}
                      id="address-name-input"
                    />
                    {this.state.errors.address_name && <span style={{ color: '#f44336', fontSize: "16px", fontFamily: "Lato , sans-serif", fontWeight: 100 }} id="address-name-error">{this.state.errors.address_name}</span>}
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#375280",
                    fontSize: "16px",
                    fontWeight: 500
                  }}
                  
                >
                  <GreenSwitch
                    checked={this.state.isChecked}
                    onChange={this.handleToggle}
                    inputProps={{ "aria-label": "toggle switch" }}
                    
                  />
                  <p >{this.tranSuccessAddress("Make it default delivery address")}</p>
                </div>
  
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 22,
                    paddingInline: 10
                  }}
                  
                >
                  <div style={this.webStyle1.button12} onClick={this.getNavigationAddAddress} >{this.tranSuccessAddress("Cancel")}</div>
                  <div
                    style={{ ...this.webStyle1.button12, ...webStyle.saveButton }}
                    onClick={this.handleSubmit}
                  >
                    {this.tranSuccessAddress("Save")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </>
    )
  }
  render() {
    
   

    return (
      //Merge Engine DefaultContainer
      <>
        {this.state.exploreBuyer ? <StylishBuyerHeader navigation={this.props.navigation} /> : <Header navigation={this.props.navigation} />}
        <Grid
          style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}
          container
          dir={i18n.dir()}
        >
          
            {this.MobileView()}
            {this.DesktopView()}
          </Grid>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Footer navigation={this.props.navigation} />
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const GreenSwitch = withStyles({
  switchBase: {
    color: "#fff",
    "&$checked": {
      transform: "translateX(22px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#CCBEB1",
        opacity: 1,
        border: "none",
        height: 17
      }
    },
    "&$focusVisible $thumb": {
      color: "#4cd964",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 19,
    height: 21
  },
  track: {
    borderRadius: 16 / 2,
    backgroundColor: "#E5E5EA",
    border: "1px solid #E5E5EA",
    opacity: 1,
    transition: "background-color 0.3s"
  },
  checked: {},
  focusVisible: {}
})(Switch);
const webStyle = {
  addAddressContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    marginBottom: "24px",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    lineHeight: "26px",
    padding: "20px"
  },
 
  saveButton: {
    color: "white",
    backgroundColor: "#CCBEB1"
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "white",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "4px",
    zIndex: 1,
    width: "120px"
  },
  dropdownP: {
    borderBottom: "3px solid #D5D5D5",
    paddingBottom: "10px",
    paddingLeft: "6px",
    paddingRight: "6px",
    color: "#375280",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "22px"
  },
  dropdownP1: {
    paddingLeft: "6px",
    paddingRight: "6px",
    color: "#DC2626",
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "22px"
  },
  innerContainer: {
    alignItems: "center",
    color: "#475569",
    display: "flex",
    fontWeight: 400,
    marginLeft: "40px",
    marginTop: "27px",
    lineHeight: "22px",
    gap: "10px"
  },
  innerContainer13: {
    lineHeight: "22px",
    fontStyle: "normal",
    fontSize: "14px",
    fontFamily: "Lato , sans-serif",
    padding: "4px 6px 4px 6px"
  },
  innerContainer23: {
    fontStyle: "normal",
    padding: "4px 6px 4px 6px",
    color: "#475569",
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700
  },
  innerContainer33: {
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "33.5px",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    marginLeft: "40px",
    gap: "15px",
    marginTop: "31px",
    display: "flex",
    fontSize: "28px"
  },
  innerContainer43: {
    marginLeft: "40px",
    fontWeight: 400,
    fontFamily: "Lato , sans-serif",
    fontSize: "14px",
    marginBottom: "37px",
    fontStyle: "normal",
    color: "#807D7E",
    lineHeight: "33.5px"
  }
};
const InputField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#FFFFFF"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#FFFFFF"
    }
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#F0F0F0",
    height: "56px",
    margin: "auto",
    borderRadius: "8px",
    fontSize: "16px",
    "@media only screen and (max-width: 414px)": {
      fontSize: "14px"
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px"
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FFFFFF",
    borderWidth: "1px",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 16px 10px 16px",
    color: "#1E293B",
    "&::placeholder": {
      color: "#94A3B8",
      fontSize: "16px",
      "@media only screen and (max-width: 414px)": {
        fontSize: "14px"
      }
    },
    "&:not(:placeholder-shown)": {
      color: "#1E293B"
    }
  },
  "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1"
  }
});
const MainGridContainer = styled(Grid)({
  display: "flex",
  justifyContent: "center",

  "& .mainCotainer": {
    marginBottom: "40px",
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "flex-start"
  },

  "& .inputHeading": {
    fontFamily: "Lato, sans-serif",
    fontStyle: "normal",
    marginBottom: "10px",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#375280"
  },
  "& .inputFeild": {
    margin: "0px 10px 0px 10px !important"
  },
  "& .phoneInput": {
    display: "flex",
    gap: "15px",
    height: "56px",
    width: "100%px"
  },

  "& .forgotPassword": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px",
    textOverflow: "ellipsis",
    cursor: "pointer",
    fontFamily: "Lato, sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "26px"
  },

  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    }
  },
  "& .inputFeild2": {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    },
    "& .MuiInputBase-input": {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0"
    }
  },
  "@media screen and (min-width: 0px) and (max-width: 1100px)": {
    "& .phoneInput": { width: "100%", height: "56px" },
    "& .inputFeild2": {
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#F0F0F0",
        borderRadius: "2px"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      "& .MuiInputBase-input": {
        color: "#375280"
      },
      "& .MuiFormHelperText-contained": {
        margin: "0 !important"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#F0F0F0"
      },
      width: "300px"
    }
  }
});
const CustomDropDownBox = styled(Box)({
  position: "relative",
  cursor: "pointer",
  width: "210px"
});
const DropDownHeaderMenu = styled(Box)({
  padding: "10px",
  gap: "15px",
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  background: "#F0F0F0",
  height: "36px",
  color: "#375280",
  fontSize: "18px",
  "&.isActive": {
    border: "1px solid red"
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const SelectedCountryList = styled(Box)({
  display: "flex",
  alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
  left: 0,
  width: "100%",
  border: "1px solid #ccc",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 1,
  height: "60vh",
  overflowX: "hidden",
  color: "#375280",
});
const OptionsValueList = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});
// Customizable Area End
