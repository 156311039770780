import React from "react";
// Customizable Area Start
import { arrow, arrowselect, crossmini, upload, deleteBttn } from "./assets";
import {storeSuccessIcon} from "../../customform/src/assets";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, Checkbox, TableBody, TableHead, TableRow, Paper, TableCell, TableContainer, styled, TextField, MenuItem, Select, RadioGroup, FormControlLabel, Radio, FormControl, OutlinedInput, Table, Typography, Button,Dialog } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
import  { ChangeEvent } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AddProductCatalougeController, { Props } from "./AddProductCatalougeController";
import ArrowGender, { CircleDIvFIlledFunc, CircleDiv, FontListed, FontWight, HrTag, LinkStatusFirst } from "../../../components/src/ArrowGender.web";
import storage from '../../../framework/src/StorageProvider';
import i18n from "../../../components/src/i18next/i18n";
import { UploadBtnBox } from "./CatalogueWeb.web";
import { AddUploadBtnPart } from "../../../components/src/CustomCarousel.web";
import CommonBackdrop from "../../../components/src/BackDrop";
import { group_error } from "../../promocodes/src/assets";
import { checkCondition } from "../../../components/src/Seller/logOut";

// Customizable Area End


export default class AddProductCatalougeWeb extends AddProductCatalougeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryName = event.target.value as string;
    const selectedCategory = this.state.category.find(
      (category) => category.attributes.name === selectedCategoryName
    );
    if (selectedCategory && selectedCategory.id) {
      this.setState({categoryIdd : selectedCategory.attributes.id})
      this.setState({ clothValue: selectedCategoryName });
      this.setState((prevState) =>({ errors: {
        ...prevState.errors,
        clothValue: '',  // Clear error message
      }, }));
      this.sub_category(selectedCategory.attributes.id); 
    }
  };
  handleChange = async(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string; value: unknown }>) => {
    const target = event.target as HTMLInputElement;
    const name = target.name;
    const value = target.value;
    if (name) {
      this.setState({
        [name]: value,
        errors: { ...this.state.errors, [name]: '' }
      } as any);
    }

    if (name === 'productName') {
      await this.findNameOnchange(value);
    }

    if (name === 'arabicProductName') {
      await this.findArabicNameOnchange(value);
    }
  };


  handleSubCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCategoryName1 = event.target.value as string;
    const selectedCategory1 = this.state.category1.find(
      (category1) => category1.attributes.name === selectedCategoryName1
    );
    if (selectedCategory1 && selectedCategory1.id) {
      this.setState({categoryValueId: selectedCategory1.attributes.id})
      this.setState({ categoryValue: selectedCategoryName1 });
      this.setState((prevState) =>({ errors: {
        ...prevState.errors,
        subCategory:''
      }, }));
      this.subsubCategorey(selectedCategory1.attributes.id); 
    }
  };
  handleSubSubCategoryChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedSubSubCategoryName = event.target.value as any;


    const selectedSubSubCategory = this.state.subsubCategory.find(
      (subsubCategory) => subsubCategory.attributes.name === selectedSubSubCategoryName
    );
    if (selectedSubSubCategory && selectedSubSubCategory.id) {
      this.setState({subsubcategoryValueId: selectedSubSubCategory.attributes.id})
      this.setState({ categoryValue1: selectedSubSubCategoryName });
      this.setState((prevState) =>({ errors: {
        ...prevState.errors,
        categoryValue1: '',  
        subSubCategory:''
      }, }));
    }
  };

  handleSelectAllChanges = () => {
    this.setState(prevState => {
      const { selectAll, storeList } = prevState;
      const newSelectAll = !selectAll;

      
      const newSelectedStores = newSelectAll ? storeList.map((store:any) => store.id) : [];

      return {
        selectedStores: newSelectedStores,
        selectAll: newSelectAll,
      };
    });
  };
  handleCheckboxChange = (id: number) => {
    this.setState(prevState => {
      const { selectedStores } = prevState;
      const isSelected = selectedStores.includes(id);
      const newSelectedStores = isSelected
        ? selectedStores.filter((storeId:any) => storeId !== id)
        : [...selectedStores, id];

      return {
        selectedStores: newSelectedStores,
        selectAll: newSelectedStores.length === this.state.storeList.length,
      };
    });
  };
 
  handleStepperNumberCSS = () =>{
    return i18n.dir() === "ltr" ? "4px 0px 24px 11px" : "4px 11px 24px 0px"
  }

  handleStepperDetailCSS = () =>{
    return i18n.dir() === "ltr" ? "0px 0px 0px -38px" : "0px -25px 0px 0px"
  }

  handleStepperMainCSS = () => {
    return i18n.dir() === "ltr" ? "12px 8px 0px 34px" : "12px 34px 0px 8px"
  }

  handleBackCSS = () => {
    return i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)"
  }

  handleSizeChange = (event: any) => {
    const selectedSizes = event.target.value;
    const selectedSizeIds = selectedSizes.map((sizeName: any) => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: any; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter((id: any) => id !== null);

    this.setState({ personName: selectedSizes, selectedSizeIds });
  };

  // Method to remove a selected size
  removeSelectedSize = (sizeName: any) => {
    const updatedSizes = this.state.personName.filter(item => item !== sizeName);
    const updatedSizeIds = updatedSizes.map(sizeName => {
      const sizeObj = this.state.varient_size.find((size: { attributes: { name: string; }; }) => size.attributes.name === sizeName);
      return sizeObj ? sizeObj.id : null;
    }).filter(id => id !== null);

    this.setState({ personName: updatedSizes, selectedSizeIds: updatedSizeIds });
  };
  handleColorChange = (event:any) => {
    const selectedColors = event.target.value;
    const selectedColorIds = selectedColors.map((colorName: any) => {
      const colorObj = this.state.varient_color.find((colorr:any) => colorr.attributes.name === colorName);
      return colorObj ? colorObj.id : null;
    }).filter((id: any) => id !== null);
    
    storage.set('selectedColor', JSON.stringify(selectedColors));
    this.setState({ selectedColors });
    this.setState({ colorArry: selectedColors, selectedColorIds });
    const newGridImages = selectedColors.map((color:any) => {
        return {
          color: color,
          images: [
            { name: "Front View", imgUrlLink: "", active: false },
            { name: "Back View", imgUrlLink: "", active: false },
            { name: "Side View", imgUrlLink: "", active: false },
          ],
          selectedImage: "",
          activeGridImage: "",
        };
    });
  
    this.setState({ gridImages: newGridImages });

  };

  removeSelectedColor = (colorName:any) => {
    const updatedColors = this.state.colorArry.filter(item => item !== colorName);
    const updatedColorIds = updatedColors.map(colorName => {
      const colorObj = this.state.varient_color.find((colorr: any) => colorr.attributes.name === colorName);
      return colorObj ? colorObj.id : null;
    }).filter(id => id !== null);
    const newGridImages = updatedColors.map((color:any) => {
      return {
        color: color,
        images: [
          { name: "Front View", imgUrlLink: "", active: false },
          { name: "Back View", imgUrlLink: "", active: false },
          { name: "Side View", imgUrlLink: "", active: false },
        ],
        selectedImage: "",
        activeGridImage: "",
      };
  });

  this.setState({ gridImages: newGridImages });
    this.setState({ colorArry: updatedColors, selectedColorIds: updatedColorIds });
  };
 





handleCreateVariant = async () => {
  const { create_vari } = this.state;
  let skuErrorExists = false;
  let validationErrors: string[] = [];

  const validateAndSetErrors = async (variant: any, index: number): Promise<boolean> => {
    const errors = [];

   

    const skuExists = await checkSku(variant.sku, index);
    if (skuExists) {
      errors.push(this.transAddProductCatalogue("SKU already exists"));
    }

    if (errors.length > 0) {
      validationErrors = validationErrors.concat(errors);
      return true;
    }
    return false;
  };

  const checkSku = async (sku: string, index: number): Promise<boolean> => {
    if (!sku) return false;

    await this.skuOnchange(sku);
    const skuExists = this.state.sku_change.already_exists.includes(sku);
    create_vari[index].skuError = skuExists ? this.transAddProductCatalogue("SKU already exists") : "";
    return skuExists;
  };

  for (let index = 0; index < create_vari.length; index++) {
    const variant = create_vari[index];
    const hasErrors = await validateAndSetErrors(variant, index);
    if (hasErrors) {
      skuErrorExists = true;
    }
  }

  this.setState({ create_vari, validationErrors });

  if (!skuErrorExists && validationErrors.length === 0) {
    this.createVarient(this.state.selectedSizeIds, this.state.selectedColorIds);
    this.setState({ isVariantCreated: true });
  } else {
    console.error(this.transAddProductCatalogue("Some errors occurred. Please fix them before updating the variant."));
  }
  if (this.state.selectedColorIds.length === 0 || this.state.selectedSizeIds.length === 0) {
    let createVariantError = "Please select ";

    if (this.state.selectedColorIds.length === 0 && this.state.selectedSizeIds.length === 0) {
      createVariantError += "a color and a size";
    } else if (this.state.selectedColorIds.length === 0) {
      createVariantError += "a color";
    } else if (this.state.selectedSizeIds.length === 0) {
      createVariantError += "a size";
    }

    // Set the error message in the state and exit
    this.setState({ createVariantError });
    return;
  }

  // Clear the error message if both color and size are selected
  this.setState({ createVariantError: "" });
};

handleInputChange = (index: any, field: any, value: any) => {
  const updatedVariants = [...this.state.create_vari];
  updatedVariants[index][field] = value;

  if (field === 'stock_qty') {
    updatedVariants[index].quantityError = "";
    if (Number(value) <= 0) {
      updatedVariants[index].quantityError = this.transAddProductCatalogue("Quantity must be greater than 0");
    }
  }


  if (field === 'price')
   {
    updatedVariants[index].priceError = ""; 

    if (Number(value) <= 0) {

      updatedVariants[index].priceError = this.transAddProductCatalogue("Price should be greater than 0");
   
    }

  }
  if (field === 'sku') {
      updatedVariants[index].skuError = "";
      const isDuplicateSku1 = updatedVariants.some(
        (variant, idx) => idx !== index && variant.sku === value
      );
      if (isDuplicateSku1) {
        updatedVariants[index].skuError = this.transAddProductCatalogue("SKU is already taken");
      }
  }
 

  this.setState({ create_vari: updatedVariants });
};


renderTableRows = () => {
  const { create_vari } = this.state;

  return create_vari.map((variant: any, index: number) => (
    <TableRow key={index}>
      <TableCell style={webStyle.trHead} component="th" scope="row">
        {`${variant.variant_size || this.transAddProductCatalogue('N/A')}/${variant.variant_color || this.transAddProductCatalogue('N/A')}`}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.stock_qty || ''} 
          onChange={(e) => this.handleInputChange(index, 'stock_qty', e.target.value)}
          id={`quantity-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.quantityError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.quantityError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.price || ''} 
          onChange={(e) => this.handleInputChange(index, 'price', e.target.value)}
          id={`price-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.priceError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.priceError}</p>
        )}
      </TableCell>
      <TableCell style={webStyle.trHead} align="left">
        <CustomInputTable 
          value={variant.sku || ''} 
          onChange={(e) => this.handleInputChange(index, 'sku', e.target.value)}
          id={`sku-${index}`} 
          variant="standard"
          InputProps={{ disableUnderline: true, style: { textAlign: 'center' } }}
        />
        {variant.skuError && (
          <p style={{ color: 'red', marginTop: '5px' }}>{variant.skuError}</p>
        )}
      </TableCell>
    </TableRow>
  ));
};

genderArrow = (arrowHandle: boolean) => {
  return (ArrowGender(arrowHandle))
}

handleIconCSS = (arrowHandle: boolean) => {
  const positionValued = arrowHandle ? 10 : 0
  return i18n.dir()==='rtl' ? {left: positionValued, paddingLeft: 10} : {right: positionValued, paddingRight: 10,}
}

arrowIcon = (arrowHandle: boolean) => (
  <img
    src={arrowselect}
    alt="dropdown"
    style={{ position: "absolute",
      transition: "transform 0.3s ease",
      zIndex: 1,
      transform: ArrowGender(arrowHandle),
      pointerEvents: "none", ...this.handleIconCSS(arrowHandle)}}
    data-test-id="select-drop-down-img"
  />
);



fontListed = (data: boolean) => {
  return (FontListed(data))
}

renderError = (error: string, name: string) => (error && <div data-test-id={`error-${name}`} style={{ color: '#f44336',fontWeight:100,fontSize:"16px",fontFamily: "Lato , sans-serif" }}>{error}</div>)

firstPageRender = () => (<div style={{ border: "1px solid #E2E8F0", borderRadius: "4px", marginTop: '70px', padding: 15 }}>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Name")}*{"(English)"}</h2>
                  <CustomInput
                  data-test-id="handleChangeg"
                    id="outlined-basic"
                    dir={i18n.dir()}
                    placeholder={this.transAddProductCatalogue("Product Name")}
                    variant="standard"
                    name="productName"
                    value={this.state.productName}
                    onChange={this.handleChange}
                    InputProps={{ disableUnderline: true }}
                  />
                    {this.renderError(this.state.errors.productName, "product-name")}
                </div>
                <div style={{ width: '50%' }} data-test-id="product-arabic-name-container">
                  <h2 data-test-id="product-arabic-name-title" style={webStyle.labelH}>{this.transAddProductCatalogue("Product Name")} {"(عربي)"} *</h2>
                  <CustomInput
                  data-test-id="handleChangeg1"
                    id="outlined-basic"
                    onChange={this.handleChange}
                    dir={i18n.dir()}
                    placeholder={this.transAddProductCatalogue("Product Name")}
                    variant="standard"
                    name="arabicProductName"
                    value={this.state.arabicProductName}
                    InputProps={{ disableUnderline: true }}
                  />
                  {this.renderError(this.state.errors.arabicProductName, "arabic-product-name")}
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Brand")}*{"(English)"}</h2>
                  <CustomInput id="outlined-basic" placeholder={this.transAddProductCatalogue("Brand")} variant="standard"
                   name="brand"
                   dir={i18n.dir()}
                   value={this.state.brand}
                   onChange={this.handleChange}
                   InputProps={{ disableUnderline: true }}
                  />
                  {this.renderError(this.state.errors.brand, "brand-name")}
                </div>
                <div style={{ width: '50%' }} data-test-id="arabic-brand-container">
                  <h2 style={webStyle.labelH} data-test-id="arabic-brand-title">{this.transAddProductCatalogue("Select Brand")} {"(عربي)"} *</h2>
                  <CustomInput 
                   placeholder={this.transAddProductCatalogue("Brand")} 
                   variant="standard"
                   name="arabicBrand"
                   id="outlined-basic" 
                   dir={i18n.dir()}
                   value={this.state.arabicBrand}
                   onChange={this.handleChange}
                   InputProps={{ disableUnderline: true }}
                  />
                  {this.renderError(this.state.errors.arabicBrand, "arabic-brand-name")}
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
              <div style={{ width: '50%' }} data-test-id="gender-container">
                  <h2 style={webStyle.labelH} data-test-id="gender-title">{this.transAddProductCatalogue("Gender")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv} data-test-id="gender-form-container">
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.genderValue}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel"
                      onChange={(event) => this.setState({ genderValue: event.target.value as string })}
                      dir={i18n.dir()}
                      onOpen={() => this.setState({ genderSelect: true })}
                      onClose={() => this.setState({ genderSelect: false })}
                      IconComponent={()=>this.arrowIcon(this.state.genderSelect)}
                      MenuProps={this.MenuProps()}
                    >
                      <MenuItem style={webStyle.menuItem} value={"male"}>{this.transAddProductCatalogue("Male")}</MenuItem>
                      <MenuItem style={webStyle.menuItem} value={"female"}>{this.transAddProductCatalogue("Female")}</MenuItem>
                      <MenuItem style={webStyle.menuItem} value={"other"}>{this.transAddProductCatalogue("Other")}</MenuItem>
                    </SelectCustom>
                    {this.renderError(this.state.errors.genderValue, "gender")}
                  </FormControl>
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.clothValue}
                      dir={i18n.dir()}
                      label="Age"
                      data-test-id="genderSel2"
                      disableUnderline={true}
                      onChange={this.handleCategoryChange}
                      onOpen={() => this.setState({ clothSelect: true })}
                      onClose={() => this.setState({ clothSelect: false })}
                      MenuProps={this.MenuProps()}
                      IconComponent={()=>this.arrowIcon(this.state.clothSelect)}
                    >
                     
 {this.state.category.map((category) => (
              <MenuItem
                key={category.id}
                style={webStyle.menuItem}
                value={category.attributes.name}
              >
                {category.attributes.name}
              </MenuItem>
            ))}
                    </SelectCustom>
                    {this.renderError(this.state.errors.clothValue, "category")}
                  </FormControl>
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Sub-category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.categoryValue}
                      label="Age"
                      disableUnderline={true}
                      dir={i18n.dir()}
                      data-test-id="genderSel3"
                      onChange={this.handleSubCategoryChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      onClose={() => this.setState({ categorySelect: false })}
                      MenuProps={this.MenuProps()}
                      IconComponent={()=>this.arrowIcon(this.state.categorySelect)}
                    >
                      {this.state.category1?.map((sub) => (
                <MenuItem
                  key={sub.id}
                  style={webStyle.menuItem}
                  value={sub.attributes.name}
                >
                  {sub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>
                    {this.renderError(this.state.errors.subCategory, "sub-category")}
                  </FormControl>
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Select Sub-Sub-category")}*</h2>
                  <FormControl fullWidth style={webStyle.selectDiv}>
                    <SelectCustom
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.categoryValue1}
                      label="Age"
                      disableUnderline={true}
                      data-test-id="genderSel34"
                      onChange={this.handleSubSubCategoryChange}
                      onOpen={() => this.setState({ categorySelect: true })}
                      onClose={() => this.setState({ categorySelect: false })}
                      dir={i18n.dir()}
                      MenuProps={this.MenuProps()}
                      IconComponent={()=>this.arrowIcon(this.state.categorySelect)}
                    >
                      {this.state.subsubCategory?.map((subsub) => (
                <MenuItem
                  key={subsub.id}
                  style={webStyle.menuItem}
                  value={subsub.attributes.name}
                >
                  {subsub.attributes.name}
                </MenuItem>
              ))}
                    </SelectCustom>
                    {this.renderError(this.state.errors.subSubCategory, "sub-sub-category")}
                  </FormControl>
                </div>
              </CustomBoxDiv>

              <CustomBoxDiv>
              <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Material")}*{"(English)"}</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Material")}
              variant="standard"
              name="material"
              value={this.state.material}
              dir={i18n.dir()}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
                {this.renderError(this.state.errors.material, "material")}
                </div>
                <div style={{ width: '50%' }} data-test-id="arabic-material-container">
                  <h2 style={webStyle.labelH} data-test-id="arabic-material-title">{this.transAddProductCatalogue("Material")} {"(عربي)"} *</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Material")}
              variant="standard"
              name="arabicMaterial"
              data-test-id="arabic-material-input"
              value={this.state.arabicMaterial}
              dir={i18n.dir()}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
              {this.renderError(this.state.errors.arabicMaterial, "arabic-material")}
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
              <div style={{ width: '50%' }} data-test-id="fit-container">
                  <h2 style={webStyle.labelH} data-test-id="fit-title">{this.transAddProductCatalogue("Fit")}*{"(English)"}</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Fit")}
              dir={i18n.dir()}
              variant="standard"
              name="fit"
              value={this.state.fit}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
                {this.renderError(this.state.errors.fit, "fit")}
                </div>
                <div style={{ width: '50%' }} data-test-id="arabic-fit-container">
                  <h2 style={webStyle.labelH} data-test-id="arabic-fit-title">{this.transAddProductCatalogue("Fit")} {"(عربي)"} *</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Fit")}
              dir={i18n.dir()}
              variant="standard"
              data-test-id="arabic-fit-input"
              name="arabicFit"
              value={this.state.arabicFit}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
            />
              {this.renderError(this.state.errors.arabicFit, "arabic-fit")}
                </div>
              </CustomBoxDiv>
              <CustomBoxDiv>
              <div style={{ width: '50%' }} data-test-id="product-care-container">
                  <h2 style={webStyle.labelH} data-test-id="product-care-title">{this.transAddProductCatalogue("Product Care")}*{"(English)"}</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Product Care")}
              variant="standard"
              data-test-id="product-care-input"
              name="productCare"
              value={this.state.productCare}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
              dir={i18n.dir()}
            />
                {this.renderError(this.state.errors.productCare, "product-care")}
                </div>
                <div style={{ width: '50%' }}>
                  <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Care")} {"(عربي)"} *</h2>
                  <CustomInput
              id="outlined-basic"
              placeholder={this.transAddProductCatalogue("Product Care")}
              variant="standard"
              name="arabicProductCare"
              value={this.state.arabicProductCare}
              onChange={this.handleChange}
              InputProps={{ disableUnderline: true }}
              dir={i18n.dir()}
            />
                {this.renderError(this.state.errors.arabicProductCare, "arabic-product-care")}
                </div>
                </CustomBoxDiv>
<h2 style={webStyle.labelH}>{this.transAddProductCatalogue("List the products")}*</h2>
<FormControl style={{ display: 'flex' }}>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="listed"
    data-test-id="listedd"
    name="radio-buttons-group"
    onChange={(event) => this.setState({ listed: event.target.value })} 
    style={{ display: 'block', marginTop: 4 }}
  >
    <FormControlLabel
      value="listed"
      data-test-id="genderSel4"
      style={{
        fontStyle: "normal !important",
        fontFamily: "Lato , sans-serif",
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: this.fontListed(this.state.listed === "listed"), 
        color: '#375280'
      }}
      control={<CustomRadio />}
      label={this.transAddProductCatalogue("Listed")}
    />
    <FormControlLabel
      value="unlisted"
      data-test-id="genderSel5"
      style={{
        fontStyle: "normal !important",
        fontFamily: "Lato , sans-serif",
        fontSize: '18px',
        lineHeight: '26px',
        fontWeight: this.fontListed(this.state.listed === "unlisted"), 
        color: '#375280'
      }}
      control={<CustomRadio />}
      label={this.transAddProductCatalogue("Unlisted")}
    />
  </RadioGroup>
</FormControl>

              <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Description")}*{"(English)"}</h2>
              <div style={webStyle.detailDiv}>
              <CustomInput
            id="outlined-basic"
            placeholder={this.transAddProductCatalogue("Product Description")}
            variant="standard"
            name="productDescription"
            value={this.state.productDescription}
            onChange={this.handleChange}
            dir={i18n.dir()}
            multiline
            InputProps={{ disableUnderline: true }}
            style={webStyle.detailTitle}
            rows={4}
          />
              {this.renderError(this.state.errors.productDescription, "product-description")}
              </div>
              <h2 style={webStyle.labelH}>{this.transAddProductCatalogue("Product Description")} {"(عربي)"} *</h2>
              <div style={webStyle.detailDiv}>
              <CustomInput
            id="outlined-basic"
            placeholder={this.transAddProductCatalogue("Product Description")}
            variant="standard"
            name="arabicProductDescription"
            value={this.state.arabicProductDescription}
            onChange={this.handleChange}
            dir={i18n.dir()}
            InputProps={{ disableUnderline: true }}
            style={webStyle.detailTitle}
            multiline 
            rows={4}
          />
              {this.renderError(this.state.errors.arabicProductDescription, "arabic-product-description")}
              </div>
              </div>)

              handleHorizontal = () =>{
                return i18n.dir() === "rtl" ? "right" as "right" : "left" as "left"
              }

    MenuProps = () => {
      return {
        style: { minWidth: "180px", height: "320px" },
        anchorOrigin: {
          vertical: "bottom" as "bottom",
          horizontal: this.handleHorizontal(),
        },
        transformOrigin: {
          vertical: "top" as "top",
          horizontal: this.handleHorizontal(),
        },
        getContentAnchorEl: null,
      };
    };

    handleSizeError = () => this.state.sizeError && 
      <div style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato, sans-serif" }}>
        {this.transAddProductCatalogue("Size is required.")}
      </div>

    handleColorError = () => this.state.colorError && 
      <div style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato, sans-serif" }}>
        {this.transAddProductCatalogue("Color is required.")}
      </div>

    handleCreateVariantError = () => this.state.createVariantError && (
      <div style={{ color: '#f44336', fontWeight: 100, fontSize: "16px", fontFamily: "Lato, sans-serif", marginTop: "10px" }}>
        {this.state.createVariantError}
      </div>
    )

    uploadImgErrorMessage = () => this.state.uploadImgError &&
      <Box style={{ backgroundColor: "#FEE2E24D", border: '1px solid #DC26264D', marginTop: '70px', padding: '12px 8px 12px 16px' }}>
        <Box style={{ display: 'flex', alignItems: "center" }}>
          <img src={group_error} width="27px" style={{ marginRight: '16px' }} />
          <Box>
            <Box style={{ color: "#DC2626", fontSize: '18px', fontFamily: 'Lato', fontWeight: 600 }}>{this.state.uploadImgError}</Box>
          </Box>
        </Box>
      </Box>

    renderBtn = () => {
      return checkCondition(this.state.linkStatus === this.state.totalSteps - 1, this.transAddProductCatalogue("Confirm"), this.transAddProductCatalogue("Next")) as string
    }
    renderCreateVariantText = () => {
    return checkCondition(this.state.isVariantCreated, this.transAddProductCatalogue('Update Variant'), this.transAddProductCatalogue('Create Variant')) as string
  }

  linkStatusFirst = (lnk: number) => {
    return (LinkStatusFirst(this.state.linkStatus, lnk))
  }
  circleDiv = (link: number) => {
    return (CircleDiv(this.state.linkStatus, link, webStyle.circleDivActive, webStyle.circleDiv))
  }

  fontWeight = (weight: number) => {
    return (FontWight(this.state.linkStatus, weight))
  }

  hrTag = (hrNum: number) => {
    return (HrTag(this.state.linkStatus, hrNum, webStyle.hrTagFill, webStyle.hrTag) )
  }

  circleDIvFIlledFunc = (item: number, circleNum: number) => {
    return (CircleDIvFIlledFunc(this.state.linkStatus, item, circleNum, webStyle.circleDivFilled, this.circleDiv))
  }

  renderSellerStepper = (userType: string) => userType==='seller' && (
    <>
      <hr style={this.hrTag(5)} />
      <div style={this.circleDIvFIlledFunc(6, 5)}>
        <h1 style={{
          fontSize: "16px", margin: this.handleStepperNumberCSS(), color: this.linkStatusFirst(6), fontStyle: "normal !important", fontWeight: this.fontWeight(5),
          fontFamily: "Lato , sans-serif", lineHeight: "24px"
        }}>5</h1>
        <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", display: "ruby", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: this.fontWeight(5), whiteSpace: "nowrap", margin: this.handleStepperDetailCSS(),}}>{this.transAddProductCatalogue("Assign Stores")}</h2>
      </div>
    </>)

    getSecondPage = () => {
      switch (this.state.linkStatus) {
        case 1:
          return this.firstPageRender();

        case 2:
          return this.getSecondPagevariant("Create", "-", "-", "-");

        case 3:
          return this.getSecondPagevariant("Update", "20", "25", "AS123SS");

        case 4:
          return (
            <>
              {this.uploadImgErrorMessage()}
              <CustomBoxDivs
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "30px",
                  marginTop: checkCondition(
                    this.state.uploadImgError === "",
                    70,
                    30
                  ) as number,
                }}
              >
                {this.state.gridImages.map((grid: any) => {
                  const gridColor = grid.color;
                  return (
                    <div
                      key={gridColor}
                      style={{
                        border: "1px solid #E2E8F0",
                        borderRadius: "4px",
                        width: "580px",
                      }}
                    >
                      {AddUploadBtnPart(
                        `${
                          gridColor.charAt(0).toUpperCase() + gridColor.slice(1)
                        } ${this.transAddProductCatalogue("Variant")}`,
                        grid.images,
                        grid.selectedImage,
                        grid.activeGridImage,
                        gridColor,
                        this.handleGridImage,
                        this.handleActiveGrid,
                        this.handleDeleteImage,
                        this.transAddProductCatalogue,
                        this.fileInputRef,
                        this.state.imageError
                      )}
                    </div>
                  );
                })}
              </CustomBoxDivs>
            </>
          );

        case 5:
          const userRole = JSON.parse(localStorage.getItem("userRole") || "{}");
          if (userRole.userType !== "stylist") {
            return (
              <CustomBoxDiv style={{ display: "flex" }}>
                <div
                  style={{
                    marginTop: "70px",
                    width: "100%",
                    border: "1px solid #E2E8F0",
                    borderRadius: "4px",
                    padding: "10px 20px 10px 20px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 0px 10px 0px",
                      display: "flex",
                      alignItems: "center",
                      borderBottom: "1px solid #D9D9D9",
                      gap: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <h2 style={webStyle.headingL}>
                      {this.transAddProductCatalogue("Select All Stores")}
                    </h2>

                    <GreenCheckbox
                      checked={this.state.selectAll}
                      onChange={this.handleSelectAllChanges}
                      data-test-id="selectAllCheckbox"
                    />
                  </div>
                  {this.state.storeList?.map((store: any) => (
                    <div
                      key={store.id}
                      style={{
                        padding: "10px 0px 10px 0px",
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #D9D9D9",
                        gap: "20px",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50px",
                          }}
                          src={store.attributes.image}
                          alt="upload"
                        />
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#375280",
                            fontFamily: "Lato, sans-serif",
                          }}
                        >
                          {store.attributes.store_name}
                        </p>
                      </div>
                      <GreenCheckbox
                        checked={this.state.selectedStores.includes(store.id)}
                        onChange={() => this.handleCheckboxChange(store.id)}
                        data-test-id="checkBox"
                      />
                    </div>
                  ))}
                </div>
              </CustomBoxDiv>
            );
          }
        default:
          break;
      }
    };

    getSecondPagevariant = (
      title: string,
      qty: string,
      price: string,
      sku: string
    ) => {
      return (
        <>
          <div
            style={{
              border: "1px solid #E2E8F0",
              borderRadius: "4px",
              marginTop: "70px",
              padding: 15,
            }}
          >
            <div>
              <h2 style={webStyle.labelH}>
                {this.transAddProductCatalogue("Variant (Size)")}*
              </h2>
              <FormControl style={webStyle.selectDiv}>
                <SelectCustomMul
                  multiple
                  displayEmpty
                  data-test-id="genderSel6"
                  disableUnderline={true}
                  value={this.state.personName}
                  onChange={this.handleSizeChange}
                  input={<OutlinedInput />}
                  renderValue={() => {
                    return (
                      <p style={{ paddingLeft: 15 }}>
                        {this.transAddProductCatalogue("Select Size")}
                      </p>
                    );
                  }}
                  MenuProps={this.MenuProps()}
                  inputProps={{ "aria-label": "Without label" }}
                  onOpen={() => this.setState({ sizeMulSelect: true })}
                  onClose={() => this.setState({ sizeMulSelect: false })}
                  IconComponent={() => this.arrowIcon(this.state.sizeMulSelect)}
                >
                  {this.state.varient_size?.map((varient_size: any) => (
                    <MenuItem
                      key={varient_size.attributes.name}
                      value={varient_size.attributes.name}
                    >
                      {varient_size.attributes.name}
                    </MenuItem>
                  ))}
                </SelectCustomMul>
                {this.handleSizeError()}
              </FormControl>
              <CustomBoxDiv style={{ marginTop: 12 }}>
                {this.state.personName?.map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "fit-content",
                        border: "1px solid #CCBEB1",
                        borderRadius: 2,
                        padding: "10px",
                        height: "18px",
                        alignItems: "center",
                        marginRight: 12,
                      }}
                      key={item}
                    >
                      <h1 style={webStyle.selectedItem}>{item}</h1>
                      <img
                        style={{ marginLeft: 15 }}
                        src={crossmini}
                        alt="crossmini"
                        data-test-id="genderSel7"
                        onClick={() => this.removeSelectedSize(item)}
                      />
                    </div>
                  );
                })}
              </CustomBoxDiv>
              <h2 style={webStyle.labelH}>
                {this.transAddProductCatalogue("Variant (Color)")}*
              </h2>
              <FormControl style={webStyle.selectDiv}>
                <SelectCustomMul
                  multiple
                  displayEmpty
                  disableUnderline={true}
                  data-test-id="genderSel8"
                  value={this.state.colorArry}
                  onChange={this.handleColorChange}
                  input={<OutlinedInput />}
                  renderValue={() => {
                    return (
                      <p style={{ paddingLeft: 15 }}>
                        {this.transAddProductCatalogue("Select Color")}
                      </p>
                    );
                  }}
                  MenuProps={this.MenuProps()}
                  inputProps={{ "aria-label": "Without label" }}
                  onOpen={() => this.setState({ colorSel: true })}
                  onClose={() => this.setState({ colorSel: false })}
                  IconComponent={() => this.arrowIcon(this.state.colorSel)}
                >
                  {this.state.varient_color?.map((varient_color: any) => (
                    <MenuItem
                      key={varient_color.attributes.name}
                      value={varient_color.attributes.name}
                    >
                      {varient_color.attributes.name}
                    </MenuItem>
                  ))}
                </SelectCustomMul>
                {this.handleColorError()}
              </FormControl>
              <CustomBoxDiv style={{ marginTop: 12 }}>
                {this.state.colorArry?.map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "fit-content",
                        border: "1px solid #CCBEB1",
                        borderRadius: 2,
                        padding: "10px",
                        height: "18px",
                        alignItems: "center",
                        marginRight: 12,
                      }}
                      key={item}
                    >
                      <h1 style={webStyle.selectedItem}>{item}</h1>
                      <img
                        style={{ marginLeft: 15 }}
                        src={crossmini}
                        alt="crossmini"
                        data-test-id="genderSel9"
                        onClick={() => this.removeSelectedColor(item)}
                      />
                    </div>
                  );
                })}
              </CustomBoxDiv>
    
              <button
                style={webStyle.createVarBtn}
                onClick={this.handleCreateVariant}
                data-test-id="handleCreateVariant"
              >
                {this.renderCreateVariantText()}
              </button>
              {this.handleCreateVariantError()}
              <br />
              <p style={webStyle.errorMsg}>
                {this.transAddProductCatalogue(
                  "You can create a max of 30 variants of the product"
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              border: "1px solid #E2E8F0",
              borderRadius: "4px",
              marginTop: "70px",
              padding: 15,
            }}
          >
            <h2 style={webStyle.labelVari}>
              {this.transAddProductCatalogue("Variant Details")} &nbsp;[
              {this.state.variantCount}/30]
            </h2>
            <TableContainer
              component={Paper}
              style={{
                border: "1px solid #E2E8F0",
                borderRadius: "4px",
                marginTop: "10px",
                boxShadow: "none",
              }}
            >
              <Table aria-label="simple table" style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={webStyle.thHead}>
                      {this.transAddProductCatalogue("Variant")}
                    </TableCell>
                    <TableCell style={webStyle.thHead} align="left">
                      {this.transAddProductCatalogue("Quantity")}
                    </TableCell>
                    <TableCell style={webStyle.thHead} align="left">
                      {this.transAddProductCatalogue("Price")} (
                      {this.state.currencySign})
                    </TableCell>
                    <TableCell style={webStyle.thHead} align="left">
                      {this.transAddProductCatalogue("SKU")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.renderTableRows()}</TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      );
    };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const userRole = JSON.parse(localStorage.getItem('userRole') || '{}');
    // Customizable Area End
    return (
      // Customizable Area Start
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }} >
          <img src={arrow} alt="arrow" style={{transform: this.handleBackCSS()}} onClick={() => {
    if (this.state.linkStatus === 1) {
      window.location.reload();
    } else {
      this.setState({ linkStatus: this.state.linkStatus - 1 });
    }
  }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h2 style={webStyle.addPro}>{this.transAddProductCatalogue("Add Products")}</h2>
        </div>
        <CustomBox style={{margin: this.handleStepperMainCSS()}}>
        <CommonBackdrop open={this.state.isCreateLoad} />
          <div style={checkCondition(this.state.linkStatus >= 2, webStyle.circleDivFilled, { border: "2px solid #375280", height: 32, width: 32, borderRadius: '30px' }) as React.CSSProperties}>
            <h1 style={{
              fontSize: "16px", margin: this.handleStepperNumberCSS(), color: this.linkStatusFirst(2),  fontStyle: "normal !important",
              fontFamily: "Lato , sans-serif", lineHeight: "24px", fontWeight: 700
            }}>1</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", margin: this.handleStepperDetailCSS(), lineHeight: "24px", display: "ruby", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: 700 , whiteSpace: "nowrap",}}>{this.transAddProductCatalogue("Product Details")}</h2>
          </div>
          <hr style={this.hrTag(2)} />
          <div style={this.circleDIvFIlledFunc(3, 2)}>
            <h1 style={{
              fontSize: "16px",  color: this.linkStatusFirst(3), margin: this.handleStepperNumberCSS(), fontStyle: "normal !important", fontWeight: this.fontWeight(2),
              fontFamily: "Lato , sans-serif", lineHeight: "24px"
            }}>2</h1>
            <h2 style={{ color: "#375280", margin: this.handleStepperDetailCSS(), fontSize: "16px", lineHeight: "24px", display: "ruby", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: this.fontWeight(2), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Create Variants")}</h2>
          </div>
          <hr style={this.hrTag(3)} />
          <div style={this.circleDIvFIlledFunc(4, 3)}>
            <h1 style={{
              fontSize: "16px",  color: this.linkStatusFirst(4), fontStyle: "normal !important", fontWeight: this.fontWeight(3),
              fontFamily: "Lato , sans-serif", margin: this.handleStepperNumberCSS(),
            }}>3</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", lineHeight: "24px", margin: this.handleStepperDetailCSS(), display: "ruby", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: this.fontWeight(3), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Update Variants")}</h2>
          </div>
          <hr style={this.hrTag(4)} />
          <div style={this.circleDIvFIlledFunc(5, 4)}>
            <h1 style={{
              fontSize: "16px", margin: this.handleStepperNumberCSS(), color: this.linkStatusFirst(5), fontStyle: "normal !important", fontWeight: this.fontWeight(4),
              fontFamily: "Lato , sans-serif", lineHeight: "24px"
            }}>4</h1>
            <h2 style={{ color: "#375280", fontSize: "16px", margin: this.handleStepperDetailCSS(), lineHeight: "24px", display: "ruby", fontStyle: "normal !important", fontFamily: "Lato , sans-serif", fontWeight: this.fontWeight(4), whiteSpace: "nowrap" }}>{this.transAddProductCatalogue("Upload Images")}</h2>
          </div>
          {this.renderSellerStepper(userRole.userType as string)}
        </CustomBox>

        {this.getSecondPage()}

        <div style={{ display: 'flex', marginTop: '25px',alignItems:"center" }}>
          <div>
            <button style={webStyle.backCsvBtn} data-test-id="genderSel11"  onClick={() => {
    if (this.state.linkStatus === 1) {
      window.location.reload();
    } else {
      this.setState({ linkStatus: this.state.linkStatus - 1 });
    }
  }} >{this.transAddProductCatalogue("Back")}</button>&nbsp;&nbsp;
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <button style={webStyle.confirmBtn} data-test-id="genderSel12" onClick={this.handleNextStep}>
            {this.renderBtn()}
              </button>
          </div>
        </div>

        <Snackbar
                  open={this.state.isAlert}
                  autoHideDuration={3000}
                  anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                  onClose={this.oncloseAlert}
                  data-test-id="alertTestId"
                >
                  <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                </Snackbar>
        <DialogContainer data-test-id="handleRedirect1" open={this.state.isSuccess} dir={i18n.dir()} >
          <Box className="content-container">
            <img src={storeSuccessIcon} alt="store-success-icon"/>
            <Typography className="text-container">{this.transAddProductCatalogue("Product listed successfully!")}</Typography>
          </Box>
        </DialogContainer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 110px",
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        textAlign: "center",
        color: "#0F172A",
        fontSize: 30,
        fontWeight: 400,
        fontFamily: "Lato, sans-serif",
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});
const GreenCheckbox = styled(Checkbox)({
  color: "#CCBEB1",
  '&.Mui-checked': {
    color: "#CCBEB1",
  },

  '&.Mui-checked + .MuiIconButton-label': {
    backgroundColor: '#CCBEB1',
  }
});
const UploadText = styled(Typography)({
  textTransform: "lowercase",
  display: "flex",
  justifyContent: "center",
  color: "#94A3B8",
  fontStyle: "normal !important",
  fontWeight: 500,
  fontSize: "18px",
  fontFamily: "Lato , sans-serif",
  marginTop: "5px",
})
const UploadBtn = styled(Typography)({
  fontWeight: 700,
  fontSize: "18px",
  fontFamily: "Lato , sans-serif",
  marginTop: "15px",
  fontStyle: "normal !important",
  display: "flex",
  color: "#375280",
  justifyContent: "center",
  textTransform: "capitalize",
  boxShadow: 'none',
})
const CustomRadio = styled(Radio)({
  '&  .MuiIconButton-label': {
    color: '#C7B9AD',
    fontWeight: 'inherit !Important'
  },
  '& MuiTypography-body1': {
    fontWeight: 'inherit !Important'
  }
})
const SelectCustom = styled(Select)(({ dir }: { dir: "rtl" | "ltr" }) =>({
  fontSize: "18px",
  height: "53px",
  borderRadius: "4px",
  lineHeight: "26px",
  border: "1px solid #F8F8F8",
  fontWeight: 400,
  background: "#f1f1f1",
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  padding: dir === "ltr" ? "0px 15px 0px 20px" : "0px 20px 0px 15px",
  color: '#375280',
  '& .MuiSelect-select.MuiSelect-select': {
    background: '#f1f1f1',
    padding: 0,
  }
}))
const SelectCustomMul = styled(Select)({

  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  fontWeight: 400,
  lineHeight: "26px",
  color: '#94A3B8',
  padding: "0px 15px",
  fontSize: "18px",
  borderRadius: "4px",
  background: "#f1f1f1",
  boxShadow: 'none', '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  '& .MuiSelect-select.MuiSelect-select': {
    margin: 0,
    padding: "0px !important",
    background: '#f1f1f1',
    height: 60,
  },
})
const CustomBoxDiv = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
})
const CustomBoxDivs = styled(Box)({
  gap:"30px",
  flexWrap: 'wrap',
  display: 'flex',
})
const CustomBox = styled(Box)({
  // width: '100%',
  alignItems: "center",
  display: 'flex',
  // flexWrap: 'wrap',
})

const CustomInput = styled(TextField)(({ dir }: { dir: "rtl" | "ltr" }) =>({
  paddingTop: 8,
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  width: "94%",
  padding: dir === "ltr" ? "8px 0px 0px 15px" : "8px 15px 0px 0px",
  borderRadius: "4px",
  marginBottom: 10,
  border: "1px solid #F8F8F8",
  background: "#f1f1f1",
  height: '43px',
  '& ::placeholder': {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: "18px",
    color: "#375280",
  },
  '& .MuiInputBase-root': {
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontFamily: "Lato , sans-serif",
    fontWeight: 400,
    fontSize: "18px",
    color: "#375280",
  }
}));
const CustomInputTable = styled(TextField)({
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  width: "100px",
  borderRadius: "4px",
  height: '40px',

  border: '1px solid #E2E8F0',
  '& ::placeholder': {
    fontStyle: "normal !important",
    fontWeight: 400,
    fontFamily: "Lato , sans-serif",
    lineHeight: "24px",
    fontSize: "18px",
    color: "#375280",
  },
  '& .MuiInputBase-root': {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 400,
    color: "#375280",
    textAlign: 'center',
    fontSize: "16px",
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
    paddingTop: '10px'
  }
});
const CustomImgFont = styled(Typography)({
  color: '#94A3B8',
  fontFamily: "Lato , sans-serif",
  marginTop: 42,
  fontStyle: "normal !important",
  textAlign: 'center',
  background: '#E2E8F0',
  lineHeight: "18px",
  fontWeight: 500,
  fontSize: "16px",
})
const webStyle = {
  titleImg: {
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "26px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: '#375280',
  },

  allThreeimg: {
    width: "100%",
    marginBottom: "-40px",
    height: 80
  },
  boxesImg: { width: 100, height: 100, border: '1px solid #E2E8F0', borderRadius: 2 },
  tableInput: {
    textAlign: "center",
    border: '1px solid #E2E8F0',
    height: 40,
    width: 100,
  },
  thHead: {
    color: '#375280',
    fontFamily: "Lato , sans-serif",
    borderBottom: "1px solid #CBD5E1",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "16px",
  },
  trHead: {
    color: '#375280',
    fontFamily: "Lato , sans-serif",
    lineHeight: "24px",
    fontStyle: "normal !important",
    borderBottom: "1px solid #CBD5E1",
    paddingTop: 25,
    fontSize: "16px",
    paddingBottom: 25,
    fontWeight: 400,
  },
  errorMsg: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    margin: 0,
    color: '#94A3B8'
  },
  errorMsg1:{
    padding: '1px 8px 1px 16px',
    border: '1px solid #DC26264D',
    borderRadius: '4px',
    fontFamily: 'Lato, sans-serif',
    backgroundColor: '#FEE2E24D',
    fontSize: '16px',
    display:"flex",
    fontWeight: 100,
    color: 'rgb(244, 67, 54)',
    alignItems:"center",
  },
  selectedItem: {
    color: "#375280",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
  },
  detailTitle: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "30px",
    fontWeight: 400,
    height: 122,
    fontSize: "18px",
    color: "#375280",
  },
  detailDiv: {
    width: "103%"
  },
  selectDiv: {
    width: '97%'
  },
  labelH: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "26px",
    fontWeight: 700,
    fontSize: "18px",
    marginBottom: "5px",
    color: "#375280",
    width: '97%',
  },
  headingL: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "26px",
    marginBottom: "5px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    color: "#375280",
    width: '97%',
  },
  labelVari: {
    marginTop: '20px',
    color: "#375280",
    fontSize: "20px",
    marginBottom: "5px",
    fontWeight: 700,
    lineHeight: "24px",
    width: '97%',
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
  },
  menuItem: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontSize: "16px",
    color: "#375280",
  },
  hrTag: {
    margin: 0,
    height: 0,
    width: "100%" as any,
    border: '1px solid #E2E8F0'
  },
   hrTagFill: {
    border: '1px solid #375280',
    margin: 0,
    height: 0,
    width: '100%' as any,
  },
  circleDivActive: {
    borderRadius: '30px',
    width: 32,
    height: 32,
    border: "2px solid #375280",
  },
  circleDiv: {
    borderRadius: '30px',
    width: 32,
    height: 32,
    border: "2px solid rgb(89, 117, 163)",
  }, circleDivFilled: {
    borderRadius: '30px',
    background: '#375280',
    width: 32,
    height: 32,
    border: "2px solid #375280",
  },
  addPro: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "32px",
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
  },
  uploadBtn: {
    fontWeight: 500,
    fontStyle: "normal !important",
    fontSize: "18px",
    color: "#375280",
    lineHeight: "24px",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    background: "#FFFFFF",
    cursor: "pointer",
    height: '54px',
    width: '535px',
    marginTop: 18,
  },
  backCsvBtn: {
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "18px",
    color: "#375280",
    fontStyle: "normal !important",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    background: "#FFFFFF",
    height: '54px',
    width: '200px',
  }, createVarBtn: {
    width: '250px',
    height: '54px',
    background: "#FFFFFF",
    border: "1px solid #CCBEB1",
    color: "#375280",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    cursor: "pointer",
    marginBottom: 6
  },
  confirmBtn: {
    fontWeight: 700,
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "26px",
    border: "1px solid #CCBEB1",
    fontStyle: "normal !important",
    background: "#CCBEB1",
    fontFamily: "Lato , sans-serif",
    borderRadius: '2px',
    height: '54px',
    cursor: "pointer",
    width: '200px',
  },
};
// Customizable Area End
