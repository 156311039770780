import React from "react";
// Customizable Area Start
import {
    FormHelperText,
    Box, TableContainer, Table, TableBody,Snackbar, InputAdornment,TableRow,Divider, TableCell,Dialog, TableHead, Drawer,Typography, Button, Modal, Backdrop, Fade, withStyles, FormControl,CircularProgress,TextField
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import Alert from '@material-ui/lab/Alert';
import { styled } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import ReturnRequestController, {
    Props,
    SellerOrderSeller,
    OrderItemSeller
} from "./ReturnRequestController.web";
import Select from '@material-ui/core/Select';
import { Noimageavailablepng, button_close ,message,calls,document,group_Group} from "./assets";
export const configJSON = require("./config");
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import { withScriptjs } from 'react-google-maps';
import TrackMap from "../../../components/src/TrackMap";
import { statusContainerReturnData,statusContainerTrackDriver } from "../../../components/src/Seller/SellerStatusPopup";
import i18n from "../../../components/src/i18next/i18n";
import { PaginationContainer } from "./SellersAllOrder.web";



// Customizable Area End

export default class ReturnRequest extends ReturnRequestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    ROWS_PER_PAGE = 10;
    renderContent = () => {
        const { activeTab } = this.state;
        
        return (
            <>
            <ReturnReqTabBox>
                <Table><TableHead>
                    <TableRow style={{ backgroundColor: '#f0f0f0', whiteSpace: "nowrap" }}>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Return Request' ? '#375280' : 'inherit',
                            color: activeTab === 'Return Request' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography style={webStyle.tableHaderCaseText} data-test-id='setActiveTab' onClick={() => this.setActiveTab('Return Request')}>{`${this.tranReturnRequest("Return Request")} (${this.state.totalOrder.total_return_request})`}</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Return in Process' ? '#375280' : 'inherit',
                            color: activeTab === 'Return in Process' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography style={webStyle.tableHaderCaseText} data-test-id='setActiveTab1' onClick={() => this.setActiveTab('Return in Process')}>{`${this.tranReturnRequest("Return In Process")} (${this.state.totalOrder.total_return_in_process})`}</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Refund under Process' ? '#375280' : 'inherit',
                            color: activeTab === 'Refund under Process' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography style={webStyle.tableHaderCaseText} data-test-id='setActiveTab2' onClick={() => this.setActiveTab('Refund under Process')}>{`${this.tranReturnRequest("Refund Under Process")} (${this.state.totalOrder.total_return_under_process})`}</Typography></TableCell>
                        <TableCell style={{
                            ...webStyle.tableHaderCase, backgroundColor: activeTab === 'Refunded' ? '#375280' : 'inherit',
                            color: activeTab === 'Refunded' ? '#FFFFFF' : '#94A3B8',
                        }}><Typography style={webStyle.tableHaderCaseText}  data-test-id='setActiveTab3' onClick={() => this.setActiveTab('Refunded')}>{`${this.tranReturnRequest("Refunded")} (${this.state.totalOrder.total_refunded})`}</Typography></TableCell>
                    </TableRow>


                </TableHead>
                </Table></ReturnReqTabBox>
                <CustomStyledTable className="container">

                    <Table stickyHeader size="small" className="custom-table">
                        <TableHead>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Order ID")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Order Time")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Products")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Shop")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Total Price")}</Typography></TableCell>
                                <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Status")}</Typography></TableCell>
                                {activeTab !== 'Refunded' && (
                                    <TableCell style={webStyle.tableHader}><Typography className="table-header-typo" variant="h6">{this.tranReturnRequest("Action")}</Typography></TableCell>
                                )}
                            </TableRow>
                        </TableHead>

                        <Table>

                        </Table>
                        {this.renderTableBody()}

                    </Table>
                </CustomStyledTable>
                {this.renderPagination()}
            </>
        );
    }

    setActiveTab = (tab: any) => {
        this.setState({ activeTab: tab });
    };

    handleReturnReqAnchor = () => {
        return i18n.dir() === "rtl" ? "left" : "right"
    }

    handlePagination = (data: {startIndex: number, endIndex: number, page: number, totalRecord: number, totalPages: number}) => (
        <PaginationContainer>
                        <Box style={webStyle.paginationSecBox}>
                            {data.startIndex} - {data.endIndex} {this.tranReturnRequest("of")} {data.totalRecord} {this.tranReturnRequest("results")}
                        </Box>
                        <CustomStyledPagination
                            page={data.page}
                            count={data.totalPages}
                            onChange={this.handlePageChangeReadyFor}
                            shape="rounded"
                            color="primary"
                            data-testid="pageChange-test-id"
                            dir={i18n.dir()}
                        />
                    </PaginationContainer>
    )


    handlePageChangeReadyFor = (_: React.ChangeEvent<unknown>, page: number) => {
        const { activeTab } = this.state;
        this.setState((prevState) => {
          const metaData = this.getActiveMetaData(activeTab);
          const isLoadingKey = this.getIsLoadingKey(activeTab);
          const pageKey = this.getReadyForPageKey(activeTab);
          return {
            ...prevState,
            [this.getMetaDataKey(activeTab)]: { ...metaData, current_page: page },
            [isLoadingKey]: true,
            [pageKey]: page,
          };
        }, ()=> this.renderData());
    };

      renderPagination = () => {
        const { activeTab } = this.state;
        const metaData = this.getActiveMetaData(activeTab);
        if (!metaData || Object.keys(metaData).length === 0) {
            return <></>;
        }
        const endIndex = Math.min(metaData.current_page * this.ROWS_PER_PAGE, metaData.total_record);
        const startIndex = (metaData.current_page - 1) * this.ROWS_PER_PAGE + 1;
    
        return this.handlePagination({
              startIndex,
              endIndex,
              page: metaData.current_page,
              totalRecord: metaData.total_record,
              totalPages: metaData.total_pages,
        });
      };

renderNoOrderFound = (colNo: number) =>(
    <TableRow>
          <TableCell className="ui-no-data" colSpan={colNo}>
            <NoRecordTypoContainer>{this.tranReturnRequest("There are no orders")}</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
)

renderCircularProgressFound = (colNo: number) =>(
    <TableRow>
      <TableCell colSpan={colNo} align="center">
        <Box style={{ ...webStyle.tabsBoxReady }}>
          <CircularProgress style={webStyle.CircularProgressColor} />
        </Box>
      </TableCell>
    </TableRow>
  )
    renderTableBody = () => {
        const { activeTab } = this.state;

        switch (activeTab) {
            case 'Return Request':
                return (
                    <TableBody>
                        <>{this.state.isLoadingRequest ? this.renderCircularProgressFound(7) : this.renderRequest()}</>
                    </TableBody>
                );
            case 'Return in Process':
                return (
                    <>
                    <TableBody>
                    <>{this.state.isLoadingInProcess ? this.renderCircularProgressFound(7) : this.renderInProcess()}</>
                    </TableBody>
                        <Drawer anchor={this.handleReturnReqAnchor()} open={this.state.isDrawerStatusOpen} onClose={this.toggleStatusDrawer(false)} dir={i18n.dir()}>
                            <Box style={webStyle.MapComponentContainerTreack}>
                                <Box style={webStyle.mapHeaderText} id="mapHeaderText">
                                    <Box id="mapTitleContainerText" style={webStyle.mapTitleContainerText}>
                                        <Typography id="trackDriveTitle" style={webStyle.trackDriveTitle}>{this.tranReturnRequest("Order Status")}</Typography>
                                        <CloseIcon role="presentation"
                                            onClick={this.toggleStatusDrawer(false)}
                                            onKeyDown={this.toggleStatusDrawer(false)} />
                                    </Box>
                                </Box>
                                <Box style={webStyle.trackContentStatusContainer} id="trackContentStatusContainer">
                                    {statusContainerReturnData(this.state.orderData)}
                                    <Box id="" style={{ boxShadow: "0px 5px 10px 0px", backgroundColor: '#FFF', padding: '24px', margin: '24px 0px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                                            <Typography id="driverNameSpase" style={webStyle.driverNameSpase}>{this.tranReturnRequest("Order ID")}:</Typography>
                                            <Typography id="orderNumber" style={webStyle.driverNameSpase}>#{this.state.orderNumber}</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={webStyle.driverNameSpase} id="Purchase">{this.tranReturnRequest("Purchase date")}:</Typography>
                                            <Typography style={webStyle.driverNameSpase} id="placetAt">{this.state.placetAt}</Typography>
                                        </Box>
                                    </Box>
                                    <Button disabled={this.state.orderData.return_pick_at === null} style={{textTransform:'capitalize',textAlign: 'center' ,...webStyle.treckOrder}} data-testid="toggleStatusDataPick" onClick={this.toggleStatusData(true,this.state.toggleStatusDataId)}>{this.tranReturnRequest("Track Order")}</Button>
                                </Box>
                            </Box>
                        </Drawer>
                    </>
                );
            case 'Refund under Process':
                return (
                    <>
                    <TableBody>
                    <>{this.state.isLoadingUnderProcess ? this.renderCircularProgressFound(7) : this.renderRefundUnderProcess()}</>
                    </TableBody>
                        <Dialog
                            onClose={this.handleCloseReturnRequestModal}
                            open={this.state.isOpenReturnRequest}
                            maxWidth="md"
                            PaperProps={{
                                style: {
                                    maxWidth: "600px",
                                    height: "731px",
                                    width: "100%",
                                    borderRadius: "12px",
                                },
                            }}
                            dir={i18n.dir()}
                        >
                            <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseReturnRequestModal} data-testid="handleCloseRejectDeleteModal" style={{ cursor: "pointer" }} /></Box>
                            <Box style={{ margin: "0 auto" }}>
                                <Typography style={{ ...webStyle.rejTitle, textAlign: "center" }}>{this.tranReturnRequest("Reject Refund")}</Typography>
                                <Typography style={webStyle.ListTitle}>{this.tranReturnRequest("Reason of Rejection")}</Typography>
                                <CustomSelectNewOrderReturn variant="outlined" style={{ width: '100%', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelectNewOrderReturn
                                        value={this.state.numberDataOrder}
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        MenuProps={{
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            getContentAnchorEl: null,
                                        }}
                                        style={webStyle.reminderName}
                                        displayEmpty
                                        onChange={this.handlenumberDataOrderChangeNumber}
                                        data-testid='sub-categories-selection'
                                    >
                                        <MenuItem value="" disabled><Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.tranReturnRequest("Select Reason")}</Box></MenuItem>
                                        {this.state.selectReson.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.tranReturnRequest(select)}</MenuItem>
                                        ))}
                                    </MuiSelectSelectNewOrderReturn>
                                </CustomSelectNewOrderReturn >
                                <Box style={{ marginTop: "32px" }}>
                                    <Typography style={webStyle.uploadTitle}>
                                    {this.tranReturnRequest("Upload the item image for rejecting the refund")}
                                    </Typography>

                                    {this.state.combineImg.length == 0 ?
                                        <Box style={webStyle.documentBox as React.CSSProperties}>
                                            <Box>
                                                <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                                    <img className="fieldImg" alt='civilIdPassport' src={document} data-testid="openPictureUploadUplode" onClick={() =>  this.openPictureUpload(0) } />
                                                </Box>
                                                <Typography style={{ color: "#375280", fontWeight: 700, fontSize: "18px", fontFamily: "Lato" }}>{this.tranReturnRequest("Upload Image (PNG or JPG)")}</Typography>
                                                <Typography style={{ color: "#94A3B8", fontWeight: 400, fontSize: "14px", fontFamily: "Lato" }}>{this.tranReturnRequest("Only PNG or JPG files are accepted")}</Typography>
                                            </Box>
                                        </Box>
                                        :
                                        <Box style={{ position: 'relative', textAlign: 'center', color: 'white' }}>
                                            <img className="uploadBoximg" src={this.state.combineImg[0] || undefined} style={{ cursor: "pointer", width: '100%', height: '14.5rem' }} />
                                            <Box style={{ position: 'absolute', top: '75%', right: '20px' }}><img src={group_Group} width="48px" height="48px" data-testid="handleRemove" onClick={this.handleRemove} /></Box>
                                        </Box>
                                    }
                                    <Button style={{ ...webStyle.uploadBtn, textTransform: "none", }} data-testid="openPictureUpload" onClick={() => { this.openPictureUpload(0) }}>
                                        {this.tranReturnRequest("Upload Photo")}
                                    </Button>
                                </Box>
                                    <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                                        <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} onClick={this.handleCloseReturnRequestModal}>{this.tranReturnRequest("Close")}</Button>
                                        <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="timeOrderDataReject" onClick={this.timeOrderDataReject}>{this.tranReturnRequest("Confirm")}</Button>
                                    </Box>
                            </Box>
                        </Dialog>

                        <Dialog
                            onClose={this.handleCloseDeleteModal}
                            open={this.state.isOpenDelete}
                            maxWidth="md"
                            dir={i18n.dir()}
                            PaperProps={{ style: { maxWidth: "600px", height: "444px", width: "100%", borderRadius: "12px" } }}
                        >
                            <Box style={webStyle.closeicon}><CloseIcon onClick={this.handleCloseDeleteModal} style={{ cursor: "pointer" }} /></Box>
                            <Box style={{ margin: '0 auto', padding: '0 20px', display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Typography style={{ ...webStyle.refTitle, textAlign: "center" }}>{this.tranReturnRequest("Initiate Refund")}</Typography>
                                <Typography style={{ ...webStyle.ListTitle, textAlign: "center", width: 420 }}>{this.tranReturnRequest("Are you sure you want to initiate a refund for the returned product?")}</Typography>
                                <Box style={{ marginTop: "32px",width:'100%' }}>
                                    <Typography style={webStyle.refundTitle}> {this.tranReturnRequest("Refund Amount")}</Typography>
                                    <InputFieldNew
                                        multiline
                                        id="outlined-multiline-static"
                                        data-testid="changeDiscRejectMrpData"
                                        inputProps={{ 'data-testid': 'changeDisc' } as React.InputHTMLAttributes<HTMLInputElement> & {
                                            'data-testid'?: string;
                                            type: 'number';
                                            'aria-label'?: string;
                                            min: '0';
                                        }}
                                        InputProps={{ startAdornment: <InputAdornment position="end" style={{ color: '#375280' }}><span style={{ color: '#375280', fontSize: '18px', marginRight: '0px' }}>$</span></InputAdornment>, }}
                                        disabled={true}
                                        value={this.state.rejectMrpData}
                                        style={{ border: this.state.errorMessage ? '1px solid red' : '#ddd', width: '100%' }}
                                        variant="outlined"
                                        type="number"
                                        onChange={(event) => {
                                            const value = event.target.value;
                                            const cleanedValue = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                                            this.setState({ refundAmount: cleanedValue });
                                        }}
                                    />
                                    {this.state.errorMessage && <FormHelperText error>{this.state.errorMessage}</FormHelperText>}
                                </Box>
                                <Box style={{ marginTop: "2rem", display: "flex", gap: "8px" }}>
                                    <Button variant="contained" style={{ ...webStyle.close, textTransform: 'none' }} data-testid="handleCloseDeleteModal" onClick={this.handleCloseDeleteModal}>{this.tranReturnRequest("Close")}</Button>
                                    <Button variant="contained" color="secondary" style={{ ...webStyle.confirm, textTransform: 'none' }} data-testid="handleConfirm" onClick={() => this.handleConfirmDetalis(this.state.rejectMrpData)}>{this.tranReturnRequest("Confirm")}</Button>
                                </Box>
                            </Box>
                        </Dialog>
                    </>
                );
            case 'Refunded':
                return (

                    <TableBody>
                        <>{this.state.isLoadingRefunded ? this.renderCircularProgressFound(6) : this.renderRefundedTable()}</>
                    </TableBody>

                );
            default:
                return null;
        }
    };

    renderRequest = () =>(<>{this.state.SellerOrdersProcessedView.length === 0 ? this.renderNoOrderFound(7) : (
        <>{this.state.SellerOrdersProcessedView?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                <TableCell style={{ verticalAlign: 'top', ...webStyle.tableHaderrow }}><Typography className="table-body-typo">{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell>


                                <TableCell data-test-id="navigateToReturnDetail1" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={{ display: "flex", gap:10 }}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut1 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut1 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut1 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.currency_logo}{sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>{this.tranReturnRequest("Return Request")}</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                    <Box style={{ display: 'flex', gap: '10px' }}>
                                        <Box display="flex" alignItems="center" style={{ gap: '10px', cursor: 'pointer' }}>
                                            <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize", gap: 8 }} data-test-id='handleOpen1' onClick={() => this.handleOpen1(sellerData.attributes.id)} >{this.tranReturnRequest("Reject")}</Button>
                                            <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} data-test-id='handleOk' onClick={() => this.handleOk(sellerData.attributes.id)}>{this.tranReturnRequest("Accept")}</Button>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <StyledDrawer
                                    aria-describedby="transition-modal-description"
                                    aria-labelledby="transition-modal-title"
                                    onClose={this.handleCancel}
                                    open={this.state.visible}
                                    BackdropComponent={Backdrop}
                                    closeAfterTransition
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                    dir={i18n.dir()}
                                >
                                    <Fade in={this.state.visible} style={webStyle.febBox}>
                                        <div style={{ padding: "10px 30px 30px 30px" }}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleCancel} /></Box>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.tranReturnRequest("Accept Return?")}</h2>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.tranReturnRequest("Are you sure you want to accept the return order?")}</h2>

                                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>{this.tranReturnRequest("Close")}</StyledButtonModel>
                                                <StyledButton1Model style={{ fontSize: "14px" }} data-test-id="timeData1" onClick={() => this.AcceptRequest(sellerData.attributes.id)}>{this.tranReturnRequest("Confirm")}</StyledButton1Model>
                                            </div>
                                        </div>
                                    </Fade>
                                </StyledDrawer>

                                <StyledDrawer
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    open={this.state.rejectOpen}
                                    onClose={this.handleClose1}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                    dir={i18n.dir()}
                                >
                                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                                        <div style={{ padding: "10px 30px 30px 30px" }}>
                                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectName, textAlign: "center" }}>{this.tranReturnRequest("Reject Return Order")}</h2>
                                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMaseg, textAlign: "center" }}>{this.tranReturnRequest("Are you sure you want to reject the return order?")}</h2>
                                            <Box style={{ marginBottom: '30px' }}>
                                                <Box style={webStyle.modelText}>{this.tranReturnRequest("Reason of Rejection")}</Box>
                                                <CustomSelect variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                                    <MuiSelectSelect
                                                        value={this.state.numberData}
                                                        displayEmpty
                                                        style={webStyle.reminderName}
                                                        onChange={this.handleSingleChangeNumber}
                                                        data-testid='sub-categories-selection'
                                                        MenuProps={{
                                                            getContentAnchorEl: null,
                                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
                                                        }}
                                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                                    >
                                                        <MenuItem value="" disabled>
                                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.tranReturnRequest("Out of Stock")}</Box>
                                                        </MenuItem>
                                                        {this.state.short.map((select: string, index: number) => (
                                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.tranReturnRequest(select)}</MenuItem>
                                                        ))}
                                                    </MuiSelectSelect>
                                                </CustomSelect >
                                            </Box>
                                            <Box style={{ marginBottom: '30px' }}>
                                                <Box style={webStyle.modelText}>{this.tranReturnRequest("Rejection Details")}</Box>
                                                <InputField
                                                    id="outlined-multiline-static"
                                                    multiline
                                                    type="text"
                                                    data-test-id="enterRejectionDetails"
                                                    rows={3}
                                                    variant="outlined"
                                                    placeholder={this.tranReturnRequest("Enter Rejection Details")}
                                                    style={{ width: '100%' }}
                                                    value={this.state.descriptionReson}
                                                    onChange={(event) => {
                                                        this.changeDisc(event.target.value)
                                                    }}
                                                />
                                            </Box>
                                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                                <StyledButtonModel style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>{this.tranReturnRequest("Close")}</StyledButtonModel>
                                                <StyledButton1Model style={{ fontSize: "14px" }} data-test-id="rejectData" onClick={this.rejectData}>{this.tranReturnRequest("Reject")}</StyledButton1Model>
                                            </div>
                                        </div>
                                    </Fade>
                                </StyledDrawer>

                            </TableRow>
                        ))}
</>)}</>)

    renderInProcess = () => (<>{this.state.SellerOrdersProcessedViewProcess.length === 0 ? this.renderNoOrderFound(7) : (
        <>{this.state.SellerOrdersProcessedViewProcess?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell>


                                <TableCell data-test-id="navigateToReturnDetail1" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={webStyle.productDflex}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut2 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut2 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut2 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.currency_logo}{sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', color: "#B55B52", backgroundColor: "#B55B521A" }}>{this.tranReturnRequest("Return In Process")}</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                    <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize", gap: 8 }} data-testid="toggleStatusDrawer" onClick={this.toggleStatusDrawer(true,sellerData.attributes.id)}>{this.tranReturnRequest("Order Status")}</Button>
                                </TableCell>

                            </TableRow>
                        ))}</>)}</>)

    renderRefundUnderProcess = () => (<>{this.state.SellerUnderProcess.length === 0 ? this.renderNoOrderFound(7) : (
        <>{this.state.SellerUnderProcess?.map((sellerData: SellerOrderSeller, index: number) => (
                            <TableRow className="hover">
                                <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell>


                                <TableCell data-test-id="navigateToReturnDetail" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
                                >
                                    {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                                        <Box style={webStyle.productDflex}>
                                            <Box><img src={items.attributes.catalogue_variant.attributes.front_image ? items.attributes.catalogue_variant.attributes.front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                            <Box style={{ ...webStyle.tableHaderrowOut }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                                        </Box>
                                    ))}
                                </TableCell>

                                <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrowOut }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.currency_logo}{sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', color: "#F545C4", backgroundColor: "#F545C41A" }}>{this.tranReturnRequest("Refund In Process")}</Box></TableCell>
                                <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top', gap: 8 }}>
                                    <Box style={{ display: "flex", gap: 10 }}>
                                        <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} data-testid="handleOpenDeleteModal" onClick={() => this.handleOpenReturnRequestModal(sellerData.id)}>{this.tranReturnRequest("Reject")}</Button>
                                        <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} data-testid="handleOpenReturnRequestModal" onClick={() => this.handleOpenDeleteModal(sellerData.id)}>{this.tranReturnRequest("Initiate Refund")}</Button>
                                    </Box>
                                </TableCell>

                            </TableRow>
                        ))}</>)}</>)

    renderRefundedTable = () =>(<>{this.state.SellerRefunded.length === 0 ? this.renderNoOrderFound(6) : (<>{this.state.SellerRefunded?.map((sellerData: SellerOrderSeller, index: number) => (
        <TableRow className="hover">
            <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">#{sellerData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography className="table-body-typo">{moment(sellerData.attributes.order_items[0].attributes.placed_at).format("hh:mm A")}</Typography></TableCell>


            <TableCell data-test-id="navigateToReturnDetail" onClick={() => this.navigateToReturnDetail({ productDetailId: sellerData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "ReturnDetail" })}
            >
                {sellerData.attributes.order_items.map((items: OrderItemSeller) => (
                    <Box style={webStyle.productDflex}>
                        <Box><img src={items.attributes.catalogue_variant.attributes.front_image ? items.attributes.catalogue_variant.attributes.front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                        <Box style={{ ...webStyle.tableHaderrowOut3 }}>{items.attributes.catalogue.attributes.name} x {items.attributes.quantity}</Box>
                    </Box>
                ))}
            </TableCell>

            <TableCell style={{ ...webStyle.tableHaderrowOut3 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.catalogue_variant.store_info.attributes.store_name}</Typography></TableCell>
            <TableCell style={{ ...webStyle.tableHaderrowOut3 }}><Typography className="table-body-typo">{sellerData.attributes.order_items[0].attributes.currency_logo}{sellerData.attributes.order_items[0].attributes.total_price}</Typography></TableCell>
            <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center', backgroundColor: "#E1EFE1", color: "#039000" }}>{this.tranReturnRequest("Refunded")}</Box></TableCell>


        </TableRow>
    ))}</>)}</>)
    // Customizable Area End
    render() {
        // Customizable Area Start
        const MapLoader = withScriptjs(TrackMap);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box data-testid="allOrder" style={{ width: '-webkit-fill-available' }} dir={i18n.dir()}>
                    {this.renderContent()}
                </Box>
                <Drawer anchor={this.handleReturnReqAnchor()} open={this.state.isDrawerStatus} onClose={this.toggleStatusData(false)} dir={i18n.dir()}>
                    <Box style={webStyle.MapComponentContainerTreack}>
                        <Box style={webStyle.mapHeaderText} id="track">
                            <Box id="track" style={webStyle.mapTitleContainerText}>
                                <Typography id="track" style={webStyle.trackDriveTitle}>{this.tranReturnRequest("Track Driver")}</Typography>
                                <CloseIcon role="presentation"
                                    onClick={this.toggleStatusData(false)}
                                    onKeyDown={this.toggleStatusData(false)} />
                            </Box>
                        </Box>
                        <Box style={{ height: '449px' }}>
                            <div id="track">
                                <MapLoader
                                    loadingElement={<div style={{ width: "200px", height: "400px" }} className="MapComponentHeight" />}
                                    googleMapURL={configJSON.mapApk}
                                    destination={this.state.longitudeData}
                                    origin={this.state.latitudeData}
                                />
                            </div>
                            <Box style={{ padding: '25px' }} id="track">
                                <Box style={webStyle.delivedTime}>
                                    <Typography id="driverName" style={webStyle.driverName}>{this.state.driverName}</Typography>
                                    {this.state.orderData.estimated_delivery_time === null ? null : <Typography style={webStyle.driverName}>{this.tranReturnRequest("Delivering in")} : {this.state.orderData.estimated_delivery_time}</Typography>}
                                </Box>
                                <Box style={{ display: 'flex', justifyContent: 'space-between',margin:'24px 0px' }}>
                                    <Box id="driverName" style={webStyle.otpData}>{this.state.orderData.otp === null ? "****" : this.state.orderData.otp} - {this.tranReturnRequest("OTP")}</Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', gap: 12 }} id="driverName">
                                        <Box id="driverName"><img src={message} /></Box>
                                        <Box id="driverName"><img src={calls} /></Box>
                                    </Box>
                                </Box>
                                <Divider style={{marginBottom:'20px'}}/>
                                {statusContainerTrackDriver(this.state.orderData)}
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    documentBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '14.5rem',
        width: '100%',
        backgroundColor: '#F0F0F0',
        position: "relative",
    },
    otpData:{backgroundColor: '#CCBEB1', padding: '13px 20px', color: "#FFFFFF", fontWeight: 700, fontSize: '14px', fontFamily: 'Lato',display:'flex',alignItems:'center'},
    driverName:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' },
    delivedTime:{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#F4F4F4', padding: '6px 16px', borderRadius: '2px' },
    new_orderBox: { backgroundColor: '#0A84FF1A', color: '#0A84FF', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    return_requestBox: { backgroundColor: '#e8f1ff', color: '#1a8fea', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    in_processBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    processedBox: { backgroundColor: '#fff3c9', color: '#d97601', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    deliveredBox: { backgroundColor: '#E2E8F0', color: '#375280', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    shippedBox: { backgroundColor: '#F0E5FF', color: '#6200EA', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    treckOrder:{ backgroundColor: '#CCBEB1', padding: '15px', width: '100%', color: '#FFFFFF', fontFamily: 'Lato', fontWeight: 800, fontSize: '20px'},
    driverNameSpase:{ color: '#375280', fontFamily: 'Lato', fontWeight: 700, fontSize: '16px' },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    tabeLable: { color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', width: '90%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    confomMaseg: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectName: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    trackDriveTitle: {
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280",
      },
      rejTitle: {
        fontSize: "30px",
        marginBottom: "30px",
        lineHeight: "40px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
    },
      closeicon: { display: "flex", justifyContent: "flex-end", margin: "20px 20px 0px 0px", color: "#475569" },
    mapTitleContainerText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    uploadTitle: {
        fontSize: "18px",
        margin: "10px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 400,
        lineHeight: "24px",
    },
    productDflex: {
        display: "flex",
        gap: 10
    },
    close: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
        width: '252px',
        height: "54px",
        border: '1px solid #CCBEB1',
        backgroundColor: 'transparent',
        marginTop: "16px",
        boxShadow: 'none',
    },
    tabeLableReadyForCollection: {
        color: "rgb(55, 82, 128)",
        fontSize: '20px',
        position: "absolute",
        height: '50vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: "50%",
        left: "50%",
        transform: "translate(20%, 20%)",
        fontWeight: 800,
        fontFamily: 'Lato',
    },
    productIMg: {
        width: '34px',
        borderRadius: '2px',
        height: '34px',
        marginBottom: "12px",
    },
    refundTitle: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 700,
    },
    tableHaderrowOut: {
        color: "#375280",
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: "Lato",
        verticalAlign: 'top',
    },
    tableHaderrowOut1: {
        verticalAlign: 'top',
        fontFamily: "Lato",
        fontSize: '16px',
        fontWeight: 400,
        color: "#375280",
    },
    ListTitle: {
        fontSize: "18px",
        margin: "10px",
        fontFamily: "Lato",
        color: "#375280",
    },
    refTitle: {
        fontSize: "30px",
        marginBottom: "30px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
    },
    confirm: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#FFFFFF",
        fontWeight: 500,
        width: '252px',
        height: "54px",
        backgroundColor: "#CCBEB1",
        marginTop: "16px",
        boxShadow: 'none',
    },
    tableHaderrowOut2: {
        fontWeight: 400,
        color: "#375280",
        fontSize: '16px',
        verticalAlign: 'top',
        fontFamily: "Lato",
    },
    trackContentStatusContainer: {
        padding: '10px 25px 80px 25px'
      },
      uploadBtn: {
        fontSize: "18px",
        fontFamily: "Lato",
        color: "#375280",
        fontWeight: 500,
        width: "520px",
        height: "54px",
        border: "1px solid #CCBEB1",
        marginTop: "16px",
    },
    paginationMainBox: {
        justifyContent: 'space-between',
        display: 'flex',
        margin: "23px 0px",
        alignItems: 'center',
    },
    CircularProgressColor: {
        color: "#375280"
    },
    mapHeaderText: {
        padding: 25
      },
    tabsBoxReady: {
        paddingBottom: '20px',
        width: '100%',
        alignItems: 'center',
        height: '75%',
        justifyContent: 'center',
        display: 'flex',
      },
    paginationSecBox: {
        fontSize: "14px",
        color: "#475569",
        fontFamily: 'Lato',
        fontWeight: 500,
    },
    MapComponentContainerTreack: {
        width: 440,
        height: "100%"
      },
    tableHaderrowOut3: {
        fontSize: '16px',
        verticalAlign: 'top',
        fontFamily: "Lato",
        fontWeight: 400,
        color: "#375280",
    },
    tableHaderCase: {
        cursor: 'pointer', padding: '16px 20px', width: "fit-content"
    },
    tableHaderCaseText:{
        fontSize: '14px', lineHeight: '18px', fontFamily: "Lato", fontWeight: 500, WebkitTextStrokeWidth: "0.5px", letterSpacing: "0.5px", cursor: 'pointer', width: "max-content"
    },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
}
const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    "& li:last-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    "& li:first-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .Mui-selected': {
        color: 'white',
    },
}));

const StyledButtonModel = styled(Button)(() => ({
    fontWeight: 500,
    color: "#375280",
    border: "1px solid #CCBEB1",
    textTransform: "none",
    width: "252px !important",
    height: "45px",
    borderRadius: "2px",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1Model = styled(Button)(() => ({
    fontWeight: 500,
    color: "#fff",
    width: "252px",
    height: "45px",
    textTransform: "none",
    backgroundColor: '#CCBEB1',
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomSelectNewOrderReturn = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '& fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelectNewOrderReturn = styled(Select)({
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',
    },
})
const CustomStyledTable = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },
"& .table-header-typo":{
    color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px", display: "flex"
},
"& .table-body-typo":{
    color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400, display: "flex"
},
    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})
const StyledDrawer = styled(Modal)((theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: "none",
}));
const CustomSelect = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelect = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
})
const InputField = styled(TextField)({
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#FFFFFF",
        }
    },
    "& .MuiInputBase-root": {
        background: 'rgb(248, 248, 248)',
        fontSize: "16px",
        height: '104px',
        borderRadius: "10px",
        width: '100%',
        margin: "auto",
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        },
        "&:not(:placeholder-shown)": {
            color: "#1E293B"
        }
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1"
    }
});

const InputFieldNew = styled(TextField)({
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderColor: "#FFFFFF",
        borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: "none"
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderWidth: "1px",
            borderColor: "#FFFFFF",
        }
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        },
        "&:not(:placeholder-shown)": {
            color: "#1E293B"
        }
    },
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiInputBase-root": {
        width: '100%',
        fontSize: "16px",
        margin: "auto",
        background: 'rgb(248, 248, 248)',
        borderRadius: "2px",
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FFFFFF",
        borderRadius: "8px",
        borderWidth: "1px",
    }, 
    
});

const NoRecordTypoContainer = styled(Typography)({
    textAlign: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    fontSize: 20,
    color: "#375280",
});

const ReturnReqTabBox = styled(Box)({ overflowX: "auto", width: "max-content", "@media only screen and (max-width: 720px)":{
    width: "100%"
} })
// Customizable Area End