import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from '../../../framework/src/StorageProvider';


import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18n from "../../../components/src/i18next/i18n";
import { ApiKey } from "../../../components/src/APICall";
import { ICountryResp } from "../../../components/src/CountryDropDown.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}

export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}
interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}
export interface CountrySuccess {
  numeric_code: string;
  country_full_name: string;
  country_code: string;
  country_flag: string;
}
export interface AddressValueType {
  value: string;
}
export interface Address {
  id: string;
  attributes: {
    address_name: string;
    is_default: boolean;
    name: string;
    phone_number: string;
    street: string;
    block: string;
    area: string;
    city: string;
    zipcode: string;
    contact_number:string;
    country_code:string;
  };
}
interface AddressAttributes {
  name: string;
  country_code: string;
  phone_number: string;
  contact_number: string;
  street: string;
  zipcode: string;
  area: string;
  block: string;
  city: string;
  house_or_building_number: string;
  floor: string | null;
  address_name: string;
  is_default: boolean;
  latitude: number;
  longitude: number;
}

interface AddressData {
  id: string;
  type: string;
  attributes: AddressAttributes;
}

interface UpdateAddressResponse {
  data: AddressData;
  meta: {
      message: string;
  };
}
interface DeleteAddressResponse {
  message: string;
}
type AlertType = 'success' | 'error' | 'warning' | 'info';

export const AdressTypeData = [
  {
    value: "Home", label: 'Home'
  },
  {
    value: "Work", label: 'Work'
  },
  {
    value: "Other", label: 'Other'
  },
];
// Customizable Area End

interface S {
  // Customizable Area Start
  isDeleteDialogOpen: boolean;
  addressToDelete: string | null;
  countrySuccess:Array<CountrySuccess>;
  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: string;
  isDrawerOpen:boolean;
  addressTypeValue: string;
  addressList: Array<AddressType> | null;
  isChecked: boolean;
  addressData: Address[];
  openDropdownId: string | null;
  deletedResponce:string;
  isAlert: boolean;
  alertMsg: string;
  alertType: AlertType;
  showaddressData:any;
  selectedCountry:CountrySuccess;
  isCountryData:string;
  updateAddresss:string;
  errors: { [key: string]: string };
  fullName:string;
  stylishbuyer: boolean;
  mapVisible: boolean;
  selectedLocation: { lat: number; lng: number } | null;
  currentLocation: { lat: number; lng: number } | null;
  mapsLoaded: boolean;
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AddressManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiAddAddressCallId: string = "";
  apiGetAllAddressCallId: string = "";

  // Customizable Area Start
  showAddressCallId: string = "";
  getAddressCallId: string = "";
  deleteAddressCallID: string = "";
  countryCodeAPIEditCallId:string='';
  updateApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
          ];

    this.state = {
      errors: {},
      isDeleteDialogOpen: false,
      addressToDelete: null,
      txtInputAddressValue: "",
      txtInputLatValue: "",
      txtInputLngValue: "",

      addressTypeValue: AdressTypeData[0].value,
      addressList: null,
      token: "",
      isDrawerOpen:false,
      stylishbuyer: false,
      addressData:[],
      isChecked: false,
      openDropdownId: null,
      deletedResponce:"",
      isAlert: false,
      alertMsg: '',
      alertType: 'success' as AlertType,
      showaddressData: [],
      countrySuccess:[{
        numeric_code:"",
        country_full_name:"",
        country_code:"",
        country_flag:""
    }],
    updateAddresss:"",
    selectedCountry:{ country_code: "KW", numeric_code: "+965" ,country_full_name: "", country_flag: ""},
    isCountryData:"+965",
    fullName:'',
    searchQuery: "",
    mapVisible: false,
      selectedLocation: null,
      currentLocation: null,
      mapsLoaded: false,
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    
    const stylishbuyer = await storage.get('exploreBuyer', true);
    if(stylishbuyer) this.setState({stylishbuyer})
    super.componentDidMount();
    this.getAddress();
    this.getCountryCodeEditApi();
    this.getToken();
    let result=await storage.get("isAlert")
    let resultMsg=await storage.get("isAlertMsg")
    
    this.setState({isAlert:Boolean(result),alertMsg:resultMsg})
    storage.remove('isAlert')
  
    if (!document.getElementById("google-maps-script")) {
      const script = document.createElement("script");
      script.id = "google-maps-script";
      script.src = `https://maps.googleapis.com/maps/api/js?key=${ApiKey()}&libraries=places`;
      script.async = true;
      script.onload = this.handleScriptSuccess;
      document.body.appendChild(script);
    } else {
      this.handleScriptSuccess();
    }

    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const name = await getStorageData('user_data', true);
    this.setState({
      fullName: name.full_name
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (apiRequestCallId === this.getAddressCallId) {
        this.setState({
            addressData: responseJson.data,
          });
       
        }
       
          
            if (responseJson && !responseJson.errors) {
              this.apiSucessCall(apiRequestCallId, responseJson);
              this.apiShowAddress(apiRequestCallId, message);
          }
            
         this.manageUpdateResponce(apiRequestCallId,responseJson)
         this.manageDeleteResponce(apiRequestCallId,responseJson)
    } 
    
    // Customizable Area End
  }

   // Customizable Area Start

   apiShowAddress = (apiRequestCallId:string, message: Message) =>{
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.showAddressCallId) {
      const { attributes } = responseJson.data;
       
         let countryData: string = "";
         
         if (!attributes.country_code_name) {
           countryData = this.getCountryByCode(attributes.country_code);
         }
         this.setState((prevState)=>({
             showaddressData: responseJson.data.attributes,
             selectedCountry: {...prevState.selectedCountry, country_code: countryData!="" ? countryData : attributes.country_code_name, numeric_code: responseJson.data.attributes.country_code,country_full_name: prevState?.selectedCountry?.country_full_name ?? "",
               country_flag: "",},
             isChecked:responseJson.data.attributes.is_default
           }));           
         }
   }

   getCountryByCode = (code: string): string => {
    const country = this.state.countrySuccess.find((country) => country.numeric_code === code);
    return country ? country.country_code : "KW";
  };
   handleScriptSuccess = () => {
    this.setState({ mapsLoaded: true });
  
    const inputG = document.getElementById("autocomplete") as HTMLInputElement;
  
    if (inputG) {
      const autocomplete = new window.google.maps.places.Autocomplete(inputG);
      autocomplete.setFields(["geometry", "formatted_address"]);
  
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
  
        if (place.geometry && place.geometry.location) {
          const { lat, lng } = place.geometry.location;
  
        
          const fullAddress = place.formatted_address || ""; 
  
          this.setState((prevState) => ({
            selectedLocation: { lat: lat(), lng: lng() },
            currentLocation: { lat: lat(), lng: lng() },
            showaddressData: {
              ...prevState.showaddressData,
              latitude: lat(),
              longitude: lng(),
              street: fullAddress,
            },
            mapVisible: true,
          }));
        } else {
          console.error("No geometry found.");
        }
      });
    }
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start
  txtInputLatWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLatValue: text });
    },
    secureTextEntry: false,
  };

  txtInputLatMobileProps = {
    ...this.txtInputLatWebProps,
  };

  txtInputLatProps = this.isPlatformWeb()
    ? this.txtInputLatWebProps
    : this.txtInputLatMobileProps;

  txtInputLngWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLngValue: text });
    },
    secureTextEntry: false,
  };

  txtInputLngMobileProps = {
    ...this.txtInputLngWebProps,
  };

  txtInputLngProps = this.isPlatformWeb()
    ? this.txtInputLngWebProps
    : this.txtInputLngMobileProps;

  txtInputAddressWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputAddressValue: text });
    },
    secureTextEntry: false,
  };

  txtInputAddressMobileProps = {
    ...this.txtInputAddressWebProps,
  };

  txtInputAddressProps = this.isPlatformWeb()
    ? this.txtInputAddressWebProps
    : this.txtInputAddressMobileProps;

  dropdownAddressTypeWebProps = {
    onChangeText: (value: string) => {
      this.setState({ addressTypeValue: value });
    },
  };

  dropdownAddressTypeMobileProps = {
    ...this.dropdownAddressTypeWebProps,
  };

  dropdownAddressTypeProps = this.isPlatformWeb()
    ? this.dropdownAddressTypeWebProps
    : this.dropdownAddressTypeMobileProps;

  handlePressAdd = () => {
    this.props.navigation.navigate("AddAddress");
  };

  handleNavigation = (redirect: string) => {
    const userNavMsgTo: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsgTo.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsgTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsgTo);
  }

  handleSavePressed() {
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.state.token,
    };

    const attrs = {
      latitude: this.state.txtInputLatValue,
      longitude: this.state.txtInputLngValue,
      address: this.state.txtInputAddressValue,
      address_type: this.state.addressTypeValue,
    };

    const httpBody = {
      address: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addAddressAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getNavigationMessage = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SuccessAddress' 
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
     
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  getAddress = async () => {
    const token = await getStorageData("auth-token");
    const header = {
        "Content-Type": configJSON.addAddressApiContentType,
        token: token,
    };
    const requestMessageList = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddressCallId = requestMessageList.messageId;
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpMethodType
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addAddressEndPoint
    );
    requestMessageList.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  };
  
    showAddress=async ( addressID:string| number)=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.showAddressCallId = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_address/addresses/${addressID}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `GET`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        
    }
  DeleteAddress=async ( addressID: string |number)=>{
    const token = await getStorageData("auth-token");
    const header = {
        token: token,
    };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteAddressCallID = requestMessage.messageId;
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_address/addresses/${addressID}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          `DELETE`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        const updatedAddressData = this.state.addressData.filter(address => address.id !== addressID);
        this.setState({ addressData: updatedAddressData });
    }
    oncloseAlert = () => {
      this.setState({ isAlert: false });
  };
  getCountryCodeEditApi = async () => {
    this.countryCodeAPIEditCallId = await this.apiCallEditForgotPass({
        contentType: configJSON.countryApiContentType,
        method: configJSON.httpMethodType,
        endPoint: configJSON.countryCodeEndPoint
    });
  };
  apiCallEditForgotPass = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
        "Content-Type": contentType,
    };
    const forgotRequestMessage1 = new Message(getName(MessageEnum.RestAPIRequestMessage));
    forgotRequestMessage1.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  
    body &&
    forgotRequestMessage1.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
    );
    
    forgotRequestMessage1.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    runEngine.sendMessage(forgotRequestMessage1.id, forgotRequestMessage1);
    return forgotRequestMessage1.messageId;
  };
  apiSucessCall = async (apiRequestCallId: string, responseJson: CountrySuccess[]) => {
    if (apiRequestCallId === this.countryCodeAPIEditCallId) {
        this.setState({
            countrySuccess: responseJson,
        }, async()=>{
          const addressID = await storage.get('addressID')
          this.showAddress(addressID)
    });
    }
   
};

handleEditAddressChange = (event: ICountryResp) => {
  this.setState((prevState) => ({
    selectedCountry: {...prevState.selectedCountry, country_code: event.country_code, numeric_code: event.numeric_code,country_full_name: prevState?.selectedCountry?.country_full_name ?? "",
      country_flag: prevState?.selectedCountry?.country_flag ?? "",},
    showaddressData: {
      ...prevState.showaddressData,
      country_code: event.country_code, numeric_code: event.numeric_code,
    },
    isCountryData: event.numeric_code,
  }));
};

getNavigationAddAddress = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'AddAddress'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
   
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}
getNavigationEditAddress = () => {
  const message: Message = new Message(getName(MessageEnum.NavigationMessage))
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    'EditAddress'
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
   
  });
  message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
  this.send(message);
}

updateSubmit=async ()=>{
  const addressID = await storage.get('addressID')
  const token = await getStorageData("auth-token");
  const header = {
      token: token,
  };
    const formData = new FormData();
      formData.append("name", this.state.showaddressData.name);
      formData.append("area", this.state.showaddressData.area);
      formData.append("block", this.state.showaddressData.block);
      formData.append("street",this.state.showaddressData.street);
      formData.append("house_or_building_number", this.state.showaddressData.house_or_building_number);
      formData.append("zipcode", this.state.showaddressData.zipcode);
      formData.append("city", this.state.showaddressData.city);
      formData.append("country_code", this.state.showaddressData.numeric_code);
      formData.append("phone_number", this.state.showaddressData.phone_number);
      formData.append("latitude", this.state.showaddressData.latitude ? String(this.state.showaddressData.latitude) : "");
      formData.append("longitude", this.state.showaddressData.longitude ? String(this.state.showaddressData.longitude) : "");
      formData.append("address_name", this.state.showaddressData.address_name);
      formData.append("is_default", this.state.showaddressData.is_default);
      formData.append("country_code_name", this.state.selectedCountry.country_code);
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateApiCallId = requestMessage.messageId;
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_address/addresses/${addressID}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PUT`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

 handleInputChange = (event :React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  this.setState((prevState) => ({
    showaddressData: {
      ...prevState.showaddressData,
      [name]: value
    }
  }));
};
handleToggle = () => {
  if(this.state.isChecked){
    this.setState({ isAlert: true, alertMsg: this.tranAddress('Please select a different address as a default'), alertType: 'error'});

  }
  else{

    this.setState((prevState) => ({
      showaddressData: {
        ...prevState.showaddressData,
        is_default: !prevState.showaddressData.is_default 
      },
      // isChecked: !prevState.isChecked, 
    }));
  }
};

toggleDropdown1 = (addressID: string) => {
  this.setState(prevState => ({
    openDropdownId: prevState.openDropdownId === addressID ? null : addressID,
  }));
};
manageUpdateResponce=async(apiRequestCallId:string,responseJson:UpdateAddressResponse)=>{
  if(apiRequestCallId === this.updateApiCallId){
    this.getAddress()
           this.setState({
             updateAddresss:responseJson.meta.message
           })
           if(this.state.updateAddresss === "Address Updated Successfully"){
             storage.set("isAlert",true)
             storage.set("isAlertMsg",'Address Updated successfully')
             
             this.setState({ isAlert: true, alertMsg: this.tranAddress('Address Updated successfully'), alertType: 'success'});
             this.getNavigationAddAddress()

           }
           if(responseJson?.data?.attributes){
            const { latitude, longitude } = responseJson?.data?.attributes
            await setStorageData("userLocation",JSON.stringify({lat: latitude, lng:longitude}))
           }
          }}
  
manageDeleteResponce=(apiRequestCallId:string,responseJson:DeleteAddressResponse)=>{
    if (apiRequestCallId === this.deleteAddressCallID) {
            this.setState({
                deletedResponce: responseJson.message,
              });
            if(this.state.deletedResponce==="Address deleted succesfully!"){
            this.setState({ isAlert: true, alertMsg: this.tranAddress('Address deleted successfully'), alertType: 'success'});
          }
            }}
handleMakeDefault = (addressID: {id: string, attributes: AddressAttributes | {is_default: boolean}}) => {
  const updatedAddresses = this.state.addressData.map((address: Address) => {
    if (address.id === addressID.id) {
      return { ...address, attributes: { ...address.attributes, is_default: true } };
    } else {
      return { ...address, attributes: { ...address.attributes, is_default: false } };
    }
  });
  this.setState({
    showaddressData: {...addressID.attributes,is_default:true},
  });
this.updateSubmit()
  this.setState({
    addressData: updatedAddresses,
    openDropdownId: null, // Close the dropdown after making default
  });
};

handleOpenDeleteDialog = (addressID: string) => {
  this.setState({ isDeleteDialogOpen: true, addressToDelete: addressID });
};

handleCloseDeleteDialog = () => {
  this.setState({ isDeleteDialogOpen: false, addressToDelete: null });
};

handleConfirmDelete = () => {
  this.DeleteAddress(this.state.addressToDelete?? "");
  this.handleCloseDeleteDialog();
};  

handleSubmit = async () => {

  const isValid = this.validateForm();
  if (isValid) {
    await this.updateSubmit();
  }
};
validateForm = () => {
  const { showaddressData } = this.state; // Assuming zipcode is a property of showaddressData
  const errors: { [key: string]: string } = {};

  // List of required fields
  const requiredFields = [
    { name: "name", message: this.tranAddress("*Please enter a valid full name") },
    { name: "area", message: this.tranAddress("*Please enter a valid Area") },
    { name: "block", message: this.tranAddress("*Please enter a valid Block") },
    { name: "street", message: this.tranAddress("*Please enter a valid Street") },
    { name: "house_or_building_number", message: this.tranAddress("*Please enter a valid House number") },
    { name: "zipcode", message: this.tranAddress("*Please enter a valid Zipcode") },
    { name: "city", message: this.tranAddress("*Please enter a valid City") },
    { name: "country_code", message: this.tranAddress("*Please enter a valid Country code") },
    { name: "phone_number", message: this.tranAddress("*Please enter a valid Phone number") },
    { name: "latitude", message: this.tranAddress("*Please enter a valid Latitude") },
    { name: "longitude", message: this.tranAddress("*Please enter a valid Longitude") },
    { name: "address_name", message: this.tranAddress("*Please enter a valid Address name") }
  ];

  requiredFields.forEach(field => {
    const value = showaddressData[field.name as keyof typeof showaddressData];
    if (typeof value === "string" && value.trim() === "") {
      errors[field.name] = field.message;
    }
  });

  this.setState({ errors });

  // Check if zipcode exists and is not empty
  const zipcode = showaddressData.zipcode;
  if (zipcode && !/^\d+$/.test(zipcode)) {
    errors.zipcode = this.tranAddress("*Please enter a valid numeric Zipcode");
  }
  const city = showaddressData.city;
  if (city && /\d/.test(city)) { // Check if the city contains any digits
    errors.city = this.tranAddress("*Please enter valid city name");
  }

  // Return true if there are no errors
  return Object.keys(errors).length === 0;
};

tranAddress = (transKey: string) => {
  return i18n.t( transKey, {ns: "address"});
}


    // Customizable Area End
}
