import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { MyStoreAPIResponse, MyStoreList } from "./MySellerStoreController";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { ErrorMessage } from "./CreateSellerStoreController";
import { apiCall } from "../../../components/src/APICall";
import { logoutSellerNavigation } from "../../../components/src/Seller/logOut";
import i18n from "../../../components/src/i18next/i18n";

export interface ErrorMessageResponse {
  error: string;
  errors?: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  myStoreResp: MyStoreList[];
  isLoader: boolean;
  alert: {
    message: string;
    isAlert: boolean;
    severity: "error" | "warning" | "info" | "success";
  };
  search: string;
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SellerStoreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  private debounceTimeout: NodeJS.Timeout | null = null;
  myStoreAPICallId: string = "";
  storeStatusUpdateAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      myStoreResp: [],
      isLoader: true,
      alert: {
        isAlert: false,
        message: "",
        severity: "success",
      },
      search: "",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && !responseJson.error) {
        this.apiSuccess(apiRequestCallId, responseJson);
      } else if (responseJson && (responseJson.errors || responseJson.error)) {
        this.apiFailure(responseJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  debounceTime(func: (...args: any[]) => void, delay: number) {
    return (...args: any[]) => {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  handleSearchInventory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      search: query,
    }));
    this.debounceTime(() => this.getMyStoreApi(query), 800)();
  };

  async componentDidMount() {
    // Customizable Area Start
    this.getMyStoreApi("");
    // this.handleTokenData();
    // Customizable Area End
  }

  apiSuccess = async (
    apiRequestCallId: string,
    responseJson: MyStoreAPIResponse
  ) => {
    if (apiRequestCallId === this.myStoreAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          myStoreResp: this.sortById(responseJson.data),
        }));
      }
    }
    if (apiRequestCallId === this.storeStatusUpdateAPICallId) {
      if (responseJson) {
        this.setState((prevState) => ({
          ...prevState,
          myStoreResp: this.sortById(responseJson.data),
          alert: {
            ...prevState.alert,
            message: "Store Status Update Successfully",
            isAlert: true,
            severity: "success",
          },
        }));
      }
    }
  };

  apiFailure = (responseJson: ErrorMessage & ErrorMessageResponse) => {
    if (responseJson.error) {
      this.setState((prevState) => ({
        ...prevState,
        alert: {
          ...prevState.alert,
          message: responseJson.error,
          isAlert: true,
          severity: "error",
        },
      }));
    } else if (responseJson.errors[0].token) {
      this.setState((prevState) => ({
        ...prevState,
        alert: {
          ...prevState.alert,
          message: responseJson.errors[0].token as string,
          isAlert: true,
          severity: "error",
        },
      }), () => {
        setTimeout(() => {
          this.handleCreateStore("Home");
          logoutSellerNavigation();
        }, 2000);
      });
    }
  };

  getMyStoreApi = async (search: string) => {
    this.setState((prevState)=>({...prevState, isLoader: true}))
    this.myStoreAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.sellerMyStoreEndPoint}?search=${search}`,
      token: await this.getTokenData(),
    });
  };

  getTokenData = async () => {
    return await getStorageData("auth-token");
  };

  handleCreateStore = (redirect: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      redirect
    );
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  handleStoreStatus = async (id: string, status: boolean) => {
    this.storeStatusUpdateAPICallId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpPutMethod,
      endPoint: `${
        configJSON.storeStatusUpdateEndPoint
      }id=${id}&status=${!status}`,
      token: await this.getTokenData(),
    });
  };

  sortById = (items: MyStoreList[]): MyStoreList[] => {
    const newItems = [...items].sort((a, b) => parseInt(b.id) - parseInt(a.id));
    this.setState({
      isLoader: false
    })
    return newItems 
  };

  handleRedirect = (path: string) => {
    const userNavMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    userNavMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsg);
  };

  navigateToPage = async (storeId: string) => {
    await setStorageData("store-id", storeId);
    this.handleRedirect("SellerStoreProfile");
  };

  onCloseAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      alert: {
        ...prevState.alert,
        isAlert: !prevState?.alert.isAlert,
      },
    }));
  };

  transSellerStore = (sellerKey: string) => {
    return i18n.t(sellerKey, { ns: "store" })
  }
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
