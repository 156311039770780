import React from "react";

// Customizable Area Start
import {
  styled, Grid,
  Box, TableContainer, Table, TableBody, TableCell, TableHead, CircularProgress, Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import OutForDeliveryControllerDetail, {
  Props,
} from "./OutForDeliveryControllerDetail.web";
import { Noimageavailablepng, backBlueArrowImg } from "./assets";
import moment from "moment";
import i18n from "../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  }
});

export default class OutForDeliveryDetail extends OutForDeliveryControllerDetail {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  outForDeliveryRole = () => {
    const { roleData } = this.state;
    switch (roleData) {
        case "seller":
            return "Seller";
        case "stylist":
            return "Stylish";
        default:
            return "";
    }
};

handleOutForDeliveryDetailsImgCSS = () =>{
  return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
}

marginAutoOutForDeliveryCSS = () => {
  return i18n.dir()==="ltr" ? "0px 0px 0px auto" : "0px auto 0px 0px"
}
  // Customizable Area End

  render() {
    // Customizable Area Start 
    const orderDetailData = this.state.SellerOrdersProcessedView.find(
        (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
            order.attributes.order_management_order.attributes.order_number === this.state.productDetailId
    );
    return (
        <ThemeProvider theme={theme}>
            <SellerStylishContainer role={this.outForDeliveryRole()} navigation={this.props.navigation}>
                    {this.state.isLoadingOut ? (

                        <Box style={{ ...webStyle.tabsBoxReady }}>
                            <CircularProgress style={webStyle.CircularProgressColor} />
                        </Box>

                    ) :
                        <><Box style={webStyle.readyForCollectionTableContainer}>
                            <GroupDetailBox>
                                <Box display="block" alignItems="center">
                                    <Box className="GroupDetailBoxLeft">
                                        <Box data-testid="proceedToAllOrderPage" id="proceedToAllOrderPage" onClick={this.proceedToAllOrderPage}>
                                            <img src={backBlueArrowImg} style={{cursor: "pointer", transform: this.handleOutForDeliveryDetailsImgCSS()}} alt="" />
                                        </Box>
                                        <Typography className="itemIdText" id="order_number" data-testid="order_number">
                                            #{orderDetailData?.attributes.order_management_order.attributes.order_number}
                                        </Typography>
                                        <button className="outForDeliveryStatusTopBtn" style={{margin: this.marginAutoOutForDeliveryCSS()}}>{this.tranOutForDeliveryDetail("Out for Delivery")}</button>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems="center" style={{margin: this.marginAutoOutForDeliveryCSS()}}>
                                    <button className="readyForStatusButton">{this.tranOutForDeliveryDetail("Order Status")}</button>
                                </Box>
                            </GroupDetailBox>
                            <Box style={webStyle.ArrivingOutCell}> 
                                      {orderDetailData?.attributes.order_items.map((items) => (
                                        <>
                                          <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>{items.attributes.driver_name}</Typography>
                                        </>
                                      ))}
                                    <Typography variant="body1" style={webStyle.ArrivingTextOut as React.CSSProperties}>{this.tranOutForDeliveryDetail("Delivering in")}: <span style={webStyle.ArrivingTextOutValue}>14 {this.tranOutForDeliveryDetail("mins")}</span></Typography>
                                 </Box>
                            <GroupOtherDetails className="container">
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                            {this.tranOutForDeliveryDetail("Store Name")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_items[0].attributes.store_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                        {this.tranOutForDeliveryDetail("Customer Name")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.account}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                        {this.tranOutForDeliveryDetail("Delivery Date")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">{moment(orderDetailData?.attributes.estimated_arrival_time as string).format("D MMM, YYYY")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                        {this.tranOutForDeliveryDetail("Shipping Address")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">
                                            {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.house_or_building_number}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.street},{orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.block} {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.area}, {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.city} - {orderDetailData?.attributes.order_management_order.attributes.delivery_addresses.attributes.zip_code}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                        {this.tranOutForDeliveryDetail("Coupon Applied")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">{this.tranOutForDeliveryDetail("Fash")}10(-$5)</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Typography className="detailTitle">
                                        {this.tranOutForDeliveryDetail("Payment Method")}
                                        </Typography>
                                        <Typography className="detailTitleDescription">{orderDetailData?.attributes.order_management_order.attributes.payment_detail?.payment_type}</Typography>
                                    </Grid>
                                </Grid>
                            </GroupOtherDetails>

                            <CustomStyledTable className="container">
                                <Table stickyHeader size="small" className="custom-table">
                                    <TableHead>
                                        <TableRowCustom style={webStyle.tableHeaderBg}>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Product")}</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Brand")}</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Size")}</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Color")}</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Product Code")}</Typography></TableCell>
                                            <TableCell style={webStyle.tableHaderReady}><Typography variant="h6" className="headeText">{this.tranOutForDeliveryDetail("Price")}</Typography></TableCell>
                                        </TableRowCustom>
                                    </TableHead>
                                    <TableBody>
                                        {orderDetailData?.attributes.order_items?.map((order, index: number) => (
                                            <React.Fragment>
                                                <TableRowCustom>
                                                    <TableCell>
                                                        <Box style={webStyle.productDflex}>
                                                            <Box><img src={order.attributes.catalogue_variant_front_image ? order.attributes.catalogue_variant_front_image : Noimageavailablepng} style={webStyle.productIMg} /></Box>
                                                            <Box style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Typography className="tableBodyTypo">{order.attributes.catalogue_name}</Typography></Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Typography className="tableBodyTypo">{order.attributes.brand_name}</Typography></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Typography className="tableBodyTypo">{order.attributes.catalogue_variant_size}</Typography></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Box style={webStyle.tableVarientColorMainBoxReady}>
                                                        <Box style={{ ...webStyle.tableVarientColorBoxReady, backgroundColor: order.attributes.catalogue_variant_color }} /> <Typography>{order.attributes.catalogue_variant_color}</Typography>
                                                    </Box></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Typography className="tableBodyTypo">{order.attributes.catalogue_variant_sku}</Typography></TableCell>
                                                    <TableCell style={{ ...webStyle.tableHaderrowReady }} className="tableRow"><Typography className="tableBodyTypo">{order.attributes.currency_logo} {order.attributes.total_price}</Typography></TableCell>
                                                </TableRowCustom>
                                            </React.Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </CustomStyledTable>
                        </Box></>
                    }
                </SellerStylishContainer>
        </ThemeProvider> 
    ); 
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
    fontFamily: "Lato",
  tableHaderReady: {
    fontSize: '16px', 
    color: "#375280",
    fontWeight: 700, 
    letterSpacing: "1px",
    WebkitTextStrokeWidth: "0.5px", 
  },
  ArrivingTextOut: {
    color: "#375280",
    fontWeight: 400,
    fontSize: "14px",
  },
  ArrivingTextOutValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  tableHaderrowReady: {
    fontSize: '16px', 
    color: "#375280", 
    fontWeight: 400,
  },
  tableVarientColorBoxReady: {
    width: '14px', height: '14px', borderRadius: '10px'
  },
  tableVarientColorMainBoxReady: {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  tableHeaderBg: {
    backgroundColor: "#f0f0f0"
  },
  ArrivingOutCell: {
    backgroundColor: '#F4F4F4',
    padding: '12px 16px',
    justifyContent: "space-between",
    display: "flex",
  },
  productDflex: {
    display: "flex",
    alignItems:"center",
    gap: 10
  },
  productIMg: {
    width: '34px',
    height:'34px',
    borderRadius:'2px',
    marginRight:"10px"
  },
  ArrivingCell: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between"
  },
  ArrivingCell2: {
    padding: '12px 16px',
    backgroundColor: '#F4F4F4',
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  ArrivingText: {
    fontWeight: 400,
    color: "#375280",
    fontSize: "14px",
  },
  ArrivingTextValue: {
    fontWeight: 700,
    color: "#375280",
    fontSize: "14px",
    marginLeft: "8px"
  },
  readyForCollectionTableContainer: {
    padding: "20px 30px"
  },
  tabsBoxReady: {
    width: '100%',
    paddingBottom: '20px',
    height: '75%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  CircularProgressColor: {
    color: "#375280"
  },
  itemIdTextDescription: {
      color: "#9A9A9A",
      fontSize: "16px",
      fontWeight: 500,
      marginLeft: "32px"
  }
}
const GroupOtherDetails = styled(Box)({
      border: '1px solid #E2E8F0',
      borderRadius: "5px 5px 0px 0px",
      padding: "24px",
      margin: "25px 0px",
      "& .detailTitle": {
        color: "#375280", 
        fontSize: "16px", 
        fontWeight: 400, 
      },
      "& .detailTitleDescription": {
        color: "#94A3B8", 
        fontSize: "16px", 
        fontWeight: 400,
        marginTop: "10px"
      }
  })
const CustomStyledTable = styled(TableContainer)({
  "& .radyToOrderStatusBtn": {
    width: "147px",
    color: "rgb(55, 82, 128)",
    border: "1px solid rgb(204, 190, 177)",
    fontWeight: 700,
    height: "34px",
    fontSize: "12px",
    backgroundColor: "#ffffff",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .MuiTableCell-sizeSmall': {
    padding: "15px 30px 15px 30px"
  },
  '& .custom-table': {
    borderRadius: "5px 5px 0px 0px",
    border: '1px solid #E2E8F0',
  },
  "& .radyToCollectionStatusBtn": {
    width: "147px",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#FEF3C7",
    textTransform: "none",
    borderRadius: "2px",
  },
  "& .radyToArrivingBox": {
    width: "100%",
    color: "#D97706",
    height: "34px",
    border: "none",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "#F4F4F4",
    textTransform: "none",
    borderRadius: "2px",
  },
  '& .cell': {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: '#ffffff',
  },
})
const TableRowCustom = styled(Table)({
  "& .headeText": {
    fontFamily: "Lato",
    "@media only screen and (max-width: 900px)": {
      fontSize: "15px",
      fontFamily: "Lato",
    },
    display: "flex"
  },
  "& .tableRow": {
    "@media only screen and (max-width: 900px)": {
      fontSize: "14px !important"
    },
    "& .tableBodyTypo":{
      display: "flex",
      fontSize: "14px !important",
      fontFamily: "Lato",
    }
  }
})
const GroupDetailBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0px",
    fontFamily: "Lato",
    flexWrap: "wrap",
    gap: 20,
    "& .GroupDetailBoxLeft": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        flexWrap: "wrap"
    },
    "& .itemIdText": {
        color: "#375280", 
        fontSize: "24px", 
        fontWeight: 700,
        marginLeft: "20px"
    },
    "& .readyForStatusButton": {
        color: "rgb(55, 82, 128)",
        width: "150px",
        border: "1px solid rgb(204, 190, 177)",
        height: "44px",
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "2px",
        backgroundColor: "#ffffff",
        textTransform: "none",
      },
    "& .readyForTrackButton": {
        color: "#ffffff",
        width: "150px",
        border: "none",
        height: "44px",
        fontWeight: 500,
        fontSize: "16px",
        borderRadius: "2px",
        backgroundColor: "#CCBEB1",
        textTransform: "none",
        marginLeft: "10px"
      },
    "& .outForDeliveryStatusTopBtn": {
        color: "#6200EA",
        width: "147px",
        border: "none",
        height: "30px",
        fontWeight: 500,
        textAlign: "center",
        fontSize: "12px",
        borderRadius: "2px",
        backgroundColor: "#F0E5FF",
        textTransform: "none",
    }
  })
// Customizable Area End
