import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { submit } from '@tap-payments/gosell';
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface AuthorizeRequest {
  id: string;
  object: string;
  customer_initiated: boolean;
  authorize_debit: boolean;
  live_mode: boolean;
  api_version: string;
  method: string;
  status: string;
  amount: number;
  currency: string;
  threeDSecure: boolean;
  save_card: boolean;
  product: string;
  transaction: {
    authorization_id: string;
    timezone: string;
    created: string;
    expiry: {
      period: number;
      type: string;
    };
    asynchronous: boolean;
    amount: number;
    currency: string;
  };
  reference: {
    track: string;
    payment: string;
    acquirer: string;
    gateway: string;
  };
  response: {
    code: string;
    message: string;
  };
  card_security: {
    code: string;
    message: string;
  };
  security: {
    threeDSecure: {
      id: string;
      status: string;
    };
  };
  acquirer: {
    response: {
      code: string;
      message: string;
    };
  };
  gateway: {
    response: {
      code: string;
      message: string;
    };
  };
  card: {
    id: string;
    object: string;
    first_six: string;
    first_eight: string;
    scheme: string;
    brand: string;
    last_four: string;
    name: string;
    expiry: {
      month: string;
      year: string;
    };
  };
  receipt: {
    id: string;
    email: boolean;
    sms: boolean;
  };
  customer: {
    id: string;
    first_name: string;
    email: string;
  };
  source: {
    object: string;
    type: string;
    payment_type: string;
    channel: string;
    id: string;
    on_file: boolean;
    payment_method: string;
  };
  redirect: {
    status: string;
    url: string;
  };
  post: {
    status: string;
    url: string;
  };
  auto: {
    status: string;
    type: string;
    time: number;
  };
  merchant: {
    country: string;
    id: string;
  };
  description: string;
  authentication: {
    id: string;
  };
  payment_agreement: {
    id: string;
    type: string;
    trace_id: string;
    total_payments_count: number;
    contract: {
      id: string;
      customer_id: string;
      type: string;
    };
    metadata: {
      txn_type: string;
      txn_id: string;
      terminal_id: string;
    };
  };
};
interface PaymentToken {
  id: number | null;
  status: string;
  created: number;
  object: string;
  live_mode: boolean;
  type: string;
  purpose: string;
  used: boolean;
  card: {
    id: string;
    object: string;
    on_file: boolean;
    address: {};
    funding: string;
    fingerprint: string;
    brand: string;
    scheme: string;
    category: string;
    exp_month: number;
    exp_year: number;
    last_four: string;
    first_six: string;
    first_eight: string;
    name: string;
    issuer: {
      bank: string;
      country: string;
      id: string;
    };
  };
  payment: {
    id: string;
    on_file: boolean;
    card_data: {
      exp_month: number;
      exp_year: number;
      last_four: string;
      first_six: string;
      first_eight: string;
      address: {};
    };
    fingerprint: string;
    scheme: string;
    category: string;
    issuer: {
      bank: string;
      country: string;
      id: string;
    };
  };
  merchant: {
    id: string;
  };
  client_ip: string;
};
interface CardListResponse {
  id: string;
  created: number;
  object: string;
  address: object;
  customer: string;
  funding: string;
  fingerprint: string;
  brand: string;
  scheme: string;
  name: string;
  exp_month: number;
  exp_year: number;
  last_four: string;
  first_six: string;
  first_eight: string;
};
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  isDrawerOpen:boolean;
  isSaveCard:boolean
  cardSaveData: {
    cardHolderName: string
  }[]
  // Customizable Area Start
  cards: PaymentToken[],
  anchor: null | string,
  currentCardId: number | null,
  autheCard: AuthorizeRequest[],
  profileName: string,
  open: boolean,
  autheAllCard: CardListResponse[],
  open1: boolean;
  carddeleteId: string;
  tabeLoder: boolean,
  isAlert: boolean,
  tostAlert: boolean,
  alertMsg: string,
  severity: 'error' | 'warning' | 'info' | 'success' | undefined
  baseUrl: string,
  stylishbuyer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedcardsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSavedCartId: string = "";
  getSavedCartGetId: string = "";
  getSavedAllCartGetId: string = "";
  getDeleteAllCart: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      severity: undefined,
      isDrawerOpen:false,
      enableField: false,
      isSaveCard:false,
      cardSaveData:[
        {
        cardHolderName:'Williams Michael'
      },
      {
        cardHolderName:'Williams'
      },
      {
        cardHolderName:'Hiren'
      }
    ]
      // Customizable Area Start
      , cards: [],
      anchor: null,
      currentCardId: null,
      profileName: "",
      open: false,
      autheCard: [],
      autheAllCard: [],
      open1: false,
      carddeleteId: "",
      tabeLoder: true,
      isAlert: false,
      tostAlert: false,
      alertMsg: "",
      baseUrl: "",
      stylishbuyer: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.submitCard = this.submitCard.bind(this);
    this.callbackFunc = this.callbackFunc.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSavedCartId !== null &&
      this.getSavedCartId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      window.location.href = responseJson.transaction.url
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSavedCartGetId !== null &&
      this.getSavedCartGetId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ autheCard: responseJson }, () => this.savedAllCardsGet())
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSavedAllCartGetId !== null &&
      this.getSavedAllCartGetId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ autheAllCard: responseJson.data, tabeLoder: false, isAlert: true, })
    }
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDeleteAllCart !== null &&
      this.getDeleteAllCart ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.savedAllCardsGet()
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgs = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgs.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgs);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  setSaveCard = () => {
    this.setState({ isSaveCard: !this.state.isSaveCard });
  };

  // Customizable Area Start
  handleNavigationSaveCard = (redirect: string) => {
    const userNavMsgSaveCard: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    userNavMsgSaveCard.addData(getName(MessageEnum.NavigationTargetMessage), redirect);
    userNavMsgSaveCard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(userNavMsgSaveCard);
  }

  submitCard () {
    submit()
  }
  formatCardNumber(number: string) {
    return i18n.dir()==='ltr' ? `**** **** **** ${number.slice(-4)}` : `${number.slice(-4)} **** **** ****` ;
  }
  handleMenuOpenCard = () => {
    this.setState({ open1: true })
  };
  handleMenuCloseCard = () => {
    this.setState({ open1: false })
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  savedCardUrl = async () => {
    const basePath = window.location.origin
    const params = new URLSearchParams(window.location.search);
    const tapId = params.get('tap_id');
    this.savedCardsGet(tapId)
    const profileHolder = await getStorageData("user_data")
    this.setState({ baseUrl: basePath, profileName: JSON.parse(profileHolder).first_name + " " + JSON.parse(profileHolder).last_name })
  }
  async componentDidMount() {
    this.savedCardUrl();
    const buyerStylish = await getStorageData('exploreBuyer', true);
    if(buyerStylish) this.setState({stylishbuyer: buyerStylish})
  }
  callbackFunc(response: PaymentToken) {
    this.setState({ tabeLoder: true, open: false })

    const isDuplicate = this.state.autheAllCard.some(
      (card) => card.fingerprint == response.card.fingerprint
    );
    if(!!isDuplicate) {
      this.setState({ tostAlert: true, tabeLoder: false, alertMsg: "This card already exists in your account", severity: "error" })
      return
    }
    const redirectUrl = `${this.state.baseUrl}/Savedcards`
    const bodyPost = {
      "token": response.id,
      "redirect_url": redirectUrl,
      "type": "VOID"
    }
    this.cardSimilarPost(bodyPost)
  }

  handleOpenMenu = (cardId: string) => {
    this.setState((prevState) => ({
      anchor: prevState.anchor === cardId ? null : cardId, carddeleteId: cardId
    }));
  };
  cardSimilarPost = async (bodyPost: { token: null | number, redirect_url: string, type: string }) => {
    const unique_token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: unique_token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSavedCartId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.authorizePost
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authorizeCard}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyPost)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  savedCardsGet = async (tapId: string | null) => {
    const unique_token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: unique_token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSavedCartGetId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.authorizeGet
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authorizeCardGet}=${tapId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  savedAllCardsGet = async () => {
    const unique_token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: unique_token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSavedAllCartGetId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.authorizeGet
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authorizeAllCardGet}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  deleteCard = () => {
    this.setState({ tostAlert: true, open1: false, tabeLoder: false, alertMsg: "Your card has been removed", severity: "success" })
    this.deleteCardsDelete()
  }
  oncloseAlert = () => {
    this.setState({ tostAlert: false, alertMsg: "", severity: undefined });
  };

  deleteCardsDelete = async () => {
    this.setState({ tabeLoder: true })
    const unique_token = await getStorageData("auth-token");
    const bodyDelete = { "card_id": this.state.carddeleteId }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: unique_token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteAllCart = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.authorizeCardDelete
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.authorizeAllCardDelete}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyDelete)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }

  transSavedCards = (transKey: string) => {
    return i18n.t( transKey, {ns: "card"})
  }
  // Customizable Area End
}
