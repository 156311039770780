import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import { Grid, styled,Snackbar,Modal,Fade, Box, CardContent, Breadcrumbs, Typography, Card, CardMedia, IconButton, Button } from "@material-ui/core";
import StylistQuoteController, {
    Props,
  } from "./StylistQuoteController.web";
import { whiteMessage as messageWhite } from "./assets"
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../components/src/i18next/i18n";
import OneToOneChat from "../../chat/src/OneToOneChat.web";
// Customizable Area End

export default class StylistQuote extends StylistQuoteController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    topPart = () => {
        const { isChatQ, productQuoteDetail, stylistId} = this.state
        const fullName = productQuoteDetail.attributes?.product_sourcing_stylist_prices.length && productQuoteDetail.attributes?.product_sourcing_stylist_prices?.find((item: {[key:string]: string}) => item.account_id === stylistId)?.full_name
        const isChat = isChatQ && !!fullName
        return (<BreadcrumbStyle>
            <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={i18n.dir() === "ltr" ? <KeyboardArrowRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}>
                <Typography color="inherit" data-testid="breadcrumb" onClick={() => this.handleNavigation("HiredStylist")}>
                {this.tranStylistQuote("Stylist")}
                </Typography>
                <Typography color="inherit" data-testid="closeChat" style={{ cursor: isChat ? 'pointer' : 'default' }} onClick={()=>this.handleChatQClick(false)}>{this.tranStylistQuote("Product Quotes")}</Typography>
                {isChat && <Typography color="inherit"> Chat with {fullName} </Typography>}
            </Breadcrumbs>
            <Box className="pageTitle">

                <IconButton className="iconButton" data-testid="iconButton" onClick={() => this.handleNavigation("HiredStylist")}>
                {i18n.dir() === "ltr" ? <KeyboardArrowLeftRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                </IconButton>
                <Typography className={i18n.dir() === "ltr" ? "text" : "text2"}>{isChat ? `Chat with ${fullName}`:this.tranStylistQuote("Product Quotes")}</Typography>
            </Box>
        </BreadcrumbStyle>)
    }
    StylistCard = () => {
        const { productQuoteDetail } = this.state;
        return (
            <CardContainer>
                <Box className="product-info">
                    <img className="product-image" src={productQuoteDetail.attributes?.images[0].url} alt="Product" />
                    <Box style={i18n.dir() === "ltr" ? {marginLeft: "16px"} : {marginRight: "16px"}} className="productName">
                    <Box className="title">{productQuoteDetail.attributes?.product_name}</Box>
                    <Box className="price">${productQuoteDetail.attributes?.min_price} - ${productQuoteDetail.attributes?.max_price}</Box>
                    </Box>
                </Box>
                {productQuoteDetail.attributes?.sizes && (
                    <Box className="detail-text">
                        {this.tranStylistQuote("Sizes")} - <span className="highlight">{productQuoteDetail.attributes.sizes.join(', ')}</span>
                    </Box>
                )}
                {productQuoteDetail.attributes?.sizes && (
                    <Box className="detail-text">
                        {this.tranStylistQuote("Colours")} - <span className="highlight">{productQuoteDetail.attributes.colours.join(', ')}</span>
                    </Box>
                )}
                <Box className="description">{productQuoteDetail.attributes?.description}
                </Box>
            </CardContainer>
        );
    };

    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { isAlert, severity, message, productQuoteDetail, stylistId, productId, isChatQ } = this.state;
        const { navigation } = this.props;
        return (
            <>
                <PageWrapper>
                    <Header navigation={navigation} />
                    <Snackbar
                    open={isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={this.oncloseAlert}
                    ><Alert variant="filled" severity={severity}>{message}</Alert></Snackbar>
                    <Grid dir={i18n.dir()} container style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            {this.topPart()}
                            <Grid container spacing={3}>
                                <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                    <LeftBox>
                                        {this.StylistCard()}
                                    </LeftBox>
                                </Grid>
                                
                                {isChatQ && (<Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                    <OneToOneChat navigation={this.props.navigation} receiver_id={stylistId}/>
                                </Grid>)}

                                {!isChatQ && <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                    {productQuoteDetail.attributes?.product_sourcing_stylist_prices.length ? productQuoteDetail.attributes?.product_sourcing_stylist_prices?.map((item: any, index: number) => {
                                        return <QuoteCard>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="subtitle1" className="quoteNumber">{this.tranStylistQuote("Quote")} {index + 1}</Typography>
                                                <Box className="imagesContainer">
                                                    {item.images.map((image:any) => {
                                                       return <CardMedia
                                                            component="img"
                                                            image={image.url}
                                                            alt="Style 1"
                                                            className="image"
                                                        />
                                                    })}
                                                </Box>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <CardContent className="cardContentContainer">
                                                    <Box className="namePriceContainer">
                                                        <Typography variant="h6">{item.full_name}</Typography>
                                                        <Typography variant="h6">
                                                            ${item.quote_price}
                                                        </Typography>
                                                    </Box>
                                                    <Typography variant="body2" className="description">
                                                        {item.product_description}
                                                    </Typography>
                                                    <Box className="buttonsContainer">
                                                    {item.request_status === "accepted" &&
                                                        <Box style={{ background: "#D0FAE4", color: "#1b9b72", width: "82%", fontFamily: "Lato", padding: "10px", textAlign: "center" }}>
                                                            {this.tranStylistQuote("Accepted")}
                                                        </Box>}
                                                    {item.request_status === "rejected" &&
                                                        <Box style={{ background: "#F29B9B", color: "#941A1D", width: "82%", fontFamily: "Lato", padding: "10px", textAlign: "center" }}>
                                                            {this.tranStylistQuote("Rejected")}
                                                        </Box>}
                                                    {item.request_status === "waiting" && 
                                                        <>
                                                        <Button variant="outlined" data-testid="reject" className="outlined" onClick={() => this.handleMenuOpenCard(item.request_id,item.id,"rejected")}>
                                                        {this.tranStylistQuote("Reject")}
                                                        </Button>
                                                        <Button variant="contained" data-testid="accept" className="contained" onClick={() => this.handleMenuOpenCard(item.request_id,item.id,"accepted")}>
                                                        {this.tranStylistQuote("Accept")}
                                                        </Button>
                                                        </>
                                                    }
                                                    <IconButton className="iconButton" data-testid="openChat" onClick={()=>{this.handleClickChat(item.account_id)}}>
                                                        <img src={messageWhite} className="icon" />
                                                    </IconButton>
                                                    </Box>
                                                </CardContent>
                                            </Grid>
                                        </Grid>
                                    </QuoteCard>
                                    }) : <StylistRow><div data-testid="noBids" className="nodata">There are no bids on your product quote yet</div></StylistRow> }
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.open}
                        closeAfterTransition
                        style={{ ...webStyle.menuDrawer }}
                        BackdropProps={{
                        timeout: 500,
                        }}
                    >
                        <Fade in={this.state.open} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <h2 id="transition-modal-title" style={{ width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px", fontFamily: "Lato" }}>Are you sure you want to {this.state.btnClicked === "accepted" ? "Accept" : "Reject"} this product quote??</h2>
                            <div style={{ marginTop: "40px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                            <Button style={{ ...webStyle.styleButtonCancel, textTransform: "none" }} data-testid="handleClose1" onClick={this.handleMenuCloseCard}>Cancel</Button>
                            <Button style={{ ...webStyle.styleButtonSave, textTransform: "none" }} data-testid="cancelOrder" onClick={() => this.acceptRejectQuote(stylistId, productId, this.state.btnClicked)}>Yes</Button>
                            </div>
                        </div>
                        </Fade>
                    </Modal>
                    <Footer navigation={navigation} />
                </PageWrapper>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const CardContainer = styled(Box)({
    borderRadius: "8px",
    fontFamily: 'Lato',

    "& .product-info": {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",

        "& .productName": {
            fontFamily: "Lato",
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "24px",
            textAlign: "left",
            color: "#375280",

            "& .title": {
                fontSize: "18px",
                fontFamily: "Lato",
                fontWeight: 700,
                marginBottom: "8px",
            },

            "& .price": {
                fontSize: "16px",
                fontWeight: 700,
                marginBottom: "12px",
            },
        }
    },

    "& .product-image": {
        width: "80px",
        height: "80px",
        borderRadius: "4px",
    },

    "& .detail-text": {
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#94A3B8",
        marginBottom: "10px",

        "& .highlight": {
            fontFamily: "Lato, sans-serif",
            fontSize: "16px",
            fontWeight: 900,
            lineHeight: "24px",
            textAlign: "left",
            color: "#94A3B8",
        },
    },

    "& .description": {
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textAlign: "left",
        color: "#94A3B8",
    },
});

const QuoteCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    padding: '16px 24px',
    marginBottom: theme.spacing(2),

    '& .quoteNumber': {
        fontFamily: 'Lato',
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '8px',
        color: " #375280"
    },

    '& .imagesContainer': {
        display: 'flex',
        gap: theme.spacing(1),
    },

    '& .image': {
        width: "100%",
        height: "150px",
        objectFit: 'cover',
        borderRadius: '4px',
    },

    '& .cardContentContainer': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: "16px"
    },

    '& .namePriceContainer': {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '26px',
        marginBottom: '12px',
        color: "#375280",
        "& h6": {
            fontFamily: 'Lato',
        }

    },

    '& .description': {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '8px',
        color: "#94A3B8", 
        minHeight: "55px",
    },

    '& .buttonsContainer': {
        boxSizing: "border-box",
        justifyContent: "space-between",
        alignItems: 'center',
        gap: '10px',
        display: "flex",
        '& .outlined': {
            border: '1px solid #CAB9A7',
            color: '#1C3E7F',
            backgroundColor: 'transparent',
            textTransform: 'none',
            padding: '8px 16px',
            fontSize: '16px',
            lineHeight: '24px',
            borderRadius: '5px',
            width: "40%",
            boxShadow: "none", 
            fontFamily: "Lato"
        },
        '& .contained': {
            backgroundColor: '#CAB9A7',
            color: '#FFFFFF',
            border: 'none',
            textTransform: 'none',
            padding: '8px 16px',
            fontSize: '16px',
            lineHeight: '24px',
            borderRadius: '5px',
            width: "40%",
            boxShadow: "none", 
            fontFamily: "Lato"
        },
        '& .iconButton': {
            backgroundColor: '#CAB9A7',
            borderRadius: '5px',
            textTransform: 'none',
            padding: '8px',
            fontSize: '16px',
            lineHeight: '24px',
            boxShadow: "none",
            "& img": {
                height: "24px",
                width: "24px"
            }
        },
    },
}));

const LeftBox = styled(Box)({
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    padding: "20px 30px",
    margin: "0 20px"
})

const PageWrapper = styled(Box)({
    background: "#F8F8F8"
})

const BreadcrumbStyle = styled(Box)({
    margin: "20px",
    "& .iconButton": {
        backgroundColor: '#fff',
        borderRadius: '50%',
        padding: '8px',
        marginRight: '8px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    },
    "& .pageTitle": {
        display: "flex",
        alignItems: "center",
        margin: "20px 0"
    },
    "& .breadcrumb": {
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        lineHeight: '22px',
        textDecoration: 'none',
        color: '#475569',
    },
    "& .text": {
        fontWeight: 600,
        fontFamily: 'Lato',
        fontSize: '18px',
        color: '#375280',
        marginLeft: "5px"
    },
    "& .text2": {
        fontWeight: 600,
        fontFamily: 'Lato',
        fontSize: '18px',
        color: '#375280',
        marginRight: "5px"
    },
    "& li": {
        cursor: "pointer"
    }
})


const StylistRow = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "18px 0",
    width: "100%",
   
    "& .nodata": {
        fontFamily: 'Lato',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: "#94A3B8",
        paddingTop: "10px",
        width: "100%"
    }
});

const webStyle = {
    styleButtonCancel: {
      fontWeight: 600,
      color: "#375280",
      fonSize: "14px",
      width: "150px",
      height: "45",
      border: "1px solid #CCBEB1",
      backgroundColor: '#ffff',
      '&:hover': {
        backgroundColor: "#fff"
      },
    },
    styleButtonSave: {
      fontWeight: 600,
      color: "#fff",
      fonSize: "14px",
      width: "150px",
      height: "45",
      backgroundColor: '#CCBEB1',
      '&:hover': {
        backgroundColor: "#CCBEB1"
      },
    },
    menuDrawer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: "none",
    }
  };

// Customizable Area End
