import React from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Typography, CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import SellersRejectedOrderController, {
    Props,
} from "../SellersRejectedOrder/SellersRejectedOrderController.web";
import { Noimageavailablepng } from "../assets";
import i18n from "../../../../components/src/i18next/i18n";
import { NotFoundContainer, PaginationContainer } from "../SellersAllOrder.web";
// Customizable Area End

export default class SellersRejectedOrderWeb extends SellersRejectedOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChangeRejected = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage, isLoading1: true }, ()=>this.allOrderDataRejected());
    };
    getPriceValueCurre = (currency: string, price: string) => {
        return `${currency} ${price}`
    } 
    renderContentRejected = (startIndexRejected: number, endIndexRejected: number) => {
        if (this.state.isLoadingInProcess) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBoxInProcess, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }
        if (this.state.tableAllOrderRejected === undefined && this.state.isAlert) {
            return (
                <NotFoundContainer>
                    {this.tranSellerRejectedOrder("There are no orders")}
                </NotFoundContainer>
            );
        }

        return (
            <>
                <CustomStyledTableOrderRejected className="container">
                <Box style={{ position: 'relative', ...webStyle.menBox, flexDirection: 'column' }}>
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHeadRejected>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                {this.state.tableHaderName.map((header: string, index: number) => (
                                    <TableCell key={index} style={webStyle.tableHaderRejected}>
                                        <Typography variant="h6" className="tableHeaderTxt">{this.tranSellerRejectedOrder(header)}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeadRejected>
                        <TableBody style={{ height: '100px', position: 'relative' }}>
                                {this.state.isLoading1 ?
                                    <TableRow data-testid="loader">
                                        <TableCell colSpan={this.state.tableHaderName.length} align="center"> <CircularProgress style={{ color: '#375280' }} />
                                    </TableCell></TableRow> :this.state.tableAllOrderRejected?.map((orderData) => (
                                <TableRow key={orderData.id} className="hover" >
                                    <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowRejectedTypo}}>#{orderData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowRejectedTypo}}>{this.dateFormetTime(orderData.attributes.order_items[0]?.attributes.placed_at)}</Typography></TableCell>
                                    <TableCell>{orderData.attributes.order_items.map((value) => {
                                        return (
                                            <Box style={{ display: 'flex', gap: 10 }} data-testid={`navigateTo=${orderData.attributes.order_management_order.attributes.order_number}`} onClick={() => this.navigateTo({ productId: orderData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "SellersRejectedOrderDetails" })}>
                                                <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px" }} /></Box>
                                                <Box style={{ ...webStyle.tableHaderrowRejected }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                            </Box>
                                        );
                                    })}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowRejectedTypo}}>{orderData.attributes.order_items[0]?.attributes.store_name}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowRejectedTypo}}>{this.getPriceValueCurre(orderData.attributes.order_items[0]?.attributes.currency_logo, orderData.attributes.order_items[0]?.attributes.total_price)}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrowRejected, verticalAlign: 'top' }}><Box style={{ ...webStyle.rejectedBox, textAlign: 'center' }}>{this.tranSellerRejectedOrder("Rejected")}</Box></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    </Box>
                </CustomStyledTableOrderRejected>
                <PaginationContainer>
                    <Box style={{ fontSize: "14px", fontWeight: 500, color: "#475569", fontFamily: 'Lato' }}>
                        {startIndexRejected} - {endIndexRejected} {this.tranSellerRejectedOrder("of")} {this.state.metaData?.total_record} {this.tranSellerRejectedOrder("results")}
                    </Box>
                    <CustomStyledPaginationRejected
                        onChange={this.handlePageChangeRejected}
                        count={this.state.metaData?.total_pages}
                        shape="rounded"
                        page={this.state.page}
                        color="primary"
                        dir={i18n.dir()}
                    />
                </PaginationContainer>
            </>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const endIndexRejected = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        const startIndexRejected = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box style={{ width: '-webkit-fill-available' }} data-testid="allOrder" dir={i18n.dir()}>
                    {this.renderContentRejected(startIndexRejected, endIndexRejected)}
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    pagenesonBoxRejected: { display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "23px 0px" },
    rejectedBox: { backgroundColor: '#FEE2E2', color: '#DC2626', padding: '6px 10px 6px 10px', borderRadius: '5px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tabeLableRejected: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
        left: "50%",
        transform: "translate(20%, 20%)",
        top: "50%",
    },
    loderBox:{top: '50%',left: '50%',transform: 'translate(-50%, -50%)',display: 'flex',justifyContent: 'center',alignItems: 'center'},
    menBox:{height: 'auto ', display: 'flex'},
    tableHaderrowRejected: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    tableHaderrowRejectedTypo: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400, display: "flex"
    },
    tableHaderRejected: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    tabsBoxInProcess: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
}
const TableHeadRejected = styled(TableHead)({
    "& .tableHeaderTxt":{
        fontFamily: "Lato"
    },
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    }
})
const CustomStyledPaginationRejected = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
    "& li:last-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    "& li:first-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    '& .Mui-selected': {
        color: 'white',
    },
}));

const CustomStyledTableOrderRejected = styled(TableContainer)({
    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px",
        "& .MuiTypography-root": {
            display: "flex"
        }
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    },
})
// Customizable Area End