import React from "react";
// more core import statements

// Customizable Area Start
import { Typography, Grid, Box, Button, CardMedia, CardContent, IconButton, styled, Card } from "@material-ui/core";
import { Vector, scroll, view } from "../src/assets";
import Sidebar from "../../../components/src/Sidebar";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Header from "../../../components/src/Header";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonIcon from '@material-ui/icons/Person';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import i18n from "../../../components/src/i18next/i18n";
import { handleImgUrl } from "../../../components/src/Chat/chat.web";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"
// Customizable Area End

import FavouriteStylistController, {
  Props,
  configJSON
} from "./FavouriteStylistController";


export default class BuyerStoreProduct extends FavouriteStylistController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleLikeImgCSS = () => {
    return i18n.dir() === 'ltr' ? { right: 15 } : { left: 15 }
  }
  renderFavouriteList() {
    if (this.state.wishlistData.length === 0 && !this.state.circularProgress) {
      return (
        <div style={{ display: "grid", placeItems: "center" }}>
          <div style={webStyle.innerContainer6}>
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <div style={webStyle.innerContainer5}>
                <img src={view} alt="View" />
              </div>
              <div style={{
                fontSize: '34px',
                fontWeight: 700, marginBottom: "14px", marginTop: "39px", color: "#375280", fontFamily: "Lato , sans-serif",
              }}>
                {this.tranFavorite("Your Stylist wishlist is empty.")}
              </div>
              <div style={webStyle.innerContainer7}>
                {this.tranFavorite("Empty Stylist Message")}
              </div>
              <Button
                style={webStyle.innerContainer8} data-test-id="continueShopping" onClick={() => this.headerNavigation("HiredStylist")}>
                {this.tranFavorite("Continue")}
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ margin: i18n.dir() === "ltr" ? "0px 0px 0px 4vw" : "0px 4vw 0px 0px" }}>
          <div style={{ color: "#375280", fontSize: "28px", fontWeight: 700, fontFamily: "Lato , sans-serif", marginTop: "85px", marginBottom: "50px" }}>
            {this.tranFavorite("Favorite Stylist")}
          </div>
          <Box style={{ display: "flex", flexDirection: "column", gap: 15 }}>
          </Box>
          <Grid container spacing={3}>
            
            {this.state.wishlistData.map((item) => (
              
              <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                console.log(item.attributes.profile_picture,"55555555")
                <StylishCard onClick={() => this.hiredStylistProfile({ productId: item.id, props: this.props, screenName: "HiredStylistProfile" })} data-test-id="hiredStylistProfile">
                  {item.attributes.profile_picture ? (
                    <CardMedia component="img" height="200" image={handleImgUrl(item.attributes.profile_picture)} alt={item.attributes.full_name} className="cardMedia" />
                  ) : (
                    <Box display="flex" justifyContent="center" alignItems="center" height="200" bgcolor={"#fff"} >
                      <PersonIcon style={{ fontSize: 80, color: "#999999" }} />
                    </Box>
                  )
                  }
                  <CardContent className="cardContent">
                    <Box className="content">
                      <Typography variant="h6" component="div" className="title">
                        {item.attributes.full_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className="description">
                        {item.attributes.bio}
                      </Typography>
                    </Box>
                    <IconButton className="favBtn" data-testid="favBtn" style={{ ...this.handleLikeImgCSS() }}
                      onClick={(event) => {
                        event.stopPropagation();
                        this.deleteFavouriteStylist(item.id);
                      }}
                    >
                      {item.attributes.is_favorite ? (
                        <FavoriteIcon fill="#FF0000" />
                      ) : (
                        <FavoriteBorderIcon fill="#375280" />
                      )}
                    </IconButton>
                  </CardContent>
                </StylishCard>
              </Grid>
            ))}
          </Grid>
        </div>
      );
    }
  }

  NavigationHeader = () => {
    return (
      <>
        <Box
          id="navigation-header"
          style={{ margin: "27px 40px 0 0", ...webStyle.innerContainer }}>
          <Typography 
            id="navigate-home" 
            style={webStyle.innerContainer1} 
            onClick={() => this.headerNavigation("LandingPage")}
          >
            {this.tranFavorite("Home")}
          </Typography>
          <img 
            id="navigation-icon" 
            src={Vector} 
            style={{ transform: i18n.dir() === 'ltr' ? 'rotate(0deg)' : 'rotate(180deg)' }} 
          />
          <Typography 
            id="navigate-stylist"
            style={webStyle.innerContainer2} 
          >
            {this.tranFavorite("Stylist")}
          </Typography>
        </Box>
      </>
    );
  }
  
  DrawerToggle = () => (
    <div 
      id="drawer-toggle" 
      style={{ marginLeft: window.innerWidth >= 768 ? "66%" : "42%", marginTop: window.innerWidth >= 768 ? "5%" : "7%" }}
    >
      <MoreVertIcon
        id="drawer-open-icon"
        onClick={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
      />
    </div>
  );
  
  DrawerContent = () => (
    <BuyerDrawer
      navigation={this.props.navigation}
      open={this.state.isDrawerOpen}
      onClose={() => this.setState((prevState) => ({ isDrawerOpen: !prevState.isDrawerOpen }))}
    >
      <div>
        <Typography 
          id="drawer-user-info"
          style={{ margin: "31px 40px 0 0", ...webStyle.innerContainer3 }} 
        >
          <img id="scroll-icon" src={scroll} />
          {this.state.fullName}
        </Typography>
      </div>
      <Typography 
        id="drawer-welcome-text"
        style={{ margin: "0 40px 37px 0", ...webStyle.innerContainer4 }} 
      >
        {this.tranFavorite("Welcome to your Account")}
      </Typography>
      <Sidebar id="drawer-sidebar" navigation={this.props.navigation} />
    </BuyerDrawer>
  )
  
  MobileView = () => {
    if (window.innerWidth >= 951) return null;
    return (
      <>
        <Grid id="mobile-view-container" item style={{ display: "flex", width: "90vw" }}>
          {this.NavigationHeader()}
          {this.DrawerToggle()}
          {this.DrawerContent()}
        </Grid>
        <Grid id="mobile-view-content" item xs={11} sm={11} style={{ display: "grid" }}>
          {this.renderFavouriteList()}
          {this.state.circularProgress && 
            <Box 
              id="loader" 
              style={{ width: "80%", textAlign: 'center', textAlignLast: 'center', position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
            >
              <CircularProgress id="progress-indicator" style={{ color: '#375280' }} />
            </Box>
          }
        </Grid>
      </>
    )
  }
  

  desktopView = () => {
    if (window.innerWidth <= 950) return null;
    return (
      <>
        <Grid item xs={3} sm={3}>
          <Box
              style={{ margin: "27px 40px 0 0", ...webStyle.innerContainer }}>
              <Typography style={webStyle.innerContainer1} data-test-id="homenavigate1" onClick={() => this.headerNavigation("LandingPage")}>
                {this.tranFavorite("Home")}
              </Typography>
              <img src={Vector} style={{ transform: i18n.dir() === 'ltr' ? 'rotate(0deg)' : 'rotate(180deg)' }} />
              <Typography style={webStyle.innerContainer2}>{this.tranFavorite("Stylist")}</Typography>
            </Box>
            <div >
              <Typography style={{ margin: "31px 40px 0 0", ...webStyle.innerContainer3 }} >
                <img src={scroll} />
                {this.state.fullName}
              </Typography>
            </div>
            <Typography style={{ margin: "0 40px 37px 0", ...webStyle.innerContainer4 }} >
              {this.tranFavorite("Welcome to your Account")}
            </Typography>
            <Sidebar navigation={this.props.navigation} />



        </Grid>
        <Grid item xs={8} sm={8} style={{ display: "grid" }}>
          {this.renderFavouriteList()}
          {this.state.circularProgress && <Box data-testid="loder" style={{ width: "80%", textAlign: 'center', textAlignLast: 'center', position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}><CircularProgress style={{ color: '#375280' }} /></Box>}
        </Grid>
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Header navigation={this.props.navigation} />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>
            {this.state.alertMsg}</Alert>
        </Snackbar>
        <Grid style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }} container dir={i18n.dir()}>

          {this.MobileView()}
          {this.desktopView()}
        </Grid>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px",
    fontFamily: "Lato",
  },
  tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  innerContainer5: {
    borderRadius: "50%",
    backgroundColor: "#CCBEB121",
    width: "170px",
    height: "170px",
    alignItems: "center",

    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  innerContainer8: {
    backgroundColor: "#CCBEB1",
    borderRadius: "2px",
    fontSize: "18px",
    padding: "10px 16px 10px 16px",
    marginTop: "48px",
    height: "64px",
    color: "white",
    textTransform: "none",
    fontWeight: 800,
    width: window.innerWidth > 951 ? "365px" : "90%",
    fontFamily: "Lato , sans-serif",
  } as React.CSSProperties,
  innerContainer6: {
    boxShadow: "2px 2px 4px 0px #0000000D, -2px -2px 4px 0px #0000000D",
    width: window.innerWidth > 951 ? "690px" : "90%",
    marginTop: window.innerWidth > 951 ? "0rem" : "2rem",
    marginLeft: window.innerWidth > 951 ? "0rem" : "2rem",
    height: "500px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  } as React.CSSProperties,


  innerContainer7: {
    color: "#375280",
    fontSize: "14px",
    fontWeight: "400px",
    fontFamily: "Lato , sans-serif",
    width: window.innerWidth > 951 ? "380px" : "85%",
  } as unknown as React.CSSProperties,
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: "pointer"
  },
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#475569",
    cursor: "pointer"
  },
  innerContainer3: {
    fontFamily: "Lato",
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 800,
    color: "#375280",
    marginLeft: "40px",
    marginTop: "31px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  innerContainer4: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
    marginLeft: "40px",
    marginBottom: "37px"

  },
};

const StylishCard = styled(Card)({
  position: "relative",
  height: "207px",
  "& .favBtn": {
    top: '15px',
    padding: '5px',
    position: 'absolute',
    background: 'white',
    "& svg": {
      fill: "#375280",
    }
  },
  "& .cardMedia": {
    height: "100%",
  },
  "& .cardContent": {
    padding: 0,
  },
  "& .content": {
    fontFamily: 'Lato',
    color: 'white',
    padding: "10px",
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '64px',
    background: 'linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))',
    "& .title": {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '26px',
      marginBottom: '4px',
      textTransform: "capitalize"
    },
    "& .description": {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14.4px',
      color: "white"
    }
  }
})
// Customizable Area End

