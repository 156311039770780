import React from "react";
// Customizable Area Start
import Header from "../../../components/src/Header";
import HiredStylistProfileController, {
    Props,
    ImagesEntity,
} from "./HiredStylistProfileController.web";
import Alert from '@material-ui/lab/Alert';
import Footer from "../../../components/src/Footer";
import { Grid, Breadcrumbs, Typography, Avatar, styled, Snackbar, Modal,TextField, Backdrop, Fade, withStyles, Box, Checkbox, CheckboxProps, CardContent as MuiCardContent, Divider, IconButton, Tabs, Tab, Card, Button } from "@material-ui/core";
import FavoriteIcon from '@material-ui/icons/Favorite';
import CircularProgress from '@material-ui/core/CircularProgress';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import { message as messageIcon, Noimageavailable } from "./assets"
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import i18n from "../../../components/src/i18next/i18n";
import OneToOneChat from "../../chat/src/OneToOneChat.web";
import { handleImgUrl } from "../../../components/src/Chat/chat.web";
import { checkCondition } from "../../../components/src/Seller/logOut";
import { CustomChatBadge } from "../../chat/src/ClientChat.web";
// Customizable Area End

export default class HiredStylistProfile extends HiredStylistProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    generateAlternatingImageRows() {
        const rowsImg: JSX.Element[] = [];
        let index = 0;
        while (index < this.state.visibleCount && index < this.state.explorePortfolioList.length) {
            if (index + 7 <= this.state.explorePortfolioList.length) {
                rowsImg.push(this.createImageGalleryRows(index, index + 1));
                index += 7;
            } else if (index < this.state.explorePortfolioList.length) {
                rowsImg.push(this.createImageGalleryRows(index, index + 1));
                index = this.state.explorePortfolioList.length;
            }
            if (index + 5 <= this.state.explorePortfolioList.length) {
                rowsImg.push(this.generateReversedImageRow(index));
                index += 5;
            } else if (index < this.state.explorePortfolioList.length) {
                rowsImg.push(this.generateReversedImageRow(index));
                index = this.state.explorePortfolioList.length;
            }
        }
        return rowsImg;
    }

    createImageGalleryRows = (largeIndex: number, smallStartIndex: number) => {
        const largeImage = this.state.explorePortfolioList[largeIndex];
        return (
            <Grid spacing={2} container key={`row-${largeIndex}`}>
                <Grid item xs={12} sm={6}>
                    <img alt={largeImage && largeImage.description} className="largeImage" src={largeImage && largeImage.image_url} data-testid="hireStylistImg" onClick={() => this.hireStylistImg(largeImage && largeImage.image_url, largeImage.description)} />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Grid spacing={2} container>
                        {this.state.explorePortfolioList.slice(smallStartIndex, smallStartIndex + 6).map((item: ImagesEntity, index: number) => (
                            <Grid item xs={6} sm={4} id="smallStartIndex" key={`small-${smallStartIndex + index}`}>
                                <img alt={item.description} className="smallImage" src={item.image_url} data-testid="hireStylistImgSmall" onClick={() => this.hireStylistImg(item.image_url, item.description)} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    generateReversedImageRow = (startIndex: number) => {
        const totalRemainIng = this.state.explorePortfolioList.length - startIndex;
        const smallImageCountImg = Math.min(totalRemainIng, 4);
        return (
            <Grid container spacing={2} key={`reverse-row-${startIndex}`}>
                <Grid item xs={12} sm={6} id="startIndex">
                    <Grid container spacing={2}>
                        {this.state.explorePortfolioList.slice(startIndex, startIndex + smallImageCountImg).map((item: ImagesEntity, index: number) => (
                            <Grid item xs={6} sm={6} key={`reverse-small-${startIndex + index}`}>
                                <img src={item && item.image_url} alt={item && item.description} data-testid="hireStylistImgs" onClick={() => this.hireStylistImg(item.image_url, item.description)} id="startIndex" className="smallImage" />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {totalRemainIng >= 5 && (
                    <Grid item xs={12} sm={6}>
                        <img data-testid="hireStylistImgUrl" onClick={() => this.hireStylistImg(this.state.explorePortfolioList[startIndex + 1].image_url, this.state.explorePortfolioList[startIndex]?.description)} src={this.state.explorePortfolioList[startIndex + 4]?.image_url} alt={this.state.explorePortfolioList[startIndex + 4]?.description} className="largeImage" />
                    </Grid>
                )}
            </Grid>
        );
    };


    renderBreadcrumbsWithTitle = (selected: number) => {
        const { isChat, stylistProfileData } = this.state
        const fullName = stylistProfileData?.attributes.full_name
        return (<BreadcrumbStyle>
            {selected === 0 && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={i18n.dir() === "ltr" ? <KeyboardArrowRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}>
                        <Typography color="inherit" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage} data-testid="hiredStylistPage" id="Stylist">{this.tranStylistPortfolio("Stylist")}</Typography>
                        <Typography color="inherit" id="Stylist" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage}>{this.tranStylistPortfolio("Stylists Portfolios")}</Typography>
                        <Typography color="inherit" id="Stylist">{this.tranStylistPortfolio("Stylist's Profile")}</Typography>
                        <Typography color="inherit" id="Stylist" data-testid="closeChat" style={{ cursor: isChat ? 'pointer' : 'default' }} onClick={()=>this.handleClickChat(false)}>{this.tranStylistPortfolio("Hire Stylist Plans")}</Typography>
                        {isChat && <Typography color="inherit"> {this.tranStylistPortfolio("Chat with")} {fullName} </Typography>}
                    </Breadcrumbs>
                </>
            )}
            {(selected === 1 || selected === 2) && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="breadcrumb" separator={i18n.dir() === "ltr" ? <KeyboardArrowRightRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}>
                        <Typography color="inherit" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage} data-testid="hiredStylistPage" id="Stylist">{this.tranStylistPortfolio("Stylist")}</Typography>
                        <Typography color="inherit" id="Stylist" style={{ cursor: 'pointer' }} onClick={this.hiredStylistPage}>{this.tranStylistPortfolio("Stylists Portfolios")}</Typography>
                        <Typography color="inherit" id="Stylist" data-testid="closeChat" style={{ cursor: isChat ? 'pointer' : 'default' }} onClick={()=>this.handleClickChat(false)}>{this.tranStylistPortfolio("Stylist's Profile")}</Typography>
                        {isChat && <Typography color="inherit"> {this.tranStylistPortfolio("Chat with")} {fullName} </Typography>}
                        <Typography color="inherit" id="Stylist" style={{ cursor: 'pointer' }}>{selected === 2 ? this.tranStylistPortfolio("Explore Portfolio"): `${this.tranStylistPortfolio("Products by")} ${this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}`}</Typography>
                    </Breadcrumbs>
                </>
            )}
            <Box className="pageTitle">
                <IconButton data-testid="handleBackButton" onClick={this.hiredStylistPage} className="iconButton"> {i18n.dir() === "ltr" ? <KeyboardArrowLeftRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}</IconButton>
                <Typography className={i18n.dir() === "ltr" ? "text" : "text2"}>{isChat ?  `${this.tranStylistPortfolio("Chat with")} ${stylistProfileData?.attributes.full_name}`:this.tranStylistPortfolio("Stylist's Profile")}</Typography>
            </Box>
        </BreadcrumbStyle>)
    }

    StylistCard = () => {
        return (
            <>
                <StylistCardContainer>
                    <Box className="avatar-box">
                        <Avatar className={i18n.dir() === "ltr" ? "avatar" : "avatar2"} src={this.state.stylistProfileData?.attributes?.profile_picture === null ? Noimageavailable : handleImgUrl(this.state.stylistProfileData?.attributes?.profile_picture) } alt={this.state.stylistProfileData?.attributes?.full_name ?? 'Default Name'} />
                        <Box>
                            <Typography variant="h6" className="name-text">{this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}</Typography>
                            <Typography variant="body2" className="available-text">{this.tranStylistPortfolio("Available to Hire")}</Typography>
                        </Box>
                        <IconButton className={i18n.dir() === "ltr" ? "favorite-icon" : "favorite-icon2"} data-testid="favBtn" onClick={() => this.toggleFavorite()}>
                            {this.state.stylistProfileData?.attributes.is_favorite ? <FavoriteIcon fill="#FF0000" /> : <FavoriteBorderIcon fill="#375280" />}
                        </IconButton>
                    </Box>
                    <Typography variant="body2" className="description">
                        {this.state.stylistProfileData?.attributes.bio}
                    </Typography>
                    <Box className="action-box">
                        <Box className="action-button">
                        <CallOutlinedIcon className="icon" style={{cursor:'pointer'}} onClick={this.openCallReqest} data-test-id="callReqestOpen"/>
                            <Typography variant="body2">{this.tranStylistPortfolio("Request a callback")}</Typography>
                        </Box>
                        <Box className="action-button" onClick={() => this.handleClickChat(true)} data-testid="cardChat">
                            <CustomChatBadge
                                badgeContent={!this.state.isChat ? this.state.stylistProfileData?.attributes?.unread_message_count : 0}
                                style={{ marginRight: '8px'}}
                            >
                            <img src={messageIcon} className="icon" />
                            </CustomChatBadge>
                            <Typography variant="body2">
                                {this.tranStylistPortfolio("Chat with")} {this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}
                            </Typography>
                        </Box>
                    </Box>
                    <Divider style={{ margin: '16px 0' }} />
                    {this.state.stylistProfileData?.id && <Button fullWidth className="hire-button" data-testid="navigateToHireStylistFormWeb" onClick={() => this.navigateTo({ productId: this.state.stylistProfileData?.id, props: this.props, screenName: "HireStylistFormWeb" })}>{this.tranStylistPortfolio("Hire Stylist")}</Button>}
                </StylistCardContainer>
            </>
        );
    };
    renderExplorePortfolio = () => {
        const { explorePortfolioList, visibleCount } = this.state;
        return (
            <>
                <ImageTitle>{this.tranStylistPortfolio("Explore Portfolio")}</ImageTitle>
                <ImageCollage>
                    {explorePortfolioList.length ? this.generateAlternatingImageRows() : <StylistRow><div className="nodata">{this.tranStylistPortfolio("No portfolio images found")}</div></StylistRow>}
                </ImageCollage>
                {(visibleCount < explorePortfolioList.length) &&
                    <Box className="viewMore">
                        <Button variant="outlined" className="viewMoreButton" onClick={this.handleViewMore} data-testid="viewMoreButton">
                            {this.tranStylistPortfolio("View More")}
                        </Button>
                    </Box>
                }
            </>
        )
    }

    renderDiscountBox = (productDis: number) => (
            <Box className="discountBadgeProductCards" style={{ backgroundColor : productDis > 0 ?  "#FFFFFF" : "transparent"}}>
                {productDis > 0 && 
                `${productDis}${this.tranStylistPortfolio("% Off")}`}
            </Box>
        )

    renderPrice = (primaryDis: number, primaryMainPrice: string | number, primaryPrice: string | number) => (
            <Box style={{ ...webStyle.accountMrp, textAlign: 'center', fontSize: checkCondition(this.state.currencySign === "$" , "18px" , "16px") as string }}>
                {primaryDis > 0 ? (
                    <>
                        <Typography id="actual-list-price">
                            <del>{`${this.state.currencySign} ${primaryMainPrice}`}</del>
                        </Typography>
                        <Typography id="discounted-list-price" style={webStyle.actualPrice}>{`${this.state.currencySign} ${primaryPrice}`}</Typography>
                    </>
                    ) : (
                        <Typography id="primary-list-price" style={webStyle.actualPrice}>{`${this.state.currencySign} ${primaryMainPrice}`}</Typography>
                )}
        </Box>)

    renderProductCards = () => {
        const { cardStylist } = this.state;
        return (
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {cardStylist && cardStylist.length > 0 ?
                    cardStylist.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <ProductCardWrapper data-testid="navigateTo" onClick={() => this.navigateTo({ productId: item.id, props: this.props, screenName: "Productdescription3" })}>
                                <div className="cardProductCards">
                                    <img
                                        className="productImageProductCards"
                                        src={item.attributes.primary_image === null ? Noimageavailable : item.attributes.primary_image}
                                        alt={item.attributes.name || "Product image"}
                                    />
                                    {this.renderDiscountBox(item.attributes.primary_discounted_percentage)}
                                    <IconButton className="favoriteIconProductCards" data-testid="isWishlistData" onClick={(event) => this.is_wishlistData(item, event)}>
                                        {item && item.attributes.is_wishlist ? <FavoriteIcon fill="#FF0000" /> : <FavoriteBorderIcon fill="#375280" />}
                                    </IconButton>
                                    <div className="productInfoProductCards">
                                        <div>
                                            <p className="productNameProductCards">{item.attributes.name}</p>
                                            <p className="brandNameProductCards">{item.attributes.description?.length > 25 ? `${item.attributes.description.slice(0,25)}...`: item.attributes.description}</p>
                                        </div>
                                        {this.renderPrice(item.attributes.primary_discounted_percentage, item.attributes.primary_main_price, item.attributes.primary_price)}
                                    </div>
                                </div>
                            </ProductCardWrapper>
                        </Grid>
                    ))
                    :
                    <StylistRow><div className="nodata">{this.tranStylistPortfolio("No product found")}</div></StylistRow>
                }
            </Grid>
        );
    }
    renderErrorMessages = (errorMessages: string[] | undefined) => {
        return errorMessages?.map((message, index) => (
          <Typography key={index} color="error" variant="caption">
            {message}
          </Typography>
        ));
      };

    quarterlyPlan = (name: string) => {
        const quarterlyPlan = this.tranStylistPortfolio("Quarterly Plan");
        const weeklyPlan = this.tranStylistPortfolio("Weekly Plan");
        const monthlyPlan = this.tranStylistPortfolio("Monthly Plan");
        switch (name) {
            case "quarterly_plan":
                return quarterlyPlan;
            case "weekly_plan":
                return weeklyPlan;
            case "monthly_plan":
                return monthlyPlan;
            default:
                break;
        }
    }
    renderPricingOptions = () => {
        return (
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {this.state.productService && this.state.productService.length > 0 ?
                    this.state.productService.map((item, index) => {
                        const isCheckedId = this.state.isChecked === item.id
                        return(
                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                            <CardWrapper>
                                <MuiCardContent className="contentPricingOptions" data-testid="contentPricingOptions">
                                    <div className="titleRowPricingOptions">
                                        <Typography className="title" variant="h6">{this.quarterlyPlan(item.attributes.name)}</Typography>
                                        <Typography className="price" variant="h6">${item.attributes.service_charges}/-</Typography>
                                    </div>
                                    <div className="dividerPricingOptions" />
                                    <ul className="featureListPricingOptions">
                                        <li className="featureItemPricingOptions">{item.attributes.styling_per_week}&nbsp;{this.getCounts(item.attributes.styling_per_week)}</li>
                                        <li className="featureItemPricingOptions">{item.attributes.discussion_time}&nbsp;{this.getHouers(item.attributes.discussion_time)}</li>
                                        {item.attributes.voice_call_facility === false ? null :<li className="featureItemPricingOptions">{this.tranStylistPortfolio("Voice and Video call facility")}</li>}
                                    </ul>
                                    <Box style={{ marginTop: '70px' }}>
                                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                                            <CustomCheckbox data-testid="setpricechecked" inputProps={{ 'data-testid': 'setpricechecked' } as React.InputHTMLAttributes<HTMLInputElement> & { 'aria-label'?: string; 'data-testid'?: string; }} checked={isCheckedId} onChange={()=>this.handleCheckboxChange(item.id)} />
                                            &nbsp;
                                            <div>
                                                <div style={{ color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500 }}>{this.tranStylistPortfolio("I agree to the stylist’s")}</div>
                                                <div style={{ color: '#94A3B8', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500 }}>
                                                    <span style={{ color: '#375280', fontSize: '14px', fontFamily: 'Lato', fontWeight: 500, borderBottom: '1px solid #375280', cursor: 'pointer' }} data-testid="policiesStylistPage" onClick={this.policiesStylistPage}>{this.tranStylistPortfolio("Terms and Conditions")}</span>&nbsp;{this.tranStylistPortfolio("of services")}.
                                                </div>
                                            </div>
                                        </div>
                                        <Button className="planButtonPricingOptions" data-testid="buybutton" onClick={() => this.buybutton(item.id)} fullWidth>{this.tranStylistPortfolio("Plan Buy")}</Button>
                                    </Box>
                                </MuiCardContent>
                            </CardWrapper>
                        </Grid>
                    )})
                    :
                    <StylistRow><div className="nodata">{this.tranStylistPortfolio("No plan found")}</div></StylistRow>
                }

            </Grid>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { selected, isChat, stylistProfileData } = this.state;
        return (
            <>
                <PageWrapper>
                    <Header navigation={this.props.navigation} />
                    <Grid container dir={i18n.dir()} style={{ maxWidth: "1440px", margin: "40px auto", width: "100%" }}>
                        <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
                            {this.renderBreadcrumbsWithTitle(selected)}
                            <Grid container spacing={3}>
                                {this.state.loading ? <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                                    <CircularProgress data-testid="CircularProgress"/>
                                </Box> :
                                    <>
                                        <Grid item xl={4} lg={4} md={4} sm={12} xs={12} >
                                            <LeftBox>
                                                {this.StylistCard()}
                                            </LeftBox>
                                        </Grid>
                                        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                                            <WrapperBox>
                                                {isChat && stylistProfileData?.id ?
                                                    <Box mr={2}><OneToOneChat data-test-id="stylistChat" isStylistPlan={true} submitStylistplan={(chatId, receiverId) => this.handleChoosePlan(chatId, receiverId)} navigation={this.props.navigation} receiver_id={stylistProfileData.id} /></Box>
                                                    : <><CustomTabs value={selected} data-testid="exploreStylist" onChange={this.handleChange}>
                                                        <CustomTab selected={selected === 0} label={this.tranStylistPortfolio("Pricing")} />
                                                        <CustomTab selected={selected === 1} label={`${this.tranStylistPortfolio("Products by")} ${this.state.stylistProfileData && this.state.stylistProfileData.attributes.full_name}`} />
                                                        <CustomTab selected={selected === 2} label={this.tranStylistPortfolio("Explore Portfolio")} />
                                                    </CustomTabs>
                                                        {selected === 0 && this.renderPricingOptions()}
                                                        {selected === 1 && this.renderProductCards()}
                                                        {selected === 2 && this.renderExplorePortfolio()}</>
                                                }
                                            </WrapperBox>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer navigation={this.props.navigation} />
                </PageWrapper>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlertInProcess}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity={this.state.alertType}>{this.state.already}</Alert></Snackbar>
                <Modal open={this.state.isModalOpen} style={{ display: "flex" }}>
                    <Box style={webStyle.modalBox} >
                        <Typography style={webStyle.modalHead}>{this.tranStylistPortfolio("Confirmation")}</Typography>
                        <Typography style={{ fontSize: "16px", color: '#375280', fontFamily: 'Lato', fontWeight: 500 }}>
                            {this.tranStylistPortfolio("Your stylist will review your request and contact you.")}
                        </Typography>
                        <Button style={{ ...webStyle.placeOrderBtn2 }} data-testid="proceedToTrackOrderPage" onClick={this.proceedToTrackOrderPage}>
                            {this.tranStylistPortfolio("My Requests")}
                        </Button>
                    </Box>
                </Modal>
                <Modal onClose={this.hireStylistImgClose} open={this.state.isModalOpenImg} style={{ display: "flex" }}>
                    <Box dir={i18n.dir()} style={{
                        background: "#fff",
                        margin: "auto",
                        borderRadius: "2px",
                        padding: "3rem",
                    }} >
                        <img src={this.state.imgStylist} width="400px" height="400px" />
                        <Typography style={{ color: '#375280', fontSize: '20px', fontFamily: 'Lato', fontWeight: 700 }}>{this.tranStylistPortfolio("Explore Portfolios")}</Typography>
                        <Box style={{ backgroundColor: '#f8f8f8' }}>
                            <Typography style={{ color: '#94A3B8', fontSize: '16px', fontFamily: 'Lato', fontWeight: 500 }}>{this.state.imgDescriptions}</Typography>
                        </Box>
                    </Box>
                </Modal>
                <StyledDrawerProfile
                dir={i18n.dir()}
                    aria-describedby="transition-modal-description"
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    onClose={this.callReqestCancel}
                    BackdropProps={{
                        timeout: 500
                    }}
                    BackdropComponent={Backdrop}
                    open={this.state.callReqest}
                >
                    <Fade in={this.state.callReqest} style={{ borderRadius: '4px', width: "500px", backgroundColor: "white" }}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box>
                                <Typography style={webStyle.inputHeading}>{this.tranStylistPortfolio("Reason for Call")}*</Typography>
                                <InputField
                                    fullWidth
                                    variant="outlined"
                                    data-test-id="reasonForCall"
                                    placeholder={this.tranStylistPortfolio("Enter Reason for Call")}
                                    name="reasonForCall"
                                    type="text"
                                    value={this.state.formData.reasonForCall}
                                    inputProps={{ maxLength: 100 ,style: { fontSize: '14px', fontFamily: 'Lato', fontStyle: "normal", fontWeight: 700 }}}
                                    onChange={this.handleInputChange}
                                    rows={2}
                                    multiline
                                />
                                {this.renderErrorMessages(this.state.errors.reasonForCall)}
                                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Box>
                                                <Typography style={webStyle.inputHeading}>{this.tranStylistPortfolio("Hours (24 Hours)")}*</Typography>
                                                <InputField
                                                    fullWidth
                                                    variant="outlined"
                                                    name="hours"
                                                    data-test-id="hours"
                                                    value={this.state.formData.hours}
                                                    placeholder={this.tranStylistPortfolio("Enter Hours")}
                                                    inputProps={{ maxLength: 2, style: { fontSize: '14px', fontFamily: 'Lato', fontStyle: "normal", fontWeight: 700 } }}
                                                    onChange={this.handleInputChange}
                                                /><br />
                                                {this.renderErrorMessages(this.state.errors.hours)}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box>
                                                <Typography style={{ ...webStyle.inputHeading, marginLeft: '10px', }}>{this.tranStylistPortfolio("Minutes")}*</Typography>
                                                <InputField
                                                    fullWidth
                                                    variant="outlined"
                                                    name="minutes"
                                                    data-test-id="minutes"
                                                    placeholder={this.tranStylistPortfolio("Enter Minutes")}
                                                    value={this.state.formData.minutes}
                                                    inputProps={{ maxLength: 2, style: { fontSize: '14px', fontFamily: 'Lato', fontStyle: "normal", fontWeight: 700 } }}
                                                    onChange={this.handleInputChange}
                                                /><br />
                                                {this.renderErrorMessages(this.state.errors.minutes)}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                    <Button style={{ ...webStyle.submitButton, textTransform: 'capitalize' }} data-test-id="handleSubmit" onClick={this.handleSubmit}>{this.tranStylistPortfolio("Submit")}</Button>
                                </Box>
                            </Box>
                        </div>
                    </Fade>
                </StyledDrawerProfile>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    submitButton:{backgroundColor:"#CCBEB1",padding:'15px',width:'100%',fontSize:'20px',color:'#ffff',fontWeight:800,fontFamily:'Lato'},
    inputHeading: {
        fontWeight: 900,
        fontSize: "16px",
        marginTop: "20px",
        fontStyle: "normal",
        marginBottom: "10px",
        color: "#375280",
        fontFamily: "Lato",
      },
    placeOrderBtn2: {
        width: "75%",
        backgroundColor: "#C7B9AD",
        marginTop: "1rem",
        padding: "1rem 0",
        fontSize: "1.125rem",
        fontWeight: 700,
        fontFamily: "Lato, sans-serif",
        color: "#fff",
        textTransform: "none" as "none",
    },
    modalHead: {
        fontSize: "24px",
        fontWeight: 800,
        marginBottom: "0.5rem",
        color: "#375280"
    },
    modalBox: {
        background: "#fff",
        width: "30vw",
        margin: "auto",
        borderRadius: "2px",
        padding: "3rem",
        textAlign: "center" as "center",
    },
    accountMrp: { fontFamily: "Lato", display: "flex", flexDirection: "column-reverse" as "column-reverse", alignItems: "center", justifyContent: "center", color: '#375280', fontSize: '18x', fontWeight: 700, backgroundColor: 'rgba(225, 222, 222, 0.43)', padding: '10px 10.5px', borderRadius: '8px', width: '88px' },
    actualPrice: { fontFamily: "Lato", color: '#375280', fontSize: '18px', fontWeight: 700, whiteSpace: "nowrap" as "nowrap"},
}
const BreadcrumbStyle = styled(Box)({
    margin: "20px",
    "& .pageTitle": {
        alignItems: "center",
        display: "flex",
        margin: "20px 0"
    },
    "& .iconButton": {
        borderRadius: '50%',
        backgroundColor: '#fff',
        padding: '8px',
        marginRight: '8px',
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    },
    "& .text": {
        fontSize: '18px',
        fontFamily: 'Lato',
        color: '#375280',
        marginLeft: "5px",
        fontWeight: 600,
    },
    "& .text2": {
        fontSize: '18px',
        fontFamily: 'Lato',
        color: '#375280',
        marginRight: "5px",
        fontWeight: 600,
    },
    "& .breadcrumb": {
        fontSize: '14px',
        fontFamily: 'Lato',
        lineHeight: '22px',
        fontWeight: 400,
        textDecoration: 'none',
        color: '#475569',
        textAlign: 'left',
    }
})
const CustomTabs = styled(Tabs)({
    borderRadius: '2px',
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const WrapperBox = styled(Box)({
    margin: '20px',
    '@media(min-width: 821px)': {
        margin: '0px',
    },
    "& .viewMore": {
        justifyContent: "center",
        display: "flex",
    },
    "& .viewMoreButton": {
        color: '#375280',
        borderColor: '#CCBEB1',
        fontWeight: 700,
        textTransform: 'none',
        padding: '8px 24px',
        fontSize: '16px',
        marginTop: '20px',
        background: "#fff",
        borderRadius: '2px',
        width: "156px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
    "& .searchBox": {
        width: '380px',
        marginBottom: '24px',
        background: "white",
        borderRadius: '2px',
        float: "right",
        border: '2px solid #CBD5E1',
        "& fieldset": {
            borderColor: "white",
            '&:focus': {
                border: 'none',
                outline: 'none',
            }
        },
        "& .searchIcon": {
            height: "20px",
            marginRight: "15px",
        },
        '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
                borderWidth: 0,
            },
            '& fieldset': {
                borderColor: 'white',
            },
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '16px',
            fontFamily: 'Lato',
            color: '#94A3B8',
            fontWeight: 400,
        },
        "& .Mui-focused": {
            outline: "none",
            borderColor: "white",
            border: 'none',
        },
        '& .MuiInputBase-input': {
            '&:focus': {
                outline: 'none',
            },
        },
    },
})

const CustomTab = styled(Tab)({
    borderRadius: '2px',
    height: '40px',
    color: '#375280',
    padding: '11px 20px',
    fontWeight: 500,
    backgroundColor: '#fff',
    textTransform: "none",
    border: "none",
    cursor: "pointer",
    marginRight: "8px",
    '&:hover': {
        color: '#375280',
        backgroundColor: '#ddd',
    },
    "&.Mui-selected": {
        color: '#fff',
        backgroundColor: '#375280',
        fontWeight: 700,
        '&:hover': {
            backgroundColor: '#375280'
        },
    }
});

const StylistRow = styled(Box)({
    justifyContent: "space-between",
    display: "flex",
    padding: "18px 0",
    width: "100%",
    alignItems: "center",
    "& .messageIcon": {
        fill: "#375280",
        height: "32px",
        width: "32px",
        cursor: "pointer",
        marginRight: "10px"
    },
    "& .nodata": {
        fontFamily: 'Lato',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        textAlign: 'center',
        color: "#94A3B8",
        paddingTop: "10px",
        width: "100%"
    }
});

const ImageTitle = styled(Box)({
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "Lato",
    color: "#375280",
    display: "flex",
    padding: "25px 0",
    alignItems: "center",
    justifyContent: "space-between",
});

const LeftBox = styled(Box)({
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    background: "white",
    margin: "0 20px",
    padding: "20px 30px",
})

const PageWrapper = styled(Box)({
    background: "#F8F8F8"
})
const ImageCollage = styled(Box)({
    "& .largeImage": {
        height: "296px",
        width: "100%",
        padding: "0px 16px 8px 0px",
        objectFit: "cover",
    },
    "& .smallImage": {
        height: "140px",
        width: "100%",
        objectFit: "cover",
    },
});

const StylistCardContainer = styled(Card)({
    borderRadius: '0px',
    padding: '0px',
    boxShadow: "none",
    '& .avatar-box': {
        alignItems: 'center',
        display: 'flex',
        marginBottom: '16px',
    },
    '& .avatar': {
        height: 56,
        marginRight: '12px',
        width: 56,
    },
    '& .avatar2': {
        height: 56,
        marginLeft: '12px',
        width: 56,
    },
    '& .name-text': {
        textTransform: 'capitalize',
        fontWeight: 600,
        color:"#375280"
    },
    '& .available-text': {
        fontSize: '14px',
        color: '#059669',
    },
    '& .description': {
        fontFamily: "Lato",
        color: '#6B7280',
        marginTop: '16px',
    },
    '& .action-box': {
        justifyContent: 'space-between',
        padding: '16px 0 0',
        display: 'flex',

    },
    '& .action-button': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: "space-between",
        width: "50%",
        flexDirection: "column",
        "&:second-child":{
            cursor: "pointer",
        },
        "&:first-child": {
            borderRight: "1px solid #E2E8F0"
        },
        "& p": {
            fontSize: '14px',
            fontFamily: 'Lato',
            textAlign: 'center',
            lineHeight: '24px',
            fontWeight: 700,
            color: '#375280',
            cursor: "pointer"
        }
    },
    '& .icon': {
        fill: "#375280",
        marginRight: '0px',
        marginBottom: '10px',
        cursor: 'pointer',
        height: '25px',
    },
    '& .hire-button': {
        textTransform: "none",
        backgroundColor: '#D1BEA8',
        color: 'white',
        fontSize: "20px",
        height: '56px',
        fontWeight: 'bold',
        borderRadius: '2px 0px 0px 0px',
        padding: '10px 16px',
        "& span": {
            borderRadius: '2px 0px 0px 0px',
        },
        '&:hover': {
            backgroundColor: '#b59f8d',
        },
    },
    '& .favorite-icon': {
        marginLeft: 'auto',
        '& svg': {
            fill: "#375280"
        }
    },
    '& .favorite-icon2': {
        marginRight: 'auto',
        '& svg': {
            fill: "#375280"
        }
    },
});

const ProductCardWrapper = styled(Box)({
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: "2px",
    cursor: "pointer",
    transition: "0.3s ease-in-out",
    "& .discountBadgeProductCards": {
        top: "15px",
        position: "absolute",
        backgroundColor: "#fff",
        left: "15px",
        fontSize: "12px",
        color: "#375280",
        padding: "4px 8px",
        fontWeight: "700",
        fontFamily: "Lato",
        borderRadius: "2px",
    },
    "& .favoriteIconProductCards": {
        right: "15px",
        position: "absolute",
        color: "#375280",
        fontSize: "24px",
        height: "30px",
        width: "30px",
        padding: "8px",
        top: "15px",
        background: "white"
    },
    "& .productImageProductCards": {
        height: "350px",
        width: "100%",
    },

    "& .productInfoProductCards": {
        justifyContent: "space-between",
        display: "flex",
        alignItems: "center",
        padding: "16px",
        "& .productNameProductCards": {
            fontFamily: "Lato",
            color: "#375280",
            fontWeight: 700,
            lineHeight: "19.2px",
            fontSize: "16px",
            margin: "10px 0 5px",
            textAlign: "left",
        },
        "& .brandNameProductCards": {
            fontSize: "14px",
            fontFamily: "Lato",
            lineHeight: "16.8px",
            fontWeight: 500,
            margin: "5px 0 10px",
            textAlign: "left",
        },
        "& .price": {
            color: '#375280',
            backgroundColor: '#F8F8F8',
            padding: '10px',
            fontFamily: 'Lato',
            borderRadius: "8px",
            fontWeight: "700",
        },
    },
})

const CardWrapper = styled(Card)({
    borderRadius: '2px',
    maxWidth: '400px',
    border: "1px solid #D5D5D5",
    boxShadow: "none",
    padding: '20px',
    height: "90%",
    "& .contentPricingOptions": {
        padding: "0px"
    },
    "& .titleRowPricingOptions": {
        justifyContent: 'space-between',
        display: 'flex',
        marginBottom: '8px',
        alignItems: 'center',
    },
    "& .title": {
        fontSize: '20px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 700,
        color: "#375280",
        textAlign: 'left',
    },
    "& .price": {
        fontSize: '20px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 800,
        color: '#375280',
        textAlign: 'right',
    },
    "& .dividerPricingOptions": {
        backgroundColor: '#e0e0e0',
        height: '1px',
        margin: '16px 0',
    },
    "& .featureListPricingOptions": {
        margin: '0',
        padding: '0 20px',
        color: '#6b6b6b',
        listStyle: 'disc',
        fontSize: '16px',
        fontFamily: 'Lato',
        lineHeight: '24px',
        fontWeight: 500,
        height: "107px"
    },
    "& .featureItemPricingOptions": {
        fontSize: '16px',
        color: '#375280',
        padding: '2px 0',
    },
    "& .planButtonPricingOptions": {
        color: 'white',
        backgroundColor: '#cbb9a8',
        borderRadius: '2px',
        fontFamily: 'Lato',
        fontSize: '20px',
        textTransform: "none",
        lineHeight: '26px',
        fontWeight: 800,
        padding: "10px",
        letterSpacing: '0.01em',
        '&:hover': {
            backgroundColor: '#bda893',
        },
    },
})
const CustomCheckbox = withStyles({
    root: {
        color: '#CCBEB1',
        '&$checked': {
            color: '#CCBEB1',
        },
    },
    icon: {
        width: 16,
        borderRadius: 3,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        height: 16,
        backgroundImage: 'linear-gradient(180deg, hsla(0,0%,100%,.8), hsla(0,0%,100%,0))',
        backgroundColor: '#f5f8fa',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            background: 'rgba(206,217,224,.5)',
            boxShadow: 'none',
        },
    },
    checked: {
        color: '#CCBEB1',
    },
    checkedIcon: {
        backgroundColor: '#CCBEB1',
    },
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const StyledDrawerProfile = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
const InputField = styled(TextField)({
    "& .MuiInputBase-root.Mui-disabled": {
        border: 'none'
    },
    "& .MuiInputBase-input": {
        color: "rgb(55, 82, 128) !important"
    },
    "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#FFFFFF",
    },
    "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "&:hover fieldset": {
            borderColor: "#FFFFFF"
        },
        "&.Mui-focused fieldset": {
            borderColor: "#FFFFFF",
            borderWidth: "1px",
        }
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderRadius: "8px",
        borderColor: "#FFFFFF",
    },
    "& .MuiInputBase-root": {
        margin: "auto",
        fontSize: "16px",
        width: '100%',
        background: 'rgb(248, 248, 248)',
        borderRadius: "2px",
        "@media only screen and (max-width: 414px)": {
            fontSize: "14px"
        }
    },
    "& .MuiOutlinedInput-input": {
        color: "#375280",
        "&:not(:placeholder-shown)": {
            color: "#1E293B",
            fontSize:'13px !important'
        },
        "&::placeholder": {
            color: "#375280",
            "@media only screen and (max-width: 414px)": {
                fontSize: "20px"
            }
        }
    },
});
// Customizable Area End
