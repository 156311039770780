import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  Box,
  Dialog,
  styled,
  TableCell,
} from "@material-ui/core";
import StylingRequestsController, { Props } from "./StylingRequestsController";
import { ThemeProvider } from "react-native-elements";
import { backBtnIcon, rejectIcon, storeSuccessIcon } from "../../assets";
import {
  StylishClientContainer,
  StylishClientTitleContainer,
} from "./StylingClientWishlist.web";
import { CustomStyledTableUpdateInventory } from "../../Seller/InventoryManagement/UpdateInventory/UpdateInventory.web";
import {
  RequesterNGContainer,
  TableBodyCell,
  TableHeaderCell,
  TableTypographyContainer,
  ViewRequestContainer,
  webStyle,
} from "./StylishClients.web";
import {
  CustomStyledStylishPagination,
  StylishPaginationContainer,
} from "../../../../catalogue/src/Stylish/StylishProductSourcing.web";
import { NoRecordTypoContainer } from "../../Seller/InventoryManagement/AssignStore/InventoryAssignStore.web";
import Loader from "../../../../../components/src/LoaderContainer";
import ReusableSnackbar from "../../../../../components/src/AlertContainer";
import i18n from "../../../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../../../components/src/SellerStylish/SellerStylishContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class StylingRequests extends StylingRequestsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderStylishRequestPagination = () => (
    <>
      {this.state.stylistRequestMeta !== undefined ? (
        <StylishPaginationContainer>
          <Box className="pagination-data-container">
            {`${(this.state.stylistRequestMeta?.current_page - 1) * 10 + 1} - 
              ${Math.min(
                this.state.stylistRequestMeta?.current_page * 10,
                this.state.stylistRequestMeta?.total_record
              )}
            of ${this.state.stylistRequestMeta.total_record} results`}
          </Box>
          <CustomStyledStylishPagination
            page={
              this.state.stylistRequestMeta.current_page !== null
                ? this.state.stylistRequestMeta.current_page
                : 1
            }
            count={
              this.state.stylistRequestMeta.total_pages !== null
                ? this.state.stylistRequestMeta.total_pages
                : 1
            }
            shape="rounded"
            color="primary"
            onChange={this.handleStylishClientReqPageChange}
            data-test-id="stylist-request-page-test-id"
            dir={i18n.dir()}
          />
        </StylishPaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  orderStatusLabel = (value: string, isPopUp: boolean) => {
    let styles = {};
    let label = "";
    switch (value) {
      case "rejected":
        styles = webStyle.shippedRejectBox;
        label = value;
        break;
      case "accepted":
        styles = webStyle.shippedBox;
        label = value;
        break;
      case "pending":
        styles = webStyle.shippedpendingBox;
        label = "Requested";
    }
    return (
      <Box
        style={{
          ...styles,
          height: isPopUp ? 15 : 27,
          borderRadius: isPopUp ? 5 : 2,
        }}
      >
        {label}
      </Box>
    );
  };

  ViewPopUpUI = () => {
    const popUpData = this.state.popUpData;
    return (
      <ViewRequestDialog
        data-test-id="request-view-dialog"
        open={this.state.isRequestOpen}
        onClose={() => this.handleRequestClose()}
      >
        {this.state.popUpData?.id && (
          <ViewRequestDialogContainer dir={i18n.dir()}>
            <Box className="main-request-title">
              <Typography className="request-number">
                #{popUpData.attributes.stylist_id}-{popUpData.id} |{" "}
                {popUpData.attributes.created_at}
              </Typography>
              <Box>
                {this.orderStatusLabel(popUpData.attributes.status, true)}
              </Box>
            </Box>
            <PreviewRequestImg>
              {popUpData.attributes.images.map((requestFile, index) => (
                <Box key={index} className="file-preview">
                  <img
                    src={requestFile.url}
                    alt={`preview ${index}`}
                    className="preview-image"
                  />
                </Box>
              ))}
            </PreviewRequestImg>
            <RequestDetailContainer>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="requester-name">
                    {popUpData.attributes.buyer_name}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                    {this.tranStylingRequest("Gender")} -{" "}
                  </Typography>
                  <Typography
                    className="description-value"
                    style={{ textTransform: "capitalize" }}
                  >
                    {popUpData.attributes.gender}
                  </Typography>
                </Box>
                <Box className="description-data">
                  <Typography className="description-name">
                    {this.tranStylingRequest("Budget")} -{" "}
                  </Typography>
                  <Typography className="description-value">
                    ${popUpData.attributes.min_price} - $
                    {popUpData.attributes.max_price}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                    {this.tranStylingRequest("Colours")} -{" "}
                  </Typography>
                  <Typography
                    className="description-value"
                    style={{ textTransform: "capitalize" }}
                  >
                    {popUpData.attributes.colour.replace(",", ", ")}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-content">
                    {popUpData.attributes.detail}
                  </Typography>
                </Box>
              </Box>
            </RequestDetailContainer>
            {popUpData.attributes.status === "pending" && (
              <RequestBtnContainer>
                <Box
                  onClick={() =>
                    this.handleAcceptReject("rejected", +popUpData.id)
                  }
                  data-test-id="reject-test-id"
                  className="reject-div"
                >
                  Reject
                </Box>
                <Box
                  onClick={() =>
                    this.handleAcceptReject("accepted", +popUpData.id)
                  }
                  data-test-id="accept-test-id"
                  className="accept-div"
                >
                  Accept
                </Box>
              </RequestBtnContainer>
            )}
            {popUpData.attributes.status === "accepted" && (
              <ViewRequestContainer>
                <Box data-test-id="view-accept-testid" className="accept-div" onClick={()=>this.navigateClientChat(popUpData.attributes.buyer_id)}>
                  {this.tranStylingRequest("Chat with Customer")}
                </Box>
              </ViewRequestContainer>
            )}
          </ViewRequestDialogContainer>
        )}
      </ViewRequestDialog>
    );
  };

  renderStylishRequestBody = () => (
    <>
      {this.state.tableBody.length === 0 ? (
        <TableRow>
          <TableCell className="ui-no-data" colSpan={6}>
            <NoRecordTypoContainer>No Styling Requests Available</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBody.map((requestData) => (
            <TableRow key={requestData.id} className="hover">
              <TableBodyCell data-test-id="product-name">
                <TableTypographyContainer
                  style={{ paddingLeft: "20px" }}
                  data-test-id={`col-req-id-body-test-id-${requestData.id}`}
                >
                  #{requestData.attributes.stylist_id}-{requestData.id}
                </TableTypographyContainer>
              </TableBodyCell>
              <TableBodyCell>
                <TableTypographyContainer
                  data-test-id={`col-req-time-body-test-id-${requestData.id}`}
                  style={{ textTransform: "uppercase" }}
                >
                  {requestData.attributes.created_at}
                </TableTypographyContainer>
              </TableBodyCell>
              <TableBodyCell>
                <RequesterNGContainer
                  data-test-id={`col-ng-body-test-id-${requestData.id}`}
                >
                  <Typography className="request-name">
                    {requestData.attributes.buyer_name}
                  </Typography>
                  <Typography className="request-gender">
                    {requestData.attributes.gender}
                  </Typography>
                </RequesterNGContainer>
              </TableBodyCell>
              <TableBodyCell>
                <TableTypographyContainer
                  data-test-id={`col-budget-id-body-test-id-${requestData.id}`}
                >
                  ${requestData.attributes.min_price} - $
                  {requestData.attributes.max_price}
                </TableTypographyContainer>
              </TableBodyCell>
              <TableBodyCell>
                <Box data-test-id={`col-status-body-test-id-${requestData.id}`}>
                  {this.orderStatusLabel(requestData.attributes.status, false)}
                </Box>
              </TableBodyCell>
              <TableBodyCell>
                <ViewRequestContainer
                  onClick={() => this.handleRequestView(requestData.id)}
                  data-test-id={`col-view-req-id-body-test-id-${requestData.id}`}
                >
                  {this.tranStylingRequest("View Request")}
                </ViewRequestContainer>
              </TableBodyCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerStylishContainer role="Stylish" navigation={this.props.navigation}>
            <StylishClientContainer>
              <StylishClientTitleContainer>
                <img
                  src={backBtnIcon}
                  data-test-id="back-btn-test-id"
                  alt="backIcon"
                  style={{ transform: i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)" }} 
                  onClick={() =>
                    this.handleStylishRequestsRedirect("StylishClients")
                  }
                />
                <Typography className="main-title">{this.tranStylingRequest("Styling Requests")}</Typography>
              </StylishClientTitleContainer>
              <CustomStyledTableUpdateInventory data-test-id="sr-table">
                <Table stickyHeader size="small" className="custom-table">
                  <TableHead>
                    <TableRow
                      style={{ backgroundColor: "#f0f0f0" }}
                      className="sr-table-head-row"
                    >
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography
                          data-test-id="col-req-id-head-test-id"
                          className="table-head-title first-typo"
                        >
                          {this.tranStylingRequest("Request ID")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography
                          data-test-id="col-req-time-head-test-id"
                          className="table-head-title"
                        >
                          {this.tranStylingRequest("Request Time")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="sr-table-head-cell"
                        style={{ width: "330px" }}
                      >
                        <Typography
                          data-test-id="col-ng-head-test-id"
                          className="table-head-title"
                        >
                          {this.tranStylingRequest("Name & Gender")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography
                          data-test-id="col-budget-head-test-id"
                          className="table-head-title"
                        >
                          {this.tranStylingRequest("Budget")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell className="sr-table-head-cell">
                        <Typography
                          data-test-id="col-status-head-test-id"
                          className="table-head-title"
                        >
                          {this.tranStylingRequest("Status")}
                        </Typography>
                      </TableHeaderCell>
                      <TableHeaderCell
                        className="sr-table-head-cell"
                        style={{ width: "241px" }}
                      >
                        <Typography
                          data-test-id="col-action-head-test-id"
                          className="table-head-title"
                        >
                          {this.tranStylingRequest("Action")}
                        </Typography>
                      </TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Loader
                      id="stylish-request-loader"
                      dataTestId="stylish-request-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderStylishRequestBody()
              )}
                  </TableBody>
                </Table>
              </CustomStyledTableUpdateInventory>
            </StylishClientContainer>
          </SellerStylishContainer>
            {this.state.stylistRequestMeta.total_record > 10 &&
              this.renderStylishRequestPagination()}
         
        {this.ViewPopUpUI()}
        <DialogSuccessRejectContainer
          data-test-id="request-accept-reject-dialog"
          open={this.state.isSROpen}
        >
          <Box className="content-container">
            <img
              src={this.state.isAccepted ? storeSuccessIcon : rejectIcon}
              alt="icon"
            />
            <Typography className="text-container">
              {`You have ${
                this.state.isAccepted ? "accepted" : "rejected"
              } the styling request of Scarlet.`}
            </Typography>
          </Box>
        </DialogSuccessRejectContainer>
        <ReusableSnackbar
          onClose={this.onCloseSRAlert}
          open={this.state.isAlert}
          severity={"error"}
          message={this.state.message}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const ViewRequestDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "24px 36px",
    width: 452,
  },
});

export const ViewRequestDialogContainer = styled(Box)({
  width: "100%",
  display: "flex",
  gap: 24,
  flexDirection: "column",
  "& .main-request-title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .request-number": {
      fontFamily: "Lato, sans-serif",
      textTransform: "uppercase",
      color: "#375280",
      fontWeight: 500,
      fontSize: 14,
    },
  },
});

export const PreviewRequestImg = styled(Box)({
  display: "flex",
  gap: 12,
  flexWrap: "wrap",
  "& .file-preview": {
    position: "relative",
    "& .preview-image": {
      width: 80,
      height: 80,
      objectFit: "fill",
      borderRadius: 2,
    },
  },
});

export const RequestDetailContainer = styled(Box)({
  display: "flex",
  gap: 12,
  flexDirection: "column",
  paddingTop: 16,
  "& .row-container": {
    display: "flex",
    justifyContent: "space-between",
    "@media(max-width:540px)": {
      flexDirection: "column",
    },
    "& .description-data": {
      display: "flex",
      "& .description-name": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 16,
        color: "#375280",
      },
      "& .description-content": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 20,
        color: "#375280",
      },
      "& .description-value": {
        fontFamily: "Lato, sans-serif",
        fontSize: 16,
        fontWeight: 900,
        color: "#375280",
        paddingLeft: 4,
      },
      "& .requester-name": {
        fontFamily: "Lato, sans-serif",
        fontSize: 18,
        fontWeight: 700,
        color: "#375280",
      },
    },
  },
});

export const RequestBtnContainer = styled(Box)({
  width: "100%",
  display: "flex",
  gap: 12,
  height: 54,
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "capitalize",
  "& .reject-div": {
    color: "#F87171",
    borderColor: "#F87171",
    width: "50%",
    height: "100%",
    fontFamily: "Lato, sans-serif",
    display: "flex",
    justifyContent: "center",
    borderRadius: "2px",
    alignItems: "center",
    border: "1px solid #F87171",
  },
  "& .accept-div": {
    color: "white",
    fontFamily: "Lato, sans-serif",
    gap: "8px",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    width: "50%",
    height: "100%",
    borderRadius: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const DialogSuccessRejectContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    padding: "50px 60px",
    width: 520,
    "& .content-container": {
      display: "flex",
      flexDirection: "column",
      gap: 30,
      alignItems: "center",
      justifyContent: "center",
      "& .text-container": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 30,
        color: "#0F172A",
        textAlign: "center",
      },
    },
    "@media(max-width:520px)": {
      padding: "20px 40px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "22px !important",
        },
      },
    },
    "@media(max-width:700px)": {
      padding: "30px 60px",
      "& .content-container": {
        "& .text-container": {
          fontSize: "25px !important",
        },
      },
    },
  },
});
// Customizable Area End
