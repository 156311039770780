import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
import { MetaResponse } from "../../Seller/Catalogues/SellerCataloguesController";
import { ErrorMessage } from "../../CreateSellerStoreController";
import i18n from "../../../../../components/src/i18next/i18n";
import { apiCall } from "../../../../../components/src/APICall";

export type callStatus = "pending" | "accepted" | "rejected" | "completed";
interface ErrorMessageResponse {
  error: string;
  errors?: string | string[];
}
interface IStylingReqResp {
  id: string;
  type: string;
  attributes: {
    stylist_id: number;
    reason: string;
    hour: number;
    minute: number;
    status: callStatus;
    buyer_name: string;
    buyer_phone_number: string;
    stylist_name: string;
  };
}

interface IStylishCallBackRequestResp {
  data: IStylingReqResp[];
  meta: MetaResponse;
}
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tableBody: IStylingReqResp[];
  metaSCBResp: MetaResponse;
  isLoading: boolean;
  page: number;
  isAlert: boolean;
  message: string;
  userRole: string;
  severity: "success" | "error";
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StylingCallBackRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLatestRequestsId: string = "";
  updateLatestAcceptRejectsId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      tableBody: [],
      metaSCBResp: {} as MetaResponse,
      isLoading: true,
      page: 1,
      isAlert: false,
      message: "",
      userRole: "",
      severity: "success",
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseSRJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseSRJson && !responseSRJson.errors) {
        this.apiSRSuccessCalled(apiRequestCallId, responseSRJson);
      } else if (responseSRJson && responseSRJson.errors) {
        this.apiSRFailureCalled(responseSRJson);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  async componentDidMount() {
    // Customizable Area Start
    const userData = await getStorageData("userRole", true);
    this.setState({ userRole: userData.userType });
    if (userData.userType === "stylist") this.fetchStylishClientRequestData();
    // Customizable Area End
  }

  apiSRSuccessCalled = (
    apiRequestCallId: string,
    responseSCJson: IStylishCallBackRequestResp & {
 data: IStylingReqResp
    }
  ) => {
    if (apiRequestCallId === this.getLatestRequestsId) {
      if (responseSCJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          tableBody: responseSCJson.data,
          metaSCBResp: responseSCJson.meta,
          isLoading: false,
        }));
      }
    } else if (apiRequestCallId === this.updateLatestAcceptRejectsId) {
      if (responseSCJson.data) {
        this.setState((prevState) => ({
          ...prevState,
          isLoading: true,
        }), ()=>this.fetchStylishClientRequestData());
      }
    }
  };

  apiSRFailureCalled = (
    responseSRJson: ErrorMessage & ErrorMessageResponse
  ) => {
    if (Array.isArray(responseSRJson.errors)) {
      const firstError = responseSRJson.errors[0];
  
      if (typeof firstError === "object" && firstError.token) {
        this.handleRedirectToHome(firstError.token);
      } else {
        this.handleRedirectToHome((responseSRJson.errors as string []).join(", "));
      }
    } else {
      this.handleRedirectToHome(responseSRJson.errors);
    }
  };

  handleRedirectToHome = (message: string) => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: true,
      isLoading: false,
      message: message,
      severity: "error",
    }));
  };

  onCloseSCBRAlert = () => {
    this.setState((prevState) => ({
      ...prevState,
      isAlert: false,
    }));
  };

  handleStylishClientReqPageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    event.preventDefault();
    this.setState(
      (prevState) => ({
        ...prevState,
        metaSCBResp: {
          ...prevState.metaSCBResp,
          current_page: newPage,
        },
        page: newPage,
        isLoading: !prevState.isLoading,
      }),
      () => this.fetchStylishClientRequestData()
    );
  };

  tranStylingCallbackRequest = (transKey: string) => {
    return i18n.t(transKey, { ns: "stylishClient" });
  };

  fetchStylishClientRequestData = async () => {
    const { page } = this.state;
    this.getLatestRequestsId = await apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: `${configJSON.latestCallbackRequestsGetEndPoint}?page=${page}&per_page=10`,
      token: await getStorageData("auth-token"),
    });
  };

  updateStylishClientRequestData = async (id: string, status: callStatus) => {
    const formData = new FormData();
    formData.append("status", status);
    this.updateLatestAcceptRejectsId = await apiCall({
      method: configJSON.httpPutMethod,
      endPoint: `${configJSON.updateCallbackRequestsEndPoint}/${id}/update_status`,
      token: await getStorageData("auth-token"),
      isJsonStringify: false,
      body: formData,
    });
  };

  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
