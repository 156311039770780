import React from "react";
// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import {
  Typography,
  Box,
  Button,
  Divider,
  Grid,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Checkbox,
  TextField,
  Modal,
  InputBase,
  Snackbar,
  Dialog
} from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import {
  createTheme,
  ThemeProvider,
  withStyles,
  createStyles,
  Theme,
  styled,
} from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PaymentDetailsController, {
  Props,
} from "./PaymentDetailsController.web";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import CheckoutSteps from "./CheckoutSteps.web";
import RadioButton from "../../../components/src/RadioButton";
import {
  expressDeliveryIcon,
  standardDeliveryIcon,
  mastercardIcon,
  googlePayIcon,
  applePayIcon,
  debitCardIcon,
  modalSuccessImg,
  loyaltyBadgeIcon,
  Noimageavailable
} from "./assets";
import i18n from "../../../components/src/i18next/i18n";
import StylishBuyerHeader from "../../../components/src/StylishBuyerHeader";
import CommonBackdrop from "../../../components/src/BackDrop";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Lato, sans-serif",
      color: "#375280",
    },
  },
});

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface CustomSwitchProps extends SwitchProps {
  classes: Styles;
}
// Customizable Area End

export default class PaymentDetails extends PaymentDetailsController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start
  shippingOptions = [
    {
      value: "same",
      label: (
        <Typography style={webStyle.shippingLabel}>
          {this.transPay("Same as Billing address")}
        </Typography>
      ),
    },
    {
      value: "change",
      label: (
        <Typography style={webStyle.shippingLabel}>
          {this.transPay("Use a different shipping address")}
        </Typography>
      ),
    },
  ];

  shippingMethodOptions = [
    {
      value: "standart",
      label: (
        <Box style={webStyle.flex}>
          <img src={standardDeliveryIcon} height={28} />
          <Typography style={webStyle.shippingLabel}>
            {this.transPay("Standard Delivery")}
          </Typography>
        </Box>
      ),
      price: "$10.00",
      time: "90 min",
    },
    {
      value: "express",
      label: (
        <Box style={webStyle.flex}>
          <img src={expressDeliveryIcon} height={28} />
          <Typography style={webStyle.shippingLabel}>
            {this.transPay("Express Delivery")}
          </Typography>
        </Box>
      ),
      price: "$20.00",
      time: "30 min",
    },
  ];

  savedPayment = [
    {
      value: "mc-745",
      label: (
        <Box style={{ display: "flex", gap: "1rem " }}>
          <img src={mastercardIcon} height={24} />
          <Typography style={webStyle.savedMethodData}>
            **** **** **** *745
          </Typography>
          ,
        </Box>
      ),
      type: "mastercard",
    },
  ];

  paymentMethods = [
    {
      value: "creditCard",
      label: (
        <Box id="creditcard">
          <Typography id="creditText" style={webStyle.paymentLabel}>{this.transPay("Credit Card")}</Typography>
          <Typography id="creditText2" style={{ color: "#3C4242" }}>
            {this.transPay("We accept all major credit cards.")}
          </Typography>
        </Box>
      ),
      subText: (
        <Box id="creditcard1" style={{ ...webStyle.savedPaymentItem, ...webStyle.debitCardAdd }}>
        <Box style={{ ...webStyle.flex, gap: "1rem" }} id="creditBox">
          <img src={debitCardIcon} alt="credit card" height={24} />
          <Typography id="creditTex3" style={webStyle.savedMethodData}>
            {this.transPay("Add credit card")}
          </Typography>
        </Box>
        <Typography id="creditText4" style={webStyle.addDebitBtn} data-test-id="changeActiveCard" onClick={() => this.changeActiveAddCardForm("creditCard")}>{this.transPay("ADD")}</Typography>
      </Box>
      ),
    },
    {
      value: "debitCard",
      label: (
        <Box id="debitcard">
          <Typography id="debitText" style={webStyle.paymentLabel}>{this.transPay("Debit Card")}</Typography>
          <Typography id="debitText2" style={{ color: "#3C4242" }}>
            {this.transPay("We accept all major Debit Cards.")}
          </Typography>
        </Box>
      ),
      subText: (
        <Box id="debitcard1" style={{ ...webStyle.savedPaymentItem, ...webStyle.debitCardAdd }}>
          <Box style={{ ...webStyle.flex, gap: "1rem" }} id="debitBox">
            <img src={debitCardIcon} height={24} alt="debit card"/>
            <Typography style={webStyle.savedMethodData} id="debitText3">
              {this.transPay("Add debit card")}
            </Typography>
          </Box>
          <Typography id="debitText4" style={webStyle.addDebitBtn} data-test-id="changeActiveCard" onClick={() => this.changeActiveAddCardForm("debitCard")}>{this.transPay("ADD")}</Typography>
        </Box>
      ),
    },
    {
      value: "googlePay",
      label: (
        <Box style={webStyle.spaceBetween} id="googlePay">
          <Typography style={webStyle.paymentLabel}>{this.transPay("Google Pay")}</Typography>
          <img src={googlePayIcon} height={20} />
        </Box>
      ),
    },
    {
      value: "applePay",
      label: (
        <Box style={webStyle.spaceBetween} id="applePay">
          <Typography style={webStyle.paymentLabel}>{this.transPay("Apple Pay")}</Typography>
          <img src={applePayIcon} height={23} />
        </Box>
      ),
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {this.state.stylishBuyer ? <StylishBuyerHeader navigation={this.props.navigation} />:<Header navigation={this.props.navigation} />}
        <Box style={{ backgroundColor: "rgb(248, 248, 248)",padding:"5px"}}>
        <CheckoutSteps activeBread="payment" transStepLang={this.transPay} />
        </Box>
        <Box style={webStyle.mainDiv} dir={i18n.dir()}>
          <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
            <Typography style={{ ...webStyle.subTitle, fontSize: "1.5rem" }}>
              {this.transPay("Shipping Information")}
            </Typography>
            <Box style={webStyle.billingBlock}>
              <Box style={webStyle.addressMain}>
                <Box>
                  <Typography
                    style={{
                      ...webStyle.addressHead,
                      ...webStyle.addressData,
                      textAlign: "center",
                    }}
                  >
                    {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.name}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    style={{ ...webStyle.addressHead, ...webStyle.addressData }}
                  >
                    {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.contact_number}
                  </Typography>
                </Box>
                <Typography data-test-id="proceedToShippingAddressPage" onClick={this.proceedToShippingAddressPage} style={webStyle.addressContextMenu}>{this.transPay("Edit")}</Typography>
              </Box>
              <Box>
                <Typography style={webStyle.fullAddress}>
                {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.house_or_building_number}, {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.street}, {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.area}
                </Typography>
                <Typography style={webStyle.fullAddress}>
                {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.city} - {this.state.AddAddressData?.attributes?.delivery_addresses?.attributes?.zip_code}
                </Typography>
              </Box>
            </Box>
            <Typography style={webStyle.subTitle}>{this.transPay("Shipping Method")}</Typography>
            <Box style={webStyle.shippingBlock}>
              <FormControl fullWidth>
                <Box>
                  <Box style={{...webStyle.flex, justifyContent: "space-between", alignItems: "center"}}>
                    <Box style={{...webStyle.flex}}>
                    <img src={expressDeliveryIcon} height={28} />
                    <Typography style={webStyle.shippingLabel}>
                      {this.transPay("Express Delivery")}
                    </Typography></Box><Typography style={webStyle.shippingLabel}>{this.state.currencySign} {this.state.ActiveCartView?.attributes?.shipping_total}</Typography>
                  </Box>
                  <Box>
                  <Box style={webStyle.spaceBetween}>
                        <Box style={{marginLeft:'45px'}}>
                          <Typography style={webStyle.fullAddress}>
                            {this.transPay("Estimated Delivery time")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            style={{
                              ...webStyle.fullAddress,
                              paddingLeft: 0,
                              textAlign: "end",
                            }}
                          >
                            {this.state.ActiveCartView?.attributes?.estimated_delivery_time ?? ""}
                          </Typography>
                        </Box>
                      </Box>
                  </Box>
                </Box>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={12}
            xs={12}
          >
            <Box style={webStyle.summary}>
            <Box mt="0.5rem" mb="1rem">
              <Typography style={webStyle.summaryTitle}>
                {this.transPay("Order Summary")}
              </Typography>
            </Box>
            <Divider style={{ borderColor: "#EDEEF2" }} />
            {this.state.ActiveCartView?.attributes?.order_items?.map((item, number) => (
            <Box style={{ ...webStyle.flex, gap: "1rem" }} my="1rem">
              <Box>
                <img
                  src={item.attributes.catalogue_variant_front_image ? item.attributes.catalogue_variant_front_image : Noimageavailable}
                  width={63}
                  height={63}
                  style={{ borderRadius: "4px" }}
                />
              </Box>
              <Box my="auto" width="100%">
                <Typography style={webStyle.itemName}>
                  {item.attributes.catalogue_name}{" "}
                  <span style={webStyle.fullAddress}>x {item.attributes.quantity}</span>
                </Typography>
                <Box style={webStyle.spaceBetween}>
                  <Typography style={webStyle.itemName}>
                    {this.transPay("Color :")} <span style={webStyle.fullAddress}>{item.attributes.catalogue_variant_color}</span>
                  </Typography>
                  <Typography style={webStyle.fullAddress}>{this.state.currencySign} {item.attributes.total_price}</Typography>
                </Box>
              </Box>
            </Box>
              ))}
            <Divider style={{ borderColor: "#EDEEF2" }} />
            <Box mt="1rem" mb="2rem">
              <Box style={webStyle.spaceBetween} mb="0.75rem">
                <Typography style={webStyle.savedMethodData}>
                  {this.transPay("Item total")} <span style={webStyle.SubtotalColor}>( {this.state.ActiveCartView?.attributes?.order_item_count} {this.transPay("items")} )</span>
                </Typography>
                <Typography style={webStyle.savedMethodData}>{this.state.currencySign} {this.state.ActiveCartView?.attributes?.sub_total}</Typography>
              </Box>
              <Box style={webStyle.spaceBetween} mb="0.75rem">
                <Typography style={webStyle.savedMethodData}>
                  {this.transPay("Delivery Charges")}
                </Typography>
                <Typography style={webStyle.savedMethodData}>
                  {this.state.currencySign} {this.state.ActiveCartView?.attributes?.shipping_total}
                </Typography>
              </Box>
              {+this.state.ActiveCartView?.attributes?.applied_discount > 0 && <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={{...webStyle.savedMethodData,color:'#01835c'}}>
                  {this.transPay("Voucher")}
                </Typography>
                <Typography style={{...webStyle.savedMethodData,color:'#01835c'}} dir="ltr">-{this.state.currencySign} {this.state.ActiveCartView?.attributes?.applied_discount}</Typography>
              </Box>}
               {Number(this.state.ActiveCartView?.attributes?.loyalty_points_discount) > 0 && <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={{...webStyle.savedMethodData,color:'#01835c'}}>
                  {this.transPay("Loyalty Points")}
                </Typography>
                <Typography style={{...webStyle.savedMethodData,color:'#01835c'}} dir="ltr">-{this.state.currencySign} {this.state.ActiveCartView?.attributes?.loyalty_points_discount}</Typography>
              </Box>}
              <Divider style={{ borderColor: "#EDEEF2" }} />
              <Box style={webStyle.spaceBetween} my="0.75rem">
                <Typography style={webStyle.totalText}>{this.transPay("Total Cost")}</Typography>
                <Typography style={webStyle.totalText}>{this.state.currencySign} {this.state.ActiveCartView?.attributes?.total}</Typography>
              </Box>
            </Box>
            <Button style={webStyle.placeOrderBtn} data-test-id="placeOrderApi" onClick={this.checkOutBeforeStore}>{this.transPay("Place Order")}</Button>
            </Box>
            <Box style={{marginTop:'41px'}}>
              <Box style={webStyle.summary}>
                <Box>
                  <Typography style={webStyle.discount}>{this.transPay("Promo Codes")}</Typography>
                  <Typography style={{ ...webStyle.disc, margin: "5px 0 15px 0" }}>{this.transPay("Enter your coupon code if you have one")}</Typography>
                  <Box style={webStyle.inputBox} >
                    <InputBase
                      style={{ padding: '0 10px' }}
                      value={this.state.inputPromrValue}
                      onChange={this.handleInputChange}
                      placeholder={this.transPay("Please enter promo code")}
                      data-test-id="inputPromrValue"
                      type="text"
                    />
                    <Button style={{ ...webStyle.button, textTransform: "none", cursor: 'pointer',borderRadius:"0px" }} data-testid="promoCode" onClick={this.promoCodeButton} >{this.state.appliedCoponCode === null ? this.transPay("Apply Coupon") : this.transPay("Remove Coupon")}</Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>
        <Modal open={this.state.isModalOpen} style={webStyle.flex} dir={i18n.dir()}>
          <Box style={webStyle.modalBox} dir={i18n.dir()}>
            <img src={modalSuccessImg} />
            <Typography style={webStyle.modalHead}>{this.transPay("Great!")}</Typography>
            <Typography style={{ fontSize: "1.5rem" }}>
              {this.transPay("Your order has been placed successfully.")}
            </Typography>
            <Box style={webStyle.loyaltyPointsString}>
              <img src={loyaltyBadgeIcon} />
              <Typography style={{ fontSize: "1.375rem" }}>
                {this.transPay("80 Loyalty points on the way.")}
              </Typography>
            </Box>
            <Button style={{ ...webStyle.placeOrderBtn2 }} data-test-id="proceedToTrackOrderPage" onClick={this.proceedToTrackOrderPage}>
              {this.transPay("All Orders")}
            </Button>
          </Box>
        </Modal>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-testid="alertTestId"
          dir={i18n.dir()}
        ><Alert variant="filled" severity={this.state.alertType}>{this.state.errorLabale}</Alert></Snackbar>
        <Footer navigation={this.props.navigation}/>
        <CommonBackdrop open={this.state.isBackDrop} />
        <Dialog open={this.state.isModalCheckOutOpen} dir={i18n.dir()} data-test-id="no-store-dialog" onClose={()=>this.storeClose()}>
          <NotStore>
            <Typography className="message-class">{this.state.notStoreMessage}</Typography>
            <Box className="store-order-item">
        {this.state.notStoreItems?.map((item, number) => (
            <Box className="order-item-main-container" my="1rem">
              <Box>
                <img
                  src={item.attributes.catalogue_variant_front_image ? item.attributes.catalogue_variant_front_image : Noimageavailable}
                  width={63}
                  height={63}
                  className="order-item-img"
                />
              </Box>
              <Box my="auto" width="100%">
                <Typography style={webStyle.itemName} id={`order-item-id-${number}`}>
                  {item.attributes.catalogue_name}{" "}
                  <span style={webStyle.fullAddress} id={`order-item-count-${number}`}>x {item.attributes.quantity}</span>
                </Typography>
                <Box style={webStyle.spaceBetween} id={`order-item-color-${number}`}>
                  <Typography style={webStyle.itemName} id={`order-item-color-name-${number}`}>
                    {this.transPay("Color :")} <span style={webStyle.fullAddress}>{item.attributes.catalogue_variant_color}</span>
                  </Typography>
                  <Typography style={webStyle.fullAddress} id={`order-item-amount-${number}`}>{this.state.currencySign} {item.attributes.total_price}</Typography>
                </Box>
              </Box>
            </Box>
              ))}
              </Box>
              </NotStore>
        </Dialog>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  button1: { cursor: "pointer", color: "#375280", width: "232px", height: "44px", fontFamily: "Lato", fontWeight: 400, fontSize: "16px", border: "1px solid #CCBEB1", marginTop: "36px", borderRadius: "2px" },
  button: { color: "#fff", background: "#CCBEB1", width: "230px", height: "43px", fontWeight: 400, fontSize: "16px", border: "1px solid #CCBEB1", fontFamily: "Lato" },
  inputBox: { display: "flex", justifyContent: "space-between", border: "1px solid #CCBEB1", borderRadius: "2px", width: "100%", },
  disc: { fontWeight: 400, fontSize: "16px", color: "#94A3B8", fontFamily: "Lato" },
  discount: { fontWeight: 700, fontSize: "24px", fontFamily: "Lato" },
  mainDiv: {
    display: "flex",
    padding: "0 2.25rem 5.25rem",
    backgroundColor: "#F8F8F8",
    gap: "2.5rem",
    flexDirection: window.innerWidth < 768 ? "column" : "row",
  } as React.CSSProperties,
  flex: { display: "flex" },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subTitle: { fontSize: "1.375rem", fontWeight: 700, marginBottom: "0.5rem" },
  billingBlock: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 8px 0px #00000014",
    padding: "1rem 1rem 1.5rem",
    margin: "1rem 0 2.5rem",
    borderRadius: "2px",
  },
  addressMain: { display: "flex", gap: "1.25rem" },
  addressName: { backgroundColor: "#F6F6F6", textAlign: "center" as "center" },
  addressHead: { color: "#94A3B8", fontWeight: 700, padding: "0.5rem 0" },
  addressData: { color: "#375280" },
  addressContextMenu: {
    color: "#375280",
    marginRight: "0",
    marginLeft: "auto",
    cursor: "pointer"
  },
  fullAddress: { color: "#94A3B8", fontSize: "0.875rem" },
  shippingBlock: {
    padding: "2.25rem 1.75rem",
    borderRadius: "2px",
    backgroundColor: "#FFF",
    margin: "1.5rem 0 2.5rem",
  },
  shippingLabel: {
    fontSize: "1.25rem",
    color: "#334155",
    fontWeight: 700,
    paddingLeft: "1rem",
  },
  methodForm: {
    flexDirection: "row-reverse" as "row-reverse",
    justifyContent: "space-between",
    marginLeft: 0,
    marginRight: 0,
  },
  methodLabel: {
    fontSize: "1.25rem",
    color: "#334155",
    fontWeight: 700,
  },
  savedPaymentItem: {
    margin: "2rem 1.5rem",
    boxShadow: "0px 2px 8px 0px #00000014",
    borderRadius: "2px",
    width: "-webkit-fill-available",
    padding: "1rem",
  },
  savedMethodData: {
    fontSize: "1.125rem",
    fontWeight: 500,
  },
  removeButton: {
    color: "#DC2626",
    fontSize: "1.125rem",
    fontWeight: 500,
    marginTop: "0.5rem",
    cursor: "pointer",
  },
  paymentLabel: {
    fontSize: "1.25rem",
    fontWeight: 700,
    color: "#3C4242",
  },
  SubtotalColor: {
    color: "94A3B8"
  },
  cardInfoBlock: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "2rem",
    margin: "2rem 0 1.5rem",
  },
  inputLabel: {
    fontWeight: 700,
  },
  input: {
    backgroundColor: "#F8F8F8",
    marginTop: "0.5rem",
    padding: "0.5rem",
    fontSize: "1.125rem",
    color: "#375280",
  },
  switchControl: {
    gap: "0.5rem",
    marginTop: "-2rem",
  },
  addCardBtn: {
    backgroundColor: "#C7B9AD",
    marginTop: "2rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 800,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  debitCardAdd: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0",
    padding: "1.5rem 1rem",
  },
  addDebitBtn: {
    fontWeight: 600,
    textDecoration: "underline",
    textUnderlinePosition: "under",
    cursor: "pointer",
  },
  summary: {
    backgroundColor: "#FFF",
    borderRadius: "2px",
    padding: "1.5rem 1rem",
    height: "fit-content",
    boxShadow: "2px 2px 4px 0px #0000000D,-2px -2px 4px 0px #0000000D",
  },
  summaryTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  itemName: {
    fontSize: "0.875rem",
  },
  totalText: {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  placeOrderBtn: {
    width: "100%",
    backgroundColor: "#C7B9AD",
    marginTop: "1rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  placeOrderBtn2: {
    width: "75%",
    backgroundColor: "#C7B9AD",
    marginTop: "1rem",
    padding: "1rem 0",
    fontSize: "1.125rem",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    color: "#fff",
    textTransform: "none" as "none",
  },
  modalBox: {
    background: "#fff",
    width: "30vw",
    margin: "auto",
    borderRadius: "2px",
    padding: "3rem",
    textAlign: "center" as "center",
  },
  modalHead: {
    fontSize: "1.875rem",
    fontWeight: 700,
    marginTop: "2.5rem",
    marginBottom: "0.5rem",
  },
  loyaltyPointsString: {
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0 1.5rem",
    justifyContent: "center",
    gap: "0.25rem",
  },
};

const StyledCheckbox = withStyles({
  root: {
    color: "#B2A69B",
  },
  checked: {
    color: "#CCBEB1 !important",
  },
})(Checkbox);

const FormControlLabelFullWidth = withStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    width: "100%",
  },
})(FormControlLabel);

const FormControlLabelNoMargin = withStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
})(FormControlLabel);

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "36px",
      height: "20px",
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: "1px",
      top: "1px",
      "&$checked": {
        transform: "translateX(16px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#C7B9AD",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#C7B9AD",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: "16px",
      height: "16px",
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, ...props }: CustomSwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      dir={i18n.dir()}
      {...props}
    />
  );
});

const NotStore = styled(Box)({
  margin: 25,
"& .message-class":{
  fontSize: 24,
  color: "#375280",
  width: "80%",
  fontFamily: "Lato, sans-serif",
  textAlign: "center",
  marginLeft: "10%"
},
"& .store-order-item":{
  width: "100%",
  "& .order-item-main-container": {
    display: "flex", 
    gap: "1rem"
  },
  "& .order-item-img": {
    borderRadius: "4px",
  }
}
})
// Customizable Area End
