import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

import {
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  styled,
  Drawer,
  FormHelperText,
} from "@material-ui/core";
import Select from "react-select";
import InventoryPairTheProductsController, {
  OptionType,
  Props,
} from "./InventoryPairTheProductsController";
import {
  CustomStyledPagination,
  CustomStyledTableUpdateInventory,
  PaginationContainer,
  TableHeaderCell,
} from "../UpdateInventory/UpdateInventory.web";
import { crossIcon, editFillIcon, selectEndIcon } from "../../../assets";
import Loader from "../../../../../../components/src/LoaderContainer";
import { NoRecordTypoContainer } from "../AssignStore/InventoryAssignStore.web";
import ReusableSnackbar from "../../../../../../components/src/AlertContainer";
import {
  CustomInput,
  Dropdown,
  DropdownIndicator,
  selectStyles,
} from "../../../../../../components/src/Seller/ReactSelect";
import i18n from "../../../../../../components/src/i18next/i18n";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class InventoryPairTheProducts extends InventoryPairTheProductsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  selectIconRender = (isOpen: boolean) => {
    const positionValue = isOpen ? 10: 0
    const piwImgStyles = i18n.dir() === 'ltr'
    ? { right: positionValue, paddingRight: 10 }
    : { left: positionValue, paddingLeft: 10 };
    return (
    <img
      src={selectEndIcon}
      alt="dropdown"
      style={{
        position: "absolute",
        zIndex: isOpen ? 100000: 1,
        cursor: "pointer",
        transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        ...piwImgStyles
      }}
      data-test-id="select-drop-down-img"
    />)
    };

  renderPairProductPagination = () => (
    <>
      {this.state.pairProductInventoryMeta !== undefined ? (
        <PaginationContainer>
          <Box className="pagination-data-container">
            {`${
              (this.state.pairProductInventoryMeta?.current_page - 1) * 10 + 1
            } - 
              ${Math.min(
                this.state.pairProductInventoryMeta?.current_page * 10,
                this.state.pairProductInventoryMeta?.total_record
              )}
            ${this.tranPairTheProducts("of")} ${this.state.pairProductInventoryMeta?.total_record} ${this.tranPairTheProducts("results")}`}
          </Box>
          <CustomStyledPagination
            data-test-id="as-pagination-test-id"
            page={this.state.page}
            count={this.state.pairProductInventoryMeta?.total_pages}
            shape="rounded"
            color="primary"
            onChange={this.handlePairProductPageChange}
            dir={i18n.dir()}
          />
        </PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );

  renderPairProductStoreBody = () => (
    <>
      {this.state.tableBodyData.length === 0 ? (
        <TableRow>
          <TableCell className="pp-no-data" colSpan={3}>
            <NoRecordTypoContainer>{this.tranPairTheProducts("No Records Found")}</NoRecordTypoContainer>
          </TableCell>
        </TableRow>
      ) : (
        <>
          {this.state.tableBodyData?.map((assignStoreInventoryData) => (
            <TableRow key={assignStoreInventoryData.id} className="hover">
              <TableCell data-test-id="product-name">
                <Box className="product-name-container">
                  <img
                    src={assignStoreInventoryData.front_image}
                    className="img-container"
                    alt={assignStoreInventoryData.product_name}
                  />
                  <Typography className="product-title">
                    {assignStoreInventoryData.product_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography className="product-sku">
                  {assignStoreInventoryData.sku}
                </Typography>
              </TableCell>
              <TableCell>
                {assignStoreInventoryData.pair_it_with.length === 0 ? (
                  <PairItWithButton
                    data-test-id={`btn-pair-product-${assignStoreInventoryData.id}`}
                    onClick={() =>
                      this.openDrawer("add", assignStoreInventoryData.id, {
                        name: assignStoreInventoryData.product_name,
                        image: assignStoreInventoryData.front_image,
                      })
                    }
                  >
                    {this.tranPairTheProducts("Pair It With")}
                  </PairItWithButton>
                ) : (
                  <EditBoxContainer>
                    <Typography className="sku-pair-title">
                      {assignStoreInventoryData.pair_it_with.join(", ")}
                    </Typography>
                    <img
                      src={editFillIcon}
                      alt="edt-ic"
                      onClick={() =>
                        this.openDrawer("edit", assignStoreInventoryData.id, {
                          name: assignStoreInventoryData.product_name,
                          image: assignStoreInventoryData.front_image,
                        })
                      }
                      style={{cursor: "pointer"}}
                      data-test-id={`btn-pair-product-${assignStoreInventoryData.id}`}
                    />
                  </EditBoxContainer>
                )}
              </TableCell>
            </TableRow>
          ))}
        </>
      )}
    </>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <CustomStyledTableUpdateInventory>
          <Table stickyHeader size="small" className="custom-table">
            <TableHead>
              <TableRow
                style={{ backgroundColor: "#f0f0f0" }}
                className="as-table-head-row"
              >
                {this.state.tableHeadName.map(
                  (header: string, index: number) => (
                    <TableHeaderCell key={index} className="as-table-head-cell">
                      <Typography className="table-head-title">{this.tranPairTheProducts(header)}</Typography>
                    </TableHeaderCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.isLoading ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Loader
                      id="step-4-loader"
                      dataTestId="step-4-loader-test"
                    />
                  </TableCell>
                </TableRow>
              ) : (
                this.renderPairProductStoreBody()
              )}
            </TableBody>
          </Table>
        </CustomStyledTableUpdateInventory>
        {this.state.pairProductInventoryMeta.total_record > 10 &&
          this.renderPairProductPagination()}
        <DrawerContainer
          anchor={i18n.dir()==="ltr" ? "right" :"left"}
          open={this.state.isDrawerOpen}
          data-test-id="drawer-test-id"
          dir={i18n.dir()}
        >
          <Box className="drawer-header">
            <Typography className="drawer-header-title">
              {this.tranPairTheProducts("Pair It With")}
            </Typography>
            <img
              className="cross-img"
              src={crossIcon}
              onClick={() => this.toggleDrawer()}
              alt="cross-ic"
              style={{cursor: "pointer"}}
              data-test-id="cross-test-id"
            />
          </Box>
          <Box className="product-img-container">
            <img
              className="product-img"
              src={this.state.product.image}
              alt=""
            />
          </Box>
          <Typography className="product-name">
            {this.state.product.name}
          </Typography>
          <Typography className="description">
            {this.tranPairTheProducts("Pair It With Description")}
          </Typography>
          <Typography className="sku-description">
            {this.tranPairTheProducts("Add SKU’s (Up to 5)")}
          </Typography>
          <Dropdown
            isOpen={this.state.pairSelect.sku1.isOpen}
            onClose={()=>this.toggleOpen("sku1") }
            target={
              <SKUSelect onClick={()=>this.toggleOpen("sku1") } data-test-id="select-drop-down-1">
                <Typography className="selected-value">
                  {this.state.pairSelect.sku1.value
                    ? `${this.state.pairSelect.sku1.value.label}`
                    : "Select The SKU"}
                </Typography>
                {this.selectIconRender(this.state.pairSelect.sku1.isOpen)}
              </SKUSelect>
            }
            data-test-id="dropdown-sku-1"
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(value, actionMeta)=>this.handleSelectChange('sku1', value, actionMeta)}
              data-test-id="select-sku-1"
              options={this.getFilteredOptions("sku1")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku1.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku2.isOpen}
            data-test-id="dropdown-sku-2"
            onClose={()=>this.toggleOpen("sku2")}
            target={
              <SKUSelect data-test-id="select-drop-down-2" onClick={()=>this.toggleOpen("sku2")}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku2.value
                    ? `${this.state.pairSelect.sku2.value.label}`
                    : "Select The SKU"}
                </Typography>
                {this.selectIconRender(this.state.pairSelect.sku2.isOpen)}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              data-test-id="select-sku-2"
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(value, actionMeta)=>this.handleSelectChange('sku2', value, actionMeta)}
              options={this.getFilteredOptions("sku2")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku2.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku3.isOpen}
            onClose={()=>this.toggleOpen("sku3")}
            data-test-id="dropdown-sku-3"
            target={
              <SKUSelect data-test-id="select-drop-down-3" onClick={()=>this.toggleOpen("sku3")}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku3.value
                    ? `${this.state.pairSelect.sku3.value.label}`
                    : "Select The SKU"}
                </Typography>
                {this.selectIconRender(this.state.pairSelect.sku3.isOpen)}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-3"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(value, actionMeta)=>this.handleSelectChange('sku3', value, actionMeta)}
              options={this.getFilteredOptions("sku3")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku3.value}
            />
          </Dropdown>
          <Dropdown
            data-test-id="dropdown-sku-4"
            isOpen={this.state.pairSelect.sku4.isOpen}
            onClose={()=>this.toggleOpen("sku4")}
            target={
              <SKUSelect onClick={()=>this.toggleOpen("sku4")} data-test-id="select-drop-down-4">
                <Typography className="selected-value">
                  {this.state.pairSelect.sku4.value
                    ? `${this.state.pairSelect.sku4.value.label}`
                    : "Select The SKU"}
                </Typography>
                {this.selectIconRender(this.state.pairSelect.sku4.isOpen)}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-4"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(value, actionMeta)=>this.handleSelectChange('sku4', value, actionMeta)}
              options={this.getFilteredOptions("sku4")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku4.value}
            />
          </Dropdown>
          <Dropdown
            isOpen={this.state.pairSelect.sku5.isOpen}
            onClose={()=>this.toggleOpen("sku5")}
            data-test-id="dropdown-sku-5"
            target={
              <SKUSelect data-test-id="select-drop-down-5" onClick={()=>this.toggleOpen("sku5")}>
                <Typography className="selected-value">
                  {this.state.pairSelect.sku5.value
                    ? `${this.state.pairSelect.sku5.value.label}`
                    : "Select The SKU"}
                </Typography>
                {this.selectIconRender(this.state.pairSelect.sku5.isOpen)}
              </SKUSelect>
            }
          >
            <Select<OptionType, false>
              autoFocus
              backspaceRemovesValue={false}
              components={{
                DropdownIndicator,
                IndicatorSeparator: null,
                Input: CustomInput,
              }}
              data-test-id="select-sku-5"
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={false}
              menuIsOpen
              onChange={(value, actionMeta)=>this.handleSelectChange('sku5', value, actionMeta)}
              options={this.getFilteredOptions("sku5")}
              placeholder="Search..."
              styles={selectStyles}
              tabSelectsValue={false}
              value={this.state.pairSelect.sku5.value}
            />
          </Dropdown>
          {this.state.error && (
            <FormHelperTextContainer data-test-id="error-test-id">
              {this.state.errorMessage}
            </FormHelperTextContainer>
          )}
          <SaveBtnContainer
            data-test-id="save-btn-test-id"
            onClick={() => this.handlePairProductSave()}
          >
            {this.tranPairTheProducts("Save")}
          </SaveBtnContainer>
        </DrawerContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertPPSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const SKUSelect = styled(Box)({
  marginTop: 16,
  width: "100%",
  paddingLeft: 18,
  height: 56,
  display: "flex",
  alignItems: "center",
  borderRadius: 2,
  backgroundColor: "#F8F8F8",
  "& .selected-value": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 14,
    color: "#375280",
  },
});

const FormHelperTextContainer = styled(FormHelperText)({
  paddingTop: 15,
  color: "f44336",
});

const SaveBtnContainer = styled(Box)({
  marginBottom: 70,
  marginTop: 20,
  backgroundColor: "#CCBEB1",
  fontFamily: "Lato, sans-serif",
  fontWeight: 800,
  fontSize: 20,
  display: "flex",
  color: "#FFFFFF",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 2,
  padding: "10px 16px 10px 16px",
  height: 56,
  cursor: "pointer"
});

const PairItWithButton = styled(Box)({
  width: 114,
  height: 34,
  borderRadius: 1,
  backgroundColor: "#CCBEB1",
  color: "#fff",
  fontFamily: "Lato, sans-serif",
  fontWeight: 700,
  fontSize: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
});

const EditBoxContainer = styled(Box)({
  height: 24,
  display: "flex",
  alignItems: "center",
  gap: 10,
  "& .sku-pair-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#375280",
  },
});

const DrawerContainer = styled(Drawer)({
  "& .MuiPaper-root.MuiDrawer-paper": {
    padding: 30,
    width: 440,
    "& .drawer-header": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .drawer-header-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 700,
        fontSize: 24,
        color: "#375280",
      },
      "& .cross-img": {
        transform: "rotate(90deg)",
      },
    },
    "& .product-img-container": {
      padding: "30px 0px",
      display: "flex",
      justifyContent: "center",
      "& .product-img": {
        width: 245,
        height: 285,
      },
    },
    "& .product-name": {
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 24,
      color: "#375280",
      width: "100%",
      textAlign: "center",
    },
    "& .description": {
      width: "100%",
      fontFamily: "Lato, sans-serif",
      fontWeight: 400,
      fontSize: 16,
      color: "#375280",
      padding: "16px 0px 20px 0px",
    },
    "& .sku-description": {
      paddingBottom: 16,
      width: "100%",
      fontFamily: "Lato, sans-serif",
      fontWeight: 700,
      fontSize: 16,
      color: "#375280",
    },
  },
});
// Customizable Area End
