
import React from "react";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Box,
    TableContainer,
    Table, TableBody, TableRow, TableCell, TableHead,
    Button, Backdrop,
    Fade, MenuItem
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import StylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";
import { Noimageavailablepng, addCircle, buttonClose, dcubesvg, groupImage, shoppingBag, timerPause, timerPause1 } from "./assets";
// Customizable Area End

import StylishDashboardController, {
    NewStylistData,
    Props
} from "./StylishDashboardController";
import { CustomSelectNewOrder, MuiSelectSelectNewOrder, StyledButton1ModelNewOrder, StyledButtonModelNewOrder, StyledDrawerOrder } from "./Dashboard.web";
import { TableBodyCell } from "../../customform/src/Stylist/Clients/StylishClients.web";
import { DialogSuccessRejectContainer, PreviewRequestImg, RequestBtnContainer, RequestDetailContainer, ViewRequestDialog, ViewRequestDialogContainer } from "../../customform/src/Stylist/Clients/StylingRequests.web";
import { rejectIcon, storeSuccessIcon } from "../../customform/src/assets";
import i18n from "../../../components/src/i18next/i18n";


export default class StylistDashboard extends StylishDashboardController {
    // Customizable Area Start

    orderLabelAndStyle = (value: string, isPopUp: boolean = false) => {
        let backgroundStyle = {}
        let label = ''
        switch (value) {
            case 'pending':
                backgroundStyle = webStyle.shippedpendingBox
                label = 'New Request'
                break;
            case 'new_order':
                backgroundStyle = webStyle.new_orderBox
                label = 'New Order'
                break;

        }
        return (<Box   style={{
            ...backgroundStyle,
            height: isPopUp ? 15 : 27,
            borderRadius: isPopUp ? 5 : 2,
          }}>{this.transStylishDashboard(label)}</Box>)
    }

    formatTimeString = (time: string): string => {
      const suffix = time.slice(-2).toUpperCase();
      return `${time.slice(0, -2)} ${this.transStylishDashboard(suffix)}`;
    };

    renderTopCards = () => {
      return <Box style={webStyle.containerStyle}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <div style={webStyle.boxStyle}>
                <Box style={{ gap: 16, display: 'flex' }}>
                    <img src={dcubesvg} style={webStyle.imgBakColor} />
                    <Box>
                        <Box style={webStyle.lableText as React.CSSProperties}>{this.transStylishDashboard("Total")}</Box>
                        <Box style={webStyle.numberBox}>{this.state.orderCountData?.total_orders}</Box>
                    </Box>
                </Box>
            </div >
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div style={webStyle.boxStyle}>
              <Box style={{ display: 'flex', gap: 16 }}>
                <img src={timerPause1} style={webStyle.imgBakColor} />
                <Box>
                    <Box style={webStyle.lableText as React.CSSProperties}>{this.transStylishDashboard("Pending")}</Box>
                    <Box style={webStyle.numberBox}>{this.state.orderCountData?.total_new_orders}</Box>
                </Box>
              </Box>
            </div >
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div style={webStyle.boxStyle}>
              <Box style={{ gap: 16, display: 'flex' }}>
                <img src={shoppingBag} style={webStyle.imgBakColor} />
                <Box>
                    <Box style={webStyle.lableText as React.CSSProperties}>{this.transStylishDashboard("In Progress")}</Box>
                    <Box style={webStyle.numberBox}>{this.state.orderCountData?.total_in_process_orders}</Box>
                </Box>
              </Box>
            </div >
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div style={webStyle.boxStyle}>
              <Box style={{ display: 'flex', gap: 16 }}>
                <img src={timerPause} style={webStyle.imgBakColor} />
                <Box>
                    <Box style={webStyle.lableText as React.CSSProperties}>{this.transStylishDashboard("Accepted")}</Box>
                    <Box style={webStyle.numberBox}>{this.state.orderCountData?.total_in_process_orders}</Box>
                </Box>
              </Box>
            </div >
          </Grid>
        </Grid>
      </Box>  
    }

    renderNewStylistRequest = () => {
      return <>
        <Box sx={webStyle.secondaryContainer}>
          <Typography style={webStyle.newOrdersText}>{this.transStylishDashboard("New Styling Requests")}{" "}({this.state.newStylistRequestData.length})</Typography>
          <Box style={webStyle.secondBox} data-test-id="viewAllClickSecond" onClick={()=> this.headerNavigation("StylingRequests")}>
            <Typography id="newStylistView" style={webStyle.viewAllText}>{this.transStylishDashboard("View All")}</Typography>
          </Box>
        </Box>
        <CustomStyledTableContainer id="newStylistContainer">
          <Table id="newStylistViewTable" stickyHeader  size="small"  className="custom-table">
            <TableHead id="newStylistViewTableHead" >
              <TableRow id="newStylistViewTableRow"  style={{ backgroundColor: '#f0f0f0' }}>
                <TableCell id="newStylistViewTableCell1"  style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Request ID")}</Typography></TableCell>
                <TableCell id="newStylistViewTableCell2"  style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Request Time")}</Typography></TableCell>
                <TableCell id="newStylistViewTableCell3" style={{...webStyle.tableHader, width: "30%"}}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Name & Gender")}</Typography></TableCell>
                <TableCell id="newStylistViewTableCell4"  style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Budget")}</Typography></TableCell>
                <TableCell id="newStylistViewTableCell5"  style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Status")}</Typography></TableCell>
                <TableCell id="newStylistViewTableCell6" style={{...webStyle.tableHader, width: "241px" }}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Action")}</Typography></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
            {this.state.newStylistRequestData.length > 0 ? (
              this.state.newStylistRequestData.map((element: NewStylistData, index: number) => {
                return(
                  <>
                    {index < 2 && (
                      <TableRow className="hover" key={index}>
                        <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'middle' }}><Box style={{...webStyle.tableHeaderName}}>#{element.attributes.stylist_id}-{element.id}</Box></TableCell>
                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'middle' }}><Typography variant="h6" style={{...webStyle.tableHeaderName}}>{this.formatTimeString(element.attributes.created_at)}</Typography></TableCell>
                        <TableCell>
                          <Box style={{ display: 'flex', gap:10, alignItems: "center"}} >
                            <Box>
                              <img src={element.attributes.stylist_profile} style={{ width: '34px', height: '34px', borderRadius: '2px' }} />
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: "column"}} >
                              <Typography style={{ ...webStyle.tableHaderName as React.CSSProperties }}> {element.attributes.buyer_name}</Typography>
                              <Typography style={{ ...webStyle.tableHaderrow, fontSize: 14, textTransform: "capitalize" }}>{element.attributes.gender}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'middle' }}><Typography variant="h6" style={{...webStyle.tableHeaderName}}>${element.attributes.min_price} - ${element.attributes.max_price}</Typography></TableCell>
                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'middle' }}>{this.orderLabelAndStyle(element.attributes.status)}</TableCell>
                        <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'middle' }}>
                          <Button style={{ ...webStyle.acceptOrder as React.CSSProperties }} onClick={()=> {this.handleViewPopup(element)}}
                            data-testid={`handleOkAcceptOrder-${index}`}>{this.transStylishDashboard("View Request")}</Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )
            })): (
            <TableRow>
              <TableBodyCell colSpan={6} style={{ textAlign: "center",color:'#375280',fontFamily:'Lato',fontWeight:700,fontSize:'16px' }}>
                {this.transStylishDashboard("No New Styling Requests Available")}
              </TableBodyCell>
            </TableRow>
            )}
            </TableBody>
          </Table>
        </CustomStyledTableContainer>
      </>
    }

    renderNewOrders = () => {
      return <>
        <Box sx={webStyle.secondaryContainer}>
          <Typography style={webStyle.newOrdersText}>{this.transStylishDashboard("New Orders")}{" "}({this.state.newOrderData.length})</Typography>
          <Box 
            style={webStyle.secondBox} data-test-id="viewAllClicked"  
            onClick={()=> this.headerNavigation("SellersAllOrderDetailsWebPage")}
          >
            <Typography style={webStyle.viewAllText}>{this.transStylishDashboard("View All")}</Typography>   
          </Box>
        </Box>
        <CustomStyledTableContainer>
            <Table   stickyHeader  size="small"  className="custom-table">
              <TableHead>   
                <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                  <TableCell id="newStylistViewTable"  style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Order ID")}</Typography></TableCell>
                  <TableCell style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Order Time")}</Typography></TableCell>
                  <TableCell style= {{...webStyle.tableHader, width: "30%"}}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Products")} </Typography></TableCell>  
                  <TableCell style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Total Price")}</Typography></TableCell>
                  <TableCell style={webStyle.tableHader}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Status")}</Typography></TableCell>
                  <TableCell style={{...webStyle.tableHader, minWidth: "241px"}}><Typography variant="h6" style={webStyle.variantText}>{this.transStylishDashboard("Action")}</Typography></TableCell>
                </TableRow>
              </TableHead>
                <TableBody>
                  {this.state.newOrderData?.length > 0 ? (this.state.newOrderData?.map((orderData: any, index:number)=> (
                    <TableRow  className="hover" key={index} >  
                      <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top', maxWidth: 141 }}>
                        <Typography variant="h6" style={{...webStyle.tableHaderrow}}>#{orderData.attributes.order_management_order.attributes.order_number}</Typography>
                      </TableCell>
                      <TableCell  style={{ ...webStyle.tableHaderrow,  verticalAlign: 'top', maxWidth: 162 }}>
                      <Typography variant="h6" style={{...webStyle.tableHaderrow}}>{this.dateFormetTime(orderData.attributes.order_items[0]?.attributes.placed_at)}</Typography></TableCell>
                      <TableCell  style= {{ ...webStyle.tableHaderrow, verticalAlign: 'top'}}>
                        {orderData.attributes.order_items?.map((value:any, index: number) => {  
                          return (  
                            <Box style={{ display: 'flex' }} key={index} data-testid={`navigateTo=${orderData.attributes.order_management_order.attributes.order_number}`}>
                              <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px", marginRight: "10px" }} />  </Box>  
                              <Box style={{ ...webStyle.tableHaderrow }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                            </Box>
                            );
                        })}
                      </TableCell>  
                      <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography variant="h6" style={{...webStyle.tableHaderrow}}>${orderData.attributes.order_items[0]?.attributes.total_price}</Typography></TableCell>
                      <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography variant="h6" style={{...webStyle.tableHaderrow}}>{this.orderLabelAndStyle(orderData.attributes.status)}</Typography></TableCell>
                      <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                          <Button style={{ ...webStyle.rejectButton, textTransform: "capitalize", width: "35%" }} data-testid={`handleOpen1Reject=${index}`}
                            onClick={() => this.handleRejectOpen(orderData.attributes.id)}
                            >{this.transStylishDashboard("Reject")}</Button>
                          <Button style={{ ...webStyle.acceptOrder2 as React.CSSProperties }} 
                          onClick={() => this.handleAcceptOrder(orderData.attributes.id)}
                            data-testid={`handleOkAcceptOrderClick-${index}`}>{this.transStylishDashboard("Accept Order")} (0:29)</Button>
                      </TableCell>
                    </TableRow>
                  ))) : (
                    <TableRow>
                      <TableBodyCell colSpan={6} style={{ textAlign: "center",color:'#375280',fontFamily:'Lato',fontWeight:700,fontSize:'16px' }}>
                        {this.transStylishDashboard("There are no new orders")}
                      </TableBodyCell>
                    </TableRow>
                  )}
                </TableBody>
            </Table>
        </CustomStyledTableContainer>
      </>
    }

    renderDashoardContent = () => {
      const { stylistType } = this.state;
      if(stylistType === "landing"){
        return <>
          {this.renderTopCards()}
          {this.renderNewStylistRequest()}
          {this.renderNewOrders()}
        </>
      } else {
        return <NoAccessText>{this.transStylishDashboard("Your account is under the review. You will get a confirmation in your email inbox soon.")}</NoAccessText>
      }
    }

    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    

    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const paginationStart = (this.state.metaDataNewOrder?.current_page - 1) * ROWS_PER_PAGE + 1;
        const paginationendIndex = Math.min(this.state.metaDataNewOrder?.current_page * ROWS_PER_PAGE, this.state.metaDataNewOrder?.total_record);
        return (
            // Required for all blocks
            <>
               <StylishContainer role="Stylish" navigation={this.props.navigation}>
                  {this.renderDashoardContent()}
                <StyledDrawerOrder
                      aria-describedby="transition-modal-description" aria-labelledby="transition-modal-title"  
                    onClose={this.handleCloseDialog}   BackdropProps={{
                        timeout: 500}}
                        open={this.state.rejectDialogOpen}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        data-test-id="rejectDialogBox">
                    <Fade  in={this.state.rejectDialogOpen } style={webStyle.rejectBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }} >
                              <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                                <img src={buttonClose} onClick={this.handleCloseDialog} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameNewOrder,  textAlign: "center" }}>{this.transStylishDashboard("Reject Order")}</h2>  
                            <h2 id="transition-modal-title"  style={{...webStyle.confomMasegNewOrder,textAlign: "center" }}>{this.transStylishDashboard("Are you sure you want to reject the order?")}</h2>  
                               <Box style={{ marginBottom: '30px' }}>
                                   <Box style={webStyle.modelText}>{this.transStylishDashboard("Reason of Rejection")}</Box>
                          <CustomSelectNewOrder variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelectNewOrder
                                        value={this.state.rejectReason} inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        MenuProps={{anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            getContentAnchorEl: null
                                        }}  
                                        displayEmpty
                                        onChange={this.handleRejectReasonChange}
                                        data-testid='sub-categories-selection'
                                        style={webStyle.reminderName}
                                    ><MenuItem  value=""  disabled>

                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>
                                                {this.transStylishDashboard("Out of Stock")}</Box>  
                                        </MenuItem>
                 {this.state.rejectSelectOption.map((select: string, index: number) => (
  <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{select}</MenuItem>))}
     </MuiSelectSelectNewOrder>
                                  </CustomSelectNewOrder >
                              </Box>
  <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                         <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleCloseDialog}>{this.transStylishDashboard("Close")}</StyledButtonModelNewOrder>
                         <StyledButton1ModelNewOrder  style={{ fontSize: "14px" }}   data-testid="rejectData"  onClick={this.rejectDataApiCall}>{this.transStylishDashboard("Reject")}</StyledButton1ModelNewOrder>  
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>

                <StyledDrawerOrder
                    closeAfterTransition
                    data-test-id = "AcceptDialog"  
                    onClose={this.handleAcceptDialogClose}
                    aria-labelledby="transition-modal-title"
                    open={this.state.acceptOrderOpen}
                    aria-describedby="transition-modal-description"
                    BackdropProps={{
                        timeout: 500,   
                    }}  
                    dir={i18n.dir()}
                    BackdropComponent={Backdrop}  
                ><Fade  in={this.state.acceptOrderOpen}  style={webStyle.rejectBox}>  
                        <div  style={{ padding: "10px 30px 30px 30px" } }>
                            <Box  style={{ display: 'flex', width: '100%', justifyContent: 'end' }}>
                                <img 
                            onClick={this.handleAcceptDialogClose} src={buttonClose} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}> 
                            {this.transStylishDashboard("Accept Order")}</h2>
                            <h2 style={{ textAlign: "center", ...webStyle.confomMasegNewOrder}}>
                            {this.transStylishDashboard("Are you sure you want to accept the order?")}</h2>

                            <Box style={{ marginBottom: '30px' }}>    
<Box style={webStyle.modelText}>  {this.transStylishDashboard("Set order reupload time")}
</Box>
                    <Box style={{ backgroundColor: "#F8F8F8", padding: "10px", borderRadius: "2px" }}>  
                                      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
       <Button onClick={this.decrementTime} disabled={this.state.reloadTime <= 1} data-testid="decrementTime">
        <img width="24px" height="24px" src={groupImage} /></Button>
                                        <Box style={webStyle.timeBox}>  
  <Typography variant="body1">{this.state.reloadTime}</Typography> 
                                             <Typography variant="body1">&nbsp;{this.transStylishDashboard("mins")}
                                             </Typography>
                                        </Box>
                          <Button onClick={this.incrementTime} 
                          data-testid="incrementTime" disabled={this.state.reloadTime >= 120}>
                            <img width="24px" height="24px" src={addCircle} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                      <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleCancel"
                       onClick={this.handleAcceptDialogClose}>{this.transStylishDashboard("Close")}</StyledButtonModelNewOrder>
                                   <StyledButton1ModelNewOrder style={{ fontSize: "14px" }} 
                                   data-testid="timeData" onClick={this.timeData}>{this.transStylishDashboard("Confirm")}</StyledButton1ModelNewOrder>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>

                <DialogSuccessRejectContainer
          data-test-id="request-accept-reject-dialog"
          open={this.state.isSROpen}
          dir={i18n.dir()}
        >
          <Box className="content-container">
            <img
              src={this.state.isAccepted ? storeSuccessIcon : rejectIcon}
              alt="icon"
            />
            <Typography className="text-container">
              {this.transStylishDashboard(`You have ${
                this.state.isAccepted ? "accepted" : "rejected"
              } the styling request of Scarlet.`)}
            </Typography>
          </Box>
        </DialogSuccessRejectContainer>
        {this.state.newOrderData?.length > 0 && (
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: "10px 0px" , padding: "0px 25px 0px 25px"}}>

                                    <Box style={{ color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: 'Lato' }}>{paginationStart} - {paginationendIndex} {this.transStylishDashboard("of")} {this.state.metaDataNewOrder?.total_record} {this.transStylishDashboard("results")}

                                    </Box>
                                    <CustomStyledPagination  
                                        data-test-id="paginationCustom" page={this.state.page}
                                        color="primary"
                                        onChange={this.handlePageChange}
                                        shape="rounded"
                                        count={Math.ceil(this.state.metaDataNewOrder?.total_record/ROWS_PER_PAGE)}
                                        dir={i18n.dir()}
                                    />
                                </Box>
        )}
                     

                     <ViewRequestDialog
        data-test-id="request-view-dialog"
        open={this.state.isRequestOpen}
        onClose={() => this.handleRequestClose()}
        dir={i18n.dir()}
      >
        {this.state.popUpData?.id && (
          <ViewRequestDialogContainer>
            <Box className="main-request-title">
              <Typography className="request-number">
                #{this.state.popUpData.attributes.stylist_id}-{this.state.popUpData.id} |{" "}
                {this.state.popUpData.attributes.created_at}
              </Typography>
              <Box>
                {this.orderLabelAndStyle(this.state.popUpData.attributes.status, true)}
              </Box>
            </Box>
            <PreviewRequestImg>
              {this.state.popUpData.attributes.images.map((requestFile, index) => (
                <Box key={index} className="file-preview">
                  <img
                    src={requestFile.url}
                    alt={`preview ${index}`}
                    className="preview-image"
                  />
                </Box>
              ))}
            </PreviewRequestImg>
            <RequestDetailContainer>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="requester-name">
                    {this.state.popUpData.attributes.buyer_name}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                    {this.transStylishDashboard("Gender")} -{" "}
                  </Typography>
                  <Typography
                    className="description-value"
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.state.popUpData.attributes.gender}
                  </Typography>
                </Box>
                <Box className="description-data">
                  <Typography className="description-name">
                  {this.transStylishDashboard("Budget")} -{" "}
                  </Typography>
                  <Typography className="description-value">
                    ${this.state.popUpData.attributes.min_price} - $
                    {this.state.popUpData.attributes.max_price}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-name">
                  {this.transStylishDashboard("Colours")} -{" "}
                  </Typography>
                  <Typography
                    className="description-value"
                    style={{ textTransform: "capitalize" }}
                  >
                    {this.state.popUpData.attributes.colour.replace(",", ", ")}
                  </Typography>
                </Box>
              </Box>
              <Box className="row-container">
                <Box className="description-data">
                  <Typography className="description-content">
                    {this.state.popUpData.attributes.detail}
                  </Typography>
                </Box>
              </Box>
            </RequestDetailContainer>
            {this.state.popUpData.attributes.status === "pending" && (
              <RequestBtnContainer>
                <Box
                  onClick={() =>
                    this.handleAcceptReject("rejected", +this.state.popUpData.id)
                  }
                  data-test-id="reject-test-id"
                  className="reject-div"
                >
                  {this.transStylishDashboard("Reject")}
                </Box>
                <Box
                  onClick={() =>
                    this.handleAcceptReject("accepted", +this.state.popUpData.id)
                  }
                  data-test-id="accept-test-id"
                  className="accept-div"
                >
                  {this.transStylishDashboard("Accept")}
                </Box>
              </RequestBtnContainer>
            )}
          </ViewRequestDialogContainer>
        )}
      </ViewRequestDialog>
                </StylishContainer>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

const CustomStyledPagination = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) =>({
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    "& li:first-of-type .MuiPaginationItem-icon": {
      transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    "& li:last-of-type .MuiPaginationItem-icon": {
      transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .Mui-selected': {
        color: 'white',
    },
}));

const CustomStyledTableContainer = styled(TableContainer)({
  margin: "25px",
  width: "calc(100% - 65px)",
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px",
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 20px"
    }
});

const webStyle = {
    containerStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '25px',
    },
    boxStyle1: {
      width: 'calc(33.33% - 10px)',
      height: '92px',
      backgroundColor: '#F8F8F8',
      display: 'flex', alignItems: 'center', padding: '16px 20px'
  },
  variantText: {
    fontFamily: "Lato"
  },
    shippedpendingBox:{
        backgroundColor: "#D1FAE5",
        color: "#059669",
        borderRadius: "2px",
        textAlign: "center",
        fontFamily: "Lato",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        height: "27px",
        width: 91,
        whiteSpace: "nowrap",
      },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
    confomMasegNewOrder: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    rejectBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    rejectNameNewOrder: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    rejectButton: { color: "#375280",marginBottom: "4px", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", marginRight: "8px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    tableHaderName: {
        color: "#375280", fontSize: '14px', fontFamily: "Lato", fontWeight: 700, whiteSpace: "nowrap"
    },
    tableHeaderName: {color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400, display: "flex", alignItem:"center"},
    secondaryContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0px 30px 10px 24px',
  },
  tableHader: {
    color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px", width: "12%", whiteSpace: "nowrap" as "nowrap", maxWidth: "122px"
},
acceptOrder: { backgroundColor: "#CCBEB1",textTransform: "capitalize", width: "100%", minWidth: 241, height: 34, borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700, whiteSpace: "nowrap" },
acceptOrder2: { backgroundColor: "#CCBEB1",textTransform: "capitalize", width: "60%", whiteSpace: "nowrap", marginBottom: "4px",borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
  secondBox:{
    cursor: "pointer",
    marginRight: "10px",
    display: "flex",
    alignItems: "center"
},

tabeLable: {
    fontSize: '20px', fontWeight: 800, 
    color: "rgb(55, 82, 128)",
     left: "50%",
     fontFamily: 'Lato', width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center', top: "54%",
    transform: "translate(-40%, 20%)",
},
dividerLine:{backgroundColor: "#375280", width: "65px", height: "2px"},
    imgBakColor: { backgroundColor: "#CCBEB1", padding: '20px', borderRadius: '2px', width: "36px", height: "36px" },
    viewAllText: {
        fontSize: "16px",
        fontFamily: "Lato",
        fontWeight: 900,
        color: "#375280",
        textTransform: "uppercase",
    } as React.CSSProperties,
    newOrdersText:{
        fontSize: "24px",
        fontFamily: "Lato",
        fontWeight: 700,
        color: "#375280"
    },
    new_orderBox:{ backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' } as React.CSSProperties,
    lableText: { fontSize: "16px", fontFamily: "Lato", fontWeight: 400, color: "#334155", marginBottom: '30px', whiteSpace: "nowrap" },
    numberBox: { fontSize: "24px", fontFamily: "Lato", fontWeight: 700, color: "#375280" },
    boxStyle: {
        width: '100%',
        height: '92px',
        backgroundColor: '#F8F8F8',
        marginRight: '21px',
        display: 'flex', alignItems: 'center', 
    },
}

const NoAccessText = styled(Typography)({
  fontWeight: 500,
  fontFamily: "Lato, sans-serif",
  textAlign: "center",
  color: '#375280',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'calc(100vh - 100px)',
  padding: '10px',
  fontSize: "clamp(18px, 2vw, 24px)",
  letterSpacing: "0%",
  lineHeight: "32px",
})
// Customizable Area End
