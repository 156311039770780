// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  styled,
  Snackbar,
  Tooltip,
  Fade,
  Backdrop,
  Modal,Button
} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import {
  setStorageData,
  removeStorageData,
  getStorageData,
} from "../../../framework/src/Utilities";
import Alert from "@material-ui/lab/Alert";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {
  logout,
  setting,
  sidebarimg,
} from "../../../blocks/customisableuserprofiles2/src/assets";
import {
  supportHelp,
  analytics,
  catalogue,
  earnings,
  boardDashboard,
  order,
  portfolio,
  inventory,
  selectedDashboard,
  selectedCatalogue,
  selectedOrder,
  selectedClients,
  selectedPortfolio,
  selectedInventoryManagement,
  selectedSupportHelp,
  selectedLanguage,
  selectedEarning,
  selectedAnalytics,
} from "../Seller/assets";
import { faceLogo } from "../../../blocks/email-account-registration/src/assets";
import { store } from "../../../blocks/customform/src/assets";
import {clients,deleteAccount} from "../Seller/assets"
import {
  StyledButton,
  StyledButton1,
  StyledButtonSigein,
  StyledDrawer,
} from "../Header";
import i18n from "../i18next/i18n";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

interface S {
  openMenu: boolean;
  selectedItem: string | null;
  isAlert: boolean;
  anchorEl: HTMLElement | null;
  open1: boolean;
  auth: string;
  isAlertDelete: boolean;
  openDelete: boolean;
  basePathe:string;
}
export interface Props {
  navigation?: any;
  id?: string;
  className?: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class StylishSidebar extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDeleteAccountId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ];
    this.state = {
      openMenu: false,
      selectedItem: "",
      isAlert: false,
      anchorEl: null,
      open1: false,
      auth: "",
      basePathe:"",
      isAlertDelete:false,
      openDelete:false,
  };
  runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  // Customizable Area End
}

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDeleteAccountId !== null &&
      this.getDeleteAccountId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.message === "Account successfully deleted.") {
        this.setState({ openDelete: false, isAlertDelete: true }, () => { setTimeout(() => { this.redirectHome() }, 3000) })
      }
    }
    // Customizable Area End
  }
 

  headerNavigation = async (pageLink: string, name: string) => {
    await setStorageData("selectedItem", name);
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
    this.setState({
      selectedItem: name,
    });
  };

  notNavigation = async (name: string) => {
    await setStorageData("selectedItem", name);
    this.setState({ selectedItem: name, isAlert: true });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  headerRedirect = (block: string) => {
    this.props.navigation.navigate(block);
  };

  handleExploreAsBuyer = async(blockName: string) => {
    await removeStorageData("selectedClient")
    await removeStorageData("matchedClients")
    await setStorageData("exploreBuyer", "true");
    this.props.navigation.navigate(blockName);
    
  }

  handleOpen1 = () => {
    this.setState({ open1: true, anchorEl: null });
  };
  hendelUrl = async () => {
    const baseUrl = new URL(document.URL).pathname.split('/')[1];
    const updateURL = this.renderURL(baseUrl);
    await setStorageData("selectedItem", updateURL)
    const selectedItemUrl = await getStorageData("selectedItem")
    this.setState({ basePathe: selectedItemUrl })
  }

  renderURL = (url: string | undefined) => {
    if (url) {
      const inventoryManagementPaths = ["InventoryManagement", "DeleteProducts"];
      const clientPaths = ["StylishClients", "ClientChat", "StylishMyClients", "StylingClientWishlist", "StylingRequests", "StylingCallBackRequest"];
      const cataloguePaths = [ "StylishCatalogue", "Catalogue", "ProductDetail", "StylishProductSourcing", "StylishMyOffer", "StylishProductSourcingRequest", "ServicesList", "NewOrderRequest", "NewPaymentRequest"]
      const orderManagementPaths = [ "OrderManagement", "SellersAllOrderDetailsWeb", "SellersOrderDetailsWebPage", "SellersInProcessOrderDetails", "SellerReadyForCollectionDetail", "OutForDeliveryDetail", "SellerDeliveredDetail", "ReturnDetail", "SellersRejectedOrderDetails"]
      if (inventoryManagementPaths.includes(url)) {
        return "InventoryManagement";
      } else if (clientPaths.includes(url)) {
        return "StylishClients";
      } else if(cataloguePaths.includes(url)){
        return "StylishCatalogue"
      } else if(orderManagementPaths.includes(url)){
        return "OrderManagement"
      }
      else return url
    }
    return "";
  };
  async componentDidMount() {
    this.hendelUrl()
  }
  handleClose1 = () => {
    this.setState({ open1: false });
  };
  
  logout = async () => {
    ["matchedClients", "selectedClient","orderNumber", "auth-token", "sms-token","userRole","countryFlge","countryFlgeTwo","numericCode","numericCodeTwo", "exploreBuyer", "fcm_token"].map(async (value) => {
      await removeStorageData(value);
    });
    this.setState({ open1: false });
    const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  transStaylistSide = (skeys: string) => {
    return i18n.t(skeys, { ns: "sidebar"})
  }

  openDeleteAccount = () => {
    this.setState({ openDelete: true })
  }
  cancelAccount = () => {
    this.setState({ openDelete: false })
  }
  deleteAccount = async () => {
    const token = await getStorageData("auth-token");
    const deleteAccountId = await getStorageData("user_id")
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    const requestMessageList = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeleteAccountId = requestMessageList.messageId;
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${deleteAccountId}`
    );
    requestMessageList.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessageList.id, requestMessageList);
  }
  oncloseAlertDlete = () => {
    this.setState({ isAlertDelete: false })
  }

  redirectHome = () => {
    ["orderNumber", "auth-token", "sms-token", "fcm_token", "userRole"].map(async value => {
      await removeStorageData(value)
    })
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  activeLink = (isActive: boolean) => {
    return isActive ? "active-link": ""
  }

  render() {
    // Customizable Area Start
    const { selectedItem } = this.state;
    const isItemSelected = selectedItem !== "";
    return (
      <>
        <StyledWrapper>
          <Box className={`sidebar-container ${i18n.dir()==="rtl" ? "left-border": "right-border"}`}>
            <Box className="sidebar-inner-container" dir={i18n.dir()}>
              <Box
                className="logo-container"
              >
                <img src={faceLogo} alt="fashLogo" className="logo" />
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishDashboard', "StylishDashboard")}
                style={{
                  backgroundColor:
                    this.state.basePathe === "StylishDashboard"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishDashboard"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "StylishDashboard")}`}>
                  <Tooltip title={this.transStaylistSide("Dashboard")}>
                    <img src={this.state.basePathe === "StylishDashboard" ? selectedDashboard : boardDashboard} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Dashboard")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishCatalogue', "StylishCatalogue")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "StylishCatalogue" || this.state.basePathe === "ProductDetail" || this.state.basePathe === "Catalogue" || this.state.basePathe === "ServicesList"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishCatalogue" || this.state.basePathe === "ProductDetail" || this.state.basePathe === "Catalogue"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "StylishCatalogue" || this.state.basePathe === "ProductDetail" || this.state.basePathe === "Catalogue" || this.state.basePathe === "ServicesList")}`}>
                  <Tooltip title={this.transStaylistSide("Catalogue")}>
                    <img src={this.state.basePathe === "StylishCatalogue" || this.state.basePathe === "ProductDetail" || this.state.basePathe === "Catalogue"
                        ? selectedCatalogue
                        : catalogue} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Catalogue")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellersAllOrderDetailsWebPage","OrderManagement")}
                style={{
                  backgroundColor:
                  this.state?.basePathe === "OrderManagement"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state?.basePathe === "OrderManagement"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state?.basePathe === "OrderManagement")}`}>
                  <Tooltip title={this.transStaylistSide("Orders")}>
                    <img src={this.state?.basePathe === "OrderManagement"
                        ? selectedOrder
                        : order} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Orders")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation('StylishClients', "StylishClients")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "StylishClients" || this.state.basePathe === "StylishMyClients"|| this.state.basePathe === "StylingClientWishlist"|| this.state.basePathe === "StylingRequests"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "StylishClients" || this.state.basePathe === "StylishMyClients"|| this.state.basePathe === "StylingClientWishlist"|| this.state.basePathe === "StylingRequests"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "StylishClients" || this.state.basePathe === "StylishMyClients"|| this.state.basePathe === "StylingClientWishlist"|| this.state.basePathe === "StylingRequests")}`}>
                  <Tooltip title={this.transStaylistSide("Clients")}>
                    <img src={this.state?.basePathe === "StylishClients"
                        ? selectedClients
                        : clients} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Clients")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("PhotoLibrary", "PhotoLibrary")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "PhotoLibrary"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "PhotoLibrary"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "PhotoLibrary")}`}>
                  <Tooltip title={this.transStaylistSide("Portfolio")}>
                    <img src={this.state.basePathe === "PhotoLibrary"
                      ? selectedPortfolio
                      : portfolio} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Portfolio")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("InventoryManagement", "InventoryManagement")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "InventoryManagement"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "InventoryManagement"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "InventoryManagement")}`}>
                  <Tooltip title={this.transStaylistSide("Inventory Management")}>
                    <img src={this.state.basePathe === "InventoryManagement"
                      ? selectedInventoryManagement
                      : inventory} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Inventory Management")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("StylistAnalytics", "AnalyticsReport")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "AnalyticsReport"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe ===
                      "AnalyticsReport"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "AnalyticsReport")}`}>
                  <Tooltip title={this.transStaylistSide("Analytics and Insights")}>
                    <img src={this.state.basePathe ===
                      "AnalyticsReport" ? selectedAnalytics : analytics} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Analytics and Insights")}</span>
                </Typography>
              </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("EarningsAnalyticsWebPage","EarningsAnalyticsWebPage")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "EarningsAnalyticsWebPage"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "EarningsAnalyticsWebPage"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "EarningsAnalyticsWebPage")}`}>
                  <Tooltip title={this.transStaylistSide("Earnings")}>
                    <img src={this.state.basePathe === "EarningsAnalyticsWebPage" ? selectedEarning :earnings} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Earnings")}</span>
                </Typography>
              </Box>
              <Box
                            className="link" onClick={() => this.headerNavigation("CurrencyLanguage","Setting")}
                            style={{ backgroundColor: this.state.basePathe === "Setting" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Setting" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className={`link ${this.activeLink(this.state.basePathe === "Setting")}`} >
                            <Tooltip title={this.transStaylistSide("Language and Currency")}>
                             <img src={this.state.basePathe === "Setting" ? selectedLanguage :setting} />
                            </Tooltip>
                            <span className="link-text">{this.transStaylistSide("Language and Currency")}</span>
                            </Typography>
                        </Box>
              <Box
                className="link"
                onClick={() => this.headerNavigation("SellerHelp", "GetHelp")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "GetHelp"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "GetHelp"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className={`link ${this.activeLink(this.state.basePathe === "GetHelp")}`}>
                  <Tooltip title={this.transStaylistSide("Get Help")}>
                    <img src={this.state.basePathe === "GetHelp"
                        ? selectedSupportHelp : supportHelp} />
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Get Help")}</span>
                </Typography>
              </Box>
              <hr style={{border: "1px solid #94A3B8"}}/>
              <Box
                className="link"
                onClick={() => this.notNavigation("exploreBuyer")}
                style={{
                  backgroundColor:
                  this.state.basePathe === "exploreBuyer"
                      ? "#F1F5F9"
                      : "transparent",
                  textDecoration: "none",
                  position: "relative",
                }}
              >
                <div
                  className="wishlist-icon"
                  style={{
                    backgroundImage:
                    this.state.basePathe === "exploreBuyer"
                        ? `url(${sidebarimg})`
                        : "none",
                  }}
                />
                <Typography className="link" onClick={() => this.handleExploreAsBuyer("LandingPage")}>
                  <Tooltip title={this.transStaylistSide("Explore as Buyer")}>
                    <img src={store}/>
                  </Tooltip>
                  <span className="link-text">{this.transStaylistSide("Explore as Buyer")}</span>
                </Typography>
                  <KeyboardArrowRightIcon className="link-text" style={{transform : `${i18n.dir()==="rtl" ? "rotate(180deg)" : "rotate(0deg)"}`}} />
              </Box>

              <Box
                className="link" onClick={this.openDeleteAccount}
                style={{ textDecoration: "none", position: "relative" }}>
                <Typography className="link">
                  
                  <Tooltip title={this.transStaylistSide("Delete Account")}>
                    <img src={deleteAccount} style={{ width: '26px' }} />
                  </Tooltip>
                  <span className="link-delete-text">{this.transStaylistSide("Delete Account")}</span>
                </Typography>
              </Box>
            </Box>

            <Box
              className="link"
              onClick={() => this.handleOpen1()}
              style={{
                backgroundColor:
                this.state.basePathe === "logout"
                    ? "#F1F5F9"
                    : "transparent",
                textDecoration: "none",
                padding: "0px 34px 32px"
              }}
              dir={i18n.dir()}
            >
              <div
                className="wishlist-icon"
                style={{
                  backgroundImage:
                  this.state.basePathe === "logout"
                      ? `url(${sidebarimg})`
                      : "none",
                }}
              />
              <Typography className="link1" dir={i18n.dir()}>
                <Tooltip title={this.transStaylistSide("Logout")}>
                  <img src={logout} />
                </Tooltip>
                <span className="link-text">{this.transStaylistSide("Logout")}</span>
              </Typography>
            </Box>
          </Box>


          <StyledDrawer
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.open1}
            onClose={this.handleClose1}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            dir={i18n.dir()}
          >
            <Fade
              in={this.state.open1}
              style={{
                borderRadius: "10px",
                maxWidth: "320px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}
              >
                <Box
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 900,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null ? "" : this.transStaylistSide("Log Out")}
                </Box>
                <h2
                  id="transition-modal-title"
                  style={{
                    fontFamily: "Lato",
                    width: "300px",
                    fontSize: "18px",
                    color: "#375280",
                    fontWeight: 600,
                    marginLeft: "10px",
                  }}
                >
                  {this.state.auth === null
                    ? this.transStaylistSide("You need to sign in to access Profile")
                    : this.transStaylistSide("Are you sure you want to log out your account?")}
                </h2>
                <div
                  style={{
                    marginTop: "40px",
                    display: "flex",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  {this.state.auth === null ? (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      {this.transStaylistSide("Cancel")}
                    </StyledButton1>
                  ) : (
                    <StyledButton
                      style={{ fontSize: "18px", lineHeight: "20px" }}
                      onClick={() => this.logout()}
                    >
                      {this.transStaylistSide("Logout")}
                    </StyledButton>
                  )}
                  {this.state.auth === null ? (
                    <StyledButtonSigein
                      style={{ fontSize: "18px" }}
                      onClick={() =>
                        this.headerRedirect("EmailAccountLoginBlock")
                      }
                    >
                      {this.transStaylistSide("Sign In")}
                    </StyledButtonSigein>
                  ) : (
                    <StyledButton1
                      style={{ fontSize: "18px" }}
                      onClick={this.handleClose1}
                    >
                      {this.transStaylistSide("Cancel")}
                    </StyledButton1>
                  )}
                </div>
              </div>
            </Fade>
          </StyledDrawer>
          <Snackbar
            open={this.state.isAlert}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={this.oncloseAlert}
            data-test-id="alertTestId"
            dir={i18n.dir()}
          >
            <Alert
              variant="filled"
              severity="info"
              style={{ width: "300px", marginTop: "10px" }}
            >
              {this.transStaylistSide("Coming Soon !!!")}
            </Alert>
          </Snackbar>
        </StyledWrapper>
        <Modal
          aria-describedby="transition-modal-description"
          open={this.state.openDelete}
          aria-labelledby="transition-modal-title"
          style={{ ...webStyle.menuDrawer }}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openDelete} style={{ borderRadius: '10px', maxWidth: "360px", backgroundColor: "white" }}>
            <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
              <div style={{ textAlign: 'center', fontSize: '30px', color: "#375280", fontWeight: 700, fontFamily: "Lato" }}>{this.transStaylistSide("Delete Account")}</div>
              <div style={{ margin: "15px 0px 30px", textAlign: 'center', fontSize: '24px', color: "#375280", fontWeight: 400, fontFamily: "Lato" }}>{this.transStaylistSide("DeleteAccountMag")}</div>
              <div style={{ display: "flex", gap: "20px", justifyContent: "space-between", flexDirection: i18n.dir() === 'ltr' ? 'row' : 'row-reverse' }}>
                <Button style={{ ...webStyle.styleButtonSave, textTransform: "none" }} data-testid="handleClose1" onClick={this.deleteAccount}>{this.transStaylistSide("Delete")}</Button>
                <Button style={{ ...webStyle.styleButtonCancel, textTransform: "none" }} data-testid="cancelAccount" onClick={this.cancelAccount}>{this.transStaylistSide("Cancel")}</Button>
              </div>
            </div>
          </Fade>
        </Modal>
        <Snackbar
          open={this.state.isAlertDelete}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlertDlete}
          data-testid="alertTestId"
        ><Alert variant="filled" severity="success" style={{ width: '300px', marginTop: '10px' }}>{this.transStaylistSide("Delete Account Successfully")}</Alert></Snackbar>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyle = {
  menuDrawer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: "none",
  },
  styleButtonCancel: {
      fontWeight: 700,
      color: "#375280",
      fontSize: "18px",
      width: "170px",
      fontFamily: "Lato",
      height: "45",
      border: "1px solid #CCBEB1",
      backgroundColor: '#ffff',
      '&:hover': {
          backgroundColor: "#fff"
      },
  },
  styleButtonSave: {
      fontWeight: 700,
      color: "#fff",
      fontSize: "18px",
      width: "170px",
      height: "45",
      fontFamily: "Lato",
      backgroundColor: '#DC2626',
      '&:hover': {
          backgroundColor: "#DC2626"
      },
  },
}
const StyledWrapper = styled(Box)({
  height: "100%",
  "& .sidebar-container": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "calc(100% - 85px)",
    "& .sidebar-inner-container": {
      padding: "40px 25px",
    }
  },
  "& .left-border":{
    borderLeft: "1px solid #E2E8F0",
  },
  "& .right-border":{
    borderRight: "1px solid #E2E8F0",
  },
  "& .logo-container": {
    paddingBottom: 30,
  },
  "& .logo": {
    "@media (max-width: 960px)": {
      width: "80px"
    }
  },
  "& .link.active-link": {
    color: "#375280",
    fontWeight: 700,
  },
  "& .link": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: "#94A3B8",
    fontFamily: "Lato , sans-serif",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: 400,
    lineHeight: "21.6px !important",
    padding: `${i18n.dir() === 'ltr' ? "8px 7px 8px 20px " : "8px 20px 8px 7px"}`,
    gap: "10px",
    "& .wishlist-icon": {
      width: "2px",
      height: "40px",
      marginLeft: "-7px",
      position: "absolute",
    },
    "& .link-text, .link-delete-text": {
      padding: 0,
    }
  },
  "& .link1": {
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Lato , sans-serif",
    fontSize: "18px !important",
    fontStyle: "normal !important",
    fontWeight: 700,
    lineHeight: "21.6px !important",
    padding: `${i18n.dir() === 'ltr' ? "8px 7px 8px 20px " : "8px 20px 8px 7px"}`,
    gap: "10px",
  },
  "& .link-delete-text": {
    fontSize: '16px', fontFamily: 'Lato', fontWeight: 600, color: '#DC2626'
  }
});
// Customizable Area End
