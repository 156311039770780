export const enProfile = {
  "My Profile": "My Profile",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Enter Your First Name": "Enter Your First Name",
  "Enter Your Last Name": "Enter Your Last Name",
  "Email": "Email",
  "Enter Your E-mail": "Enter Your E-mail",
  "Phone Number": "Phone Number",
  "Enter Phone Number": "Enter Phone Number",
  "Save Changes": "Save Changes",
  "Change Password": "Change Password",
  "Old Password": "Old Password",
  "Enter Your Old Password": "Enter Your Old Password",
  "New Password": "New Password",
  "Enter Your New Password": "Enter Your New Password",
  "Repeat New Password": "Repeat New Password",
  "Re-Enter Your Password": "Re-Enter Your Password",
  "OTP Verification": "OTP Verification",
  "Please enter the 4 digit code sent to":
    "Please enter the 4 digit code sent to",
  "your email id ": "your email id ",
  "your mobile number id ": "your mobile number id ",
  "Didn't Received Code?": "Didn't Received Code?",
  "Resend Code": "Resend Code",
  "Verify Account": "Verify Account",
  "Resent OTP Successfully": "Resent OTP Successfully",
  "* Enter 4 digit OTP": "* Enter 4 digit OTP",
  "* Please re-enter your password": "* Please re-enter your password",
  "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric":
    "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric",
  "*Please enter your password": "*Please enter your password",
  "*Please enter a valid email address": "*Please enter a valid email address",
  "*Please enter your email address": "*Please enter your email address",
  "*Please enter a valid first name": "*Please enter a valid first name",
  "*Please enter a valid last name": "*Please enter a valid last name",
  "*Please enter your phone number": "*Please enter your phone number",
  "*Please enter a valid phone number": "*Please enter a valid phone number",
  "User Profile Updated Successfully": "User Profile Updated Successfully",
  "Change Password Updated Successfully": "Change Password Updated Successfully",
  "Edit Profile": "Edit Profile",
  "User Details": "User Details",
  "Password": "Password",
  "Enter Your Password": "Enter Your Password",
  "Edit Profile Picture": "Edit Profile Picture",
  "Upload Image": "Upload Image",
  "Replace Image": "Replace Image",
  "Upload a png, jpg or jpeg file": "Upload a png, jpg or jpeg file",
  "Welcome!": "Welcome!",
  "Choose your user type": "Choose your user type",
  "Continue as": "Continue as",
  "Buyer": "Buyer",
  "Seller": "Seller",
  "Stylist": "Stylist",
  "Select Language": "Select Language",
  "English": "English",
  "Select Currency": "Select Currency",
  "Dollar": "Dollar",
  "Dinar": "Dinar",
  "Continue": "Continue",
  "Arabic - عربي": "Arabic - عربي",
  "Admin Request": "Admin Request",
  "Bank Details":"Bank Details",
  "Your bank information is saved to receive payments of your orders.":"Your bank information is saved to receive payments of your orders.",
  "IBAN Number":"IBAN Number",
  "Account Number":"Account Number",
  "An error occurred, please try again":"An error occurred, please try again",
  "please upload image.": "please upload image.",
  "Transactions":"Transactions",
  "Loyalty Point Transactions Not Found":"Loyalty Point Transactions Not Found",
  "% off on": "% off on",
  "Claim offer using": "Claim offer using",
  "points":"points",
  "Points":"Points",
  "Redeem":"Redeem",
  "Loyalty Points": "Loyalty Points",
  "Home": "Home",
  "Welcome to your Account": "Welcome to your Account",
  "Redeem points?":"Redeem points?",
  "Tap ‘Yes’ to collect the reward using points.":"Tap ‘Yes’ to collect the reward using points.",
  "Cancel": "Cancel",
  "Yes": "Yes",
  "Congratulations":"Congratulations",
  "Thank you! You will get a free delivery on your next order.": "Thank you! You will get a free delivery on your next order.",
  "Continue Shopping": "Continue Shopping",
  "All": "All",
  "Use Loyalty Points":"Use Loyalty Points",
  "Back":"Back",
  "Redeemed, ": "Redeemed, ",
  "Order":"Order",
  "Credited on":"Credited on",
  "Debited on": "Debited on",
  "Redeem your points effortlessly when you purchase new products from us.": "Redeem your points effortlessly when you purchase new products from us."
};

export const arProfile = {
  "Redeem your points effortlessly when you purchase new products from us.": "استبدل نقاطك بسهولة عند شراء منتجات جديدة منا.",
  "Debited on": "مدين على",
  "Credited on": "الائتمان على",
  "Order":"طلب",
  "Redeemed, ": "مستردة, ",
  "Back": "خلف",
  "Use Loyalty Points":"استخدم نقاط الولاء",
  "All":"الكل",
  "Continue Shopping": "متابعة التسوق",
  "Thank you! You will get a free delivery on your next order.":"شكرًا لك! سوف تحصل على توصيل مجاني لطلبك التالي.",
  "Congratulations":"تهانينا",
  "Yes": "نعم",
  "Cancel":"الإلغاء",
  "Tap ‘Yes’ to collect the reward using points.": "اضغط على 'نعم' لجمع المكافأة باستخدام النقاط.",
  "Redeem points?":"استبدال النقاط؟",
  "Welcome to your Account": "مرحبا بك في حسابك",
  "Home": "الصفحة الرئيسية",
  "Loyalty Points": "نقاط الولاء",
  "Redeem": "يسترد",
  "points": "نقاط",
  "Points": "نقاط",
  "Claim offer using":"المطالبة بالعرض باستخدام",
  "% off on": "% خصم على",
  "Loyalty Point Transactions Not Found": "لم يتم العثور على معاملات نقاط الولاء",
  "Transactions": "المعاملات",
  "My Profile": "حسابي",
  "First Name": "الاسم الأول",
  "Last Name": "اسم العائلة",
  "Enter Your First Name": "أدخل اسمك الأول",
  "Enter Your Last Name": "أدخل اسمك الأخير",
  "Email": " البريد الإلكتروني",
  "Enter Your E-mail": "أدخل بريدك الإلكتروني",
  "Phone Number": " رقم الهاتف",
  "Enter Phone Number": "أدخل رقم الهاتف",
  "Save Changes": "حفظ التغيرات",
  "Change Password": "تغيير كلمة المرور",
  "Old Password": "كلمة المرور القديمة",
  "Enter Your Old Password": "أدخل كلمة المرور القديمة الخاصة بك",
  "New Password": "كلمة المرور الجديدة",
  "Enter Your New Password": "أدخل كلمة المرور الجديدة",
  "Repeat New Password": "أعد كتابة كلمة المرور الجديدة",
  "Re-Enter Your Password": "أعد إدخال كلمة المرور الخاصة بك",
  "OTP Verification": "التحقق من OTP",
  "Please enter the 4 digit code sent to":
    "الرجاء إدخال الرمز المكون من 4 أرقام المرسل إليه",
  "your email id ": "معرف البريد الإلكتروني الخاص بك ",
  "your mobile number id ": "معرف رقم هاتفك المحمول ",
  "Didn't Received Code?": "لم تتلق الرمز؟",
  "Resend Code": "إعادة إرسال الرمز",
  "Verify Account": "التحقق من الحساب",
  "Resent OTP Successfully":
    "تمت إعادة إرسال كلمة المرور لمرة واحدة (OTP) بنجاح",
  "* Enter 4 digit OTP": "* أدخل كلمة مرور لمرة واحدة مكونة من 4 أرقام",
  "* Please re-enter your password":
    "*كلمتا المرور غير متطابقتين. من فضلك أعد إدخال كلمة المرور الخاصة بك",
  "*Enter valid password contain minimum 8 digit with at least one special character, one uppercase letter and alphanumeric":
    "*يجب أن تكون كلمة المرور من 8 خانات على الأقل ",
  "*Please enter your password": "*يرجى إدخال كلمة مرور الخاصة بك",
  "*Please re-enter your password": "*الرجاء إعادة إدخال كلمة المرور الخاصة بك",
  "*Please enter a valid email address": "*يرجى إدخال بريد إلكتروني صالح",
  "*Please enter your email address": "*يرجى إدخال عنوان البريد الإلكتروني الخاص بك",
  "*Please enter a valid first name": "*يرجى إدخال إسم أول صالح",
  "*Please enter a valid last name": "*يرجى إدخال إسم عائلة صالح",
  "*Please enter your phone number": "*الرجاء إدخال رقم هاتفك",
  "*Please enter a valid phone number": "*يرجى إدخال رقم هاتف صالح",
  "User Profile Updated Successfully": "تم تحديث ملف تعريف المستخدم بنجاح",
  "Change Password Updated Successfully": "تم تحديث تغيير كلمة المرور بنجاح",
  "Edit Profile": "تعديل الملف الشخصي",
  "User Details": "تفاصيل المستخدم",
  "Password": "كلمة المرور",
  "Enter Your Password": "أدخل كلمة المرور الخاصة بك",
  "Edit Profile Picture": "تعديل صورة الملف الشخصي",
  "Upload Image":"رفع الصورة ",
  "Replace Image": "استبدال الصورة",
  "Upload a png, jpg or jpeg file": "رفع ملف png أو jpg أو jpeg أ",
  "Welcome!": "أهلا بكم!",
  "Choose your user type": "اختر نوع العضوية",
  "Continue as": "الاستمرار ك",
  "Buyer": "مشتري",
  "Seller": "بائع",
  "Stylist": "مصمم",
  "Select Language": "اختر اللغة",
  "English": "الإنجليزية",
  "Select Currency": "حدد العملة",
  "Dollar": "الدولار",
  "Dinar": "الدينار",
  "Continue": "المتابعة",
  "Arabic - عربي": "عربي - Arabic",
  "Admin Request": "طلبات المسؤول",
  "Bank Details": "التفاصيل البنكية",
  "Your bank information is saved to receive payments of your orders.": "يتم حفظ معلوماتك المصرفية لأجل تلقي مدفوعات طلباتك",
  "IBAN Number":"رقم الآيبان",
  "Account Number":"رقم الحساب",
  "An error occurred, please try again":"حدث خطأ، يرجى المحاولة مرة أخرى",
  "please upload image.": "يرجى تحميل الصورة"
};
