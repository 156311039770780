import React from "react";
// Customizable Area Start
import {
    Box, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress, Button, Modal, Typography, Backdrop, Fade, Grid, Snackbar
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import SellersInProcessOrderDetailsController, {
    Props,
} from "./SellersInProcessOrderDetailsController.web";
import { button_close, Noimageavailablepng, button_arrow } from "../assets"
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../../components/src/SellerStylish/SellerStylishContainer";
import { CustomStyledTableNewOrder } from "./SellersInProcessOrder.web";
// Customizable Area End

export default class SellersInProcessOrderDetailsWebPage extends SellersInProcessOrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleSellerInProcessDetailsImgCSS = () =>{
        return i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)'
    }
    marginAutoInProcessCSS = () => {
        return i18n.dir()==="ltr" ? "0px 0px 0px auto" : "0px auto 0px 0px"
    }
    getPriceValue = (currency: string, price: string) => {
        return `${currency} ${price}`
    }
    orderDetailsInProcess = () => {
        const orderDatalsInProcess = this.state.tableAllOrder.find(
            (order: { attributes: { order_management_order: { attributes: { order_number: string | number } } } }) =>
                order.attributes.order_management_order.attributes.order_number === this.state.orderId
        );
        if (!orderDatalsInProcess) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }

        const { order_items, order_management_order } = orderDatalsInProcess.attributes;
        const { delivery_addresses } = order_management_order.attributes;

        return (
            <>
                <Box style={{ padding: '30px' }}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', flexWrap: "wrap", gap: 10 }}>
                        <Box>
                            <Box style={{ display: "flex", alignItems: 'center', flexWrap: "wrap", gap: 5 }}>
                                <img src={button_arrow} style={{ cursor: "pointer", transform: this.handleSellerInProcessDetailsImgCSS() }} data-testid="contioneShopping" onClick={this.contioneShoppingInProcessDetails} /> <Box style={webStyle.newOrderIdInProcess} data-testid={`order_number`}>#{order_management_order.attributes.order_number}</Box> <Box style={{ ...webStyle.new_orderBox, textAlign: 'center', whiteSpace: "nowrap", margin: this.marginAutoInProcessCSS() }}>{this.tranSellerInProcessOrder("In Process")}</Box>
                            </Box>
                            <Box style={{ color: "#9A9A9A", fontSize: "16px", fontFamily: "Lato", fontWeight: 500, marginTop: "6px" }}>
                                {this.tranSellerInProcessOrder("Total Products")} : {order_items.length}
                            </Box>
                        </Box>
                        <Box style={{margin: this.marginAutoInProcessCSS(), display: "flex", gap: 10, flexWrap: "wrap"}}>
                            <Button style={{ ...webStyle.acceptOrderInProcessDetails, textTransform: "capitalize", margin: this.marginAutoInProcessCSS() }} data-testid={`timeOrderData=${orderDatalsInProcess.attributes.id}`} onClick={() => this.handleOpenInProcessDetails(orderDatalsInProcess.attributes.id)}>{this.tranSellerInProcessOrder("Ready To Ship")}</Button>
                        </Box>
                    </Box>
                    <Box>
                        <CustomStyledTableNewOrder className="container" style={{marginTop: "20px"}}>
                            <Table stickyHeader size="small" className="custom-table">
                                <TableHead>
                                    <TableRowCustom>
                                        {this.state.tableLable.map((header: string, index: number) => (
                                            <TableCell key={index} style={webStyle.tableHader}>
                                                <Typography variant="h6" className="headerText" >{this.tranSellerInProcessOrder(header)}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRowCustom>
                                </TableHead>
                                <TableBody>
                                    {order_items?.map((order, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: "flex", gap: 10 }}>
                                                    <Box >
                                                        <img src={order.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : order.attributes.catalogue_variant_front_image} style={{ width: '44px', height: '44px', borderRadius: '2px' }} />
                                                    </Box>
                                                    <Box style={{ ...webStyle.tableHaderrowInProcessDetails }}>{order.attributes.catalogue_name}</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowInProcessDetailsTypo}}>{order.attributes.brand_name}</Typography></TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowInProcessDetailsTypo}}>{order.attributes.catalogue_variant_size}</Typography></TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}>
                                                <Box style={{ display: 'flex', alignItems: 'center', gap: 18 }}>
                                                    <Box style={{ backgroundColor: order.attributes.catalogue_variant_color, width: '14px', height: '14px', borderRadius: '10px'}} /><Typography style={{...webStyle.tableHaderrowInProcessDetailsTypo}}>{order.attributes.catalogue_variant_color}</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowInProcessDetailsTypo}}>{order.attributes.catalogue_variant_sku}</Typography></TableCell>
                                            <TableCell style={{ ...webStyle.tableHaderrowInProcessDetails, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowInProcessDetailsTypo}}>{this.getPriceValue(order.attributes?.currency_logo, order.attributes.total_price)}</Typography></TableCell>
                                        </TableRow>
                                    )
                                    )}
                                </TableBody>
                            </Table>
                        </CustomStyledTableNewOrder>
                        <Box style={webStyle.tableBox}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>{this.tranSellerInProcessOrder("Store Name")}</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_items[0].attributes.store_name}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>{this.tranSellerInProcessOrder("Customer Name")}</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_management_order.attributes.account}</Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box style={webStyle.orderDetailsNameInProcessDetails}>{this.tranSellerInProcessOrder("Shipping Address")}</Box>
                                    <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{this.getAddressInProcessDetails(delivery_addresses)}</Box>
                                </Grid>
                            </Grid>
                            <Box style={{ marginTop: '30px' }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameInProcessDetails}>{this.tranSellerInProcessOrder("Coupon Applied")}</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{this.tranSellerInProcessOrder("Fash")}10(-$5)</Box>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Box style={webStyle.orderDetailsNameInProcessDetails}>{this.tranSellerInProcessOrder("Payment Method")}</Box>
                                        <Box style={{ ...webStyle.orderDetailsValueInProcessDetails, textTransform: "capitalize" }}>{order_management_order.attributes.payment_detail?.payment_type}</Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }
    sellerInProcesRole = () => {
        const { roleData } = this.state;
        switch (roleData) {
            case "seller":
                return "Seller";
            case "stylist":
                return "Stylish";
            default:
                return "";
        }
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <SellerStylishContainer role={this.sellerInProcesRole()} navigation={this.props.navigation}>
                    {this.orderDetailsInProcess()}
                </SellerStylishContainer>       
                <StyledDrawerInProcess
                    closeAfterTransition
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    BackdropProps={{
                        timeout: 500,
                    }}
                    onClose={this.handleCancelInProcessDetails}
                    open={this.state.visible}
                    BackdropComponent={Backdrop}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.visible} style={webStyle.febBoxInProcessInProcessDetails}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancelInProcessDetails} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.changeOrderInProcessDetails, textAlign: "center" }}>{this.tranSellerInProcessOrder("Change Status")}</h2>
                            <Box style={{ ...webStyle.modelTextInProcessDetails, textAlign: 'center' }}>
                                <Box style={{ paddingBottom: '20px' }}>{this.tranSellerInProcessOrder("Are you sure you want to change the order status to ready for collection?")}</Box>
                                <Box>{this.tranSellerInProcessOrder("Delivery partner will be assigned soon for the order pickup.")}</Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelInProcessDetails style={{ fontSize: "14px" }} data-testid="handleCancelInProcess" onClick={this.handleCancelInProcessDetails}>{this.tranSellerInProcessOrder("Close")}</StyledButtonModelInProcessDetails>
                                <StyledButtonModelInProcessInProcessDetails style={{ fontSize: "14px" }} data-testid="timeDataInProcess" onClick={this.timeDataInProcessDetails}>{this.tranSellerInProcessOrder("Confirm")}</StyledButtonModelInProcessInProcessDetails>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerInProcess>
                <Snackbar
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    open={this.state.isAlertOrder}
                    style={{ marginTop: '10px' }}
                    autoHideDuration={3000}
                    onClose={this.oncloseAlert}
                    data-testid="oncloseAlert"
                ><Alert variant="filled" severity="success">{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    modelTextInProcessDetails: { color: "#375280", fontFamily: 'Lato', fontSize: '18px', fontWeight: 400, marginBottom: "30px" },
    febBoxInProcessInProcessDetails: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    acceptOrderInProcessDetails: { backgroundColor: "#CCBEB1", width: "160px", height: "44px", borderRadius: "2px", color: "#FFFFFF", fontSize: "16px", fontFamily: 'Lato', fontWeight: 700 },
    orderDetailsNameInProcessDetails: { color: "#375280", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px" },
    orderDetailsValueInProcessDetails: { color: "#94A3B8", fontFamily: 'Lato', fontWeight: 400, fontSize: "16px", marginTop: "10px", textTransform: "capitalize" },
    newOrderIdInProcess: { color: "#375280", fontFamily: 'Lato', fontWeight: 700, fontSize: "24px", margin: '0px 16px' },
    tableBox: { border: "1px solid #E2E8F0", boxShadow: '0px', marginTop: "30px", padding: '20px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    new_orderBox: { backgroundColor: '#FFE7D0', color: '#BE5B00', padding: '6px 10px 6px 10px', borderRadius: '2px', textAlign: 'center', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    tableHaderrowInProcessDetails: {
        color: "#375280", fontSize: '14px', fontFamily: "Lato", fontWeight: 400
    },
    tableHaderrowInProcessDetailsTypo: {
        color: "#375280", fontSize: '14px', fontFamily: "Lato", fontWeight: 400, display: "flex"
    },
    changeOrderInProcessDetails: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '0px 0px 20px 0px' },
}

const StyledButtonModelInProcessDetails = styled(Button)(() => ({
    textTransform: "none",
    height: "45px",
    fontWeight: 500,
    color: "#375280",
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    width: "252px !important",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButtonModelInProcessInProcessDetails = styled(Button)(() => ({
    backgroundColor: '#CCBEB1',
    height: "45px",
    textTransform: "none",
    color: "#fff",
    width: "252px",
    borderRadius: "2px",
    fontWeight: 500,
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))

const StyledDrawerInProcess = styled(Modal)((theme) => ({
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: "none",
    alignItems: 'center',
}));

const TableRowCustom = styled(TableRow)(() => ({
    "& .headerText": {
        display: "flex",
        fontFamily: "Lato",
        "@media only screen and (max-width: 900px)": {
            fontSize: "15px",
            fontFamily: "Lato",
          }
    }
}))
// Customizable Area End