import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop, Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import Sidebar from "../../../components/src/Sidebar";
import { Vector, scroll } from "../src/assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { CountrySuccess } from "../../email-account-registration/src/EmailAccountRegistrationController.web";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import OtpInput from "react-otp-input";
import i18n from "../../../components/src/i18next/i18n";
import CircularProgress from '@material-ui/core/CircularProgress';


// Customizable Area End

import Customisableuserprofiles2Controller, {
  Props,
  configJSON,
} from "./Customisableuserprofiles2Controller";
import CountrySelect from "../../../components/src/CountryDropDown.web";
import BuyerDrawer from "../../../components/src/SellerStylish/BuyerDrawer";
import MoreVertIcon from "@material-ui/icons/MoreVert"

export default class Customisableuserprofiles2 extends Customisableuserprofiles2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handlePWDImgCSS = () => {
    return i18n.dir() === 'ltr' ? { right: 10 } : { left: 10 }
  }

  handleOldpasswordUI = () => {
    return (
      <Box style={{
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        <TextField
          data-test-id="oldPassword"
          fullWidth
          type={this.state.oldPasswordIcon ? "text" : "password"}
          value={this.state.oldPassword}
          variant="outlined"
          helperText={this.handleOldPasswordText()}
          error={true}
          className={this.generateClassName(this.state.errorsMessage.errorOldPassword, "inputFeild2", "inputFeild2Error2")}
          onChange={(event) => this.handleOldPassword(event.target.value)}
          placeholder={this.transProfileMain(configJSON.PlaceholderOldPasss)}
        />
        <Box style={{
          position: "absolute",
          cursor: "pointer",
          top: "14px",
          ...this.handlePWDImgCSS()
        }} onClick={() => {
          this.setState({
            oldPasswordIcon: !this.state.oldPasswordIcon
          })
        }}
          data-test-id="oldIconChange"
        >
          {
            this.state.oldPasswordIcon ? <Visibility style={webStyle.iconstyle} /> : <VisibilityOff style={webStyle.iconstyle} />
          }
        </Box>
      </Box>
    )
  }

  handleCountryCode = () => {
    return (
      <Box style={{
        display: "flex"
      }}>
        <CustomDropDown className={this.state.errorsMessage.errorPhoneNumber ? "isActive" : ""}>
          <CountrySelect
            id="phoneCode"
            name="phoneCode"
            value={this.state.selectedCountry?.country_code || "KW"}
            placeholder="Select Phone Code"
            data-test-id="handleUserProfilePhoneCodeChange"
            onChange={this.handleUserProfilePhoneCodeChange}
            countryCode={this.state.contryCodeResp}
            isBorder
          />
        </CustomDropDown>

        <MobileTextField
          data-test-id="phoneNumberTextInput"
          variant="outlined"
          onChange={(event) => this.handleNumber(event.target.value)}
          value={this.state.phoneNumber}
          helperText={this.handlePhoneNumber()}
          error={true}
          className={this.generateClassName(this.state.phoneNumberError, "inputFeild2", "inputFeild2Error2")}
          placeholder={this.transProfileMain(configJSON.PlaceholderPhone)}
          inputProps={{ maxLength: 12 }}
          InputProps={{
            style: {
              gap: "4px",
              border: "none",
              borderRadius: "0px",
            },
          }}
        />
      </Box>
    )
  }


  renderNavigationHeader = () => {
    return (
      <Box
        id="navigation-container"
        dir={i18n.dir()}
        style={{
          margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "27px 40px 0 0",
          ...webStyle.innerContainer
        }}
      >
        <Typography
          id="home-navigation"
          style={webStyle.innerContainer1}
          data-test-id="handleNavigation1"
          onClick={() => this.headerNavigation("LandingPage")}
        >
          {this.transProfile(configJSON.HomeMsg)}
        </Typography>

        <img
          id="breadcrumb-separator"
          src={Vector}
          style={{ transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }}
          alt="Separator"
        />

        <Typography
          id="profile-navigation"
          style={{ ...webStyle.innerContainer2, fontWeight: 700 }}
        >
          {this.transProfile(configJSON.ProfileMsg)}
        </Typography>
      </Box>
    );
  }

   renderDrawerToggle = () => {
    return (
      <div style={{ marginLeft: window.innerWidth >= 951?"260%":"80%", marginTop: "15%" }}>
        <MoreVertIcon
          data-test-id="drawerOpenMoreVertIcon"
          onClick={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}
        />
      </div>
    );
  }

  renderDrawerContent = () => {
    return (
      <BuyerDrawer
        navigation={this.props.navigation}
        data-test-id="drawerClose"
        open={this.state.isDrawerOpen}
        onClose={() => this.setState({ isDrawerOpen: !this.state.isDrawerOpen })}
      >
        <div>
          <Typography
            style={{
              margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "31px 40px 0 0",
              ...webStyle.innerContainer3
            }}
            dir={i18n.dir()}
          >
            <img src={scroll} alt="Scroll" />
            {this.state.fullName}
          </Typography>
          <Typography
            style={{
              margin: i18n.dir() === "ltr" ? "0 0 40px 37px" : "0 40px 37px 0",
              ...webStyle.innerContainer4
            }}
            dir={i18n.dir()}
          >
            {this.transProfile(configJSON.WelcomeAccountMsg)}
          </Typography>
        </div>
        <Sidebar navigation={this.props.navigation} data-test-id="mobilesidebar" />
      </BuyerDrawer>
    );
  }

  renderPersonalInfoFields = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} id="first-name-container">
          <Typography style={webStyle.inputHeadingBox1} id="first-name-label">
            {this.transProfileMain(configJSON.Fname)}
          </Typography>
          <TextField
            id="first-name"
            data-test-id="FirstNameIdmobileview"
            fullWidth
            variant="outlined"
            value={this.state.firstName}
            onChange={(event) => this.handleFirst(event.target.value)}
            className={this.generateClassName(this.state.firstNameError, "inputFeild2", "inputFeild2Error2")}
            helperText={this.handleHelperTextFirstname()}
            error={true}
            placeholder={this.transProfileMain(configJSON.PlaceholderFirst)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} id="last-name-container">
          <Typography style={webStyle.inputHeadingBox1} id="last-name-label">
            {this.transProfileMain(configJSON.Lname)}
          </Typography>
          <TextField
            id="last_name"
            data-test-id="LastNameIdmobileview"
            fullWidth
            variant="outlined"
            value={this.state.lastName}
            onChange={(event) => this.handleLast(event.target.value)}
            helperText={this.handleHelperText(this.state.errorsMessage.lastNameMessage)}
            error={true}
            className={this.generateClassName(this.state.lastNameError, "inputFeild2", "inputFeild2Error2")}
            placeholder={this.transProfileMain(configJSON.Placeholderlast)}
          />
        </Grid>
      </Grid>
    );
  }

  renderContactInfoFields = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} id="email-container">
          <Typography style={webStyle.inputHeadingBox1} id="email-label">
            {this.transProfileMain(configJSON.Email)}
          </Typography>
          <TextField
            id="email"
            data-test-id="emailmobileview"
            fullWidth
            variant="outlined"
            value={this.state.email}
            onChange={(event) => this.handleEmail(event.target.value)}
            helperText={this.handleHelperLastText(this.state.errorsMessage.emailMessage)}
            error={true}
            className={this.generateClassName(this.state.errorEmail, "inputFeild2", "inputFeild2Error2")}
            placeholder={this.transProfileMain(configJSON.PlaceholderEmail)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} id="phone-container">
          <Typography style={webStyle.inputHeadingBox1} id="phone-label">
            {this.transProfileMain(configJSON.Phone)}
          </Typography>
          {this.handleCountryCode()}
        </Grid>
      </Grid>
    );
  }

  renderSaveButton = () => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        id="save-button-container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          style={webStyle.savebuttn}
          data-test-id="handleSaveProfile"
          id="save-profile"
          onClick={this.handleSaveClick}
        >
          <Typography style={webStyle.saveText}>
            {this.transProfileMain(configJSON.SaveChangeMsg)}
          </Typography>
        </Box>
      </Grid>
    );
  }

  renderMobileNewPasswordField = () => {
    return (
      <Box style={{
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        <TextField
          data-test-id="newPasswordmobileview"
          fullWidth
          type={this.state.newPasswordIcon ? "text" : "password"}
          variant="outlined"
          value={this.state.newPassword}
          helperText={this.state.errorsMessage.newPasswordMessage || ""}
          error={true}
          className={this.generateClassName(this.state.errorsMessage.errorNewPassword, "inputFeild2", "inputFeild2Error2")}
          onChange={(event) => this.handleNewPassword(event.target.value)}
          placeholder={this.transProfileMain(configJSON.PlaceholderNewPass)}
        />
        <Box
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "14px",
            ...this.handlePWDImgCSS()
          }}
          onClick={() => this.setState({ newPasswordIcon: !this.state.newPasswordIcon })}
          data-test-id="newPasswordViewClickmobileview"
        >
          {this.state.newPasswordIcon ?
            <Visibility style={webStyle.iconstyle} /> :
            <VisibilityOff style={webStyle.iconstyle} />
          }
        </Box>
      </Box>
    );
  }

  renderMobileConfirmPasswordField = () => {
    return (
      <Box style={{
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        <TextField
          data-test-id="reNewPasswordmobileview"
          fullWidth
          type={this.state.ReNewPasswordIcon ? "text" : "password"}
          variant="outlined"
          value={this.state.rePassword}
          helperText={this.state.errorsMessage.rePasswordMessage || ""}
          error={true}
          className={this.generateClassName(this.state.errorsMessage.errorRePassword, "inputFeild2", "inputFeild2Error2")}
          onChange={(event) => this.handleRePassword(event.target.value)}
          placeholder={this.transProfileMain(configJSON.PlaceholderReNewPass)}
        />
        <Box
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "14px",
            ...this.handlePWDImgCSS()
          }}
          onClick={() => this.setState({ ReNewPasswordIcon: !this.state.ReNewPasswordIcon })}
          data-test-id="rePasswordView"
        >
          {this.state.ReNewPasswordIcon ?
            <Visibility style={webStyle.iconstyle} /> :
            <VisibilityOff style={webStyle.iconstyle} />
          }
        </Box>
      </Box>
    );
  }

  renderMobilePasswordChangeSection = () => {
    return (
      <>
        <Typography style={webStyle.changeText}>
          {this.transProfileMain(configJSON.ChangePasswordMSG)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.OldPass)}
            </Typography>
            {this.handleOldpasswordUI()}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.NewPass)}
            </Typography>
            {this.renderMobileNewPasswordField()}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.ReNewPass)}
            </Typography>
            {this.renderMobileConfirmPasswordField()}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              style={webStyle.changebuttn}
              data-test-id="changePasswordApimobileview"
              onClick={this.handleChangePassword}
            >
              <Typography style={webStyle.saveText}>
                {this.transProfileMain(configJSON.ChangePasswordMSG)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

   renderLoadingSpinner = () => {
    return (
      this.state.loading && (
        <Box
          id="loading-spinner"
          style={{
            textAlign: 'center',
            paddingBottom: '20px',
            position: "absolute",
            top: "62%",
            left: "50%",
            width: '54px',
            backgroundColor: "#FFFFFF",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress style={{ color: '#375280', marginTop: "20px" }} />
        </Box>
      )
    );
  }

  mobileView = () => {
    if (window.innerWidth < 951)
      return (
        <>
          <Grid item style={{ display: "flex", height: 117 }}>
            {this.renderNavigationHeader()}
            {this.renderDrawerToggle()}
            {this.renderDrawerContent()}
          </Grid>

          <Grid item xs={12} md={12} style={{ paddingInline: 30 }}>
            <Typography style={webStyle.mainHeading}>
              {this.transProfileMain(configJSON.MyprofileMsg)}
            </Typography>
            <MainContainer style={webStyle.innerContainer6} dir={i18n.dir()} id="main-container">
              <form data-test-id="formSubmitProfile" id="profile-form">
                {this.renderPersonalInfoFields()}
                {this.renderContactInfoFields()}
                {this.renderSaveButton()}
                {this.renderMobilePasswordChangeSection()}
              </form>
              {this.renderLoadingSpinner()}
            </MainContainer>
          </Grid>
        </>
      );
  }

  renderDesktopNavigationHeader = () => {
    return (
      <Box
        dir={i18n.dir()}
        style={{
          margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "27px 40px 0 0",
          ...webStyle.innerContainer
        }}
      >
        <Typography
          style={webStyle.innerContainer1}
          data-test-id="handleNavigation"
          onClick={() => this.headerNavigation("LandingPage")}
        >
          {this.transProfile(configJSON.HomeMsg)}
        </Typography>
        <img
          src={Vector}
          style={{ transform: i18n.dir() === "rtl" ? 'rotate(180deg)' : 'rotate(0deg)' }}
          alt="Separator"
        />
        <Typography style={{ ...webStyle.innerContainer2, fontWeight: 700 }}>
          {this.transProfile(configJSON.ProfileMsg)}
        </Typography>
      </Box>
    );
  }

  renderDesktopUserProfileHeader = () => {
    return (
      <>
        <div>
          <Typography
            style={{
              margin: i18n.dir() === "ltr" ? "27px 0 40px 0" : "31px 40px 0 0",
              ...webStyle.innerContainer3
            }}
            dir={i18n.dir()}
          >
            <img src={scroll} alt="Scroll" />
            {this.state.fullName}
          </Typography>
        </div>
        <Typography
          style={{
            margin: i18n.dir() === "ltr" ? "0 0 40px 37px" : "0 40px 37px 0",
            ...webStyle.innerContainer4
          }}
          dir={i18n.dir()}
        >
          {this.transProfile(configJSON.WelcomeAccountMsg)}
        </Typography>
      </>
    );
  }

   renderDesktopPersonalInfoFields = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography style={webStyle.inputHeadingBox1}>
            {this.transProfileMain(configJSON.Fname)}
          </Typography>
          <TextField
            data-test-id="FristNameId"
            fullWidth
            variant="outlined"
            value={this.state.firstName}
            onChange={(event) => this.handleFirst(event.target.value)}
            className={this.generateClassName(this.state.firstNameError, "inputFeild2", "inputFeild2Error2")}
            helperText={this.handleHelperTextFirstname()}
            error={true}
            placeholder={this.transProfileMain(configJSON.PlaceholderFirst)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} id="desktopLastName">
          <Typography style={webStyle.inputHeadingBox1}>
            {this.transProfileMain(configJSON.Lname)}
          </Typography>
          <TextField
            id="deskTopViewLastName"
            data-test-id="LastNameId"
            fullWidth
            variant="outlined"
            value={this.state.lastName}
            onChange={(event) => this.handleLast(event.target.value)}
            helperText={this.handleHelperText(this.state.errorsMessage.lastNameMessage)}
            error={true}
            className={this.generateClassName(this.state.lastNameError, "inputFeild2", "inputFeild2Error2")}
            placeholder={this.transProfileMain(configJSON.Placeholderlast)}
          />
        </Grid>
      </Grid>
    );
  }

renderDesktopContactInfoFields = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography style={webStyle.inputHeadingBox1}>
            {this.transProfileMain(configJSON.Email)}
          </Typography>
          <TextField
            data-test-id="emailChange"
            fullWidth
            variant="outlined"
            value={this.state.email}
            onChange={(event) => this.handleEmail(event.target.value)}
            helperText={this.handleHelperLastText(this.state.errorsMessage.emailMessage)}
            error={true}
            className={this.generateClassName(this.state.errorEmail, "inputFeild2", "inputFeild2Error2")}
            placeholder={this.transProfileMain(configJSON.PlaceholderEmail)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Typography style={webStyle.inputHeadingBox1}>
            {this.transProfileMain(configJSON.Phone)}
          </Typography>
          {this.handleCountryCode()}
        </Grid>
      </Grid>
    );
  }

  renderDesktopSaveButton = () => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Box
          style={webStyle.savebuttn}
          data-test-id="handleSaveProfile"
          onClick={this.handleSaveClick}
        >
          <Typography style={webStyle.saveText}>
            {this.transProfileMain(configJSON.SaveChangeMsg)}
          </Typography>
        </Box>
      </Grid>
    );
  }

  renderDesktopNewPasswordField = () => {
    return (
      <Box style={{
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        <TextField
          data-test-id="newPassword"
          fullWidth
          type={this.state.newPasswordIcon ? "text" : "password"}
          variant="outlined"
          value={this.state.newPassword}
          helperText={this.state.errorsMessage.newPasswordMessage || ""}
          error={true}
          className={this.generateClassName(this.state.errorsMessage.errorNewPassword, "inputFeild2", "inputFeild2Error2")}
          onChange={(event) => this.handleNewPassword(event.target.value)}
          placeholder={this.transProfileMain(configJSON.PlaceholderNewPass)}
        />
        <Box
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "14px",
            ...this.handlePWDImgCSS()
          }}
          onClick={() => this.setState({ newPasswordIcon: !this.state.newPasswordIcon })}
          data-test-id="newPasswordViewClick"
        >
          {this.state.newPasswordIcon ?
            <Visibility style={webStyle.iconstyle} /> :
            <VisibilityOff style={webStyle.iconstyle} />
          }
        </Box>
      </Box>
    );
  }

  renderDesktopConfirmPasswordField = () => {
    return (
      <Box style={{
        display: "flex",
        alignItems: "center",
        position: "relative"
      }}>
        <TextField
          data-test-id="reNewPassword"
          fullWidth
          type={this.state.ReNewPasswordIcon ? "text" : "password"}
          variant="outlined"
          value={this.state.rePassword}
          helperText={this.state.errorsMessage.rePasswordMessage || ""}
          error={true}
          className={this.generateClassName(this.state.errorsMessage.errorRePassword, "inputFeild2", "inputFeild2Error2")}
          onChange={(event) => this.handleRePassword(event.target.value)}
          placeholder={this.transProfileMain(configJSON.PlaceholderReNewPass)}
        />
        <Box
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "14px",
            ...this.handlePWDImgCSS()
          }}
          onClick={() => this.setState({ ReNewPasswordIcon: !this.state.ReNewPasswordIcon })}
          data-test-id="rePasswordView"
        >
          {this.state.ReNewPasswordIcon ?
            <Visibility style={webStyle.iconstyle} /> :
            <VisibilityOff style={webStyle.iconstyle} />
          }
        </Box>
      </Box>
    );
  }

  renderDesktopPasswordChangeSection = () => {
    return (
      <>
        <Typography style={webStyle.changeText}>
          {this.transProfileMain(configJSON.ChangePasswordMSG)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.OldPass)}
            </Typography>
            {this.handleOldpasswordUI()}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.NewPass)}
            </Typography>
            {this.renderDesktopNewPasswordField()}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography style={webStyle.inputHeadingBox1}>
              {this.transProfileMain(configJSON.ReNewPass)}
            </Typography>
            {this.renderDesktopConfirmPasswordField()}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              style={webStyle.changebuttn}
              data-test-id="changePasswordApi"
              onClick={this.handleChangePassword}
            >
              <Typography style={webStyle.saveText}>
                {this.transProfileMain(configJSON.ChangePasswordMSG)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  renderDesktopLoadingSpinner = () => {
    return (
      this.state.loading && (
        <Box
          style={{
            textAlign: 'center',
            paddingBottom: '20px',
            position: "absolute",
            top: "62%",
            left: "50%",
            width: '54px',
            backgroundColor: "#FFFFFF",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress style={{ color: '#375280', marginTop: "20px" }} />
        </Box>
      )
    );
  }

  desktopView = () => {
    if (window.innerWidth >= 951)
      return (
        <>
          <Grid item>
            {this.renderDesktopNavigationHeader()}
            {this.renderDesktopUserProfileHeader()}
            <Sidebar navigation={this.props.navigation} />
          </Grid>

          <Grid item xs={12} md={8} style={{ paddingInline: 55, marginTop: '90px' }}>
            <Typography style={webStyle.mainHeading}>
              {this.transProfileMain(configJSON.MyprofileMsg)}
            </Typography>

            <MainContainer style={webStyle.innerContainer6} dir={i18n.dir()}>
              <form data-test-id="formSubmitProfile">
                {this.renderDesktopPersonalInfoFields()}
                {this.renderDesktopContactInfoFields()}
                {this.renderDesktopSaveButton()}
                {this.renderDesktopPasswordChangeSection()}
              </form>

              {this.renderDesktopLoadingSpinner()}
            </MainContainer>
          </Grid>
        </>
      );
  }

  render() {
    

    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Header navigation={this.props.navigation} data-test-id="header" />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType} data-test-id="alertSeverity">
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
        <Grid
          style={{ backgroundColor: "#F8F8F8", marginBottom: "58px" }}
          dir={i18n.dir()}
          container
          data-test-id="gridContainer"
        >
          {this.desktopView()}
          {this.mobileView()}
        </Grid>

        <StyledModal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.emailOtpVerify}
          onClose={this.handleEmailAndOtpVerifyClose}
          closeAfterTransition
          data-test-id="transitionModal"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade
            in={this.state.emailOtpVerify}
            style={{ borderRadius: '10px', maxWidth: "390px", backgroundColor: "white" }}
            data-test-id="fadeTransition"
          >
            <MainContainerDialog>
              <Box className={"formBox"} data-test-id="formBox">
                <Box data-test-id="formSubmit" style={{ width: "100%" }}>
                  <Box className={"headBox"} data-test-id="headBox">
                    <Typography className={"headText"} data-test-id="headerText">
                      {this.transProfileMain(configJSON.otpVerification)}
                    </Typography>
                  </Box>
                  <Typography data-test-id="maskEmail" className={"headSubText"}>
                    {this.handleOption() ? (
                      <>
                        {this.transProfileMain(configJSON.sentTo)} <br /> {this.transProfileMain(configJSON.emailText)}
                        {this.maskEmail()}
                      </>
                    ) : (
                      <>
                        {this.transProfileMain(configJSON.sentTo)} <br />{this.transProfileMain(configJSON.smsText)}
                        {this.maskPhone()}
                      </>
                    )}
                  </Typography>
                  <Box className="otpBox1" data-test-id="otpBox1">
                    <OtpContainer data-test-id="otpContainer">
                      <OtpInput
                        onChange={this.handleOTPChange}
                        value={this.state.otpInput}
                        numInputs={4}
                        containerStyle={{ gap: "15px" }}
                        data-test-id="otpInputID"
                        inputStyle={{
                          height: "50px",
                          width: "50px",
                          background: "#F0F0F0",
                          margin: "0px 3px",
                          border: "none",
                          fontSize: "25px",
                          textAlign: "center",
                          color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)",
                          outline: "none",
                        }}
                        renderInput={(props) => (
                          <input
                            {...props}
                            type="text"
                            onKeyDown={(event) => { this.handlekeyDown(event); }}
                            data-test-id="otpInputField"
                          />
                        )}
                      />
                      {this.state.errorOtpMsg && (
                        <Box className={"otpMsgBox"} data-test-id="otpMsgBox">
                          {this.state.errorOtpMsg}
                        </Box>
                      )}
                    </OtpContainer>
                  </Box>
                  <Box className={"textHeading1"} data-test-id="textHeading">
                    <Typography className={"bottomText"} align="center">
                      <Typography className={"reciveText"} data-test-id="receiveText">
                        {this.transProfileMain(configJSON.reciveCodeText)}
                      </Typography>
                      <Typography data-test-id="resendOtp" onClick={this.handeResentOTPApiCall}>
                        {this.transProfileMain(configJSON.resendCode)}
                      </Typography>
                    </Typography>
                    <Button
                      variant="contained"
                      className={"LoginUserButton"}
                      onClick={() => this.handleSubmit()}
                      disableElevation
                      data-test-id="handleSaveSubmit"
                    >
                      {this.transProfileMain(configJSON.verifyAccount)}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </MainContainerDialog>
          </Fade>
        </StyledModal>

        <Footer navigation={this.props.navigation} data-test-id="footer" />
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start


const StyledModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "none",
}));

const OtpContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 5px 10px",
});

const MainContainerDialog = styled(Box)({
  "& .formBox": {
    display: "flex",
    padding: "20px",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    flexDirection: "column",
  },
  "& .LoginUserButton": {
    width: "100% ",
    borderRadius: "0px",
    color: "white ",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1) ",
    gap: "8px",
    height: "56px ",
    textTransform: 'none',
    fontSize: "19px",
    fontWeight: 600,
    margin: "15px 0px 0px 0px"
  },
  "& .reciveText": {
    color: "var(--Primary-Purple-500, #CCBEB1)"
  },
  "& .textHeading1": {
    textAlign: "center"
  },
  "& .bottomText": {
    display: "flex",
    color: "#375280 ",
    cursor: "pointer",
    fontFamily: "Lato,sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .otpMsgBox": {
    color: "red",
    margin: "11px 12% 0px 0px",
    width: "100%",
    fontWeight: 300,
    fontFamily: "Avenir, sans-serif"
  },
  "& .headBox": {
    width: "100%",
    textAlign: "center"
  },
  "& .headText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "26px",
    alignSelf: "center",
  },
  "& .headSubText": {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    textOverflow: "ellipsis",
    src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
    fontFamily: "Avenir,sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
    alignSelf: "center",
  },
  "& .otpBox1": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 15,
    padding: "10px 0px",
  },
});

const MainContainer = styled(Box)({
  "& .MuiFormHelperText-root.Mui-error": {
    display: "flex"
  },
  "& .inputFeild2Error2": {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",
      border: "none"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
  },
  "& .inputFeild2": {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  },
});

const CustomDropDown = styled(Box)({
  position: "relative",
  width: "180px",
  cursor: "pointer"
});

const DropDownHeader = styled(Box)({
  padding: "10px",
  gap: "15px",
  display: "flex",
  alignItems: "center",
  border: "none",
  borderRadius: "2px",
  background: "#F0F0F0",
  height: "36px",
  color: "#375280",
  fontSize: "18px",
  "&.isActive": {
    border: "1px solid red",
  },
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const MobileTextField = styled(TextField)({
  width: "100%",
  marginLeft: "20px",
  gap: "4px",
  border: "none",
  borderRadius: "none",
  "& .MuiOutlinedInput-root": {
    border: "none"
  }
});

const SelectedCountry = styled(Box)({
  display: "flex",
  alignItems: "center"
});

const DropDownOptions = styled(Box)({
  left: 0,
  width: "100%",
  border: "1px solid #ccc",
  borderTop: "none",
  borderRadius: "0 0 4px 4px",
  backgroundColor: "#fff",
  position: "absolute",
  zIndex: 1,
  height: "60vh",
  overflow: "auto"
});

const OptionsValue = styled(Box)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "#F0F0F0"
  }
});

const webStyle = {
  plainText: {
    "& .MuiTextField-root": {
      "&:hover": {
        border: "1px solid #F0F0F0 !important",
      },
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root:hover": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#F0F0F0",
      borderRadius: "2px",

    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#F0F0F0",
    }
  } as React.CSSProperties,
  errorText: {
    "& .MuiFormControl-root": {
      border: "1px solid #F0F0F0",
      borderRadius: "2px",
    },
    '& .MuiInputBase-input': {
      color: "#375280"
    },
    "& .MuiFormHelperText-contained": {
      margin: "0 !important"
    },
  } as React.CSSProperties,
  iconstyle: {
    color: "#000000",
    opacity: "0.5",
  },
  changeText: {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    marginTop: '25px'
  },
  saveText: {
    fontFamily: 'Lato',
    fontSize: "20px",
    fontWeight: 800,
  },
  changebuttn: {
    height: "56px",
    borderRadius: "3px",
    backgroundColor: "rgb(204, 190, 177)",
    display: "flex",
    marginTop: "2.8rem",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    cursor: "pointer",
  },
  savebuttn: {
    width: "475px",
    height: "56px",
    borderRadius: "3px",
    backgroundColor: "rgb(204, 190, 177)",
    display: "flex",
    marginTop: "2.8rem",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    cursor: "pointer",

  },
  emailContainer: {
    width: "176px",
    height: "22px",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "16px",
    color: "#375280"
  },
  nameContainer: {
    width: "76px",
    height: "22px",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: "16px",
    color: "#375280"
  },
  leftContainer: {
    width: "967px",
    height: "642px",
    position: "absolute" as "absolute",
    top: "198px",
    left: "405px",
  },
  mainHeading: {
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    position: "relative",
    marginBottom: '35px'
  } as React.CSSProperties,

  secondMainHeading: {
    width: "183px",
    height: "31px",
    fontWeight: 800,
    fontSize: "20px",
    fontFamily: "Lato",
    color: "#375280",
    position: "relative",
    marginBottom: "16px"
  } as React.CSSProperties,

  innerContainer: {
    color: "#475569",
    fontWeight: 400,
    lineHeight: "22px",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    marginLeft: "40px",
    marginTop: "27px",
    fontFamily: "Lato",
  },
  innerContainer1: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: "pointer"
  },
  innerContainer2: {
    padding: "4px 6px 4px 6px",
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "22px",
    cursor: "pointer",
    color: "#475569"
  },
  innerContainer3: {
    fontFamily: "Lato",
    fontSize: "28px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 800,
    color: "#375280",
    marginLeft: "40px",
    marginTop: "31px",
    display: "flex",
    alignItems: "center",
    gap: "15px",
    textTransform: "capitalize"
  } as React.CSSProperties,
  innerContainer4: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "33.5px",
    fontWeight: 400,
    color: "#807D7E",
    marginLeft: "40px",
    marginBottom: "37px"

  },
  innerContainer5: {
    borderRadius: "50%",
    backgroundColor: "#CCBEB121",
    width: "170px",
    height: "170px",
    alignItems: "center",

    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  innerContainer6: {
    boxShadow: "2px 2px 4px 0px #0000000D, -2px -2px 4px 0px #0000000D",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    padding: '30px'
  } as React.CSSProperties,

  inputHeadingBox1: {
    color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
    margin: "10px 0",
    textOverflow: "ellipsis",
    fontFamily: "Lato",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "26px",
  },



};
// Customizable Area End
