import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SellerInventoryManagementController, {
  Props,
} from "./SellerInventoryManagementController";
import {
  Grid,
  Box,
  Typography,
  Popover,
  styled,
  Checkbox,
  Button,
  InputBase,
  IconButton,
  Tabs,
  Tab,
  Dialog,
  FormHelperText,
} from "@material-ui/core";
import {
  backBtnIcon,
  crossIcon,
  filterIcon,
} from "../../assets";
import { SellerStoreHeader } from "../AnalyticsInsights/SellerAnalyticsInsights.web";
import { SellerStoreBodyContainer } from "../Revenue/SellerRevenue.web";
import { search } from "../../../../catalogue/src/assets";
import SellerUpdateInventory from "./UpdateInventory/UpdateInventory.web";
import SellerSetPrices from "./SetPrices/InventorySetPrices.web";
import SellerAssignStore from "./AssignStore/InventoryAssignStore.web";
import ReusableSnackbar from "../../../../../components/src/AlertContainer";
import DownloadTemplates from "./DownloadTemplates/DownloadTemplates.web";
import BulkActions from "./BulkActions/BulkAction.web";
import SellerPairProduct from "./PairTheProducts/InventoryPairTheProducts.web";
import i18n from "../../../../../components/src/i18next/i18n";
import SellerStylishContainer from "../../../../../components/src/SellerStylish/SellerStylishContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#CCBEB1",
    },
  },
});
// Customizable Area End

export default class SellerInventoryManagement extends SellerInventoryManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handlePopover = () => { 
    return i18n.dir()==='ltr'?'right': 'left';
  }

  handleBackCSS = () => {
    return i18n.dir()==='ltr'? "rotate(0deg)" : "rotate(180deg)";
  }

  renderContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 1:
        return (
          <SellerUpdateInventory
            data-test-id="seller-update-store"
            navigation={this.props.navigation}
            searchValue={this.state.searchValue}
            filter={this.state.filter}
            handleUpdateStore={this.handleUpdateStore}
            removeData={this.state.isInParentAPICalled}
            confirmData={this.handleConfirmUpdateInventory}
            handleRedirect={this.handleRedirectBack}
          />
        );
      case 2:
        return (
          <SellerSetPrices
            navigation={this.props.navigation}
            filterValue={this.state.filter}
            handleUpdatePriceStore={this.handleUpdateStore}
            removeInventoryData={this.state.isInParentAPICalled}
            confirmInventoryData={this.handleConfirmUpdateInventory}
            data-test-id="seller-update-set-price-store"
            handleRedirect={this.handleRedirectBack}
            searchValue={this.state.searchValue}
          />
        );
      case 3:
        return (
          <SellerAssignStore
            navigation={this.props.navigation}
            handleAssignStore={this.handleAssignStore}
            searchValue={this.state.search}
            data-test-id="seller-assign-store"
            handleRedirect={this.handleRedirectBack}
            removeData={this.state.isInParentAPICalled}
            filterValue={this.state.assignFilterState}
          />
        );
      case 4:
        return (
          <SellerPairProduct
            navigation={this.props.navigation}
            searchValue={this.state.search}
            data-test-id="seller-pair-products"
            handleRedirect={this.handleRedirectBack}
          />
        );
      case 5:
        return (
          <BulkActions
            navigation={this.props.navigation}
            handleRedirect={this.handleRedirectBack}
          />
        );
      case 6:
        return (
          <DownloadTemplates
            navigation={this.props.navigation}
            handleRedirect={this.handleRedirectBack}
          />
        );
      default:
        return null;
    }
  };

  renderHeaderContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case 1:
        return this.activeTab1Header();
      case 2:
        return this.activeTab1Header();
      case 3:
        return this.activeTab3Header();
      case 4:
        return this.activeTab4Header();
      case 5:
        return <></>;
      case 6:
        return <></>;
      default:
        return null;
    }
  };
  
  renderASFilterRight = () => {
    const { selectedSetPrice } = this.state.filterState;

    switch (selectedSetPrice) {
      case "Size":
        return this.renderSizeFilter();

      case "Price":
        return this.renderPriceFilter();

      case "Category":
        return this.renderCategoryFilter();

      case "Sub Category":
        return this.renderSubCategoryFilter();

      default:
        return <></>;
    }
  };

  renderSizeFilter = () => (
    <Box>
      {this.state.sizes.map((f) => (
        <SPFilterRight key={+f.id} dir={i18n.dir()} data-test-id="size-test-dive-id">
          <CheckboxContainer
            color="secondary"
            data-test-id={`as-size-checked-${+f.id}`}
            checked={this.isSizeChecked(+f.id)}
            onChange={() => this.handleSizeCheckboxChange(+f.id)}
          />
          <Typography
            className={`filter-right-title ${
              this.isSizeChecked(+f.id) ? "selected" : ""
            }`}
          >
            {this.tranInventoryManagement(f.attributes.name)}
          </Typography>
        </SPFilterRight>
      ))}
    </Box>
  );

  renderPriceFilter = () => (
    <>
      <PriceBoxContainer>
        <PriceBoxContainerInput
          startAdornment="$ "
          value={this.state.value.min}
          placeholder={this.tranInventoryManagement("Min Price")}
          onChange={(event) => this.handleAsFilterPriceChange(event, 0)}
          data-test-id="as-min-price-test-id"
          inputProps={{ maxLength: 6 }}
          onBlur={this.handlePriceBlur}
        />
        <PriceBoxContainerInput
          startAdornment="$ "
          value={this.state.value.max}
          inputProps={{ maxLength: 6 }}
          placeholder={this.tranInventoryManagement("Max Price")}
          onChange={(event) => this.handleAsFilterPriceChange(event, 1)}
          data-test-id="as-max-price-test-id"
          onBlur={this.handlePriceBlur}
        />
      </PriceBoxContainer>
      {this.state.assignStoreFilterError.priceError && (
        <FormHelperTextContainer style={{ padding: "0px 15px" }}>
          {this.state.assignStoreFilterError.priceError}
        </FormHelperTextContainer>
      )}
    </>
  );

  renderCategoryFilter = () => (
    <Box>
      {this.state.category.map((f) => (
        <SPFilterRight dir={i18n.dir()} key={+f.id}>
          <CheckboxContainer
            color="secondary"
            data-test-id={`as-category-checked-${+f.id}`}
            checked={this.isCategoryChecked(+f.id)}
            onChange={() => this.handleCategoryCheckboxChange(+f.id)}
          />
          <Typography
            className={`filter-right-title ${
              this.isCategoryChecked(+f.id) ? "selected" : ""
            }`}
          >
            {this.tranInventoryManagement(f.attributes.name)}
          </Typography>
        </SPFilterRight>
      ))}
    </Box>
  );

  renderSubCategoryFilter = () => (
    <Box>
      {this.state.subCategory.length === 0 ? (
        <Typography className="sub-category-not-found">
          {this.tranInventoryManagement("Please Select Any one Category For Filter")}
        </Typography>
      ) : (
        <>
          {this.state.subCategory.map((f) => (
            <SPFilterRight key={+f.id} dir={i18n.dir()}>
              <CheckboxContainer
                color="secondary"
                data-test-id={`as-sub-category-checked-${+f.id}`}
                checked={this.isSubCategoryChecked(+f.id)}
                onChange={() => this.handleSubCategoryCheckboxChange(+f.id)}
              />
              <Typography
                className={`filter-right-title ${
                  this.isSubCategoryChecked(+f.id) ? "selected" : ""
                }`}
              >
                {this.tranInventoryManagement(f.attributes.name)}
              </Typography>
            </SPFilterRight>
          ))}
        </>
      )}
    </Box>
  );

  activeTab1Header = () => (
    <UIHeaderRightDiv>
      <InputBase
        placeholder={this.tranInventoryManagement("Search")}
        style={{ ...webStyle.searchBox, width: "210px", height: "44px" }}
        startAdornment={
          <IconButton style={{ padding: "10px" }} aria-label="search">
            <img src={search} width="20px" height="20px" />
          </IconButton>
        }
        data-test-id="search-box-test-id"
        value={this.state.search}
        onChange={this.handleSearchInventory}
        name="searchValue"
      />
      <Button
        variant={
          this.determineVariantStyle(this.state.updatedInventory.length !== 0)
            .variant
        }
        className={
          this.determineVariantStyle(this.state.updatedInventory.length !== 0)
            .className
        }
        disableElevation
        data-test-id="confirm-btn-test-id"
        onClick={
          this.state.updatedInventory.length !== 0
            ? () => this.handleConfirmUpdateInventory()
            : undefined
        }
        disabled={this.state.updatedInventory.length === 0}
      >
        {this.tranInventoryManagement("Confirm")}
      </Button>
      <div>
        <img
          src={filterIcon}
          alt="filter-icon"
          onClick={this.handleUpdateInventoryFilterClick}
          data-test-id="ui-filter-ic"
          style={{ cursor: "pointer" }}
        />
        <PopoverContainer
          open={this.state.filterPopup.openInventoryUpdate}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: this.handlePopover(),
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          dir={i18n.dir()}
          data-test-id="step-1-2-filter-dive-test-id"
        >
          <Box className="filter-header">
            <Typography className="filter-header-title">{this.tranInventoryManagement("Filters")}</Typography>
            <img
              src={crossIcon}
              onClick={this.handleCloseUpdateInventoryFilter}
              style={{cursor: "pointer"}}
              alt="close-icon"
              data-test-id="ui-filter-close-ic"
            />
          </Box>
          <Box className="filter-body">
            <Box className="filter-container">
              <Typography className="filter-body-title">
                {this.tranInventoryManagement("Low On Stock")}
              </Typography>
              <CheckboxContainer
                checked={this.state.filterState.updateInventory.lowOnStock}
                data-test-id="ui-filter-low-on-stock"
                onChange={() =>
                  this.handleCheckboxFilterInventory("lowOnStock")
                }
                color="secondary"
              />
            </Box>
            <Box className="filter-container">
              <Typography className="filter-body-title">{this.tranInventoryManagement("Unlisted")}</Typography>
              <CheckboxContainer
                checked={this.state.filterState.updateInventory.unlisted}
                data-test-id="ui-filter-unlisted"
                onChange={() => this.handleCheckboxFilterInventory("unlisted")}
                color="secondary"
              />
            </Box>
            <Box className="filter-container">
              <Typography className="filter-body-title">{this.tranInventoryManagement("Listed")}</Typography>
              <CheckboxContainer
                checked={this.state.filterState.updateInventory.listed}
                data-test-id="ui-filter-listed"
                onChange={() => this.handleCheckboxFilterInventory("listed")}
                color="secondary"
              />
            </Box>
            <Box className="filter-container">
              <Typography className="filter-body-title">
                {this.tranInventoryManagement("Out Of Stock")}
              </Typography>
              <CheckboxContainer
                checked={this.state.filterState.updateInventory.outOfStock}
                data-test-id="ui-filter-out-of-stock"
                onChange={() =>
                  this.handleCheckboxFilterInventory("outOfStock")
                }
                color="secondary"
              />
            </Box>
            {this.state.inventoryFilter.error && (
              <FormHelperTextContainer>
                {this.state.inventoryFilter.errorMessage}
              </FormHelperTextContainer>
            )}
            <Box className="btn-container">
              <Button
                variant="outlined"
                className={"clear-all-btn"}
                disableElevation
                data-test-id="clear-all-test-id"
                onClick={() => this.handleClearAll()}
              >
                {this.tranInventoryManagement("Clear All")}
              </Button>
              <Button
                variant="contained"
                className={"apply-btn"}
                disableElevation
                onClick={() => this.handleFilterApply()}
                data-test-id="filter-apply-test-id"
              >
                {this.tranInventoryManagement("Apply")}
              </Button>
            </Box>
          </Box>
        </PopoverContainer>
      </div>
    </UIHeaderRightDiv>
  );

  activeTab3Header = () => (
    <UIHeaderRightDiv>
      <InputBase
        placeholder={this.tranInventoryManagement("Search")}
        style={{ ...webStyle.searchBox, width: "210px", height: "44px" }}
        startAdornment={
          <IconButton style={{ padding: "10px" }} aria-label="search">
            <img src={search} width="20px" height="20px" />
          </IconButton>
        }
        data-test-id="search-tab3-box-test-id"
        value={this.state.search}
        onChange={this.handleSearchInventory}
        name="searchValue"
      />
      <Button
        variant={
          this.determineVariantStyle(this.state.variantIds.length !== 0).variant
        }
        className={
          this.determineVariantStyle(this.state.variantIds.length !== 0)
            .className
        }
        disableElevation
        data-test-id="as-btn-test-id"
        onClick={
          this.state.variantIds.length !== 0
            ? () => this.handleAssignStoreDialogClose()
            : undefined
        }
        disabled={this.state.variantIds.length === 0}
      >
        {this.tranInventoryManagement("Assign Store")}
      </Button>
      <div>
        <img
          src={filterIcon}
          alt="filter-icon"
          data-test-id="as-filter-ic"
          style={{ cursor: "pointer" }}
          onClick={this.handleAssignStoreFilterClick}
        />
        <PopoverContainer
          open={this.state.filterPopup.openAssignStore}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: this.handlePopover(),
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          data-test-id="as-filter-popup"
          dir={i18n.dir()}
        >
          <Box className="filter-header">
            <Typography className="filter-header-title">{this.tranInventoryManagement("Filters")}</Typography>
            <img
              src={crossIcon}
              onClick={this.handleCloseAssignStoreFilter}
              style={{cursor: "pointer"}}
              alt="close-icon"
              data-test-id="as-filter-close-ic"
            />
          </Box>
          <Box className="filter-body" style={{ paddingTop: 0 }}>
            <Box>
              <Grid container>
                <Grid item xs={5}>
                  <SetPriceFilterListContainer dir={i18n.dir()} data-test-id="set-price-filter-dive-test-id">
                    {this.state.filterState.setPrice.map((item) => (
                      <Box
                        key={item.name}
                        onClick={() =>
                          this.handleSelectedSetPricesFilter(item.name)
                        }
                        data-test-id={`as-filter-type-${item.name}`}
                        style={{cursor: "pointer"}}
                        className={
                          item.name === this.state.filterState.selectedSetPrice
                            ? "set-price-selected"
                            : ""
                        }
                      >
                        <Typography className="list-item-textfield">
                          {this.tranInventoryManagement(item.name)}
                        </Typography>
                      </Box>
                    ))}
                  </SetPriceFilterListContainer>
                </Grid>
                <Grid item xs={7}>
                  <RightFilterBoxContainer>
                    {this.renderASFilterRight()}
                  </RightFilterBoxContainer>
                </Grid>
              </Grid>
            </Box>
            {this.state.assignStoreFilterError.emptyError && (
              <FormHelperTextContainer>
                {this.state.assignStoreFilterError.emptyError}
              </FormHelperTextContainer>
            )}
            <Box className="btn-container">
              <Button
                variant="outlined"
                className={"clear-all-btn"}
                disableElevation
                data-test-id="as-filter-clear-test-id"
                onClick={() => this.handleAssignStoreClearAll()}
              >
                {this.tranInventoryManagement("Clear All")}
              </Button>
              <Button
                variant="contained"
                className={"apply-btn"}
                onClick={() => this.handleAssignStoreFilterApply()}
                disableElevation
                data-test-id="as-filter-apply"
              >
                {this.tranInventoryManagement("Apply")}
              </Button>
            </Box>
          </Box>
        </PopoverContainer>
      </div>
    </UIHeaderRightDiv>
  );

  activeTab4Header = () => (
    <InputBase
      placeholder={this.tranInventoryManagement("Search")}
      style={{ ...webStyle.searchBox, width: "210px", height: "44px" }}
      value={this.state.search}
      startAdornment={
        <IconButton style={{ padding: "10px" }} aria-label="search">
          <img src={search} width="20px" height="20px" />
        </IconButton>
      }
      onChange={this.handleSearchInventory}
      data-test-id="search-box-tab-4-test-id"
      name="searchValue"
    />
  );

  inventoryRole = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return "Seller";
      case "stylist":
        return "Stylish";
      default:
        return "";
    }
  };

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <SellerStylishContainer role={this.inventoryRole()} navigation={this.props.navigation}>
          <SellerStoreBodyContainer>
              <SellerStoreHeader>
                <UIHeader>
                  <Box className="title-container">
                    {this.state.roleData === "seller" && (
                      <img
                        src={backBtnIcon}
                        data-test-id="back-btn-test-id"
                        alt="backIcon"
                        style={{transform: this.handleBackCSS()}}
                        onClick={() =>
                          this.handleRedirectBack("SellerStoreProfile")
                        }
                      />
                    )}
                    <Typography id="revenue-title" className="title">
                      {this.tranInventoryManagement("Inventory Management")}
                    </Typography>
                  </Box>
                  <Box className="btn-container">
                    {this.renderHeaderContent()}
                  </Box>
                </UIHeader>
              </SellerStoreHeader>
              <SellerInventoryData>
                <Grid container spacing={3}>
                  {this.state.inventoryCount.map(
                    (item: { path: string; value: string; name: string }) => (
                      <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                        <Box className="inventory-data-container">
                          <img src={item.path} alt={item.name} />
                          <Box className="value-container">
                            <Box className="value-main-container">
                              <Typography className="name-text-title">
                                {this.tranInventoryManagement(item.name)}
                              </Typography>
                              <Typography className="value-text-title">
                                {item.value}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  )}
                </Grid>
              </SellerInventoryData>

              <TabWrapBox className="inventory-tab">
                <Tabs
                  value={this.state.activeTab}
                  onChange={(event, newValue) =>
                    this.handleInventoryTabChange(newValue)
                  }
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: { ...webStyle.tabs },
                  }}
                  style={{
                    overflowX: "auto",
                    width: "fit-content",
                  }}
                  id="tab-test"
                  data-test-id="tab-test"
                >
                  {this.state.inventoryTabData.map((tab) => (
                    <Tab
                      data-test-id={tab.testId}
                      label={this.tranInventoryManagement(tab.label)}
                      style={{
                        ...webStyle.allTab,
                        textTransform: "none",
                        padding: 15,
                      }}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
              </TabWrapBox>

              <InventoryBodyContainer>
                {this.renderContent()}
              </InventoryBodyContainer>
          </SellerStoreBodyContainer>
        </SellerStylishContainer>
        <DialogAssignStoreContainer
          data-test-id="update-store-time-dialog"
          open={this.state.isAssignStoreDialogOpen}
          dir={i18n.dir()}
        >
          <Box className="cross-icon-container">
            <img
              src={crossIcon}
              onClick={() => this.handleAssignStoreDialogClose()}
              style={{cursor: "pointer"}}
              alt="cross-icon"
              data-test-id="as-dialog-cross-icon-test"
            />
          </Box>
          <Box className="content-container">
            <Typography className="assign-store-title-container">
              {this.tranInventoryManagement("Assign Stores")}
            </Typography>
            <Box className="main-container">
              <InputBase
                placeholder={this.tranInventoryManagement("Search Store")}
                style={{ ...webStyle.searchBox, height: "58px", width: "100%" }}
                startAdornment={
                  <IconButton style={{ padding: "10px" }} aria-label="search">
                    <img src={search} width="20px" height="20px" />
                  </IconButton>
                }
                value={this.state.searchAssignStoreQuery}
                onChange={this.handleAssignStoreSearch}
                data-test-id="as-dialog-search-box-test-id"
                name="search"
              />
              <Box className="select-all-product-container">
                <Typography className="select-all-title">
                  {this.tranInventoryManagement("Select All Products")}
                </Typography>{" "}
                <CheckboxContainer
                  checked={this.state.isAllSelectedStore}
                  data-test-id="as-dialog-all-store"
                  onChange={() => this.handleCheckboxAllSelectStore()}
                  color="secondary"
                />
              </Box>
              <Box className="store-name-display-container">
                {this.state.selectedAssignData.map((selectedAssignStore) => (
                  <Box className="store-name-container">
                    <Box className="store-name-left">
                      <img
                        src={selectedAssignStore.path}
                        alt={`img-${selectedAssignStore.id}`}
                        className="img-container"
                      />
                      <Typography className="store-name">
                        {selectedAssignStore.name}
                      </Typography>
                    </Box>
                    <CheckboxContainer
                      checked={selectedAssignStore.isSelected}
                      data-test-id={`as-store-${selectedAssignStore.id}`}
                      onChange={() =>
                        this.handleCheckboxSelectStore(
                          selectedAssignStore.id,
                          !selectedAssignStore.isSelected
                        )
                      }
                      color="secondary"
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            {this.state.assignStoreError.error && (
              <FormHelperTextContainer>
                {this.state.assignStoreError.errorMessage}
              </FormHelperTextContainer>
            )}
            <Box className="btn-container">
              <Button
                variant="outlined"
                onClick={() => this.handleAssignStoreDialogClose()}
                className={"back-btn"}
                disableElevation
                data-test-id="back-icon-test-id"
              >
                {this.tranInventoryManagement("Back")}
              </Button>
              <Button
                variant="contained"
                className={"next-btn"}
                disableElevation
                onClick={() => this.handleAssignSubmitStore()}
                data-test-id="submit-as-test"
              >
                {this.tranInventoryManagement("Assign Stores")}
              </Button>
            </Box>
          </Box>
        </DialogAssignStoreContainer>
        <ReusableSnackbar
          open={this.state.isAlert}
          message={this.state.message}
          onClose={this.onAlertIsMSnackClose}
          severity={this.state.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  searchBox: {
    border: "1px solid #CBD5E1",
    fontFamily: "Lato, sans-serif",
    background: "#FFFFFF",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "2px",
    padding: "10px, 8px, 10px, 12px",
  },
  allTab: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    padding: 0,
    minWidth: "110px",
  },
  tabs: {
    backgroundColor: "#375280",
    height: 3,
    borderBottom: "1px solid #E2E8F0",
    weight: "20px",
  },
};

export const FormHelperTextContainer = styled(FormHelperText)({
  paddingTop: 15,
  color: "f44336",
});

const UIHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "@media(max-width:900px)": {
    flexDirection: "column",
    gap: 20,
  },
});

const UIHeaderRightDiv = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 20,
  "& .outlined-btn-confirm": {
    textTransform: "capitalize",
    color: "#375280",
    height: "100%",
    borderColor: "#CCBEB1",
    width: 150,
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 400,
    },
  },
  "& .contained-btn-confirm": {
    textTransform: "capitalize",
    gap: "8px",
    height: "100%",
    color: "white",
    width: 150,
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    borderRadius: "2px",
    "& .MuiButton-label": {
      fontSize: "18px",
      fontWeight: 600,
    },
  },
  "@media(max-width:900px)": {
    justifyContent: "space-between",
    width: "100%",
  },
});

export const PopoverContainer = styled(Popover)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  margin: "140px 40px 0px 0px",
  "@media(max-width:900px)": {
    marginTop: 180,
  },
  "@media(max-width:490px)": {
    marginTop: 250,
  },
  "& .MuiPaper-root.MuiPopover-paper": {
    width: 420,
    top: "30px !important",
    "& .filter-header": {
      display: "flex",
      justifyContent: "space-between",
      padding: dir === "ltr" ? "10px 10px 10px 20px" : "10px 20px 10px 10px",
      borderBottom: "1px solid #F1F5F9",
      alignItems: "center",
      "& .filter-header-title": {
        fontFamily: "Avenir,sans-serif",
        fontWeight: 800,
        fontSize: 20,
        color: "#375280",
      },
    },
    "& .filter-body": {
      display: "flex",
      gap: 10,
      padding: dir === "ltr" ? "10px 10px 10px 20px" : "10px 20px 10px 10px",
      flexDirection: "column",
      width: 375,
      "& .filter-container": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 375,
        padding: "9px 0px",
        "& .filter-body-title": {
          fontFamily: "Lato, sans-serif",
          fontWeight: 400,
          fontSize: 18,
          color: "#375280",
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: 380,
        marginTop: 10,
        marginBottom: 10,
        "& .clear-all-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .apply-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "@media(max-width:500px)": {
        width: "78vw",
        "& .filter-container": {
          width: "78vw",
        },
        "& .btn-container": {
          width: "78vw",
        },
      },
      "@media(max-width:480px)": {
        width: "70vw",
        "& .filter-container": {
          width: "70vw",
        },
        "& .btn-container": {
          width: "70vw",
        },
      },
    },
  },
}));

export const CheckboxContainer = styled(Checkbox)({
  color: "#B2A69B",
  width: 20,
  aspectRatio: 1,
  borderRadius: 4,
  borderWidth: 1,
  padding: 0,
});

const SellerInventoryData = styled(Box)({
  width: "100%",
  marginTop: 30,
  "& .MuiGrid-root.MuiGrid-container": {
    "& .MuiGrid-root.MuiGrid-item": {
      "& .inventory-data-container": {
        display: "flex",
        padding: 16,
        gap: 16,
        borderRadius: 2,
        backgroundColor: "#F8F8F8",
        "& .value-container": {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          "& .right-img": {
            width: 20,
            height: 20,
          },
          "& .value-main-container": {
            display: "flex",
            flexDirection: "column",
            gap: 6,
            "& .name-text-title": {
              fontFamily: "Lato, sans-serif",
              color: "#334155",
              fontSize: 16,
              fontWeight: 400,
            },
            "& .value-text-title": {
              fontFamily: "Lato, sans-serif",
              color: "#375280",
              fontSize: 24,
              fontWeight: 700,
            },
          },
        },
      },
    },
  },
});

const TabWrapBox = styled(Box)({
  width: "100%",
  overflowX: "auto",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #e3e8f0",
    width: "max-content",
    marginLeft: 5,
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#375280",
    },
    "& .MuiTab-textColorInherit": {
      color: "#94A3B8",
    },
  },
});

const InventoryBodyContainer = styled(Box)({
  width: "100%",
  marginTop: 20,
});

export const SetPriceFilterListContainer = styled(Box)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  "& .MuiBox-root": {
    border: "1px solid #e3e8f0",
    padding: dir==="ltr"?"16px 0px 16px 25px": "16px 25px 16px 0px",
    "& .list-item-textfield": {
      fontWeight: 400,
      fontFamily: "Lato, sans-serif",
      color: "#375280",
      fontSize: 16,
    },
  },
  "& .set-price-selected.MuiBox-root": {
    backgroundColor: "#F8F8F8",
    "& .list-item-textfield": {
      fontWeight: 700,
    },
  },
}));

export const SPFilterRight = styled(Box)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  margin: dir==="ltr"? "24px 0px 24px 24px" : "24px 24px 24px 0px",
  "& .filter-right-title": {
    fontFamily: "Lato, sans-serif",
    color: "#375280",
    fontWeight: 400,
    fontSize: 16,
    height: 24,
  },
  "& .filter-right-title.selected": {
    fontWeight: 700,
  },
}));

export const DialogAssignStoreContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    "& .cross-icon-container": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .content-container": {
      padding: "0px 30px 30px 30px",
      width: 520,
      "@media(max-width:700px)": {
        width: 320,
      },
      "& .assign-store-title-container": {
        fontFamily: "Lato, sans-serif",
        paddingBottom: 30,
        fontWeight: 500,
        justifyContent: "center",
        fontSize: 30,
        display: "flex",
        alignItems: "center",
        color: "#375280",
      },
      "& .main-container": {
        "& .store-name-display-container": {
          maxHeight: 400,
          overflowY: "auto",
          overflowX: "hidden",
          "& .store-name-container": {
            padding: "24px 0px",
            borderBottom: "1px solid #D9D9D9",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: 2,
            "& .store-name-left": {
              display: "flex",
              gap: 10,
              alignItems: "center",
              "& .img-container": {
                width: 40,
                height: 40,
                borderRadius: "50%",
              },
              "& .store-name": {
                fontFamily: "Lato, sans-serif",
                fontWeight: 500,
                fontSize: 20,
                color: "#375280",
              },
            },
          },
        },
        "& .select-all-product-container": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "23px 0px 7px 0px",
          "& .select-all-title": {
            color: "#375280",
            fontFamily: "Lato, sans-serif",
            fontWeight: 500,
            fontSize: 18,
          },
        },
      },
      "& .btn-container": {
        display: "flex",
        gap: 16,
        height: "54px",
        width: "100%",
        marginTop: 30,
        "& .back-btn": {
          textTransform: "capitalize",
          color: "#375280",
          borderColor: "#CCBEB1",
          width: "50%",
          height: "100%",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 500,
          },
        },
        "& .next-btn": {
          textTransform: "capitalize",
          color: "white",
          gap: "8px",
          backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
          width: "50%",
          height: "100%",
          borderRadius: "2px",
          "& .MuiButton-label": {
            fontSize: "18px",
            fontWeight: 600,
          },
        },
      },
      "@media(max-width:520px)": {
        padding: 10,
        "& .inputHeadingClass": {
          padding: 10,
          paddingTop: 26,
        },
        "& .MuiFormControl-root.finance-status": {
          padding: 10,
        },
      },
    },
  },
});

export const RightFilterBoxContainer = styled(Box)({
  maxHeight: 320,
  overflowY: "auto",
  overflowX: "hidden",
  "& .sub-category-not-found": {
    color: "#375280",
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    marginTop: 24,
  },
});

export const PriceBoxContainer = styled(Box)({
  padding: "30px 15px 15px 15px",
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  gap: 15,
  width: "93%",
  "@media(max-width:480px)": {
    width: "100%",
  },
});

export const PriceBoxContainerInput = styled(InputBase)({
  border: "1px solid #cbc9ca",
  color: "#375280",
  borderRadius: "8px",
  padding: "5px 15px",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  fontFamily: "Lato",
});
// Customizable Area End
