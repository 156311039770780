import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  Typography,
  Box,
  Grid,
  InputAdornment,
  styled,
  TextField,
  Button,
  FormHelperText,
  Dialog,
} from "@material-ui/core";
import MySellerStoreController, {
  configJSON,
  MyStoreList,
  Props,
} from "./MySellerStoreController";
import Footer from "../../../components/src/Footer";
import SellerHeader from "../../../components/src/Seller/SellerHeader";
import {
  addIcon,
  backBtnIcon,
  crossIcon,
  editUploadIcon,
  step3,
  step4,
  uploadIcon,
} from "./assets";
import { MainGrid } from "./CreateSellerStore.web";
import { search } from "../../email-account-registration/src/assets";
import ReusableSnackbar from "../../../components/src/AlertContainer";
import Loader from "../../../components/src/LoaderContainer";
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class SellerStore extends MySellerStoreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderStepper1 = () => (
    <Step1Container>
      <SearchBoxContainer
        variant="outlined"
        placeholder="Search Stores"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={search} className="searchBoxIcon" />
            </InputAdornment>
          ),
        }}
        data-test-id="search-box-test-id"
        value={this.state.searchQuery}
        onChange={this.handleSearch}
      />
      {this.state.isLoader ? <Loader id="my-seller-store-loader" dataTestId="my-seller-store-loader-test" />:<><Grid container className="main-grid-container" spacing={3}>
        <Grid item xs={12} sm={6}>
          <AddDataContainer
            data-test-id="redirect-create-store"
            onClick={() => this.handleRedirection("CreateSellerStore")}
          >
            <img src={addIcon} alt="add-icon" />
            <Typography className="add-title">Add New Store</Typography>
          </AddDataContainer>
        </Grid>
        {this.state.selectedData.map((store: MyStoreList) => (
          <Grid item xs={12} sm={6} key={store.id}>
            <StoreDataContainer>
              <img
                className="img-container"
                src={store.attributes.image}
                alt={store.attributes.store_name}
              />
              <Typography className="store-name-title">
                {store.attributes.store_name}
              </Typography>
              <Box className={`${store.attributes.status}-container`}>
                {store.attributes.status}
              </Box>
            </StoreDataContainer>
          </Grid>
        ))}
      </Grid>
      <ButtonContainer
        data-test-id="next-btn-test-id"
        onClick={() => this.handleNext()}
      >
        Continue
      </ButtonContainer>
      </>}
    </Step1Container>
  );

  renderStepper2 = () => (
    <form data-test-id="formSubmit" onSubmit={this.handleSubmit2}>
      {!this.state.step2.previewId ? (
        <Button variant="contained" component="label" className="upload-btn">
          <img src={uploadIcon} alt="upload" />
          <Typography className="upload-title">Upload your ID proof</Typography>
          <input
            type="file"
            hidden
            data-test-id="upload-id-test"
            onChange={this.handleIdChange}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      ) : (
        <ImagePreviewContainer>
          <img
            src={this.state.step2.previewId}
            alt="Uploaded Profile"
            className="image-preview"
          />
          <Button
            variant="contained"
            component="label"
            className="edit-btn"
          >
            <img src={editUploadIcon} alt="edit" />
            <input
              type="file"
              data-test-id="re-upload-id-test"
              hidden
              onChange={this.handleIdChange}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        </ImagePreviewContainer>
      )}
      <FormHelperTextContainer data-test-id="upload-id-error">
        {this.state.errorsMessageStep2.errorIdUpload
          ? this.state.errorsMessageStep2.errorIdUpload
          : ""}
      </FormHelperTextContainer>

      {!this.state.step2.previewLicense ? (
        <Button variant="contained" component="label" className="upload-btn">
          <img src={uploadIcon} alt="upload" />
          <Typography className="upload-title">Upload your License</Typography>
          <input
            type="file"
            hidden
            data-test-id="upload-license-test"
            onChange={this.handleLicenseChange}
            accept=".png, .jpg, .jpeg"
          />
        </Button>
      ) : (
        <ImagePreviewContainer>
          <img
            src={this.state.step2.previewLicense}
            alt="Uploaded Profile"
            className="image-preview"
          />
          <Button
            variant="contained"
            component="label"
            className="edit-btn"
          >
            <img src={editUploadIcon} alt="edit" />
            <input
              type="file"
              data-test-id="re-upload-license-test"
              hidden
              onChange={this.handleLicenseChange}
              accept=".png, .jpg, .jpeg"
            />
          </Button>
        </ImagePreviewContainer>
      )}
      <FormHelperTextContainer data-test-id="upload-license-error">
        {this.state.errorsMessageStep2.errorLicenseUpload
          ? this.state.errorsMessageStep2.errorLicenseUpload
          : ""}
      </FormHelperTextContainer>
      <ButtonContainer data-test-id="upload-btn-test-id">
        <Button
          variant="contained"
          className={"upload-seller-btn"}
          disableElevation
          type="submit"
        >
          {configJSON.upload}
        </Button>
      </ButtonContainer>
    </form>
  );
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <SellerHeader navigation={this.props.navigation} isSellerForm />
        <MainGrid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
            <Box className="sideImageBox">
              {this.state.activeStep === 0 && (
                <img className="sideImage" src={step3} alt="step-1" />
              )}
              {this.state.activeStep === 1 && (
                <img className="sideImage" src={step4} alt="step-2" />
              )}
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={"formGrid"}
          >
            <Box className="title-container">
              <img
                src={backBtnIcon}
                alt="backIcon"
                data-test-id="backward-test-id"
                onClick={() => this.handleBackward()}
              />
              <Typography className="title">My Stores</Typography>
            </Box>
            <Box className="step-1-container">
              {this.state.activeStep === 0 && this.renderStepper1()}
              {this.state.activeStep === 1 && this.renderStepper2()}
            </Box>
          </Grid>
        </MainGrid>
        <Footer navigation={this.props.navigation} />
        <DialogContainer
          data-test-id="upload-document-success-dialog"
          open={this.state.isPopup}
          onClose={() => this.handleClose()}
        >
          <Box className="cross-icon-container">
            <img
              src={crossIcon}
              onClick={() => this.handleClose()}
              alt="cross-icon"
            />
          </Box>
          <Box className="content-container">
            <Typography className="text-title">Great!</Typography>
            <Typography className="text-description">
              Your Request to register as a Seller has been submitted
              successfully. You will get a confirmation in your email inbox
              soon.{" "}
            </Typography>
            <Box className="btn-container">
              <Button
                variant="outlined"
                className={"back-btn"}
                disableElevation
                data-test-id="log-out-btn-test"
                onClick={() => this.handleLogout("Home")}
              >
                Log Out
              </Button>
              <Button
                variant="contained"
                className={"next-btn"}
                disableElevation
                onClick={() => this.handleRedirection("CreateSellerStore")}
              >
                Create Store
              </Button>
            </Box>
          </Box>
        </DialogContainer>
        <ReusableSnackbar
          onClose={this.onAlert}
          open={this.state.alertError.isAlert}
          message={this.state.alertError.message}
          severity={this.state.alertError.severity}
          dataTestId="alertTestId"
          autoHideDuration={3000}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
// Customizable Area Star
const Step1Container = styled(Box)({
  "& .main-grid-container": {
    paddingTop: 26,
    maxHeight: 720,
    overflow: "auto",
    marginTop: 20,
    "@media(max-width:600px)": {
      padding: "26px 40px 26px 40px",
    },
  },
});

const SearchBoxContainer = styled(TextField)({
  marginTop: 26,
  "& .searchBoxIcon": {
    color:
      "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
  },
  "& .MuiInputBase-input": {
    paddingLeft: 5,
    color: "#94A3B8",
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    "& .MuiInputBase-input:focus": {
      "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
        borderColor: "none !important",
        border: "2px solid #94A3B8 !important",
      },
    },
    "& .MuiInputBase-input:active": {
      "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
        borderColor: "none !important",
        border: "2px solid #94A3B8 !important",
      },
    },
    "& .MuiInputBase-input:hover": {
      "& .MuiOutlinedInput-root.MuiOutlinedInput-notchedOutline": {
        borderColor: "none !important",
        border: "2px solid #94A3B8 !important",
      },
    },
  },
  display: "flex",
  height: "56px",
  justifyContent: "flex-start",
  borderRadius: "2px",
});

const StoreDataContainer = styled(Box)({
  width: "100%",
  display: "flex",
  gap: 14,
  flexDirection: "column",
  "& .img-container": {
    borderRadius: 2,
    width: "100%",
    aspectRatio: 1,
    objectFit: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:600px)": {
      width: 240,
    },
  },
  "& .store-name-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 20,
    color: "#375280",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "@media(max-width:600px)": {
      width: 240,
    },
  },
  "& .Pending-container": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#D97706",
    backgroundColor: "#FEF3C7",
    padding: "6px 16px",
    width: "max-content",
    borderRadius: 5,
  },
  "& .Approved-container": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#059669",
    backgroundColor: "#D1FAE5",
    padding: "6px 16px",
    width: "max-content",
    borderRadius: 5,
  },
  "& .Rejected-container": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 16,
    color: "#df0025",
    backgroundColor: "#e4a6b0",
    padding: "6px 16px",
    width: "max-content",
    borderRadius: 5,
  },
});

const AddDataContainer = styled(Box)({
  borderRadius: 2,
  width: "100%",
  aspectRatio: 1,
  objectFit: "cover",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#F8F8F8",
  gap: 16,
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  "& .add-title": {
    fontFamily: "Lato, sans-serif",
    fontWeight: 400,
    fontSize: 20,
    color: "#375280",
  },
  "@media(max-width:600px)": {
    width: 240,
  },
});

const ButtonContainer = styled(Box)({
  display: "flex",
  height: "54px",
  width: "100%",
  marginTop: "52px",
  textTransform: "capitalize",
  justifyContent: "center",
  alignItems: "center",
  fontFamily: "Lato, sans-serif",
  color: "white",
  backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
  borderRadius: "2px",
  fontSize: 20,
  fontWeight: 800,
  "& .upload-seller-btn": {
    height: "100%",
    backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
    width: "100%",
    fontSize: 20,
    color: "white",
    textTransform: "none",
    fontWeight: 800,
    fontFamily: "Lato, sans-serif",
    borderRadius: "2px",
  },
});

const DialogContainer = styled(Dialog)({
  "& .MuiDialog-paper": {
    "& .cross-icon-container": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .content-container": {
      padding: "10px 50px 50px 50px",

      display: "flex",
      flexDirection: "column",
      gap: 10,
      alignItems: "center",
      justifyContent: "center",
      "& .text-title": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 500,
        fontSize: 30,
        color: "#375280",
        textAlign: "center",
      },
      "& .text-description": {
        fontFamily: "Lato, sans-serif",
        fontWeight: 400,
        fontSize: 18,
        color: "#375280",
        textAlign: "center",
      },
    },
    "& .btn-container": {
      display: "flex",
      gap: 16,
      height: "54px",
      width: "100%",
      marginTop: 20,
      "& .back-btn": {
        textTransform: "capitalize",
        color: "#375280",
        borderColor: "#CCBEB1",
        width: "50%",
        height: "100%",
        "& .MuiButton-label": {
          fontSize: "18px",
          fontWeight: 500,
        },
      },
      "& .next-btn": {
        textTransform: "capitalize",
        color: "white",
        gap: "8px",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1)",
        width: "50%",
        height: "100%",
        borderRadius: "2px",
        "& .MuiButton-label": {
          fontSize: "18px",
          fontWeight: 600,
        },
      },
    },
  },
});

const FormHelperTextContainer = styled(FormHelperText)({
  color: "#f44336",
});

const ImagePreviewContainer = styled(FormHelperText)({
  position: "relative",
  marginTop: "16px",
  "& .image-preview": {
    width: "100%",
    height: 240,
    objectFit: "cover",
  },
  "& .edit-btn": {
    position: "absolute",
    top: "6px",
    right: "0px",
    background: "transparent",
    boxShadow: "none",
  },
});
// Customizable Area End
