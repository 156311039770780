import React from "react";
import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import i18n from "./i18next/i18n";

interface ConnectorProps {
  direction: 'ltr' | 'rtl';
}
const ColorlibConnector = withStyles(() => ({
  alternativeLabel: {
    top: 17,
    left: (props: ConnectorProps) => (props.direction === 'ltr' ? "calc(-50% + 6px)" : "calc(50% + 6px)"),
    right: (props: ConnectorProps) => (props.direction === 'ltr' ? "calc(50% + 6px)" : "calc(-50% + 6px)"),
  },
  active: {
    "& $line": {
      backgroundColor: "#375280",
      height: 2,
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#375280",
      height: 2,
    },
  },
  line: {
    height: 2,
    border: 0,
    backgroundColor: "#E2E8F0",
    borderRadius: 1,
    width: "100%",
    marginLeft: -10
  },
}))(StepConnector);

interface ColorlibStepIconProps {
  active?: boolean;
  completed?: boolean;
  icon?: React.ReactNode;
}

const useColorlibStepIconStyles = makeStyles(() => ({
  root: {
    color: "#375280",
    width: 32,
    height: 32,
    display: "flex",
    zIndex: 1,
    alignItems: "center",
    borderRadius: "50%",
    justifyContent: "center",
    borderColor: "#375280",
    border: "1px solid",
    backgroundColor: "#fff",
  },
  active: {
    border: "2px solid",
  },
  completed: {
    backgroundColor: "#375280",
    color: "#fff",
  },
}));

interface ICustomStepper {
  activeStep: number;
  steps: string[];
  stepCount: number;
  dir?: "ltr" | "rtl";
}

export default function CustomizedSteppers({
  activeStep,
  steps,
  stepCount,
  dir
}: ICustomStepper) {
  const ColorlibStepIcon = (props: ColorlibStepIconProps) => {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    const steps = Array.from({ length: stepCount }, (_, i) => `Step ${i + 1}`);
    const icons = steps.reduce((acc, _, index) => {
      acc[String(index + 1)] = <span>{index + 1}</span>;
      return acc;
    }, {} as { [key: string]: React.ReactNode });

    return (
      <div
      
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    );
  };

  return (
    <CustomStepper
    dir={dir || 'ltr'}
      alternativeLabel
      activeStep={activeStep}
      connector={<ColorlibConnector  direction={dir || 'ltr'}/>}
    >
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon} icon={index + 1}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </CustomStepper>
  );
}
const CustomStepper = styled(Stepper)(({ dir }: { dir: "rtl" | "ltr" })=> ({
  margin: "0px -19% 0px -18%",
  "@media(max-width:520px)": {
    margin: "0px -18% 0px -16%",
  },
  "@media(max-width:420px)": {
    margin: "0px -16% 0px -14%",
  },
  backgroundColor: "transparent",
  "& .MuiStep-root": {
    "& .MuiStepLabel-root": {
      alignItems:'right',
      textAlign: dir === "rtl" ? "right" : "left" ,
      "& .MuiStepLabel-labelContainer": {
        "& .MuiTypography-root.MuiStepLabel-label": {
          color: "#375280",
          fontSize: 16,
          fontWeight: 400,
        },
        "& .MuiStepLabel-label.MuiStepLabel-active, .MuiStepLabel-label.MuiStepLabel-completed":
          {
            fontWeight: 700,
          },
      },
    },
  },
}));
