import React from "react";
import { appleProvider, handleSignIn } from "../firebaseConfig";
import { Button, styled } from "@material-ui/core";

type stringNull = string | null

export interface IAppleLoginResponse{
  accessToken: string,
  email: stringNull,
  displayName: stringNull
}

interface IAppleButtonProps {
onSuccess: (token: IAppleLoginResponse)=> void,
children: React.ReactNode 
}
const AppleLoginButton: React.FC<IAppleButtonProps> = ({onSuccess, children}) => {
  return (
    <SocialMediaBtn variant="text" onClick={() =>  handleSignIn(appleProvider, "Apple", onSuccess)}>
      {children}
    </SocialMediaBtn>
  );
};

export default AppleLoginButton;

const SocialMediaBtn = styled(Button)({
  padding: 0,
  minWidth: "max-content"
})