type GeolocationCoordinates = {
    latitude: number;
    longitude: number;
  };
  
  type GeolocationCallback = (coordinates: GeolocationCoordinates) => void;
  type GeolocationErrorCallback = (message: string) => void;
  
  export const getGeolocationCoordinates = (
    onSuccess: GeolocationCallback,
    onError: GeolocationErrorCallback
  ) => {
    if (!navigator.permissions || !navigator.geolocation) {
      onError("Geolocation is not supported by your browser.");
      return;
    }
  
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coordinates: GeolocationCoordinates = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            onSuccess(coordinates);
          },
          (error) => {
            onError(handleGeolocationError(error));
          }
        );
      } else {
        onError("Geolocation permission is denied.");
      }
    });
  };
  
  const handleGeolocationError = (error: GeolocationPositionError): string => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        return "User denied the request for Geolocation.";
      case error.POSITION_UNAVAILABLE:
        return "Location information is unavailable.";
      case error.TIMEOUT:
        return "The request to get user location timed out.";
      default:
        return "An unknown error occurred.";
    }
  };
  