Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.readEndPoint = "/bx_block_notifications/notifications/"
exports.getNotificationEndPoint = "bx_block_notifications/notifications"
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.deleteMessage = "Notifications deleted!"
exports.titleMessage="Notifications";
exports.HomeMsg="Home";
exports.ProfileMsg="Profile";
// Customizable Area End