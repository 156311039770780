import React from "react";
// Customizable Area Start
import {
    Box, TableContainer, Table, TableBody, TableRow, Select, MenuItem, TableCell, Snackbar, TableHead, Typography, Button, Modal, Backdrop, Fade, withStyles, FormControl, CircularProgress
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import { styled } from "@material-ui/core/styles";
import SellersNewOrderController, {
    Props,
} from "../SellersNewDetails/SellersNewOrderController.web";
import { group_image, add_circle, button_close, Noimageavailablepng } from "../assets";
import Alert from '@material-ui/lab/Alert';
import i18n from "../../../../components/src/i18next/i18n";
import { NotFoundContainer, PaginationContainer } from "../SellersAllOrder.web";
// Customizable Area End

export default class SellersAllOrderWebPage extends SellersNewOrderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        event.preventDefault()
        this.setState({ page: newPage, isLoading1: true });
        this.allOrderData()
    };

    getPriceVal = (currency: string,  price: string) => {
        return  `${currency} ${price}`
    }
    renderContent = (startIndex: number, endIndex: number) => {
        if (this.state.isLoading) {
            return (
                <Box data-testid="loader" style={{ ...webStyle.tabsBox, textAlign: 'center', textAlignLast: 'center' }}>
                    <CircularProgress style={{ color: '#375280' }} />
                </Box>
            );
        }
        if (this.state.tableAllOrder === undefined && this.state.isAlert) {
            return (
                <NotFoundContainer>
                    {this.tranSellerNewOrder("There are no orders")}
                </NotFoundContainer>
            );
        }

        return (
            <>
                <CustomStyledTableNewOrder className="container">
                    <Table stickyHeader size="small" className="custom-table">
                        <TableHeadNewOrder>
                            <TableRow style={{ backgroundColor: '#f0f0f0' }}>
                                {this.state.tableHaderName.map((header: string, index: number) => (
                                    <TableCell key={index} style={webStyle.tableHader}>
                                        <Typography variant="h6" className="tableHeaderText">{this.tranSellerNewOrder(header)}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHeadNewOrder>
                        <TableBody>
                            {this.state.isLoading1 ?
                                <TableRow data-testid="loader">
                                    <TableCell colSpan={this.state.tableHaderName.length} align="center">
                                        <CircularProgress style={{ color: '#375280' }} />
                                    </TableCell>
                                </TableRow> : this.state.tableAllOrder?.map((orderData) => (
                                <TableRow key={orderData.id} className="hover" >
                                    <TableCell data-testid="order_number" style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowTypo}}>#{orderData.attributes.order_management_order.attributes.order_number}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowTypo}}>{this.dateFormetTime(orderData.attributes.order_items[0]?.attributes.placed_at)}</Typography></TableCell>
                                    <TableCell>{orderData.attributes.order_items.map((value) => {
                                        return (
                                            <Box style={{ display: 'flex', gap: 10 }} data-testid={`navigateTo=${orderData.attributes.order_management_order.attributes.order_number}`} onClick={() => this.navigateTo({ productId: orderData.attributes.order_management_order.attributes.order_number, props: this.props, screenName: "SellersOrderDetailsWebPage" })}>
                                                <Box><img src={value.attributes.catalogue_variant_front_image === "" ? Noimageavailablepng : value.attributes.catalogue_variant_front_image} style={{ width: '34px', height: '34px', borderRadius: '2px', marginBottom: "12px"}} /></Box>
                                                <Box style={{ ...webStyle.tableHaderrow }}>{value.attributes.catalogue_name} x {value.attributes.quantity}</Box>
                                            </Box>
                                        );
                                    })}</TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowTypo}}>{orderData.attributes.order_items[0]?.attributes.store_name}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Typography style={{...webStyle.tableHaderrowTypo}}>{this.getPriceVal(orderData.attributes.order_items[0].attributes.currency_logo, orderData.attributes.order_items[0]?.attributes.total_price)}</Typography></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}><Box style={{ ...webStyle.new_orderBox, textAlign: 'center' }}>{this.tranSellerNewOrder("New Order")}</Box></TableCell>
                                    <TableCell style={{ ...webStyle.tableHaderrow, verticalAlign: 'top' }}>
                                        <Box style={{display: "flex", gap: 8}}><Button style={{ ...webStyle.rejectButton, textTransform: "capitalize" }} data-testid={`handleOpen1Reject=${orderData.attributes.id}`} onClick={() => this.handleOpen1(orderData.attributes.id)}>{this.tranSellerNewOrder("Reject")}</Button>
                                        <Button style={{ ...webStyle.acceptOrder, textTransform: "capitalize" }} onClick={() => this.handleOk(orderData.attributes.id)} data-testid="handleOkAcceptOrder">{this.tranSellerNewOrder("Accept Order")} ({this.formatTime(this.state.timer)})</Button></Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </CustomStyledTableNewOrder>
                <PaginationContainer>
                    <Box style={{ fontSize: "14px", fontWeight: 500, color: "#475569", fontFamily: 'Lato' }}>
                        {startIndex} - {endIndex} {this.tranSellerNewOrder("of")} {this.state.metaData?.total_record} {this.tranSellerNewOrder("results")}
                    </Box>
                    <CustomStyledPaginationNewOrders
                        count={this.state.metaData?.total_pages}
                        color="primary"
                        onChange={this.handlePageChange}
                        shape="rounded"
                        page={this.state.page}
                        dir={i18n.dir()}
                    />
                </PaginationContainer>
            </>
        );
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const ROWS_PER_PAGE = 10;
        const startIndex = (this.state.metaData?.current_page - 1) * ROWS_PER_PAGE + 1;
        const endIndex = Math.min(this.state.metaData?.current_page * ROWS_PER_PAGE, this.state.metaData?.total_record);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Box data-testid="allOrder" style={{ width: '-webkit-fill-available' }} dir={i18n.dir()}>
                    {this.renderContent(startIndex, endIndex)}
                </Box>
                <StyledDrawerOrder
                    aria-describedby="transition-modal-description"
                    aria-labelledby="transition-modal-title"
                    onClose={this.handleClose1}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    open={this.state.rejectOpen}
                    BackdropComponent={Backdrop}
                    closeAfterTransition
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.rejectOpen} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ width: '100%', display: 'flex', justifyContent: 'end' }}><img src={button_close} onClick={this.handleClose1} /></Box>
                            <h2 id="transition-modal-title" style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}>{this.tranSellerNewOrder("Reject Order")}</h2>
                            <h2 id="transition-modal-title" style={{ ...webStyle.confomMasegNewOrder, textAlign: "center" }}>{this.tranSellerNewOrder("Are you sure you want to reject the order?")}</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.tranSellerNewOrder("Reason of Rejection")}</Box>
                                <CustomSelectNewOrder variant="outlined" style={{ width: '500px', backgroundColor: '#ffffff' }}>
                                    <MuiSelectSelectNewOrder
                                        value={this.state.numberData}
                                        inputProps={{ 'aria-label': 'Select Option', "data-testid": "select-id", }}
                                        MenuProps={{
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            getContentAnchorEl: null,
                                        }}
                                        style={webStyle.reminderName}
                                        displayEmpty
                                        onChange={this.handleSingleChangeNumber}
                                        data-testid='sub-categories-selection'
                                    >
                                        <MenuItem value="" disabled>
                                            <Box style={{ ...webStyle.newShort, display: 'flex' }}>{this.tranSellerNewOrder("Out of Stock")}</Box>
                                        </MenuItem>
                                        {this.state.short.map((select: string, index: number) => (
                                            <MenuItem key={index} data-testid="sub-cat-list" style={webStyle.newShort} value={select}>{this.tranSellerNewOrder(select)}</MenuItem>
                                        ))}
                                    </MuiSelectSelectNewOrder>
                                </CustomSelectNewOrder >
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleClose1" onClick={this.handleClose1}>{this.tranSellerNewOrder("Close")}</StyledButtonModelNewOrder>
                                <StyledButton1ModelNewOrder style={{ fontSize: "14px" }} data-testid="rejectData" onClick={this.rejectData}>{this.tranSellerNewOrder("Reject")}</StyledButton1ModelNewOrder>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>
                <StyledDrawerOrder
                    closeAfterTransition
                    aria-describedby="transition-modal-description"
                    onClose={this.handleCancel}
                    aria-labelledby="transition-modal-title"
                    open={this.state.visible}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    dir={i18n.dir()}
                    BackdropComponent={Backdrop}
                >
                    <Fade in={this.state.visible} style={webStyle.febBox}>
                        <div style={{ padding: "10px 30px 30px 30px" }}>
                            <Box style={{ display: 'flex', width: '100%', justifyContent: 'end' }}><img onClick={this.handleCancel} src={button_close} /></Box>
                            <h2 style={{ ...webStyle.rejectNameNewOrder, textAlign: "center" }}>{this.tranSellerNewOrder("Accept Order")}</h2>
                            <h2 style={{ textAlign: "center", ...webStyle.confomMasegNewOrder }}>{this.tranSellerNewOrder("Are you sure you want to accept the order?")}</h2>
                            <Box style={{ marginBottom: '30px' }}>
                                <Box style={webStyle.modelText}>{this.tranSellerNewOrder("Set order reupload time")}</Box>
                                <Box style={{ backgroundColor: "#F8F8F8", padding: "10px", borderRadius: "2px" }}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button onClick={this.decrementTime} disabled={this.state.reloadTime <= 1} data-testid="incrementTime"><img width="24px" height="24px" src={group_image} /></Button>
                                        <Box style={webStyle.timeBox}>
                                            <Typography variant="body1">{this.state.reloadTime}</Typography>
                                            <Typography variant="body1">&nbsp;{this.tranSellerNewOrder("mins")}</Typography>
                                        </Box>
                                        <Button onClick={this.incrementTime} data-testid="decrementTime" disabled={this.state.reloadTime >= 120}><img width="24px" height="24px" src={add_circle} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButtonModelNewOrder style={{ fontSize: "14px" }} data-testid="handleCancel" onClick={this.handleCancel}>{this.tranSellerNewOrder("Close")}</StyledButtonModelNewOrder>
                                <StyledButton1ModelNewOrder style={{ fontSize: "14px" }} data-testid="timeData" onClick={this.timeData}>{this.tranSellerNewOrder("Confirm")}</StyledButton1ModelNewOrder>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawerOrder>
                <Snackbar
                    open={this.state.isAlertOrder}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    autoHideDuration={3000}
                    style={{ marginTop: '10px' }}
                    data-testid="oncloseAlert"
                    onClose={this.oncloseAlert}
                ><Alert variant="filled" severity="success">{this.state.already}</Alert></Snackbar>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    new_orderBox: { backgroundColor: '#D1FAE5', color: '#059669', padding: '6px 10px 6px 10px', borderRadius: '2px', fontFamily: 'Lato', fontSize: '12px', fontWeight: 500, lineHeight: '18px' },
    modelText: { color: "#375280", fontSize: "18px", fontFamily: "Lato", fontWeight: 700, paddingBottom: '4px' },
    acceptOrder: { backgroundColor: "#CCBEB1", borderRadius: "2px", color: "#FFFFFF", fontSize: "12px", fontFamily: 'Lato', fontWeight: 700 },
    rejectButton: { color: "#375280", fontFamily: 'Lato', fontWeight: 500, fontSize: "12px", border: "1px solid #CCBEB1", borderRadius: '0px', padding: "10px, 16px, 10px, 16px" },
    timeBox: { display: 'flex', alignItems: 'center', color: "#375280", fontFamily: "Lato", fontSize: "18px", fontWeight: 400 },
    tabeLable: {
        color: "rgb(55, 82, 128)", fontSize: '20px', fontWeight: 800, fontFamily: 'Lato', height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
        top: "50%",
        left: "50%",
        transform: "translate(20%, 20%)"
    },
    tabsBox: { width: '100%', height: '75%', paddingBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    confomMasegNewOrder: { fontSize: '18px', color: "#375280", fontWeight: 400, fontFamily: "Lato", marginBottom: '30px' },
    febBox: { borderRadius: '10px', width: "500px", backgroundColor: "white" },
    rejectNameNewOrder: { fontSize: '30px', color: "#375280", fontWeight: 500, fontFamily: "Lato", margin: '20px 0px' },
    tableHader: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 700, WebkitTextStrokeWidth: "0.5px", letterSpacing: "1px"
    },
    reminderName: { backgroundColor: '#F8F8F8', height: '56px' },
    tableHaderrow: {
        color: "#375280", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    tableHaderrowTypo: {
        color: "#375280", display: "flex", fontSize: '16px', fontFamily: "Lato", fontWeight: 400
    },
    newShort: { color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato" },
}
const CustomStyledPaginationNewOrders = styled(Pagination)(({ dir }: { dir: "rtl" | "ltr" }) => ({
    "& li:first-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
        backgroundColor: "#375280"
    },
    '& .MuiPaginationItem-root': {
        color: '#375280',
    },
    "& li:last-of-type .MuiPaginationItem-icon": {
        transform: dir === "ltr" ? "rotate(0deg)" : "rotate(180deg)",
    },
    '& .Mui-selected': {
        color: 'white',
    },
}));

const StyledButtonModelNewOrder = styled(Button)(() => ({
    textTransform: "none",
    fontWeight: 500,
    borderRadius: "2px",
    border: "1px solid #CCBEB1",
    height: "45px",
    width: "252px !important",
    color: "#375280",
    '&:hover': {
        backgroundColor: "white",
    },
}))
const StyledButton1ModelNewOrder = styled(Button)(() => ({
    fontWeight: 500,
    backgroundColor: '#CCBEB1',
    width: "252px",
    textTransform: "none",
    height: "45px",
    color: "#fff",
    '&:hover': {
        backgroundColor: "#CCBEB1"
    },
}))
const CustomStyledTableNewOrder = styled(TableContainer)({
    '& .custom-table': {
        border: '1px solid #E2E8F0',
        borderRadius: "5px 5px 0px 0px"
    },

    '& .cell': {
        backgroundColor: '#ffffff',
        borderRadius: "5px 5px 0px 0px"
    },
    '& .MuiTableCell-sizeSmall': {
        padding: "15px 30px 15px 30px"
    }
})

const CustomSelectNewOrder = withStyles(theme => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ffffff',
            },
            '&:hover fieldset': {
                borderColor: '#ffffff',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "transparent"
            }
        },
    },
}))(FormControl);
const MuiSelectSelectNewOrder = styled(Select)({
    "&.MuiOutlinedInput-root": {
        borderRadius: '2px', color: '#375280', fontSize: '14px', fontWeight: 500, fontFamily: "Lato"
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
        borderColor: "#e9eef4",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#e9eef4',

    },
})
const StyledDrawerOrder = styled(Modal)((theme) => ({
    alignItems: 'center',
    display: 'flex',
    backgroundColor: "none",
    justifyContent: 'center',
}));
const TableHeadNewOrder = styled(TableHead)({
    "& .tableHeaderText":{
        fontFamily: "Lato"
    },
    '& .MuiTableCell-stickyHeader': {
        backgroundColor: 'white !important'
    }, "& .MuiTypography-root": {
        display: "flex"
    }
})
// Customizable Area End