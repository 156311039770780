import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Drawer, 
  IconButton, 
  makeStyles,
  Theme, 
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import {
  Close as CloseIcon,
  AccountCircle as AccountCircleIcon,
  ShoppingCart as ShoppingCartIcon,
  Favorite as FavoriteIcon,
  Payment as PaymentIcon,
  CardGiftcard as LoyaltyIcon,
  CreditCard as CreditCardIcon,
  Notifications as NotificationsIcon,
  Star as StarIcon,
  Home as HomeIcon,
  Language as LanguageIcon,
  Delete as DeleteIcon,
  ExitToApp as ExitToAppIcon
} from '@material-ui/icons';

// Define interface for style props
interface StyleProps {
  width: number;
  showCloseButton: boolean;
}

// Define interface for component props
interface BuyerDrawerProps {
  open: boolean;
  onClose: () => void;
  anchor?: 'left' | 'right' | 'top' | 'bottom';
  width?: number;
  showCloseButton?: boolean;
  className?: string;
  'data-testid'?: string;
  navigation:any;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  drawerPaper: {
    width: props => props.width || 400,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    zIndex: theme.zIndex.drawer + 1,
    '&:hover': {
      color: theme.palette.grey[800],
    },
  },
  content: {
    marginTop: props => props.showCloseButton ? theme.spacing(5) : 0,
    height: '100%',
    overflow: 'auto',
  }
}));


const BuyerDrawer: React.FC<BuyerDrawerProps> = ({ 
  open, 
  onClose, 
  anchor = 'right',
  width = 250,
  showCloseButton = true,
  className,
  'data-testid': dataTestId = 'buyer-drawer',
  navigation,children
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ width, showCloseButton });

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper
      }}
      className={className}
      data-testid={dataTestId}
    >
      {showCloseButton && (
        <IconButton
          onClick={onClose}
          className={classes.closeButton}
          data-testid={`${dataTestId}-close-button`}
          aria-label={t('common.close')}
          title={t('common.close')}
        >
          <CloseIcon />
        </IconButton>
      )}
      
      <div className={classes.content} data-testid={`${dataTestId}-content`}>
        {children}
      </div>
    </Drawer>
  );
};

export default BuyerDrawer;
