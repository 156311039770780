// Customizable Area Start
import React from "react";
import LocationSelector from "../../../../components/src/Stylish/HeaderMap";
import ReusableSnackbar from "../../../../components/src/AlertContainer";
// Customizable Area End

import LocationPickerController, {
  Props,
  configJSON,
} from "./LocationPickerController.web";

export default class LocationPicker extends LocationPickerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {this.state.isVisible && <LocationSelector id={configJSON.deliverTotitle} navigation={this.props.navigation} addressData={this.state.addressData}/>}
      <ReusableSnackbar
        open={this.state.isError}
        message={this.state.errorMessage}
        onClose={this.closeSnackbar}
        severity={"error"}
        dataTestId="alertPickerLocationTestId"
        autoHideDuration={3000}
      />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
