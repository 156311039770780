export const enSidebar = {
    "My Orders": "My Orders",
    "Wishlist": "Wishlist",
    "My Profile": "My Profile",
    "Payment History": "Payment History",
    "Saved Cards": "Saved Cards",
    "Stylist": "Stylist",
    "Address": "Address",
    "Delete Account":"Delete Account",
    "Language and Currency": "Language and Currency",
    "Logout": "Logout",
    "Profile": "Profile",
    "Home": "Home",
    "Welcome to your Account": "Welcome to your Account",
    "Dashboard": "Dashboard",
    "Catalogue": "Catalogue",
    "Orders": "Orders",
    "Stores": "Stores",
    "Analytics and Insights": "Analytics and Insights",
    "Earnings": "Earnings",
    "Your Activity": "Your Activity",
    "Get Help": "Get Help",
    "Coming Soon !!!": "Coming Soon !!!",
    "Log Out": "Log Out",
    "Cancel": "Cancel",
    "You need to sign in to access Profile": "You need to sign in to access Profile",
    "Are you sure you want to log out your account?": "Are you sure you want to log out your account?",
    "Sign In": "Sign In",
    "Clients":	"Clients",
    "Sales Report":	"Sales Report",
    "Inventory Management":	"Inventory Management",
    "Portfolio":	"Portfolio",
    "Explore as Buyer": "Explore as Buyer",
    "Stylist Services": "Stylist Services",
    "Exclusive New Trending Collection": "Exclusive New Trending Collection",
    "New In": "New In",
    "15% Off": "15% Off",
    "CLICK ME": "CLICK ME",
    "item added to wishlist": "item added to wishlist",
    "The item has been removed from wishlist": "The item has been removed from wishlist",
    "NEW - SEASON STYLE": "NEW - SEASON STYLE",
    "Discover the key and styling tricks to elevate your look now": "Discover the key and styling tricks to elevate your look now",
    "Recommendations For You": "Recommendations For You",
    "Explore Our Stylists": "Explore Our Stylists",
    "Explore Our Trending Collection": "Explore Our Trending Collection",
    "Loyalty Points": "Loyalty Points",
    "Notification": "Notification",
    "Delete Account Successfully":"Delete Account Successfully.",
    "Delete":"Delete",
    "DeleteAccountMag":"Are you sure you want to delete your account?",
    "Favourite Stylist": "Favorite Stylist",
    "TopTitle": "Shop now and enjoy stylish fashion delivered straight to your doorstep at no extra cost.",

}

export const arSidebar = {
    "DeleteAccountMag":"هل أنت متأكد أنك تريد حذف حسابك؟",
    "Delete":"يمسح",
    "Delete Account Successfully":"تم حذف الحساب بنجاح.",
    "My orders": "أوامري",
    "Wishlist": "قائمة الرغبات",
    "My Profile": "ملفي الشخصي",
    "Payment History": "تاريخ الدفع",
    "Save Cards": "حفظ البطاقات",
    "Stylist": "المصممون",
    "Address": "عنوان",
    "Delete Account": "حذف الحساب",
    "Language and Currency": "اللغة والعملة",
    "Logout": "تسجيل الخروج",
    "Profile": "حساب تعريفي",
    "Home": "الصفحة الرئيسية",
    "Welcome to your Account": "مرحبًا بك في حسابك",
    "Dashboard": "لوحة التحكم",
    "Catalogue": "قائمة المعروضات ",
    "Orders": "الطلبات",
    "Stores": "المتاجر",
    "Analytics and Insights": "التحليلات و المعطيات",
    "Earnings": "الدخل",
    "Your Activity": "نشاطك",
    "Get Help": "احصل على المساعدة",
    "Coming Soon !!!": "قريباً !!!",
    "Log Out": "تسجيل الخروج",
    "Cancel": "يلغي",
    "You need to sign in to access Profile": "تحتاج إلى تسجيل الدخول للوصول إلى الملف الشخصي",
    "Are you sure you want to log out your account?": "هل أنت متأكد أنك تريد تسجيل الخروج من حسابك؟",
    "Sign In": "تسجيل الدخول",
    "Clients":	"العملاء",
    "Sales Report":	"تقرير المبيعات",
    "Inventory Management":	"إدارة المخزون",
    "Portfolio": "مَلَفّ",
    "Explore as Buyer": "الاستكشاف كمشتري",
    "Stylist Services": "خدمات المصممين",
    "Exclusive New Trending Collection": "مجموعة جديدة حصرية رائجة",
    "New In": "وصل حديثا",
    "15% Off": "خصم 15%",
    "CLICK ME": "انقر فوقي",
    "item added to wishlist": "تمت إضافة العنصر إلى قائمة الرغبات",
    "The item has been removed from wishlist": "تمت إزالة هذا العنصر من قائمة الرغبات",
    "NEW - SEASON STYLE": "جديد - نمط الموسم",
    "Discover the key and styling tricks to elevate your look now": "اكتشف الحيل الأساسية والأناقة لرفع مستوى مظهرك الآن",
    "Recommendations For You": "توصيات لك",
    "Explore Our Stylists": "استكشف مصممينا",
    "Explore Our Trending Collection": "استكشف مجموعتنا الرائجة",
    "Saved Cards": "حفظ البطاقات",
    "Loyalty Points": "نقاط الولاء",
    "Notification": "الإخطار",
    "Favourite Stylist": "المصممون",
    "TopTitle": "تسوق الآن واستمتع بأنماط الأزياء الأنيقة التي ستسلم إلى عتبة بابك دون تكاليف إضافية.",
}

