import React from "react";
// Customizable Area Start
import { Box, styled, Grid, TextField, Snackbar, Modal, Typography, Button, Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { createTheme, ThemeProvider,withStyles } from "@material-ui/core/styles";
import {  arrow,  cross, upload } from "./assets";
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import Alert from '@material-ui/lab/Alert';
import Switch from "@material-ui/core/Switch";
import { Carousel } from 'react-responsive-carousel';

import EditCatalougeWeb from "./EditCatalougeWeb.web";
// Customizable Area End

// Customizable Area Start
import CatalogueController, { Props } from "./AddProductCatalougeController";
import i18n from "../../../components/src/i18next/i18n";
import { UploadBtnBox } from "./CatalogueWeb.web";
import SellerStylishContainer from "../../../components/src/SellerStylish/SellerStylishContainer";
// Customizable Area End


export default class ProductDetail extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleViewAsBuyerClick = () => {
    this.setState({ viewAsBuyer: !this.state.viewAsBuyer });
  };
  toggleColorDropdown = () => {
    this.setState((prevState) => ({
      isColorDropdownOpen: !prevState.isColorDropdownOpen,
    }));
  };
  
  // Toggle dropdown visibility for size
  toggleSizeDropdown = () => {
    this.setState((prevState) => ({
      isSizeDropdownOpen: !prevState.isSizeDropdownOpen,
    }));
  };
  
  // Handle color selection
  handleColorSelect = (color: string,index:number) => {
    this.setState({
      selectedColor: color,
      selectedColorId:index,
      isColorDropdownOpen: false, // Close color dropdown after selection
    });
  };
  
  // Handle size selection
  handleSizeSelect = (size: string) => {
    this.setState({
      selectedSize: size,
      isSizeDropdownOpen: false, // Close size dropdown after selection
    });
  };

  handleImgRotateCSS = () =>{
    return i18n.dir() === "ltr" ? "rotate(0deg)" : "rotate(180deg)"
  }

  productDetailRole = () => {
    const { roleData } = this.state;
    switch (roleData) {
      case "seller":
        return "Seller";
      case "stylist":
        return "Stylish";
      default:
        return "";
    }
  };

  renderNotOpenMainForm = () => {
    const { selectedColor, selectedSize } = this.state;

    const images = [
      this.state.catalogueDetail?.attributes?.catalogue_variants[this.state.selectedColorId]?.attributes?.front_image,
      this.state.catalogueDetail?.attributes?.catalogue_variants[this.state.selectedColorId]?.attributes?.back_image,
      this.state.catalogueDetail?.attributes?.catalogue_variants[this.state.selectedColorId]?.attributes?.side_image,
    ].filter(image => image);
    const price = [
      this.state.catalogueDetail?.attributes?.catalogue_variants[this.state.selectedColorId]?.attributes?.price,
     ].filter(image => image);
  
    return <>{!this.state.openMainForm && (
      <>
        <div style={webStyle.headerDiv}>
        <div style={{display: "flex", justifyContent: "space-between", gap: 20, width: "min-content"}}><img src={arrow} alt="arrow"  style={{cursor:"pointer", transform: this.handleImgRotateCSS()}} data-test-id="getNavigationMessageCatalogue" onClick={() => this.getNavigationMessageCatalogue()} />
          {!this.state.openMainForm && (
            <h1 style={webStyle.titleNotification}>{this.transAddProductCatalogue("Product Details")}</h1>
          )}</div>
          {this.state.arrayHolder && this.state.arrayHolder.length > 0 && (
            <CustomBoxSearch>
              <button onClick={() => this.handleViewAnalytics()} className="btnSetting1" data-test-id="ViewProduct">
              {this.transAddProductCatalogue("View Analytics")}
              </button>
              &nbsp;
              <button
                className="btnsetting"
                data-test-id="AddProduct"
                onClick={() => {this.setState({ openMainForm:true,openModel:false ,editProductDetail :true})
                localStorage.setItem("editProduct","true")
              }}
              >
                {this.transAddProductCatalogue("Edit Details")}
              </button>
              &nbsp;
            </CustomBoxSearch>
          )}
        </div>
    
   
    <div style={webStyle.contentDivConteiner} id="scrollableDiv">
    <div>
      <div>
        <CustomBoxContent>
          <Grid container spacing={3}>
            <Grid item>
              <div style={{ position: "relative" }}>
                <img
                  src={images.length > 0 ? images[this.state.selectedItem] : this.state.catalogueDetail?.attributes?.primary_image || ""}
                  alt="No image found"
                  style={webStyle.zaraImg as React.CSSProperties}
                />
                <div style={webStyle.dotsContainer as React.CSSProperties}>
                  {images.map((_, index) => (
                    <span
                      key={index}
                      style={{
                        ...webStyle.dot,
                        ...(this.state.selectedItem === index && webStyle.highlightedDot),
                      }}
                    />
                  ))}
                </div>
              </div>
              {images.length > 1 && (
                <div style={webStyle.elementBlock}>
                  <div
                    style={{...webStyle.leftIcon, transform: this.handleImgRotateCSS()}}
                    data-test-id="handleClick"
                    onClick={this.handleClick}
                  >
                    <ChevronLeftIcon style={{ color: "white" }} />
                  </div>
                  <div style={webStyle.imgBlock} ref={this.elementRef}>
                    {images.map((image, index) => (
                      <img
                        key={index}
                        src={image || ""}
                        alt={`Image ${index}`}
                        style={{
                          width: "44px",
                          height: "44px",
                          border: this.state.selectedItem === index ? "3px solid #CCBEB1" : "none",
                          borderRadius: "3px"
                        }}
                      />
                    ))}
                  </div>
                  <div
                    style={{...webStyle.next, transform: this.handleImgRotateCSS()}}
                    data-test-id="handleNextClick"
                    onClick={this.handleNextClick}
                  >
                    <NavigateNextIcon style={{ color: "white" }} />
                  </div>
                </div>
              )}
            </Grid>
  
            <Grid item xs={12} sm={8} md={9}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <h1 style={webStyle.h1First}>
                        {this.state.catalogueDetail?.attributes?.name || this.transAddProductCatalogue("Product Name")}
                      </h1>
                    </Grid>
                    <Grid item>
                      <h1 style={webStyle.h1Last}>
                        {this.state.currencySign} {price[0]
                          ? parseFloat(price[0]).toFixed(2)
                          : "0.00"}
                      </h1>
                    </Grid>
                  </Grid>
                </Grid>
  
              
                <Grid item>
    <div style={{ display: "flex", alignItems: "center", marginTop: "50px", gap: "25px" }}>
      
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "25px",
          borderBottom: "1px solid #E2E8F0",
          borderTop: "1px solid #E2E8F0",
          padding: "12px 0px 12px 0px",
          position: "relative",
        }}
      >
        <h1 style={webStyle.h1Sec2}>
          {this.transAddProductCatalogue(selectedColor) || this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variant_color?.name || this.transAddProductCatalogue("Color")}
        </h1>
  
        <KeyboardArrowDownRoundedIcon
          style={{ color: "#375280", cursor: "pointer", transform: this.rotateArrowTransform(this.state.isColorDropdownOpen) }}
          data-test-id="toggleColorDropdown"
          onClick={this.toggleColorDropdown}
        />
  
        {this.renderColorDropDown()}
      </div>
  
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "25px",
          borderBottom: "1px solid #E2E8F0",
          borderTop: "1px solid #E2E8F0",
          padding: "12px 0px 12px 0px",
          position: "relative",
        }}
      >
        <h1 style={webStyle.h1Sec2}>
          {this.transAddProductCatalogue(selectedSize) || this.state.catalogueDetail?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variant_size?.name || this.transAddProductCatalogue("Size")}
        </h1>
  
        <KeyboardArrowDownRoundedIcon
          style={{ color: "#375280", cursor: "pointer", transform: this.rotateArrowTransform(this.state.isSizeDropdownOpen)}}
          data-test-id="toggleSizeDropdown"
          onClick={this.toggleSizeDropdown}
        />
  
        {this.renderSizeDropDown()}
      </div>
    </div>
  </Grid>
                <Grid item>
                  <button data-test-id="veiwBtn" style={webStyle.veiwBtn} onClick={this.handleViewAsBuyerClick}>
                    {this.transAddProductCatalogue("View Details")}
                  </button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomBoxContent>
  
        <hr style={{ border: "1px solid rgb(226, 232, 240)", marginTop: 12, marginBottom: 18 }} />
      </div>
    </div>
  </div>
  
        {this.renderViewAsBuyer()}
      </>
    )}</>
  }

  renderColorDropDown = () => {
    const { isColorDropdownOpen,  catalogueDetail, selectedColor } = this.state;
    return <>
    {isColorDropdownOpen && (
          <div
            style={{
              position: "absolute",
              top: "100%", // Dropdown appears right below the color text
              left: "0",
              backgroundColor: "#FFF",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              zIndex: 1,
              padding: "10px",
              minWidth: "100%", // Ensure the dropdown has the same width as the parent container
            }}
          >
            {Array.from(new Set(catalogueDetail?.attributes?.catalogue_variants.map((variant:any,index:number) => variant.attributes.catalogue_variant_color?.name)))
              .map((color:any, index:any) => (
                <div
                  key={index}
                  style={{
                    padding: "8px 12px",
                    cursor: "pointer",
                    color: '#375280',
                    backgroundColor: selectedColor === color ? "#F0F0F0" : "transparent"
                  }}
                  data-test-id="handleColorSelect"
                  onClick={() => this.handleColorSelect(color || 'Color',index)}
                >
                  {this.transAddProductCatalogue(color)}
                </div>
              ))}
          </div>
        )}
    </>
  }

  renderSizeDropDown = () => {
    const { isSizeDropdownOpen,  catalogueDetail, selectedSize } = this.state;
    return <>{isSizeDropdownOpen && (
    <div
      style={{
        position: "absolute",
        top: "100%", // Dropdown appears right below the size text
        left: "0",
        backgroundColor: "#FFF",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "4px",
        zIndex: 1,
        padding: "10px",
        minWidth: "100%", // Ensure the dropdown has the same width as the parent container
      }}
    >
      {Array.from(new Set(catalogueDetail?.attributes?.catalogue_variants.map((variant:any) => variant.attributes.catalogue_variant_size?.name)))
        .map((size:any, index:any) => (
          <div
            key={index}
            style={{
              color: '#375280',
              cursor: "pointer",
              padding: "8px 12px",
              backgroundColor: selectedSize === size ? "#F0F0F0" : "transparent"
            }}
            data-test-id="handleSizeSelect"
            onClick={() => this.handleSizeSelect(size || 'Size')}
          >
            {this.transAddProductCatalogue(size)}
          </div>
        ))}
    </div>
  )}</>}

  renderViewAsBuyer = () => (
    <>
    {this.state.viewAsBuyer && (
            <Box style={webStyle.contentDivConteiner} id="scrollableDiv">
              <Box>
                <AccordionDetailsData>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={webStyle.title}>{this.transAddProductCatalogue("Product description")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={webStyle.discription}>
                      {this.state.catalogueDetail?.attributes?.description }
                    </Typography>
                  </AccordionDetails>
                </AccordionDetailsData>
                <AccordionDetailsData>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={webStyle.title}>{this.transAddProductCatalogue("Size & Fit")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={webStyle.discription}>
                    {this.transAddProductCatalogue("Fit")}: {this.state.catalogueDetail.attributes?.fit}
                    </Typography>
                  </AccordionDetails>
                </AccordionDetailsData>
                <AccordionDetailsData>
                  <AccordionSummary
                    expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={webStyle.title}>{this.transAddProductCatalogue("Product care & Material")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography style={webStyle.discription}>
                      {this.transAddProductCatalogue("Material")} - {this.state.catalogueDetail.attributes?.material }
                      <br />
                      {this.state.catalogueDetail?.attributes?.prodcut_care }
                    </Typography>
                  </AccordionDetails>
                </AccordionDetailsData>
               
  
                  <AccordionDetailsData >
                    <AccordionSummary
                      expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography style={webStyle.title}>{this.transAddProductCatalogue("Expected Delivery & Total Cost")}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={webStyle.discription}>
                      {this.transAddProductCatalogue("Delivery by")}: {this.formattedDate()} <br />
                        <Box style={{ display: 'flex', width: '32%' }}>
                          <Typography style={webStyle.discription}>{this.transAddProductCatalogue("Product Price")} : </Typography>
                          <Typography style={webStyle.discription}>{`${this.state.currencySign} ${this.state.catalogueDetail.attributes.primary_price
                            ? parseFloat(this.state.catalogueDetail.attributes?.primary_price).toFixed(2)
                            : "0.0"}`}</Typography>
                        </Box>
                        <Box style={{ display: 'flex', width: '32%' }}>
                          <Typography style={webStyle.discription}>{this.transAddProductCatalogue("Approx. Delivery Cost")} : </Typography>
                          <Typography style={webStyle.discription}> {this.state.currencySign} 10.00</Typography>
                        </Box>
                        <Box style={{ display: 'flex', width: '32%' }}>
                          <Typography style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%" }}>{this.transAddProductCatalogue("Total Cost")} : </Typography>
                          <Typography style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%" }}>{`${this.state.currencySign} ${this.state.catalogueDetail.attributes.primary_price
                            ? (parseFloat(this.state.catalogueDetail.attributes?.primary_price) + 10).toFixed(2)
                            : "0.0"}`}</Typography>
                        </Box>
                      </Typography>
                    </AccordionDetails>
                  </AccordionDetailsData>
                  <AccordionDetailsData>
    <AccordionSummary
      expandIcon={<KeyboardArrowDownRoundedIcon style={{ color: "#375280" }} />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography style={webStyle.title}>
        {this.state.userRole?.userType==='seller' 
          ? this.transAddProductCatalogue('Store Information')
          : this.transAddProductCatalogue('Stylist Information')}
      </Typography>
    </AccordionSummary>
  
    <AccordionDetails>
      <Box>
        <Box style={{ fontFamily: "Lato", fontSize: '16px', fontWeight: 700, color: '#375280', width: "100%", height: '3px' }}>
          {this.state.userRole?.userType==='seller'
            ? this.state.catalogueDetail.attributes.catalogue_variants_with_store[0]?.store_info.attributes?.store_name
            : this.state.catalogueDetail.attributes?.owner_full_name 
          }
        </Box>
        <br />
        <Box style={webStyle.discription}>
          {this.state.userRole?.userType==='seller' 
            ? [
                this.state.catalogueDetail.attributes.catalogue_variants_with_store[0]?.store_info.attributes?.area,
                this.state.catalogueDetail.attributes.catalogue_variants_with_store[0]?.store_info.attributes?.block,
                this.state.catalogueDetail.attributes.catalogue_variants_with_store[0]?.store_info.attributes?.address,
                this.state.catalogueDetail.attributes.catalogue_variants_with_store[0]?.store_info.attributes?.zipcode
              ].filter(Boolean).join(', ')
            : [
              this.state.catalogueDetail.attributes.owner_address?.area,
              this.state.catalogueDetail.attributes.owner_address?.block,
              this.state.catalogueDetail.attributes.owner_address?.address,
              this.state.catalogueDetail.attributes.owner_address?.zipcode
              ].filter(Boolean).join(', ')
          }
        </Box>
      </Box>
    </AccordionDetails>
  </AccordionDetailsData>
  
              </Box>
            </Box>
          )}
    </>
  )

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      
        <ThemeProvider theme={theme}>
        <SellerStylishContainer role={this.productDetailRole()} navigation={this.props.navigation}>
            <div style={webStyle.mainDiv}>
       {this.renderNotOpenMainForm()}
             {this.state.openMainForm&&(<EditCatalougeWeb navigation={this.props.navigation} id={""}/>)}  
              </div>
          </SellerStylishContainer>
        <Snackbar
                    open={this.state.showAlert}
                    anchorOrigin={{  vertical: 'top' ,horizontal: 'right',}}
                    autoHideDuration={3000}
                    data-testid="oncloseAlert"
                    style={{marginTop:'10px'}}
                    onClose={()=>this.closeModel()}
                ><Alert variant="filled" onClose={()=>this.closeModel()} severity={ "error"}>{this.state.showError}</Alert></Snackbar>
      
                <div>
                  <Modal
                    open={this.state.openModel}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    dir={i18n.dir()}
                  >
                    <Box sx={styleModel}>
                      <CustomDiv>
                        <img src={cross}
                         alt="cross" 
                         onClick={()=>this.setState({openModel:false})}
                         data-test-id="modelOne" 
                        />
                      </CustomDiv>
                      <CustomH1 variant="h1">{this.transAddProductCatalogue("Edit Products")}</CustomH1>
                      <div style={{margin: "40 49 20 50"}}>
                        <div>
                          <h1 style={webStyle.menuH1} data-test-id="Manuaaly" onClick={()=>{this.setState({openMainForm:true,openModel:false})}}>{this.transAddProductCatalogue("Manually")}</h1>
                          <hr style={webStyle.hrrModel}/>    
                          </div>
                          <div onClick={()=>this.setState({openModelCsv:true,openModel:false})} data-test-id="modelTwo" >          
                          <h1 style={webStyle.csvH4}>{this.transAddProductCatalogue("CSV Excel Sheet")}</h1>
                          <hr style={webStyle.hrrModel}/>    
                          </div>                                   
                          <h1 style={webStyle.apiH12}>{this.transAddProductCatalogue("API Integration")}</h1>
                                           
                      </div>
                    </Box>
                  </Modal>
                </div>      
                <div>
                  <Modal
                    open={this.state.openModelCsv}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    dir={i18n.dir()}
                  >
                    <Box sx={styleModelCsv}>
                      <CustomDiv>
                        <img src={cross} alt="cross"  onClick={()=>this.setState({openModelCsv:false})} data-test-id="modelFr"/>
                      </CustomDiv>
                      <CustomH1 variant="h1">{this.transAddProductCatalogue("CSV Excel Sheet")}</CustomH1>
                      <div style={{margin: "40 49 20 50"}}>
                      <UploadBtnBox><Button component="label"  
                       style={{
                         backgroundColor:'#F3F3F3',
                         height:'230px',
                         width:'100%',
                         boxShadow:'none',
                         borderRadius:'2px',
                        }} variant="contained">
                        <img src={upload} alt="uploaded" />
                        <UploadBtn data-test-id="upload-product-title">
                        {this.transAddProductCatalogue("Upload Products")}
                        </UploadBtn>
                        <UploadText data-test-id="upload-product-des">{this.transAddProductCatalogue("Only CSV file accepted")}</UploadText>
                        <input
                          type="file"
                          hidden
                          id="upload-pro-img"
                          data-test-id="upload-image-test" 
                          onChange={(e)=>{this.setState({fileName:e.target.value})}}
                          accept=".csv"
                        />
                      </Button>
                      </UploadBtnBox>
                        <p style={webStyle.fileName} data-test-id="upload-file-name">{this.state.fileName}</p>
                          <BtnBox>
                           <button data-test-id="backBtn"   onClick={()=>this.setState({openModelCsv:false,openModel:true})} style={webStyle.backCsvBtn}>{this.transAddProductCatalogue("Back")}</button>&nbsp;&nbsp;
                           <button data-test-id="confirmBtn"  onClick={()=>{this.setState({openMainForm:true,openModelCsv:false})}} style={webStyle.confirmBtn}>{this.transAddProductCatalogue("Confirm")}</button>  
                          </BtnBox>                  
                      </div>
                    </Box>
                  </Modal>
                </div>   
                
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styleModel = {
  left: '50%',
  top: '50%',
  bgcolor: 'background.paper',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  borderRadius: "12px",
  border: "1px solid #CCBEB1",
  height: "434px",
  width: "450px",
  padding:0,
  position: 'absolute' as 'absolute',
};
const styleModelCsv = {
  transform: 'translate(-50%, -50%)',
  position: 'absolute' as 'absolute',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  left: '50%',
  border: "1px solid #CCBEB1",
  borderRadius: "12px",
  width: "620px",
  padding:0,
  top: '50%',
  height: "484px",
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
const CustomBox = styled(Box)({
    textAlign:'center',
    marginTop: "160px",
    marginBottom: "150px",
    marginLeft:'-40px'
});

const CustomBoxSearch = styled(Box)({
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    "& .btnSetting":{
      fontSize: "17px",
      lineHeight: "24px",
      color: "#FFFFFF",
      borderRadius: "4px",
      fontWeight: 700,
      border: "1px solid",
      marginLeft: -3,
      marginRight:18,
      fontStyle: "normal !important",
      height: 56,
      fontFamily: "Lato , sans-serif",
      cursor: "pointer",
      width: "240px",
      background: "#CCBEB1",
      "@media only screen and (max-width: 960px)": {
        width: "160px",
      },
    },
    "& .btnSetting1":{
      lineHeight: "24px",
      fontSize: "17px",
      borderRadius: "4px",
      fontWeight: 700,
      height: 56,
      border: "1px solid #CCBEB1",
      color: "#375280",
      marginRight:18,
      marginLeft: -3,
      fontStyle: "normal !important",
      fontFamily: "Lato , sans-serif",
      cursor: "pointer",
      width: "240px",
      background: "#FFFFFF",
      "@media only screen and (max-width: 960px)": {
        width: "160px",
      },
    },
    
});
const AccordionDetailsData = styled(Accordion)({
  padding: 0,
  color: "#375280",
  borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
  boxShadow: 'none !important',
  '&:before': {
    backgroundColor: 'transparent !important',
    borderTop: 'none !important',
  },
  '&.Mui-expanded': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12) !important',
  },
  fontFamily: "Lato",
})
const CustomInput = styled(TextField)({
    marginRight:25,
    border: "1px solid #CBD5E1",
    borderRadius: "4px",
    width:"250px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    paddingTop:11,
    paddingLeft:14,
    '& ::placeholder':{
      fontFamily: "Lato , sans-serif",
      fontStyle: "normal !important",
      fontWeight: 400,
      lineHeight: "22px",
      fontSize: "16px",
      color: "#43556E;",
    }
});
const CustomBoxContent = styled(Box)({
  flexWrap: "wrap",
  display: "flex",
});
const CustomDiv= styled(Typography)({
    textAlign:'end'
})
const CustomH1 =styled(Typography)({
    color: "#375280",
    fontSize: "30px",
    fontWeight: 500,
    lineHeight: "40px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    textAlign:"center"
})
const UploadBtn = styled(Typography)({
  boxShadow:'none',
  textTransform: "capitalize",
  fontFamily: "Lato , sans-serif",
  fontStyle: "normal !important",
  marginTop: "15px",
  display: "flex",
  justifyContent: "center",
  lineHeight: "26px",
  width: "100%",
  fontWeight: 700,
  fontSize: "18px",
  color: "#375280",
})
const UploadText = styled(Typography)({
  textTransform: "lowercase",
  color: "#94A3B8",
  fontSize: "18px",
  display: "flex",
  justifyContent: "center",
  lineHeight: "26px",
  width: "100%",
  fontWeight: 500,
  fontStyle: "normal !important",
  fontFamily: "Lato , sans-serif",
  marginTop: "5px"
})
const BtnBox = styled(Box)({
  display:'flex',
  flexWrap:'wrap',
  marginTop:'32px',
})
const webStyle = {
  fileName:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "20px",
    fontWeight: 500,
    fontSize: "14px",
    color: "#375280",
  },
  title: { fontWeight: 700, fontSize: "20px", fontFamily: "Lato" },
  elementBlock: { backgroundColor: "rgb(248, 248, 248)", display: "flex", padding: "20px 0", justifyContent: 'center', gap: "14px", alignItems: "center" },
  leftIcon: { display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundColor: "#CCBEB1", borderRadius: '50%', width: '22px', height: '22px', },
  imgBlock: { display: 'flex', backgroundColor: "rgb(248, 248, 248)",  maxWidth: '462px', borderRadius: '10px', gap: 10 },
  next: { display: 'flex', cursor: 'pointer', backgroundColor: '#CCBEB1', borderRadius: '50%', width: '22px', height: '22px', justifyContent: 'center', alignItems: 'center', },

  discription: { fontWeight: 400, fontSize: "16px", fontFamily: "Lato", width: "100%", color: "#375280" },
  backCsvBtn:{
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "18px",
    color: "#375280",
    fontStyle: "normal !important",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    background: "#FFFFFF",
    cursor: "pointer",
    width:'252px',
    height:'54px',
   },
   confirmBtn:{
     width:'252px',
     height:'54px',
     background: "#CCBEB1",
     border: "1px solid #CCBEB1",
     color: "#FFFFFF",
     fontSize: "18px",
     fontWeight: 700,
     lineHeight: "26px",
     fontStyle: "normal !important",
     fontFamily: "Lato , sans-serif",
     borderRadius:'2px',
     cursor: "pointer"
    },
    veiwBtn:{
      lineHeight: "24px",
      fontFamily: "Lato , sans-serif",
      fontWeight: 600,
      borderRadius:'2px',
      cursor: "pointer",
      fontSize: "16px",
      padding:"10px 16px 10px 16px",
      fontStyle: "normal !important",
      color: "#375280",
      border: "1px solid #E2E8F0",
      background: "#E2E8F0",
      marginTop:'28px'
     },
  backBtn:{
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "18px",
    fontStyle: "normal !important",
    color: "#375280",
    border: "1px solid #CCBEB1",
    fontFamily: "Lato , sans-serif",
    background: "#FFFFFF",
    cursor: "pointer",
    width:'170px',
   height:'54px',
  },
  addBtn:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    borderRadius:'2px',
    lineHeight: "26px",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    color: "#FFFFFF",
    border: "1px solid #CCBEB1",
    background: "#CCBEB1",
    height:'54px',
    width:'170px',
   },
   hrrModel:{
    marginBottom:24,
    marginTop:24,
    border:"1px solid #D9D9D9",
  },
  modelCross:{
    textAlign:"end"
  },
  menuH1:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 700,
    cursor:"pointer",
    fontSize: "20px",
    color: "#375280",
  },
  csvH4:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "20px",
    color: "#375280",
  },
  apiH12:{
    fontSize: "20px",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontFamily: "Lato , sans-serif",
    fontWeight: 500,
    color: "#375280",
  },
  zaraImg:{
    border: "1px solid #E2E8F0",
    height: "234",
    width: "235",
    position: "relative",
  },
  dotsContainer: {
    position: "absolute",
    bottom: "10px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: "8px",
  },
  dot: {
    width: "8px",  
    height: "8px",  
    borderRadius: "50%",
    backgroundColor: "#ccc", 
    transition: "all 0.3s ease",
  },
  highlightedDot: {
    width: "12px",  
    height: "12px", 
    backgroundColor: "#375280",
  },
  h1First:{
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 700,
    fontSize: "24px",
    color: "#375280",
    margin:0,
  },
  h1Sec:{
    margin:0,
    color: "#375280",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  h1Sec2:{
    margin:0,
    color: "#375280",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "22px",
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif"
  },
  h1Last:{
    margin:0,
    fontStyle: "normal !important",
    lineHeight: "26px",
    fontWeight: 700,
    fontFamily: "Lato , sans-serif",
    fontSize: "22px",
    color: "#059669",
  },
  divofZara:{
    display:'flex'
  },
  contentDivConteiner:{
    border:"1px solid #E2E8F0",
    borderRadius: "4px",
    marginTop: "25px",
    padding: "20 20 0 20",
  },
  contentHeartDiv:{
    background: "#FFFFFF",
    marginBottom: 70,
    borderRadius: "45px",
    boxShadow: "0px 2px 8px 5px #00000014",
    border: "10px solid #FFFFFF",
    width:"24px",
    height:"24px",
    marginLeft:'auto',
  },
  headerDiv: {
    marginBottom: "-5px",
    display: "flex",
    gap: 20,
    justifyContent: "space-between"
  },
  tshirtImg:{
    marginBottom:12
  },
  
  
  btnTshirt:{
    lineHeight: "26px",
    fontSize: "20px",
    fontWeight: 700,
    borderRadius: "4px",
    border: "1px solid",
    fontStyle: "normal !important",
    height: 58,
    fontFamily: "Lato , sans-serif",
    marginLeft: -3,
    color: "#FFFFFF",
    cursor: "pointer",
    width: "250px",
    background: "#CCBEB1",
  },
  divTshirt: {
    marginBottom: "150px",
    marginTop: "190px",
    textAlign:'center',
  },
  titleNotification: {
    fontStyle: "normal !important",
    fontFamily: "Lato , sans-serif",
    fontWeight: 700,
    fontSize: "24px",
    whiteSpace: 'nowrap' as 'nowrap',
    color: "#375280",
  },
  mainDiv: {
    marginTop: "20px",
    margin: "30px",
  },
  titleOfDivShirtHeader: {
    fontFamily: "Lato , sans-serif",
    fontStyle: "normal !important",
    lineHeight: "24px",
    fontWeight: 500,
    fontSize: "28px",
    color: "#375280",
  },
};
// Customizable Area End
