import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { ErrorsMessage } from "../SellerProfileController.web";
import { CountrySuccess } from "../CurrencylanguageController";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import React from "react";
import storage from "framework/src/StorageProvider";

// Customizable Area Start
import i18n, { i18nUpdate } from "../../../../components/src/i18next/i18n";
import { checkCondition } from "../../../../components/src/Seller/logOut";
import { ICountryResp } from "../../../../components/src/CountryDropDown.web";

interface ErrorPhone {
  errors: [{
    phone_number: string,
    email: string
  }]
};

interface OTPBody {
  otp_type: string | undefined;
  token: string | undefined;
  pin: string;
}

interface APIPayloadType {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ResendSMSOTP {
  data: {
    type: string;
    attributes: {
      full_phone_number: string;
    }
  }
};
interface ResendEmailOTP {
  data: {
    type: string;
    attributes: {
      email: string;
    }
  }
}

interface MetaResponse {
  meta: {
    sms_token: string;
    email_token: string;
  }
}

interface PhoneOTPPayLoad {
  token: string | undefined;
  pin: string;
}
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  errorMessage: ErrorsMessage;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  profileImage: string;
  selectedCountry: CountrySuccess;
  contryCodeResp: CountrySuccess[];
  isCountryData: string;
  oldPasswordIcon: boolean;
  oldPassword: string;
  newPasswordIcon: boolean;
  newPassword: string;
  ReNewPasswordIcon: boolean;
  rePassword: string;
  isAlert: boolean;
  alertType: 'success' | 'error' | 'warning' | 'info';
  alertMsg: string;
  emailAndOtpVerify: boolean;
  emailType: string;
  uploadDialogOpen: boolean;
  imageUrl: string;
  imageUpload: FileList | null,
  inputRef: React.RefObject<HTMLInputElement>;
  otpInput: string;
  errorOtpMsg: string;
  uploderror:string;
  newProfileImg: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class StylishProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getPersonalUserDataAPICallID: string = "";
  changeProfileAPICallID: string = "";
  countryCodeDataAPICall: string = "";
  changePasswordAPICallID: string = "";
  changeProfileImageAPICallID: string = "";
  resendOTPCallID: string = "";
  phoneNumberOtpVerifyCallID: string = "";
  emailVerifyCallID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      loading: false,
      errorMessage: {
        firstNameMessage: "",
        lastNameMessage: "",
        emailMessage: "",
        passwordMessage: "",
        rePasswordMessage: "",
        phoneNumberMessage: "",
        oldPasswordMessage: "",
        newPasswordMessage: "",
        errorFirstName: false,
        errorLastName: false,
        errorEmail: false,
        errorPassword: false,
        errorOldPassword: false,
        errorNewPassword: false,
        errorRePassword: false,
        errorPhoneNumber: false
      },
      firstName: "",
      lastName: "",
      email: "",
      uploderror:"",
      phoneNumber: "",
      profileImage: "",
      contryCodeResp: [],
      oldPasswordIcon: false,
      oldPassword: '',
      newPasswordIcon: false,
      newPassword: '',
      ReNewPasswordIcon: false,
      rePassword: '',
      selectedCountry: { country_code: "KW", numeric_code: "+965" ,country_full_name: "", country_flag: ""},
      isCountryData: "+91",
      isAlert: false,
      alertType: 'success',
      alertMsg: '',
      emailAndOtpVerify: false,
      emailType: "",
      uploadDialogOpen: false,
      imageUrl: "",
      imageUpload: null,
      inputRef: React.createRef(),
      otpInput: "",
      errorOtpMsg: "",
      newProfileImg: ""
    };
    i18nUpdate()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getCountryCallApiCall();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (apiRequestCallId === this.getPersonalUserDataAPICallID) {
      this.handleGetProfileResponse(responseJson)
    } else if (apiRequestCallId === this.changeProfileAPICallID) {
      this.handleSaveAPIResponse(responseJson)
    } else if (apiRequestCallId === this.countryCodeDataAPICall) {
      this.setState({
        contryCodeResp: responseJson
      }, ()=>this.getPersonalUserAPICall());
    } else if (apiRequestCallId === this.changePasswordAPICallID) {
      this.handleChangePasswordResponse(responseJson.errors)
    } else if (apiRequestCallId === this.changeProfileImageAPICallID) {
      this.getPersonalUserAPICall();
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: `${this.transStylist("User Profile Updated Successfully")}`,
        uploadDialogOpen: false,
        imageUrl: '',
        uploderror:"",
        imageUpload: null
      });
    } else if (apiRequestCallId === this.resendOTPCallID) {
      alert(configJSON.resentOTP);
    } else if (apiRequestCallId === this.emailVerifyCallID) {
      this.handleVerifyApiCallResponse(responseJson);
    } else if (apiRequestCallId === this.phoneNumberOtpVerifyCallID) {
      this.handlePhoneNumber(responseJson);

    }

    // Customizable Area End
  }

  // Customizable Area Start
handleGetProfileResponse = (responseJson: {data: {
  attributes: {
    last_name: string;
    phone_number: number;
    first_name: string;
    email: string;
    profile_picture: string;
    country_code_name: string | null;
    country_code: number
  }
}, meta: {
  stylist_profile_picture: string;
}}) => {
  if (responseJson.data) {
    const { attributes } = responseJson.data;
          
    let countryData: string | null = null;
    
    if (!attributes.country_code_name) {
      countryData = this.getCountryByCode(`+${attributes.country_code.toString()}`);
    }
    
    this.setState((prevState)=>({
      lastName: responseJson.data?.attributes?.last_name,
      phoneNumber: responseJson.data?.attributes?.phone_number?.toString(),
      firstName: responseJson.data?.attributes?.first_name,
      email: responseJson.data?.attributes?.email,
      profileImage: responseJson.meta?.stylist_profile_picture,
      selectedCountry: {
        ...prevState.selectedCountry,
        country_code: countryData ? countryData : attributes.country_code_name ?? "KW",
        numeric_code: responseJson.data.attributes.country_code?.toString() || "+965",
        country_full_name: "",
        country_flag: "",
      },
      newProfileImg: responseJson.data?.attributes?.profile_picture,
    }));
  }
}

getCountryByCode = (code: string): string => {
  const country = this.state.contryCodeResp.find((country) => country.numeric_code === code);
  return country ? country.country_code : "KW";
};

  transStylist = (klog: string) => {
    return i18n.t(klog, { ns: "profile" })
  };

  handleVerifyApiCallResponse = async (responseJson: { message: string, email?: string } & {
    errors: {
      pin: string
    }
  }) => {
    const smSToken = await getStorageData('sms-token');
    if (smSToken && responseJson?.message) {
      this.setState({
        emailType: "sms_otp",
        otpInput: ''
      })
    } else if (responseJson?.email) {
      this.setState({
        errorOtpMsg: responseJson?.email
      });
    } else if (responseJson?.errors?.pin) {
      this.setState({
        errorOtpMsg: responseJson?.errors?.pin
      });
    } else {
      this.setState({
        emailAndOtpVerify: false,
        otpInput: '',
      });
      if (responseJson?.message) {
        this.setState({
          isAlert: true,
          // alertMsg: responseJson?.message,
          alertType: "success"
        });
      }
      await removeStorageData('user-email');
    }
  }

  handlePhoneNumber = async (responseJson: {
    errors: {
      pin: string
    }
  } & {
    message: string
  }) => {
    if (responseJson.errors?.pin) {
      this.setState({
        errorOtpMsg: responseJson.errors?.pin
      })
    } else {
      this.setState({
        emailAndOtpVerify: false, emailType: "",
        otpInput: '', isAlert: true, 
        // alertMsg: responseJson?.message,
        alertType: "success"
      });
      await removeStorageData('sms-token');
      await removeStorageData('user-email');
    }
  }

  handleSubmitPhoneOTPForm = async (body: PhoneOTPPayLoad) => {
    this.phoneNumberOtpVerifyCallID = await this.apiCallMobileService({
      contentType: configJSON.postEmailVarificationContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postPhoneVarificationEndPoint,
      body: body
    });
  };

  handleSubmitEmailOTPForm = async (body: OTPBody) => {
    this.emailVerifyCallID = await this.apiCallMobileService({
      contentType: configJSON.postEmailVarificationContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postEmailVarificationEndPoint,
      body: body
    });
  };

  handleEmailAndOtpVerifyClose = () => {
    this.setState({ emailAndOtpVerify: false, otpInput: "", errorOtpMsg: "" });
  };

  handleSubmit = async () => {
    const { otpInput } = this.state;
    const errorMSG = otpInput === '' || otpInput.length !== 4 ? configJSON.enterOTP : '';
    this.setState({ errorOtpMsg: errorMSG });
    const isApiType = this.state.emailType === configJSON.smsType;
    const tokenMobile = await getStorageData("sms-token");
    const otpTypeMobile = configJSON.smsType;
    const otpTypeEmail = configJSON.emailType;
    const tokenEmail = await getStorageData("user-email");
    if (errorMSG === "") {
      if (isApiType) {
        const apiRes = {
          token: tokenMobile,
          pin: otpInput,
          otp_type: otpTypeMobile
        };
        this.handleSubmitPhoneOTPForm(apiRes);
      } else {
        const apiRes = {
          otp_type: otpTypeEmail,
          token: tokenEmail,
          pin: otpInput,
        };
        this.handleSubmitEmailOTPForm(apiRes);
      }
    }
  };

  handleResendSMSOTP = async (body: ResendSMSOTP | ResendEmailOTP) => {
    this.resendOTPCallID = await this.apiCallMobileService({
      contentType: configJSON.postResendOTPContentType,
      method: configJSON.postApimethod,
      endPoint: configJSON.postResendOTPEndPoint,
      body: body
    });

  };

  apiCallMobileService = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const headers = {
      "Content-Type": contentType,
    };

    const requestmobileOTPMessageSerice = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestmobileOTPMessageSerice.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers));

    requestmobileOTPMessageSerice.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestmobileOTPMessageSerice.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestmobileOTPMessageSerice.id, requestmobileOTPMessageSerice);
    return requestmobileOTPMessageSerice.messageId;
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || (e.key >= '0' && e.key <= '9')) {
      return;
    }
    e.preventDefault();
  }
  handeResentOTP = () => {
    if (this.state.emailType === 'email_otp') {
      const apiRes = {
        data: {
          type: configJSON.email_accountMsg,
          attributes: {
            email: this.state.email,
          },
        },
      };
      this.handleResendSMSOTP(apiRes);
    } else {
      const apiRes = {
        data: {
          type: configJSON.sms_accountMsg,
          attributes: {
            full_phone_number: `${this.state.isCountryData}${this.state.phoneNumber}`,
          },
        },
      };
      this.handleResendSMSOTP(apiRes);
    }
  };

  handleChangePasswordResponse = (responseError: string) => {
    if (responseError) {
      this.setState({
        isAlert: true,
        alertType: "error",
        alertMsg: responseError
      });
    } else {
      this.setState({
        isAlert: true,
        alertType: "success",
        alertMsg: `${this.transStylist("Password changed successfully")}`
      });
    }
  };

  handleOption = () => {
    if (this.state.emailType === 'email_otp') {
      return true;
    }
  };

  maskEmail = () => {
    let mail = this.state.email;
    let atIndex = mail?.indexOf("@");
    let maskedEmail = mail?.substring(atIndex - 2);
    let starMail = `********${maskedEmail}`;
    return starMail;
  };

  handleOTPChange = (otps: string = "") => {
    this.setState({
      otpInput: otps,
      errorOtpMsg: "",
    });
  }

  maskPhone = () => {
    let phoneNumber = this.state.phoneNumber;
    let length = phoneNumber?.length;
    let maskedPart1 = phoneNumber?.substring(length - 2);
    let starPhone = `*******${maskedPart1}`;
    return starPhone;
  };

  handleUpload = () => {
    return this.state.inputRef.current
      ? this.state.inputRef.current.click()
      : "";
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  handleProfilePicUpload = async () => {
    if (this.state.imageUpload) {
      const token = await getStorageData("auth-token");
      const headers = {
        token: token
      };

      const formDataa = new FormData();
      formDataa.append("first_name", this.state.firstName);
      formDataa.append("last_name", this.state.lastName);
      formDataa.append("email", this.state.email);
      formDataa.append("full_phone_number", `${this.state.selectedCountry.numeric_code}${this.state.phoneNumber}`);
      formDataa.append("country_code", this.state.selectedCountry.numeric_code);
      formDataa.append("country_code_name", this.state.selectedCountry.country_code);
      formDataa.append("stylist_profile_picture", this.state.imageUpload[0], this.state.imageUpload[0]?.name);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changeProfileImageAPICallID = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formDataa
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateProfilePicAPIEndPoint);
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        `PUT`
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      this.setState({uploderror:this.transStylist("please upload image.")})
    }

  }

  handleCloseUploadDialog = () => {
    this.setState((prevState)=>({...prevState,
      imageUrl: '',
      imageUpload: null,
      uploadDialogOpen: false,
      profileImage: prevState.newProfileImg,
    }));
  };

  handleFileUpload = (files: FileList | null) => {
    if (files) {
      this.setState((prevState)=>({...prevState,
        imageUpload: files,
        profileImage: URL.createObjectURL(files[0]),
        imageUrl: URL.createObjectURL(files[0]),
      }));
    }
  };

  handleSaveAPIResponse = async (responseJson: ErrorPhone & MetaResponse & { errors: { full_phone_number: string } }) => {

    if (responseJson?.errors?.full_phone_number) {
      this.setState({
        isAlert: true,
        alertType: 'error',
        alertMsg: `${responseJson.errors.full_phone_number}`
      })
    } else if (responseJson?.errors?.length > 0) {
      if (responseJson?.errors[0].phone_number) {
        this.setState({
          isAlert: true,
          alertType: 'error', alertMsg: `Phone Number ${responseJson.errors[0].phone_number}`
        })

      } else {
        this.setState({
          alertType: 'error', isAlert: true,
          alertMsg: `Email ${responseJson.errors[0].email}`
        })
      }
    } else {

      if (responseJson.meta.email_token && responseJson.meta.sms_token) {
        await setStorageData("user-email", responseJson.meta.email_token);
        await setStorageData("sms-token", responseJson.meta.sms_token);
        const tokenToOpen = this.handleSmsOREmail(responseJson.meta);
        this.setState({
          emailAndOtpVerify: true,
          emailType: tokenToOpen
        });
      } else if (responseJson.meta.email_token) {
        await setStorageData("user-email", responseJson.meta.email_token);
        const tokenToOpen = this.handleSmsOREmail(responseJson.meta);
        this.setState({
          emailAndOtpVerify: true,
          emailType: tokenToOpen
        });
      } else if (responseJson.meta.sms_token) {
        await setStorageData("sms-token", responseJson.meta.sms_token);
        const tokenToOpen = this.handleSmsOREmail(responseJson.meta);
        this.setState({
          emailAndOtpVerify: true,
          emailType: tokenToOpen
        });
      } else {
        this.setState({
          isAlert: true,
          alertMsg: `${this.transStylist("User Profile Updated Successfully")}`
        });
        this.getPersonalUserAPICall();

      }
    }
  };

  handleEditProfileClick = () => {
    this.setState({
      uploadDialogOpen: true
    })
  };

  validatePassword = () => {
    const { oldPassword } = this.state;

    const passRegx = configJSON.passRegx;
    if (oldPassword === "" || !passRegx.test(oldPassword)) {
      this.setState({
        errorMessage: {
          oldPasswordMessage:  checkCondition(Boolean(oldPassword), this.transStylist(configJSON.min8digitValidationMsg), this.transStylist(configJSON.validationPassMsg)) as string,
          errorOldPassword: true
        }
      });
      return false;
    }
    return true;

  };

  validateRePassword = () => {
    const { newPassword, rePassword } = this.state;
    if (rePassword === "" || rePassword !== newPassword) {
      this.setState({
        errorMessage: {
          rePasswordMessage:  checkCondition(Boolean(rePassword), this.transStylist(configJSON.confirmPass), this.transStylist(configJSON.reEnterValidationMsg)) as string,
          errorRePassword: true
        }
      });

      return false;
    }
    return true;

  };


  validateNewPassword = () => {
    const { newPassword } = this.state;
    const passRegx = configJSON.passRegx;
    if (newPassword === "" || !passRegx.test(newPassword)) {
      this.setState({
        errorMessage: {
          newPasswordMessage:  checkCondition(Boolean(newPassword), this.transStylist(configJSON.min8digitValidationMsg), this.transStylist(configJSON.validationPassMsg)) as string,
          errorNewPassword: true
        }
      });
      return false;
    }

    return true;
  };

  handleSelectStylishCountryCode =  (event: ICountryResp) => {
    this.setState((prevState) => ({
      isCountryData: event.numeric_code,
      selectedCountry: {...prevState.selectedCountry, country_code: event.country_code, numeric_code: event.numeric_code,country_full_name: "",
        country_flag: ""}
    }));
  };


  handleChangePassword = () => {
    if (!this.validatePassword()) {
      return;
    }
    if (!this.validateNewPassword()) {
      return;
    }

    if (!this.validateRePassword()) {
      return;
    }
    this.changePasswordApiCall();
  };

  changePasswordApiCall = async () => {
    const tokenn = await getStorageData("auth-token");
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: tokenn
    };

    const payload = {
      data: {
        "current_password": this.state.oldPassword,
        "new_password": this.state.newPassword,
        "confirm_password": this.state.rePassword
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/accounts/change_password`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), `PUT`);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountryCallApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryCodeDataAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryCodeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSmsOREmail = (metaData: {
    sms_token: string;
    email_token: string;
  }) => {
    if (metaData.sms_token && metaData.email_token) {
      return "email_otp"
    } else if (metaData.email_token) {
      return "email_otp"
    } else {
      return "sms_otp"
    }
  };

  generateClassName = (
    error: string | undefined,
    baseClassName: string,
    errorClassName: string
  ) => {
    return error ? errorClassName : baseClassName;
  };

  generateHelperFirst = () => {
    return this.state.errorMessage.firstNameMessage ? this.state.errorMessage.firstNameMessage : "";
  };


  validateFirstName = () => {
    const { firstName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (firstName?.trim() === "" || !nameRegex.test(firstName)) {
      this.setState({
        errorMessage: {
          errorFirstName: true, firstNameMessage: this.transStylist(configJSON.firstNameValidationMsg)
        },
      });
      return false;
    }
    return true;
  };

  validateEmail = () => {
    const { email } = this.state;
    const emailRegex = configJSON.emailRegex;
    if (email === "" || !emailRegex.test(email)) {
      this.setState({
        errorMessage: {
          errorEmail: true,
          emailMessage:  checkCondition(Boolean(email), this.transStylist(configJSON.emailValidMsg), this.transStylist(configJSON.emailValidationMsg)) as string,
        }
      });
      return false;
    }
    return true;
  };

  validateLastName = () => {
    const { lastName } = this.state;
    const nameRegex = configJSON.nameRegex;
    if (lastName?.trim() === "" || !nameRegex.test(lastName)) {
      this.setState({
        errorMessage: {
          lastNameMessage: this.transStylist(configJSON.lastNameValidationMsg),
          errorLastName: true
        },
      });
      return false;
    }
    return true;
  };

  validatePhoneNumber = () => {
    const { phoneNumber } = this.state;
    const phoneRegex = configJSON.phoneRegex;

    if (phoneNumber?.trim() === "") {
      this.setState({
        errorMessage: {
          phoneNumberMessage: this.transStylist(configJSON.phoneValidationMsg),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else if (!phoneRegex.test(phoneNumber) && phoneNumber.length <= 7) {
      this.setState({
        errorMessage: {
          phoneNumberMessage: this.transStylist(configJSON.alphnewmertic),
          errorPhoneNumber: true
        }
      });
      return false;
    }
    else {
      this.setState({
        errorMessage: {
          phoneNumberMessage: "",
          errorPhoneNumber: false
        }
      });
      return true;
    }
  };

  handleUserProfile = () => {
    if (!this.validateFirstName()) {
      return;
    }
    if (!this.validateLastName()) {
      return;
    }
    if (!this.validateEmail()) {
      return;
    }

    if (!this.validatePhoneNumber()) {
      return;
    }

    this.changeprofileApiCall();
  };

  changeprofileApiCall = async () => {
    const token = await getStorageData("auth-token");
    const header = { token: token };
    const formData = new FormData();
    formData.append("last_name", this.state.lastName);
    formData.append("email", this.state.email);
    formData.append("first_name", this.state.firstName);
    formData.append("full_phone_number", `${this.state.selectedCountry.numeric_code}${this.state.phoneNumber}`);
    formData.append("country_code", this.state.selectedCountry.numeric_code);
    formData.append("country_code_name", this.state.selectedCountry.country_code);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.changeProfileAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/update_personal_detail`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `PUT`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFirst = (event: string) => {
    const firstName = event;
    this.setState({
      firstName: firstName.replace(/\d/g, ''),
      errorMessage: {
        firstNameMessage: "",
        errorFirstName: false
      }
    });
  };

  generateHelperLast = () => {
    return this.state.errorMessage.lastNameMessage ? this.state.errorMessage.lastNameMessage : "";
  };

  helperTextPhoneNumber = () => {
    return this.state.errorMessage.phoneNumberMessage ? this.state.errorMessage.phoneNumberMessage : ""
  }

  handleEmail = (emailVal: string) => {
    const email = emailVal;
    this.setState({
      email: email,
      errorMessage: {
        emailMessage: "",
        errorEmail: false
      }
    });
  };

  handleNewPassword = (passVal: string) => {
    const newPassword = passVal;
    this.setState({
      newPassword: newPassword,
      errorMessage: {
        newPasswordMessage: "",
        errorNewPassword: false
      }
    })
  };

  getPersonalUserAPICall = async () => {
    const user_id = localStorage.getItem('user_id');
    const token = await getStorageData("auth-token");
    const header = {
      "Content-Type": configJSON.countryApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPersonalUserDataAPICallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPersonalUserDetailsApiEndPoint}?user_id=${user_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRePassword = (passVal: string) => {
    const rePassword = passVal;
    const { newPassword } = this.state;
    if (newPassword !== rePassword) {
      this.setState({
        rePassword: rePassword,
        errorMessage: {
          rePasswordMessage: this.transStylist(configJSON.confirmPass),
          errorRePassword: true
        }
      });
    } else {
      this.setState({
        rePassword: rePassword,
        errorMessage: {
          rePasswordMessage: "",
          errorRePassword: false
        }
      });
    }
  };

  handleOldPassword = (passVal: string) => {
    const oldPassword = passVal;
    this.setState({
      oldPassword: oldPassword,
      errorMessage: {
        oldPasswordMessage: "",
        errorOldPassword: false
      }
    })
  };

  handleLast = (event: string) => {
    const lastName = event;
    this.setState({
      lastName: lastName.replace(/\d/g, ''),
      errorMessage: {
        lastNameMessage: "",
        errorLastName: false
      }
    });
  };

  helperTextOldPassword = () => {
    return this.state.errorMessage.oldPasswordMessage ? this.state.errorMessage.oldPasswordMessage : ""
  }

  handleView = (state: boolean, trueSta: string, falseSta: string) => {
    if (state) {
      return trueSta;
    } else {
      return falseSta;
    }
  }

  handleNumber = (numberVal: string) => {
    const number = numberVal;
    this.setState({
      phoneNumber: number.replace(/\D/g, ''),
      errorMessage: {
        phoneNumberMessage: "",
        errorPhoneNumber: false
      }
    })
  };

  generateHelperEmail = () => {
    return this.state.errorMessage.emailMessage ? this.state.errorMessage.emailMessage : "";
  }
  // Customizable Area End

}
