import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Drawer,
  useMediaQuery,
  Box,
  useTheme,
  styled,
} from "@material-ui/core";
import SellerSidebar from "../Seller/SellerSideBar";
import SellerHeader from "../Seller/SellerHeader";
import StylishSidebar from "../Stylish/StylishSidebar";
import StylishHeader from "../Stylish/StylishHeader";
import i18n from "../i18next/i18n";

interface HeaderSidebarProps {
  role: "Seller" | "Stylish" | "Buyer" | "";
  children: React.ReactNode;
  navigation: any;
  handleClickStoreEvent?: ( id: number | string) => void;
  isStoreOptions?: boolean;
}

const HeaderSidebar: React.FC<HeaderSidebarProps> = ({ role, children, navigation, isStoreOptions, handleClickStoreEvent }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
      setIsDrawerOpen(isMobile ? false: true);
  }, [isMobile]);

  const roleDrawerWidth = role === "Stylish" ? 315 : 291;
  const drawerWidth = i18n.dir()==="ltr"? 307:roleDrawerWidth;

  const contentDivWidth = !isDrawerOpen ? "0px" : `${drawerWidth}px`;
  const contentDiv = isMobile ? "0px" : contentDivWidth;

  const Sidebar = (role: string) => {
    switch (role) {
      case "Seller":
        return <SellerSidebar navigation={navigation} />;
      case "Stylish":
        return <StylishSidebar navigation={navigation} />;
      case "Buyer":
      default:
        <></>;
    }
  };

  const Header = (role: string) => {
    switch (role) {
      case "Seller":
        return <SellerHeader toggleSidebar={toggleDrawer} navigation={navigation} isStoreOptions={isStoreOptions} handleClickStoreEvent={handleClickStoreEvent} />;
      case "Stylish":
        return <StylishHeader navigation={navigation} toggleSidebar={toggleDrawer} />;
      case "Buyer":
      default:
        <></>;
    }
  };
  return (
    <>
      {role === "Buyer" || role==="" ? <>{children}</> :<><AppBar
        position="fixed"
        style={{boxShadow: "none",width: `calc(100% - ${contentDiv}`,
        backgroundColor: "#FFF",
        margin:i18n.dir()==="ltr" ? 0 :`0px ${contentDiv} 0px 0px`, display: "block"}}
        dir={i18n.dir()}
      >
        <ToolbarContainer dir={i18n.dir()}>
          {Header(role)}
        </ToolbarContainer>
      </AppBar>

      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        open={isDrawerOpen}
        onClose={toggleDrawer}
        anchor={i18n.dir()==="ltr" ? "left":"right"}
        style={{
          width: drawerWidth,
          flexShrink: 0,
        }}
      >
        {Sidebar(role)}
      </Drawer>
      <MainContainer
        dir={i18n.dir()}
        contentDiv={contentDiv}
      >
        {children}
      </MainContainer></>}
    </>
  );
};

export default HeaderSidebar;

const MainContainer = styled(Box)(({ dir, contentDiv }: { dir: "rtl" | "ltr" , contentDiv: string}) => ({
  flexGrow: 1,
  marginLeft: dir==="ltr" ? contentDiv : 0,
  marginRight: dir==="rtl" ? contentDiv : 0,
  marginTop: "85px",
}));

const ToolbarContainer = styled(Toolbar)(({ dir }: { dir: "rtl" | "ltr" }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 20,
  padding: dir==="ltr"? "0px 0px 0px 24px" : "0px 24px 0px 0px",
  width: "100%",
  backgroundColor: "#FFF",
  borderBottom: "1px solid var(--Neutrals-Cool-gray-200, #E2E8F0)",
}));
