import React from "react";

// Customizable Area Start

import { createTheme, styled } from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@material-ui/core";import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {sideImage} from "../src/assets";
import Header from "../../../components/src/Header";
import Footer from "../../../components/src/Footer";
import i18n from "../../../components/src/i18next/i18n";
// Customizable Area End

import EmailAccountLoginController, {
    CountrySuccess,
    Props, configJSON
} from "./EmailAccountLoginController.web";
import CountrySelect from "../../../components/src/CountryDropDown.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class UserAccountActivation extends EmailAccountLoginController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const phoneClassName=this.state.mobileError?"inputFeild2Error2":"inputFeild2";
        const phoneErrorMessage=this.state.errorsMessage.mobileNumberError ? this.state.errorsMessage.errorMsgMobile : "";
        return (
            // Required for all blocks
            <>
                <Header navigation={this.props.navigation}/>
                <MainGridContainer dir={i18n.dir()} className="LoginFormMainGrid" container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className="imageGrid">
                        <Box className="sideImageBox">
                            <img src={sideImage} alt="" className="sideImageContaine" />
                        </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={"formGrid"}>
                        <Box className={"formBox"}>
                            <form data-test-id="formSubmit1"  onSubmit={this.handleSubmit} style={{ width: "100%" }}>
                                <Box>
                                    <Typography className={"welcomeText"}>{this.transLogin(configJSON.welcomeBack)}</Typography>
                                    <Typography className={"welcomeSubText"}>
                                        {this.transLogin(configJSON.AddPhoneNumber)}
                                    </Typography>

                                    <Typography data-test-id="countryId" className={"inputHeading"}>{this.transLogin(configJSON.phoneNumber)}</Typography>
                                    <Box className={"phoneInput"}>
                                    <CustomDropDownBox>
                                        <CountrySelect
                                            id="phoneCode"
                                            name="phoneCode"
                                            placeholder="Select Phone Code"
                                            value={this.state.selectedCountry?.country_code || "KW"}
                                            onChange={this.handleAccountChange}
                                            countryCode={this.state.countrySuccess}
                                        />
                                        </CustomDropDownBox>
                                        <TextField
                                            dir={i18n.dir()}
                                            data-test-id="phoneNumberTextInput1"
                                            variant="outlined"
                                            className={phoneClassName}
                                            value={this.state.mobileNumber}
                                            onChange={this.handlePhoneNumberChange}
                                            placeholder={this.transLogin(configJSON.phonePlace)}
                                            error={this.state.errorsMessage.mobileNumberError}
                                            helperText={phoneErrorMessage}
                                            inputProps={{ maxLength: 12 }}
                                            InputProps={{
                                                style: {
                                                    gap: "4px",
                                                    border: "0px",
                                                    borderRadius: "0px",
                                                    width: "420px"
                                                },
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    variant="contained"
                                    className={"loginUserButton"}
                                    disableElevation
                                    type="submit"
                                >
                                    {this.transLogin(configJSON.AddPhoneNumber)}
                                </Button>
                                <Snackbar
                                    dir={i18n.dir()}
                                    open={this.state.isAlert}
                                    autoHideDuration={3000}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    onClose={this.oncloseAlert}
                                    data-test-id="alertTestId"
                                >
                                    <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
                                </Snackbar>
                            </form>
                        </Box>
                    </Grid>
                </MainGridContainer>
                <Footer navigation={this.props.navigation}/>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const MainGridContainer = styled(Grid)(({ dir }: { dir: "rtl" | "ltr" }) => ({
    display: "flex",
    justifyContent: "center",
    "& .sideImageBox": {
        width: "100%",
        height: "100%",
    },
    "& .sideImageContaine": {
        width: "100%",
        height: "100%",
    },
    "& .phoneInput": { display: "flex",gap:"15px", width: "100%" ,height: "60px"},
    "& .formGrid": {
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
    },
    "& .imageGrid": {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    "& .welcomeSubText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "50px",
        alignSelf: "flex-start",
    },
    "& .inputHeading": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "20px 10px 10px 0px",
        textOverflow: "ellipsis",
        fontFamily: "Lato, sans-serif",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    "& .formBox": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "50px",
        padding: "5px",
    },
    "& .loginUserButton": {
        textTransform:"capitalize",
        margin: "25px 0px 20px 0px !important",
        width: "100% !important",
        borderRadius: "0px !important",
        color: "white !important",
        backgroundColor: "var(--Primary-Purple-500, #CCBEB1) !important",
        gap: "8px",
        height: "56px !important",
        "& .MuiButton-label":{
            fontWeight:600,
            fontSize:"16px"
        }
    },
    "& .terms": {
        color: "#979797 !important",
        margin: "10px 10px 10px 10px !important",
        width: "520px !important",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
    },
    "& .welcomeText": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        textOverflow: "ellipsis",
        src: "url('/fonts/Avenir-Light.ttf') format('truetype')",
        fontFamily: "Avenir,sans-serif",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
        alignSelf: "flex-start",
    },
    "& .Register": {
        padding: "10px",
        width: "520px !important",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .socialLogin": {
        padding: "20px",
        width: "80% !important",
        display: "flex",
        alignItems: "center",

        justifyContent: "center",
    },
    "& .socialLoginImage": {
        margin: "10px 25px",
    },
    "& .condition": {
        color: "var(--Neutrals-Cool-gray-500, #375280)",
        cursor: "pointer",
    },
    "& .listData":{
        color:'#375280',
        display: "flex",
        alignItems: "center",
        gap: "3px"
    },
    "& .bottomText": {
        color: "#375280 !important",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
    },
    "& .registerButton": {
        cursor: "pointer",
        color:
            "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280)) !important",
        margin: "10px 10px 0 10px",

        fontFamily: "Lato ,sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "24px !important",
    },
    "& .inputFeild2Error2":{
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            border:"none",
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important",
            textAlign: dir === 'ltr' ? "left" : 'right',

        },
    },
    "& .inputFeild2": {
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F0F0F0",
            borderRadius: "2px",
            
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#F0F0F0",
        },
        '& .MuiInputBase-input': {
            color: "#375280"
        },
        "& .MuiFormHelperText-contained":{
            margin:"0 !important"
        },
           "& .MuiOutlinedInput-notchedOutline":{
            borderColor: "#F0F0F0",
        },
        "& .MuiOutlinedInput-root:hover":{
            border:"none, !important"
        }
    },
    "& .forgotPassword": {
        color: "var(--Neutrals-Cool-gray-500, var(--Light-Navy, #375280))",
        margin: "10px",
        textOverflow: "ellipsis",
        cursor: "pointer",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 200,
        lineHeight: "26px",
    },
    "& .listView":{
        height:"400px"
    },
    "& .continueText": {
        width: "100%",
        color: "#375280 !important",
        fontFamily: "Lato, sans-serif",
        fontSize: "16px !important",
        fontStyle: "normal !important",
        fontWeight: 400,
        lineHeight: "24px !important",
        display: "flex",
        flexDirection: "row",
        "&:before, &:after": {
          content: "''",
          flex: "1 1",
          borderBottom: "1px solid",
          margin: "auto"
        },
        "&:before": {
          marginRight: "25px"
        },
        "&:after": {
          marginLeft: "25px"
        }
      },
      "@media screen and (min-width: 0px) and (max-width: 1100px)": {
        "& .formBox": { width: "400px !important", flexWrap: "wrap" },
        "& .Register": {
          width: "350px !important",
        },
        "& .socialLogin": {
          width: "350px !important",
        },
        "& .terms": {
          width: "350px !important",
        },
        "& .loginUserButton": {
          width: "100%",
        },
        "& .phoneInput": { width: "100%" ,height: "60px"},
        "& .inputFeildPassword": {
          "& .MuiOutlinedInput-adornedEnd": {
              backgroundColor: "#F0F0F0",
              borderRadius: "2px",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
          },
      },
      },
      "& .loginUserButton1": {
        textTransform:"capitalize",
        margin: "25px 0px 20px 0px !important",
        width: "100% !important",
        borderRadius: "0px !important",
        color: "white !important",
        backgroundColor: "#FFFFFF !important",
        gap: "8px",
        border: "1px solid #CCBEB1",
        height: "56px !important",
        "& .MuiButton-label":{
            fontWeight:600,
            fontSize:"16px"
        }
    },
}));

const MobileTextField = styled(TextField)({
    width: "100%",
    marginLeft: "20px",
    gap: "4px",
    border: "0px !important",
    borderRadius: "0px !important",
    backgroundColor: "#f8f8f8",
    "@media screen and (min-width: 0px) and (max-width: 1100px)": {
        width: "100%",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
    },
});
export const CustomDropDownBox = styled(Box)({
    position: "relative",
    cursor: "pointer",
    width: "180px",
    height: "calc(100% - 5px)"
});
const DropDownHeaderBox = styled(Box)({
    padding: "10px",
    gap: "15px",
    display: "flex",
    alignItems: "center",
    border: "none",
    borderRadius: "2px",
    background: "#F0F0F0",
    height: "36px",
    color: "#375280",
    fontSize: "18px",
    "&.isActive":{
    border: "1px solid red",
    },
    "&:hover": {
        backgroundColor: "#F0F0F0"
    }
});
const SelectedCountryList = styled(Box)({
    display: "flex",
    alignItems: "center",
});
const DropDownOptionsList = styled(Box)({
    left: 0,
    width: "100%",
    border: "1px solid #ccc",
    borderTop: "none",
    borderRadius: "0 0 4px 4px",
    backgroundColor: "#fff",
    position: "absolute",
    zIndex: 1,
    height: "60vh",
    overflowX: "hidden"
});
const OptionsValueList = styled(Box)({
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
        backgroundColor: "#F0F0F0"
    }
});
// Customizable Area End
