// Customizable Area Start
import React from "react"
import {
    Box,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    styled,Snackbar,
    Modal,Button,Fade,
    Backdrop
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { wishlist, stylist, setting, saveCard, reviews, paymentHistory, myProfile, myOrder, loyality, address, logout, sidebarimg } from "../../blocks/customisableuserprofiles2/src/assets";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { setStorageData, removeStorageData ,getStorageData } from "../../framework/src/Utilities";
import Alert from '@material-ui/lab/Alert';
import i18n from "./i18next/i18n";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { IBlock } from "../../framework/src/IBlock";
import { deleteAccount } from "../src/Seller/assets";
import { runEngine } from "../../framework/src/RunEngine";
import { StyledButton, StyledButton1, StyledDrawer } from "./Header";

interface S {
    openMenu: boolean;
    selectedItem: string | null;
    isAlert:boolean;
    basePathe:string;
    buyerStylish: boolean;
    isAlertDelete:boolean;
    openDelete:boolean;
    accountMag:string;
    open1: boolean,
}
export interface Props {
    navigation?: any;
    id?: string;
}

interface ConnectorProps {
    direction: 'ltr' | 'rtl';
}
// Customizable Area End

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class Sidebar extends BlockComponent<Props, S, SS>{
    // Customizable Area Start
    getDeleteAccountId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        this.state = {
            openMenu: false,
            open1: false,
            selectedItem: "",
            isAlert:false,
            basePathe:"",
            buyerStylish: false,
            isAlertDelete: false,
            openDelete: false,
            accountMag: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getDeleteAccountId !== null &&
            this.getDeleteAccountId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson.message === "Account successfully deleted.") {
                this.setState({ openDelete: false, isAlertDelete: true }, () => { setTimeout(() => { this.redirectHome() }, 3000) })
            }
        }
        // Customizable Area End
    }
    handleItemClick = (item: string) => {
        localStorage.setItem("selectedItem", item)
        this.setState({
            selectedItem: item
        });
    };
    headerNavigation = async (pageLink: string, name: string) => {
        await setStorageData("selectedItem", name)
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
        this.setState({
            selectedItem: name
        });
    };
    handleOpenLogoutModel = () => {
        this.setState({ open1: true });
    };
    handleClose1 = () => {
        this.setState({ open1: false });
    };
    logoutNavigation = async (pageLink: string, name: string) => {
        ["storedPath", "orderNumber", "auth-token", "sms-token", "fcm_token", "userRole", "stylitOrder", "matchedClients", "user_data", "resend_otp_number", "sms_otp_token", "unique_token", "user_id", "account_id"].map(async value=>{
            await removeStorageData(value)
        })
        this.setState({ open1: false });
        const toNavigate = new Message(getName(MessageEnum.NavigationMessage));
        toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pageLink);
        toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(toNavigate);
        this.setState({
            selectedItem: name
        });
    }
    notNavigation = async (name:string) => {
        await setStorageData("selectedItem", name)
        this.setState({ selectedItem: name,isAlert: true,basePathe:name });
    }
    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    hendelUrl = async () => {
        const baseUrl = new URL(document.URL).href.split('/').pop();
        await setStorageData("selectedItem", baseUrl)
        const selectedItemUrl = await getStorageData("selectedItem")
        this.setState({basePathe:selectedItemUrl})
    }
    async componentDidMount() {
        this.hendelUrl();
        const buyerStylish = await getStorageData('exploreBuyer', true)
        this.setState({buyerStylish});
    }
    transSideB = (keySB: string) => {
        return i18n.t(keySB, { ns: "sidebar" } )
    }
    openDeleteAccount = () => {
        this.setState({ openDelete: true })
    }
    cancelAccount = () => {
        this.setState({ openDelete: false })
    }
    deleteAccount = async () => {
        const token = await getStorageData("auth-token");
        const deleteAccountId = await getStorageData("user_id")
        const header = {
            "Content-Type": "application/json",
            token: token,
        };
        const requestMessageList = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDeleteAccountId = requestMessageList.messageId;
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "DELETE"
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/${deleteAccountId}`
        );
        requestMessageList.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessageList.id, requestMessageList);
    }
    oncloseAlertDlete = () => {
        this.setState({ isAlertDelete: false })
    }

    redirectHome = () => {
        ["orderNumber", "auth-token", "sms-token", "fcm_token", "userRole"].map(async value => {
            await removeStorageData(value)
        })
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Home");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    render() {
        // Customizable Area Start
        const { selectedItem } = this.state;
        const isItemSelected = selectedItem !== "";
        return (
            <StyledWrapper direction={i18n.dir()}>
                <Grid container>
                    <Grid
                        style={{ marginLeft: i18n.dir() === "ltr" ? "40px" : 0, marginRight: i18n.dir() === "rtl" ? "40px" : 0 }}
                        dir={i18n.dir()}
                    >
                        <Box className="link"  onClick={() => this.headerNavigation("OrderManagementPage","OrderManagementPage")}
                            style={{ backgroundColor: this.state.basePathe === "OrderManagementPage" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}> 
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "OrderManagementPage" ? `url(${sidebarimg})` : "none"
                                }}
                            />
                            <Typography className="link" >
                                <img src={myOrder} />
                                <span>{this.transSideB("My orders")}</span>
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Wishlist","Wishlist")}
                            style={{ backgroundColor: this.state.basePathe === "Wishlist" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Wishlist" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link" >
                                <img src={wishlist} />
                                <span>{this.transSideB("Wishlist")}</span>
                            </Typography>
                        </Box>
                        {!this.state.buyerStylish &&  <Box className="link" onClick={() => this.headerNavigation("Customisableuserprofiles2","profile")}
                            style={{ backgroundColor: this.state.basePathe === "profile" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "profile" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link" >
                                <img src={myProfile} />
                                <span>{this.transSideB("My Profile")}</span>
                            </Typography>
                        </Box> 
                        }
                        <Box className="link" onClick={() => this.headerNavigation("PaymentHistoryWeb","PaymentHistoryWeb")}
                            style={{ backgroundColor: this.state.basePathe === "PaymentHistoryWeb" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "PaymentHistoryWeb" ? `url(${sidebarimg})` : "none"
                                }}
                            />
                            <Typography className="link">
                                <img src={paymentHistory} />
                                <span>{this.transSideB("Payment History")}</span>
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Loyaltysystem","Loyaltysystem")}
                            style={{ backgroundColor: this.state.basePathe === "Loyaltysystem" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Loyaltysystem" ? `url(${sidebarimg})` : "none"
                                }}
                            />
                            <Typography className="link" >
                                <img src={loyality} />
                                <span>{this.transSideB("Loyalty Points")}</span>
                            </Typography>
                        </Box>

                        <Box className="link" onClick={() => this.headerNavigation("Savedcards","Savedcards")}
                            style={{ backgroundColor: this.state.basePathe === "Savedcards" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Savedcards" ? `url(${sidebarimg})` : "none"
                                }}
                            />
                            <Typography className="link" >
                                <img src={saveCard} />
                                <span>{this.transSideB("Saved Cards")}</span>
                            </Typography>
                        </Box>
                        <Box className="link" onClick={() => this.headerNavigation("Notifications","Notifications")}
                            style={{ backgroundColor: localStorage.getItem("selectedItem") === "Notifications" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: localStorage.getItem("selectedItem") === "Notifications" ? `url(${sidebarimg})` : "none",
                                    width: "2px",
                                    height: "54px",
                                    marginLeft: "-7px",
                                    position: "absolute"
                                }}
                            />
                            <Typography className="link" >
                                <NotificationsNoneIcon style={{fill: "#375280"}} />
                                {this.transSideB("Notification")}
                            </Typography>
                        </Box>
                           {!this.state.buyerStylish &&  <Box className="link" onClick={() => this.headerNavigation("FavouriteStylist","FavouriteStylist")}
                            style={{ backgroundColor: this.state.basePathe === "FavouriteStylist" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "FavouriteStylist" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link" >
                                <img src={stylist} />
                                <span>{this.transSideB("Favourite Stylist")}</span>
                            </Typography>
                        </Box>
                        }
                        <Box 
                            className="link" onClick={() => this.headerNavigation("AddAddress","AddAddress")}
                            style={{ backgroundColor: this.state.basePathe === "AddAddress" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative"}}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "AddAddress" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link" >
                                <img src={address} />
                                <span>{this.transSideB("Address")}</span>
                            </Typography>
                        </Box>
                        <Box
                            className="link" onClick={() => this.headerNavigation("CurrencyLanguage","Setting")}
                            style={{ backgroundColor: this.state.basePathe === "Setting" ? "#F1F5F9" : "transparent", textDecoration: "none",position: "relative" }}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: this.state.basePathe === "Setting" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link" >
                                <img src={setting} />
                                <span>{this.transSideB("Language and Currency")}</span>
                            </Typography>
                        </Box>
                        <Box
                            className="link" onClick={this.openDeleteAccount}
                            style={{ textDecoration: "none",position: "relative" }}>
                            <Typography className="link1" >
                                <img src={deleteAccount} style={{width:'30px'}}/>
                                <span>{this.transSideB("Delete Account")}</span>
                            </Typography>
                        </Box>
                        <Box
                            className="link" onClick={() => this.handleOpenLogoutModel()}
                            style={{ backgroundColor: localStorage.getItem("selectedItem") === "logout" ? "#F1F5F9" : "transparent", textDecoration: "none", position: "relative"}}>
                            <div
                                className="wishlist-icon"
                                style={{
                                    backgroundImage: localStorage.getItem("selectedItem") === "logout" ? `url(${sidebarimg})` : "none",
                                }}
                            />
                            <Typography className="link1">
                                <img src={logout} />
                                {this.transSideB("Logout")}
                            </Typography>
                        </Box>
                    </Grid>

                </Grid>
                <Modal
                    aria-describedby="transition-modal-description"
                    open={this.state.openDelete}
                    aria-labelledby="transition-modal-title"
                    style={{ ...webStyle.menuDrawer }}
                    closeAfterTransition
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.openDelete} style={{ borderRadius: '10px', maxWidth: "360px", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <div style={{ textAlign:'center', fontSize: '30px', color: "#375280", fontWeight: 700, fontFamily: "Lato" }}>{this.transSideB("Delete Account")}</div>
                            <div style={{margin: "15px 0px 30px", textAlign:'center', fontSize: '24px', color: "#375280", fontWeight: 400, fontFamily: "Lato" }}>{this.transSideB("DeleteAccountMag")}</div>
                            <div style={{ display: "flex", gap: "20px", justifyContent: "space-between", flexDirection: i18n.dir() === 'ltr' ? 'row' : 'row-reverse' }}>
                                <Button style={{ ...webStyle.styleButtonSave, textTransform: "none" }} data-testid="handleClose1" onClick={this.deleteAccount}>{this.transSideB("Delete")}</Button>
                                <Button style={{ ...webStyle.styleButtonCancel, textTransform: "none" }} data-testid="cancelAccount" onClick={this.cancelAccount}>{this.transSideB("Cancel")}</Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <StyledDrawer
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open1}
                    onClose={this.handleClose1}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    dir={i18n.dir()}
                >
                    <Fade in={this.state.open1} style={{ borderRadius: '10px', maxWidth: "320px", backgroundColor: "white" }}>
                        <div style={{ textAlign: "center", padding: "10px 30px 30px 30px" }}>
                            <Box id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 900, marginLeft: "10px" }}>{this.transSideB("Log Out")}</Box>
                            <h2 id="transition-modal-title" style={{ fontFamily: 'Lato', width: "300px", fontSize: '18px', color: "#375280", fontWeight: 600, marginLeft: "10px" }}>{this.transSideB("Are you sure you want to log out your account?")}</h2>
                            <div style={{ marginTop: "15px", display: "flex", gap: "20px", justifyContent: "space-between" }}>
                                <StyledButton style={{ fontSize: "18px", lineHeight: "20px" }} onClick={() => this.logoutNavigation("Home","logout")}>{this.transSideB("Log Out")}</StyledButton>
                                <StyledButton1 style={{ fontSize: "18px" }} onClick={this.handleClose1}>{this.transSideB("Cancel")}</StyledButton1>
                            </div>
                        </div>
                    </Fade>
                </StyledDrawer>

                <Snackbar
                    open={this.state.isAlertDelete}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={this.oncloseAlertDlete}
                    data-testid="alertTestId"
                ><Alert variant="filled" severity="success" style={{width:'300px',marginTop:'10px'}}>{this.transSideB("Delete Account Successfully")}</Alert></Snackbar>

                <Snackbar
                    open={this.state.isAlert}
                    autoHideDuration={3000}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    onClose={this.oncloseAlert}
                    data-testid="alertTestId"
                ><Alert variant="filled" severity="info" style={{width:'300px',marginTop:'10px'}}>{this.transSideB("Coming Soon !!!")}</Alert></Snackbar>
            </StyledWrapper>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const webStyle = {
    menuDrawer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "none",
    },
    styleButtonCancel: {
        fontWeight: 700,
        color: "#375280",
        fontSize: "18px",
        width: "170px",
        fontFamily: "Lato",
        height: "45",
        border: "1px solid #CCBEB1",
        backgroundColor: '#ffff',
        '&:hover': {
            backgroundColor: "#fff"
        },
    },
    styleButtonSave: {
        fontWeight: 700,
        color: "#fff",
        fontSize: "18px",
        width: "170px",
        height: "45",
        fontFamily: "Lato",
        backgroundColor: '#DC2626',
        '&:hover': {
            backgroundColor: "#DC2626"
        },
    },
}
const StyledWrapper = styled(Box)({
    "& .wishlist-icon":{
        width: "2px",
        height: "54px",
        margin: (props: ConnectorProps) => (props.direction === 'ltr'?"0px 0px 0px -7px": "0px -7px 0px 0px"),
        position: "absolute"
    },
    "& .link": {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        color: "#334155",
        fontFamily: "Lato , sans-serif",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "21.6px !important",
        padding: (props: ConnectorProps) => (props.direction === 'ltr'?"8px 20px 8px 7px": "8px 7px 8px 20px"),
        gap: "10px"
    },
    "& .link1": {
        color: "#DC2626",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        fontFamily: "Lato , sans-serif",
        fontSize: "18px !important",
        fontStyle: "normal !important",
        fontWeight: 700,
        lineHeight: "21.6px !important",
        padding: (props: ConnectorProps) => (props.direction === 'ltr'?"8px 20px 8px 7px": "8px 4px 8px 7px"),
        gap: "10px"

    },

})
// Customizable Area End